
import React, { useState , useEffect } from 'react';
import './APFC_Signalling_Manual_Form.css'; 
import axios from 'axios';
import { LeadPFheader } from '../../../Operator/Data'; 
import { Link , useParams , useNavigate} from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import { getCurrentDateTime } from '../../../Diagnose/dateUtils'; 
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const APFC_Signalling_Manual_Form = () => {
    const { token_id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        running_load_kw: '',
        power_factor: '',
        no_of_switching_banks_are_on: '',
        desired_number_of_switching_banks_to_be_on: '',
        token_id: token_id,
        dateandtime: getCurrentDateTime(), 
        createdby: '', 
        createdat: '',
        modifiedby: '',
        modifiedat: ''
    });

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
      // Retrieve the logged-in user from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        setLoggedInUser(user);
      }
      setUserDesignation(user.level);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = {
                ...formData,
                running_load_kw: Number(formData.running_load_kw),
                power_factor: Number(formData.power_factor),
                no_of_switching_banks_are_on: Number(formData.no_of_switching_banks_are_on),
                desired_number_of_switching_banks_to_be_on: Number(formData.desired_number_of_switching_banks_to_be_on),
            };
            const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/apfcsignallingmanualforms`, dataToSend);

            console.log('Data posted successfully:', response.data);

            alert('APFC Signalling Manual Form data added successfully');

            // Reset form fields after successful submission
            setFormData({
                name: '',
                running_load_kw: '',
                power_factor: '',
                no_of_switching_banks_are_on: '',
                desired_number_of_switching_banks_to_be_on: '',
                token_id: token_id,
                dateandtime: getCurrentDateTime(),
                createdby: '',
                createdat: '',
                modifiedby: '',
                modifiedat: ''
            });
            navigate('/APFC_Signalling_Manual_Formfetch');

        } catch (error) {
            console.error('Error posting data:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <div id='apfc_signalling_manual_form-total'>
             <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />}
            </div>
            <div id='apfc_signalling_manual_form-right'>
                <div id='apfc_signalling_manual_form-right-1'>
                    <div id='apfc_signalling_manual_form-heading'>
                        {LeadPFheader.alertHeading}
                    </div>
                    <div id='apfc_signalling_manual_form-heading1'>
                        {LeadPFheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={LeadPFheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <h3 id='apfc_signalling_manual_form-h3'>{LeadPFheader.subalertHeading}</h3>
                <div id='apfc_signalling_manual_form-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='apfc_signalling_manual_form-divtop'>
                            <label>
                                <span>APFC Signalling Manual Form</span>
                            </label>
                            {/* <label>
                                <Link to='/APFC_Signalling_Manual_Formfetch'><button type="button">View Data</button></Link>
                            </label> */}
                        </div>

                        <div id='apfc_signalling_manual_form-row'>
                            <label>
                                <span>Name <span id='all-mandetorymark'>*</span></span>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label>
                                <span>Running Load kW <span id='all-mandetorymark'>*</span></span>
                                <input
                                    type="number"
                                    name="running_load_kw"
                                    value={formData.running_load_kw}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                        </div>

                        <div id='apfc_signalling_manual_form-row'>
                            <label>
                                <span>Power Factor <span id='all-mandetorymark'>*</span></span>
                                <input
                                    type="number"
                                    name="power_factor"
                                    value={formData.power_factor}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label>
                                <span>No. of Switching Banks On <span id='all-mandetorymark'>*</span></span>
                                <input
                                    type="number"
                                    name="no_of_switching_banks_are_on"
                                    value={formData.no_of_switching_banks_are_on}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                        </div>

                        <div id='apfc_signalling_manual_form-row'>
                            <label>
                                <span>Desired No. of Switching Banks to be On <span id='all-mandetorymark'>*</span></span>
                                <input
                                    type="number"
                                    name="desired_number_of_switching_banks_to_be_on"
                                    value={formData.desired_number_of_switching_banks_to_be_on}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                        </div>
                        <label>
                            <button id='apfc_signalling_manual_form-submit' type="submit">Submit</button>
                        </label>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default APFC_Signalling_Manual_Form;

