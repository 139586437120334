import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import "./WeeklyReport.css";
import patternLabLogo from "../../../Images/logo1.svg";
import { Col, Empty, Flex, Progress, Row } from "antd";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import { useTranslation } from "react-i18next";
import { useWeekReportQuery } from "../../../api/services/weeklyReport/queries";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import EnergyIntensityBar from "./Charts/EnergyIntensityBar/EnergyIntensityBar";
import ConsuptionChart from "./Charts/Consumption/Consumption";
import DoughnutChartWithLabels from "./Charts/Alerts/AlertsChart";
import LossesWithAlerts from "./LossesWithAlerts/LossesWithAlerts";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useForm } from "react-hook-form";
import moment from "moment";
import { Select } from "antd";

const WeeklyReport = () => {
  const { t } = useTranslation();
  const monthInputRef = useRef(null);
  const { register, setValue, watch } = useForm({
    defaultValues: {
      month: moment().format("YYYY-MM"),
      week: 1,
    },
  });

  const { month, week } = watch();

  const { data, isSuccess, isLoading, isError, error } = useWeekReportQuery({
    month,
    week,
    enabled: month || week,
  });

  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const elementRefs = [ref1, ref2, ref3];

  const downloadPDF = () => {
    const pdf = new jsPDF("portrait", "pt", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Function to handle each element conversion and PDF page addition
    const addElementToPDF = (elementRef, isFirstPage) => {
      return html2canvas(elementRef.current, {
        scale: 2.25, // High resolution
        useCORS: true, // Handles cross-origin content
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 1);

        // Get the canvas dimensions
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        // Calculate the scaling ratio to fit the PDF page
        const ratio = Math.min(
          pdfWidth / canvasWidth,
          pdfHeight / canvasHeight
        );
        // const imgWidth = canvasWidth * ratio;
        const imgHeight = canvasHeight * ratio;

        // Add the image to the PDF
        if (!isFirstPage) {
          pdf.addPage();
        }
        pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, imgHeight || pdfHeight);
      });
    };

    // Process each element and ensure it's added to a new page
    const promises = elementRefs.map(
      async (elementRef, index) =>
        await addElementToPDF(elementRef, index === 0) // Only skip `addPage` for the first element
    );

    // After all elements are added, save the PDF
    Promise.all(promises).then(() => {
      pdf.save("multi_page_report.pdf");
    });
  };

  let basePercentage = (20 / 26) * 100;
  let diffPercentage = (6 / 26) * 100;

  if (isSuccess && data) {
    basePercentage =
      (data?.energy_intensity.standard / data?.energy_intensity.actual) * 100;
    diffPercentage =
      (data?.energy_intensity.difference / data?.energy_intensity.actual) * 100;
  }

  return (
    <>
      <Paper
        elevation={1}
        sx={{
          p: 2,
          mb: 2,
          gap: 3,
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        {!isError && (
          <Button
            onClick={downloadPDF}
            sx={{ textTransform: "none", fontWeight: 600, height: 35 }}
            variant="contained"
            color="none"
            endIcon={<PictureAsPdfIcon />}
          >
            {t("common.exportPDF")}
          </Button>
        )}

        <div>
          <input
            {...register("month")}
            style={{ height: "30px" }}
            type="month"
            ref={monthInputRef}
            defaultValue={month}
            max={new Date().toISOString().slice(0, 7)}
            onClick={() => monthInputRef.current.showPicker()}
            onChange={(e) => setValue("month", e.target.value)}
          />
        </div>

        <div>
          <Select
            style={{
              width: 150,
              height: 35,
            }}
            defaultValue={week}
            value={week}
            {...register("week")}
            onChange={(value) => setValue("week", value)}
            optionFilterProp="label"
            options={t("weeklyReport.weekLabels", { returnObjects: true })}
          />
        </div>
      </Paper>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 100,
            height: 500,
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : isError ? (
        <Flex
          style={{ height: "500px" }}
          gap={5}
          vertical
          align="center"
          justify="center"
        >
          <Typography
            sx={{ fontFamily: "DM Sans", mb: 5 }}
            className="energy-intensity-span"
          >
            {error?.message}
          </Typography>

          <Empty />
        </Flex>
      ) : (
        <Paper sx={{ pb: 3, textAlign: "center" }}>
          <div ref={ref1}>
            <div className="banner-container">
              <Box
                sx={{ display: "flex", justifyContent: "space-between", m: 5 }}
              >
                <img src={patternLabLogo} alt="logo" />
                <img src={patternLabLogo} alt="logo" />
              </Box>
            </div>

            <div className="content-container">
              <div>
                <Typography className="score-card-title">
                  {t("weeklyReport.plantReport")}
                </Typography>
                <Typography className="score-card-description">
                  {data?.week_start_date} to {data?.week_end_date}
                </Typography>
              </div>
            </div>

            {/* Energy Intensity & Efficiency Intensity */}

            <Row
              gutter={[5, 5]}
              justify="center"
              align="top" // Adjust alignment to vertically center content
              style={{ width: "100%" }}
            >
              <Col
                xs={24}
                sm={11}
                style={{
                  display: "flex", // Make the column a flex container
                  alignItems: "start", // Center items vertically
                  justifyContent: "start", // Center items horizontally
                  textAlign: "start", // Make the column"
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography className="energy-intensity">
                    {t("weeklyReport.energyIntensity")}
                  </Typography>

                  <Box sx={{ p: 2 }}>
                    <Typography
                      className="energy-intensity-actual"
                      sx={{ display: "flex", alignItems: "center", gap: 1 }}
                    >
                      {t("weeklyReport.actualEnergyIntensity")}
                      <span className="energy-intensity-span">
                        {data?.energy_intensity?.actual}
                      </span>
                    </Typography>
                    <Flex style={{ width: "100%" }}>
                      <Box
                        bgcolor={"green"}
                        className="energy-intensity-span"
                        sx={{
                          width: `${basePercentage}%`,
                          height: 70,
                          lineHeight: "70px",
                          position: "relative",
                          textAlign: "center",
                          color: "white",
                        }}
                      >
                        <ArrowDropUpOutlinedIcon
                          sx={{
                            color: "#F03840",
                            position: "absolute",
                            bottom: -22,
                            right: -17.5,
                          }}
                          fontSize="large"
                        />
                        <ArrowDropDownOutlinedIcon
                          sx={{
                            color: "#F03840",
                            position: "absolute",
                            top: -22,
                            right: -17.5,
                          }}
                          fontSize="large"
                        />
                        {data?.energy_intensity?.standard}
                      </Box>

                      <Box
                        bgcolor={"red"}
                        className="energy-intensity-span"
                        sx={{
                          width: `${diffPercentage}%`,
                          height: 70,
                          lineHeight: "70px",
                          textAlign: "center",
                          color: "white",
                        }}
                      >
                        {data?.energy_intensity?.difference}
                      </Box>
                    </Flex>
                    <Flex
                      justify="center"
                      gap={10}
                      align="center"
                      style={{ marginTop: "30px" }}
                    >
                      <Box
                        bgcolor={"green"}
                        sx={{ height: 10, width: 50, display: "inline-block" }}
                      ></Box>
                      <Typography sx={{ fontSize: 12, fontFamily: "DM Sans", color: '#757474' }}>
                      
                        {t("weeklyReport.standard")}
                      </Typography>
                      <Box
                        bgcolor={"red"}
                        sx={{ height: 10, width: 50, display: "inline-block" }}
                      ></Box>
                      <Typography sx={{fontSize: 12, fontFamily: "DM Sans", color: '#757474' }}>
                       
                        {t("weeklyReport.deviation")}
                      </Typography>
                    </Flex>
                  </Box>

                  <Flex
                    style={{
                      height: "600px",
                    }}
                    vertical
                    justify="start"
                  >
                    <Box
                      sx={{
                        height: 500,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <EnergyIntensityBar
                        labels={data?.energy_intensity?.graph_data?.labels}
                        barData={data?.energy_intensity?.graph_data?.data}
                        baseValue={Number(data?.energy_intensity?.standard)}
                      />
                    </Box>

                    <Typography sx={{ fontFamily: "DM Sans" }}>
                      {t("weeklyReport.energyIntensityDes")}
                    </Typography>
                  </Flex>
                </Box>
              </Col>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  borderColor: "#F2F1F1",
                  borderWidth: "1px",
                }}
              />
              <Col
                xs={24}
                sm={11}
                style={{
                  paddingLeft: "20px",
                  textAlign: "start", // Make the column"
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography className="energy-intensity">
                    {t("weeklyReport.energyEfficiency")}
                  </Typography>
                  <Box sx={{ px: 5, mt: 3 }}>
                    <Flex justify="space-between" align="center">
                      <Typography className="efficiency-percentage">
                        {data?.energy_efficiency}%
                      </Typography>

                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <Progress
                          style={{
                            backgroundColor: "#C8E2BF",
                            borderRadius: "100px",
                          }}
                          type="circle"
                          percent={data?.energy_efficiency}
                          strokeColor={"#378B1D"}
                          format={() => null}
                        />

                        {/* Centered SVG using flexbox */}
                        <div className="progress-wrapper">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="#000000"
                            width="100px"
                            height="100px"
                          >
                            <path d="M12 2L8 10h3v8l4-8h-3z" />
                          </svg>
                        </div>
                      </div>
                    </Flex>
                  </Box>

                  <Divider
                    flexItem
                    sx={{
                      mt: 2,
                      mb: 2,
                      borderColor: "#F2F1F1",
                      borderWidth: "1px",
                    }}
                  />

                  <Flex
                    style={{
                      height: "600px",
                    }}
                    vertical
                    justify="space-around"
                  >
                    <Box>
                      <Typography className="energy-intensity">
                        {t("weeklyReport.consumptionBySource")}
                      </Typography>
                      <Box
                        sx={{
                          height: 400,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ConsuptionChart
                          consumptionData={data?.source_consumption?.data}
                          labels={data?.source_consumption?.labels}
                        />
                      </Box>
                    </Box>

                    <Typography sx={{ fontFamily: "DM Sans" }}>
                      {t("weeklyReport.energyEfficiencyDes")}
                    </Typography>
                  </Flex>
                </Box>
              </Col>
            </Row>

            <Divider
              flexItem
              sx={{
                mt: 3,
                borderColor: "#F2F1F1",
                borderWidth: "1px",
              }}
            />

            {/* Total Losses  */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                className="energy-intensity"
                sx={{ px: 5, pt: 3, justifySelf: "start" }}
              >
                {t("weeklyReport.totalLosses")}
              </Typography>
              <Typography className="energy-intensity" sx={{ pr: 30, pt: 3 }}>
                {t("weeklyReport.totalCriticalAlerts")}
              </Typography>
            </Box>

            <Row align={"top"} justify={"center"}>
              <Col
                xs={24}
                sm={8}
                style={{
                  padding: "20px",
                }}
              >
                <Box sx={{ width: "100%", height: "100%" }}>
                  <Box
                    sx={{
                      px: 2,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: 300,
                    }}
                  >
                    <Typography
                      sx={{ fontSize: 20, alignSelf: "flex-start" }}
                      className="energy-intensity-actual"
                    >
                      {t("weeklyReport.energyLoss")}
                    </Typography>

                    <Box
                      sx={{
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          gap: 0,
                        }}
                      >
                        <Typography
                          style={{ color: "red" }}
                          className="total-loss-value"
                        >
                          {data?.total_data?.losses?.energy}
                        </Typography>
                      </Box>
                      <Typography className="energy-intensity-actual">
                        {" "}
                        {t("weeklyReport.kwh")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Col>

              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  mt: 5,
                  borderColor: "#F2F1F1",
                  borderWidth: "1px",
                }}
              />

              <Col
                xs={24}
                sm={7}
                style={{
                  padding: "20px",
                }}
              >
                <Box sx={{ width: "100%", height: "100%" }}>
                  <Box
                    sx={{
                      px: 2,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: 300,
                    }}
                  >
                    <Typography
                      sx={{ fontSize: 20, alignSelf: "flex-start" }}
                      className="energy-intensity-actual"
                    >
                      {t("weeklyReport.revenueLoss")}
                    </Typography>

                    <Box
                      sx={{
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          gap: 0,
                        }}
                      >
                        <Typography className="total-loss-value">
                          ₹ {data?.total_data?.losses?.revenue}
                        </Typography>
                      </Box>
                      <Typography className="energy-intensity-actual">
                        {t("weeklyReport.rupees")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Col>

              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  mt: 5,
                  borderColor: "#F2F1F1",
                  borderWidth: "1px",
                }}
              />
              <Col
                xs={24}
                sm={8}
                style={{
                  paddingLeft: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <DoughnutChartWithLabels
                  totalAlerts={data?.total_data?.critical_alerts?.raised_alerts}
                  AlertsData={data?.total_data?.critical_alerts?.data}
                  labels={data?.total_data?.critical_alerts?.labels}
                  colors={["#FAA2A6", "#F03840"]}
                />
              </Col>
            </Row>

            <Divider
              flexItem
              sx={{
                mt: 2,
                borderColor: "#F2F1F1",
                borderWidth: "1px",
              }}
            />

            {/* Revenue Losses Break Down  */}

            <Typography
              className="energy-intensity"
              sx={{ px: 5, pt: 3, pb: 1, fontFamily: "DM Sans" }}
              dangerouslySetInnerHTML={{
                __html: t("weeklyReport.diagnoseReduceTitle1", {
                  percentage: data?.loss_percentages?.critical,
                }),
              }}
            />

            {data &&
              data?.break_down_data?.losses_with_alerts.length > 0 &&
              data?.break_down_data?.losses_with_alerts
                .slice(0, 1)
                .map((lossesItem, index) => (
                  <LossesWithAlerts
                    key={index}
                    title={lossesItem.alert_type}
                    alertsData={lossesItem.alerts_data}
                    energyLoss={lossesItem.energy_loss}
                    revenueLoss={lossesItem.revenue_loss}
                  />
                ))}
          </div>
          <div ref={ref2}>
            {data &&
              data?.break_down_data?.losses_with_alerts.length > 0 &&
              data?.break_down_data?.losses_with_alerts
                .slice(1, 5)
                .map((lossesItem, index) => (
                  <LossesWithAlerts
                    key={index}
                    title={lossesItem.alert_type}
                    alertsData={lossesItem.alerts_data}
                    energyLoss={lossesItem.energy_loss}
                    revenueLoss={lossesItem.revenue_loss}
                  />
                ))}
          </div>
          <div ref={ref3}>
            {data &&
              data?.break_down_data?.losses_with_alerts.length > 0 &&
              data?.break_down_data?.losses_with_alerts
                .slice(5)
                .map((lossesItem, index) => (
                  <LossesWithAlerts
                    key={index}
                    title={lossesItem.alert_type}
                    alertsData={lossesItem.alerts_data}
                    energyLoss={lossesItem.energy_loss}
                    revenueLoss={lossesItem.revenue_loss}
                  />
                ))}
            <Typography
              className="energy-intensity"
              sx={{ px: 5, pt: 1, fontFamily: "DM Sans" }}
            >
              {t("weeklyReport.stepsToDiagnose")}
            </Typography>

            <Box
              sx={{ textAlign: "start", px: 5, pt: 1, fontFamily: "DM Sans" }}
            >
              {t("weeklyReport.diagnoseSteps", {
                returnObjects: true,
              }).map((step) => (
                <ul>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: step,
                    }}
                  ></li>
                </ul>
              ))}
            </Box>

            <Divider
              flexItem
              sx={{
                mt: 2,
                borderColor: "#F2F1F1",
                borderWidth: "1px",
              }}
            />

            {/* LossesBreakdowm */}
            <Typography
              className="energy-intensity"
              sx={{ px: 5, pt: 3, pb: 1, fontFamily: "DM Sans" }}
              dangerouslySetInnerHTML={{
                __html: t("weeklyReport.diagnoseReduceTitle1", {
                  percentage: data?.loss_percentages?.revenue,
                }),
              }}
            />

            <Row align="middle" justify="center" glutter={[0, 0]}>
              {data?.break_down_data?.losses?.map((lossItem, index) => (
                <Col key={index} xs={24} sm={12}>
                  <Typography
                    className="energy-intensity"
                    sx={{ px: 5, pt: 3, justifySelf: "start" }}
                  >
                    {lossItem?.type}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                      flexDirection: "column",
                      borderLeft: "2px solid #F2F1F1",
                    }}
                  >
                    <Box
                      sx={{
                        pt: 2,
                        px: 10,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 20, alignSelf: "flex-start" }}
                        className="energy-intensity-actual"
                      >
                        {t("weeklyReport.energyLoss")}
                      </Typography>

                      <Box
                        sx={{
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            gap: 0,
                          }}
                        >
                          <Typography className="total-loss-value-cri">
                            {lossItem.energy_loss.value}
                          </Typography>
                          {Number(lossItem?.energy_loss?.value) !== 0 && (
                            <>
                              {lossItem?.energy_loss?.flag ? (
                                <ArrowDropDownOutlinedIcon
                                  sx={{ color: "#F03840", fontSize: "100px" }}
                                />
                              ) : (
                                <ArrowDropUpOutlinedIcon
                                  sx={{ color: "#36A642", fontSize: "100px" }}
                                />
                              )}
                            </>
                          )}
                        </Box>
                        <Typography className="energy-intensity-actual">
                          {t("weeklyReport.kwh")}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider
                      flexItem
                      sx={{
                        mt: 2,
                        mb: 2,
                        width: "50%",
                        borderColor: "#F2F1F1",
                        borderWidth: "1px",
                      }}
                    />
                    <Box
                      sx={{
                        pt: 2,
                        px: 10,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 20, alignSelf: "flex-start" }}
                        className="energy-intensity-actual"
                      >
                        {t("weeklyReport.revenueLoss")}
                      </Typography>

                      <Box
                        sx={{
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            gap: 0,
                          }}
                        >
                          <Typography className="total-loss-value-cri">
                            ₹ {lossItem?.revenue_loss?.value}
                          </Typography>

                          {Number(lossItem?.revenue_loss?.value) !== 0 && (
                            <>
                              {lossItem?.revenue_loss?.flag ? (
                                <ArrowDropDownOutlinedIcon
                                  sx={{ color: "#F03840", fontSize: "100px" }}
                                />
                              ) : (
                                <ArrowDropUpOutlinedIcon
                                  sx={{ color: "#36A642", fontSize: "100px" }}
                                />
                              )}
                            </>
                          )}
                        </Box>
                        <Typography className="energy-intensity-actual">
                          {t("weeklyReport.rupees")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Divider
                    flexItem
                    sx={{
                      width: "100%",
                      mt: 2,
                      mb: 1,
                      borderColor: "#F2F1F1",
                      borderWidth: "1px",
                    }}
                  />
                </Col>
              ))}
            </Row>

            <Typography
              className="energy-intensity"
              sx={{ px: 5, pt: 0, fontFamily: "DM Sans" }}
            >
              {t("weeklyReport.stepsToFollowSuggestions")}
            </Typography>

            <Box
              sx={{ textAlign: "start", px: 5, pt: 1, fontFamily: "DM Sans" }}
            >
              {t("weeklyReport.suggestions", {
                returnObjects: true,
              }).map((step) => (
                <ul>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: step,
                    }}
                  ></li>
                </ul>
              ))}
            </Box>

            <Divider
              flexItem
              sx={{
                mt: 2,
                mb: 2,
                borderColor: "#F2F1F1",
                borderWidth: "1px",
              }}
            />

            {/* unidentified Losses */}

            <Row align={"top"} justify={"start"} style={{ textAlign: "start" }}>
              <Col xs={24} sm={8}>
                <Box sx={{ px: 5 }}>
                  <Typography className="energy-intensity">
                    {t("weeklyReport.unidentifiedLoss")}
                  </Typography>

                  <Typography className="total-loss-value">
                    {data?.total_data?.losses?.energy}
                  </Typography>

                  <Typography className="energy-intensity-actual">
                    {t("weeklyReport.kwh")}
                  </Typography>
                </Box>
              </Col>

              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  mt: 5,
                  borderColor: "#F2F1F1",
                  borderWidth: "1px",
                }}
              />

              <Col xs={24} sm={12}>
                <Box sx={{ px: 5, pt: 3, fontFamily: "DM Sans" }}>
                  <Typography
                    className="energy-intensity"
                    dangerouslySetInnerHTML={{
                      __html: t("weeklyReport.diagnoseReduceTitle2", {
                        percentage: data?.loss_percentages?.unidentified,
                      }),
                    }}
                  />

                  <Typography sx={{ mt: 3 }}>
                    {t("weeklyReport.unidentifiedLossDes")}
                  </Typography>
                </Box>
              </Col>
            </Row>
          </div>
        </Paper>
      )}
    </>
  );
};

export default WeeklyReport;
