// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const TOD_Run_Time_Week = () => {
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/todruntime-last7days`);
//                 setData(response.data);
//                 setLoading(false);
//             } catch (error) {
//                 console.error('Error fetching last 7 days data:', error);
//                 setError('Error fetching data');
//                 setLoading(false);
//             }
//         };

//         fetchData();
//     }, []);

//     if (loading) return <div>Loading...</div>;
//     if (error) return <div>{error}</div>;

//     return (
//         <div>
//             <h1>Last 7 Days Data</h1>
//             <label>
//                 <span>Section Name</span>
//                 <select>
//                     <option>HV</option>
//                 </select>
//             </label>
//             <label>
//                 <span>Select type</span>
//                 <select>
//                     <option>Incentives</option>
//                     <option>Noraml</option>
//                     <option>Penalty</option>
//                 </select>
//             </label>

//             <ul>
//                 {data.map((item, index) => (
//                     <li key={index}>
//                         <div> <strong> Date:</strong> {item.date}</div>
//                         <div> <strong>Station Points: </strong> {item.stationPoints.join(', ')}</div>
//                         <div> <strong> Incentives: </strong>{JSON.stringify(item.Incentives)}</div>
//                         <div> <strong> Noraml: </strong>{JSON.stringify(item.Normal)}</div>
//                         <div> <strong>Penalty: </strong> {JSON.stringify(item.Penalty)}</div>
//                     </li>
//                 ))}
//             </ul>
//         </div>
//     );
// };

// export default TOD_Run_Time_Week;



// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const stationPoints = [
//   "DG Incomer -1", "DG Incomer -2", "DG Incomer -3", "Solar-1", "WHR-1", "LV-2", 
//   "ENGG store panel", "Unit-5", "AR&D", "Production", "Lighting", "APFC-2", 
//   "DG Grid Incomer -2", "DG Incomer 4", "DG Incomer 5", "DG Incomer 6", "Solar-2", 
//   "WHR-2", "Pump House", "HV", "LV-1", "Compressor-11 KW", "Compressor-75 KW", 
//   "Colony", "Compressor", "APFC-1", "DG Grid Incomer -1", "Cut bend"
// ];

// const sortedStationPoints = stationPoints.sort();

// const TOD_Run_Time_Week = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [selectedStation, setSelectedStation] = useState('HV');
//   const [selectedType, setSelectedType] = useState('Incentives');

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/todruntime-last7days`);
//         setData(response.data);
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching last 7 days data:', error);
//         setError('Error fetching data');
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const filteredData = data.filter(item => 
//     item.stationPoints.includes(selectedStation)
//   ).map(item => ({
//     date: item.date,
//     Run_Hours: item[selectedType]?.Run_Hours?.[selectedStation] ?? 0,
//     Consumption: item[selectedType]?.Consumption?.[selectedStation] ?? 0
//   }));

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>{error}</div>;

//   return (
//     <div>
//       <h1>Last 7 Days Data</h1>
//       <label>
//         <span>Station Point Name</span>
//         <select value={selectedStation} onChange={(e) => setSelectedStation(e.target.value)}>
//           {sortedStationPoints.map((point, index) => (
//             <option key={index} value={point}>{point}</option>
//           ))}
//         </select>
//       </label>
//       <label>
//         <span>Select Type</span>
//         <select value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
//           <option value="Incentives">Incentives</option>
//           <option value="Normal">Normal</option>
//           <option value="Penalty">Penalty</option>
//         </select>
//       </label>

//       <ul>
//         {filteredData.map((item, index) => (
//           <li key={index}>
//             <div><strong>Date:</strong> {item.date}</div>
//             <div><strong>{selectedType} Run Hours:</strong> {item.Run_Hours}</div>
//             <div><strong>{selectedType} Consumption:</strong> {item.Consumption}</div>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default TOD_Run_Time_Week;




import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';

const stationPoints = [
  "DG Incomer -1", "DG Incomer -2", "DG Incomer -3", "Solar-1", "WHR-1", "LV-2", 
  "ENGG store panel", "Unit-5", "AR&D", "Production", "Lighting", "APFC-2", 
  "DG Grid Incomer -2", "DG Incomer 4", "DG Incomer 5", "DG Incomer 6", "Solar-2", 
  "WHR-2", "Pump House", "HV", "LV-1", "Compressor-11 KW", "Compressor-75 KW", 
  "Colony", "Compressor", "APFC-1", "DG Grid Incomer -1", "Cut bend"
];

const sortedStationPoints = stationPoints.sort();

const TOD_Run_Time_Week = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStation, setSelectedStation] = useState('HV');
  const [selectedType, setSelectedType] = useState('Incentives');

  const chartRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/todruntime-last7days`);
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching last 7 days data:', error);
        setError('Error fetching data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!chartRef.current) return;

    const chart = echarts.init(chartRef.current);

    const filteredData = data.filter(item => 
      item.stationPoints.includes(selectedStation)
    ).map(item => ({
      date: item.date,
      Run_Hours: item[selectedType]?.Run_Hours?.[selectedStation] ?? "00:00:00",
      Consumption: item[selectedType]?.Consumption?.[selectedStation] ?? 0
    }));

    const dates = filteredData.map(item => item.date);

    // Convert Run_Hours from "HH:MM:SS" format to decimal hours for plotting
    const runHoursData = filteredData.map(item => {
      const [hours, minutes, seconds] = item.Run_Hours.split(':').map(Number);
      return hours + (minutes / 60) + (seconds / 3600);
    });

    const consumptionData = filteredData.map(item => item.Consumption);

    const formatHHMMSS = (decimalHours) => {
      const totalSeconds = decimalHours * 3600;
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = Math.floor(totalSeconds % 60);
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: function (params) {
          let tooltip = params[0].name;
          params.forEach(param => {
            if (param.seriesName === 'Run Hours') {
              tooltip += `<br/>${param.marker} ${param.seriesName}: ${formatHHMMSS(param.value)}`;
            } else {
              tooltip += `<br/>${param.marker} ${param.seriesName}: ${param.value}`;
            }
          });
          return tooltip;
        }
      },
      legend: {
        data: ['Run Hours', 'Consumption']
      },
      
      xAxis: {
        type: 'category',
        data: dates,
        axisPointer: {
          type: 'shadow'
        },
        axisLabel: {
            rotate: 20,
            interval: 0,
          },
      },
      yAxis: [
        {
          type: 'value',
          name: 'Run Hours',
          axisLabel: {
            formatter: function (value) {
              return formatHHMMSS(value);
            }
          }
        },
        {
          type: 'value',
          name: 'Consumption',
          axisLabel: {
            show: false
          }
        }
      ],
      series: [
        {
          name: 'Run Hours',
          type: 'bar',
          data: runHoursData,
          yAxisIndex: 0,
          label: {
            // show: true,
            // position: 'inside',
            formatter: function (params) {
              return formatHHMMSS(params.value);
            }
          },
          emphasis: {
            focus: 'series'
          }
        },
        {
          name: 'Consumption',
          type: 'bar',
          data: consumptionData,
          yAxisIndex: 1,
          emphasis: {
            focus: 'series'
          }
        }
      ]
    };

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [data, selectedStation, selectedType]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h1>Last 7 Days Data</h1>
      <label>
        <span>Station Point Name</span>
        <select value={selectedStation} onChange={(e) => setSelectedStation(e.target.value)}>
          {sortedStationPoints.map((point, index) => (
            <option key={index} value={point}>{point}</option>
          ))}
        </select>
      </label>
      <label>
        <span>Select Type</span>
        <select value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
          <option value="Incentives">Incentives</option>
          <option value="Normal">Normal</option>
          <option value="Penalty">Penalty</option>
        </select>
      </label>

      <div ref={chartRef} style={{ width: '100%', height: '500px' }}></div>
    </div>
  );
};

export default TOD_Run_Time_Week;

