import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import { LowPFheader } from '../../../Operator/Data';
import { Link } from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import './Capacitance_Manual_Formfetch.css';
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const Capacitance_Manual_Formfetch = () => {
    const [capacitors, setCapacitors] = useState([]);
    const [capacitorEditData, setCapacitorEditData] = useState(null);

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
      // Retrieve the logged-in user from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        setLoggedInUser(user);
      }
      setUserDesignation(user.level);
    }, []);

    const fetchCapacitors = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/capacitance`);
            setCapacitors(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchCapacitors();
    }, []);

    const handleEditClick = (capacitor) => {
        setCapacitorEditData(capacitor);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setCapacitorEditData({ ...capacitorEditData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        console.log('Updating data:', capacitorEditData);
        alert("Capacitor updated successfully");
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/capacitance/${capacitorEditData._id}`, capacitorEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchCapacitors();
            setCapacitorEditData(null);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='capacitance-manual-formfetch-total'>
             <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />} 
            </div>
            <div id='capacitance-manual-formfetch-right'>
                <div id='capacitance-manual-formfetch-right-1'>
                    <div id='capacitance-manual-formfetch-heading'>
                        {LowPFheader.alertHeading}
                    </div>
                    <div id='capacitance-manual-formfetch-heading1'>
                        {LowPFheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={LowPFheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <div id='capacitance-manual-formfetch-right-2'>
                    <div id='capacitance-manual-formfetch-right-21'>

                    <label>
                                <span  style={{fontWeight:'bold'}}>Current Capacitance</span>
                            </label>
                        {/* <label>
                            <Link to='/Capacitance_Manual_Form'><button>Form</button></Link>
                        </label> */}
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Token ID</th>
                                <th>Date and Time</th>
                                <th>Name</th>
                                <th>Capacitor Bank Rating</th>
                                <th>Threshold Capacitance</th>
                                <th>Measured Capacitance</th>
                                {/* <th>Created By</th>
                                <th>Created At</th>
                                <th>Modified By</th>
                                <th>Modified At</th> */}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {capacitors.map((capacitor) => (
                                <tr key={capacitor._id}>
                                    <td>{capacitor.token_id}</td>
                                    <td>{capacitor.dateandtime}</td>
                                    <td>{capacitor.name}</td>
                                    <td>{capacitor.capacitor_bank_rating}</td>
                                    <td>{capacitor.threshold_capacitance}</td>
                                    <td>{capacitor.measured_capacitance}</td>
                                    {/* <td>{capacitor.createdby}</td>
                                    <td>{capacitor.createdat}</td>
                                    <td>{capacitor.modifiedby}</td>
                                    <td>{capacitor.modifiedat}</td> */}
                                    <td>
                                        <button onClick={() => handleEditClick(capacitor)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {capacitorEditData && (
                        <Popup open={true} onClose={() => setCapacitorEditData(null)}>
                            <div id='capacitance-manual-formfetch-popup'>
                                <h3>Edit Capacitor</h3>
                                <form onSubmit={handleEditSubmit}>

                                    <div id='capacitance-manual-formfetch-popup1'>
                                        <label>
                                            <span>Token ID</span>
                                            <input type="text" name="token_id" value={capacitorEditData.token_id} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Date and Time</span>
                                            <input type="text" name="dateandtime" value={capacitorEditData.dateandtime} onChange={handleEditChange} disabled />
                                        </label>
                                    </div>
                                    <div id='capacitance-manual-formfetch-popup1'>
                                        <label>
                                            <span>Name <span id='all-mandetorymark'>*</span></span>
                                            <input name="name" value={capacitorEditData.name} onChange={handleEditChange} required />
                                        </label>
                                        <label>
                                            <span>Capacitor Bank Rating <span id='all-mandetorymark'>*</span></span>
                                            <input type="number" name="capacitor_bank_rating" value={capacitorEditData.capacitor_bank_rating} onChange={handleEditChange} required />
                                        </label>
                                    </div>
                                    <div id='capacitance-manual-formfetch-popup1'>
                                        <label>
                                            <span>Threshold Capacitance <span id='all-mandetorymark'>*</span></span>
                                            <input type="number" name="threshold_capacitance" value={capacitorEditData.threshold_capacitance} onChange={handleEditChange} required />
                                        </label>
                                        <label>
                                            <span>Measured Capacitance <span id='all-mandetorymark'>*</span></span>
                                            <input type="number" name="measured_capacitance" value={capacitorEditData.measured_capacitance} onChange={handleEditChange} required/>
                                        </label>
                                    </div>
                                    {/* <div id='capacitance-manual-formfetch-popup1'>
                                        <label>
                                            <span>Created By</span>
                                            <input type="text" name="createdby" value={capacitorEditData.createdby} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Created At</span>
                                            <input type="text" name="createdat" value={capacitorEditData.createdat} onChange={handleEditChange} disabled />
                                        </label>
                                    </div>
                                    <div id='capacitance-manual-formfetch-popup1'>
                                        <label>
                                            <span>Modified By</span>
                                            <input type="text" name="modifiedby" value={capacitorEditData.modifiedby} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Modified At</span>
                                            <input type="text" name="modifiedat" value={capacitorEditData.modifiedat} onChange={handleEditChange} disabled />
                                        </label>
                                    </div> */}

                                    <button id='capacitance-manual-formfetch-popup-update' type="submit">Update</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Capacitance_Manual_Formfetch;
