import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import { Phaseimbalanceheader } from '../../../Operator/Data';
import { Link } from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import './Hotspot_Test_Formfetch.css';
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const Hotspot_Test_Formfetch = () => {
    const [hotspotTestForm, setHotspotTestForm] = useState([]);
    const [hotspotTestFormEditData, setHotspotTestFormEditData] = useState(null);

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
      // Retrieve the logged-in user from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        setLoggedInUser(user);
      }
      setUserDesignation(user.level);
    }, []);

    const fetchHotspotTestForm = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/hotspottestforms`);
            setHotspotTestForm(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchHotspotTestForm();
    }, []);

    const handleEditClick = (form) => {
        setHotspotTestFormEditData(form);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setHotspotTestFormEditData({ ...hotspotTestFormEditData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/hotspottestforms/${hotspotTestFormEditData._id}`, hotspotTestFormEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchHotspotTestForm();
            setHotspotTestFormEditData(null);
            alert("Hotspot Test Form updated successfully");
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='hotspot_test_formfetch-total'>
             <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />} 
            </div>
            <div id='hotspot_test_formfetch-right'>
                <div id='hotspot_test_formfetch-right-1'>
                    <div id='hotspot_test_formfetch-heading'>
                        {Phaseimbalanceheader.alertHeading}
                    </div>
                    <div id='hotspot_test_formfetch-heading1'>
                        {Phaseimbalanceheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Phaseimbalanceheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <div id='hotspot_test_formfetch-right-2'>
                    <div id='hotspot_test_formfetch-right-21'>
                    <label>
                                <span style={{fontWeight:'bold'}}>Hotspot Test Monitor</span>
                            </label>
                        {/* <label>
                            <Link to='/Hotspot_Test_Form'><button>Form</button></Link>
                        </label> */}
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Token ID</th>
                                <th>Date and Time</th>
                                <th>Machines Where Hotspots Found</th>
                                {/* <th>Created By</th>
                                <th>Created At</th>
                                <th>Modified By</th>
                                <th>Modified At</th> */}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {hotspotTestForm.map((form) => (
                                <tr key={form._id}>
                                    <td>{form.token_id}</td>
                                    <td>{form.dateandtime}</td>
                                    <td>{form.machines_where_hotspots_found}</td>
                                    {/* <td>{form.createdby}</td>
                                    <td>{form.createdat}</td>
                                    <td>{form.modifiedby}</td>
                                    <td>{form.modifiedat}</td> */}
                                    <td>
                                        <button onClick={() => handleEditClick(form)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {hotspotTestFormEditData && (
                        <Popup open={true} onClose={() => setHotspotTestFormEditData(null)}>
                            <div id='hotspot_test_formfetch-popup'>
                                <h3>Edit Hotspot Test Form</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='hotspot_test_formfetch-popup1'>
                                        <label>
                                            <span>Token ID</span>
                                            <input name="token_id" value={hotspotTestFormEditData.token_id} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Date and Time</span>
                                            <input name="dateandtime" value={hotspotTestFormEditData.dateandtime} onChange={handleEditChange} disabled />
                                        </label>
                                    </div>
                                    <div id='hotspot_test_formfetch-popup1'>
                                        <label> 
                                            <span>Machines Where Hotspots Found <span id='all-mandetorymark'>*</span></span>
                                            <input type="text" name="machines_where_hotspots_found" value={hotspotTestFormEditData.machines_where_hotspots_found} onChange={handleEditChange} required/>
                                        </label>
                                        {/* <label>
                                            <span>Created By</span>
                                            <input type="text" name="createdby" value={hotspotTestFormEditData.createdby} onChange={handleEditChange} disabled/>
                                        </label> */}
                                    </div>
                                    {/* <div id='hotspot_test_formfetch-popup1'>
                                       
                                        <label>
                                            <span>Created At</span>
                                            <input type="text" name="createdat" value={hotspotTestFormEditData.createdat} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Modified By</span>
                                            <input type="text" name="modifiedby" value={hotspotTestFormEditData.modifiedby} onChange={handleEditChange} disabled/>
                                        </label>
                                    </div>
                                    <div id='hotspot_test_formfetch-popup1'>
                                        
                                        <label>
                                            <span>Modified At</span>
                                            <input type="text" name="modifiedat" value={hotspotTestFormEditData.modifiedat} onChange={handleEditChange} disabled />
                                        </label>
                                    </div> */}
                                    <button id='hotspot_test_formfetch-popup-update' type="submit">Update</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Hotspot_Test_Formfetch;
