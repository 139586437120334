
import React, { useState, useEffect } from 'react';
import './Voltageregulator.css';
import axios from 'axios';
import { IoIosAddCircle } from "react-icons/io";
import { IoIosRemoveCircle } from "react-icons/io";
import { Voltagevoltagefluctuationsheader } from '../../../Operator/Data';
import { Link, useParams , useNavigate} from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import { getCurrentDateTime } from '../../../Diagnose/dateUtils';
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const Voltageregulator = () => {

  const { token_id } = useParams();
  const navigate = useNavigate();
  const [regulators, setRegulators] = useState([{ voltage_regulatorname: '', voltage_value: '' }]);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [userDesignation, setUserDesignation] = useState('');
  useEffect(() => {
    // Retrieve the logged-in user from local storage
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.username) {
      setLoggedInUser(user);
    }
    setUserDesignation(user.level);
  }, []);

  const handleAdd = () => {
    setRegulators([...regulators, { voltage_regulatorname: '', voltage_value: '' }]);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newRegulators = [...regulators];
    newRegulators[index][name] = value;
    setRegulators(newRegulators);
  };

  const handleCancel = (index) => {
    const newRegulators = regulators.filter((_, i) => i !== index);
    setRegulators(newRegulators);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!loggedInUser || !loggedInUser.username) {
      alert('User not logged in');
      return;
    }


    try {
      const dataToSend = regulators.map(regulator => ({
        dateandtime: getCurrentDateTime(),
        token_id: token_id,
        createdby: loggedInUser.username, // Attach the logged-in user's username
        createdat: getCurrentDateTime(),
        modifiedby: '',
        modifiedat: '',
        voltage_regulatorname: regulator.voltage_regulatorname,
        voltage_value: Number(regulator.voltage_value),
        spare1: '',
        spare2: ''
      }));

      const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/voltage_regulators`, dataToSend);

      console.log('Data posted successfully:', response.data);

      alert('Voltage regulator data added successfully');
      navigate('/Voltageregulatorfetch');
      setRegulators([{ voltage_regulatorname: '', voltage_value: '' }]);
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  };

  return (


    <div id='voltageregulator-total'>

      <div>
        {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />}
      </div>
      <div id='voltageregulator-right'>

        <div id='voltageregulator-right-1'>
          <div id='voltageregulator-heading'>
            {Voltagevoltagefluctuationsheader.alertHeading}
          </div>
          <div id='voltageregulator-heading1'>
            {Voltagevoltagefluctuationsheader.alertActions.map((action, index) => (
              <button key={index} aria-label={action.label}>
                {action.icon}
              </button>
            ))}
            <img src={Voltagevoltagefluctuationsheader.logoSrc} alt='logo' />
          </div>
        </div>
        <h3 id='voltageregulator-h3'>{Voltagevoltagefluctuationsheader.subalertHeading}</h3>
        <div id='voltageregulator-right-2'>
          <form onSubmit={handleSubmit}>
            <div id='voltageregulator-divtop'>
              <label >
                <span>Current voltage regulator</span>
                {/* <span>#123456</span> */}
              </label>
              {/* <label>
                <Link to='/Voltageregulatorfetch'><button type="button">View Data</button></Link>
              </label> */}
            </div>


            {regulators.map((regulator, index) => (
              <div key={index} id='voltageregulator-row'>
                <label>
                  <span >Voltage Regulator Name<span id='all-mandetorymark'>*</span></span>
                  <input
                    name="voltage_regulatorname"
                    value={regulator.voltage_regulatorname}
                    onChange={(e) => handleChange(index, e)}
                    required
                  />
                </label>
                <label>
                  <span>Voltage<span id='all-mandetorymark'>*</span></span>
                  <input
                    name="voltage_value"
                    value={regulator.voltage_value}
                    onChange={(e) => handleChange(index, e)}
                    required
                  />
                </label>
                {index !== 0 && (
                  <button id='voltageregulator-cancelbtn' type="button" onClick={() => handleCancel(index)}><IoIosRemoveCircle id='reg-btn' /></button>
                )}
                {index === regulators.length - 1 && (
                  <button id='voltageregulator-cancelbtn' type="button" onClick={handleAdd}><IoIosAddCircle id='reg-btn' /></button>
                )}

              </div>
            ))}

            <label>
              <button id='voltageregulator-submit' type="submit">Submit</button>
            </label>
          </form>
        </div>

      </div>

    </div>
  );
};

export default Voltageregulator;
