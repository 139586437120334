

import React, { useState, useEffect } from 'react';
import './Fixedvariables.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Fixedvariables = () => {
    const [formData, setFormData] = useState({
        "plantname": '',
        "address": '',
        "typeofindustry": '',
        "energyintesity":'',
        "Contracted_max_demand_(CMD)": '',
        "Multiplying_actor_(MF)": '',
        date: '',
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [dataId, setDataId] = useState(null);

    useEffect(() => {
        // Fetch data from the API when the component mounts
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/fixed-variables`);
                const data = await response.json();
                if (data.length > 0) {
                    setFormData(data[0]);
                    setDataId(data[0]._id); 
                    setIsSubmitted(true);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/fixed-variables`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...formData, date: new Date().toISOString() })
            });
            if (response.ok) {
                const result = await response.json();
                setIsSubmitted(true);
                setDataId(result.insertedId); // Assuming the response contains the inserted ID
                toast.success('Basic Plant details added successfully');
            } else {
                toast.error('Error submitting form');
            }
        } catch (error) {
            toast.error('Error submitting form');
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        console.log('Updating data with ID:', dataId);

        const { _id, ...updateData } = formData;

        console.log('Form data:', updateData);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/fixed-variables/${dataId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...updateData, date: new Date().toISOString() })
            });
            if (response.ok) {
                setIsEditing(false);
                toast.success('Basic Plant details updated successfully');
            } else {
                const errorText = await response.text();
                console.error('Error response:', errorText);
                toast.error('Error updating form');
            }
        } catch (error) {
            console.error('Error updating form:', error);
            toast.error('Error updating form');
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };


    // const [options, setOptions] = useState([]);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-Names`);
    //             const data = await response.json();
    //             setOptions(data);
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     };

    //     fetchData();
    // }, []);

    return (
        <div id='fixedvariables-total'>
            <div id='fixedvariables-right'>
                <div id='fixedvariables-right-2'>
                    <form onSubmit={isEditing ? handleUpdate : handleSubmit}>
                        <div id='fixedvariables-divtop'>
                            <label>
                                <span> {Masterformsheadingsss.TariffHeading}</span>
                            </label>
                        </div>
                        <div id='fixedvariables-div1'>
                            <label>
                                <span>Updated Date</span>
                                <input
                                    type='date'
                                    value={formData.date ? formData.date.split('T')[0] : ''}
                                    disabled
                                />
                            </label>
                            <label>
                                <span>Plant Name</span>
                                <input
                                    name="plantname"
                                    value={formData.plantname}
                                    onChange={handleChange}
                                    disabled={isSubmitted && !isEditing}
                                />
                            </label>
                        </div>
                        <div id='fixedvariables-div1'>
                            <label>
                                <span>Location/ Address</span>
                                <input
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    disabled={isSubmitted && !isEditing}
                                />
                            </label>
                            <label>
                                <span>Type of Industry</span>
                                <input
                                    name="typeofindustry"
                                    value={formData.typeofindustry}
                                    onChange={handleChange}
                                    disabled={isSubmitted && !isEditing}
                                />
                            </label>
                        </div>
                        <div id='fixedvariables-div1'>
                            <label>
                                <span>Contracted max demand (CMD)</span>
                                <input
                                    name="Contracted_max_demand_(CMD)"
                                    value={formData["Contracted_max_demand_(CMD)"]}
                                    onChange={handleChange}
                                    disabled={isSubmitted && !isEditing}
                                />
                            </label>
                            <label>
                                <span>Multiplying Factor (MF)</span>
                                <input
                                    name="Multiplying_actor_(MF)"
                                    value={formData["Multiplying_actor_(MF)"]}
                                    onChange={handleChange}
                                    disabled={isSubmitted && !isEditing}
                                />
                            </label>
                             </div>
                             <div id='fixedvariables-div1'>
                            <label>
                                <span>Energy Intesity</span>
                                <input
                                    name="energyintesity"
                                    value={formData.energyintesity}
                                    onChange={handleChange}
                                    disabled={isSubmitted && !isEditing}
                                />
                            </label>
                            
                             </div>
                        <div id='fixedvariables-divbottom'>
                            
                                {!isSubmitted && <button id='fixedvariables-save' type="submit"> {Masterformsheadingsss.Submitheading}</button>}
                                {isSubmitted && !isEditing && <button id='fixedvariables-save' type="button" onClick={handleEdit}>Edit</button>}
                                {isSubmitted && isEditing && <button id='fixedvariables-save' type="submit"> {Masterformsheadingsss.Updateheading}</button>}
                     
                        </div>

                    </form>
                </div>
            </div>
        </div>
    );
};

export default Fixedvariables;

