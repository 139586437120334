


import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';
import './DashTransformerhealth.css';

const DashTransformerhealth = ({ selectedMonth }) => {
    const [lossdata, setLossData] = useState([]);
    const chartRef = useRef(null); 

    useEffect(() => {
        const fetchLossData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/transformerloss_percentage`);
                const filteredData = response.data.filter(item => {
                    // Extract the month from the date
                    const itemMonth = new Date(item.date).toISOString().slice(0, 7); 
                    return itemMonth === selectedMonth;
                });
                setLossData(filteredData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchLossData();
    }, [selectedMonth]);

    useEffect(() => {
        if (chartRef.current) {

            const myChart = echarts.init(chartRef.current);


            const dates = lossdata.map(item => new Date(item.date).toLocaleDateString());
            const transformerLoss = lossdata.map(item => item["Transformer_Loss_%"] !== null ? item["Transformer_Loss_%"] : '-');
            const transformerEfficiency = lossdata.map(item => item["Transformer_Efficiency_%"] !== null ? item["Transformer_Efficiency_%"] : '-');
            const oilTemp = lossdata.map(item => item.oilTemp !== null ? item.oilTemp : '-');
            const windingTemp = lossdata.map(item => item.windingTemp !== null ? item.windingTemp : '-');

            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: ['Transformer Loss %', 'Transformer Efficiency %', 'Oil Temp (°C)', 'Winding Temp (°C)'],
                },
                xAxis: {
                    type: 'category',
                    data: dates,
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLabel: { interval: 0, rotate: 60 }
                },
                yAxis: [
                    {
                        type: 'value',
                        name: 'Efficiency & Loss (%)',
                        axisLabel: {
                            formatter: '{value} %'
                        }
                    },
                    {
                        type: 'value',
                        name: 'Temperature',
                        axisLabel: {
                            formatter: '{value} °C'
                        }
                    }
                ],
                series: [
                    {
                        name: 'Transformer Loss %',
                        type: 'bar',
                        data: transformerLoss,
                        itemStyle: {
                            color: '#EA4335' 
                        }
                    },
                    {
                        name: 'Transformer Efficiency %',
                        type: 'bar',
                        data: transformerEfficiency,
                        itemStyle: {
                            color: 'green' 
                        }
                    },
                    {
                        name: 'Oil Temp (°C)',
                        type: 'line',
                        yAxisIndex: 1,
                        data: oilTemp,
                        itemStyle: {
                            color: 'orange' 
                        }
                    },
                    {
                        name: 'Winding Temp (°C)',
                        type: 'line',
                        yAxisIndex: 1,
                        data: windingTemp,
                        itemStyle: {
                              color: '#7E95DF'
                        }
                    }
                ]
            };

            myChart.setOption(option);

            return () => {
                myChart.dispose();
            };
        }
    }, [lossdata]);

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayString = yesterday.toLocaleDateString();

    const yesterdayData = lossdata.filter(item => new Date(item.date).toLocaleDateString() === yesterdayString);

    return (
       
        <div id='dashtransformerhealth-total'>
            <span style={{fontWeight:'bold', fontSize:'20px'}} >Transformer Health</span>
            {lossdata.length === 0 ? (
                <p className="no-data" style={{textAlign:'center'}}>Not Available</p>
            ) : (
                <>
                    <div id='dashtransformerhealth-div1'>
                        <div id='dashtransformerhealth-div2'>
                            {yesterdayData.map((item) => (
                                <div key={item._id} id='dashtransformerhealth-div21'>
                                    <label>Tap Position (Yesterday)* :</label>
                                    <p>{item.Tapposition !== null ? item.Tapposition : 'N/A'}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div id="dashtransformerhealth-barchart" ref={chartRef} />
                </>
            )}
        </div>

    );
};

export default DashTransformerhealth;
