
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './Utilization.css'
// import { Link } from 'react-router-dom';

const DailyUtilization_Percenatage = () => {
    const [utilizationPercentage, setUtilizationPercentage] = useState(null);
    const [utilizationPercentagedate, setUtilizationPercentagedate] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/utilization_percentage/yesterday`);
                // Assuming the data is an array and the percentage is in the first item
                if (response.data.length > 0) {
                    setUtilizationPercentage(response.data[0]["Utilization_%"]);
                    setUtilizationPercentagedate(response.data[0]["date"]);
                }
            } catch (error) {
                console.error('Error fetching the data', error);
            }
        };

        fetchData();
    }, []);

    const displayValue = utilizationPercentage !== null ? utilizationPercentage : 'N/A';

    return (
        <div className="circular-progress-container">
            <div id='util-div1'>
                <span>Utilization (%)</span>
                {/* <Link to='/'> <button> view more </button></Link> */}

            </div>
            <div className='util-progressbar'>
                <CircularProgressbar
                    value={utilizationPercentage !== null ? utilizationPercentage : 0}
                    // text={`${utilizationPercentage}%`}
                    text={displayValue}
                    styles={buildStyles({
                        rotation: 0.00,
                        strokeLinecap: 'round',
                        textSize: '16px',
                        pathTransitionDuration: 0.5,
                        pathColor: utilizationPercentage !== null ? `rgba(32, 157, 37, ${utilizationPercentage / 100})` : '#D1EDD3',
                        // pathColor: `rgba(32, 157, 37, ${utilizationPercentage / 100})`,
                        textColor: 'black',
                        trailColor: '#D1EDD3',
                        backgroundColor: '#D1EDD3',
                    })}
                />
            </div>
            <div id="util-date-display">
              Last Updated :  {utilizationPercentagedate ? ` ${utilizationPercentagedate}` : 'N/A'} 
              <span> &nbsp; (24 Hours)</span>
            </div>

        </div>
    );
};

export default DailyUtilization_Percenatage;
