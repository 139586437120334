// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dashtransformerhealth-total {
    margin-top: 2vh;
    height: auto;
    background-color: white;
    border: 1px solid #DFE6FA;
    border-radius: 10px;
    padding: 15px;
}


#dashtransformerhealth-div1 {

    display: flex;
    text-align: center;
    justify-content:end ;
   
    span {
        font-weight: bold;
        font-size: 20px;
    }
    

}
#dashtransformerhealth-div2{

    #dashtransformerhealth-div21{
        margin-top: 3px;
        display: flex;
        align-items: center;
        p{
            /* margin-top: -5px; */
            height: 4vh;
            width: 4vw;
            padding: 2px;
            background-color: #F6F6F6;
            border: 1px solid #DFE6FA;
            border-radius: 6px;
            align-content: center;
        }
    }
   
}

#dashtransformerhealth-barchart {
    margin-top: 3vh;
    width: 95%;
    height: 400px;
}`, "",{"version":3,"sources":["webpack://./src/Components/SuperDashboards/Monthly-Dash/DashTransformerhealth/DashTransformerhealth.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;AACjB;;;AAGA;;IAEI,aAAa;IACb,kBAAkB;IAClB,oBAAoB;;IAEpB;QACI,iBAAiB;QACjB,eAAe;IACnB;;;AAGJ;AACA;;IAEI;QACI,eAAe;QACf,aAAa;QACb,mBAAmB;QACnB;YACI,sBAAsB;YACtB,WAAW;YACX,UAAU;YACV,YAAY;YACZ,yBAAyB;YACzB,yBAAyB;YACzB,kBAAkB;YAClB,qBAAqB;QACzB;IACJ;;AAEJ;;AAEA;IACI,eAAe;IACf,UAAU;IACV,aAAa;AACjB","sourcesContent":["#dashtransformerhealth-total {\n    margin-top: 2vh;\n    height: auto;\n    background-color: white;\n    border: 1px solid #DFE6FA;\n    border-radius: 10px;\n    padding: 15px;\n}\n\n\n#dashtransformerhealth-div1 {\n\n    display: flex;\n    text-align: center;\n    justify-content:end ;\n   \n    span {\n        font-weight: bold;\n        font-size: 20px;\n    }\n    \n\n}\n#dashtransformerhealth-div2{\n\n    #dashtransformerhealth-div21{\n        margin-top: 3px;\n        display: flex;\n        align-items: center;\n        p{\n            /* margin-top: -5px; */\n            height: 4vh;\n            width: 4vw;\n            padding: 2px;\n            background-color: #F6F6F6;\n            border: 1px solid #DFE6FA;\n            border-radius: 6px;\n            align-content: center;\n        }\n    }\n   \n}\n\n#dashtransformerhealth-barchart {\n    margin-top: 3vh;\n    width: 95%;\n    height: 400px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
