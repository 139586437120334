import { Box, Divider, Typography } from "@mui/material";
import { Col, Row } from "antd";
import React from "react";
import DoughnutChartWithLabels from "../Charts/Alerts/AlertsChart";
import "./LossesWithAlerts.css";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import { useTranslation } from "react-i18next";

const LossesWithAlerts = ({
  title = "",
  alertsData = {},
  energyLoss,
  revenueLoss,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography className="energy-intensity" sx={{ px: 5, pt: 3 }}>
        {title}:
      </Typography>

      <Row align={"top"} justify={"center"}>
        <Col
          xs={24}
          sm={7}
          style={{
            padding: "20px",
          }}
        >
          <Box sx={{ width: "100%", height: "100%" }}>
            <Box
              sx={{
                px: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: 300,
              }}
            >
              <Typography
                sx={{ fontSize: 20, alignSelf: "flex-start" }}
                className="energy-intensity-actual"
              >
                {t("weeklyReport.energyLoss")}
              </Typography>

              <Box
                sx={{
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: 0,
                  }}
                >
                  <Typography className="total-loss-value-cri">
                    {energyLoss?.value}
                  </Typography>

                  {energyLoss?.value !== 0 && (
                    <>
                      {energyLoss?.flag ? (
                        <ArrowDropDownOutlinedIcon
                          sx={{ color: "#F03840", fontSize: "100px",}}
                        />
                      ) : (
                        <ArrowDropUpOutlinedIcon
                          sx={{ color: "#36A642", fontSize: "100px", }}
                        />
                      )}
                    </>
                  )}
                </Box>
                <Typography className="energy-intensity-actual">
                 
                  {t("weeklyReport.kwh")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Col>

        <Divider
          orientation="vertical"
          flexItem
          sx={{
            borderColor: "#F2F1F1",
            borderWidth: "1px",
          }}
        />

        <Col
          xs={24}
          sm={7}
          style={{
            padding: "20px",
          }}
        >
          <Box sx={{ width: "100%", height: "100%" }}>
            <Box
              sx={{
                px: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: 300,
              }}
            >
              <Typography
                sx={{ fontSize: 20, alignSelf: "flex-start" }}
                className="energy-intensity-actual"
              >
               {t("weeklyReport.revenueLoss")}
              </Typography>

              <Box
                sx={{
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: 0,
                  }}
                >
                  <Typography className="total-loss-value-cri">
                    ₹ {revenueLoss?.value}
                  </Typography>

                  {revenueLoss?.value !==0 && (
                    <>
                      {revenueLoss?.flag ? (
                        <ArrowDropDownOutlinedIcon
                          sx={{ color: "#F03840", fontSize: "100px",  }}
                          // fontSize="large"
                        />
                      ) : (
                        <ArrowDropUpOutlinedIcon
                          sx={{ color: "#36A642", fontSize: "100px", }}
                          // fontSize="large"
                        />
                      )}
                    </>
                  )}
                </Box>

                <Typography className="energy-intensity-actual">
                  {t("weeklyReport.rupees")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Col>

        <Divider
          orientation="vertical"
          flexItem
          sx={{
            // mt: 5,
            borderColor: "#F2F1F1",
            borderWidth: "1px",
          }}
        />

        <Col
          xs={24}
          sm={7}
          style={{
            padding: "20px",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box sx={{ px: 2 }}>
              <Typography
                sx={{ fontSize: 20 }}
                className="energy-intensity-actual"
              >
                {t("weeklyReport.alerts")}
              </Typography>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <DoughnutChartWithLabels
                  totalAlerts={alertsData?.raised_alerts}
                  AlertsData={alertsData?.data}
                  labels={alertsData?.labels}
                  colors={["#FAA2A6", "#F03840"]}
                />
              </div>
            </Box>
          </Box>
        </Col>
      </Row>

      <Divider
        flexItem
        sx={{
          mt: 2,
          borderColor: "#F2F1F1",
          borderWidth: "1px",
        }}
      />
    </>
  );
};

export default LossesWithAlerts;
