// import React, { useEffect, useState } from 'react';
// import io from 'socket.io-client';
// import * as echarts from 'echarts';
// import { BarChart } from '@mui/x-charts/BarChart';
// import { LineChart } from '@mui/x-charts/LineChart';
// import Operatorsidebar from '../Operator/Sidebar/Sidebar';
// import { Livesummaryheader } from '../Operator/Data';
// import './Livesummary.css';
// import { FaChevronDown } from "react-icons/fa";
// import { FaChevronUp } from "react-icons/fa";

// function Livesummary() {
//     const [hvData, setHvData] = useState(null);
//     const [imaxValues, setImaxValues] = useState({});
//     const [mdData, setMdData] = useState([]);
//     const [timestamps, setTimestamps] = useState([]);
//     const [showLV1data, setShowLV1data] = useState(false);
//     const [showLV2data, setShowLV2data] = useState(false);
//     const [showProductiondata, setShowProductiondata] = useState(false);
//     const [showDG1data1, setShowDG1data] = useState(false);
//     const [showDG2data, setShowDG2data] = useState(false);

//     useEffect(() => {
//         const socket = io('http://localhost:5003');
//         socket.on('connect', () => {
//             console.log('WebSocket client connected');
//         });

//         socket.on('hv_data', (data) => {
//             console.log('Received hv_data:', data);
//             setHvData(data);
//             setMdData(prevMdData => [...prevMdData, data.MD]); // Accumulate MD values
//             setTimestamps(prevTimestamps => [...prevTimestamps, data.current_time]); // Accumulate timestamps
//         });

//         socket.on('imax_percentage_values', (data) => {
//             console.log('Received imax_values:', data);
//             setImaxValues(prevValues => ({ ...prevValues, ...data }));
//         });

//         return () => {
//             socket.disconnect();
//         };
//     }, []);

//     useEffect(() => {
//         console.log('MD Data:', mdData);
//         console.log('Timestamps:', timestamps);
//     }, [mdData, timestamps]);

//     useEffect(() => {
//         if (hvData) {
//             // Initialize the ECharts gauge chart
//             const chartDom = document.getElementById('gauge-chart');
//             const myChart = echarts.init(chartDom);

//             // Chart configuration
//             const option = {
//                 series: [
//                     {
//                         name: 'Gauge',
//                         type: 'gauge',
//                         min: -1,
//                         max: 1,
//                         splitNumber: 5,
//                         radius: '55%',
//                         axisLine: {
//                             lineStyle: {
//                                 width: 10,
//                                 color: [
//                                     [0.9, 'red'], 
//                                     [0.95, 'orange'], 
//                                     [1, 'green']
//                                 ],
//                             },
//                         },
//                         pointer: {
//                             width: 5,
//                         },
//                         detail: {
//                             fontSize: 23,
//                             formatter: '{value}',
//                         },
//                         data: [{ value: hvData.PF }],
//                     },
//                 ],
//             };

//             // Set the option to the chart
//             myChart.setOption(option);

//             // Resize the chart when window size changes
//             window.addEventListener('resize', myChart.resize);

//             return () => {
//                 window.removeEventListener('resize', myChart.resize);
//             };
//         }
//     }, [hvData]);

//     if (!hvData) {
//         return <div>Loading...</div>;
//     }

//     const barChartData = {
//         xAxis: [{ scaleType: 'band', data: ['Ir', 'Iy', 'Ib'] }],
//         series: [{ data: [hvData.Load.Ir, hvData.Load.Iy, hvData.Load.Ib], color: '#7E95DF' }]
//     };

//     const getProgressBarColor = (percentage) => {
//         if (percentage < 90) {
//             return 'green';
//         } else if (percentage >= 90 && percentage < 95) {
//             return 'orange';
//         } else if (percentage >= 95) {
//             return 'red';
//         }
//     };

//     const toggleLV1Data = () => {
//         setShowLV1data(!showLV1data);
//     };
//     const toggleLV2Data = () => {
//         setShowLV2data(!showLV2data);
//     };
//     const toggleProductionData = () => {
//         setShowProductiondata(!showProductiondata);
//     };
//     const toggleDG1Data1 = () => {
//         setShowDG1data(!showDG1data1);
//     };
//     const toggleDG2Data = () => {
//         setShowDG2data(!showDG2data);
//     };


//     return (
//         <div id='livesummary-total'>
//             <div>
//                 <Operatorsidebar />
//             </div>
//             <div id='livesummary-right'>
//                 <div id='livesummary-right-1'>
//                     <div id='livesummary-heading'>
//                         {Livesummaryheader.alertHeading}
//                     </div>
//                     <div id='livesummary-heading1'>
//                         {Livesummaryheader.alertActions.map((action, index) => (
//                             <button key={index} aria-label={action.label}>
//                                 {action.icon}
//                             </button>
//                         ))}
//                         <img src={Livesummaryheader.logoSrc} alt='logo' />
//                     </div>
//                 </div>

//                 <div id='livesummary-right-2'>
//                     <div id='livesummary-right-21'>
//                         <div id='livesummary-right-22'>
//                             <div>
//                                 <p>Units Consumed(KVAh)</p>
//                                 <p>(Today)</p>
//                                 <h3  style={{fontSize:'50px'}}>{hvData.Energy_Consumption}</h3>
//                             </div>
//                             <div>
//                                 <p style={{marginLeft:'50px'}}>Load(Amps)</p>
//                                 <BarChart
//                                     xAxis={barChartData.xAxis}
//                                     series={barChartData.series}
//                                     width={220}
//                                     height={200}
//                                 />
//                             </div>
//                             <div>
//                                 <p style={{marginLeft:'95px'}}>Power Factor</p>
//                                 <div id="gauge-chart" style={{ width: 300, height: 300 }}></div>
//                             </div>
//                             <div>
//                                 <p style={{marginLeft:'70px'}}>Demand(KVA)</p>
//                                 <LineChart
//                                     xAxis={[{ data: mdData.map((_, index) => index + 1) }]} // Use indices as X-axis labels
//                                     series={[
//                                         {
//                                             data: mdData,
//                                             area: true,
//                                             color: '#7E95DF',
//                                         },
//                                     ]}
//                                     width={300}
//                                     height={200}
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                     <div>
//                     </div>
//                 </div>

//                 <div id='livesummary-right-3'>
//                     <div id='livesummary-right-31'>
//                         <div id='livesummary-right-headflex1'>
//                             <div id='livesummary-right-headflex12'> <span> Load :&lt; 90 <span id='btn1'></span> </span></div>
//                             <div id='livesummary-right-headflex12'> <span> Load : &gt;= 90 to 95  <span id='btn2'></span> </span></div>
//                             <div id='livesummary-right-headflex12'> <span> Load : &gt; 95  <span id='btn3'></span> </span></div>
//                         </div>
//                         <div id='livesummary-right-headflex'>
//                             <div id='livesummary-sectionname'>Section Name</div>
//                             <div id='livesummary-loadbar'>Load Bar </div>
//                             <div id='livesummary-threshold'>Rated Value</div>
//                         </div>

//                         <div className="section-container">
//                             {Object.entries(imaxValues).map(([section, values]) => {
//                                 if (section === 'HV') {
//                                     return (
//                                         <div className="section" key={section}>
//                                             <div className="section-name">{section}</div>

//                                             <div className={`progress-bar-container ${getProgressBarColor(values.percentage)}`}>
//                                                 <div
//                                                     className={` progress-bar-5 ${getProgressBarColor(values.percentage)}`}
//                                                     style={{ width: `${values.percentage}%` }}
//                                                 >
//                                                     {values.percentage}%
//                                                 </div>

//                                             </div>

//                                             <div className="threshold">{values.threshold}</div>
//                                         </div>
//                                     );
//                                 }
//                                 return null;
//                             })}
//                             {Object.entries(imaxValues).map(([section, values]) => {
//                                 if (section === 'Solar-1') {
//                                     return (
//                                         <div className="section" key={section}>
//                                             <div className="section-name">{section}</div>
//                                             <div className={`progress-bar-container ${getProgressBarColor(values.percentage)}`}>
//                                                 <div
//                                                     className={`progress-bar ${getProgressBarColor(values.percentage)}`}
//                                                     style={{ width: `${values.percentage}%` }}
//                                                 >
//                                                     {values.percentage}%
//                                                 </div>
//                                             </div>
//                                             <div className="threshold">{values.threshold}</div>
//                                         </div>
//                                     );
//                                 }
//                                 return null;
//                             })}
//                             {Object.entries(imaxValues).map(([section, values]) => {
//                                 if (section === 'Solar-2') {
//                                     return (
//                                         <div className="section" key={section}>
//                                             <div className="section-name">{section}</div>
//                                             <div className={`progress-bar-container ${getProgressBarColor(values.percentage)}`}>
//                                                 <div
//                                                     className={`progress-bar ${getProgressBarColor(values.percentage)}`}
//                                                     style={{ width: `${values.percentage}%` }}
//                                                 >
//                                                     {values.percentage}%
//                                                 </div>
//                                             </div>
//                                             <div className="threshold">{values.threshold}</div>
//                                         </div>
//                                     );
//                                 }
//                                 return null;
//                             })}
//                             {Object.entries(imaxValues).map(([section, values]) => {
//                                 if (section === 'WHR-1') {
//                                     return (
//                                         <div className="section" key={section}>
//                                             <div className="section-name">{section}</div>
//                                             <div className={`progress-bar-container ${getProgressBarColor(values.percentage)}`}>
//                                                 <div
//                                                     className={`progress-bar ${getProgressBarColor(values.percentage)}`}
//                                                     style={{ width: `${values.percentage}%` }}
//                                                 >
//                                                     {values.percentage}%
//                                                 </div>
//                                             </div>
//                                             <div className="threshold">{values.threshold}</div>
//                                         </div>
//                                     );
//                                 }
//                                 return null;
//                             })}
//                             {Object.entries(imaxValues).map(([section, values]) => {
//                                 if (section === 'WHR-2') {
//                                     return (
//                                         <div className="section" key={section}>
//                                             <div className="section-name">{section}</div>
//                                             <div className={`progress-bar-container ${getProgressBarColor(values.percentage)}`}>
//                                                 <div
//                                                     className={`progress-bar ${getProgressBarColor(values.percentage)}`}
//                                                     style={{ width: `${values.percentage}%` }}
//                                                 >
//                                                     {values.percentage}%
//                                                 </div>
//                                             </div>
//                                             <div className="threshold">{values.threshold}</div>
//                                         </div>
//                                     );
//                                 }
//                                 return null;
//                             })}
//                             {Object.entries(imaxValues).map(([section, values]) => {
//                                 if (section === 'LV-1') {
//                                     return (
//                                         <div className="section" key={section}>
//                                             <div className="section-name">{section} <button id='livesummary-dropbtn' onClick={toggleLV1Data}>{showLV1data ? <FaChevronUp /> : <FaChevronDown />}</button></div>
//                                             <div className={`progress-bar-container ${getProgressBarColor(values.percentage)}`}>
//                                                 <div
//                                                     className={`progress-bar ${getProgressBarColor(values.percentage)}`}
//                                                     style={{ width: `${values.percentage}%` }}
//                                                 >
//                                                     {values.percentage}%
//                                                 </div>
//                                             </div>
//                                             <div className="threshold">{values.threshold}</div>
//                                         </div>
//                                     );
//                                 }
//                                 return null;
//                             })}
//                             <div>
//                                 {showLV1data && (
//                                     Object.entries(imaxValues).map(([section, values]) => {
//                                         if (section === 'Colony') {
//                                             return (
//                                                 <div className="section-1" key={section}>
//                                                     <div className="section-name-1">{section}</div>
//                                                     <div className={`progress-bar-container-1 ${getProgressBarColor(values.percentage)}`}>
//                                                         <div
//                                                             className={`progress-bar-1 ${getProgressBarColor(values.percentage)}`}
//                                                             style={{ width: `${values.percentage}%` }}
//                                                         >
//                                                             {values.percentage}%
//                                                         </div>
//                                                     </div>
//                                                     <div className="threshold-1">{values.threshold}</div>
//                                                 </div>
//                                             );
//                                         }
//                                         return null;
//                                     })
//                                 )}
//                                 <div>
//                                     {showLV1data && (
//                                         Object.entries(imaxValues).map(([section, values]) => {
//                                             if (section === 'Compressor') {
//                                                 return (
//                                                     <div className="section-2" key={section}>
//                                                         <div className="section-name-2">{section}</div>
//                                                         <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
//                                                             <div
//                                                                 className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
//                                                                 style={{ width: `${values.percentage}%` }}
//                                                             >
//                                                                 {values.percentage}%
//                                                             </div>
//                                                         </div>
//                                                         <div className="threshold-2">{values.threshold}</div>
//                                                     </div>
//                                                 );
//                                             }
//                                             return null;
//                                         })
//                                     )}
//                                 </div>
//                                 {showLV1data && (
//                                     Object.entries(imaxValues).map(([section, values]) => {
//                                         if (section === 'APFC-1') {
//                                             return (
//                                                 <div className="section-2" key={section}>
//                                                     <div className="section-name-2">{section}</div>
//                                                     <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
//                                                         <div
//                                                             className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
//                                                             style={{ width: `${values.percentage}%` }}
//                                                         >
//                                                             {values.percentage}%
//                                                         </div>
//                                                     </div>
//                                                     <div className="threshold-2">{values.threshold}</div>
//                                                 </div>
//                                             );
//                                         }
//                                         return null;
//                                     })
//                                 )}
//                             </div>

//                             {Object.entries(imaxValues).map(([section, values]) => {
//                                 if (section === 'LV-2') {
//                                     return (
//                                         <div className="section" key={section}>
//                                             <div className="section-name">{section} <button id='livesummary-dropbtn' onClick={toggleLV2Data}>{showLV2data ? <FaChevronUp /> : <FaChevronDown />}</button></div>
//                                             <div className={`progress-bar-container ${getProgressBarColor(values.percentage)}`}>
//                                                 <div
//                                                     className={`progress-bar ${getProgressBarColor(values.percentage)}`}
//                                                     style={{ width: `${values.percentage}%` }}
//                                                 >
//                                                     {values.percentage}%
//                                                 </div>
//                                             </div>
//                                             <div className="threshold">{values.threshold}</div>
//                                         </div>
//                                     );
//                                 }
//                                 return null;
//                             })}
//                             <div>
//                                 {showLV2data && (
//                                     Object.entries(imaxValues).map(([section, values]) => {
//                                         if (section === 'ENGG store Panel') {
//                                             return (
//                                                 <div className="section-1" key={section}>
//                                                     <div className="section-name-1">{section}</div>
//                                                     <div className={`progress-bar-container-1 ${getProgressBarColor(values.percentage)}`}>
//                                                         <div
//                                                             className={`progress-bar-1 ${getProgressBarColor(values.percentage)}`}
//                                                             style={{ width: `${values.percentage}%` }}
//                                                         >
//                                                             {values.percentage}%
//                                                         </div>
//                                                     </div>
//                                                     <div className="threshold-1">{values.threshold}</div>
//                                                 </div>
//                                             );
//                                         }
//                                         return null;
//                                     })
//                                 )}
//                                 <div>
//                                     {showLV2data && (
//                                         Object.entries(imaxValues).map(([section, values]) => {
//                                             if (section === 'Unit-5') {
//                                                 return (
//                                                     <div className="section-2" key={section}>
//                                                         <div className="section-name-2">{section}</div>
//                                                         <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
//                                                             <div
//                                                                 className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
//                                                                 style={{ width: `${values.percentage}%` }}
//                                                             >
//                                                                 {values.percentage}%
//                                                             </div>
//                                                         </div>
//                                                         <div className="threshold-2">{values.threshold}</div>
//                                                     </div>
//                                                 );
//                                             }
//                                             return null;
//                                         })
//                                     )}
//                                 </div>
//                                 {showLV2data && (
//                                     Object.entries(imaxValues).map(([section, values]) => {
//                                         if (section === 'AR&D') {
//                                             return (
//                                                 <div className="section-2" key={section}>
//                                                     <div className="section-name-2">{section}</div>
//                                                     <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
//                                                         <div
//                                                             className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
//                                                             style={{ width: `${values.percentage}%` }}
//                                                         >
//                                                             {values.percentage}%
//                                                         </div>
//                                                     </div>
//                                                     <div className="threshold-2">{values.threshold}</div>
//                                                 </div>
//                                             );
//                                         }
//                                         return null;
//                                     })
//                                 )}
//                                 {showLV2data && (
//                                     Object.entries(imaxValues).map(([section, values]) => {
//                                         if (section === 'Production') {
//                                             return (
//                                                 <div className="section-2" key={section} >
//                                                     <div className="section-name-2">{section}  <button id='livesummary-dropbtn3' onClick={toggleProductionData}>{showProductiondata ? <FaChevronUp /> : <FaChevronDown />} </button> </div>
//                                                     <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
//                                                         <div
//                                                             className={`progress-bar ${getProgressBarColor(values.percentage)}`}
//                                                             style={{ width: `${values.percentage}%` }}
//                                                         >
//                                                             {values.percentage}%
//                                                         </div>
//                                                     </div>
//                                                     <div className="threshold-2">{values.threshold}</div>
//                                                 </div>
//                                             );
//                                         }
//                                         return null;
//                                     })
//                                 )}
//                                 {/* -----------------------Production------------------------------ */}
//                                 {showProductiondata && (
//                                     Object.entries(imaxValues).map(([section, values]) => {
//                                         if (section === 'Solar-1') {
//                                             return (
//                                                 <div className="section-pro" key={section} >
//                                                     <div className="section-name-pro">{section} </div>
//                                                     <div className={`progress-bar-container-pro ${getProgressBarColor(values.percentage)}`}>
//                                                         <div
//                                                             className={`progress-bar-pro ${getProgressBarColor(values.percentage)}`}
//                                                             style={{ width: `${values.percentage}%` }}
//                                                         >
//                                                             {values.percentage}%
//                                                         </div>
//                                                     </div>
//                                                     <div className="threshold-pro">{values.threshold}</div>
//                                                 </div>
//                                             );
//                                         }
//                                         return null;
//                                     })
//                                 )}
//                                 {/* -----------------------Production------------------------------ */}
//                                 {showLV2data && (
//                                     Object.entries(imaxValues).map(([section, values]) => {
//                                         if (section === 'APFC-2') {
//                                             return (
//                                                 <div className="section-2" key={section}>
//                                                     <div className="section-name-2">{section}</div>
//                                                     <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
//                                                         <div
//                                                             className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
//                                                             style={{ width: `${values.percentage}%` }}
//                                                         >
//                                                             {values.percentage}%
//                                                         </div>
//                                                     </div>
//                                                     <div className="threshold-2">{values.threshold}</div>
//                                                 </div>
//                                             );
//                                         }
//                                         return null;
//                                     })
//                                 )}
//                                 {showLV2data && (
//                                     Object.entries(imaxValues).map(([section, values]) => {
//                                         if (section === 'spare-1') {
//                                             return (
//                                                 <div className="section-2" key={section}>
//                                                     <div className="section-name-2">{section}</div>
//                                                     <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
//                                                         <div
//                                                             className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
//                                                             style={{ width: `${values.percentage}%` }}
//                                                         >
//                                                             {values.percentage}%
//                                                         </div>
//                                                     </div>
//                                                     <div className="threshold-2">{values.threshold}</div>
//                                                 </div>
//                                             );
//                                         }
//                                         return null;
//                                     })
//                                 )}
//                                 {showLV2data && (
//                                     Object.entries(imaxValues).map(([section, values]) => {
//                                         if (section === 'spare-2') {
//                                             return (
//                                                 <div className="section-2" key={section}>
//                                                     <div className="section-name-2">{section}</div>
//                                                     <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
//                                                         <div
//                                                             className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
//                                                             style={{ width: `${values.percentage}%` }}
//                                                         >
//                                                             {values.percentage}%
//                                                         </div>
//                                                     </div>
//                                                     <div className="threshold-2">{values.threshold}</div>
//                                                 </div>
//                                             );
//                                         }
//                                         return null;
//                                     })
//                                 )}
//                                 {/* ---------DG-1-------------- */}
//                                 {Object.entries(imaxValues).map(([section, values]) => {
//                                     if (section === 'DG Grid Incomer -1') {
//                                         return (
//                                             <div className="section" key={section}>
//                                                 <div className="section-name">{section} <button id='livesummary-dropbtn2' onClick={toggleDG1Data1}>{showDG1data1 ? <FaChevronUp /> : <FaChevronDown />}</button></div>
//                                                 <div className={`progress-bar-container ${getProgressBarColor(values.percentage)}`}>
//                                                     <div
//                                                         className={`progress-bar ${getProgressBarColor(values.percentage)}`}
//                                                         style={{ width: `${values.percentage}%` }}
//                                                     >
//                                                         {values.percentage}%
//                                                     </div>
//                                                 </div>
//                                                 <div className="threshold">{values.threshold}</div>
//                                             </div>
//                                         );
//                                     }
//                                     return null;
//                                 })}
//                                 <div>
//                                     {showDG1data1 && (
//                                         Object.entries(imaxValues).map(([section, values]) => {
//                                             if (section === 'DG Incomer -1') {
//                                                 return (
//                                                     <div className="section-1" key={section}>
//                                                         <div className="section-name-1">{section}</div>
//                                                         <div className={`progress-bar-container-1 ${getProgressBarColor(values.percentage)}`}>
//                                                             <div
//                                                                 className={`progress-bar-1 ${getProgressBarColor(values.percentage)}`}
//                                                                 style={{ width: `${values.percentage}%` }}
//                                                             >
//                                                                 {values.percentage}%
//                                                             </div>
//                                                         </div>
//                                                         <div className="threshold-1">{values.threshold}</div>
//                                                     </div>
//                                                 );
//                                             }
//                                             return null;
//                                         })
//                                     )}
//                                     <div>
//                                         {showDG1data1 && (
//                                             Object.entries(imaxValues).map(([section, values]) => {
//                                                 if (section === 'DG Incomer -2') {
//                                                     return (
//                                                         <div className="section-2" key={section}>
//                                                             <div className="section-name-2">{section}</div>
//                                                             <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
//                                                                 <div
//                                                                     className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
//                                                                     style={{ width: `${values.percentage}%` }}
//                                                                 >
//                                                                     {values.percentage}%
//                                                                 </div>
//                                                             </div>
//                                                             <div className="threshold-2">{values.threshold}</div>
//                                                         </div>
//                                                     );
//                                                 }
//                                                 return null;
//                                             })
//                                         )}
//                                     </div>
//                                     {showDG1data1 && (
//                                         Object.entries(imaxValues).map(([section, values]) => {
//                                             if (section === 'DG Incomer -3') {
//                                                 return (
//                                                     <div className="section-2" key={section}>
//                                                         <div className="section-name-2">{section}</div>
//                                                         <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
//                                                             <div
//                                                                 className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
//                                                                 style={{ width: `${values.percentage}%` }}
//                                                             >
//                                                                 {values.percentage}%
//                                                             </div>
//                                                         </div>
//                                                         <div className="threshold-2">{values.threshold}</div>
//                                                     </div>
//                                                 );
//                                             }
//                                             return null;
//                                         })
//                                     )}
//                                 </div>

//                                 {/* ---------DG-2-------------- */}
//                                 {Object.entries(imaxValues).map(([section, values]) => {
//                                     if (section === 'DG Grid Incomer -2') {
//                                         return (
//                                             <div className="section" key={section}>
//                                                 <div className="section-name">{section} <button id='livesummary-dropbtn2' onClick={toggleDG2Data}>{showDG2data ? <FaChevronUp /> : <FaChevronDown />}</button></div>
//                                                 <div className={`progress-bar-container ${getProgressBarColor(values.percentage)}`}>
//                                                     <div
//                                                         className={`progress-bar ${getProgressBarColor(values.percentage)}`}
//                                                         style={{ width: `${values.percentage}%` }}
//                                                     >
//                                                         {values.percentage}%
//                                                     </div>
//                                                 </div>
//                                                 <div className="threshold">{values.threshold}</div>
//                                             </div>
//                                         );
//                                     }
//                                     return null;
//                                 })}
//                                 <div>
//                                     {showDG2data && (
//                                         Object.entries(imaxValues).map(([section, values]) => {
//                                             if (section === 'DG Incomer 4') {
//                                                 return (
//                                                     <div className="section-1" key={section}>
//                                                         <div className="section-name-1">{section}</div>
//                                                         <div className={`progress-bar-container-1 ${getProgressBarColor(values.percentage)}`}>
//                                                             <div
//                                                                 className={`progress-bar-1 ${getProgressBarColor(values.percentage)}`}
//                                                                 style={{ width: `${values.percentage}%` }}
//                                                             >
//                                                                 {values.percentage}%
//                                                             </div>
//                                                         </div>
//                                                         <div className="threshold-1">{values.threshold}</div>
//                                                     </div>
//                                                 );
//                                             }
//                                             return null;
//                                         })
//                                     )}
//                                     <div>
//                                         {showDG2data && (
//                                             Object.entries(imaxValues).map(([section, values]) => {
//                                                 if (section === 'DG Incomer 5') {
//                                                     return (
//                                                         <div className="section-2" key={section}>
//                                                             <div className="section-name-2">{section}</div>
//                                                             <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
//                                                                 <div
//                                                                     className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
//                                                                     style={{ width: `${values.percentage}%` }}
//                                                                 >
//                                                                     {values.percentage}%
//                                                                 </div>
//                                                             </div>
//                                                             <div className="threshold-2">{values.threshold}</div>
//                                                         </div>
//                                                     );
//                                                 }
//                                                 return null;
//                                             })
//                                         )}
//                                     </div>
//                                     {showDG2data && (
//                                         Object.entries(imaxValues).map(([section, values]) => {
//                                             if (section === 'DG Incomer 6') {
//                                                 return (
//                                                     <div className="section-2" key={section}>
//                                                         <div className="section-name-2">{section}</div>
//                                                         <div className={`progress-bar-container-2 ${getProgressBarColor(values.percentage)}`}>
//                                                             <div
//                                                                 className={`progress-bar-2 ${getProgressBarColor(values.percentage)}`}
//                                                                 style={{ width: `${values.percentage}%` }}
//                                                             >
//                                                                 {values.percentage}%
//                                                             </div>
//                                                         </div>
//                                                         <div className="threshold-2">{values.threshold}</div>
//                                                     </div>
//                                                 );
//                                             }
//                                             return null;
//                                         })
//                                     )}
//                                 </div>
//                             </div>
//                         </div>

//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Livesummary;



// ------------------------------------------------------------------




// import React, { useEffect, useState } from 'react';
// import io from 'socket.io-client';
// import * as echarts from 'echarts';
// import { BarChart } from '@mui/x-charts/BarChart';
// import { LineChart } from '@mui/x-charts/LineChart';
// import { Livesummaryheader } from '../Operator/Data';
// import './Livesummary.css';
// import { FaChevronDown } from "react-icons/fa";
// import { FaChevronUp } from "react-icons/fa";
// import Supervisorsidebar from '../Supervisor/Supersidebar/Supersidebar';
// import Homeload from '../Operator/Home/HomeLoad'
// import Operatorsidebar from '../Operator/Sidebar/Sidebar';
// function Livesummary() {
//     const [hvData, setHvData] = useState(null);
//     const [imaxValues, setImaxValues] = useState({});
//     const [mdData, setMdData] = useState([]);
//     const [timestamps, setTimestamps] = useState([]);
//     const [showLV1data, setShowLV1data] = useState(false);
//     const [showLV2data, setShowLV2data] = useState(false);
//     const [showProductiondata, setShowProductiondata] = useState(false);
//     const [showDG1data1, setShowDG1data] = useState(false);
//     const [showDG2data, setShowDG2data] = useState(false);
//     const [loggedInUser, setLoggedInUser] = useState(null);
//     const [userDesignation, setUserDesignation] = useState('');

//     useEffect(() => {
//         const user = JSON.parse(localStorage.getItem('user'));
//         if (user && user.username) {
//             setLoggedInUser(user);
//         }
//         setUserDesignation(user.level);
//     }, []);

//     useEffect(() => {
//         const socket = io('http://localhost:5003');
//         socket.on('connect', () => {
//             console.log('WebSocket client connected');
//         });

//         socket.on('hv_data', (data) => {
//             console.log('Received hv_data:', data);
//             setHvData(data);
//             setMdData(prevMdData => [...prevMdData, data.MD]); // Accumulate MD values
//             setTimestamps(prevTimestamps => [...prevTimestamps, data.LiveTime]); // Accumulate timestamps
//         });

//         socket.on('imax_percentage_values', (data) => {
//             console.log('Received imax_values:', data);
//             setImaxValues(prevValues => ({ ...prevValues, ...data }));
//         });

//         return () => {
//             socket.disconnect();
//         };
//     }, []);

//     useEffect(() => {
//         console.log('MD Data:', mdData);
//         console.log('Timestamps:', timestamps);
//     }, [mdData, timestamps]);

//     useEffect(() => {
//         if (hvData) {
//             // Initialize the ECharts gauge chart
//             const chartDom = document.getElementById('gauge-chart');
//             const myChart = echarts.init(chartDom);

//             // Chart configuration
//             const option = {
//                 series: [
//                     {
//                         name: 'Gauge',
//                         type: 'gauge',
//                         min: 0,
//                         max: 1,
//                         splitNumber: 5,
//                         radius: '55%',
//                         axisLine: {
//                             lineStyle: {
//                                 width: 10,
//                                 color: [
//                                     [0.9, 'red'],
//                                     [0.95, 'orange'],
//                                     [1, 'green']
//                                 ],
//                             },
//                         },
//                         pointer: {
//                             width: 5,
//                         },
//                         detail: {
//                             fontSize: 23,
//                             formatter: '{value}',
//                         },
//                         data: [{ value: hvData.PF }],
//                     },
//                 ],
//             };

//             // Set the option to the chart
//             myChart.setOption(option);

//             // Resize the chart when window size changes
//             window.addEventListener('resize', myChart.resize);

//             return () => {
//                 window.removeEventListener('resize', myChart.resize);
//             };
//         }
//     }, [hvData]);

//     if (!hvData) {
//         return <div>Loading...</div>;
//     }

//     const barChartData = {
//         xAxis: [{ scaleType: 'band', data: ['Ir', 'Iy', 'Ib'] }],
//         series: [{ data: [hvData.Load.Ir, hvData.Load.Iy, hvData.Load.Ib], color: '#7E95DF' }]
//     };

//     const getProgressBarColor = (percentage) => {
//         if (percentage < 90) {
//             return 'green';
//         } else if (percentage >= 90 && percentage < 95) {
//             return 'orange';
//         } else if (percentage >= 95) {
//             return 'red';
//         }
//     };

//     const toggleLV1Data = () => {
//         setShowLV1data(!showLV1data);
//     };
//     const toggleLV2Data = () => {
//         setShowLV2data(!showLV2data);
//     };
//     const toggleProductionData = () => {
//         setShowProductiondata(!showProductiondata);
//     };
//     const toggleDG1Data1 = () => {
//         setShowDG1data(!showDG1data1);
//     };
//     const toggleDG2Data = () => {
//         setShowDG2data(!showDG2data);
//     };


//     return (
//         <div id='livesummary-total'>
//             <div>
//                 {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />}
//             </div>
//             <div>
//             </div>
//             <div id='livesummary-right'>
//                 <div id='livesummary-right-1'>
//                     <div id='livesummary-heading'>
//                         {Livesummaryheader.alertHeading}
//                     </div>
//                     <div id='livesummary-heading1'>
//                         {Livesummaryheader.alertActions.map((action, index) => (
//                             <button key={index} aria-label={action.label}>
//                                 {action.icon}
//                             </button>
//                         ))}
//                         <img src={Livesummaryheader.logoSrc} alt='logo' />
//                     </div>
//                 </div>

//                 <div id='livesummary-right-2'>
//                     <div id='livesummary-lastupdated'>
//                         <span> <strong>Last Updated at :</strong>  {hvData.LiveTime}</span>
//                     </div>
//                     <div id='livesummary-right-21'>
//                         <div id='livesummary-right-22'>
//                             <div>
//                                 <p>Units Consumed (KVAh)</p>
//                                 <p>(Today)</p>
//                                 <h3 style={{ fontSize: '50px' }}>{hvData.Energy_Consumption}</h3>
//                             </div>
//                             <div>
//                                 <p style={{ marginLeft: '50px' }}>Load (Amps)</p>
//                                 <BarChart
//                                     xAxis={barChartData.xAxis}
//                                     series={barChartData.series}
//                                     width={220}
//                                     height={200}
//                                 />
//                             </div>
//                             <div>
//                                 <p style={{ marginLeft: '95px' }}>Power Factor</p>
//                                 <div id="gauge-chart" style={{ width: 300, height: 300 }}></div>
//                             </div>
//                             <div>
//                                 <p style={{ marginLeft: '70px' }}>Demand (KVA)</p>
//                                 <LineChart
//                                     xAxis={[{ data: mdData.map((_, index) => index + 1) }]} // Use indices as X-axis labels
//                                     series={[
//                                         {
//                                             data: mdData,
//                                             area: true,
//                                             color: '#7E95DF',
//                                         },
//                                     ]}
//                                     width={300}
//                                     height={200}
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                     <div>
//                     </div>
//                 </div>

//                 <Homeload />
//             </div>
//         </div>
//     );
// }

// export default Livesummary;



import React, { useEffect, useState } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import * as echarts from 'echarts';
import { BarChart } from '@mui/x-charts/BarChart';
import { Livesummaryheader } from '../Operator/Data';
import './Livesummary.css';
import Supervisorsidebar from '../Supervisor/Supersidebar/Supersidebar';
import Operatorsidebar from '../Operator/Sidebar/Sidebar';
import Homeload from '../Operator/Home/HomeLoad';
import { ThreeDots } from 'react-loader-spinner'; // Importing spinner from react-loader-spinner
import TopBar from '../Commonfns/Topbar/Topbar';
import moment from 'moment';
function Livesummary() {
    const [hvData, setHvData] = useState(null);
    const [imaxValues, setImaxValues] = useState({});
    const [mdData, setMdData] = useState([]);
    const [timestamps, setTimestamps] = useState([]);
    const [loadingTime, setLoadingTime] = useState(0);
    const [showLV1data, setShowLV1data] = useState(false);
    const [showLV2data, setShowLV2data] = useState(false);
    const [showProductiondata, setShowProductiondata] = useState(false);
    const [showDG1data1, setShowDG1data] = useState(false);
    const [showDG2data, setShowDG2data] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    const [maxValue, setMaxValue] = useState(null);

    const [accessedLevel, setAccessedLevel] = useState(null);

    useEffect(() => {
        // Fetch the accessed level from localStorage
        const level = localStorage.getItem('accessedLevel');
        setAccessedLevel(level);
    }, []);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
        }
        setUserDesignation(user.level);
    }, []);

    // useEffect(() => {
    //     const socket = io(process.env.REACT_APP_LIVESUMMARY_URL);
    //     socket.on('connect', () => {
    //         console.log('WebSocket client connected');
    //     });

    //     socket.on('hv_data', (data) => {
    //         console.log('Received hv_data:', data);
    //         setHvData(data);
    //     });

    //     socket.on('imax_percentage_values', (data) => {
    //         console.log('Received imax_values:', data);
    //         setImaxValues(prevValues => ({ ...prevValues, ...data }));
    //     });

    //     return () => {
    //         socket.disconnect();
    //     };
    // }, []);
    useEffect(() => {
        // Open a WebSocket connection to the server
        const ws = new WebSocket(process.env.REACT_APP_LIVESUMMARY_URL);
    
        
        ws.onmessage = (event) => {
         console.log(JSON.parse(event.data));
         setHvData(JSON.parse(event.data));
         
        };
    
        
    
        // Clean up the WebSocket connection when the component unmounts
        return () => ws.close();
      }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setLoadingTime(prev => prev + 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (hvData) {

            console.log("PF Alter");
            
            const gaugeChartDom = document.getElementById('gauge-chart');
            const gaugeChart = echarts.init(gaugeChartDom);

            const gaugeOption = {
                series: [
                    {
                        name: 'Gauge',
                        type: 'gauge',
                        min: 0,
                        max: 1,
                        splitNumber: 5,
                        radius: '55%',
                        axisLine: {
                            lineStyle: {
                                width: 10,
                                color: [
                                    [0.9, 'red'],
                                    [0.95, 'orange'],
                                    [1, 'green']
                                ],
                            },
                        },
                        pointer: {
                            width: 5,
                        },
                        detail: {
                            fontSize: 23,
                            formatter: '{value}',
                        },
                        data: [{ value: hvData.PF }],
                    },
                ],
            };


            console.log(gaugeOption);
            

            console.log(hvData);
            
            gaugeChart.setOption(gaugeOption);
            window.addEventListener('resize', gaugeChart.resize);

            return () => {
                window.removeEventListener('resize', gaugeChart.resize);
            };
        }
    }, [hvData]);

    useEffect(() => {
        const fetchMaxValue = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/fixed-variables`);
                console.log("API response:", response.data); // Log the full API response

                if (Array.isArray(response.data) && response.data.length > 0) {
                    const maxVal = parseFloat(response.data[0]["Contracted_max_demand_(CMD)"]);
                    console.log("Fetched max value:", maxVal); // Log the fetched value

                    if (!isNaN(maxVal)) {
                        setMaxValue(maxVal); // Set the max value if valid
                    } else {
                        console.log("Invalid max value fetched"); // Log if the value is invalid
                    }
                } else {
                    console.log("Max value property not found in response"); // Log if the data structure is incorrect
                }
            } catch (error) {
                console.log("Error fetching the max value:", error); // Log any errors during fetch
            }
        };

        fetchMaxValue();
    }, []);

    const getDemandTextColor = () => {
        if (hvData && maxValue) {
            const percentageOfCMD = (hvData.MD / maxValue) * 100;
            if (percentageOfCMD < 90) {
                return 'green';
            } else if (percentageOfCMD >= 90 && percentageOfCMD <= 95) {
                return 'orange';
            } else {
                return 'red';
            }
        }
        return 'black'; // Default color
    };

    if (!hvData) {
        return (
            <div id='loading-container'
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',  // This ensures the container takes up the full height of the viewport
                    textAlign: 'center'
                }}>
                <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#5c70ab"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
                <p  >Loading... {loadingTime} seconds elapsed</p>
            </div>
        );
    }

    const barChartData = {
        xAxis: [{ scaleType: 'band', data: ['Ir', 'Iy', 'Ib'] }],
        series: [{ data: [hvData.Load.Ir, hvData.Load.Iy, hvData.Load.Ib], color: '#7E95DF' }]
    };


    const toggleLV1Data = () => {
        setShowLV1data(!showLV1data);
    };
    const toggleLV2Data = () => {
        setShowLV2data(!showLV2data);
    };
    const toggleProductionData = () => {
        setShowProductiondata(!showProductiondata);
    };
    const toggleDG1Data1 = () => {
        setShowDG1data(!showDG1data1);
    };
    const toggleDG2Data = () => {
        setShowDG2data(!showDG2data);
    };


    return (
        <div id='livesummary-total'>
            <div>
                {accessedLevel === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />}
            </div>
            <div id='livesummary-right'>
                 <TopBar section="Liveplantsummaryheading" />

                <div id='livesummary-right-2'>
                    <div id='livesummary-lastupdated'>
                        <span> <strong>Last Updated at :</strong>  {moment(hvData.LiveTime).format('DD-MM-YY hh:mm:ss A') }</span>
                    </div>
                    <div id='livesummary-right-21'>
                        <div id='livesummary-right-22'>
                            <div>
                                <p>Units Consumed (KVAh)</p>
                                <p>(Today)</p>
                                <h3 style={{ fontSize: '50px' }}>{hvData.Energy_Consumption}</h3>
                            </div>
                            <div>
                                <p style={{ marginLeft: '50px' }}>Load (Amps)</p>
                                <BarChart
                                    xAxis={barChartData.xAxis}
                                    series={barChartData.series}
                                    width={220}
                                    height={200}
                                />
                            </div>
                            <div>
                                <p style={{ marginLeft: '95px' }}>Power Factor</p>
                                <div id="gauge-chart" style={{ width: 300, height: 300 }}></div>
                            </div>
                            <div>
                            <p style={{ marginLeft: '2px' }}> CMD: <span style={{ fontWeight: 'lighter' }}>
                                    {maxValue !== null && maxValue !== undefined ? maxValue : 'N/A'}
                                </span></p>
                                <p style={{ marginLeft: '2px' }}>Today's Demand (kVA)</p>
                                <h3 style={{ fontSize: '50px', color: getDemandTextColor() }}>{hvData.MD}</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <Homeload />
            </div>
        </div>
    );
}

export default Livesummary;

