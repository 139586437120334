import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import './Incomersfetch.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Masterformsheadingsss } from '../../Operator/Data';
const Incomersfetch = ({ onFormButtonClick }) => {
    const [incomers, setIncomers] = useState([]);
    const [incomerseditData, setIncomersEditData] = useState(null);

    const fetchIncomers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/incomers`);
            setIncomers(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchIncomers();
    }, []);

    const handleEditClick = (incomers) => {
        setIncomersEditData(incomers);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setIncomersEditData({ ...incomerseditData, [name]: value });
    };

    //   const validateData = (data) => {
    //     // Add validation logic based on your requirements
    //     if (!data.groundingPointId || !data.location || !data.locationNumber || !data.resistance || !data.periodicityOfMaintenance || !data.previousMaintenanceDate) {
    //       return false;
    //     }
    //     return true;
    //   };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        // if (!validateData(incomerseditData)) {
        //   console.error('Invalid data:', incomerseditData);
        //   return;
        // }
        console.log('Updating data:', incomerseditData);
        toast.success("Incomers Points updated sucessfully")
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/incomers/${incomerseditData._id}`, incomerseditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchIncomers();
            setIncomersEditData(null);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
            toast.error('Error Updating Incomer');
        }
    };

    return (


        <div id='incomersfetch-total'>
            <div id='incomersfetch-right'>

                <div id='incomersfetch-right-2' >

                    <div id='incomersfetch-right-21'>
                        <label>
                            {Masterformsheadingsss.incomersHeading}
                        </label>
                        <label>
                            <button onClick={onFormButtonClick}>{Masterformsheadingsss.Formheading}</button>
                        </label>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Name</th>
                                <th>From Substation</th>
                                <th>Voltage level</th>
                                <th>Department meter MF</th>
                                <th>CT Ratio of department MF</th>
                                <th>PT Ratio of department MF</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {incomers.map((incomer) => (
                                <tr key={incomer._id}>
                                    <td>{incomer.date}</td>
                                    <td>{incomer.name}</td>
                                    <td>{incomer.fromsubstation}</td>
                                    <td>{incomer.voltagelevel}</td>
                                    <td>{incomer.departmentmeterMF}</td>
                                    <td>{incomer.ctratioofdepartmentMF}</td>
                                    <td>{incomer.ptratioofdepartmentMF}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(incomer)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {incomerseditData && (
                        <Popup open={true} closeOnDocumentClick onClose={() => setIncomersEditData(null)}>
                            <div id='incomersfetch-popup'>
                                <h3>Edit Incomers</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='incomersfetch-popup1'>
                                        <label>
                                            <span>Date</span>
                                            <input name="date" value={incomerseditData.date} disabled />
                                        </label>
                                        <label>
                                            <span>Name</span>
                                            <input name="name" value={incomerseditData.name} onChange={handleEditChange} required />
                                        </label>
                                    </div>

                                    <div id='incomersfetch-popup1'>
                                        <label>
                                            <span>From Substation</span>
                                            <input name="fromsubstation" value={incomerseditData.fromsubstation} onChange={handleEditChange} required />
                                        </label>
                                        <label>
                                            <span>Voltage level</span>
                                            <input type='number' name="voltagelevel" value={incomerseditData.voltagelevel} onChange={handleEditChange} required />
                                        </label>
                                    </div>

                                    <div id='incomersfetch-popup1'>
                                        <label>
                                            <span>Department meter MF</span>
                                            <input type='number' name="departmentmeterMF" value={incomerseditData.departmentmeterMF} onChange={handleEditChange} required />
                                        </label>
                                        <label>
                                            <span>CT Ratio of department MF</span>
                                            <input type="number" name="ctratioofdepartmentMF" value={incomerseditData.ctratioofdepartmentMF} onChange={handleEditChange} required />
                                        </label>
                                    </div>
                                    <div id='incomersfetch-popup1'>
                                        <label>
                                            <span>PT Ratio of department MF</span>
                                            <input type='number' name="ptratioofdepartmentMF" value={incomerseditData.ptratioofdepartmentMF} onChange={handleEditChange} required />
                                        </label>
                                    </div>
                                    <button id='incomersfetch-popup-update' type="submit">{Masterformsheadingsss.Updateheading}</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Incomersfetch;
