
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Superadminsidebar from '../../Superadmin/Sidebar/Sidebar';
import Adminsidebar from '../../Adminsidebar/Adminsidebar';
import './Usercreation.css'
import { Userheader } from '../../Operator/Data';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 
import TopBar from '../../Commonfns/Topbar/Topbar';
const Usercreation = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    designation: '',
    level: '',
    name: '',
    username: '',
    password: '',
    confirmpassword: '',
    createdby: '',
    modifiedby: ''
  });

  const [errors, setErrors] = useState({});
  const [designations, setDesignations] = useState([]);
  const [userDesignation, setUserDesignation] = useState('');

  useEffect(() => {
    fetchDesignations();
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.username) {
      setFormData((prevData) => ({
        ...prevData,
        createdby: user.username
      }));
      setUserDesignation(user.level);
    }
  }, []);

  const fetchDesignations = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/designationsotherthanlevel1`);
      setDesignations(response.data);
    } catch (error) {
      console.error('Error fetching designations:', error);
    }
  };

  useEffect(() => {
    fetchDesignations();
  }, []);

  const { designation, level, name, username, password, confirmpassword } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errorMessages = { ...errors };

    if (name === 'username') {
      const usernameRegex = /^[a-z0-9]+$/;
      if (!usernameRegex.test(value)) {
        errorMessages.username = 'Username should only contain lowercase letters and numbers.';
      } else {
        delete errorMessages.username;
      }
    }

    if (name === 'password' || name === 'confirmpassword') {
      if (name === 'password' && value === username) {
        errorMessages.password = 'Password should not be the same as Username.';
      } else {
        delete errorMessages.password;
      }

      if (name === 'confirmpassword' && value !== password) {
        errorMessages.confirmpassword = 'Passwords do not match.';
      } else {
        delete errorMessages.confirmpassword;
      }
    }

    setErrors(errorMessages);
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDesignationChange = (e) => {
    const selectedDesignation = designations.find(desig => desig.designation === e.target.value);
    setFormData((prevData) => ({
      ...prevData,
      designation: e.target.value,
      level: selectedDesignation ? selectedDesignation.level : ''
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.keys(errors).length > 0) {
      toast.error('Please fix the errors in the form.');
      return;
    }

    const postData = {
      ...formData
    };

    try {
      await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/users`, postData);
      toast.success('User created successfully');
      setFormData({
        designation: '',
        level: '',
        name: '',
        username: '',
        password: '',
        confirmpassword: '',
        createdby: '',
        modifiedby: ''
      });
    } catch (error) {
      toast.error('Error creating user:', error.message);
    }
  };

  
  const togglePasswordVisibility = () => setShowPassword(prev => !prev);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(prev => !prev);

  return (


    <div id='usercreation-total'>
      <div>
        {/* {userDesignation === 'Level-1' ? <Superadminsidebar /> : <Adminsidebar />} */}
        <Superadminsidebar />
      </div>
      <div id='usercreation-right'>
        <div id='usercreation-right-1'>
        <TopBar section="AdduserHeading" />
          {/* <div id='usercreation-heading'>
            {Userheader.alertHeading}
          </div>
          <div id='usercreation-heading1'>
            {Userheader.alertActions.map((action, index) => (
              <button key={index} aria-label={action.label}>
                {action.icon}
              </button>
            ))}
            <img src={Userheader.logoSrc} alt='logo' />
          </div> */}
        </div>
        <div id='usercreation-right-2'>
          <form onSubmit={handleSubmit}>
            <div id='usercreation-divtop'>
              <label>
                <Link to='/Userfetch'><button type="button">View Data</button></Link>
              </label>
            </div>
            <div id='usercreation-div1'>
              <label>
                <span>Designation</span>
                <select name="designation" value={designation} onChange={handleDesignationChange} required>
                  <option value="">Select Designation</option>
                  {designations.map((desig) => (
                    <option key={desig._id} value={desig.designation}>
                      {desig.designation}
                    </option>
                  ))}
                </select>
              </label>

              {/* <label>
                <span>Designation</span>
                <input
                  type="text"
                  name="designation"
                  value={designation}
                  onChange={handleChange}
                  required
                  disabled
                />
              </label> */}
            </div>
            <div id='usercreation-div1'>
              <label>
                <span>Name</span>
                <input
                id='usercreation-div1-input'
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                <span>Username</span>
                <input
                 id='usercreation-div1-input'
                  type="text"
                  name="username"
                  value={username}
                  onChange={handleChange}
                  required
                />
                {errors.username && <div style={{ color: 'red' }}>{errors.username}</div>}
              </label>
            </div>
            <div id='usercreation-div1'>
              <label>
                <span>Password</span>
               <div id='password-container'>
              <input
              id='password-container-input'
                type={showPassword ? "text" : "password"}  // Toggle input type
                value={password}
                onChange={handleChange}
                name='password'
                required
              />
               <span onClick={togglePasswordVisibility} className='password-toggle-icon'>
               {showPassword ? <FaEyeSlash /> : <FaEye />} 
              </span>
            </div>
                {errors.password && <div style={{ color: 'red' }}>{errors.password}</div>}
              </label>
              <label>
                <span>Confirm Password</span>
                <div id='password-container'>
                <input
                 id='password-container-input'
                 type={showConfirmPassword ? "text" : "password"}
                  name="confirmpassword"
                  value={confirmpassword}
                  onChange={handleChange}
                  required
                />
                <span onClick={toggleConfirmPasswordVisibility} className='password-toggle-icon'>
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />} 
              </span>
               </div>
               
                {errors.confirmpassword && <div style={{ color: 'red' }}>{errors.confirmpassword}</div>}
              </label>
            </div>
            <div id='usercreation-div1'>

            </div>
            <label id='usercreation-savediv' >
            <button id='usercreation-save' type="submit">Register</button>

            </label>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Usercreation;

