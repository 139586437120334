// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const Utilization_Percenatage = () => {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/utilization_percentage`);
//         setData(response.data);
//       } catch (error) {
//         console.error('Error fetching the data', error);
//       }
//     };

//     fetchData();
//   }, []);
//   return (
//     <div>
//       {data.map((item) => (
//         <div key={item._id}>
//           <p>Date: {item.date}</p>
//           <p>Utilization Percentage: {item["Utilization_%"]}</p>
//         </div>
//       ))}
//     </div>
//   )
// }

// export default Utilization_Percenatage


import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';

const Utilization_Percenatage = () => {
  const [data, setData] = useState([]);
  const chartRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/utilization_percentage`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching the data', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0 && chartRef.current) {
      const chartInstance = echarts.init(chartRef.current);

      const dates = data.map(item => item.date);
      const utilizationPercentages = data.map(item => item["Utilization_%"]);

      const option = {
        title: {
          text: 'Utilization %'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: dates,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: 'Utilization %',
            type: 'bar',
            barWidth: '10%',
            data: utilizationPercentages
          }
        ]
      };

      chartInstance.setOption(option);

      // Cleanup function to dispose of the chart instance
      return () => {
        chartInstance.dispose();
      };
    }
  }, [data]);

  return (
    <div>
      <div ref={chartRef} style={{ height: '400px', width: '100%' }}></div>
    </div>
  );
};

export default Utilization_Percenatage;
