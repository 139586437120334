import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import "./Alertscount.css";
import { getAlertsCount } from "../../../api/services/trends/trendsService";
import chatIcon from "../../../Images/Black.svg";
import Stationpoints from "../../Commonfns/Stationpoints";
import moment from "moment/moment";
import { useForm } from "react-hook-form";
import manIcon from "../../../Images/man.svg";
import { whatSholdYouDo } from "../../../utils/trends";
import lightIcon from "../../../Images/light.svg";
import { Link } from "react-router-dom";
import { errorToast } from "../../../utils/toast";
import { useTranslation } from "react-i18next";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { DatePicker, Popover } from "antd";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Alertscount = () => {
  const { t } = useTranslation();
  const monthInputRef = useRef(null);

  const { register, setValue, watch, getValues } = useForm({
    defaultValues: {
      station_point: "",
      month: dayjs(),
    },
  });

  const [chartData, setChartData] = useState({});

  const { station_point, month } = watch();

  useEffect(() => {
    ChartJS.unregister(ChartDataLabels);
    getAlertsCount(station_point, month.format('YYYY-MM'))
      .then((res) => {
        setChartData(res.alert_counts);
      })
      .catch((error) => {
        errorToast(error.message || "failed to fetch Alert Count");
      });
  }, [station_point, month]);


  const disableFutureMonths = (current) => {
    return current && current > dayjs().endOf("month");
  };

  const onChange = (value) => {
    if (value) {
      setValue("month", value);
    }
  };

  const getData = () => {
    const data = {
      labels: Object.keys(chartData).map((key) => key),
      datasets: [
        {
          label: "Alert Count",
          data: Object.values(chartData).map((value) => value),
          backgroundColor: Array.from({
            length: Object.entries(chartData).length,
          }).map((_color) => "#DD4848"),
          borderWidth: 1,
        },
      ],
    };

    return data;
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        title: {
          display: true,
          text: "Parameters",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
      },
      y: {
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        beginAtZero: true,
        title: {
          display: true,
          text: "Count",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
      },
    },
    animation: {
      // duration: 50,
      // easing: undefined,
    },
    plugins: {
      tooltip: {
       
        backgroundColor: "white", // Set the tooltip background color to white
        titleColor: "black", // Set the title text color to black for contrast
        bodyColor: "black",
        borderColor: "black",
        borderWidth: 1,
        yAlign: 'bottom', // Ensures tooltip is positioned above the bar
        caretSize: 5, // Adjust size of the tooltip caret
        caretPadding: 0,

        titleFont: {
          size: 15, // Increase title font size
        },
        bodyFont: {
          size: 14, // Increase body font size
        },
        padding: 12, // Increase padding inside the tooltip
        boxPadding: 10, // Padding around colored boxes
        boxWidth: 30, // Width of color indicator boxes
        boxHeight: 30, // Height of color indicator boxes
      },
    },
  };

  const handleStationPointChange = (value) => {
    if (value.length === 0) {
      setValue(station_point, "HV");
    } else {
      setValue("station_point", value);
    }
  };

  return (
    <div>
      <Paper  elevation={0}
        sx={{
          border: "1px solid #DFE6FA",
        }} className="filter-container">
        <Box>
          <div>
            <Stationpoints onStationPointChange={handleStationPointChange} />
          </div>
        </Box>
        <Box>
          <div>
            <label>{t("common.selectMonthColumn")}</label>
          </div>

          <div>
          <DatePicker
                  {...register("month")}
                  style={{ width: "200px" }}
                  defaultValue={getValues("month")}
                  size={"medium"}
                  picker={"month"}
                  disabledDate={disableFutureMonths} // Restricts future months
                  onChange={onChange}
                />
        
          </div>
        </Box>
      </Paper>

      <Paper  elevation={0}
        sx={{
          border: "1px solid #DFE6FA",
        }} className="chart-container">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {t("alertTrendsComponent.alertsTrend")}
          </Typography>

          <Popover
            placement="leftBottom"
            content={
              <Box maxWidth={600}>
                <Box className="static-content-container">
                  <Box className="imp-container">
                    <img src={chatIcon} width={"40px"} height={"40px"} />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#447F44", fontWeight: "bold" }}
                      >
                        {t("common.whyIsThisImportanQuestion")}
                      </Typography>
                      <Typography variant="body1">
                        {t("alertTrendsComponent.whyIsThisImportantAnswer")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className="static-content-container">
                  <Box
                    className="imp-container"
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <img
                        style={{ alignSelf: "start" }}
                        src={manIcon}
                        width={"100px"}
                        height={"40px"}
                      />

                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="body1"
                          sx={{ color: "#447F44", fontWeight: "bold" }}
                        >
                          {t("common.WhatShouldYouDoQuestion")}
                        </Typography>
                        <Typography variant="body1">
                          <li>
                            {t("alertTrendsComponent.whatShouldYouDoAnswer")}
                          </li>
                        </Typography>

                        <Typography variant="body1">
                          <ul
                            style={{
                              margin: 5,
                              listStyleType: "square",
                            }}
                          >
                            {t(
                              "alertTrendsComponent.whatShouldYouDoAnswerBullets",
                              {
                                returnObjects: true,
                              }
                            ).map((point, index) => (
                              <li key={index}>{point}</li>
                            ))}
                          </ul>
                        </Typography>
                      </Box>
                    </Box>

                    <Box>
                      <Link to={"/Alertspage?section=OperatorAlertstable-1"}>
                        <Button
                          sx={{
                            textTransform: "none",
                            backgroundColor: "#447F44",
                            color: "white",
                            width: "150px",
                            justifySelf: "start",
                            alignSelf: "start",
                            borderRadius: "4px",
                          }}
                        >
                          {t("alertTrendsComponent.alertsHistory")}
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Box>

                <Box className="static-content-container">
                  <Box className="imp-container">
                    <img src={lightIcon} width={"40px"} height={"40px"} />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#447F44", fontWeight: "bold" }}
                      >
                        {t("common.howWillYouImproveQuestion")}
                      </Typography>
                      <Typography variant="body1">
                        {t("alertTrendsComponent.howWillYouImproveAnswer")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            }
            // title="Title"
          >
            <Box
              type="button"
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: 0.1,
              }}
              // onClick={() => setOpen()}
            >
              <HelpOutlineOutlinedIcon fontSize="medium" color="disabled" />
              <Typography
                fontSize={15}
                style={{ display: "inline", color: "gray" }}
              >
                How to use?
              </Typography>
            </Box>
          </Popover>
        </Box>

        <Bar className="alerts-chart" data={getData()} options={options} />
      </Paper>
    </div>
  );
};

export default Alertscount;
