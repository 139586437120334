
import React, { useEffect, useState } from 'react';
import Operatorsidebar from '../Sidebar/Sidebar'
import {  alertData3 } from '../Data';
import './Livedata.css'

function LiveData() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [ws, setWs] = useState(null);

  useEffect(() => {
    const connectWebSocket = () => {
      const socket = new WebSocket('ws://localhost:3006');

      socket.onopen = () => {
        console.log('Connected to WebSocket server');
        setError(null);

        // Heartbeat to keep the connection alive
        const heartbeat = () => {
          if (socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify({ type: 'ping' }));
            setTimeout(heartbeat, 10000); // 30 seconds
          }
        };
        heartbeat();
      };

      socket.onmessage = (event) => {
        try {
          const newData = JSON.parse(event.data);
          setData(newData);
        } catch (e) {
          console.error('Error parsing WebSocket message:', e);
        }
      };

      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
        setError('WebSocket error');
      };

      socket.onclose = (event) => {
        console.log('WebSocket connection closed', event);
        if (event.code !== 1000) { // 1000 indicates a normal closure
          setError('WebSocket connection closed unexpectedly');
          setTimeout(connectWebSocket, 1000); // Reconnect after 1 second
        }
      };

      setWs(socket);
    };

    connectWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  if (error) {
    return <p>Error loading data: {error}</p>;
  }

  return (
    <div>


<div id='Livedata-total'>
    <div>
      <Operatorsidebar />
    </div>
    <div id='Livedata-right'>
        <div id='Livedata-right-1'>
        <div id='Livedata-heading'>
          {alertData3.alertHeading}
        </div>
        <div id='Livedata-heading1'>
          {alertData3.alertActions.map((action, index) => (
            <button key={index} aria-label={action.label}>
              {action.icon}
            </button>
          ))}
          <img src={alertData3.logoSrc} alt='logo' />
        </div>
      </div>

      <div id='Livedata-right-2'>
      <table>
            <thead>
              <tr>
                <th>S No</th>
                <th>Device EUI</th>
                <th>Fr</th>
                <th>Iavg</th>
                <th>Ib</th>
                <th>Imax</th>
                <th>Ir</th>
                <th>Iy</th>
                <th>KVAh</th>
                <th>KVArh</th>
                <th>Pf</th>
                <th>THDib</th>
                <th>THDir</th>
                <th>THDiy</th>
                <th>THDvb</th>
                <th>THDvr</th>
                <th>THDvy</th>
                <th>Vavg</th>
                <th>Vb</th>
                <th>Vbr</th>
                <th>Vr</th>
                <th>Vry</th>
                <th>Vy</th>
                <th>Vyb</th>
                <th>Division</th>
                <th>kVA</th>
                <th>kW</th>
                <th>kWh</th>
                <th>Station POI</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.deviceEUi}</td>
                  <td>{item.Fr}</td>
                  <td>{item.Iavg}</td>
                  <td>{item.Ib}</td>
                  <td>{item.Imax}</td>
                  <td>{item.Ir}</td>
                  <td>{item.Iy}</td>
                  <td>{item.KVAh}</td>
                  <td>{item.KVArh}</td>
                  <td>{item.Pf}</td>
                  <td>{item.THDib}</td>
                  <td>{item.THDir}</td>
                  <td>{item.THDiy}</td>
                  <td>{item.THDvb}</td>
                  <td>{item.THDvr}</td>
                  <td>{item.THDvy}</td>
                  <td>{item.Vavg}</td>
                  <td>{item.Vb}</td>
                  <td>{item.Vbr}</td>
                  <td>{item.Vr}</td>
                  <td>{item.Vry}</td>
                  <td>{item.Vy}</td>
                  <td>{item.Vyb}</td>
                  <td>{item.division}</td>
                  <td>{item.kVA}</td>
                  <td>{item.kW}</td>
                  <td>{item.kWh}</td>
                  <td>{item.stationPoi}</td>
                  <td>{item.utcTimestamp}</td>
                </tr>
              ))}
            </tbody>
          </table>
      </div>
    </div>
  </div>
    </div>
  );
}

export default LiveData;