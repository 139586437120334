// import React, { useEffect, useRef, useState } from 'react';
// import axios from 'axios';
// import * as echarts from 'echarts';
// import './DashPF.css';

// const DashPF = ({ selectedMonth }) => {
//     const [powerFactorData, setPowerFactorData] = useState([]);
//     const powerFactorChartRef = useRef(null);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/powerfactor`);
//                 setPowerFactorData(response.data);
//             } catch (error) {
//                 console.log("Error fetching the PF Data")
//             }
//         };

//         fetchData();
//     }, []);

//     useEffect(() => {
//         if (powerFactorData.length > 0) {
//             const chartDom = powerFactorChartRef.current;
//             const myChart = echarts.init(chartDom);

//             const option = {
//                 title: {
//                     text: 'Power Factor',
//                 },
//                 tooltip: {
//                     trigger: 'axis',
//                     axisPointer: {
//                         type: 'shadow'
//                     }
//                 },
//                 legend: {
//                     data: ['Power Factor HV']
//                 },
//                 grid: {
//                     left: '1%',
//                     right: '1%',
//                     bottom: '1%',
//                     containLabel: true
//                 },
//                 xAxis: {
//                     type: 'category',
//                     data: powerFactorData.map(item => item.date),
//                     axisTick: {
//                         alignWithLabel: true
//                     }
//                 },
//                 yAxis: {
//                     type: 'value',
//                     min: 0,
//                     max: 1,
//                     interval: 0.2,
//                     axisLabel: {
//                         formatter: value => value.toFixed(1) // Format the labels to one decimal place
//                     }
//                 },
//                 series: [
//                     {
//                         name: 'Power Factor HV',
//                         type: 'bar',
//                         barWidth: '30%',
//                         data: powerFactorData.map(item => item.PF_HV),
//                         itemStyle: {
//                             color: '#7E95DF'
//                         }
//                     }
//                 ]
//             };

//             myChart.setOption(option);

//             const resizeChart = () => {
//                 myChart.resize();
//             };

//             window.addEventListener('resize', resizeChart);

//             return () => {
//                 window.removeEventListener('resize', resizeChart);
//                 myChart.dispose();
//             };
//         }
//     }, [powerFactorData]);



//     return (
//         <div >

//             <div ref={powerFactorChartRef} style={{height:'400px' , width:'100%'}} />

//         </div>
//     );
// };

// export default DashPF;





import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';
import './DashPF.css';

const DashPF = ({ selectedMonth }) => {
    const [powerFactorData, setPowerFactorData] = useState([]);
    const powerFactorChartRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/powerfactor`);
                const filteredData = response.data.filter(item => {
                    // Extract the month from the date
                    const itemMonth = new Date(item.date).toISOString().slice(0, 7); // 'YYYY-MM'
                    return itemMonth === selectedMonth;
                });
                setPowerFactorData(filteredData);
            } catch (error) {
                console.log("Error fetching the PF Data", error);
            }
        };

        fetchData();
    }, [selectedMonth]);

    useEffect(() => {
        if (powerFactorData.length > 0) {
            const chartDom = powerFactorChartRef.current;
            const myChart = echarts.init(chartDom);

            const option = {
                title: {
                    // text: 'Power Factor',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: ['Power Factor']
                },
                grid: {
                    left: '1%',
                    right: '1%',
                    bottom: '1%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: powerFactorData.map(item => item.date),
                    axisTick: {
                        alignWithLabel: true
                    },
                    axisLabel: { interval: 0, rotate: 60 }
                },
                yAxis: {
                    type: 'value',
                    min: 0,
                    max: 1,
                    interval: 0.2,
                    axisLabel: {
                        formatter: value => value.toFixed(1) // Format the labels to one decimal place
                    }
                },
                series: [
                    {
                        name: 'Power Factor HV',
                        type: 'bar',
                        barWidth: '30%',
                        data: powerFactorData.map(item => item.PF_HV),
                        itemStyle: {
                            color: '#7E95DF'
                        }
                    }
                ]
            };

            myChart.setOption(option);

            const resizeChart = () => {
                myChart.resize();
            };

            window.addEventListener('resize', resizeChart);

            return () => {
                window.removeEventListener('resize', resizeChart);
                myChart.dispose();
            };
        }
    }, [powerFactorData]);

    return (
        // <div id='dashpf-total'>
        //     <span>Power Factor (PF)</span>
        //     {powerFactorData.length === 0 && <p style={{textAlign:'center'}} >Not Available</p>}
        //     <div ref={powerFactorChartRef} id='dashpf-barchart' />
        // </div>

        <div id='dashpf-total'>
        <span>Power Factor (PF)</span>
        {powerFactorData.length === 0 ? (
            <p className="no-data" style={{textAlign:'center'}} >Not Available</p>
        ) : (
            <div ref={powerFactorChartRef} id='dashpf-barchart' />
        )}
    </div>
    );
};

export default DashPF;
