// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const Downtime_Uptime = () => {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/downtime_Report`);
//         setData(response.data);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <div>
//       <h1>Downtime and Uptime Reports</h1>
//       {data.map((report) => (
//         <div key={report._id} style={{ marginBottom: '20px' }}>
//           <h2>Date: {new Date(report.date).toLocaleDateString()}</h2>
//           <h3>Station Points:</h3>
//           <ul>
//             {report.stationPoints.map((point) => (
//               <li key={point}>{point}</li>
//             ))}
//           </ul>
//           <h3>Downtime Durations:</h3>
//           <ul>
//             {Object.entries(report.Downtime_Durations).map(([point, duration]) => (
//               <li key={point}>
//                 {point}: {duration}
//               </li>
//             ))}
//           </ul>
//           <h3>Uptime Durations:</h3>
//           <ul>
//             {Object.entries(report.Uptime_Durations).map(([point, duration]) => (
//               <li key={point}>
//                 {point}: {duration}
//               </li>
//             ))}
//           </ul>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Downtime_Uptime;



// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const Downtime_Uptime = () => {
//   const [data, setData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [stationPoint, setStationPoint] = useState('HV');
//   const [fromDate, setFromDate] = useState('');
//   const [toDate, setToDate] = useState('');

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/downtime_Report`);
//         setData(response.data);
//         filterData(response.data, stationPoint, fromDate, toDate);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {
//     filterData(data, stationPoint, fromDate, toDate);
//   }, [stationPoint, fromDate, toDate, data]);

//   const filterData = (data, stationPoint, fromDate, toDate) => {
//     let filtered = data.filter(report => 
//       report.stationPoints.includes(stationPoint) || 
//       (report.Downtime_Durations[stationPoint] !== undefined) || 
//       (report.Uptime_Durations[stationPoint] !== undefined)
//     );

//     if (fromDate) {
//       filtered = filtered.filter(report => new Date(report.date) >= new Date(fromDate));
//     }
//     if (toDate) {
//       filtered = filtered.filter(report => new Date(report.date) <= new Date(toDate));
//     }

//     setFilteredData(filtered);
//   };

//   return (
//     <div>
//       <h1>Downtime and Uptime Reports</h1>
//       <div>
//         <label>
//           Station Point:
//           <select value={stationPoint} onChange={(e) => setStationPoint(e.target.value)}>
//             <option value="HV">HV</option>
//             <option value="ENGG store Panel">ENGG store Panel</option>
//             <option value="Unit-5">Unit-5</option>
//             <option value="AR&D">AR&D</option>
//             {/* Add more options as needed */}
//           </select>
//         </label>
//         <label>
//           From Date:
//           <input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
//         </label>
//         <label>
//           To Date:
//           <input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
//         </label>
//       </div>
//       {filteredData.map((report) => (
//         <div key={report._id} style={{ marginBottom: '20px' }}>
//           <h2>Date: {new Date(report.date).toLocaleDateString()}</h2>
//           <h3>Downtime Durations:</h3>
//           <ul>
//             {Object.entries(report.Downtime_Durations)
//               .filter(([point]) => point === stationPoint)
//               .map(([point, duration]) => (
//                 <li key={point}>
//                   {point}: {duration}
//                 </li>
//               ))}
//           </ul>
//           <h3>Uptime Durations:</h3>
//           <ul>
//             {Object.entries(report.Uptime_Durations)
//               .filter(([point]) => point === stationPoint)
//               .map(([point, duration]) => (
//                 <li key={point}>
//                   {point}: {duration}
//                 </li>
//               ))}
//           </ul>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Downtime_Uptime;



// import React, { useEffect, useState, useRef } from 'react';
// import axios from 'axios';
// import * as echarts from 'echarts';

// const Downtime_Uptime = () => {
//   const [data, setData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [stationPoint, setStationPoint] = useState('HV');
//   const [fromDate, setFromDate] = useState('');
//   const [toDate, setToDate] = useState('');
//   const chartRef = useRef(null);
//   const chartInstanceRef = useRef(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/downtime_Report`);
//         setData(response.data);
//         filterData(response.data, stationPoint, fromDate, toDate);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {
//     filterData(data, stationPoint, fromDate, toDate);
//   }, [stationPoint, fromDate, toDate, data]);

//   useEffect(() => {
//     if (chartInstanceRef.current) {
//       chartInstanceRef.current.dispose();
//     }
//     renderChart();
//   }, [filteredData]);

//   const filterData = (data, stationPoint, fromDate, toDate) => {
//     let filtered = data.filter(report => 
//       (report.stationPoints && report.stationPoints.includes(stationPoint)) || 
//       (report.Downtime_Durations && report.Downtime_Durations[stationPoint] !== undefined) || 
//       (report.Uptime_Durations && report.Uptime_Durations[stationPoint] !== undefined)
//     );

//     if (fromDate) {
//       filtered = filtered.filter(report => new Date(report.date) >= new Date(fromDate));
//     }
//     if (toDate) {
//       filtered = filtered.filter(report => new Date(report.date) <= new Date(toDate));
//     }

//     setFilteredData(filtered);
//   };

//   const convertTimeToMinutes = (timeStr) => {
//     const [hours, minutes, seconds] = timeStr.split(':').map(Number);
//     return (hours * 60) + minutes + (seconds / 60);
//   };

//   const convertMinutesToTime = (minutes) => {
//     const hours = Math.floor(minutes / 60);
//     const mins = Math.floor(minutes % 60);
//     const secs = Math.round((minutes % 1) * 60);
//     return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
//   };

//   const renderChart = () => {
//     if (!chartRef.current) return;

//     chartInstanceRef.current = echarts.init(chartRef.current);

//     const dates = filteredData.map(report => new Date(report.date).toLocaleDateString());
//     const downtime = filteredData.map(report => convertTimeToMinutes(report.Downtime_Durations[stationPoint] || "0:00:00"));
//     const uptime = filteredData.map(report => convertTimeToMinutes(report.Uptime_Durations[stationPoint] || "0:00:00"));

//     console.log('Dates:', dates);
//     console.log('Downtime:', downtime);
//     console.log('Uptime:', uptime);

//     const option = {
//         title: {
//             text: 'Downtime & Uptime Reports'
//           },
//       legend: {
//         data: ['Downtime', 'Uptime']
//       },
//       tooltip: {
//         trigger: 'axis',
//         axisPointer: {
//           type: 'shadow'
//         },
//         formatter: function (params) {
//           const downtimeParam = params.find(param => param.seriesName === 'Downtime');
//           const uptimeParam = params.find(param => param.seriesName === 'Uptime');
//           const downtimeValue = downtimeParam ? convertMinutesToTime(downtimeParam.value) : '';
//           const uptimeValue = uptimeParam ? convertMinutesToTime(uptimeParam.value) : '';
//           return `
//             ${params[0].axisValue}<br/>
//             Downtime: ${downtimeValue}<br/>
//             Uptime: ${uptimeValue}
//           `;
//         }
//       },
//       xAxis: [
//         {
//           type: 'category',
//           data: dates,
//           axisLabel: {
//             rotate: 90,
//             interval: 0,
//           },
//         },
//       ],
//       yAxis: {
//         type: 'value',
//         axisLabel: {
//           formatter: function (value) {
//             return convertMinutesToTime(value);
//           }
//         }
//       },
//       series: [
//         {
//           name: 'Downtime',
//           type: 'bar',
//           data: downtime,
//           itemStyle: {
//             color: '#ff4d4d'
//           },
//         },
//         {
//           name: 'Uptime',
//           type: 'bar',
//           data: uptime,
//           itemStyle: {
//             color: '#4caf50'
//           },
//         },
//       ],
//     };

//     chartInstanceRef.current.setOption(option);
//   };

//   return (
//     <div>
//       <div>
//         <label>
//           Station Point:
//           <select value={stationPoint} onChange={(e) => setStationPoint(e.target.value)}>
//           <option value="HV">HV</option>
//             <option value="ENGG store Panel">ENGG store Panel</option>
//             <option value="Unit-5">Unit-5</option>
//             <option value="AR&D">AR&D</option>
//             <option value="Lighting">Lighting</option>
//             <option value="APFC-2">APFC-2</option>
//             <option value="DG Grid Incomer -2">DG Grid Incomer -2</option>
//             <option value="DG Incomer 4">DG Incomer 4</option>
//             <option value="DG Incomer 5">DG Incomer 5</option>
//             <option value="DG Incomer 6">DG Incomer 6</option>
//             <option value="Solar-2">Solar-2</option>
//             <option value="WHR-2">WHR-2</option>
//             <option value="spare-1">spare-1</option>
//             <option value="spare-2">spare-2</option>
//             <option value="LV-1">LV-1</option>
//             <option value="Compressor-11KW">Compressor-11KW</option>
//             <option value="Compressor-75KW">Compressor-75KW</option>
//             <option value="Colony">Colony</option>
//             <option value="Compressor">Compressor</option>
//             <option value="APFC-1">APFC-1</option>
//             <option value="DG Grid Incomer -1">DG Grid Incomer -1</option>
//             <option value="DG Incomer 1">DG Incomer 1</option>
//             <option value="DG Incomer 2">DG Incomer 2</option>
//             <option value="DG Incomer 3">DG Incomer 3</option>
//             <option value="Solar-1">Solar-1</option>
//             <option value="WHR-1">WHR-1</option>
//             <option value="LV-2">LV-2</option>
//             <option value="Production">Production</option>
//           </select>
//         </label>
//         <label>
//           From Date:
//           <input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
//         </label>
//         <label>
//           To Date:
//           <input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
//         </label>
//       </div>
//       <div ref={chartRef} style={{ width: '100%', height: '400px', marginTop: '20px' }}></div>
//     </div>
//   );
// };

// export default Downtime_Uptime;





// import React, { useEffect, useState, useRef } from 'react';
// import axios from 'axios';
// import * as echarts from 'echarts';

// const Downtime_Uptime = () => {
//   const [data, setData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [stationPoint, setStationPoint] = useState('HV');
//   const chartRef = useRef(null);
//   const chartInstanceRef = useRef(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/downtime_Report`);
//         setData(response.data);
//         filterData(response.data, stationPoint);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {
//     filterData(data, stationPoint);
//   }, [stationPoint, data]);

//   useEffect(() => {
//     if (chartInstanceRef.current) {
//       chartInstanceRef.current.dispose();
//     }
//     renderChart();
//   }, [filteredData]);

//   const filterData = (data, stationPoint) => {
//     let filtered = data.filter(report => 
//       (report.stationPoints && report.stationPoints.includes(stationPoint)) || 
//       (report.Downtime_Durations && report.Downtime_Durations[stationPoint] !== undefined) || 
//       (report.Uptime_Durations && report.Uptime_Durations[stationPoint] !== undefined)
//     );

//     setFilteredData(filtered);
//   };

//   const convertTimeToMinutes = (timeStr) => {
//     const [hours, minutes, seconds] = timeStr.split(':').map(Number);
//     return (hours * 60) + minutes + (seconds / 60);
//   };

//   const convertMinutesToTime = (minutes) => {
//     const hours = Math.floor(minutes / 60);
//     const mins = Math.floor(minutes % 60);
//     const secs = Math.round((minutes % 1) * 60);
//     return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
//   };

//   const renderChart = () => {
//     if (!chartRef.current) return;

//     chartInstanceRef.current = echarts.init(chartRef.current);

//     const dates = filteredData.map(report => new Date(report.date).toLocaleDateString());
//     const downtime = filteredData.map(report => convertTimeToMinutes(report.Downtime_Durations[stationPoint] || "0:00:00"));
//     const uptime = filteredData.map(report => convertTimeToMinutes(report.Uptime_Durations[stationPoint] || "0:00:00"));

//     const option = {
//       title: {
//         text: 'Downtime & Uptime Reports'
//       },
//       legend: {
//         data: ['Downtime', 'Uptime']
//       },
//       tooltip: {
//         trigger: 'axis',
//         axisPointer: {
//           type: 'shadow'
//         },
//         formatter: function (params) {
//           const downtimeParam = params.find(param => param.seriesName === 'Downtime');
//           const uptimeParam = params.find(param => param.seriesName === 'Uptime');
//           const downtimeValue = downtimeParam ? convertMinutesToTime(downtimeParam.value) : '';
//           const uptimeValue = uptimeParam ? convertMinutesToTime(uptimeParam.value) : '';
//           return `
//             ${params[0].axisValue}<br/>
//             Downtime: ${downtimeValue}<br/>
//             Uptime: ${uptimeValue}
//           `;
//         }
//       },
//       xAxis: [
//         {
//           type: 'category',
//           data: dates,
//           axisLabel: {
//             rotate: 30,
//             interval: 0,
//           },
//         },
//       ],
//       yAxis: {
//         type: 'value',
//         axisLabel: {
//           formatter: function (value) {
//             return convertMinutesToTime(value);
//           }
//         }
//       },
//       dataZoom: [
//         {
//           type: 'inside',
//           start: 0,
//           end: 100,
//         },
//         {
//           start: 0,
//           end: 100,
//           handleIcon: 'path://M3 6h1v12H3zm1 0H3V6zm12 0h1v12h-1zm1 0h-1V6z',
//           handleSize: '80%',
//           left: '10%',
//           right: '10%',
//           top: '90%',
//         }
//       ],
//       series: [
//         {
//           name: 'Downtime',
//           type: 'bar',
//           data: downtime,
//           itemStyle: {
//             color: '#ff4d4d'
//           },
//         },
//         {
//           name: 'Uptime',
//           type: 'bar',
//           data: uptime,
//           itemStyle: {
//             color: '#4caf50'
//           },
//         }
//       ]
//     };

//     chartInstanceRef.current.setOption(option);
//   };

//   return (
//     <div>
//       <div>
//         <label>
//           Station Point:
//           <select value={stationPoint} onChange={(e) => setStationPoint(e.target.value)}>
//             <option value="HV">HV</option>
//             <option value="ENGG store Panel">ENGG store Panel</option>
//             <option value="Unit-5">Unit-5</option>
//             <option value="AR&D">AR&D</option>
//             <option value="Lighting">Lighting</option>
//             <option value="APFC-2">APFC-2</option>
//             <option value="DG Grid Incomer -2">DG Grid Incomer -2</option>
//             <option value="DG Incomer 4">DG Incomer 4</option>
//             <option value="DG Incomer 5">DG Incomer 5</option>
//             <option value="DG Incomer 6">DG Incomer 6</option>
//             <option value="Solar-2">Solar-2</option>
//             <option value="WHR-2">WHR-2</option>
//             <option value="spare-1">spare-1</option>
//             <option value="spare-2">spare-2</option>
//             <option value="LV-1">LV-1</option>
//             <option value="Compressor-11KW">Compressor-11KW</option>
//             <option value="Compressor-75KW">Compressor-75KW</option>
//             <option value="Colony">Colony</option>
//             <option value="Compressor">Compressor</option>
//             <option value="APFC-1">APFC-1</option>
//             <option value="DG Grid Incomer -1">DG Grid Incomer -1</option>
//             <option value="DG Incomer 1">DG Incomer 1</option>
//             <option value="DG Incomer 2">DG Incomer 2</option>
//             <option value="DG Incomer 3">DG Incomer 3</option>
//             <option value="Solar-1">Solar-1</option>
//             <option value="WHR-1">WHR-1</option>
//             <option value="LV-2">LV-2</option>
//             <option value="Production">Production</option>
//           </select>
//         </label>
//       </div>
//       <div ref={chartRef} style={{ width: '100%', height: '400px', marginTop: '20px'}}></div>
//     </div>
//   );
// };

// export default Downtime_Uptime;



// import React, { useEffect, useState, useRef } from 'react';
// import axios from 'axios';
// import * as echarts from 'echarts';
// import Stationpoints from '../Commonfns/Stationpoints'; 

// const Downtime_Uptime = () => {
//   const [data, setData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [stationPoint, setStationPoint] = useState('HV');
//   const chartRef = useRef(null);
//   const chartInstanceRef = useRef(null);
//   const [zoomRange, setZoomRange] = useState({ start: 0, end: 100 });

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/downtime_Report`);
//         setData(response.data);
//         filterData(response.data, stationPoint, true); // Filter for yesterday's data initially
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {
//     filterData(data, stationPoint);
//   }, [stationPoint, data]);

//   useEffect(() => {
//     if (chartInstanceRef.current) {
//       chartInstanceRef.current.dispose();
//     }
//     renderChart();
//   }, [filteredData, zoomRange]);

//   const filterData = (data, stationPoint, initial = false) => {
//     const now = new Date();
//     const yesterday = new Date(now);
//     yesterday.setDate(now.getDate() - 1);
//     const formattedYesterday = yesterday.toISOString().split('T')[0]; // Format YYYY-MM-DD

//     // Filter data based on the stationPoint and date
//     let filtered = data.filter(report => {
//       const reportDate = new Date(report.date).toISOString().split('T')[0];
//       const isStationPointMatch = (report.stationPoints && report.stationPoints.includes(stationPoint)) || 
//                                   (report.Downtime_Durations && report.Downtime_Durations[stationPoint] !== undefined) || 
//                                   (report.Uptime_Durations && report.Uptime_Durations[stationPoint] !== undefined);

//       return isStationPointMatch && (initial ? reportDate === formattedYesterday : true);
//     });

//     if (initial) {
//       setFilteredData(filtered);
//       setZoomRange({ start: 100, end: 100 }); // Set zoom to show only yesterday's data
//     } else {
//       setFilteredData(data);
//     }
//   };

//   const convertTimeToMinutes = (timeStr) => {
//     const [hours, minutes, seconds] = timeStr.split(':').map(Number);
//     return (hours * 60) + minutes + (seconds / 60);
//   };

//   const convertMinutesToTime = (minutes) => {
//     const hours = Math.floor(minutes / 60);
//     const mins = Math.floor(minutes % 60);
//     const secs = Math.round((minutes % 1) * 60);
//     return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
//   };

//   const getAxisLabelInterval = (start, end) => {
//     const totalDays = data.length;
//     const visibleDays = (totalDays * (end - start)) / 100;
//     if (visibleDays > 30) return 0; // Show all dates if more than 30 days are visible
//     if (visibleDays > 15) return 1; // Show every other date if 15-30 days are visible
//     return 2; // Show every third date if less than 15 days are visible
//   };

//   const renderChart = () => {
//     if (!chartRef.current) return;

//     chartInstanceRef.current = echarts.init(chartRef.current);

//     const allDates = data.map(report => new Date(report.date).toLocaleDateString());
//     const allDowntime = data.map(report => convertTimeToMinutes(report.Downtime_Durations[stationPoint] || "0:00:00"));
//     const allUptime = data.map(report => convertTimeToMinutes(report.Uptime_Durations[stationPoint] || "0:00:00"));

//     const dates = filteredData.map(report => new Date(report.date).toLocaleDateString());
//     const downtime = filteredData.map(report => convertTimeToMinutes(report.Downtime_Durations[stationPoint] || "0:00:00"));
//     const uptime = filteredData.map(report => convertTimeToMinutes(report.Uptime_Durations[stationPoint] || "0:00:00"));

//     const option = {
//       title: {
//         text: 'Downtime & Uptime Reports'
//       },
//       legend: {
//         data: ['Downtime', 'Uptime']
//       },
//       tooltip: {
//         trigger: 'axis',
//         axisPointer: {
//           type: 'shadow'
//         },
//         formatter: function (params) {
//           const downtimeParam = params.find(param => param.seriesName === 'Downtime');
//           const uptimeParam = params.find(param => param.seriesName === 'Uptime');
//           const downtimeValue = downtimeParam ? convertMinutesToTime(downtimeParam.value) : '';
//           const uptimeValue = uptimeParam ? convertMinutesToTime(uptimeParam.value) : '';
//           return `
//             ${params[0].axisValue}<br/>
//             Downtime: ${downtimeValue}<br/>
//             Uptime: ${uptimeValue}
//           `;
//         }
//       },
//       xAxis: [
//         {
//           type: 'category',
//           data: allDates,
//           axisLabel: {
//             rotate: 0,
//             interval: getAxisLabelInterval(zoomRange.start, zoomRange.end),
//           },
//         },
//       ],
//       yAxis: {
//         type: 'value',
//         axisLabel: {
//           formatter: function (value) {
//             return convertMinutesToTime(value);
//           }
//         }
//       },
//       dataZoom: [
//         {
//           type: 'inside',
//           start: zoomRange.start,
//           end: zoomRange.end,
//           xAxisIndex: [0],
//         },
//         {
//           type: 'slider',
//           start: zoomRange.start,
//           end: zoomRange.end,
//           handleIcon: 'path://M3 6h1v12H3zm1 0H3V6zm12 0h1v12h-1zm1 0h-1V6z',
//           handleSize: '80%',
//           left: '10%',
//           right: '10%',
//           bottom: '0%', // Position the slider further down
//           onZoom: (event) => {
//             setZoomRange({ start: event.start, end: event.end });
//             if (event.start === 0 && event.end === 100) {
//               setFilteredData(data); // Show all data when zoom is reset to full
//             }
//           }
//         }
//       ],
//       series: [
//         {
//           name: 'Downtime',
//           type: 'bar',
//           data: downtime,
//           itemStyle: {
//             color: '#ff4d4d'
//           },
//         },
//         {
//           name: 'Uptime',
//           type: 'bar',
//           data: uptime,
//           itemStyle: {
//             color: '#4caf50'
//           },
//         }
//       ]
//     };

//     chartInstanceRef.current.setOption(option);
//   };

//     // Handle station point change from Stationpoints component
//     const handleStationPointChange = (stationPoint) => {
//       setStationPoint(stationPoint);
//     };

//   return (
//     <div>
//       <div>
//         <label>
//           Station Point:
//           <Stationpoints onStationPointChange={handleStationPointChange} />
//         </label>
//       </div>
//       <div ref={chartRef} style={{ width: '100%', height: '400px', marginTop: '20px' }}></div>
//     </div>
//   );
// };

// export default Downtime_Uptime;




import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';
import Stationpoints from '../Commonfns/Stationpoints';

const Downtime_Uptime = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [stationPoint, setStationPoint] = useState('HV');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/downtime_Report`);
        setData(response.data);
        filterData(response.data, stationPoint); // Filter for last one year of data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    filterData(data, stationPoint);
  }, [stationPoint, data]);

  const filterData = (data, stationPoint) => {
    if (data.length === 0) return;

    const latestDate = new Date(Math.max(...data.map(report => new Date(report.date))));
    const oneYearAgo = new Date(latestDate);
    oneYearAgo.setFullYear(latestDate.getFullYear() - 1);

    // Filter data for the last year and based on the stationPoint
    const filtered = data.filter(report => {
      const reportDate = new Date(report.date);
      const isWithinLastYear = reportDate >= oneYearAgo && reportDate <= latestDate;
      const isStationPointMatch = (report.stationPoints && report.stationPoints.includes(stationPoint)) ||
                                  (report.Downtime_Durations && report.Downtime_Durations[stationPoint] !== undefined) ||
                                  (report.Uptime_Durations && report.Uptime_Durations[stationPoint] !== undefined);
      return isWithinLastYear && isStationPointMatch;
    });

    setFilteredData(filtered);
  };

  const generatePlotData = () => {
    const dates = filteredData.map(report => report.date);
    const downtime = filteredData.map(report => report.Downtime_Durations[stationPoint] || "00:00:00");
    const uptime = filteredData.map(report => report.Uptime_Durations[stationPoint] || "00:00:00");

    console.log('Dates:', dates);
    console.log('Downtime:', downtime);
    console.log('Uptime:', uptime);

    return [
      {
        x: dates,
        y: downtime,
        type: 'bar',
        name: 'Downtime',
        marker: { color: '#ff4d4d' }
      },
      {
        x: dates,
        y: uptime,
        type: 'bar',
        name: 'Uptime',
        marker: { color: '#7E95DF' }
      }
    ];
  };

  const handleStationPointChange = (stationPoint) => {
    setStationPoint(stationPoint);
  };

  return (
    <div>
      <div>
        <label>
          Station Point:
          <Stationpoints onStationPointChange={handleStationPointChange} />
        </label>
      </div>
      <Plot
        data={generatePlotData()}
        layout={{
          width: 1200,
          height: 500,
          title: 'Downtime & Uptime Reports',
          xaxis: {
            title: 'Date',
            type: 'date', // Ensure x-axis is interpreted as dates
            tickformat: '%Y-%m-%d', // Format for tick labels
            rangeslider: { visible: true }
          },
          yaxis: {
            title: 'Time (HH:MM:SS)',
            tickformat: '%H:%M:%S'
          },
          barmode: 'group', // To display bars side by side
          hovermode: 'x unified'
        }}
        config={{
          displayModeBar: true,
          modeBarButtonsToRemove: [
            'pan2d', 'select2d', 'lasso2d', 'autoScale2d',
            'hoverClosestCartesian', 'hoverCompareCartesian',
            'toggleSpikelines', 'resetViewMapbox', 'resetViewSankey'
          ],
          modeBarButtonsToAdd: [
            'zoomIn2d', 'zoomOut2d', 'resetScale2d', 'downloadImage'
          ],
          displaylogo: false
        }}
      />
    </div>
  );
};

export default Downtime_Uptime;



