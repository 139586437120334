import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from "./locales/en/translation.json";

i18n
  .use(LanguageDetector) // Detect user language
  .use(initReactI18next) // Pass the i18n instance to react-i18next
  .init({
    resources: {
      en: {
       translation: enTranslation
      },
    },
    fallbackLng: "en", // Default language
    interpolation: {
      escapeValue: false, // React already safes from XSS
    },
  });

export default i18n;
