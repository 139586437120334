
import { useState, React } from 'react';
import { Operatorheadingss } from '../Data';
import './AllTOD.css';
import Operatorsidebar from '../Sidebar/Sidebar';
import TodDataComponent from './TODview';
import TODviewcomponent from './SavedTOD';
import TopBar from '../../Commonfns/Topbar/Topbar';
const AllTOD = () => {
    const [activeSection, setActiveSection] = useState('TodDataComponent-1');

    const handleButtonClick = (section) => {
        setActiveSection(section);
    };


    return (
        <div id='todpage-total'>
            <div>
                <Operatorsidebar />
            </div>
            <div id='todpage-right'>
                {/* <div id='todpage-right-1'>
                    <div id='todpage-heading'>
                        {Operatorheadingss.TODHeading}
                    </div>
                    <div id='sectionpoints-heading1'>
                        {Operatorheadingss.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Operatorheadingss.logoSrc} alt='logo' />
                    </div>
                </div> */}
                 <TopBar section="TODTrackingheading" />

                <div id='todpage-right-2'>
                    <div id='todpage-right-21'>
                        <div id='todpage-right-23'>
                            <button
                                className={activeSection === 'TodDataComponent-1' ? 'active' : ''}
                                onClick={() => handleButtonClick('TodDataComponent-1')}
                            >
                                Live TOD

                            </button>
                            <button
                                className={activeSection === 'TODviewcomponent-1' ? 'active' : ''}
                                onClick={() => handleButtonClick('TODviewcomponent-1')}
                            >
                                Historical TOD
                            </button>
                        </div>
                    </div>

                    <div id='todpage-right-3'>
                        <div>
                            <div>
                                {activeSection === 'TodDataComponent-1' && <TodDataComponent />}
                                {activeSection === 'TODviewcomponent-1' && <TODviewcomponent />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllTOD;