import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Machine.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Mastermachine = ({ onViewData }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    const initialFormData = {
        date: getCurrentDate(),
        name: '',
        machineid: '',
        parentname: '',
        metermake: '',
        meterslno: '',
        phase: '',
        connectedloadinva: '',
        voltage: '',
        thresholdload: '',
        typeofload: '',
        fullloadcurrent: '',
        idleloadcurrent: '',
        ratedcurrent: '',
        frequency: '',
        outputpower: '',
        powerfactor: '',
        loadrating: '',
        impedence: '',
        proposedperiodicityofmaintenance: '',
        previousmaintenancedonedate: '',
        spare1: '',
        spare2: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-Sectionnames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = {
                ...formData,
                meterslno: Number(formData.meterslno),
                connectedloadinva: Number(formData.connectedloadinva),
                voltage: Number(formData.voltage),
                thresholdload: Number(formData.thresholdload),
                fullloadcurrent: Number(formData.fullloadcurrent),
                idleloadcurrent: Number(formData.idleloadcurrent),
                ratedcurrent: Number(formData.ratedcurrent),
                frequency: Number(formData.frequency),
                outputpower: Number(formData.outputpower),
                powerfactor: Number(formData.powerfactor),
                impedence: Number(formData.impedence)
            };

            await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/mastermachines`, dataToSend);

            toast.success('Master Machine added successfully');
            setFormData(initialFormData);
            fetchParentNames();
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    return (
        <div id='mastermachine-total'>
            <div id='mastermachine-right'>
                <div id='mastermachine-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='mastermachine-divtop'>
                            <label>
                                <span> {Masterformsheadingsss.MachineHeading}</span>
                            </label>
                            <label>
                                <button type="button" onClick={onViewData}>View Data</button>
                            </label>
                        </div>
                        <div id='mastermachine-div1'>
                            <label>
                                <span>Date</span>
                                <input type='date' name="date" value={formData.date} disabled />
                            </label>
                            <label>
                                <span>Parent Name</span>
                                <select name="parentname" value={formData.parentname} onChange={handleChange} >
                                    <option value="">Select Parent Name</option>
                                    {parentNames.map((name, index) => (
                                        <option key={index} value={name}>{name}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div id='mastermachine-div1'>
                            <label>
                                <span>Name</span>
                                <input name="name" value={formData.name} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Meter Make</span>
                                <input name="metermake" value={formData.metermake} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastermachine-div1'>
                            <label>
                                <span>Meter Serial No.</span>
                                <input type='number' name="meterslno" value={formData.meterslno} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Phase</span>
                                <select name="phase" value={formData.phase} onChange={handleChange} required>
                                    <option value=''>Select Phase</option>
                                    <option value='3-Phase'>3-Phase</option>
                                    <option value='1-Phase-Vr'>1-Phase-Vr</option>
                                    <option value='1-Phase-Vy'>1-Phase-Vy</option>
                                    <option value='1-Phase-Vb'>1-Phase-Vb</option>
                                </select>
                            </label>
                        </div>
                        <div id='mastermachine-div1'>
                            <label>
                                <span>Connected Load (in kVA)</span>
                                <input type='number' name="connectedloadinva" value={formData.connectedloadinva} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Voltage</span>
                                <input type='number' name="voltage" value={formData.voltage} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastermachine-div1'>
                            <label>
                                <span>Threshold Load</span>
                                <input type='number' name="thresholdload" value={formData.thresholdload} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Type of Load</span>
                                <input name="typeofload" value={formData.typeofload} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastermachine-div1'>
                            <label>
                                <span>Full Load Current</span>
                                <input type='number' name="fullloadcurrent" value={formData.fullloadcurrent} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Idle Load Current</span>
                                <input type='number' name="idleloadcurrent" value={formData.idleloadcurrent} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastermachine-div1'>
                            <label>
                                <span>Rated Current</span>
                                <input type='number' name="ratedcurrent" value={formData.ratedcurrent} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Frequency</span>
                                <input type='number' name="frequency" value={formData.frequency} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastermachine-div1'>
                            <label>
                                <span>Output Power</span>
                                <input type='number' name="outputpower" value={formData.outputpower} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Power Factor</span>
                                <input type='number' name="powerfactor" value={formData.powerfactor} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastermachine-div1'>
                            <label>
                                <span>Load Rating</span>
                                <select name="loadrating" value={formData.loadrating} onChange={handleChange} required >
                                    <option value=''> Select Load Rating</option>
                                    <option value='Continious'>Continious</option>
                                    <option value='Short Time'>Short Time</option>
                                    <option value='Impulse'>Impulse</option>
                                </select>

                            </label>
                            <label>
                                <span>Impedence</span>
                                <input type='number' name="impedence" value={formData.impedence} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastermachine-div1'>
                            <label>
                                <span>Proposed Periodicity of Maintenance</span>
                                <input name="proposedperiodicityofmaintenance" value={formData.proposedperiodicityofmaintenance} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Previous Maintenance Done Date</span>
                                <input type='date' name="previousmaintenancedonedate" value={formData.previousmaintenancedonedate} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastermachine-divbottom'>
                            <label>
                                <button id='mastermachine-save'>{Masterformsheadingsss.Submitheading}</button>

                            </label>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Mastermachine;
