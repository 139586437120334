

import React, { useState, useEffect  } from "react";
import { Link , useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Logo from '../../../Images/logo.svg';
import "../../Operator/Sidebar/Sidebar.css";
import {  FaHome } from "react-icons/fa";
import { MdContactPhone } from "react-icons/md";
import UserdetailsHeader from "../../UserManagement/Details/Userdetailshead";
import { FaRegUserCircle } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { PiUserSwitchBold } from "react-icons/pi";
import { RiAdminFill } from "react-icons/ri";
import { FaUsers } from "react-icons/fa6";
import { FaUserCog , FaUserLock } from "react-icons/fa";
const Superadminsidebar = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [username, setUsername] = useState('');
  const [designation, setDesignation] = useState('');
  const [level, setLevel] = useState('');
  const [isLevel1User, setIsLevel1User] = useState(false);
    useEffect(() => {
        // Retrieve the user data from local storage
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
          setUsername(user.username);
          setLevel(user.level);
          setDesignation(user.designation);
        }

        if (user && user.level === "Level-1") {
            setIsLevel1User(true);
        }
      }, []);
    const onleave = () => {
        setIsOpen(false);
        setActiveDropdown(null); // Close any open dropdown when the sidebar loses focus
    };

    const onenter = () => {
        setIsOpen(true);
    };

    const toggleDropdown = (dropdownName) => {
        if (activeDropdown === dropdownName) {
            setActiveDropdown(null);
        } else {
            setActiveDropdown(dropdownName);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('accessedLevel');
        navigate('/');
      };

    return (
        <div>
            <div className={`sidebar ${isOpen ? "sidebar--open" : ""}`} onMouseLeave={onleave} onMouseEnter={onenter}>
                <div className="trigger">
                    <Link to='/'>
                        <div className="sidbarMainlogo">
                            <img src={Logo} alt="" />
                        </div>
                    </Link>
                    <FontAwesomeIcon icon={isOpen ? '' : faBars} />
                </div>

                <Link className="sidebar-position">
          <FaRegUserCircle className="sidebar-iconss" />
          <label style={{ display: 'flex', flexDirection: 'column' }}>
            <span>Username: {username}</span>
            <span style={{ marginTop: '3px' }}> Designation: {username}</span>
          </label>
        </Link>
                {/* <Link to='/Designation' className="sidebar-position">
                    <FaHome />
                    <span>Home</span>
                </Link> */}

                
                <Link to='/Designation' className="sidebar-position">
                    <FaUserCog />
                    <span>Designation</span>
                </Link>

               
                {isLevel1User && (
                    <Link to='/SuperadminRegistration' className="sidebar-position">
                        <FaUserLock />
                        <span>Super Admin</span>
                    </Link>
                )}
         
                <Link to='/Usercreation' className="sidebar-position">
                    <FaUsers />
                    <span>Users</span>
                </Link>

                {level !== 'Level-4' && (
          <Link to='/Loginscreens' className="sidebar-position">
            <PiUserSwitchBold className="sidebar-iconss1" />
            <span>Switch</span>
          </Link>
        )}

        <Link onClick={handleLogout} to='/' className="sidebar-position">
          <IoMdLogOut className="sidebar-iconss" />
          <span>Logout</span>
        </Link>

                {/* <Link to='/' className="sidebar-position">
                    <MdContactPhone />
                    <span>Logout</span>
                </Link> */}
            </div>
        </div>
    );
};

export default Superadminsidebar;