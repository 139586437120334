
// import React, { useEffect, useState } from 'react';
// import './Accumulator.css';

// const Accumulator = ({ selectedSection, refresh }) => {
//   const [accumulatorData, setAccumulatorData] = useState([]);

//   const fetchAccumulatorData = async () => {
//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/accumulatordata`);
//       const data = await response.json();
//       if (Array.isArray(data)) {
//         setAccumulatorData(data);
//       } else {
//         console.error('Status data is not an array:', data);
//       }
//     } catch (error) {
//       console.error('Error fetching accumulator data:', error);
//     }
//   };

//   useEffect(() => {
//     fetchAccumulatorData();
//   }, [refresh]);

  
//   const filteredData = selectedSection
//   ? accumulatorData.filter(accumulator => accumulator.stationPoint === selectedSection)
//   : accumulatorData;

//   return (
//     <div id='accumulator-totalcontent'>
//       <table>
//         <thead>
//           <tr>
//           <th>S No</th>
//             <th>Section</th>
//             <th>Parameter Name</th>
//             <th>Value</th>
//           </tr>
//         </thead>
//         <tbody>
//           {filteredData.map((accumulator, index) => (
//             <tr key={index}>
//               <td>{index + 1}</td>
//               <td>{accumulator.stationPoint}</td>
//               <td>{accumulator.Parameter}</td>
//               <td>{accumulator.Value}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default Accumulator;


import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import './Accumulator.css';
const Accumulator = ({ selectedSection }) => {
  const [mqttData, setMqttData] = useState([]);

  // useEffect(() => {
  //   const socket = io(process.env.REACT_APP_SECTIONS_URL);

  //   socket.on('connect', () => {
  //     console.log('Connected to WebSocket server');
  //   });

  //   socket.on('disconnect', () => {
  //     console.log('Disconnected from WebSocket server');
  //   });

  //   socket.on('mqtt_data', (data) => {
  //     console.log('Received data:', data);

  //     setMqttData((prevData) => {
  //       const existingDataIndex = prevData.findIndex(item => item.deviceEUi === data.deviceEUi);
  //       if (existingDataIndex !== -1) {
  //         // Update existing data if deviceEUi already exists in state
  //         const updatedData = [...prevData];
  //         updatedData[existingDataIndex] = data;
  //         return updatedData;
  //       } else {
  //         // Add new data if deviceEUi is not in state
  //         return [data, ...prevData];
  //       }
  //     });
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);


  useEffect(() => {
    // Open a WebSocket connection to the server
    const ws = new WebSocket(process.env.REACT_APP_SECTIONS_URL);

    // When a message is received from the server, update the state
    ws.onmessage = (event) => {

      const data = JSON.parse(event.data);

      setMqttData(data);
    };

    // Clean up the WebSocket connection when the component unmounts
    return () => ws.close();
  }, []);

  const filteredData = selectedSection ? mqttData.filter(data => data.section === selectedSection) : mqttData;


  return (

      <div id='accumulator-totalcontent'>
      <table>
        <thead>
          <tr>
            <th>Division</th>
            <th>Section</th>
            <th>Key</th>
            <th>Timestamp</th>
            {/* <th>Vr</th>
            <th>Vy</th>
            <th>Vb</th>
            <th>Vavg</th>
            <th>Ir</th>
            <th>Iy</th>
            <th>Ib</th>
            <th>Iavg</th>
            <th>Imax</th>
            <th>Fr</th>
            <th>THDvr</th>
            <th>THDvy</th>
            <th>THDvb</th>
            <th>THDir</th>
            <th>THDiy</th>
            <th>THDib</th>
            <th>Vry</th>
            <th>Vyb</th>
            <th>Vbr</th>
            <th>PF</th>
            <th>kW</th>
            <th>kVA</th> */}
            <th>kWh</th>
            <th>KVArh</th>
            <th>KVAh</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map(data => (
            <tr key={data.deviceEUi}>
              <td>{data.division}</td>
              <td>{data.section}</td>
              <td>{data.Key}</td>
              <td>{data.utcTimestamp}</td>
              {/* <td>{data.Vr}</td>
              <td>{data.Vy}</td>
              <td>{data.Vb}</td>
              <td>{data.Vavg}</td>
              <td>{data.Ir}</td>
              <td>{data.Iy}</td>
              <td>{data.Ib}</td>
              <td>{data.Iavg}</td>
              <td>{data.Imax}</td>
              <td>{data.Fr}</td>
              <td>{data.THDvr}</td>
              <td>{data.THDvy}</td>
              <td>{data.THDvb}</td>
              <td>{data.THDir}</td>
              <td>{data.THDiy}</td>
              <td>{data.THDib}</td>
              <td>{data.Vry}</td>
              <td>{data.Vyb}</td>
              <td>{data.Vbr}</td>
              <td>{data.Pf}</td>
              <td>{data.kW}</td>
              <td>{data.kVA}</td> */}
              <td>{data.kWh}</td>
              <td>{data.KVArh}</td>
              <td>{data.KVAh}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Accumulator;
