import React, { useState } from 'react';

const Transformerform = () => {
  const [formData, setFormData] = useState({
    transformernameid: '',
    transformername: '',
    modifiedby: '',
    modifiedat: '',
    createdby: '',
    createdat: '',
    status: 'active',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/transformerfrom`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (response.ok) {
        console.log('Form submitted successfully:', data);
        setFormData({
          transformernameid: '',
          transformername: '',
          modifiedby: '',
          modifiedat: '',
          createdby: '',
          createdat: '',
          status: 'active',
        });
      } else {
        // Handle error
        console.error('Form submission failed:', data);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        <span>Transformer Name id</span>
        <input name="transformernameid" value={formData.transformernameid} onChange={handleChange} />
      </label>
      <label>
        <span>Transformer Name</span>
        <input name="transformername" value={formData.transformername} onChange={handleChange} />
      </label>
      <label>
        <span>Modified By</span>
        <input name="modifiedby" value={formData.modifiedby} onChange={handleChange} />
      </label>
      <label>
        <span>Modified At</span>
        <input name="modifiedat" value={formData.modifiedat} onChange={handleChange} />
      </label>
      <label>
        <span>Created By</span>
        <input name="createdby" value={formData.createdby} onChange={handleChange} />
      </label>
      <label>
        <span>Created At</span>
        <input name="createdat" value={formData.createdat} onChange={handleChange} />
      </label>
      <button type="submit">Submit</button>
    </form>
  );
};

export default Transformerform;
