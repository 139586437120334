import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Generators.css';  // Make sure the CSS file is renamed accordingly
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Mastergenerator = ({ onViewData }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    const initialFormData = {
        date: getCurrentDate(),
        name: '',
        typeofgenerator: '',
        mastergeneratorid: '',
        parentname: '',
        metermake: '',
        meterslno: '',
        phase: '',
        generatorpowerinva: '',
        voltagelevel: '',
        frequency: '',
        enginepower: '',
        coolingsystem: '',
        fuelconsumptionperhour: '',
        fuelcosumptionper1kwh: '',
        starttype: '',
        proposedperiodicityofmaintenance: '',
        previousmaintenancedonedate: '',
        spare1: '',
        spare2: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-transformerlvNames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = {
                ...formData,
                meterslno: Number(formData.meterslno),
                generatorpowerinva: Number(formData.generatorpowerinva),
                voltagelevel: Number(formData.voltagelevel),
                frequency: Number(formData.frequency),
                enginepower: Number(formData.enginepower),
                fuelconsumptionperhour: Number(formData.fuelconsumptionperhour),
                fuelcosumptionper1kwh: Number(formData.fuelcosumptionper1kwh),
            };

            await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/mastergenerators`, dataToSend);

            toast.success('Master Generator added successfully');
            setFormData(initialFormData);
            fetchParentNames();
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    return (
        <div id='mastergen-total'>
            <div id='mastergen-right'>
                <div id='mastergen-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='mastergen-divtop'>
                            <label>
                                <span> {Masterformsheadingsss.GeneratorHeading}</span>

                            </label>
                            <label>
                                <button type="button" onClick={onViewData}>View Data</button>

                            </label>
                        </div>
                        <div id='mastergen-div1'>
                            <label>
                                <span>Date</span>
                                <input type='date' name="date" value={formData.date} disabled />
                            </label>
                            <label>
                                <span>Parent Name</span>
                                <select name="parentname" value={formData.parentname} onChange={handleChange} >
                                    <option value="">Select Parent Name</option>
                                    {parentNames.map((name, index) => (
                                        <option key={index} value={name}>{name}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div id='mastergen-div1'>
                            <label>
                                <span>Name</span>
                                <input name="name" value={formData.name} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Type of Generator</span>
                                <select name="typeofgenerator" value={formData.typeofgenerator} onChange={handleChange} required>
                                    <option value=''>Select type of generator</option>
                                    <option value='Solar'>Solar</option>
                                    <option value='Diesel Generator (DG)'>Diesel Generator (DG) </option>
                                    <option value='Waste Heat Recovery (WHR)'>Waste Heat Recovery (WHR)</option>
                                    <option value='Wind'>Wind</option>
                                    <option value='Hydro'>Hydro</option>
                                    <option value='Bio energy'>Bio energy</option>
                                </select>
                                {/* <input name="typeofgenerator" value={formData.typeofgenerator} onChange={handleChange} required /> */}
                            </label>
                        </div>
                        <div id='mastergen-div1'>

                            <label>
                                <span>Meter Make</span>
                                <input name="metermake" value={formData.metermake} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Meter Serial No.</span>
                                <input type='number' name="meterslno" value={formData.meterslno} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastergen-div1'>

                            <label>
                                <span>Phase</span>
                                <select name="phase" value={formData.phase} onChange={handleChange} required>
                                    <option value=''>Select Phase</option>
                                    <option value='3-Phase'>3-Phase</option>
                                    <option value='1-Phase-Vr'>1-Phase-Vr</option>
                                    <option value='1-Phase-Vy'>1-Phase-Vy</option>
                                    <option value='1-Phase-Vb'>1-Phase-Vb</option>
                                </select>
                            </label>
                            <label>
                                <span>Generator Power (in kVA)</span>
                                <input type='number' name="generatorpowerinva" value={formData.generatorpowerinva} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastergen-div1'>

                            <label>
                                <span>Voltage Level</span>
                                <input type='number' name="voltagelevel" value={formData.voltagelevel} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Frequency</span>
                                <input type='number' name="frequency" value={formData.frequency} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastergen-div1'>

                            <label>
                                <span>Engine Power</span>
                                <input type='number' name="enginepower" value={formData.enginepower} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Cooling System</span>
                                <input name="coolingsystem" value={formData.coolingsystem} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastergen-div1'>

                            <label>
                                <span>Fuel Consumption per Hour</span>
                                <input type='number' name="fuelconsumptionperhour" value={formData.fuelconsumptionperhour} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Fuel Consumption per 1 kWh</span>
                                <input type='number' name="fuelcosumptionper1kwh" value={formData.fuelcosumptionper1kwh} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastergen-div1'>

                            <label>
                                <span>Start Type</span>
                                <input name="starttype" value={formData.starttype} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Proposed Periodicity of Maintenance</span>
                                <input name="proposedperiodicityofmaintenance" value={formData.proposedperiodicityofmaintenance} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastergen-div1'>

                            <label>
                                <span>Previous Maintenance Done Date</span>
                                <input type='date' name="previousmaintenancedonedate" value={formData.previousmaintenancedonedate} onChange={handleChange} required />
                            </label>
                            {/* <label>
                                <span>Spare 1</span>
                                <input name="spare1" value={formData.spare1} onChange={handleChange} />
                            </label> */}
                        </div>
                        <div id='mastergen-divbottom'>
                            <label>
                                <button id='mastergen-save' type="submit">{Masterformsheadingsss.Submitheading}</button>
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Mastergenerator;
