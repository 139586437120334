// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unfilled_forms_total{
    background-color: white;
    border: 1px solid #DFE6FA;
    height: 70vh;
    border-radius: 10px;
    align-content: center;
    justify-content: center;

    table{
        margin: 20px;
        width: 88vw;
        height: 60vh;
        display: block;
        overflow-y: scroll;


        th,
        td {
          width: calc(88vw/ 4);
          /* Adjust this to fit your column count */
        }

        button{
            background-color: #D8E1FF;
            color: black;
            border: 1px solid white;
            border: none;
            border-radius: 4px;
            padding: 10px 20px;
            cursor: pointer;
        }
    }
}


@media screen and (min-width: 1440px) {

.unfilled_forms_total {
    table {
        width: 90vw;
    }
}
}

@media screen and (min-width: 1920px) {
    .unfilled_forms_total {
        table {
            width: 91vw;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Diagnose/Pendingtasks/Unfilled_forms/Unfilled_forms.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,qBAAqB;IACrB,uBAAuB;;IAEvB;QACI,YAAY;QACZ,WAAW;QACX,YAAY;QACZ,cAAc;QACd,kBAAkB;;;QAGlB;;UAEE,oBAAoB;UACpB,yCAAyC;QAC3C;;QAEA;YACI,yBAAyB;YACzB,YAAY;YACZ,uBAAuB;YACvB,YAAY;YACZ,kBAAkB;YAClB,kBAAkB;YAClB,eAAe;QACnB;IACJ;AACJ;;;AAGA;;AAEA;IACI;QACI,WAAW;IACf;AACJ;AACA;;AAEA;IACI;QACI;YACI,WAAW;QACf;IACJ;AACJ","sourcesContent":[".unfilled_forms_total{\n    background-color: white;\n    border: 1px solid #DFE6FA;\n    height: 70vh;\n    border-radius: 10px;\n    align-content: center;\n    justify-content: center;\n\n    table{\n        margin: 20px;\n        width: 88vw;\n        height: 60vh;\n        display: block;\n        overflow-y: scroll;\n\n\n        th,\n        td {\n          width: calc(88vw/ 4);\n          /* Adjust this to fit your column count */\n        }\n\n        button{\n            background-color: #D8E1FF;\n            color: black;\n            border: 1px solid white;\n            border: none;\n            border-radius: 4px;\n            padding: 10px 20px;\n            cursor: pointer;\n        }\n    }\n}\n\n\n@media screen and (min-width: 1440px) {\n\n.unfilled_forms_total {\n    table {\n        width: 90vw;\n    }\n}\n}\n\n@media screen and (min-width: 1920px) {\n    .unfilled_forms_total {\n        table {\n            width: 91vw;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
