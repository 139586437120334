import React, { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { UserContext } from './UserContext';

const RestrictedRoute = () => {
  const { user } = useContext(UserContext);

  if (!user) {
    // If the user is not authenticated, redirect to login page
    return <Navigate to="/" replace />;
  }

  if (user.level === 'Level-4') {
    // If the user is Level-4, restrict access and redirect to "/Operator-Dash"
    return <Navigate to="/Operator-Dash" replace />;
  }

  // If the user is not Level-4, allow access to the child routes
  return <Outlet />;
};

export default RestrictedRoute;
