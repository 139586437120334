import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import './Alertsdurationfetch.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Alertsdurationfetch = ({ onFormButtonClick }) => {
    const [alertsDuration, setAlertsDuration] = useState([]);
    const [alertEditData, setAlertEditData] = useState(null);

    const fetchAlertsDuration = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/alertsduration`);
            setAlertsDuration(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchAlertsDuration();
    }, []);

    const handleEditClick = (alert) => {
        setAlertEditData(alert);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setAlertEditData({ ...alertEditData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        console.log('Updating data:', alertEditData);
        toast.success("Alert Duration updated successfully");
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/alertsduration/${alertEditData._id}`, alertEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchAlertsDuration();
            setAlertEditData(null);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='alertsdurationfetch-total'>
            <div id='alertsdurationfetch-right'>
                <div id='alertsdurationfetch-right-2'>
                    <div id='alertsdurationfetch-right-21'>
                        <label>
                            <span> {Masterformsheadingsss.AlertsdurationHeading}</span>
                        </label>
                        <label>
                            <button onClick={onFormButtonClick}> {Masterformsheadingsss.Formheading}</button>
                        </label>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Alert Type</th>
                                <th>Duration</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {alertsDuration.map((alert) => (
                                <tr key={alert._id}>
                                    <td>{alert.date}</td>
                                    <td>{alert.alert_type}</td>
                                    <td>{alert.duration}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(alert)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {alertEditData && (
                        <Popup open={true} onClose={() => setAlertEditData(null)}>
                            <div id='alertsdurationfetch-popup'>
                                <h3>Edit Alert Duration</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='alertsdurationfetch-popup1'>
                                        <label>
                                            <span>Date</span>
                                            <input type="date" name="date" value={alertEditData.date} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Alert Type</span>
                                            <select name="alert_type" value={alertEditData.alert_type} onChange={handleEditChange}>
                                                <option value="">Select Alert Type</option>
                                                <option value="Voltage loss">Voltage loss</option>
                                                <option value="Abnormal PF loss">Abnormal PF loss</option>
                                                <option value="THD loss">THD loss</option>
                                                <option value="Unbalanced loads">Unbalanced loads</option>
                                                <option value="Negative PF loss">Negative PF loss</option>
                                                <option value="Idle time loss">Idle time loss</option>
                                                <option value="Transformer loss">Transformer loss</option>
                                                <option value="Utilization loss">Utilization loss</option>
                                                <option value="Currents loss">Currents loss</option>
                                                <option value="TOD loss">TOD loss</option>
                                                <option value="KVA">KVA</option>
                                            </select>
                                            {/* <input type="text" name="alert_type" value={alertEditData.alert_type} onChange={handleEditChange} /> */}
                                        </label>
                                    </div>
                                    <div id='alertsdurationfetch-popup1'>
                                        <label>
                                            <span>Duration (in Mins)</span>
                                            <input type="text" name="duration" value={alertEditData.duration} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <button id='alertsdurationfetch-popup-update' type="submit"> {Masterformsheadingsss.Updateheading}</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Alertsdurationfetch;
