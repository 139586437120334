// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#powerdiagnose-total {
    display: flex;
    background-color: #F3F6FE;
    min-height: 100vh;
    /* position: fixed; */

}

#powerdiagnose-right {
    margin-left: 4vw;
}



#powerdiagnose-right-2 {
    margin-top: 15px;
    padding: 0px 30px 5px 30px;
}
#powerdiagnose-right-3{

    margin-top: 2vh;
    height: auto;
    width: 95.6%;
    /* background-color: #DFF2DC;
    border: 1px solid #b2e8a5; */
    background-color: white;
    border: 1px solid #b2e8a5;
    border-radius: 10px;
    padding: 5px 20px 20px 20px;

    #powerdiagnose-right-3-1{

        margin-top: 5vh;

        label{
            margin-top: 5px;
            display: flex;
            flex-direction: column;

            
            button {
                margin-top: 10px;
                background-color: #252B3F;
                color: white;
                border: none;
                border-radius: 4px;
                padding: 8px 20px;
                cursor: pointer;
                width: 12vw;
            }

            button:disabled {
                cursor: not-allowed;
                opacity: 0.6;
            }

            button.acknowledged {
                cursor: not-allowed;
                opacity: 0.6;
            }
        }

    }
}`, "",{"version":3,"sources":["webpack://./src/Components/RevenuelossDiagnose/Powersurgediagnose/Powersurgediagnose.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,iBAAiB;IACjB,qBAAqB;;AAEzB;;AAEA;IACI,gBAAgB;AACpB;;;;AAIA;IACI,gBAAgB;IAChB,0BAA0B;AAC9B;AACA;;IAEI,eAAe;IACf,YAAY;IACZ,YAAY;IACZ;gCAC4B;IAC5B,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;IACnB,2BAA2B;;IAE3B;;QAEI,eAAe;;QAEf;YACI,eAAe;YACf,aAAa;YACb,sBAAsB;;;YAGtB;gBACI,gBAAgB;gBAChB,yBAAyB;gBACzB,YAAY;gBACZ,YAAY;gBACZ,kBAAkB;gBAClB,iBAAiB;gBACjB,eAAe;gBACf,WAAW;YACf;;YAEA;gBACI,mBAAmB;gBACnB,YAAY;YAChB;;YAEA;gBACI,mBAAmB;gBACnB,YAAY;YAChB;QACJ;;IAEJ;AACJ","sourcesContent":["#powerdiagnose-total {\n    display: flex;\n    background-color: #F3F6FE;\n    min-height: 100vh;\n    /* position: fixed; */\n\n}\n\n#powerdiagnose-right {\n    margin-left: 4vw;\n}\n\n\n\n#powerdiagnose-right-2 {\n    margin-top: 15px;\n    padding: 0px 30px 5px 30px;\n}\n#powerdiagnose-right-3{\n\n    margin-top: 2vh;\n    height: auto;\n    width: 95.6%;\n    /* background-color: #DFF2DC;\n    border: 1px solid #b2e8a5; */\n    background-color: white;\n    border: 1px solid #b2e8a5;\n    border-radius: 10px;\n    padding: 5px 20px 20px 20px;\n\n    #powerdiagnose-right-3-1{\n\n        margin-top: 5vh;\n\n        label{\n            margin-top: 5px;\n            display: flex;\n            flex-direction: column;\n\n            \n            button {\n                margin-top: 10px;\n                background-color: #252B3F;\n                color: white;\n                border: none;\n                border-radius: 4px;\n                padding: 8px 20px;\n                cursor: pointer;\n                width: 12vw;\n            }\n\n            button:disabled {\n                cursor: not-allowed;\n                opacity: 0.6;\n            }\n\n            button.acknowledged {\n                cursor: not-allowed;\n                opacity: 0.6;\n            }\n        }\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
