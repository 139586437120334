
import { useState, React, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Formscomponent.css';
import Productionentry from '../../../Supervisor/Productionentry/Productionentry';
import PowerBills from '../../../Supervisor/PowerBills/PowerBills';
import Transformermonitoring from '../Tranformermonitoringlogs/Transformermonitoring';
import Operatorsidebar from '../../Sidebar/Sidebar';
import Transformermonitoringfetch from '../Tranformermonitoringlogs/Transformermonitoringfetch';
import PowerBillsfetch from '../../../Supervisor/PowerBills/PowerBillsfetch';
import Breakdownlogs from '../Breakdownlogs/Breakdownlogs';
import Breakdownlogsfetch from '../Breakdownlogs/Breakdownlogsfetch';
import DGlogs from '../DGlogs/DGlogs';
import DGlogsfetch from '../DGlogs/DGlogsfetch';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import Productionentryfetch from '../../../Supervisor/Productionentry/Productionentryfetch';
import TopBar from '../../../Commonfns/Topbar/Topbar';
import TargetEfficiency from '../TargetEfficiency/TargetEfficiency';
import TargetEfficiencyfetch from '../TargetEfficiency/TargetEfficiencyfetch';
const Formscomponent = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialSection = queryParams.get('section') || 'Transformermonitoring-1';
    const initialDate = queryParams.get('date') || '';

    const [activeSection, setActiveSection] = useState(initialSection);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    const [accessedLevel, setAccessedLevel] = useState(null);

    useEffect(() => {
        // Fetch the accessed level from localStorage
        const level = localStorage.getItem('accessedLevel');
        setAccessedLevel(level);
    }, []);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
        }
        setUserDesignation(user.level);
    }, []);

    const handleButtonClick = (section) => {
        console.log(`Navigating to section: ${section}`);
        setActiveSection(section);
    };

    const isTransformermonitoringActive =
        activeSection === 'Transformermonitoring-1' || activeSection === 'Transformermonitoringfetch';

    const isPowerBillsActive =
        activeSection === 'PowerBills-1' || activeSection === 'PowerBillsfetch';

    const isProductionentryActive =
        activeSection === 'Productionentry-1' || activeSection === 'Productionentryfetch';

    const isBreakdownlogsActive =
        activeSection === 'Breakdownlogs-1' || activeSection === 'Breakdownlogsfetch';

    const isDGlogsActive =
        activeSection === 'DGlogs-1' || activeSection === 'DGlogsfetch';

    const isTargetEfficiencyActive =
        activeSection === 'TargetEfficiency-1' || activeSection === 'TargetEfficiencyfetch';


    useEffect(() => {

        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        };

        scrollToTop();

    }, []);

    return (
        <div id='formscomponent-total'>

            <div>
                {accessedLevel === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />}
            </div>
            <div id='formscomponent-right'>
                <TopBar section="Forms" />

                <div id='formscomponent-right-2'>
                    <div id='formscomponent-right-21'>
                        <div id='formscomponent-right-23'>
                            <button
                                className={isTransformermonitoringActive ? 'active' : ''}
                                onClick={() => handleButtonClick('Transformermonitoring-1')}
                            >
                                Transformer Monitoring
                            </button>
                            <button
                                className={isProductionentryActive ? 'active' : ''}
                                onClick={() => handleButtonClick('Productionentry-1')}
                            >
                                Production Entry
                            </button>
                            <button
                                className={isPowerBillsActive ? 'active' : ''}
                                onClick={() => handleButtonClick('PowerBills-1')}
                            >
                                Monthly Power Bill
                            </button>

                            <button
                                className={isTargetEfficiencyActive ? 'active' : ''}
                                onClick={() => handleButtonClick('TargetEfficiency-1')}
                            >
                                Target Efficiency
                            </button>


                            <button
                                className={isBreakdownlogsActive ? 'active' : ''}
                                onClick={() => handleButtonClick('Breakdownlogs-1')}
                            >
                                Breakdown Tracking
                            </button>


                            <button
                                className={isDGlogsActive ? 'active' : ''}
                                onClick={() => handleButtonClick('DGlogs-1')}
                            >
                                DG Tracking
                            </button>



                        </div>
                    </div>

                    <div id='formscomponent-right-3'>
                        <div>
                            <div>

                                {activeSection === 'Transformermonitoring-1' && (
                                    <Transformermonitoring
                                        initialDate={initialDate}
                                        onViewData={() => handleButtonClick('Transformermonitoringfetch')}
                                    />
                                )}
                                {activeSection === 'Productionentry-1' && (
                                    <Productionentry
                                        initialDate={initialDate}
                                        onViewData={() => handleButtonClick('Productionentryfetch')}
                                    />
                                )}

                                {activeSection === 'PowerBills-1' && (
                                    <PowerBills
                                        initialDate={initialDate}
                                        onViewData={() => handleButtonClick('PowerBillsfetch')}
                                    />
                                )}

                                {activeSection === 'Breakdownlogs-1' && (
                                    <Breakdownlogs
                                        onViewData={() => handleButtonClick('Breakdownlogsfetch')}
                                    />
                                )}

                                {activeSection === 'DGlogs-1' && (
                                    <DGlogs
                                        onViewData={() => handleButtonClick('DGlogsfetch')}
                                    />
                                )}

                                {activeSection === 'DGlogs-1' && (
                                    <DGlogs
                                        onViewData={() => handleButtonClick('DGlogsfetch')}
                                    />
                                )}

                                {activeSection === 'TargetEfficiency-1' && (
                                    <TargetEfficiency
                                        onViewData={() => handleButtonClick('TargetEfficiencyfetch')}
                                    />
                                )}




                                {activeSection === 'Transformermonitoringfetch' && (
                                    <Transformermonitoringfetch
                                        onFormButtonClick={() => handleButtonClick('Transformermonitoring-1')}
                                    />
                                )}

                                {activeSection === 'Productionentryfetch' && (
                                    <Productionentryfetch
                                        onFormButtonClick={() => handleButtonClick('Productionentry-1')}
                                    />
                                )}

                                {activeSection === 'PowerBillsfetch' && (
                                    <PowerBillsfetch
                                        onFormButtonClick={() => handleButtonClick('PowerBills-1')}
                                    />
                                )}

                                {activeSection === 'Breakdownlogsfetch' && (
                                    <Breakdownlogsfetch
                                        onFormButtonClick={() => handleButtonClick('Breakdownlogs-1')}
                                    />
                                )}

                                {activeSection === 'DGlogsfetch' && (
                                    <DGlogsfetch
                                        onFormButtonClick={() => handleButtonClick('DGlogs-1')}
                                    />
                                )}

                                {activeSection === 'TargetEfficiencyfetch' && (
                                    <TargetEfficiencyfetch
                                        onFormButtonClick={() => handleButtonClick('TargetEfficiency-1')}
                                    />
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Formscomponent