import React, { useState , useEffect} from 'react';
import './Internal_Fault.css';
import axios from 'axios';
import { IoIosAddCircle, IoIosRemoveCircle } from "react-icons/io";
import { Power_Surgesheader } from '../../../Operator/Data';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import { getCurrentDateTime } from '../../../Diagnose/dateUtils';
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const Internal_Fault = () => {
    const { token_id } = useParams();
    const navigate = useNavigate();
    const [faults, setFaults] = useState([{ machine_name: '', type_of_fault: '' }]);

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
      // Retrieve the logged-in user from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        setLoggedInUser(user);
      }
      setUserDesignation(user.level);
    }, []);

    const handleAdd = () => {
        setFaults([...faults, { machine_name: '', type_of_fault: '' }]);
    };

    const handleChange = (index, event) => {
        const { name, value } = event.target;
        const newFaults = [...faults];
        newFaults[index][name] = value;
        setFaults(newFaults);
    };

    const handleCancel = (index) => {
        const newFaults = faults.filter((_, i) => i !== index);
        setFaults(newFaults);
    };

    const initialFaultData = {
        dateandtime: getCurrentDateTime(),
        machine_name: '',
        type_of_fault: '',
        token_id: token_id,
        createdby: '',
        createdat: '',
        modifiedby: '',
        modifiedat: '',
        spare1: '',
        spare2: ''
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = faults.map(fault => ({
                ...initialFaultData,
                ...fault,
            }));

            const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/internalfaults`, dataToSend);

            console.log('Data posted successfully:', response.data);

            alert('Internal Fault data added successfully');
            setFaults([{ machine_name: '', type_of_fault: '' }]);

            navigate('/Internal_Faultfetch');
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to add Internal Fault data');
        }
    };

    return (
        <div id='internalfault-total'>
            <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />}
            </div>
            <div id='internalfault-right'>
                <div id='internalfault-right-1'>
                    <div id='internalfault-heading'>
                        {Power_Surgesheader.alertHeading}
                    </div>
                    <div id='internalfault-heading1'>
                        {Power_Surgesheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Power_Surgesheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <h3 id='internalfault-h3'>{Power_Surgesheader.subalertHeading}</h3>
                <div id='internalfault-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='internalfault-divtop'>
                            <label>
                                <span>Internal fault found in any machine</span>
                                {/* <span>#123456</span> */}
                            </label>
                            {/* <label>
                                <Link to='/Internal_Faultfetch'><button type="button">View Data</button></Link>
                            </label> */}
                        </div>
                        {faults.map((fault, index) => (
                            <div key={index} id='internalfault-row'>
                                <label>
                                    <span>Machine Name <span id='all-mandetorymark'>*</span></span>
                                    <input
                                        name="machine_name"
                                        value={fault.machine_name}
                                        onChange={(e) => handleChange(index, e)}
                                        required
                                    />
                                </label>
                                <label>
                                    <span>Type of Fault <span id='all-mandetorymark'>*</span></span>
                                    <input
                                        name="type_of_fault"
                                        type="text"
                                        value={fault.type_of_fault}
                                        onChange={(e) => handleChange(index, e)}
                                        required
                                    />
                                </label>
                                {index !== 0 && (
                                    <button id='internalfault-cancelbtn' type="button" onClick={() => handleCancel(index)}><IoIosRemoveCircle id='reg-btn' /></button>
                                )}
                                {index === faults.length - 1 && (
                                    <button id='internalfault-cancelbtn' type="button" onClick={handleAdd}><IoIosAddCircle id='reg-btn' /></button>
                                )}
                            </div>
                        ))}
                        <label>
                            <button id='internalfault-submit' type="submit">Submit</button>
                        </label>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Internal_Fault;
