import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './DashPendingreviews.css'
import { useNavigate } from 'react-router-dom'; 
const DashPendingreviews = () => {
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/assignedtasks/reassigned-todo`);
                setTasks(response.data);
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };

        fetchTasks();
    }, []);

    const handleDiagnoseClick = (token_id) => {
        console.log("Navigating to diagnose page for token ID:", token_id);
        navigate(`/Diagnose/${token_id}`);
    };

  return (
    <div id='pendingreviewdash-total'>
    {tasks.length > 0 ? (
        tasks.map((task) => (
            <div key={task._id} id='pendingreviewdash-content'>
                <div >
                    <strong  >Token ID:</strong>
                    <span>{task.token_id}</span>
                </div>
                <div >
                    <strong>Alert Type:</strong>
                    <span>{task.alert_heading}</span>
                </div>
                <div>
                    <strong>Created By:</strong>
                    <span>{task.createdby}</span>
                </div>
                <div >
                    <strong>Created At:</strong>
                    <span>{task.createdat}</span>
                </div>
                <div>
                    <button id='pendingreviewdash-top-action' onClick={() => handleDiagnoseClick(alert.token_id)}>view more</button>
                    </div>
            </div>
        ))
    ) : (
        <div>No Pending Reviews available</div>
    )}
</div>
  )
}

export default DashPendingreviews