import React, { useState, useEffect } from 'react'
import './DashPendingActions.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const DashPendingActions = () => {
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);
    useEffect(() => {
        const fetchTODoTasks = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/assignedtasks/todo`);
                setTasks(response.data);
            } catch (error) {
                console.error('Error fetching To-do tasks', error);
            }
        };

        fetchTODoTasks();
    }, []);
    const handleDiagnoseClick = (token_id) => {
        console.log("Navigating to diagnose page for token ID:", token_id);
        navigate(`/Diagnose/${token_id}`);
    };

    return (
        <div id='pendingactionsdash-total'>
            {tasks.length > 0 ? (
                tasks.map((task) => (
                    <div key={task._id} id='pendingactionsdash-content'>
                        <div >
                            <strong  >Token ID:</strong>
                            <span>{task.token_id}</span>
                        </div>
                        <div >
                            <strong>Pending Task:</strong>
                            <span>{task.alert_heading}</span>
                        </div>
                        <div >
                            <strong>Assigned To:</strong>
                            <span>{task.assignedto}</span>
                        </div>
                        <div>
                            <strong>Created By:</strong>
                            <span>{task.createdby}</span>
                        </div>
                        <div >
                            <strong>Created At:</strong>
                            <span>{task.createdat}</span>
                        </div>
                        <div>
                            <button id='pendingreviewdash-top-action' onClick={() => handleDiagnoseClick(alert.token_id)}>view more</button>
                        </div>
                    </div>
                ))
            ) : (
                <div className='no-tasks'>No Pending Actions available</div>
            )}
        </div>
    )
}

export default DashPendingActions