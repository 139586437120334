import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_EMS_URL,
  timeout: 10000, 
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  config => {
   
    const token = localStorage.getItem('token');

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
   
    return response;
  },
  error => {
    
    return Promise.reject(error);
  }
);

export default axiosInstance;
