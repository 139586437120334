// // import React, { useEffect, useState } from 'react';

// // const MonthlyCalendertab = () => {
// //     const [monthlyEvents, setMonthlyEvents] = useState([]);

// //     const fetchMonthlyEvents = async () => {
// //         try {
// //             const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/monthlycalenderevents`);
// //             const data = await response.json();
// //             setMonthlyEvents(data);
// //         } catch (error) {
// //             console.error('Error fetching monthly calendar events:', error);
// //         }
// //     };

// //     useEffect(() => {
// //         fetchMonthlyEvents();
// //     }, []);

// //     return (
// //         <div>
// //             <h2>Monthly Calendar Events</h2>
// //             {monthlyEvents.map((event, index) => (
// //                 <div key={index}>
// //                     <h3>Month: {event.month}</h3>
// //                     <table>
// //                         <thead>
// //                             <tr>
// //                                 <th>Station Point</th>
// //                                 <th>KWH Consumption</th>
// //                                 <th>KVAH Consumption</th>
// //                                 <th>PF</th>
// //                                 <th>Utilization %</th>
// //                                 <th>Runtime</th>
// //                                 <th>Energy Intensity</th>
// //                                 <th>Production</th>
// //                                 <th>Maximum Demand (kVA)</th>
// //                             </tr>
// //                         </thead>
// //                         <tbody>
// //                             {event.data.map((item, idx) => (
// //                                 <tr key={idx}>
// //                                     <td>{item.stationPoint}</td>
// //                                     <td>{item.KWH_consumption}</td>
// //                                     <td>{item.KVAH_consumption}</td>
// //                                     <td>{item.PF}</td>
// //                                     <td>{item["Utilization_%"]}</td>
// //                                     <td>{item.Runtime}</td>
// //                                     <td>{item.Energy_Intensity}</td>
// //                                     <td>{item.Production}</td>
// //                                     <td>{item.Maximum_Demand_kVA}</td>
// //                                 </tr>
// //                             ))}
// //                         </tbody>
// //                     </table>
// //                 </div>
// //             ))}
// //         </div>
// //     );
// // };

// // export default MonthlyCalendertab;



// import React, { useEffect, useState } from 'react';

// const MonthlyCalendertab = ({ selectedMonth, selectedStationPoint }) => {
//     const [monthlyEvents, setMonthlyEvents] = useState([]);

//     const fetchMonthlyEvents = async () => {
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/monthlycalenderevents`);
//             const data = await response.json();
//             setMonthlyEvents(data);
//         } catch (error) {
//             console.error('Error fetching monthly calendar events:', error);
//         }
//     };

//     useEffect(() => {
//         fetchMonthlyEvents();
//     }, []);

//     const filteredEvents = monthlyEvents
//         .filter(event => event.month === selectedMonth)
//         .map(event => ({
//             ...event,
//             data: event.data.filter(item => item.stationPoint === selectedStationPoint)
//         }));

//     return (
//         <div id='calendercombine-right-3' >
//             <p>
//                 Monthly Calender Events
//             </p>
//             {filteredEvents.map((event, index) => (
//                 <div key={index}>
//                     <div>
//                         {event.data.map((item, idx) => (
//                             <div key={idx} id='calendercombine-right-32'>

//                                 <div id='calendercombine-right-31'>
//                                     <label>
//                                         <span>Section Point:</span>
//                                         <input value={item.stationPoint}></input>
//                                     </label>
//                                 </div>

//                                 <div id='calendercombine-right-31'>
//                                     <label>
//                                         <span>KWH Consumption:</span>
//                                         <input value={item.KWH_consumption}></input>
//                                     </label>
//                                     <label>
//                                         <span>KVAH Consumption:</span>
//                                         <input value={item.KVAH_consumption}></input>
//                                     </label>
//                                 </div>

//                                 <div id='calendercombine-right-31'>
//                                     <label>
//                                         <span>PF:</span>
//                                         <input value={item.PF}></input>
//                                     </label>
//                                     <label>
//                                         <span>Utilization %:</span>
//                                         <input value={item["Utilization_%"]}></input>
//                                     </label>
//                                 </div>

//                                 <div id='calendercombine-right-31'>
//                                     <label>
//                                         <span>Runtime::</span>
//                                         <input value={item.Runtime}></input>
//                                     </label>
//                                     <label>
//                                         <span>Energy Intensity:</span>
//                                         <input value={item.Energy_Intensity}></input>
//                                     </label>
//                                 </div>

//                                 <div id='calendercombine-right-31'>
//                                     <label>
//                                         <span>Production:</span>
//                                         <input value={item.Production}></input>
//                                     </label>
//                                     <label>
//                                         <span>Maximum Demand (kVA):</span>
//                                         <input value={item.Maximum_Demand_kVA}></input>
//                                     </label>
//                                 </div>

//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             ))}
//         </div>

//     );
// };

// export default MonthlyCalendertab;


import React, { useEffect, useState } from 'react';

const MonthlyCalendertab = ({ selectedMonth, selectedStationPoint }) => {
    const [monthlyEvents, setMonthlyEvents] = useState([]);

    const fetchMonthlyEvents = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/monthlycalenderevents`);
            const data = await response.json();
            setMonthlyEvents(data);
        } catch (error) {
            console.error('Error fetching monthly calendar events:', error);
        }
    };

    useEffect(() => {
        fetchMonthlyEvents();
    }, []);

    const filteredEvents = monthlyEvents
        .filter(event => event.month === selectedMonth)
        .map(event => ({
            ...event,
            data: event.data.filter(item => item.stationPoint === selectedStationPoint)
        }))
        .filter(event => event.data.length > 0);
    const renderValue = (value) => value === null ? 'N/A' : value;

    return (
        <div id='calendercombine-right-3'>
            <p>Monthly Calendar</p>
            {filteredEvents.length > 0 ? (
                filteredEvents.map((event, index) => (
                    <div key={index}>
                        <div>
                            {event.data.map((item, idx) => (
                                <div key={idx} id='calendercombine-right-32'>
                                    <div id='calendercombine-right-31'>
                                        <label>
                                            <span>Section:</span>
                                            <input value={renderValue(item.stationPoint)} readOnly></input>
                                        </label>
                                    </div>
                                    <div id='calendercombine-right-31'>
                                        <label>
                                            <span>KWH Consumption:</span>
                                            <input value={renderValue(item.KWH_consumption)} readOnly></input>
                                        </label>
                                        <label>
                                            <span>KVAH Consumption:</span>
                                            <input value={renderValue(item.KVAH_consumption)} readOnly></input>
                                        </label>
                                    </div>
                                    <div id='calendercombine-right-31'>
                                        <label>
                                            <span>PF:</span>
                                            <input value={renderValue(item.PF)} readOnly></input>
                                        </label>
                                        <label>
                                            <span>Maximum Demand (kVA):</span>
                                            <input value={renderValue(item.Maximum_Demand_kVA)} readOnly></input>
                                        </label>

                                    </div>
                                    <div id='calendercombine-right-31'>
                                        <label>
                                            <span>Runtime:</span>
                                            <input value={renderValue(item.Runtime)} readOnly></input>
                                        </label>
                                        <label>
                                            <span>Energy Intensity:</span>
                                            <input value={renderValue(item.Energy_Intensity)} readOnly></input>
                                        </label>
                                    </div>
                                    <div id='calendercombine-right-31'>
                                        <label>
                                            <span>Production:</span>
                                            <input value={renderValue(item.Production)} readOnly></input>
                                        </label>
                                        <label>
                                            <span>Utilization %:</span>
                                            <input value={renderValue(item["Utilization_%"])} readOnly></input>
                                        </label>

                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))
            ) : (
                <div id='calendercombine-right-32'>
                    <p>No data available for the selected month and station point.</p>
                </div>
            )}
        </div>
    );
};

export default MonthlyCalendertab;
