import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Pending_Results.css';
import { useNavigate } from 'react-router-dom';
const Pending_Results = () => {
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/assignedtasks/reassigned-todo`);
                setTasks(response.data);
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };

        fetchTasks();
    }, []);

    const handleDiagnoseClick = (token_id) => {
        console.log("Navigating to diagnose page for token ID:", token_id);
        navigate(`/Diagnose/${token_id}`);
    };

    return (
        <div id='pending_results-total'>
                <table>
                    <thead>
                        <tr>
                            <th>S. No.</th>
                            <th>Token ID</th>
                            <th>Alert Heading</th>
                            <th>Assigned To</th>
                            <th>Created By</th>
                            <th>Created At</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody>
                    {tasks.length > 0 ? (
                        tasks.map((task, index) => (
                            <tr key={task._id}>
                                <td>{index + 1}</td>
                                <td>{task.token_id}</td>
                                <td>{task.alert_heading}</td>
                                <td>{task.assignedto}</td>
                                <td>{task.createdby}</td>
                                <td>{task.createdat}</td>
                                <td><button onClick={() => handleDiagnoseClick(task.token_id)} >Open Form</button></td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7" style={{ textAlign: 'center' }}>No Pending Reviews available</td>
                        </tr>
                    )}
                </tbody>
                </table>
        </div>
    );
};

export default Pending_Results;
