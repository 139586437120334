

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './TargetEfficiencyfetch.css'; // Import your CSS file for styling
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { convertMMYYYYtoYYYYMM, convertYYYYMMtoMMYYYY, filterDataByMonthRange } from '../../OperatorForms/Dateformat';
import { FaFileCsv } from "react-icons/fa6";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const TargetEfficiencyfetch = ({ onFormButtonClick }) => {

    const convertTMonthInputFormat = (dateString) => {
        const [yyyy, mm] = dateString.split('-');
        return `${mm}-${yyyy}`;
    };
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedLog, setSelectedLog] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [errors, setErrors] = useState({ target_efficiency: '' });
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [userDesignation, setUserDesignation] = useState('');

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
        }
        setUserDesignation(user.level);
    }, []);

    useEffect(() => {
        const fetchTarget_efficiencyData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/target-efficiency`);
                const result = response.data;
                if (Array.isArray(result)) {
                    setData(result);
                    setFilteredData(result);
                } else {
                    console.error('Failed to fetch data:', result.error);
                }
            } catch (error) {
                console.error('Error fetching Target Efficiency data:', error);
            }
        };

        fetchTarget_efficiencyData();
    }, []);

    useEffect(() => {
        filterDataByDate();
    }, [fromDate, toDate, data]);

    const filterDataByDate = () => {
        let filtered = data;
    
        if (fromDate) {
            const [fromYear, fromMonth] = fromDate.split('-');
            const fromDateFormatted = `${fromYear}-${fromMonth}`;
    
            filtered = filtered.filter(log => {
                const [logMonth, logYear] = log.month.split('-');
                const logDateFormatted = `${logYear}-${logMonth}`;
                return logDateFormatted >= fromDateFormatted;
            });
        }
    
        if (toDate) {
            const [toYear, toMonth] = toDate.split('-');
            const toDateFormatted = `${toYear}-${toMonth}`;
    
            filtered = filtered.filter(log => {
                const [logMonth, logYear] = log.month.split('-');
                const logDateFormatted = `${logYear}-${logMonth}`;
                return logDateFormatted <= toDateFormatted;
            });
        }
    
        setFilteredData(filtered);
    };
    
    const handleEditClick = (item) => {
        setSelectedLog({
            ...item,
            month: convertTMonthInputFormat(item.month), // Convert MM-YYYY to YYYY-MM for the input field
        });
        setIsPopupOpen(true);
    };



    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'target_efficiency') {
            // Validate the target_efficiency field
            let formattedValue = value;
            
            if (formattedValue > 100) {
                formattedValue = 100;
            }

            // Only allow numbers up to two decimal places
            if (/^\d+(\.\d{0,2})?$/.test(formattedValue) || formattedValue === '') {
                setSelectedLog((prevData) => ({
                    ...prevData,
                    target_efficiency: formattedValue
                }));
            }
        } else {
            setSelectedLog((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }

         // Reset errors for the field on input
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: ''
        }));
    };


    const handleUpdate = async () => {

        const newErrors = {};

        // Check if target_efficiency is filled
        if (!selectedLog.target_efficiency) {
            newErrors.target_efficiency = 'Target Efficiency is required.';
        }
    
        setErrors(newErrors);
    
        // Show toast error and prevent update if there are validation errors
        if (Object.keys(newErrors).length > 0) {
            toast.error('Please fill all required fields before updating.');
            return;
        }

        try {
            const updatedLog = {
                ...selectedLog,
                month: convertYYYYMMtoMMYYYY(selectedLog.month), // Convert back to MM-YYYY when saving
                modifiedby: loggedInUser.username,
                modifiedat: new Date().toISOString(),
            };

            const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/target-efficiency/${selectedLog._id}`, updatedLog);

            if (response.data.success) {
                setData(data.map(item => (item._id === selectedLog._id ? { ...selectedLog, month: convertTMonthInputFormat(selectedLog.month) } : item)));
                setIsPopupOpen(false);
                setSelectedLog(null);
                toast.success('Target Efficiency updated successfully');
            } else {
                console.error('Failed to update data:', response.data.error);
            }
        } catch (error) {
            console.error('Error Adding Target Efficiency', error);
            if (error.response && error.response.data.message === 'A log already exists for the month') {
                toast.error('Data already exists for the Selected Month, Please select any other Month');
            } else {
                toast.error('Failed to add Target Efficiency. Please try again later.');
            }
        }
    };



    const downloadCSV = () => {
        const headers = [
            'Month',
            'Target Efficiency',
        ];

        const rows = filteredData.map(log => [
            log.month, 
            log.target_efficiency,
           
        ]);

        // Create a CSV string
        const csvContent = [
            headers.join(','),
            ...rows.map(row => row.join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'Target_Efficiency.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleFromDateChange = (e) => {
        const selectedFromDate = e.target.value;
        setFromDate(selectedFromDate);

        if (toDate && toDate < selectedFromDate) {
            setToDate('');
        }
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const getCurrentMonth = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}`;
    };


  return (
    <div id='targetefficiencyfetch-total'>

    <div id='targetefficiencyfetch-right'>

        <div id='targetefficiencyfetch-right-2'>
            <div id='targetefficiencyfetch-right-21'>
                <label>
                    <span>From Month:</span>
                    <input
                        type="month"
                        value={fromDate}
                        onChange={handleFromDateChange}
                        max={getCurrentMonth()}
                    />
                </label>
                <label>
                    <span>To Month:</span>
                    <input
                        type="month"
                        value={toDate}
                        onChange={handleToDateChange}
                        max={getCurrentMonth()}
                        min={fromDate || ''}
                        disabled={!fromDate}
                    />
                </label>
                <label id='formbutton-left'>
                    <button id='formbutton-from' onClick={onFormButtonClick}>Form</button>
                </label>
                <label>
                    <button id='formbutton-csv' onClick={downloadCSV}><FaFileCsv id='formbutton-csvicon' /></button>
                </label>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>Month</th>
                        <th>Target Efficiency (%)</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.length > 0 ? (
                        filteredData.map((item) => (
                            <tr key={item._id}>
                                <td>{item.month}</td>
                                <td>{item.target_efficiency}</td>
                                <td><button onClick={() => handleEditClick(item)}>Edit</button></td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="15">No data available for selected filters.</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <Popup open={isPopupOpen} onClose={() => setIsPopupOpen(false)} modal>
                <div id='targetefficiencyfetch-popup'>
                    <h3>Edit Target Efficiency Form</h3>
                    {selectedLog && (
                        <form>
                            <div id='targetefficiencyfetch-popup1'>
                                <label>
                                    <span>Month<span id='all-mandetorymark'>*</span></span>
                                    <input type='month' name='month' value={selectedLog.month} onChange={handleChange} max={getCurrentMonth()}  />
                                </label>
                                <label>
                                    <span>Target Efficiency (%)<span id='all-mandetorymark'>*</span></span>
                                    <input type='number' name='target_efficiency' value={selectedLog.target_efficiency} onChange={handleChange} step="0.01"
                                    min="0"
                                    max="100" />
                                    {errors.target_efficiency && <span className="targetefficiencyfetch-errormessage">{errors.target_efficiency}</span>}
                                </label>
                            </div>
                           
                            <button id='targetefficiencyfetch-popup-update' type="button" onClick={handleUpdate}>Update</button>
                        </form>
                    )}
                </div>
            </Popup>
        </div>
    </div>
</div >
  )
}

export default TargetEfficiencyfetch