

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Productionentry.css';
import 'reactjs-popup/dist/index.css';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const convertToDateInputFormat = (dateString) => {
  const [dd, mm, yyyy] = dateString.split('-');
  return `${yyyy}-${mm}-${dd}`;
};

const formatDateToDDMMYYYY = (date) => {
  const d = new Date(date);
  const dd = String(d.getDate()).padStart(2, '0');
  const mm = String(d.getMonth() + 1).padStart(2, '0');
  const yyyy = d.getFullYear();
  return `${dd}-${mm}-${yyyy}`;
};

const Productionentry = ({ onViewData }) => {
  const initialFormData = {
    date: '',
    section: '',
    sku: '',
    productionCount: ''
  };

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const unfilledDate = queryParams.get('date') || '';

  const [formData, setFormData] = useState({
    ...initialFormData,
    date: unfilledDate ? convertToDateInputFormat(unfilledDate) : getCurrentDate()
  });

  const [productionEntries, setProductionEntries] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [userDesignation, setUserDesignation] = useState('');

  const fetchProductionEntries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/production-entry`);
      setProductionEntries(response.data);
    } catch (error) {
      console.error('Error fetching production entries', error);
    }
  };

  useEffect(() => {
    fetchProductionEntries();
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.username) {
      setLoggedInUser(user);
      setFormData((prevData) => ({
        ...prevData,
        createdby: user.username
      }));
      setUserDesignation(user.level);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'productionCount') {
      // Regex to allow up to 10 digits before the decimal and exactly 2 digits after
      const regex = /^\d{0,10}(\.\d{0,2})?$/;
  
      if (!regex.test(value)) {
        // toast.error('Please enter a valid number with up to 12 digits including 2 decimal places.');
        return;
      }
    }

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedDate = formatDateToDDMMYYYY(formData.date);

    const parsedFormData = {
      ...formData,
      date: formattedDate,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/production-entry`, parsedFormData);
      if (response.data.success) {
        setFormData(initialFormData);
        fetchProductionEntries();
        toast.success('Production entry saved successfully!');
      } else {
        toast.error('Failed to save production entry');
      }
    } catch (error) {
      console.error('Error Adding production entry', error);
      if (error.response && error.response.data.message === 'A log already exists for the date') {
        toast.error('Data already exists for the selected date, Please select another date.');
      } else {
        toast.error('Failed to add Production entry. Please try again later.');
      }
    }
  };

  const handleKeyPress = (e) => {
    const invalidChars = ["e", "E", "+", "-"];
    if (invalidChars.includes(e.key)) {
        e.preventDefault();
    }
};

  return (
    <div id='productionentry-total'>
      <div id='productionentry-right'>
        <div id='productionentry-right-2'>
          <form onSubmit={handleSubmit}>
            <div id='productionentry-divtop'>
              <label>
                <button type="button" onClick={onViewData}>View Data</button>
              </label>
            </div>
            <div id='productionentry-div1'>
              <label>
                <span>Date <span id='all-mandetorymark'>*</span></span>
                <input type='date' name='date' value={formData.date} onChange={handleChange}  max={getCurrentDate()} required />
              </label>

              <label>
                <span>Production Count <span id='all-mandetorymark'>*</span></span>
                <input type='number' name='productionCount' value={formData.productionCount} onChange={handleChange} required  step="0.01"    onKeyPress={handleKeyPress}  />
              </label>
            </div>
            <button id='productionentry-save' type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Productionentry;



