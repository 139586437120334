// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const Transformerhealth = () => {
//     const [lossdata, setLossData] = useState([]);

//     useEffect(() => {
//         const fetchLossData = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/transformerloss_percentage`);
//                 setLossData(response.data);
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         fetchLossData();
//     }, []);

//     return (
//         <div>
//             <h1>Transformer Health Details</h1>
//             {lossdata.map((item) => (
//                 <div key={item._id} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
//                     <h3>Date: {new Date(item.date).toLocaleDateString()}</h3>
//                     <p>Transformer Loss %: {item["Transformer_Loss_%"] !== null ? item["Transformer_Loss_%"] : 'N/A'}</p>
//                     <p>Transformer Efficiency %: {item["Transformer_Efficiency_%"] !== null ? item["Transformer_Efficiency_%"] : 'N/A'}</p>
//                     <p>Oil Temp: {item.oilTemp !== null ? item.oilTemp : 'N/A'}</p>
//                     <p>Winding Temp: {item.windingTemp !== null ? item.windingTemp : 'N/A'}</p>
//                     <p>Tap Position: {item.Tapposition !== null ? item.Tapposition : 'N/A'}</p>
//                 </div>
//             ))}
//         </div>
//     );
// };

// export default Transformerhealth;


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import * as echarts from 'echarts';

// const Transformerhealth = () => {
//     const [lossdata, setLossData] = useState([]);

//     useEffect(() => {
//         const fetchLossData = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/transformerloss_percentage`);
//                 setLossData(response.data);
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         fetchLossData();
//     }, []);

//     useEffect(() => {
//         // Initialize echarts instance
//         const myChart = echarts.init(document.getElementById('chart-container'));

//         // Prepare data for the chart
//         const dates = lossdata.map(item => new Date(item.date).toLocaleDateString());
//         const transformerLoss = lossdata.map(item => item["Transformer_Loss_%"] !== null ? item["Transformer_Loss_%"] : '-');
//         const transformerEfficiency = lossdata.map(item => item["Transformer_Efficiency_%"] !== null ? item["Transformer_Efficiency_%"] : '-');
//         const oilTemp = lossdata.map(item => item.oilTemp !== null ? item.oilTemp : '-');
//         const windingTemp = lossdata.map(item => item.windingTemp !== null ? item.windingTemp : '-');

//         // Set up echarts options
//         const option = {
//             tooltip: {
//                 trigger: 'axis',
//                 axisPointer: {
//                     type: 'cross',
//                     crossStyle: {
//                         color: '#999'
//                     }
//                 }
//             },
//             legend: {
//                 data: ['Transformer Loss %', 'Transformer Efficiency %', 'Oil Temp', 'Winding Temp']
//             },
//             xAxis: {
//                 type: 'category',
//                 data: dates,
//                 axisPointer: {
//                     type: 'shadow'
//                 }
//             },
//             yAxis: [
//                 {
//                     type: 'value',
//                     name: 'Percentage',
//                     axisLabel: {
//                         formatter: '{value} %'
//                     }
//                 },
//                 {
//                     type: 'value',
//                     name: 'Temperature',
//                     axisLabel: {
//                         formatter: '{value} °C'
//                     }
//                 }
//             ],
//             series: [
//                 {
//                     name: 'Transformer Loss %',
//                     type: 'bar',
//                     data: transformerLoss
//                 },
//                 {
//                     name: 'Transformer Efficiency %',
//                     type: 'bar',
//                     data: transformerEfficiency
//                 },
//                 {
//                     name: 'Oil Temp',
//                     type: 'line',
//                     yAxisIndex: 1,
//                     data: oilTemp
//                 },
//                 {
//                     name: 'Winding Temp',
//                     type: 'line',
//                     yAxisIndex: 1,
//                     data: windingTemp
//                 }
//             ]
//         };

//         // Set options and render chart
//         option && myChart.setOption(option);

//         // Clean up when component unmounts
//         return () => {
//             myChart.dispose();
//         };
//     }, [lossdata]);

//     // Get yesterday's date
//     const yesterday = new Date();
//     yesterday.setDate(yesterday.getDate() - 1);
//     const yesterdayString = yesterday.toLocaleDateString();

//     // Filter data for yesterday's date
//     const yesterdayData = lossdata.filter(item => new Date(item.date).toLocaleDateString() === yesterdayString);

//     return (
//         <div>
//             <h1>Transformer Health Details</h1>
//             <div id="chart-container" style={{ width: '100%', height: '400px' }}></div>
//             {yesterdayData.map((item) => (
//                 <div key={item._id} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
//                     <p>Tap Position: {item.Tapposition !== null ? item.Tapposition : 'N/A'}</p>
//                 </div>
//             ))}
//         </div>
//     );
// };

// export default Transformerhealth;


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';

const Transformerhealth = () => {
    const [lossdata, setLossData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filter, setFilter] = useState('thisWeek');

    useEffect(() => {
        const fetchLossData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/transformerloss_percentage`);
                setLossData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchLossData();
    }, []);

    useEffect(() => {
        // Filter data based on the selected filter
        let filtered = [];
        const today = new Date();

        if (filter === 'thisWeek') {
            const startOfWeek = new Date(today);
            startOfWeek.setDate(today.getDate() - today.getDay());
            filtered = lossdata.filter(item => new Date(item.date) >= startOfWeek);
        } else if (filter === 'lastWeek') {
            const endOfLastWeek = new Date(today);
            endOfLastWeek.setDate(today.getDate() - today.getDay() - 1);
            const startOfLastWeek = new Date(endOfLastWeek);
            startOfLastWeek.setDate(endOfLastWeek.getDate() - 6);
            filtered = lossdata.filter(item => new Date(item.date) >= startOfLastWeek && new Date(item.date) <= endOfLastWeek);
        } else if (filter === 'thisMonth') {
            const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            filtered = lossdata.filter(item => new Date(item.date) >= startOfMonth);
        }

        setFilteredData(filtered);
    }, [filter, lossdata]);

    useEffect(() => {
        // Initialize echarts instance
        const myChart = echarts.init(document.getElementById('chart-container'));

        // Prepare data for the chart
        const dates = filteredData.map(item => new Date(item.date).toLocaleDateString());
        const transformerLoss = filteredData.map(item => item["Transformer_Loss_%"] !== null ? item["Transformer_Loss_%"] : '-');
        const transformerEfficiency = filteredData.map(item => item["Transformer_Efficiency_%"] !== null ? item["Transformer_Efficiency_%"] : '-');
        const oilTemp = filteredData.map(item => item.oilTemp !== null ? item.oilTemp : '-');
        const windingTemp = filteredData.map(item => item.windingTemp !== null ? item.windingTemp : '-');

        // Set up echarts options
        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            legend: {
                data: ['Transformer Loss %', 'Transformer Efficiency %', 'Oil Temp', 'Winding Temp']
            },
            xAxis: {
                type: 'category',
                data: dates,
                axisPointer: {
                    type: 'shadow'
                }
            },
            yAxis: [
                {
                    type: 'value',
                    name: 'Percentage',
                    axisLabel: {
                        formatter: '{value} %'
                    }
                },
                {
                    type: 'value',
                    name: 'Temperature',
                    axisLabel: {
                        formatter: '{value} °C'
                    }
                }
            ],
            series: [
                {
                    name: 'Transformer Loss %',
                    type: 'bar',
                    data: transformerLoss
                },
                {
                    name: 'Transformer Efficiency %',
                    type: 'bar',
                    data: transformerEfficiency
                },
                {
                    name: 'Oil Temp',
                    type: 'line',
                    yAxisIndex: 1,
                    data: oilTemp
                },
                {
                    name: 'Winding Temp',
                    type: 'line',
                    yAxisIndex: 1,
                    data: windingTemp
                }
            ]
        };

        // Set options and render chart
        option && myChart.setOption(option);

        // Clean up when component unmounts
        return () => {
            myChart.dispose();
        };
    }, [filteredData]);

    // Get yesterday's date
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayString = yesterday.toLocaleDateString();

    // Filter data for yesterday's date
    const yesterdayData = lossdata.filter(item => new Date(item.date).toLocaleDateString() === yesterdayString);

    return (
        <div>
            <h1>Transformer Health</h1>
            <div>
                {yesterdayData.map((item) => (
                    <div key={item._id}>
                        <p>Tap Position: {item.Tapposition !== null ? item.Tapposition : 'N/A'}</p>
                    </div>
                ))}
            </div>
            
            <div>
                <span>Time Range: </span>
                <select value={filter} onChange={(e) => setFilter(e.target.value)}>
                    <option value="thisWeek">This Week</option>
                    <option value="lastWeek">Last Week</option>
                    <option value="thisMonth">This Month</option>
                </select>
            </div>
            <div id="chart-container" style={{ width: '100%', height: '400px' }}></div>
            
        </div>
    );
};

export default Transformerhealth;



