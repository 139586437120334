import React, { useEffect, useState } from 'react';

const Areaformfetch = () => {
  const [logs, setLogs] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/areafrom`);
        const data = await response.json();
        
        if (response.ok) {
          setLogs(data.data);
        } else {
          setError(data.error);
        }
      } catch (error) {
        setError('Error fetching logs');
      }
    };

    fetchLogs();
  }, []);

  const toggleStatus = async (areanameid, currentStatus) => {
    try {
      const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
      const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/areaform/${areanameid}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus }),
      });

      if (response.ok) {
        setLogs(logs.map(log => log.areanameid === areanameid ? { ...log, status: newStatus } : log));
      } else {
        const data = await response.json();
        setError(data.error);
      }
    } catch (error) {
      setError('Error updating status');
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h2>Area Logs</h2>
      <ul>
        {logs.map(log => (
          <li key={log.areanameid}>
            <div>Area Name: {log.areaname}</div>
            <div>Modified By: {log.modifiedby}</div>
            <div>Modified At: {log.modifiedat}</div>
            <div>Created By: {log.createdby}</div>
            <div>Created At: {log.createdat}</div>
            <div>Status: {log.status}</div>
            <button onClick={() => toggleStatus(log.areanameid, log.status)}>
              Toggle Status
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Areaformfetch;
