import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LVform.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const LVform = ({ onViewData }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    const initialFormData = {
        date: getCurrentDate(),
        lvId: '',
        name: '',
        parentname: '',
        metermake: '',
        meterslno: '',
        phase: '',
        connectionloadinva: '',
        thresholdload: '',
        spare1: '',
        spare2: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-transformerNames`);
            const data = await response.json();
            setParentNames(data);

            // const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/names`);
            // const fetchedParentNames = response.data.map(item => item.name);
            // setParentNames(fetchedParentNames);
        } catch (error) {
            console.error('Error fetching incomer names', error);
        }
    };

    // Fetch incomer names on component mount
    useEffect(() => {
        fetchParentNames();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = {
                ...formData,
                connectionloadinva: Number(formData.connectionloadinva),
                thresholdload: Number(formData.thresholdload)
            };

            await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/LVforms`, dataToSend);


            // console.log('Response from names API:', response.data);

            toast.success('LV form added successfully');
            setFormData(initialFormData);
            fetchParentNames();
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    return (
        <div id='lvforms-total'>
            <div id='lvforms-right'>
                <div id='lvforms-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='lvforms-divtop'>
                            <label>
                                {Masterformsheadingsss.LVHeading}
                            </label>
                            <label>
                                <button type="button" onClick={onViewData}>View Data</button>
                            </label>
                        </div>
                        <div id='lvforms-div1'>
                            <label>
                                <span>Date</span>
                                <input type='date' name="date" value={formData.date} disabled />
                            </label>
                            <label>
                                <span>Parent Name</span>
                                <select name="parentname" value={formData.parentname} onChange={handleChange} >
                                    <option value="">Select Parent Name</option>
                                    {parentNames.map((name, index) => (
                                        <option key={index} value={name}> {name}</option>
                                    ))}
                                </select>
                                {/* <select name="parentname" value={formData.parentname} onChange={handleChange} >
                                    <option value="">Select Parent Name</option>
                                    {parentNames.map((name, index) => (
                                        <option key={index} value={name}>{name}</option>
                                    ))}
                                </select> */}
                            </label>
                        </div>
                        <div id='lvforms-div1'>
                            <label>
                                <span>Name</span>
                                <input name="name" value={formData.name} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Meter Make</span>
                                <input name="metermake" value={formData.metermake} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='lvforms-div1'>
                            <label>
                                <span>Meter Serial No.</span>
                                <input name="meterslno" value={formData.meterslno} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Phase</span>
                                <select name="phase" value={formData.phase} onChange={handleChange} required>
                                    <option value=''>Select Phase</option>
                                    <option value='3-Phase'>3-Phase</option>
                                    <option value='1-Phase-Vr'>1-Phase-Vr</option>
                                    <option value='1-Phase-Vy'>1-Phase-Vy</option>
                                    <option value='1-Phase-Vb'>1-Phase-Vb</option>
                                </select>

                                {/* <input name="phase" value={formData.phase} onChange={handleChange} required /> */}
                            </label>
                        </div>
                        <div id='lvforms-div1'>
                            <label>
                                <span>Connection Load (kVA)</span>
                                <input type="number" name="connectionloadinva" value={formData.connectionloadinva} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Threshold Load</span>
                                <input type="number" name="thresholdload" value={formData.thresholdload} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='lvforms-divbottom'>
                            <label>
                                <button id='lvforms-save' type="submit">{Masterformsheadingsss.Submitheading}</button>
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default LVform;
