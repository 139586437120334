
// import React, { useEffect, useState, useRef } from "react";
// import "./CriticalAlerts.css";
// import { isWithinInterval } from "date-fns";
// import { FaFileCsv } from "react-icons/fa6";
// import { useLocation, useNavigate } from "react-router-dom";
// import Supervisorsidebar from "../Supervisor/Supersidebar/Supersidebar";
// import Stationpoints from "../Commonfns/Stationpoints";
// import TopBar from "../Commonfns/Topbar/Topbar";
// const CriticalAlerts = () => {
//   const [alerts, setAlerts] = useState([]);
//   // const [filteredAlerts, setFilteredAlerts] = useState([]);
//   const [section, setSection] = useState("");
//   const [fromDate, setFromDate] = useState("");
//   const [toDate, setToDate] = useState("");
//   const [alertTypes, setAlertTypes] = useState([]);
//   const [selectedAlertType, setSelectedAlertType] = useState("");
//   const [page, setPage] = useState(1);
//   const [isLoading, setIsLoading] = useState(false);
//   const [hasMoreData, setHasMoreData] = useState(true);
//   const itemsPerPage = 20;
//   const navigate = useNavigate();

//   const location = useLocation();
//   const tableRef = useRef(null);

//   // const queryParams = new URLSearchParams(location.search);

//   useEffect(() => {
//     const queryParams = new URLSearchParams(location.search);

//     const sectionParam = queryParams.get("section") || "";
//     const fromParam = queryParams.get("from") || "";
//     const toParam = queryParams.get("to") || "";
//     const selectedTypeParam = queryParams.get("selectedType") || "";

//     setSection(sectionParam);
//     setFromDate(fromParam);
//     setToDate(toParam);
//     setSelectedAlertType(selectedTypeParam);
//   }, []);

//   // Update query parameters when any of the state values change
//   useEffect(() => {
//     const queryParams = new URLSearchParams();

//     if (section) queryParams.set("section", section);
//     if (fromDate) queryParams.set("from", fromDate);
//     if (toDate) queryParams.set("to", toDate);
//     if (selectedAlertType) queryParams.set("selectedType", selectedAlertType);

//     navigate(`?${queryParams.toString()}`);
//   }, [section, fromDate, toDate, selectedAlertType, navigate]);

//   const fetchAlerts = async () => {
//     if (isLoading || !hasMoreData) {
//       // console.log("Already loading or no more data to fetch.");
//       return;
//     }

//     setIsLoading(true);
//     try {
//       let query = `${process.env.REACT_APP_API_EMS_URL}/api/criticalalerts/filter?page=${page}&limit=${itemsPerPage}&`;

//       if (fromDate) {
//         query += `fromDate=${fromDate}&`;
//       }
//       if (toDate) {
//         query += `toDate=${toDate}&`;
//       }
//       if (section) {
//         query += `section_name=${encodeURIComponent(section)}&`;
//       }
//       if (selectedAlertType) {
//         query += `specific_alert_type=${encodeURIComponent(
//           selectedAlertType
//         )}&`;
//       }

//       const response = await fetch(query);
//       const data = await response.json();

//       // console.log("Fetched data length:", data.length);

//       if (data.length === 0) {
//         // console.log("No more data to load.");
//         setHasMoreData(false); // No more data available
//         return;
//       }

//       if (page === 1) {
//         setAlerts(data);
//       } else {
//         setAlerts((prevAlerts) => [...prevAlerts, ...data]); // Append new data to existing data
//       }

//       setHasMoreData(data.length === itemsPerPage);
//     } catch (error) {
//       console.error("Error fetching alerts:", error);
//     }
//     setIsLoading(false);
//   };

//   const fetchAlertTypes = async () => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_EMS_URL}/api/unique-alert-types-criticalalerts`
//       );
//       const types = await response.json();
//       setAlertTypes(types);
//     } catch (error) {
//       console.error("Error fetching alert types:", error);
//     }
//   };

//   // Reset scroll position whenever filters change
//   useEffect(() => {
//     if (tableRef.current) {
//       tableRef.current.scrollTop = 0; // Reset scroll position to top
//     }
//   }, [section, selectedAlertType, fromDate, toDate]);

//   useEffect(() => {
//     setPage(1);
//     fetchAlerts();
//     fetchAlertTypes();
//     setHasMoreData(true);
//   }, [section, selectedAlertType, fromDate, toDate]);

//   // Load more items when the user scrolls to the bottom of the table
//   const loadMoreItems = () => {
//     if (!isLoading && hasMoreData) {
//       // console.log(`Loading more items... Current page: ${page}`);
//       // setPage((prevPage) => prevPage + 1);
//       setPage((prevPage) => {
//         const newPage = prevPage + 1;
//         // console.log(`New page set: ${newPage}`);
//         return newPage;
//       });
//     }
//   };

//   useEffect(() => {
//     if (page > 1) {
//       // console.log(`Fetching data for page: ${page}`);
//       fetchAlerts();
//     }
//   }, [page]);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (
//         tableRef.current &&
//         tableRef.current.scrollTop + tableRef.current.clientHeight >=
//           tableRef.current.scrollHeight &&
//         !isLoading
//       ) {
//         console.log("Reached bottom of the table, triggering loadMoreItems...");
//         loadMoreItems();
//       }
//     };
//     const tableElement = tableRef.current;
//     if (tableElement) {
//       tableElement.addEventListener("scroll", handleScroll);
//     }

//     return () => {
//       if (tableElement) {
//         tableElement.removeEventListener("scroll", handleScroll);
//       }
//     };
//   }, [isLoading, hasMoreData]);

//   const downloadCSV = async () => {
//     try {
//       let query = `${process.env.REACT_APP_API_EMS_URL}/api/criticalalerts/filter/download?`;

//       if (fromDate) {
//         query += `fromDate=${fromDate}&`;
//       }
//       if (toDate) {
//         query += `toDate=${toDate}&`;
//       }
//       if (section) {
//         query += `section_name=${encodeURIComponent(section)}&`;
//       }
//       if (selectedAlertType) {
//         query += `specific_alert_type=${encodeURIComponent(
//           selectedAlertType
//         )}&`;
//       }

//       const response = await fetch(query);
//       const data = await response.json();

//       // Generate CSV content from the fetched data
//       const csvContent = [
//         [
//           "S NO",
//           "Section",
//           "Critical Alert Parameter",
//           "Critical Alert Value",
//           "Threshold",
//           "Start Time",
//           "End Time",
//           "Duration",
//         ],
//         ...data.map((alert, index) => [
//           index + 1,
//           index + 1,
//           alert.section_name,
//           `"${alert.critical_alert_parameter}"`,
//           `"${alert.critical_alert_value}"`,
//           alert.threshold,
//           alert.start_time,
//           alert.end_time,
//           alert.duration,
//         ]),
//       ]
//         .map((row) => row.join(","))
//         .join("\n");

//       // Create a Blob from the CSV content
//       const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
//       const url = URL.createObjectURL(blob);

//       // Open a new window with the same path and trigger the download
//       const newWindow = window.open(window.location.href, "_blank"); // Open a new window with the same URL
//       if (newWindow) {
//         // Create a download link in the new window and trigger the download
//         newWindow.onload = function () {
//           const link = newWindow.document.createElement("a");
//           link.setAttribute("href", url);
//           link.setAttribute("download", "CriticalAlerts.csv");
//           link.style.display = "none";
//           newWindow.document.body.appendChild(link);
//           link.click();
//           newWindow.document.body.removeChild(link); // Remove the link after downloading
//         };
//       }
//     } catch (error) {
//       console.error("Error fetching data for CSV download:", error);
//     }
//   };

//   const handleDiagnoseClick = (token_id) => {
//     console.log("Navigating to diagnose page for token ID:", token_id);
//     navigate(`/Diagnose/${token_id}`);
//   };

//   const handleStationPointChange = (selectedStationPoint) => {
//     setSection(selectedStationPoint);
//   };

//   const handleAlertTypeChange = (e) => {
//     setSelectedAlertType(e.target.value);
//   };

//   const handleFromDateChange = (e) => {
//     const selectedFromDate = e.target.value;
//     setFromDate(selectedFromDate);

//     if (toDate && toDate < selectedFromDate) {
//       setToDate("");
//     }
//   };

//   const handleToDateChange = (e) => {
//     setToDate(e.target.value);
//   };

//   const getTodayDate = () => {
//     const today = new Date();
//     return today.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
//   };
//   return (
//     <div id="criticalalets-total">
//       <div>
//         <Supervisorsidebar />
//       </div>
//       <div id="criticalalets-right">
//         <TopBar section="Criticalalertsheading" />
//         <div id="criticalalets-right-2">
//           <div id="criticalalets-right-21">
//             <label>
//               <span>From Date:</span>
//               <input
//                 type="date"
//                 value={fromDate}
//                 onChange={handleFromDateChange}
//                 max={getTodayDate()} // No future dates allowed
//               />
//             </label>
//             <label>
//               <span>To Date:</span>
//               <input
//                 type="date"
//                 value={toDate}
//                 onChange={handleToDateChange}
//                 max={getTodayDate()} // No future dates allowed
//                 min={fromDate || ""}
//                 disabled={!fromDate}
//               />
//             </label>
//             <label>
//               <span>Alert Type:</span>
//               <select
//                 value={selectedAlertType}
//                 onChange={handleAlertTypeChange}
//               >
//                 <option value="">Please select</option>
//                 {alertTypes.map((type, index) => (
//                   <option key={index} value={type}>
//                     {type}
//                   </option>
//                 ))}
//               </select>
//             </label>
//             <label>
//               <Stationpoints
//                 defaultStationPoint={section}
//                 showAll={true}
//                 onStationPointChange={handleStationPointChange}
//               />
//             </label>

//             <button onClick={downloadCSV}>
//               <FaFileCsv />
//             </button>
//           </div>

//           <div id="criticalalets-right-3">
//             <table ref={tableRef}>
//               <thead>
//                 <tr>
//                   <th>S. No.</th>
//                   <th>Token ID</th>
//                   <th>Alert Type</th>
//                   <th>Section</th>
//                   <th>Critical Alert Parameter</th>
//                   <th>Critical Alert Value</th>
//                   <th>Start Time</th>
//                   <th>End Time</th>
//                   <th>Duration</th>
//                   <th>Action</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {alerts.length === 0 && !isLoading ? (
//                   <tr>
//                     <td colSpan="10">
//                       No data available for selected filters.
//                     </td>
//                   </tr>
//                 ) : (
//                   alerts.map((alert, index) => (
//                     <tr key={index}>
//                       <td>{index + 1}</td>
//                       <td>{alert.token_id}</td>
//                       <td>{alert.specific_alert_type}</td>
//                       <td>{alert.section_name}</td>
//                       <td>{alert.critical_alert_parameter}</td>
//                       <td>{alert.critical_alert_value}</td>
//                       <td>{alert.start_time}</td>
//                       <td>{alert.end_time}</td>
//                       <td>{alert.duration}</td>
//                       <td>
//                         {!alert.token_id.startsWith("Kv") && (
//                           <button
//                             onClick={() => handleDiagnoseClick(alert.token_id)}

//                             disabled={!alert.token_id_exist} // Disable if token_id_exist is false
//                             style={{
//                               backgroundColor: alert.token_id_exist ? "" : "gray",
//                               cursor: alert.token_id_exist ? "pointer" : "not-allowed",
//                             }}
//                           >
//                             Diagnose
//                           </button>

                         
//                         )}
//                       </td>
//                     </tr>
//                   ))
//                 )}
//                 {isLoading && (
//                   <tr>
//                     <td colSpan="10" style={{ textAlign: "center" }}>
//                       Loading...
//                     </td>
//                   </tr>
//                 )}

//                 {!hasMoreData && !isLoading && alerts.length > 0 && (
//                   <tr>
//                     <td colSpan="10" style={{ textAlign: "center" }}>
//                       No more data to show.
//                     </td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CriticalAlerts;




import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import Supervisorsidebar from "../Supervisor/Supersidebar/Supersidebar";
import Stationpoints from "../Commonfns/Stationpoints";
import TopBar from "../Commonfns/Topbar/Topbar";
import { FaFileCsv } from "react-icons/fa6";
import "./CriticalAlerts.css";

const itemsPerPage = 20;

const fetchAlerts = async ({ pageParam = 1, queryKey }) => {
  const [_, section, fromDate, toDate, selectedAlertType] = queryKey;
  let query = `${process.env.REACT_APP_API_EMS_URL}/api/criticalalerts/filter?page=${pageParam}&limit=${itemsPerPage}`;

  if (fromDate) query += `&fromDate=${fromDate}`;
  if (toDate) query += `&toDate=${toDate}`;
  if (section) query += `&section_name=${encodeURIComponent(section)}`;
  if (selectedAlertType) query += `&specific_alert_type=${encodeURIComponent(selectedAlertType)}`;

  const response = await fetch(query);
  if (!response.ok) throw new Error("Failed to fetch alerts");
  return response.json();
};

const fetchAlertTypes = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_EMS_URL}/api/unique-alert-types-criticalalerts`
  );
  if (!response.ok) throw new Error("Failed to fetch alert types");
  return response.json();
};

const CriticalAlerts = () => {
  const [section, setSection] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [alertTypes, setAlertTypes] = useState([]);
  const [selectedAlertType, setSelectedAlertType] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const { ref, inView } = useInView({ threshold: 0.1 });

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } = useInfiniteQuery({
    queryKey: ["critical-alerts", section, fromDate, toDate, selectedAlertType],
    queryFn: fetchAlerts,
    getNextPageParam: (lastPage) =>
      lastPage.hasMore ? lastPage.currentPage + 1 : undefined,
  });

  useEffect(() => {
    // Fetch alert types on mount
    const loadAlertTypes = async () => {
      try {
        const types = await fetchAlertTypes();
        setAlertTypes(types);
      } catch (error) {
        console.error("Error fetching alert types:", error);
      }
    };

    loadAlertTypes();
  }, []);

    const handleDiagnoseClick = (token_id) => {
    // console.log("Navigating to diagnose page for token ID:", token_id);
    navigate(`/Diagnose/${token_id}`);
  };

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(location.search);
  //   setSection(queryParams.get("section") || "");
  //   setFromDate(queryParams.get("from") || "");
  //   setToDate(queryParams.get("to") || "");
  //   setSelectedAlertType(queryParams.get("selectedType") || "");
  // }, [location]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const sectionParam = queryParams.get("section") || "";
    const fromParam = queryParams.get("from") || "";
    const toParam = queryParams.get("to") || "";
    const selectedTypeParam = queryParams.get("selectedType") || "";

    setSection(sectionParam);
    setFromDate(fromParam);
    setToDate(toParam);
    setSelectedAlertType(selectedTypeParam);
  }, []);

  // Update query parameters when any of the state values change
  useEffect(() => {
    const queryParams = new URLSearchParams();

    if (section) queryParams.set("section", section);
    if (fromDate) queryParams.set("from", fromDate);
    if (toDate) queryParams.set("to", toDate);
    if (selectedAlertType) queryParams.set("selectedType", selectedAlertType);

    navigate(`?${queryParams.toString()}`);
  }, [section, fromDate, toDate, selectedAlertType, navigate]);

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, hasNextPage, fetchNextPage]);

  const handleStationPointChange = (selectedStationPoint) => {
    setSection(selectedStationPoint);
  };

  const handleAlertTypeChange = (e) => {
    setSelectedAlertType(e.target.value);
  };

  const handleFromDateChange = (e) => {
    const selectedFromDate = e.target.value;
    setFromDate(selectedFromDate);

    if (toDate && toDate < selectedFromDate) {
      setToDate("");
    }
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
  };

  return (
    <div id="criticalalets-total">
      <Supervisorsidebar />
      <div id="criticalalets-right">
        <TopBar section="Criticalalertsheading" />
        <div id="criticalalets-right-2">
          <div id="criticalalets-right-21">
            <label>
              From Date:
              <input
                type="date"
                value={fromDate}
                onChange={handleFromDateChange}
                max={getTodayDate()}
              />
            </label>
            <label>
              To Date:
              <input
                type="date"
                value={toDate}
                onChange={handleToDateChange}
                max={getTodayDate()} // No future dates allowed
                min={fromDate || ""}
                disabled={!fromDate}
              />
            </label>
            <label>
              Alert Type:
              <select
                value={selectedAlertType}
                onChange={handleAlertTypeChange}
              >
                <option value="">Please select</option>
                {alertTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </label>
            <Stationpoints
              defaultStationPoint={section}
              showAll={true}
              onStationPointChange={handleStationPointChange}
            />
            <button>
              <FaFileCsv />
            </button>
          </div>
          <div id="criticalalets-right-3">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Token ID</th>
                  <th>Alert Type</th>
                  <th>Section</th>
                  <th>Parameter</th>
                  <th>Value</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Duration</th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
  {data?.pages.flatMap((page, pageIndex) =>
    page.data.map((alert, index) => (
      <tr key={`${pageIndex}-${index}`}>
        <td>{pageIndex * itemsPerPage + index + 1}</td>
        <td>{alert.tokenId || "N/A"}</td>
        <td>{alert.specificAlertType || "N/A"}</td>
        <td>{alert.sectionName || "N/A"}</td>
        <td>{alert.criticalAlertParameter || "N/A"}</td>
        <td>{alert.criticalAlertValue || "N/A"}</td>
        <td>{alert.startTime || "N/A"}</td>
        <td>{alert.endTime || "N/A"}</td>
        <td>{alert.duration || "N/A"}</td>
        {/* <td>
          {!alert.tokenId.startsWith("Kv") && (
            <button
              onClick={() => handleDiagnoseClick(alert.tokenId)}
              disabled={!alert.tokenIdExist} 
              style={{
                backgroundColor: alert.tokenIdExist ? "" : "gray",
                cursor: alert.tokenIdExist ? "pointer" : "not-allowed",
              }}
            >
              Diagnose
            </button>
          )}
        </td> */}
      </tr>
    ))
  )}
  <tr ref={ref}>
    <td colSpan="9" style={{ textAlign: "center" }}>
      {isFetchingNextPage ? "Loading more..." : !hasNextPage && "No more data"}
    </td>
  </tr>
</tbody>

            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CriticalAlerts;
