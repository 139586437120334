
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SupervisorDashHeader } from '../Operator/Data';
import { supervisordashbuttons } from '../Operator/Data';
import './Alldash.css';
import Supervisorsidebar from '../Supervisor/Supersidebar/Supersidebar';
import DailyDashboard from './Daily-Dash/DailyDashboard';
import MonthlyDashboardContainer from './Monthly-Dash/MonthlyDashboardContainer';
import Tab3 from './Tab3/Tab3';
import Tab1 from './Tab1/Tab1';
import Operatorsidebar from '../Operator/Sidebar/Sidebar';
import TopBar from '../Commonfns/Topbar/Topbar';
const Alldash = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [activeSection, setActiveSection] = useState('Tab1-1');
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');

   

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
            setUserDesignation(user.level);
        }
        const section = new URLSearchParams(location.search).get('section');
        if (section) {
            setActiveSection(section);
        }
    }, [location.search]);

    // Update the URL whenever the active section changes
    const handleButtonClick = (section) => {
        setActiveSection(section);
        navigate(`?section=${section}`);
    };

    return (
        <div id='super-alldash-total'>
            <div>
                <Supervisorsidebar/>
                {/* {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />} */}
            </div>
            <div id='super-alldash-right'>
                {/* <div id='super-alldash-right-1'>
                    <div id='super-alldash-heading'>
                        {SupervisorDashHeader.alertHeading}
                    </div>
                    <div id='super-alldash-heading1'>
                        {SupervisorDashHeader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={SupervisorDashHeader.logoSrc} alt='logo' />
                    </div>
                </div> */}

<TopBar section="Supervisordashheading" />

                <div id='super-alldash-right-2'>
                    <div id='super-alldash-right-21'>
                        <div id='super-alldash-right-23'>
                            <button
                                className={activeSection === 'Tab1-1' ? 'active' : ''}
                                onClick={() => handleButtonClick('Tab1-1')}
                            >
                                {supervisordashbuttons.button1Heading}
                                {/* Overview */}
                            </button>
                            <button
                                className={activeSection === 'DailyDashboard-1' ? 'active' : ''}
                                onClick={() => handleButtonClick('DailyDashboard-1')}
                            >
                                 {supervisordashbuttons.button2Heading}
                               
                            </button>
                            <button
                                className={activeSection === 'MonthlyDashboard-1' ? 'active' : ''}
                                onClick={() => handleButtonClick('MonthlyDashboard-1')}
                            >
                                 {supervisordashbuttons.button3Heading}
                              
                            </button>
                            <button
                                className={activeSection === 'Tab3-1' ? 'active' : ''}
                                onClick={() => handleButtonClick('Tab3-1')}
                            >
                                 {supervisordashbuttons.button4Heading}
                              
                            </button>


                        </div>
                    </div>

                    <div id='super-alldash-right-3'>
                        <div>
                            <div>
                                {activeSection === 'Tab1-1' && <Tab1 />}
                                {activeSection === 'DailyDashboard-1' && <DailyDashboard />}
                                {activeSection === 'MonthlyDashboard-1' && <MonthlyDashboardContainer />}
                                {activeSection === 'Tab3-1' && <Tab3 />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Alldash;
