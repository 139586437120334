import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { Link } from 'react-router-dom'; // Import Link for routing
import './Dashcriticalalerts.css'
const Dashcriticalalerts = () => {
    const [alerts, setAlerts] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate(); // Initialize useNavigate hook

    useEffect(() => {
        const fetchAlerts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/latest-criticalalerts`);
                setAlerts(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching critical alerts:', error);
                setLoading(false);
            }
        };

        fetchAlerts();
    }, []);

    const handleDiagnoseClick = (token_id) => {
        console.log("Navigating to diagnose page for token ID:", token_id);
        navigate(`/Diagnose/${token_id}`);
    };

    if (loading) {
        return <div>Loading...</div>; // Show a loading message while data is being fetched
    }

    return (
        <div>
            <div id='dashcriticalalerts-right-21'>
                <div id='dashcriticalalerts-topone'>
                    <label>
                        <span id='dashcriticalalerts-alert'>Critical Alerts</span>
                    </label>
                    <label>
                        <Link to='/CriticalAlerts'>
                            <button id='dashcriticalalerts-viewmore'>View More</button>
                        </Link>
                    </label>
                </div>
                <div id='dashcriticalalerts-right-211'>
                    {alerts.map((alert) => (
                        <div key={alert._id} className="dashcriticalalerts-item">
                            <div id="dashcriticalalerts-details">
                                <div id='dashcriticalalerts-div'>
                                    <label>
                                        <strong>Token ID:</strong>
                                        <span>{alert.token_id}</span>
                                    </label>
                                    <label>
                                        <strong>Section:</strong>
                                        <span>{alert.section_name}</span>
                                    </label>
                                </div>
                                <div id='dashcriticalalerts-div'>
                                    <label>
                                        <strong>Parameter Name:</strong>
                                        <span>{alert.critical_alert_parameter}</span>
                                    </label>
                                    <label>
                                        <strong>Event Type:</strong>
                                        <span>{alert.specific_alert_type}</span>
                                    </label>
                                </div>
                                <div id='dashcriticalalerts-div'>
                                    <label>
                                        <strong>Alert Value:</strong>
                                        <span>{alert.critical_alert_value}</span>
                                    </label>
                                    <label>
                                        <strong>Duration:</strong>
                                        <span>{alert.duration}</span>
                                    </label>
                                </div>
                                {/* <div id='dashcriticalalerts-div'>
                                    <label >

                                        {!alert.token_id.startsWith('Kv') && (
                                            <button onClick={() => handleDiagnoseClick(alert.token_id)}
                                            disabled={!alert.token_id_exist} // Disable if token_id_exist is false
                                            style={{
                                              backgroundColor: alert.token_id_exist ? "" : "gray",
                                              cursor: alert.token_id_exist ? "pointer" : "not-allowed",
                                            }}
                                            >Diagnose</button>
                                        )}
                                    </label>
                                </div> */}
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
};

export default Dashcriticalalerts;
