import React, { useState } from 'react';
import axios from 'axios';
import './Groundingpoints.css'
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Groundingpoints = ({ onViewData }) => {

    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    const initialFormData = {
        date: getCurrentDate(),
        groundingPointId: '',
        location: '',
        locationNumber: '',
        resistance: '',
        periodicityOfMaintenance: '',
        previousMaintenanceDate: '',
        spare1: '',
        spare2: ''
    };

    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const dataToSend = {
    //             ...formData,
    //             resistance: Number(formData.resistance)
    //         };

    //         await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/groundingpoints`, dataToSend);
    //          toast.success('Grounding Points added successfully');
    //         setFormData(initialFormData); // Clear form fields
    //     } catch (error) {
    //         console.error('Error submitting data', error);
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = {
                ...formData,
                resistance: Number(formData.resistance)
            };

            console.log('Data being sent:', dataToSend);  // Log the data to the console

            await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/groundingpoints`, dataToSend);
            toast.success('Grounding Points added successfully');
            setFormData(initialFormData); // Clear form fields
        } catch (error) {
            console.error('Error submitting data', error);
        }
    };


    return (

        <div id='groundingpoints-total'>
            <div id='groundingpoints-right'>

                <div id='groundingpoints-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='groundingpoints-divtop'>
                            <label>
                                <span> {Masterformsheadingsss.GroundingpointHeading}</span>
                            </label>
                            <label>
                                <button type="button" onClick={onViewData}>View Data</button>
                            </label>
                        </div>
                        <div id='groundingpoints-div1'>
                            <label>
                                <span>Date</span>
                                <input type='date' name="date" value={formData.date} disabled />
                            </label>
                            <label>
                                <span>Location</span>
                                <input name="location" value={formData.location} onChange={handleChange} required />
                            </label>

                            {/* <label>
                                <span>Grounding points id</span>
                                <input name="groundingPointId" value={formData.groundingPointId} onChange={handleChange} required />
                            </label> */}
                        </div>
                        <div id='groundingpoints-div1'>
                            <label>
                                <span>Location number</span>
                                <input name="locationNumber" value={formData.locationNumber} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Resistance</span>
                                <input type='number' name="resistance" value={formData.resistance} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='groundingpoints-div1'>

                            <label>
                                <span>Proposed periodicity of maintenance</span>
                                <input name="periodicityOfMaintenance" value={formData.periodicityOfMaintenance} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Previous maintenance done date</span>
                                <input type="date" name="previousMaintenanceDate" value={formData.previousMaintenanceDate} onChange={handleChange} required />
                            </label>

                        </div>

                        <div id='groundingpoints-divbottom'>
                            <label>
                                <button id='groundingpoints-save' type="submit"> {Masterformsheadingsss.Submitheading}</button>
                            </label>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    );
};

export default Groundingpoints;
