

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './DGlogsfetch.css';
import { FaFileCsv } from "react-icons/fa6";
import { formatDateToDDMMYYYY, formatDateTimeToDDMMYYYYHHMM, convertToInputDateTimeFormat } from '../Dateformat';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
const DGlogsfetch = ({ onFormButtonClick }) => {
    const [logs, setLogs] = useState([]);
    const [filteredLogs, setFilteredLogs] = useState([]);
    const [selectedLog, setSelectedLog] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [errors, setErrors] = useState({
        kwhinitial: '',
        kwhfinal: '',
        starttime: '',
        endtime: ''
    });
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
        }
        setUserDesignation(user.level);
    }, []);

  
    const calculateDuration = (starttime, endtime) => {
        const startTime = moment(starttime);
        const endTime = moment(endtime);
    
        if (endTime.isSameOrAfter(startTime)) {
            const duration = moment.duration(endTime.diff(startTime));
            const days = Math.floor(duration.asDays());
            const hours = duration.hours();
            const minutes = duration.minutes();
    
            const formattedDuration = `${days}d ${hours < 10 ? '0' + hours : hours}h ${minutes < 10 ? '0' + minutes : minutes}m`;
    
            setSelectedLog(prevState => ({
                ...prevState,
                duration: formattedDuration
            }));
        } else {
            // If end time is before start time, set duration to empty
            setSelectedLog(prevState => ({
                ...prevState,
                duration: ''
            }));
        }
    };

    const calculateKWhConsumption = (kwhinitial, kwhfinal) => {
        const initial = parseFloat(kwhinitial);
        const final = parseFloat(kwhfinal);

        if (!isNaN(initial) && !isNaN(final)) {
            return (final - initial).toFixed(2);
        }
        return '';
    };

    useEffect(() => {
        const fetchLogs = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/dg-logs`);
                const data = response.data;
                console.log('Fetched Logs:', data); // Debugging line
                setLogs(data);
                setFilteredLogs(data);
            } catch (error) {
                console.error('Failed to fetch data:', error.message);
            }
        };

        fetchLogs();
    }, []);

    useEffect(() => {
        filterLogsByDate();
    }, [fromDate, toDate, logs]);

    const filterLogsByDate = () => {
        let filtered = logs;

        if (fromDate) {
            const [fromYear, fromMonth, fromDay] = fromDate.split('-');
            const fromDateFormatted = new Date(fromYear, fromMonth - 1, fromDay);  // Create a Date object for comparison

            filtered = filtered.filter(log => {
                const [logDay, logMonth, logYear] = log.date.split('-');
                const logDateFormatted = new Date(logYear, logMonth - 1, logDay); // Create a Date object from log.date
                return logDateFormatted >= fromDateFormatted; // Compare the dates
            });
        }

        if (toDate) {
            const [toYear, toMonth, toDay] = toDate.split('-');
            const toDateFormatted = new Date(toYear, toMonth - 1, toDay);  // Create a Date object for comparison

            filtered = filtered.filter(log => {
                const [logDay, logMonth, logYear] = log.date.split('-');
                const logDateFormatted = new Date(logYear, logMonth - 1, logDay); // Create a Date object from log.date
                return logDateFormatted <= toDateFormatted; // Compare the dates
            });
        }

        setFilteredLogs(filtered);
    };


    const handleEditClick = (log) => {

        setSelectedLog({
            ...log,
            date: log.date.split('-').reverse().join('-'), // Convert "DD-MM-YYYY" to "YYYY-MM-DD"
            starttime: convertToInputDateTimeFormat(log.starttime), // Convert to "YYYY-MM-DDTHH:MM"
            endtime: convertToInputDateTimeFormat(log.endtime) // Convert to "YYYY-MM-DDTHH:MM"
        });
        setIsPopupOpen(true);
    };

    
    const handleChange = (e) => {
        const { name, value } = e.target;
    
        setSelectedLog((prevState) => {
            const updatedFormData = {
                ...prevState,
                [name]: value,
            };
    
            let newErrors = { ...errors };
    
            // Validation for diesel consumption
            if (name === 'dieselconsumption') {
                if (value.match(/^\d{1,4}(\.\d{1,2})?$/)) {
                    newErrors.dieselconsumption = ''; // Clear error if valid
                } else {
                    newErrors.dieselconsumption = 'Enter a valid number up to 4 digits and 2 decimal places.';
                }
            }
    
            // Validation for KWh initial and final
            if (name === 'kwhinitial' || name === 'kwhfinal') {
                if (value.match(/^\d{0,15}(\.\d{0,2})?$/)) {
                    newErrors[name] = '';
                } else {
                    newErrors[name] = 'Enter a valid number up to 15 digits and 2 decimal places.';
                }
            }
    
            // Start and End Time Validation
            const startTime = moment(updatedFormData.starttime);
            const endTime = moment(updatedFormData.endtime);
            const endOfToday = moment().endOf('day');
    
            if (name === 'starttime' || name === 'endtime') {
                if (startTime.isAfter(endOfToday) || endTime.isAfter(endOfToday)) {
                    newErrors[name] = 'Date cannot be in the future.';
                } else {
                    newErrors[name] = '';
                }
    
                if (endTime.isBefore(startTime)) {
                    newErrors.endtime = 'End time cannot be earlier than start time.';
                } else {
                    newErrors.endtime = '';
                }
            }

               // Validation for KWh values
        if (name === 'kwhinitial' || name === 'kwhfinal') {
            const initial = parseFloat(updatedFormData.kwhinitial);
            const final = parseFloat(updatedFormData.kwhfinal);

            if (!isNaN(initial) && !isNaN(final) && initial > final) {
                newErrors.kwhinitial = 'KWh Initial value cannot be greater than KWh Final value.';
            } else {
                newErrors.kwhinitial = '';
                updatedFormData.kwhconsumption = calculateKWhConsumption(updatedFormData.kwhinitial, updatedFormData.kwhfinal);
            }
        }
    
            setErrors(newErrors);
            return updatedFormData;
        });
    };
    


    const handleUpdate = async () => {

        const requiredFields = [
           'starttime', 'endtime', 'kwhinitial', 'kwhfinal', 'dieselconsumption', 'duration'
        ];
    
        let hasErrors = false;
        const newErrors = { ...errors };
    
 // Validate required fields
 requiredFields.forEach((field) => {
    const fieldValue = selectedLog[field];

    // Check if the field is empty, handling both strings and numbers
    if (fieldValue === undefined || fieldValue === null || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
        newErrors[field] = `${field} is required.`;
        hasErrors = true;
    }
});


    // Additional validation for dieselconsumption pattern
    if (selectedLog.dieselconsumption && !/^\d{1,4}(\.\d{1,2})?$/.test(selectedLog.dieselconsumption)) {
        newErrors.dieselconsumption = 'Enter a valid number up to 4 digits and 2 decimal places.';
        hasErrors = true;
    }

     // Additional validation for specific fields
     if (selectedLog.starttime && selectedLog.endtime) {
        const startTime = moment(selectedLog.starttime);
        const endTime = moment(selectedLog.endtime);
        
        if (endTime.isBefore(startTime)) {
            newErrors.endtime = 'End time cannot be earlier than start time.';
            hasErrors = true;
        }
    }

    if (selectedLog.kwhinitial && selectedLog.kwhfinal && parseFloat(selectedLog.kwhinitial) > parseFloat(selectedLog.kwhfinal)) {
        newErrors.kwhinitial = 'KWh initial value cannot be greater than KWh final value.';
        hasErrors = true;
    }

    // Update the errors state if any errors exist
    setErrors(newErrors);

    // If there are errors, show a toast and stop submission
    if (hasErrors) {
        toast.error('Please fix all errors before submitting.');
        return;
    }

        // Format the date and time using the dateformat functions
        const formattedDate = formatDateToDDMMYYYY(selectedLog.date);
        const formattedStartTime = formatDateTimeToDDMMYYYYHHMM(selectedLog.starttime);
        const formattedEndTime = formatDateTimeToDDMMYYYYHHMM(selectedLog.endtime);

        const currentDate = new Date();
        const formattedModifiedDate = formatDateTimeToDDMMYYYYHHMM(currentDate.toISOString());

        const updatedLog = {
            ...selectedLog,
            date: formattedDate,
            starttime: formattedStartTime,
            endtime: formattedEndTime,
            modifiedby: loggedInUser?.username,
            modifiedat: formattedModifiedDate,
            kwhinitial: parseFloat(selectedLog.kwhinitial),
            kwhfinal: parseFloat(selectedLog.kwhfinal),
            kwhconsumption: parseFloat(selectedLog.kwhconsumption),
            dieselconsumption: parseFloat(selectedLog.dieselconsumption),
        };


        if (!errors.kwhinitial && !errors.kwhfinal && !errors.starttime && !errors.endtime) { 
            try {
                const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/dg-logs/${selectedLog._id}`, updatedLog);
                const data = response.data;
                if (data.success) {
                    setLogs(logs.map(log => (log._id === selectedLog._id ? updatedLog : log)));
                    setIsPopupOpen(false);
                    setSelectedLog(null);
                    toast.success('DG Log updated successfully');
                } else {
                    console.error('Failed to update data:', data.error);
                }
            } catch (error) {
                console.error('Error updating data:', error);
                if (error.response && error.response.data.message === 'A log already exists for the date') {
                    toast.error('Data already exists for the Selected Date, Please select any other Date');
                } else {
                    toast.error('Failed to update DG log. Please try again later.');
                }
            }
        }
        else {
            toast.error('Please fix the errors before submitting.');
        }
       
    };

    const downloadCSV = () => {
        // Define the headers for the CSV file
        const headers = ['Date', 'Start Time', 'End Time', 'KWhinitial', 'KWhfinal', 'KWh Consumption', 'Diesel Consumption', 'Duration'];

        // Map the log data to CSV format
        const rows = filteredLogs.map(log => [
            log.date,
            log.starttime,
            log.endtime,
            log.kwhinitial,
            log.kwhfinal,
            log.kwhconsumption,
            log.dieselconsumption,
            log.duration
        ]);

        // Combine headers and rows
        const csvContent = [
            headers.join(','), // Join headers with commas
            ...rows.map(row => row.join(',')) // Join each row's data with commas
        ].join('\n'); // Combine each row with a newline character

        // Create a blob from the CSV string
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // Create a link element
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'DG_Tracking.csv');
        document.body.appendChild(link); // Required for Firefox
        link.click();
        document.body.removeChild(link);
    };

    const handleFromDateChange = (e) => {
        const selectedFromDate = e.target.value;
        setFromDate(selectedFromDate);

        if (toDate && toDate < selectedFromDate) {
            setToDate('');
        }
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const getTodayDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
    };

    const getCurrentDateTime = () => {
        const now = new Date();
        return now.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:MM
    };

    const getEndOfToday = () => {
        // Get the end of today using moment
        return moment().endOf('day').format('YYYY-MM-DDTHH:mm');
    };

    return (
        <div id='dglogsfetch-total'>

            <div id='dglogsfetch-right'>

                <div id='dglogsfetch-right-2'>
                    <div id='dglogsfetch-right-21'>
                        <label>
                            <span>From Date:</span>
                            <input
                                type='date'
                                value={fromDate}
                                onChange={handleFromDateChange}
                                max={getTodayDate()}
                            />
                        </label>
                        <label>
                            <span>To Date:</span>
                            <input
                                type='date'
                                value={toDate}
                                onChange={handleToDateChange}
                                max={getTodayDate()}
                                min={fromDate || ''}
                                disabled={!fromDate}
                            />
                        </label>

                        <label id='formbutton-left'>
                            <button id='formbutton-from' onClick={onFormButtonClick}>Form</button>
                        </label>
                        <label>
                            <button id='formbutton-csv' onClick={downloadCSV}><FaFileCsv id='formbutton-csvicon' /></button>
                        </label>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>KWhinitial</th>
                                <th>KWhfinal</th>
                                <th>KWh Consumption</th>
                                <th>Diesel Consumption</th>
                                <th>Duration</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredLogs.length === 0 ? (
                                <tr>
                                    <td colSpan="10">No data available for selected filters.</td>
                                </tr>
                            ) : (
                                filteredLogs.map((log, index) => (
                                    <tr key={log._id || index}>
                                        <td>{log.date}</td>
                                        <td>{log.starttime}</td>
                                        <td>{log.endtime}</td>
                                        <td>{log.kwhinitial}</td>
                                        <td>{log.kwhfinal}</td>
                                        <td>{log.kwhconsumption}</td>
                                        <td>{log.dieselconsumption}</td>
                                        <td>{log.duration}</td>
                                        <td><button onClick={() => handleEditClick(log)}>Edit</button></td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    <Popup open={isPopupOpen} onClose={() => setIsPopupOpen(false)} modal>
                        <div id='dglogsfetch-popup'>
                            <h3>Edit DG Tracking Form</h3>
                            {selectedLog && (
                                <form>
                                    <div id='dglogsfetch-popup1'>
                                        <label>
                                            <span>  Date  <span id='all-mandetorymark'>*</span></span>
                                            <input type='date' name="date" value={selectedLog.date} onChange={handleChange} max={getTodayDate()} required/>
                                        </label>
                                        <label>
                                            <span>    Start time <span id='all-mandetorymark'>*</span></span>
                                            <input type='datetime-local' name="starttime" value={selectedLog.starttime} onChange={handleChange} max={getEndOfToday()} required />
                                            {errors.starttime && <span id='dglogsfetch-errormessage' style={{ color: 'red' }}>{errors.starttime}</span>}
                                        </label>
                                    </div>
                                    <div id='dglogsfetch-popup1'>
                                        <label>
                                            <span>  End time  <span id='all-mandetorymark'>*</span></span>
                                            <input type='datetime-local' name="endtime" value={selectedLog.endtime} onChange={handleChange} max={getEndOfToday()} required />
                                            {errors.endtime && <span id='dglogsfetch-errormessage' style={{ color: 'red' }}>{errors.endtime}</span>}
                                        </label>
                                        <label>
                                            <span>  KWh initial <span id='all-mandetorymark'>*</span></span>
                                            <input name="kwhinitial" value={selectedLog.kwhinitial} onChange={handleChange} required />
                                            {errors.kwhinitial && <span id='dglogsfetch-errormessage' style={{ color: 'red' }}>{errors.kwhinitial}</span>}
                                        </label>
                                    </div>

                                    <div id='dglogsfetch-popup1'>
                                        <label>
                                            <span> KWh final <span id='all-mandetorymark'>*</span></span>
                                            <input type='number' name="kwhfinal" value={selectedLog.kwhfinal} onChange={handleChange} required />
                                            {errors.kwhfinal && <span id='dglogsfetch-errormessage' style={{ color: 'red' }}>{errors.kwhfinal}</span>}
                                        </label>
                                        <label>
                                            <span>KWh Consumption  <span id='all-mandetorymark'>*</span></span>
                                            <input type='number' name="kwhconsumption" value={selectedLog.kwhconsumption} onChange={handleChange} disabled/>
                                        </label>
                                    </div>
                                    <div id='dglogsfetch-popup1'>

                                        <label>
                                            <span>  Duration<span id='all-mandetorymark'>*</span></span>
                                            <input name="duration" value={selectedLog.duration} onChange={handleChange} disabled />
                                        </label>

                                        <label>
                                            <span>Diesel Consumption  <span id='all-mandetorymark'>*</span></span>
                                            <input type='number' name="dieselconsumption" value={selectedLog.dieselconsumption} onChange={handleChange} required />
                                            {errors.dieselconsumption && <span span id='breakdownfetch-errormessage' style={{ color: 'red' }}>{errors.dieselconsumption}</span>}
                                        </label>
                                    </div>

                                    <button id='dglogsfetch-popup-update' type="button" onClick={handleUpdate}>Update</button>
                                </form>
                            )}
                        </div>
                    </Popup>
                </div>
            </div>
        </div>
    );
}

export default DGlogsfetch;
