
export const formatDateToDDMMYYYY = (date) => {
    return new Date(date).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric' // Use 'numeric' to get the full 4-digit year
    }).replace(/\//g, '-'); // Replace '/' with '-' to match the desired format
};




// Dateformat.js or dateUtils.js
export const formatDateTimeToDDMMYYYYHHMM = (datetime) => {
    const date = new Date(datetime);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
};


export const convertToInputDateTimeFormat = (dateTime) => {
    if (!dateTime) return '';

    const [date, time] = dateTime.split(' ');
    const [day, month, year] = date.split('-');
    const [timeValue, modifier] = time.split(' ');
    let [hours, minutes] = timeValue.split(':');

    if (modifier === 'PM' && hours !== '12') {
        hours = parseInt(hours, 10) + 12;
    } else if (modifier === 'AM' && hours === '12') {
        hours = '00';
    }

    // Ensure hours and minutes are two digits
    hours = String(hours).padStart(2, '0');
    minutes = String(minutes).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
};



// ----------------
export const formatDateToMMYYYY = (date) => {
    // Create a new Date object from the given date
    const parsedDate = new Date(date);
    // Check if the created date is valid
    if (isNaN(parsedDate)) {
        return "Invalid Date";
    }
    // Extract the month and year
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns 0-based month
    const year = parsedDate.getFullYear();

    // Return the date in MM-YYYY format
    return `${month}-${year}`;
};



export const convertMMYYYYtoYYYYMM = (dateString) => {
    if (!dateString) return '';
    const [month, year] = dateString.split('-');
    return `${year}-${month}`; // Convert MM-YYYY to YYYY-MM
};

export const convertYYYYMMtoMMYYYY = (dateString) => {
    if (!dateString) return '';
    const [year, month] = dateString.split('-');
    return `${month}-${year}`; // Convert YYYY-MM to MM-YYYY
};





