
// import React, { useState } from "react";
// import { Link } from "react-router-dom";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBars } from "@fortawesome/free-solid-svg-icons";
// import Logo from '../../../Images/logo.png';
// import "./Sidebar.css";
// import { FaInfoCircle, FaHome } from "react-icons/fa";
// import { SiBloglovin } from "react-icons/si";
// import { MdMiscellaneousServices } from "react-icons/md";
// import { MdContactPhone } from "react-icons/md";

// const Operatorsidebar = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const onleave=()=>{
//     setIsOpen(false);
//   };
//   const onenter=()=>{
//     setIsOpen(true);
//   }

//   return (


//     <div>
//       <div className={`sidebar ${isOpen ? "sidebar--open" : ""}`    }    onMouseLeave={onleave}  onMouseEnter={onenter} >
//         <div className="trigger"  >
//           <Link to='/'>
//           <div className="sidbarMainlogo">
//             <img src={Logo} alt="" />
//           </div> 
//           </Link>
//           <FontAwesomeIcon icon={isOpen ? '': faBars}  />
//         </div>

//         <Link to='/' className="sidebar-position">

//             <FaHome/>          
//           <span>Home</span>
//         </Link>



//         {/* <Link  to='/' className="sidebar-position">
//           <MdMiscellaneousServices />

//           <span>Alerts History</span>
//         </Link> */}

//         <Link  to='/Livealerts' className="sidebar-position">
//           <SiBloglovin />

//           <span>Live Alerts</span>
//         </Link>

//         <Link  to='/Livedata' className="sidebar-position">
//           <FaInfoCircle />

//           <span>Live Data</span>
//         </Link>
//         <Link to='/Historydata' className="sidebar-position">
//           <MdContactPhone />

//           <span>Data History</span>
//         </Link>

//         {/* <Link to='/Statussummary' className="sidebar-position">
//           <MdContactPhone />

//           <span>Status Summary</span>
//         </Link> */}

//         <Link to='/Sectionpoints' className="sidebar-position">
//           <MdContactPhone />

//           <span>Section Wise View</span>
//         </Link>

//         <Link to='/TODview' className="sidebar-position">
//           <MdMiscellaneousServices />

//           <span>TOD Live</span>
//         </Link>

//         <Link to='/TODHistory' className="sidebar-position">
//           <MdMiscellaneousServices />

//           <span>TOD History</span>
//         </Link>

//         <Link to='/Breakdownlogs' className="sidebar-position">
//           <MdContactPhone />

//           <span>Breakdownlogs</span>
//         </Link>

//         <Link to='/Breakdownlogsfetch' className="sidebar-position">
//           <MdContactPhone />

//           <span>Breakdownlogsfetch</span>
//         </Link>

//         <Link to='/Transformermonitoring' className="sidebar-position">
//           <MdContactPhone />

//           <span>Transformermonitoring</span>
//         </Link>

//         <Link to='/Transformermonitoringfetch' className="sidebar-position">
//           <MdContactPhone />

//           <span>Transformermonitoring</span>
//         </Link>
//         <Link to='/DGlogs' className="sidebar-position">
//           <MdContactPhone />

//           <span>DG Logs</span>
//         </Link>
//         <Link to='/DailyConsumption' className="sidebar-position">
//           <MdContactPhone />

//           <span>Daily Consumption</span>
//         </Link>
//         <Link to='/Tranformerform' className="sidebar-position">
//           <MdContactPhone />

//           <span>Tranformer Form</span>
//         </Link>

//         <Link to='/Areaform' className="sidebar-position">
//           <MdContactPhone />

//           <span>Location Form</span>
//         </Link>

//         <Link to='/' className="sidebar-position">
//           <MdContactPhone />

//           <span>Logout</span>
//         </Link>
//       </div>
//     </div>
//   );
// };

// export default Operatorsidebar;





// import React, { useState  , useEffect} from "react";
// import { Link , useNavigate} from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBars } from "@fortawesome/free-solid-svg-icons";
// import Logo from '../../../Images/logo.png';
// import daily from '../../../Images/daily.png';
// import sections from '../../../Images/sections.png';
// import sld from '../../../Images/sld.png';
// import "./Sidebar.css";

// import { BiSolidHome } from "react-icons/bi";
// import { GrTasks } from "react-icons/gr";
// import { MdDescription } from "react-icons/md";
// import { TbAlertSquareFilled } from "react-icons/tb";
// import { BsClipboardDataFill } from "react-icons/bs";
// import { SiGoogleforms } from "react-icons/si";
// import { BsCalendar3 } from "react-icons/bs";
// import { FaRegUserCircle } from "react-icons/fa";
// import { IoMdLogOut } from "react-icons/io";
// const Operatorsidebar = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [activeDropdown, setActiveDropdown] = useState(null);

//   const [username, setUsername] = useState('');
//   const [designation, setDesignation] = useState('');
//   const navigate = useNavigate();

//   useEffect(() => {
//     // Retrieve the user data from local storage
//     const user = JSON.parse(localStorage.getItem('user'));
//     if (user) {
//       setUsername(user.username);
//       setDesignation(user.designation); // Assuming "level" is used for the designation
//     }
//   }, []);

//   const onleave = () => {
//     setIsOpen(false);
//     setActiveDropdown(null); // Close any open dropdown when the sidebar loses focus
//   };

//   const onenter = () => {
//     setIsOpen(true);
//   };

//   const toggleDropdown = (dropdownName) => {
//     if (activeDropdown === dropdownName) {
//       setActiveDropdown(null);
//     } else {
//       setActiveDropdown(dropdownName);
//     }
//   };

//   const handleLogout = () => {
//     // setUser(null);
//     localStorage.removeItem('user');
//     navigate('/');
//   };

//   return (
//     <div>
//       <div className={`sidebar ${isOpen ? "sidebar--open" : ""}`} onMouseLeave={onleave} onMouseEnter={onenter}>
//         <div className="trigger">
//           <Link to='/Operator-Dash'>
//             <div className="sidbarMainlogo">
//               <img src={Logo} alt="" />
//             </div>
//           </Link>
//           <FontAwesomeIcon icon={isOpen ? '' : faBars} />
//         </div>

//         <Link className="sidebar-position">
//           <FaRegUserCircle className="sidebar-iconss"/>
//           <label style={{display:'flex', flexDirection:'column'}}>
//           <span>Username: {username}</span>
//           <span style={{marginTop:'3px'}}> Designation: {designation}</span>
//           </label>
//         </Link>
//         <Link to='/Operator-Dash' className="sidebar-position">
//           <BiSolidHome className="sidebar-iconss" />
//           <span>Home</span>
//         </Link>

//         <Link to='/PendingTasks' className="sidebar-position">
//           <GrTasks className="sidebar-iconss" />
//           <span>Tasks</span>
//         </Link>

//         <Link to='/LiveSummary' className="sidebar-position">
//           <MdDescription  className="sidebar-iconss1"/>
//           <span>Live Plant Summary</span>
//         </Link>

//         <Link to='/Alertspage' className="sidebar-position">
//           <TbAlertSquareFilled  className="sidebar-iconss"/>
//           <span>Alerts</span>
//         </Link>
//         <Link to='/AllTOD' className="sidebar-position">
//           <BsCalendar3  className="sidebar-iconss" />
//           <span>TOD Tracking</span>
//         </Link>

//         <Link to='/DailyConsumption' className="sidebar-position">
//           {/* <FaHome /> */}
//           <img className="sidebarimage" src={daily} alt=""  />
//           <span> Consumption Monitoring</span>
//         </Link>

//         <Link to='/Section-Wise-View' className="sidebar-position">
//         <img className="sidebarimage1" src={sections} alt=""  />
//           <span>Sections</span>
//         </Link>

//         <Link to='/SLD' className="sidebar-position">
//         <img className="sidebarimage2" src={sld} alt=""  />
//           <span>SLD</span>
//         </Link>

//         <Link to='/Historydata' className="sidebar-position">
//           <BsClipboardDataFill className="sidebar-iconss"/>
//           <span> History Data</span>
//         </Link>

//         <Link to='/Forms' className="sidebar-position">
//           <SiGoogleforms className="sidebar-iconss"/>
//           <span> Forms</span>
//         </Link>

//         <Link  onClick={handleLogout} to='/' className="sidebar-position">
//           <IoMdLogOut className="sidebar-iconss" />
//           <span>Logout</span>
//         </Link>
//       </div>
//     </div>
//   );
// };

// export default Operatorsidebar;


import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Logo from '../../../Images/logo.svg';
import daily from '../../../Images/daily.png';
import sections from '../../../Images/sections.png';
import sld from '../../../Images/sld.png';
import "./Sidebar.css";
import { BiSolidHome } from "react-icons/bi";
import { GrTasks } from "react-icons/gr";
import { MdDescription } from "react-icons/md";
import { TbAlertSquareFilled } from "react-icons/tb";
import { BsClipboardDataFill } from "react-icons/bs";
import { SiGoogleforms } from "react-icons/si";
import { BsCalendar3 } from "react-icons/bs";
import { FaRegUserCircle } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { PiUserSwitchBold } from "react-icons/pi";

const Operatorsidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [username, setUsername] = useState('');
  const [designation, setDesignation] = useState('');
  const [level, setLevel] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [popupForForms, setPopupForForms] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve the user data from local storage
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setUsername(user.username);
      setLevel(user.level);
      setDesignation(user.designation);
    }
  }, []);

  const onleave = () => {
    setIsOpen(false);
    setActiveDropdown(null);
  };

  const onenter = () => {
    setIsOpen(true);
  };

  const toggleDropdown = (dropdownName) => {
    if (activeDropdown === dropdownName) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(dropdownName);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('accessedLevel');
    navigate('/');
  };

  const handleLiveSummaryClick = (e) => {
    if (level !== 'Level-4') {
      e.preventDefault();
      setShowPopup(true);
    }
  };

  const handleFormsClick = (e) => {
    if (level !== 'Level-4') {
      e.preventDefault();
      setPopupForForms(true);
    }
  };

  const handlePopupClose = (path) => {
    setShowPopup(false);
    setPopupForForms(false);
    navigate(path);
  };

  const handlePopupCancel = () => {
    setShowPopup(false);
    setPopupForForms(false);
  };

  return (
    <div>
      <div className={`sidebar ${isOpen ? "sidebar--open" : ""}`} onMouseLeave={onleave} onMouseEnter={onenter}>
        <div className="trigger">
          <Link to='/Operator-Dash'>
            <div className="sidbarMainlogo">
              <img src={Logo} alt="" />
            </div>
          </Link>
          <FontAwesomeIcon icon={isOpen ? '' : faBars} />
        </div>

        <Link className="sidebar-position">
          <FaRegUserCircle className="sidebar-iconss" />
          <label style={{ display: 'flex', flexDirection: 'column' }}>
            <span>Username: {username}</span>
            <span style={{ marginTop: '3px' }}> Designation: {username}</span>
          </label>
        </Link>
        <Link to='/Operator-Dash' className="sidebar-position">
          <BiSolidHome className="sidebar-iconss" />
          <span>Home</span>
        </Link>

        {/* <Link to='/PendingTasks' className="sidebar-position">
          <GrTasks className="sidebar-iconss" />
          <span>Tasks</span>
        </Link> */}

        {/* Modified Live Summary Link */}
        <Link to='/LiveSummary' className="sidebar-position" >
          <MdDescription className="sidebar-iconss1" />
          <span>Live Plant Summary</span>
        </Link>

        <Link to='/Alertspage' className="sidebar-position">
          <TbAlertSquareFilled className="sidebar-iconss" />
          <span>Alerts</span>
        </Link>
        <Link to='/AllTOD' className="sidebar-position">
          <BsCalendar3 className="sidebar-iconss" />
          <span>TOD Tracking</span>
        </Link>

        <Link to='/DailyConsumption' className="sidebar-position">
          <img className="sidebarimage" src={daily} alt="" />
          <span> Consumption Monitoring</span>
        </Link>

        <Link to='/Section-Wise-View' className="sidebar-position">
          <img className="sidebarimage1" src={sections} alt="" />
          <span>Sections</span>
        </Link>

        {/* <Link to='/SLD' className="sidebar-position">
          <img className="sidebarimage2" src={sld} alt="" />
          <span>SLD</span>
        </Link> */}

        <Link to='/Historydata' className="sidebar-position">
          <BsClipboardDataFill className="sidebar-iconss" />
          <span> History Data</span>
        </Link>

        <Link to='/Forms' className="sidebar-position" >
          <SiGoogleforms className="sidebar-iconss" />
          <span>Forms</span>
        </Link>


        {level !== 'Level-4' && (
          <Link to='/Loginscreens' className="sidebar-position">
            <PiUserSwitchBold className="sidebar-iconss1" />
            <span>Switch</span>
          </Link>
        )}

        <Link onClick={handleLogout} to='/' className="sidebar-position">
          <IoMdLogOut className="sidebar-iconss" />
          <span>Logout</span>
        </Link>
      </div>

      {showPopup && (
        <div className="popup-1234">
          <div className="popup-content-sidebar">
            <h3>Notice</h3>
            <p>You are about to navigate to the Live Plant Summary page.</p>
            <div className="popup-buttons">
              <button onClick={() => handlePopupClose('/LiveSummary')}>Okay</button>
              <button onClick={handlePopupCancel}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {popupForForms && (
        <div className="popup-1234">
          <div className="popup-content-sidebar">
            <h3>Notice</h3>
            <p>You are about to navigate to the Forms page.</p>
            <div className="popup-buttons">
              <button onClick={() => handlePopupClose('/Forms')}>Okay</button>
              <button onClick={handlePopupCancel}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Operatorsidebar;


