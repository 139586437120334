import axiosInstance from "../axios";
import ROUTES from "../routes.json";

export const fetchWeekReportData = async (month, week) => {
  const params = {
    month,
    week_number: week,
  };

  try {
    return (
      await axiosInstance.get(ROUTES.API.REPORTS.WEEKLY_REPORT_URL, { params })
    ).data;
  } catch (error) {
   
    throw new Error(
      error.response?.data?.message || "Error fetching weekly report"
    );
  }
};
