import { useQuery } from "@tanstack/react-query";
import { fetchWeekReportData } from "./weeklyReportService";

export const useWeekReportQuery = ({ month, week, enabled }) =>
  useQuery({
    queryKey: ["weekReport", month, week],
    queryFn: () => fetchWeekReportData(month, week),
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    enabled: !!enabled,
    retry: 0,
  });
