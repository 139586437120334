import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Mastertransformer.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Mastertransformerform = ({ onViewData }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    const initialFormData = {
        date: getCurrentDate(),
        mastertransformerId: '',
        name: '',
        parentname: '',
        transformername: '',
        phase: '',
        primaryvoltage: '',
        secondaryvoltage: '',
        kvarating: '',
        fullloadcurrent: '',
        impedence: '',
        idleloadcurrent: '',
        vectortype: '',
        coolanttype: '',
        make: '',
        slno: '',
        iecstandard: '',
        isostandard : '',
        dimentions  : '',
        weight : '',
        proposedperiodicityofmaintenance  : '',
        previousmaintenancedonedate  : '',
        spare1: '',
        spare2: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-hvincomerNames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching incomer names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = {
                ...formData,
                primaryvoltage: Number(formData.primaryvoltage),
                secondaryvoltage: Number(formData.secondaryvoltage),
                kvarating: Number(formData.kvarating),
                fullloadcurrent: Number(formData.fullloadcurrent),
                impedence: Number(formData.impedence),
                idleloadcurrent: Number(formData.idleloadcurrent),
                slno: Number(formData.slno),
                dimentions: Number(formData.dimentions),
                weight: Number(formData.weight),
            };

            await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/mastertransformers`, dataToSend);

            toast.success('Transformer added successfully');
            setFormData(initialFormData); 
            fetchParentNames(); 
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    return (
        <div id='mastertrans-total'>
           
            <div id='mastertrans-right'>
                <div id='mastertrans-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='mastertrans-divtop'>
                            <label>
                            {Masterformsheadingsss.TransformerHeading}
                            </label>
                            <label>
                            <button type="button" onClick={onViewData}>View Data</button>
                            </label>
                        </div>
                        <div id='mastertrans-div1'>
                            <label>
                                <span>Date</span>
                                <input type='date' name="date" value={formData.date} disabled />
                            </label>
                            <label>
                                <span>Parent Name</span>
                                <select name="parentname" value={formData.parentname} onChange={handleChange} >
                                    <option value="">Select Parent Name</option>
                                    {parentNames.map((name, index) => (
                                        <option key={index} value={name}>{name}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div id='mastertrans-div1'>
                            <label>
                                <span>Name</span>
                                <input name="name" value={formData.name} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Name of the Transformer</span>
                                <input name="transformername" value={formData.transformername} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastertrans-div1'>
                            <label>
                                <span>Phase</span>
                                <select name="phase" value={formData.phase} onChange={handleChange} required>
                                    <option value=''>Select Phase</option>
                                    <option value='3-Phase'>3-Phase</option>
                                    <option value='1-Phase-Vr'>1-Phase-Vr</option>
                                    <option value='1-Phase-Vy'>1-Phase-Vy</option>
                                    <option value='1-Phase-Vb'>1-Phase-Vb</option>
                                </select>
                            </label>
                            <label>
                                <span>Primary Voltage</span>
                                <input type='number' name="primaryvoltage" value={formData.primaryvoltage} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastertrans-div1'>
                            <label>
                                <span>Secondary Voltage</span>
                                <input type='number' name="secondaryvoltage" value={formData.secondaryvoltage} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>KVA Rating</span>
                                <input type='number' name="kvarating" value={formData.kvarating} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastertrans-div1'>
                            <label>
                                <span>Full Load Current</span>
                                <input type='number' name="fullloadcurrent" value={formData.fullloadcurrent} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Impedence</span>
                                <input type='number' name="impedence" value={formData.impedence} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastertrans-div1'>
                            <label>
                                <span>Idle Load Current</span>
                                <input type='number' name="idleloadcurrent" value={formData.idleloadcurrent} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Vector Type</span>
                                <input name="vectortype" value={formData.vectortype} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastertrans-div1'>
                            <label>
                                <span>Coolant Type</span>
                                <input name="coolanttype" value={formData.coolanttype} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Make</span>
                                <input name="make" value={formData.make} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastertrans-div1'>
                            <label>
                                <span>Serial No.</span>
                                <input type='number' name="slno" value={formData.slno} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>IEC Standard</span>
                                <input name="iecstandard" value={formData.iecstandard} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastertrans-div1'>
                            <label>
                                <span>ISO Standard</span>
                                <input name="isostandard" value={formData.isostandard} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Dimensions</span>
                                <input type='number' name="dimentions" value={formData.dimentions} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastertrans-div1'>
                            <label>
                                <span>Weight</span>
                                <input type='number' name="weight" value={formData.weight} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Proposed Periodicity of Maintenance</span>
                                <input name="proposedperiodicityofmaintenance" value={formData.proposedperiodicityofmaintenance} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='mastertrans-div1'>
                            <label>
                                <span>Previous Maintenance Done Date</span>
                                <input type='date' name="previousmaintenancedonedate" value={formData.previousmaintenancedonedate} onChange={handleChange} required />
                            </label>
                            {/* <label>
                                <span>Spare 1</span>
                                <input name="spare1" value={formData.spare1} onChange={handleChange} />
                            </label> */}
                        </div>
                        <div id='mastertrans-div1'>
                            {/* <label>
                                <span>Spare 2</span>
                                <input name="spare2" value={formData.spare2} onChange={handleChange} />
                            </label> */}
                        </div>
                      
                            <button id='mastertrans-save' type="submit">Submit</button>
                   
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Mastertransformerform;
