


import { useState, React } from 'react';
import './ReportAlertcount.css';
import Overallalertscount from './Overallalertscount/Overallalertscount';
import Stationpointsalerts from './StationpointsAlerts/Stationpointsalerts';

const ReportsAlertscount = () => {

    const [activeSection, setActiveSection] = useState('Overallalertscount-1');

    const handleButtonClick = (section) => {
        setActiveSection(section);
    };

    return (
        <div >
              <div id='alertcount-report-right-2'>
                    <div id='alertcount-report-right-21'>
                        <div id='alertcount-report-right-23'>
                            <button
                                className={activeSection === 'Overallalertscount-1' ? 'active' : ''}
                                onClick={() => handleButtonClick('Overallalertscount-1')}
                            >
                                Overall Alerts
                            </button>
                            <button
                                className={activeSection === 'Stationpointsalerts-1' ? 'active' : ''}
                                onClick={() => handleButtonClick('Stationpointsalerts-1')}
                            >
                              Section Alerts
                            </button>
                   
                        </div>
                    </div>

                    <div id='alertcount-report-right-3'>
                        <div>
                            <div>
                                {activeSection === 'Overallalertscount-1' && <Overallalertscount />}
                                {activeSection === 'Stationpointsalerts-1' && <Stationpointsalerts />}
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default ReportsAlertscount;
