import React, { useState , useEffect} from 'react';
import './Stabilizerform.css';
import axios from 'axios';
import { IoIosAddCircle } from "react-icons/io";
import { IoIosRemoveCircle } from "react-icons/io";
import { Voltagevoltagefluctuationsheader } from '../../../Operator/Data';
import { Link, useParams ,useNavigate  } from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import { getCurrentDateTime } from '../../../Diagnose/dateUtils';
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const Stabilizerform = () => {
  const { token_id } = useParams();
  const navigate = useNavigate();
  const [stabilizers, setStabilizers] = useState([{ stabilizername: '', stabilizer_value: '' }]);

  const [loggedInUser, setLoggedInUser] = useState(null);
  const [userDesignation, setUserDesignation] = useState('');
  useEffect(() => {
    // Retrieve the logged-in user from local storage
    const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        setLoggedInUser(user);
      }
    setUserDesignation(user.level);
  }, []);

  const handleAdd = () => {
    setStabilizers([...stabilizers, { stabilizername: '', stabilizer_value: '' }]);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newStabilizers = [...stabilizers];
    newStabilizers[index][name] = value;
    setStabilizers(newStabilizers);
  };

  const handleCancel = (index) => {
    const newStabilizers = stabilizers.filter((_, i) => i !== index);
    setStabilizers(newStabilizers);
  };

  const initialStabilizerData = {
    dateandtime: getCurrentDateTime(),
    stabilizername: '',
    stabilizer_value: '',
    token_id: token_id,
    createdby: '',
    createdat: '',
    modifiedby: '',
    modifiedat: '',
    spare1: '',
    spare2: ''
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSend = stabilizers.map(stabilizer => ({
        ...initialStabilizerData,
        ...stabilizer,
        stabilizer_value: Number(stabilizer.stabilizer_value),
      }));

      const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/stabilizers`, dataToSend);

      console.log('Data posted successfully:', response.data);

      alert('Stabilizer data added successfully');
      navigate('/Stabilizerformfetch');
      setStabilizers([{ stabilizername: '', stabilizer_value: '' }]);
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
  };


  return (


    <div id='stabilizerform-total'>

      <div>
        {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />}
      </div>
      <div id='stabilizerform-right'>

        <div id='stabilizerform-right-1'>
          <div id='stabilizerform-heading'>
            {Voltagevoltagefluctuationsheader.alertHeading}
          </div>
          <div id='stabilizerform-heading1'>
            {Voltagevoltagefluctuationsheader.alertActions.map((action, index) => (
              <button key={index} aria-label={action.label}>
                {action.icon}
              </button>
            ))}
            <img src={Voltagevoltagefluctuationsheader.logoSrc} alt='logo' />
          </div>
        </div>
        <h3 id='stabilizerform-h3'>{Voltagevoltagefluctuationsheader.subalertHeading}</h3>
        <div id='stabilizerform-right-2'>
          <form onSubmit={handleSubmit}>
            <div id='stabilizerform-divtop'>
              <label >
                <span>Current Stabilizer</span>
                {/* <span>#123456</span> */}
              </label>
              {/* <label>
                <Link to='/Stabilizerformfetch'><button type="button">View Data</button></Link>
              </label> */}
            </div>

            {stabilizers.map((stabilizer, index) => (
              <div key={index} id='stabilizerform-row'>
                <label>
                  <span>Stabilizer Name <span id='all-mandetorymark'>*</span></span>
                  <input
                    name="stabilizername"
                    value={stabilizer.stabilizername}
                    onChange={(e) => handleChange(index, e)}
                    required
                  />
                </label>
                <label>
                  <span>Stabilizer Value <span id='all-mandetorymark'>*</span></span>
                  <input
                    name="stabilizer_value"
                    type="number"
                    value={stabilizer.stabilizer_value}
                    onChange={(e) => handleChange(index, e)}
                    required
                  />
                </label>
                {index !== 0 && (
                  <button id='stabilizerform-cancelbtn' type="button" onClick={() => handleCancel(index)}><IoIosRemoveCircle id='reg-btn' /></button>
                )}
                {index === stabilizers.length - 1 && (
                  <button id='stabilizerform-cancelbtn' type="button" onClick={handleAdd}><IoIosAddCircle id='reg-btn' /></button>
                )}

              </div>
            ))}

            <label>
              <button id='stabilizerform-submit' type="submit">Submit</button>
            </label>
          </form>
        </div>

      </div>

    </div>
  );
};

export default Stabilizerform;
