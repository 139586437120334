import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import { Power_Surgesheader } from '../../../Operator/Data';
import { Link } from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import './internal_Faultfetch.css';
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const InternalFaultfetch = () => {
    const [faults, setFaults] = useState([]);
    const [faultEditData, setFaultEditData] = useState(null);

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
      // Retrieve the logged-in user from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        setLoggedInUser(user);
      }
      setUserDesignation(user.level);
    }, []);

    const fetchFaults = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/internalFaults`);
            setFaults(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchFaults();
    }, []);

    const handleEditClick = (fault) => {
        setFaultEditData(fault);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setFaultEditData({ ...faultEditData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        console.log('Updating data:', faultEditData);
        alert("Internal fault updated successfully");
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/internalFaults/${faultEditData._id}`, faultEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchFaults();
            setFaultEditData(null);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='internalFaultfetch-total'>
           <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />}
            </div>
            <div id='internalFaultfetch-right'>
                <div id='internalFaultfetch-right-1'>
                    <div id='internalFaultfetch-heading'>
                        {Power_Surgesheader.alertHeading}
                    </div>
                    <div id='internalFaultfetch-heading1'>
                        {Power_Surgesheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Power_Surgesheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <div id='internalFaultfetch-right-2'>
                    <div id='internalFaultfetch-right-21'>
                    <label>
                            <span style={{fontWeight:'bold'}}>Internal fault found in any machine</span>
                        </label>
                        {/* <label>
                            <Link to='/Internal_Fault'><button>Form</button></Link>
                        </label> */}
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Token ID</th>
                                <th>Date and Time</th>
                                <th>Machine Name</th>
                                <th>Type of Fault</th>
                                {/* <th>Created By</th>
                                <th>Created At</th>
                                <th>Modified By</th>
                                <th>Modified At</th> */}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {faults.map((fault) => (
                                <tr key={fault._id}>
                                    <td>{fault.token_id}</td>
                                    <td>{fault.dateandtime}</td>
                                    <td>{fault.machine_name}</td>
                                    <td>{fault.type_of_fault}</td>
                                    {/* <td>{fault.createdby}</td>
                                    <td>{fault.createdat}</td>
                                    <td>{fault.modifiedby}</td>
                                    <td>{fault.modifiedat}</td> */}
                                    <td>
                                        <button onClick={() => handleEditClick(fault)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {faultEditData && (
                        <Popup open={true} onClose={() => setFaultEditData(null)}>
                            <div id='internalFaultfetch-popup'>
                                <h3>Edit Internal Fault</h3>
                                <form onSubmit={handleEditSubmit}>

                                    <div id='internalFaultfetch-popup1'>
                                        <label>
                                            <span>Token ID</span>
                                            <input type="text" name="token_id" value={faultEditData.token_id} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Date and Time</span>
                                            <input type="text" name="dateandtime" value={faultEditData.dateandtime} onChange={handleEditChange} disabled />
                                        </label>
                                    </div>
                                    <div id='internalFaultfetch-popup1'>
                                        <label>
                                            <span>Machine Name <span id='all-mandetorymark'>*</span></span>
                                            <input type="text" name="machine_name" value={faultEditData.machine_name} onChange={handleEditChange} required/>
                                        </label>
                                        <label>
                                            <span>Type of Fault <span id='all-mandetorymark'>*</span></span>
                                            <input type="text" name="type_of_fault" value={faultEditData.type_of_fault} onChange={handleEditChange} required/>
                                        </label>
                                    </div>
                                    {/* <div id='internalFaultfetch-popup1'>
                                        <label>
                                            <span>Created By</span>
                                            <input type="text" name="createdby" value={faultEditData.createdby} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Created At</span>
                                            <input type="text" name="createdat" value={faultEditData.createdat} onChange={handleEditChange} disabled />
                                        </label>
                                    </div>
                                    <div id='internalFaultfetch-popup1'>
                                        <label>
                                            <span>Modified By</span>
                                            <input type="text" name="modifiedby" value={faultEditData.modifiedby} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Modified At</span>
                                            <input type="text" name="modifiedat" value={faultEditData.modifiedat} onChange={handleEditChange} disabled />
                                        </label>
                                    </div> */}

                                    <button id='internalFaultfetch-popup-update' type="submit">Update</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default InternalFaultfetch;
