// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#monthlydash-div {
    /* margin-left: 50vw; */
    height: 6vh;
    border: 1.5px solid #DFE6FA;
    padding: 8px 13px 5px 13px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    #monthlydash-div1 {
        align-items: center;
        /* margin-top: 1vh; */

        #monthlydash-bold{
            font-weight: bold;
        }
    }

    #monthlydash-div2 {
        align-items: center;

        input {
            height: 4.5vh;
            border-radius: 5px;
            border: 1.5px solid #DFE6FA;
        }
    }

}`, "",{"version":3,"sources":["webpack://./src/Components/SuperDashboards/Monthly-Dash/MonthlyDashboard.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,WAAW;IACX,2BAA2B;IAC3B,0BAA0B;IAC1B,kBAAkB;IAClB,uBAAuB;IACvB,aAAa;IACb,8BAA8B;IAC9B,qBAAqB;IACrB,mBAAmB;;IAEnB;QACI,mBAAmB;QACnB,qBAAqB;;QAErB;YACI,iBAAiB;QACrB;IACJ;;IAEA;QACI,mBAAmB;;QAEnB;YACI,aAAa;YACb,kBAAkB;YAClB,2BAA2B;QAC/B;IACJ;;AAEJ","sourcesContent":["#monthlydash-div {\n    /* margin-left: 50vw; */\n    height: 6vh;\n    border: 1.5px solid #DFE6FA;\n    padding: 8px 13px 5px 13px;\n    border-radius: 8px;\n    background-color: white;\n    display: flex;\n    justify-content: space-between;\n    align-content: center;\n    align-items: center;\n\n    #monthlydash-div1 {\n        align-items: center;\n        /* margin-top: 1vh; */\n\n        #monthlydash-bold{\n            font-weight: bold;\n        }\n    }\n\n    #monthlydash-div2 {\n        align-items: center;\n\n        input {\n            height: 4.5vh;\n            border-radius: 5px;\n            border: 1.5px solid #DFE6FA;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
