

import React, { useEffect, useState } from 'react';
import MonthlyDashboard from './MonthlyDashboard';
import DashboardRevenueloss from './Revenueloss/DashRevenueloss';
import DashTypeofloadConsumption from './Typeofload/DashTypeofload';
import DashPF from './DashPF/DashPF';
import DashMaxdemsndkVA from './Maxdemand/MaxdemandkVA';
import Dashenergyintencity from './Dashenergyintencity/Dashenergyintencity';
import DashTransformerhealth from './DashTransformerhealth/DashTransformerhealth';

const MonthlyDashboardContainer = () => {
  const [selectedMonth, setSelectedMonth] = useState(() => {
    const date = new Date();
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
  });

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
  };

  useEffect(() => {

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
  
    scrollToTop();

  }, []);

  return (
    <div>
      <MonthlyDashboard selectedMonth={selectedMonth} onMonthChange={handleMonthChange} />
      <DashboardRevenueloss selectedMonth={selectedMonth} />
      <DashTypeofloadConsumption selectedMonth={selectedMonth} />
      <DashPF selectedMonth={selectedMonth} />
      <DashMaxdemsndkVA selectedMonth={selectedMonth} />
      <Dashenergyintencity selectedMonth={selectedMonth} />
      <DashTransformerhealth selectedMonth={selectedMonth} />
    </div>
  );
};

export default MonthlyDashboardContainer;
