// import React, { useEffect, useState } from 'react';
// import './Status.css';

// const Status = ({ selectedSection , refresh }) => {
//     const [statusData, setStatusData] = useState([]);

//     const fetchData = async () => {
//         try {
//           const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/statusdata`);
//           const data = await response.json();
//           if (Array.isArray(data)) {
//             setStatusData(data);
//           } else {
//             console.error('Status data is not an array:', data);
//           }
//         } catch (error) {
//           console.error('Error fetching status data:', error);
//         }
//       };

//       useEffect(() => {
//         fetchData();
//       }, [refresh]);

//     const filteredData = selectedSection
//         ? statusData.filter(status => status.Section === selectedSection)
//         : statusData;

//     return (
//         <div id='status-totalcontent'>
//             <table>
//                 <thead>
//                     <tr>
//                       <th>S No</th>
//                         <th>Section</th>
//                         <th>Parameter Name</th>
//                         <th>Parameter Value</th>
//                         <th>Communication Status</th>
//                         <th>Last Change Of Status</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {filteredData.map((status, index) => (
//                         <tr key={index}>
//                           <td>{index + 1}</td>
//                             <td>{status.Section}</td>
//                             <td>{status['Parameter Name']}</td>
//                             <td>{status['Parameter Value']}</td>
//                             <td>{status['Communication Status']}</td>
//                             <td>{status['Last Change Of Status']}</td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         </div>
//     );
// };

// export default Status;

// import React, { useEffect, useState } from 'react';
// import { io } from 'socket.io-client';
// import './Status.css'
// const Status = () => {
//   const [mqttData, setMqttData] = useState([]);

//   useEffect(() => {
//     const socket = io('http://localhost:3001');

//     socket.on('connect', () => {
//       console.log('Connected to WebSocket server');
//     });

//     socket.on('disconnect', () => {
//       console.log('Disconnected from WebSocket server');
//     });

//     socket.on('mqtt_data', (data) => {
//       console.log('Received data:', data);

//       setMqttData((prevData) => {
//         const existingDataIndex = prevData.findIndex(item => item.deviceEUi === data.deviceEUi);
//         if (existingDataIndex !== -1) {
//           // Update existing data if deviceEUi already exists in state
//           const updatedData = [...prevData];
//           updatedData[existingDataIndex] = data;
//           return updatedData;
//         } else {
//           // Add new data if deviceEUi is not in state
//           return [data, ...prevData];
//         }
//       });
//     });

//     return () => {
//       socket.disconnect();
//     };
//   }, []);

//   return (

//     <div id='status-totalcontent'>
//     <table>
//       <thead>
//         <tr>
//           <th>Device EUi</th>
//           <th>Division</th>
//           <th>Section</th>
//           <th>Timestamp</th>
//           <th>Vr</th>
//           <th>Vy</th>
//           <th>Vb</th>
//           <th>Vavg</th>
//           <th>Ir</th>
//           <th>Iy</th>
//           <th>Ib</th>
//           <th>Parameter Name</th>
//           <th>Parameter Value</th>
//         </tr>
//       </thead>
//       <tbody>
//         {mqttData.map(data => (
//           <tr key={data.deviceEUi}>
//             <td>{data.deviceEUi}</td>
//             <td>{data.division}</td>
//             <td>{data.section}</td>
//             <td>{data.utcTimestamp}</td>
//             <td>{data.Vr}</td>
//             <td>{data.Vy}</td>
//             <td>{data.Vb}</td>
//             <td>{data.Vavg}</td>
//             <td>{data.Ir}</td>
//             <td>{data.Iy}</td>
//             <td>{data.Ib}</td>
//             <td></td>
//             <td></td>

//           </tr>
//         ))}
//       </tbody>
//     </table>
//   </div>

//   );
// };

// export default Status;

// ---------------------

import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import "./Status.css";

const Status = ({ selectedSection }) => {
  const [mqttData, setMqttData] = useState([]);

  // useEffect(() => {
  //   const socket = io(process.env.REACT_APP_SECTIONS_URL);

  //   socket.on('connect', () => {
  //     console.log('Connected to WebSocket server');
  //   });

  //   socket.on('disconnect', () => {
  //     console.log('Disconnected from WebSocket server');
  //   });

  //   socket.on('mqtt_data', (data) => {
  //     console.log('Received data:', data);

  //     setMqttData((prevData) => {
  //       const existingDataIndex = prevData.findIndex(item => item.deviceEUi === data.deviceEUi);
  //       if (existingDataIndex !== -1) {
  //         // Update existing data if deviceEUi already exists in state
  //         const updatedData = [...prevData];
  //         updatedData[existingDataIndex] = data;
  //         return updatedData;
  //       } else {
  //         // Add new data if deviceEUi is not in state
  //         return [data, ...prevData];
  //       }
  //     });
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    // Open a WebSocket connection to the server
    const ws = new WebSocket(process.env.REACT_APP_SECTIONS_URL);

    // When a message is received from the server, update the state
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      setMqttData(data);
    };

    // Clean up the WebSocket connection when the component unmounts
    return () => ws.close();
  }, []);

  const getACInputStatus = (data) => {
    const { Vr, Vy, Vb } = data;
    return Vr !== 0 || Vy !== 0 || Vb !== 0 ? "Healthy" : "Failure";
  };

  const getSwitchStatus = (data) => {
    const { Ir, Iy, Ib } = data;
    return Ir !== 0 || Iy !== 0 || Ib !== 0 ? "On" : "Off";
  };

  const filteredData = selectedSection
    ? mqttData.filter((data) => data.section === selectedSection)
    : mqttData;

  return (
    <div id="status-totalcontent">
      <table>
        <thead>
          <tr>
            <th>Division</th>
            <th>Section</th>
            <th>Timestamp</th>
            <th>Parameter Name</th>
            <th>Parameter Value</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((data, index) => (
            <React.Fragment key={index}>
              <tr>
                <td>{data.division}</td>
                <td>{data.section}</td>
                <td>{data.utcTimestamp}</td>
                <td>AC Input</td>
                <td>{getACInputStatus(data)}</td>
              </tr>
              <tr>
                <td>{data.division}</td>
                <td>{data.section}</td>
                <td>{data.utcTimestamp}</td>
                <td>Switch</td>
                <td>{getSwitchStatus(data)}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Status;
