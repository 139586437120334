import { createTheme } from '@mui/material/styles';

const TypograpyTheme = createTheme({
  typography: {
    fontFamily: 'DM Sans, sans-serif !important', // Set your preferred font family
   
  },
});

export default TypograpyTheme;
