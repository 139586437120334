import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import './Machinefetch.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Mastermachinefetch = ({ onFormButtonClick }) => {
    const [masterMachines, setMasterMachines] = useState([]);
    const [masterMachineEditData, setMasterMachineEditData] = useState(null);
    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-Sectionnames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    const fetchMasterMachines = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/mastermachines`);
            setMasterMachines(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchMasterMachines();
    }, []);

    const handleEditClick = (masterMachine) => {
        setMasterMachineEditData(masterMachine);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setMasterMachineEditData({ ...masterMachineEditData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        console.log('Updating data:', masterMachineEditData);
        toast.success("Master Machine updated successfully");
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/mastermachines/${masterMachineEditData._id}`, masterMachineEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchMasterMachines();
            setMasterMachineEditData(null);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='mastermachinefetch-total'>

            <div id='mastermachinefetch-right'>

                <div id='mastermachinefetch-right-2'>
                    <div id='mastermachinefetch-right-21'>
                        <label>
                            <span> {Masterformsheadingsss.MachineHeading}</span>
                        </label>
                        <label>
                            <button onClick={onFormButtonClick}>{Masterformsheadingsss.Formheading}</button>
                        </label>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Parent Name</th>
                                <th>Name</th>
                                <th>Meter Make</th>
                                <th>Meter SL No</th>
                                <th>Phase</th>
                                <th>Connected Load in kVA</th>
                                <th>Voltage</th>
                                <th>Threshold Load</th>
                                <th>Type of Load</th>
                                <th>Full Load Current</th>
                                <th>Idle Load Current</th>
                                <th>Rated Current</th>
                                <th>Frequency</th>
                                <th>Output Power</th>
                                <th>Power Factor</th>
                                <th>Load Rating</th>
                                <th>Impedance</th>
                                <th>Proposed Periodicity of Maintenance</th>
                                <th>Previous Maintenance Done Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {masterMachines.map((masterMachine) => (
                                <tr key={masterMachine._id}>
                                    <td>{masterMachine.date}</td>
                                    <td>{masterMachine.parentname}</td>
                                    <td>{masterMachine.name}</td>
                                    <td>{masterMachine.metermake}</td>
                                    <td>{masterMachine.meterslno}</td>
                                    <td>{masterMachine.phase}</td>
                                    <td>{masterMachine.connectedloadinva}</td>
                                    <td>{masterMachine.voltage}</td>
                                    <td>{masterMachine.thresholdload}</td>
                                    <td>{masterMachine.typeofload}</td>
                                    <td>{masterMachine.fullloadcurrent}</td>
                                    <td>{masterMachine.idleloadcurrent}</td>
                                    <td>{masterMachine.ratedcurrent}</td>
                                    <td>{masterMachine.frequency}</td>
                                    <td>{masterMachine.outputpower}</td>
                                    <td>{masterMachine.powerfactor}</td>
                                    <td>{masterMachine.loadrating}</td>
                                    <td>{masterMachine.impedence}</td>
                                    <td>{masterMachine.proposedperiodicityofmaintenance}</td>
                                    <td>{masterMachine.previousmaintenancedonedate}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(masterMachine)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {masterMachineEditData && (
                        <Popup open={true} onClose={() => setMasterMachineEditData(null)}>
                            <div id='mastermachinefetch-popup'>
                                <h3>Edit Machine</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='mastermachinefetch-popup1'>
                                        <label>
                                            <span>Date</span>
                                            <input name="date" value={masterMachineEditData.date} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Parent Name</span>
                                            <select name="parentname" value={masterMachineEditData.parentname} onChange={handleEditChange}>
                                                <option value="">Select Parent Name</option>
                                                {parentNames.map((name) => (
                                                    <option key={name} value={name}>{name}</option>
                                                ))}
                                            </select>
                                        </label>
                                    </div>
                                    <div id='mastermachinefetch-popup1'>
                                        <label>
                                            <span>Name</span>
                                            <input type="text" name="name" value={masterMachineEditData.name} onChange={handleEditChange} />
                                        </label>

                                        <label>
                                            <span>Meter Make</span>
                                            <input type="text" name="metermake" value={masterMachineEditData.metermake} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='mastermachinefetch-popup1'>
                                        <label>
                                            <label>
                                                <span>Meter SL No</span>
                                                <input type="number" name="meterslno" value={masterMachineEditData.meterslno} onChange={handleEditChange} />
                                            </label>

                                        </label>
                                        <label>
                                            <span>Phase</span>
                                            <select name="phase" value={masterMachineEditData.phase} onChange={handleEditChange} required>
                                                <option value=''>Select Phase</option>
                                                <option value='3-Phase'>3-Phase</option>
                                                <option value='1-Phase-Vr'>1-Phase-Vr</option>
                                                <option value='1-Phase-Vy'>1-Phase-Vy</option>
                                                <option value='1-Phase-Vb'>1-Phase-Vb</option>
                                            </select>
                                        </label>

                                    </div>
                                    <div id='mastermachinefetch-popup1'>
                                        <label>
                                            <span>Connected Load in kVA</span>
                                            <input type="number" name="connectedloadinva" value={masterMachineEditData.connectedloadinva} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Voltage</span>
                                            <input type="number" name="voltage" value={masterMachineEditData.voltage} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='mastermachinefetch-popup1'>
                                        <label>
                                            <span>Threshold Load</span>
                                            <input type="number" name="thresholdload" value={masterMachineEditData.thresholdload} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Type of Load</span>
                                            <input type="number" name="typeofload" value={masterMachineEditData.typeofload} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='mastermachinefetch-popup1'>
                                        <label>
                                            <span>Full Load Current</span>
                                            <input type="number" name="fullloadcurrent" value={masterMachineEditData.fullloadcurrent} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Idle Load Current</span>
                                            <input type="number" name="idleloadcurrent" value={masterMachineEditData.idleloadcurrent} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='mastermachinefetch-popup1'>
                                        <label>
                                            <span>Rated Current</span>
                                            <input type="number" name="ratedcurrent" value={masterMachineEditData.ratedcurrent} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Frequency</span>
                                            <input type="number" name="frequency" value={masterMachineEditData.frequency} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='mastermachinefetch-popup1'>
                                        <label>
                                            <span>Output Power</span>
                                            <input type="number" name="outputpower" value={masterMachineEditData.outputpower} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Power Factor</span>
                                            <input type="number" name="powerfactor" value={masterMachineEditData.powerfactor} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='mastermachinefetch-popup1'>
                                        <label>
                                            <span>Load Rating</span>
                                            <select name="loadrating" value={masterMachineEditData.loadrating} onChange={handleEditChange} >
                                                <option value=''> Select Load Rating</option>
                                                <option value='Continious'>Continious</option>
                                                <option value='Short Time'>Short Time</option>
                                                <option value='Impulse'>Impulse</option>
                                            </select>
                                            {/* <input type="number" name="loadrating" value={masterMachineEditData.loadrating} onChange={handleEditChange} /> */}
                                        </label>
                                        <label>
                                            <span>Impedance</span>
                                            <input type="number" name="impedence" value={masterMachineEditData.impedence} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='mastermachinefetch-popup1'>
                                        <label>
                                            <span>Proposed Periodicity of Maintenance</span>
                                            <input type="text" name="proposedperiodicityofmaintenance" value={masterMachineEditData.proposedperiodicityofmaintenance} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Previous Maintenance Done Date</span>
                                            <input type="date" name="previousmaintenancedonedate" value={masterMachineEditData.previousmaintenancedonedate} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    {/* <div id='mastermachinefetch-popup-buttons'> */}
                                    <button id='mastermachinefetch-popup-update' type="submit">{Masterformsheadingsss.Updateheading}</button>
                                    {/* <button type="button" onClick={() => setMasterMachineEditData(null)}>Cancel</button> */}
                                    {/* </div> */}
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Mastermachinefetch;
