// import React, { useEffect, useState } from 'react';

// const MonthlyCalenderevents = () => {
//   const [events, setEvents] = useState([]);
//   const [filteredEvents, setFilteredEvents] = useState([]);
//   const [selectedSection, setSelectedSection] = useState('HV'); // default section
//   const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString().slice(0, 7)); // default to current month

//   useEffect(() => {
//     fetch(`${process.env.REACT_APP_API_EMS_URL}/api/monthlycalenderevents`)
//       .then(response => response.json())
//       .then(data => {
//         console.log('API response:', data);
//         setEvents(data);
//       })
//       .catch(error => console.error('Error fetching data:', error));
//   }, []);

//   useEffect(() => {
//     filterEvents();
//   }, [events, selectedSection, selectedMonth]);

//   const filterEvents = () => {
//     console.log('Filtering events:', { month: selectedMonth, section: selectedSection, eventsList: events });

//     const filtered = events
//       .filter(event => event.month === selectedMonth)
//       .flatMap(event => event.data.filter(item => item.stationPoint === selectedSection));

//     console.log('Filtered events:', filtered);
//     setFilteredEvents(filtered);
//   };

//   return (
//     <div>
//       <h1>Monthly Calendar Events</h1>
//       <select value={selectedSection} onChange={e => setSelectedSection(e.target.value)}>
//         <option value="HV">HV</option>
//         <option value="LV-1">LV-1</option>
//         <option value="Compressor-11KW">Compressor-11KW</option>
//         {/* Add other sections as needed */}
//       </select>
//       <input type='month' value={selectedMonth} onChange={e => setSelectedMonth(e.target.value)}></input>
      
//       <div>
//         {filteredEvents.map((event, index) => (
//           <div key={index}>
//             <p>Station Point: {event.stationPoint}</p>
//             <p>KWH Consumption: {event.KWH_consumption}</p>
//             <p>KVAH Consumption: {event.KVAH_consumption}</p>
//             <p>PF: {event.PF}</p>
//             <p>Utilization %: {event["Utilization_%"]}</p>
//             <p>Runtime: {event.Runtime}</p>
//             <p>Energy_Intensity: {event.Energy_Intensity}</p>
//             <p>Production: {event.Production}</p>
//             <p>Maximum_Demand_kVA: {event.Maximum_Demand_kVA}</p>
//           </div>
//         ))}
//         {filteredEvents.length === 0 && (
//           <p>No events found for the selected section and month.</p>
//         )}
//       </div>
//     </div>
//   );
// };

// export default MonthlyCalenderevents;




// import React, { useEffect, useState, useRef } from 'react';
// import axios from 'axios';
// import FullCalendar from '@fullcalendar/react';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import interactionPlugin from '@fullcalendar/interaction';

// const MonthlyCalenderevents = () => {
//   const [events, setEvents] = useState([]);
//   const [filteredEvents, setFilteredEvents] = useState([]);
//   const [selectedSection, setSelectedSection] = useState('HV');
//   const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString().slice(0, 7)); // default to current month
//   const calendarRef = useRef(null);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/calenderevents`);
//       const formattedEvents = response.data.map(event => ({
//         title: `Event on ${event.date}`,
//         start: event.date,
//         extendedProps: {
//           Section: event.stationPoint,
//           energyIntensity: event['Energy_Intensity'] || 'N/A',
//           md: event.Maximum_Demand_kVA || 'N/A',
//           pf: event.PF || 'N/A',
//           production: event.Production || 'N/A',
//           runTime: event.Runtime || 'N/A',
//           utilization: event['Utilization_%'] || 'N/A',
//           kWh: event.KWH_consumption || 'N/A'
//         }
//       }));
//       setEvents(formattedEvents);
//     } catch (error) {
//       console.error('Error fetching calendar events:', error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   useEffect(() => {
//     filterEvents();
//   }, [events, selectedSection, selectedMonth]);

//   const filterEvents = () => {
//     console.log('Filtering events:', { month: selectedMonth, section: selectedSection, eventsList: events });

//     const filtered = events.filter(event => {
//       const eventMonth = new Date(event.start).toISOString().slice(0, 7);
//       return eventMonth === selectedMonth && event.extendedProps && event.extendedProps.Section === selectedSection;
//     });

//     console.log('Filtered events:', filtered);
//     setFilteredEvents(filtered);
//   };

//   const renderEventContent = (eventInfo) => {
//     const { title } = eventInfo.event;
//     const { Section, energyIntensity, md, pf, production, runTime, utilization, kWh } = eventInfo.event.extendedProps || {};

//     const renderValue = (value) => value === null ? 'N/A' : value;

//     return (
//       <div>
//         <label>{title}</label>
//         <p>kWh: {renderValue(kWh)}</p>
//         <p>Section: {renderValue(Section)}</p>
//         <p>Energy Intensity: {renderValue(energyIntensity)}</p>
//         <p>MD: {renderValue(md)}</p>
//         <p>PF: {renderValue(pf)}</p>
//         <p>Production: {renderValue(production)}</p>
//         <p>Run Time: {renderValue(runTime)}</p>
//         <p>Utilization (%): {renderValue(utilization)}</p>
//       </div>
//     );
//   };


//     useEffect(() => {
//     fetch(`${process.env.REACT_APP_API_EMS_URL}/api/monthlycalenderevents`)
//       .then(response => response.json())
//       .then(data => {
//         console.log('API response:', data);
//         setEvents(data);
//       })
//       .catch(error => console.error('Error fetching data:', error));
//   }, []);

//   useEffect(() => {
//     filterEvents();
//   }, [events, selectedSection, selectedMonth]);


//   return (
//     <div>
//       <h1>Monthly Calendar Events</h1>
//       <select value={selectedSection} onChange={e => setSelectedSection(e.target.value)}>
//         <option value="HV">HV</option>
//         <option value="LV-1">LV-1</option>
//         <option value="LV-2">LV-2</option>
//         <option value="Compressor-11KW">Compressor-11KW</option>
//         <option value="Compressor-75KW">Compressor-75KW</option>
//         <option value="Colony">Colony</option>
//         <option value="Compressor">Compressor</option>
//         <option value="APFC-1">APFC-1</option>
//         <option value="APFC-2">APFC-2</option>
//         <option value="DG Grid Incomer -1">DG Grid Incomer -1</option>
//         <option value="DG Grid Incomer -2">DG Grid Incomer -2</option>
//         <option value="DG Incomer 1">DG Incomer 1</option>
//         <option value="DG Incomer 2">DG Incomer 2</option>
//         <option value="DG Incomer 3">DG Incomer 3</option>
//         <option value="DG Incomer 4">DG Incomer 4</option>
//         <option value="DG Incomer 5">DG Incomer 5</option>
//         <option value="DG Incomer 6">DG Incomer 6</option>
//         <option value="Solar-1">Solar-1</option>
//         <option value="Solar-2">Solar-2</option>
//         <option>WHR-1</option>
//         <option>WHR-2</option>
//         <option>ENGG store Panel</option>
//         <option>Unit-5</option>
//         <option>AR&D</option>
//         <option>Production</option>
//         <option>Lighting</option>
//         <option>spare-1</option>
//         <option>spare-2</option>
//       </select>
//       <input type='month' value={selectedMonth} onChange={e => setSelectedMonth(e.target.value)}></input>
      
//             <div>
//               <p>monthlycalenderevents API</p>
//               <h1>monthlycalenderevents</h1>
//        {filteredEvents.map((event, index) => (
//          <div key={index}>
//             <p>Station Point: {event.stationPoint}</p>
//              <p>KWH Consumption: {event.KWH_consumption}</p>
//              <p>KVAH Consumption: {event.KVAH_consumption}</p>
//              <p>PF: {event.PF}</p>
//              <p>Utilization %: {event["Utilization_%"]}</p>
//              <p>Runtime: {event.Runtime}</p>
//              <p>Energy_Intensity: {event.Energy_Intensity}</p>
//              <p>Production: {event.Production}</p>
//            <p>Maximum_Demand_kVA: {event.Maximum_Demand_kVA}</p>
//           </div>
//         ))}        {filteredEvents.length === 0 && (
//          <p>No events found for the selected section and month.</p>
//         )}
//      </div>

//       <div id='calenderevents-right-4'>
//         <p>These are from calender evnets API</p>
//         <p>Monthly Events</p>
//         <div id='calenderevents-fulcal'>
//           <FullCalendar
//             ref={calendarRef}
//             initialView="dayGridMonth"
//             headerToolbar={{
//               right: 'today',
//               left: 'title',
//             }}
//             themeSystem="Simplex"
//             plugins={[dayGridPlugin, interactionPlugin]}
//             events={filteredEvents}
//             eventContent={renderEventContent}
//             dateClick={(info) => console.log(`Date clicked: ${info.dateStr}`)}
//             datesSet={(info) => console.log(`Current view dates: ${info.startStr} to ${info.endStr}`)}
//           />
//         </div>
//       </div>
//       <div id="printable-content" style={{ display: 'none' }}>
//         <h1>Monthly Calendar Events</h1>
//         <div>
//           <span>Section: {selectedSection}</span>
//           <span>Month: {selectedMonth}</span>
//         </div>
//         <div>
//           {events.map((event, index) => (
//             <div key={index}>
//               <h2>{event.title}</h2>
//               <p>kWh: {event.extendedProps.kWh}</p>
//               <p>Section: {event.extendedProps.Section}</p>
//               <p>Energy Intensity: {event.extendedProps.energyIntensity}</p>
//               <p>MD: {event.extendedProps.md}</p>
//               <p>PF: {event.extendedProps.pf}</p>
//               <p>Production: {event.extendedProps.production}</p>
//               <p>Run Time: {event.extendedProps.runTime}</p>
//               <p>Utilization: {event.extendedProps.utilization}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MonthlyCalenderevents;




import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import './Calender.css'
const MonthlyCalenderevents = () => {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [selectedSection, setSelectedSection] = useState('HV');
  const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString().slice(0, 7)); // default to current month
  const calendarRef = useRef(null);
  const [loading, setLoading] = useState(true);
  // Fetch data from `calenderevents` API
  const fetchCalendarEvents = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/calenderevents`);
      const formattedEvents = response.data.map(event => ({
        title: `Event on ${event.date}`,
        start: event.date,
        extendedProps: {
          Section: event.stationPoint,
          energyIntensity: event['Energy_Intensity'] || 'N/A',
          md: event.Maximum_Demand_kVA || 'N/A',
          pf: event.PF || 'N/A',
          production: event.Production || 'N/A',
          runTime: event.Runtime || 'N/A',
          utilization: event['Utilization_%'] || 'N/A',
          kWh: event.KWH_consumption || 'N/A'
        }
      }));
      setEvents(formattedEvents);
    } catch (error) {
      console.error('Error fetching calendar events:', error);
    }
  };

  // Fetch data from `monthlycalenderevents` API
  const fetchMonthlyCalendarEvents = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/monthlycalenderevents`);
      const formattedEvents = response.data.map(event => ({
        title: event.title,
        start: event.start,
        extendedProps: {
          Section: event.sectionPoint,
          energyIntensity: event['Energy_Intensity'] || 'N/A',
          md: event.Maximum_Demand_kVA || 'N/A',
          pf: event.PF || 'N/A',
          production: event.Production || 'N/A',
          runTime: event.Runtime || 'N/A',
          utilization: event['Utilization_%'] || 'N/A',
          kWh: event.KWH_consumption || 'N/A'
        }
      }));
      setEvents(prevEvents => [...prevEvents, ...formattedEvents]);
    } catch (error) {
      console.error('Error fetching monthly calendar events:', error);
    }
  };

  useEffect(() => {
    fetchCalendarEvents();
    fetchMonthlyCalendarEvents();
  }, []);

  // Filter events based on selected section and month
  useEffect(() => {
    filterEvents();
  }, [events, selectedSection, selectedMonth]);

  const filterEvents = () => {
    const filtered = events.filter(event => {
      const eventMonth = new Date(event.start).toISOString().slice(0, 7);
      return eventMonth === selectedMonth && event.extendedProps && event.extendedProps.Section === selectedSection;
    });
    setFilteredEvents(filtered);
  };

  // Render content for each event in the calendar
  const renderEventContent = (eventInfo) => {
    const { title } = eventInfo.event;
    const { Section, energyIntensity, md, pf, production, runTime, utilization, kWh } = eventInfo.event.extendedProps || {};

    const renderValue = (value) => value === null ? 'N/A' : value;

    return (
      <div>
        <label>{title}</label>
        <p>kWh: {renderValue(kWh)}</p>
        <p>Section: {renderValue(Section)}</p>
        <p>Energy Intensity: {renderValue(energyIntensity)}</p>
        <p>MD: {renderValue(md)}</p>
        <p>PF: {renderValue(pf)}</p>
        <p>Production: {renderValue(production)}</p>
        <p>Run Time: {renderValue(runTime)}</p>
        <p>Utilization (%): {renderValue(utilization)}</p>
      </div>
    );
  };

  return (
    <div>
      <h1>Monthly Calendar Events</h1>
      <select value={selectedSection} onChange={e => setSelectedSection(e.target.value)}>
        <option value="HV">HV</option>
        <option value="LV-1">LV-1</option>
        <option value="LV-2">LV-2</option>
        <option value="Compressor-11KW">Compressor-11KW</option>
        <option value="Compressor-75KW">Compressor-75KW</option>
        <option value="Colony">Colony</option>
        <option value="Compressor">Compressor</option>
        <option value="APFC-1">APFC-1</option>
        <option value="APFC-2">APFC-2</option>
        <option value="DG Grid Incomer -1">DG Grid Incomer -1</option>
        <option value="DG Grid Incomer -2">DG Grid Incomer -2</option>
        <option value="DG Incomer 1">DG Incomer 1</option>
        <option value="DG Incomer 2">DG Incomer 2</option>
        <option value="DG Incomer 3">DG Incomer 3</option>
        <option value="DG Incomer 4">DG Incomer 4</option>
        <option value="DG Incomer 5">DG Incomer 5</option>
        <option value="DG Incomer 6">DG Incomer 6</option>
        <option value="Solar-1">Solar-1</option>
        <option value="Solar-2">Solar-2</option>
        <option>WHR-1</option>
        <option>WHR-2</option>
        <option>ENGG store Panel</option>
        <option>Unit-5</option>
        <option>AR&D</option>
        <option>Production</option>
        <option>Lighting</option>
        <option>spare-1</option>
        <option>spare-2</option>
      </select>
      <input type='month' value={selectedMonth} onChange={e => setSelectedMonth(e.target.value)}></input>

      <div>
      <h2>Filtered Events</h2>
      {loading ? (
        <p>Loading events...</p>
      ) : filteredEvents.length > 0 ? (
        <div>
          {filteredEvents.map((event, index) => (
            <div key={index}>
              <p>Station Point: {event.stationPoint}</p>
              <p>kWh Consumption: {event.KWH_consumption}</p>
              <p>KVAH Consumption: {event.KVAH_consumption}</p>
              <p>PF: {event.PF}</p>
              <p>Utilization %: {event.Utilization_}</p>
              <p>Runtime: {event.Runtime}</p>
              <p>Energy Intensity: {event.Energy_Intensity}</p>
              <p>Production: {event.Production}</p>
              <p>Maximum Demand kVA: {event.Maximum_Demand_kVA}</p>
            </div>
          ))}
        </div>
      ) : (
        <p>No events found for the selected section and month.</p>
      )}
    </div>

      <div id='calendar-container'>
        <h2>Full Calendar View</h2>
        <FullCalendar
          ref={calendarRef}
          initialView="dayGridMonth"
          headerToolbar={{
            right: 'today',
            left: 'title',
          }}
          themeSystem="Simplex"
          plugins={[dayGridPlugin, interactionPlugin]}
          events={filteredEvents}
          eventContent={renderEventContent}
          dateClick={(info) => console.log(`Date clicked: ${info.dateStr}`)}
          datesSet={(info) => console.log(`Current view dates: ${info.startStr} to ${info.endStr}`)}
        />
      </div>
    </div>
  );
};

export default MonthlyCalenderevents;

