import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import { LeadPFheader } from '../../../Operator/Data'; // Adjust import as needed
import { Link } from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import './APFC_Signalling_Manual_Formfetch.css';
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const APFC_Signalling_Manual_Formfetch = () => {
    const [factors, setFactors] = useState([]);
    const [factorEditData, setFactorEditData] = useState(null);

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
        // Retrieve the logged-in user from local storage
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
        }
        setUserDesignation(user.level);
    }, []);

    const fetchFactors = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/apfcsignallingmanualforms`); // Adjust endpoint
            setFactors(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchFactors();
    }, []);

    const handleEditClick = (factor) => {
        setFactorEditData(factor);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setFactorEditData({ ...factorEditData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/apfcsignallingmanualforms/${factorEditData._id}`, factorEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchFactors();
            setFactorEditData(null);
            alert("APFC Signalling Manual Form updated successfully");
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='apfc_signalling_manual_formfetch-total'>
            <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />}
            </div>
            <div id='apfc_signalling_manual_formfetch-right'>
                <div id='apfc_signalling_manual_formfetch-right-1'>
                    <div id='apfc_signalling_manual_formfetch-heading'>
                        {LeadPFheader.alertHeading}
                    </div>
                    <div id='apfc_signalling_manual_formfetch-heading1'>
                        {LeadPFheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={LeadPFheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <div id='apfc_signalling_manual_formfetch-right-2'>
                    <div id='apfc_signalling_manual_formfetch-right-21'>
                        <label>
                            <span style={{ fontWeight: 'bold' }} >APFC Signalling Manual Form</span>
                        </label>
                        {/* <label>
                            <Link to='/APFC_Signalling_Manual_Form'><button>Form</button></Link> 
                        </label> */}
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Token ID</th>
                                <th>Date and Time</th>
                                <th>Name</th>
                                <th>Running Load KW</th>
                                <th>Power Factor</th>
                                <th>No. of Switching Banks Are On</th>
                                <th>Desired Number of Switching Banks to be On</th>
                                {/* <th>Created By</th>
                                <th>Created At</th>
                                <th>Modified By</th>
                                <th>Modified At</th> */}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {factors.map((factor) => (
                                <tr key={factor._id}>
                                    <td>{factor.token_id}</td>
                                    <td>{factor.dateandtime}</td>
                                    <td>{factor.name}</td>
                                    <td>{factor.running_load_kw}</td>
                                    <td>{factor.power_factor}</td>
                                    <td>{factor.no_of_switching_banks_are_on}</td>
                                    <td>{factor.desired_number_of_switching_banks_to_be_on}</td>
                                    {/* <td>{factor.createdby}</td>
                                    <td>{factor.createdat}</td>
                                    <td>{factor.modifiedby}</td>
                                    <td>{factor.modifiedat}</td> */}
                                    <td>
                                        <button onClick={() => handleEditClick(factor)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {factorEditData && (
                        <Popup open={true} onClose={() => setFactorEditData(null)}>
                            <div id='apfc_signalling_manual_formfetch-popup'>
                                <h3>Edit APFC Signalling Manual Form</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='apfc_signalling_manual_formfetch-popup1'>
                                        <label>
                                            <span>Token ID</span>
                                            <input type="text" name="token_id" value={factorEditData.token_id} onChange={handleEditChange} disabled />
                                        </label>

                                        <label>
                                            <span>Date and Time</span>
                                            <input type="text" name="dateandtime" value={factorEditData.dateandtime} onChange={handleEditChange} disabled />
                                        </label>
                                    </div>
                                    <div id='apfc_signalling_manual_formfetch-popup1'>
                                        <label>
                                            <span>Name <span id='all-mandetorymark'>*</span></span>
                                            <input type="text" name="name" value={factorEditData.name} onChange={handleEditChange} required />
                                        </label>
                                        <label>
                                            <span>Running Load KW <span id='all-mandetorymark'>*</span></span>
                                            <input type="number" name="running_load_kw" value={factorEditData.running_load_kw} onChange={handleEditChange} required />
                                        </label>
                                    </div>
                                    <div id='apfc_signalling_manual_formfetch-popup1'>
                                        <label>
                                            <span>Power Factor <span id='all-mandetorymark'>*</span></span>
                                            <input type="number" name="power_factor" value={factorEditData.power_factor} onChange={handleEditChange} required />
                                        </label>
                                        <label>
                                            <span>No. of Switching Banks Are On <span id='all-mandetorymark'>*</span></span>
                                            <input type="number" name="no_of_switching_banks_are_on" value={factorEditData.no_of_switching_banks_are_on} onChange={handleEditChange} required />
                                        </label>
                                    </div>
                                    <div id='apfc_signalling_manual_formfetch-popup1'>
                                        <label>
                                            <span>Desired No. of Switching Banks to be On <span id='all-mandetorymark'>*</span></span>
                                            <input type="number" name="desired_number_of_switching_banks_to_be_on" value={factorEditData.desired_number_of_switching_banks_to_be_on} onChange={handleEditChange} required />
                                        </label>
                                        {/* <label>
                                            <span>Created By</span>
                                            <input type="text" name="createdby" value={factorEditData.createdby} onChange={handleEditChange} />
                                        </label> */}
                                    </div>
                                    {/* <div id='apfc_signalling_manual_formfetch-popup1'>
                                        <label>
                                            <span>Created At</span>
                                            <input type="text" name="createdat" value={factorEditData.createdat} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Modified By</span>
                                            <input type="text" name="modifiedby" value={factorEditData.modifiedby} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='apfc_signalling_manual_formfetch-popup1'>
                                        <label>
                                            <span>Modified At</span>
                                            <input type="text" name="modifiedat" value={factorEditData.modifiedat} onChange={handleEditChange} />
                                        </label>
                                    </div> */}
                                    <button id='apfc_signalling_manual_formfetch-popup-update' type="submit">Update</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default APFC_Signalling_Manual_Formfetch;
