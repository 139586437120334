import React, { useState } from 'react';

const Areaform = () => {
  const [formData, setFormData] = useState({
    locationnameid:'',
    locationname: '',
    modifiedby: '',
    modifiedat: '',
    createdby: '',
    createdat: '',
    status:'active',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/areafrom`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (response.ok) {
        console.log('Form submitted successfully:', data);
        setFormData({
          locationnameid:'',
          locationname: '',
            modifiedby: '',
            modifiedat: '',
            createdby: '',
            createdat: '',
            status:'active',
        });
      } else {
        // Handle error
        console.error('Form submission failed:', data);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
        <label>
        <span>areanameid</span>
        <input name="locationnameid" value={formData.areanameid} onChange={handleChange} />
      </label>
      <label>
        <span>LOcation Name</span>
        <input name="locationname" value={formData.areaname} onChange={handleChange} />
      </label>
      <label>
        <span>Modified By</span>
        <input name="modifiedby" value={formData.modifiedby} onChange={handleChange} />
      </label>
      <label>
        <span>Modified At</span>
        <input name="modifiedat" value={formData.modifiedat} onChange={handleChange} />
      </label>
      <label>
        <span>Created By</span>
        <input name="createdby" value={formData.createdby} onChange={handleChange} />
      </label>
      <label>
        <span>Created At</span>
        <input name="createdat" value={formData.createdat} onChange={handleChange} />
      </label>
      <button type="submit">Submit</button>
    </form>
  );
};

export default Areaform;
