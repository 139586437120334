// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const Monthly_PF = () => {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     const fetchMonthlyPFData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/monthpowerfactor`);
//         setData(response.data);
//       } catch (error) {
//         console.error('Error fetching monthly PF data:', error);
//       }
//     };

//     fetchMonthlyPFData();
//   }, []);

//   if (data.length === 0) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       {data.map((item, index) => (
//         <div key={index}>
//           <p>Month: {item.month}</p>
//           <p>Maximum Demand (kVA): {item.Maximum_Demand_kVA}</p>
//           <p>PF: {item.PF}</p>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Monthly_PF;



import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';
import Operatorsidebar from '../Operator/Sidebar/Sidebar';
import { monthpf_mdheader } from '../Operator/Data';
import './Monthly_PF.css';
const Monthly_PF = () => {
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const pfChartRef = useRef(null);
  const mdChartRef = useRef(null);

  useEffect(() => {
    const getLastThreeMonths = () => {
      const now = new Date();
      const toMonth = now.toISOString().slice(0, 7); // Format: YYYY-MM
      now.setMonth(now.getMonth() - 2);
      const fromMonth = now.toISOString().slice(0, 7); // Format: YYYY-MM
      return { fromMonth, toMonth };
    };

    const { fromMonth, toMonth } = getLastThreeMonths();
    setFromDate(fromMonth);
    setToDate(toMonth);
  }, []);

  useEffect(() => {
    const fetchMonthlyPFData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/monthpowerfactor`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching monthly PF data:', error);
      }
    };

    fetchMonthlyPFData();
  }, []);

  useEffect(() => {
    const filterDataByDateRange = () => {
      const from = new Date(fromDate);
      const to = new Date(toDate);
      return data.filter(item => {
        const itemDate = new Date(item.month + '-01'); // Assuming 'month' is in YYYY-MM format
        return itemDate >= from && itemDate <= to;
      });
    };

    if (data.length > 0) {
      const filteredData = filterDataByDateRange();

      const pfChart = echarts.init(pfChartRef.current);
      const pfOption = {
        title: {
          text: 'Power Factor Monthly Data'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '1%',
          right: '1%',
          bottom: '1%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: filteredData.map(item => item.month),
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            min: -1,
            max: 1,
            interval: 0.2,
            axisLabel: {
              formatter: value => value.toFixed(1)
            }
          }
        ],
        series: [
          {
            name: 'PF',
            type: 'bar',
            barWidth: '10%',
            data: filteredData.map(item => item.PF)
          }
        ]
      };

      pfChart.setOption(pfOption);

      const mdChart = echarts.init(mdChartRef.current);
      const mdOption = {
        title: {
          text: 'MAX Demand Monthly Data'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        // legend: {
        //   data: ['Maximum Demand (kVA)']
        // },
        toolbox: {
          // feature: {
          //   saveAsImage: {}
          // }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: filteredData.map(item => item.month)
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: 'Maximum Demand (kVA)',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: filteredData.map(item => item.Maximum_Demand_kVA)
          }
        ]
      };

      mdChart.setOption(mdOption);

      return () => {
        pfChart.dispose();
        mdChart.dispose();
      };
    }
  }, [data, fromDate, toDate]);

  if (data.length === 0) {
    return <div>Loading...</div>;
  }

  return (


    <div id='monthpdmd-total'>
    <div>
      <Operatorsidebar />
    </div>
    <div id='monthpdmd-right'>
      <div id='monthpdmd-right-1'>
        <div id='monthpdmd-heading'>
          {monthpf_mdheader.alertHeading}
        </div>
        <div id='monthpdmd-heading1'>
          {monthpf_mdheader.alertActions.map((action, index) => (
            <button key={index} aria-label={action.label}>
              {action.icon}
            </button>
          ))}
          <img src={monthpf_mdheader.logoSrc} alt='logo' />
        </div>
      </div>

      <div id='monthpdmd-right-2'>

      <div id='monthpdmd-right-21'>
      <label>
          <span>From Date</span>
          <input 
            type='month' 
            value={fromDate} 
            onChange={(e) => setFromDate(e.target.value)} 
          />
        </label>
        <label>
          <span>To Date</span>
          <input 
            type='month' 
            value={toDate} 
            onChange={(e) => setToDate(e.target.value)} 
          />
        </label>
                  </div>
        <div >
          <div id='monthpfgraph'>
            <div ref={pfChartRef} id='monthpf-graph-div' />
          </div>
          <div id='monthmaxdemandgraph'>
            <div ref={mdChartRef} id='monthmaxdemand-graph-div'/>
          </div>
        </div>
      </div>
    </div>
  </div>

    // <div>
    //   <div>
    //     <label>
    //       <span>From Date</span>
    //       <input 
    //         type='month' 
    //         value={fromDate} 
    //         onChange={(e) => setFromDate(e.target.value)} 
    //       />
    //     </label>
    //     <label>
    //       <span>To Date</span>
    //       <input 
    //         type='month' 
    //         value={toDate} 
    //         onChange={(e) => setToDate(e.target.value)} 
    //       />
    //     </label>
    //   </div>
    //   <div ref={pfChartRef} style={{ width: '100%', height: '350px' }}></div>
    //   <div ref={mdChartRef} style={{ width: '100%', height: '350px', marginTop: '20px' }}></div>
    // </div>
  );
};

export default Monthly_PF;



