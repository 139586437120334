import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import { Voltagevoltagefluctuationsheader } from '../../../Operator/Data';
import { Link } from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import './Stabilizerformfetch.css'
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';
const Stabilizerformfetch = () => {
    const [stabilizers, setStabilizers] = useState([]);
    const [stabilizerEditData, setStabilizerEditData] = useState(null);

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
        // Retrieve the logged-in user from local storage
        const user = JSON.parse(localStorage.getItem('user'));
          if (user && user.username) {
            setLoggedInUser(user);
          }
        setUserDesignation(user.level);
    }, []);

    const fetchStabilizers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/stabilizers`);
            setStabilizers(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchStabilizers();
    }, []);

    const handleEditClick = (stabilizer) => {
        setStabilizerEditData(stabilizer);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setStabilizerEditData({ ...stabilizerEditData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        console.log('Updating data:', stabilizerEditData);
        alert("Stabilizer updated successfully");
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/stabilizers/${stabilizerEditData._id}`, stabilizerEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchStabilizers();
            setStabilizerEditData(null);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };


    return (


        <div id='stabilizerformfetch-total'>
            <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />}
            </div>
            <div id='stabilizerformfetch-right'>
                <div id='stabilizerformfetch-right-1'>
                    <div id='stabilizerformfetch-heading'>
                        {Voltagevoltagefluctuationsheader.alertHeading}
                    </div>
                    <div id='stabilizerformfetch-heading1'>
                        {Voltagevoltagefluctuationsheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Voltagevoltagefluctuationsheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <div id='stabilizerformfetch-right-2'>
                    <div id='stabilizerformfetch-right-21'>
                        <label >
                            <span style={{ fontWeight: 'bold' }}>Current Stabilizer</span>
                        </label>
                        {/* <label>
                            <Link to='/Stabilizerform'><button>Form</button></Link>
                        </label> */}
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Token ID</th>
                                <th>Date and Time</th>
                                <th>Stabilizer Name</th>
                                <th>Stabilizer Value</th>
                                {/* <th>Created By</th>
                        <th>Created At</th>
                        <th>Modified By</th>
                        <th>Modified At</th> */}

                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stabilizers.map((stabilizer) => (
                                <tr key={stabilizer._id}>
                                    <td>{stabilizer.token_id}</td>
                                    <td>{stabilizer.dateandtime}</td>
                                    <td>{stabilizer.stabilizername}</td>
                                    <td>{stabilizer.stabilizer_value}</td>
                                    {/* <td>{stabilizer.createdby}</td>
                            <td>{stabilizer.createdat}</td>
                            <td>{stabilizer.modifiedby}</td>
                            <td>{stabilizer.modifiedat}</td>
                            <td>{stabilizer.spare1}</td>
                            <td>{stabilizer.spare2}</td> */}
                                    <td>
                                        <button onClick={() => handleEditClick(stabilizer)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {stabilizerEditData && (
                        <Popup open={true} onClose={() => setStabilizerEditData(null)}>
                            <div id='stabilizerformfetch-popup'>
                                <h3>Edit Current Stabilizer</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='stabilizerformfetch-popup1'>
                                        <label>
                                            <span>Token ID</span>
                                            <input type="text" name="token_id" value={stabilizerEditData.token_id} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Date and Time</span>
                                            <input type="text" name="dateandtime" value={stabilizerEditData.dateandtime} onChange={handleEditChange} disabled />
                                        </label>

                                    </div>
                                    <div id='stabilizerformfetch-popup1'>
                                        <label>
                                            <span>Stabilizer Name <span id='all-mandetorymark'>*</span></span>
                                            <input type="text" name="stabilizername" value={stabilizerEditData.stabilizername} onChange={handleEditChange} required />
                                        </label>
                                        <label>
                                            <span>Stabilizer Value <span id='all-mandetorymark'>*</span></span>
                                            <input type="number" name="stabilizer_value" value={stabilizerEditData.stabilizer_value} onChange={handleEditChange} required />
                                        </label>

                                    </div>
                                    <div id='stabilizerformfetch-popup1'>

                                        {/* <label>
                                    <span>Created By</span>
                                    <input type="text" name="createdby" value={stabilizerEditData.createdby} onChange={handleEditChange} disabled/>
                                </label>
                                <label>
                                    <span>Created At</span>
                                    <input type="text" name="createdat" value={stabilizerEditData.createdat} onChange={handleEditChange} disabled/>
                                </label> */}
                                    </div>
                                    <div id='stabilizerformfetch-popup1'>

                                        {/* <label>
                                    <span>Modified By</span>
                                    <input type="text" name="modifiedby" value={stabilizerEditData.modifiedby} onChange={handleEditChange} disabled/>
                                </label>
                                <label>
                                    <span>Modified At</span>
                                    <input type="text" name="modifiedat" value={stabilizerEditData.modifiedat} onChange={handleEditChange} disabled />
                                </label> */}
                                    </div>
                                    <button id='stabilizerformfetch-popup-update' type="submit">Update</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>








        // <div>
        //     <table>
        //         <thead>
        //             <tr>
        //                 <th>Date</th>
        //                 <th>Stabilizer Name</th>
        //                 <th>Stabilizer Value</th>
        //                 <th>Token ID</th>
        //                 <th>Date and Time</th>
        //                 <th>Created By</th>
        //                 <th>Created At</th>
        //                 <th>Modified By</th>
        //                 <th>Modified At</th>
        //                 <th>Spare 1</th>
        //                 <th>Spare 2</th>
        //                 <th>Actions</th>
        //             </tr>
        //         </thead>
        //         <tbody>
        //             {stabilizers.map((stabilizer) => (
        //                 <tr key={stabilizer._id}>
        //                     <td>{stabilizer.date}</td>
        //                     <td>{stabilizer.stabilizername}</td>
        //                     <td>{stabilizer.stabilizer_value}</td>
        //                     <td>{stabilizer.token_id}</td>
        //                     <td>{stabilizer.dateandtime}</td>
        //                     <td>{stabilizer.createdby}</td>
        //                     <td>{stabilizer.createdat}</td>
        //                     <td>{stabilizer.modifiedby}</td>
        //                     <td>{stabilizer.modifiedat}</td>
        //                     <td>{stabilizer.spare1}</td>
        //                     <td>{stabilizer.spare2}</td>
        //                     <td>
        //                         <button onClick={() => handleEditClick(stabilizer)}>Edit</button>
        //                     </td>
        //                 </tr>
        //             ))}
        //         </tbody>
        //     </table>
        //     {stabilizerEditData && (
        //         <Popup open={true} onClose={() => setStabilizerEditData(null)}>
        //             <div id='stabilizerformfetch-popup'>
        //                 <h3>Edit Stabilizer</h3>
        //                 <form onSubmit={handleEditSubmit}>
        //                     <div id='stabilizerformfetch-popup1'>
        //                         <label>
        //                             <span>Date</span>
        //                             <input name="date" value={stabilizerEditData.date} onChange={handleEditChange} disabled />
        //                         </label>
        //                         <label>
        //                             <span>Stabilizer Name</span>
        //                             <input type="text" name="stabilizername" value={stabilizerEditData.stabilizername} onChange={handleEditChange} />
        //                         </label>
        //                     </div>
        //                     <div id='stabilizerformfetch-popup2'>
        //                         <label>
        //                             <span>Stabilizer Value</span>
        //                             <input type="number" name="stabilizer_value" value={stabilizerEditData.stabilizer_value} onChange={handleEditChange} />
        //                         </label>
        //                         <label>
        //                             <span>Token ID</span>
        //                             <input type="text" name="token_id" value={stabilizerEditData.token_id} onChange={handleEditChange} />
        //                         </label>
        //                     </div>
        //                     <div id='stabilizerformfetch-popup2'>
        //                         <label>
        //                             <span>Date and Time</span>
        //                             <input type="text" name="dateandtime" value={stabilizerEditData.dateandtime} onChange={handleEditChange} />
        //                         </label>
        //                         <label>
        //                             <span>Created By</span>
        //                             <input type="text" name="createdby" value={stabilizerEditData.createdby} onChange={handleEditChange} />
        //                         </label>
        //                     </div>
        //                     <div id='stabilizerformfetch-popup2'>
        //                         <label>
        //                             <span>Created At</span>
        //                             <input type="text" name="createdat" value={stabilizerEditData.createdat} onChange={handleEditChange} />
        //                         </label>
        //                         <label>
        //                             <span>Modified By</span>
        //                             <input type="text" name="modifiedby" value={stabilizerEditData.modifiedby} onChange={handleEditChange} />
        //                         </label>
        //                     </div>
        //                     <div id='stabilizerformfetch-popup2'>
        //                         <label>
        //                             <span>Modified At</span>
        //                             <input type="text" name="modifiedat" value={stabilizerEditData.modifiedat} onChange={handleEditChange} />
        //                         </label>
        //                         <label>
        //                             <span>Spare 1</span>
        //                             <input type="text" name="spare1" value={stabilizerEditData.spare1} onChange={handleEditChange} />
        //                         </label>
        //                     </div>
        //                     <div id='stabilizerformfetch-popup2'>
        //                         <label>
        //                             <span>Spare 2</span>
        //                             <input type="text" name="spare2" value={stabilizerEditData.spare2} onChange={handleEditChange} />
        //                         </label>
        //                     </div>
        //                     <button id='stabilizerformfetch-popup-update' type="submit">Update</button>
        //                 </form>
        //             </div>
        //         </Popup>
        //     )}
        // </div>
    );
};

export default Stabilizerformfetch;
