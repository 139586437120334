export const whatSholdYouDo = [
  "alert occurrence time",
  "Alert duration",
  "location of alert",
  "Filter the type of alert",
  "Diagnose the alert to solve the problem.",
];

export const timeStringToDecimalHours = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  return hours + minutes / 60;
};

export const hoursToTimeString = (hours) => {
  const totalMinutes = Math.round(hours * 60);
  const formattedHours = String(Math.floor(totalMinutes / 60)).padStart(2, "0");
  const formattedMinutes = String(totalMinutes % 60).padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}`;
};

export const decimalHoursToTimeString = (decimalHours) => {
  const totalMinutes = Math.round(decimalHours * 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const seconds = Math.round(((decimalHours % 1) * 3600) % 60);
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}:${String(seconds).padStart(2, "0")}`;
};

export const todImpPoints = [
  "TOD1 & TOD5: Incentives - 00:00:00 to 06:00:00 & 22:00:00 to 24:00:00",
  "TOD2 & TOD4: Penalty - 06:00:00 to 10:00:00 & 18:00:00 to 22:00:00",
  "TOD3: Common - 10:00:00 to 18:00:00",
];

export const getRunHoursOptions = (yLabel = "Hours") => {
  return {
    responsive: true,
    interaction: {
      mode: "index", // Highlight both bars on the same date
      intersect: false,
    },
    hover: {
      mode: "index",
      intersect: false,
      onHover: function (event, chartElement) {
        event.native.target.style.cursor = chartElement[0]
          ? "pointer"
          : "default";
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        title: {
          display: true,
          text: "Date",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
      },
      y: {
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        beginAtZero: true,

        ticks: {
          callback: (value) => hoursToTimeString(value),
          beginAtZero: true,
        },
        title: {
          display: true,
          text: yLabel,
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
      },
    },
    animation: {
      // duration: 50,
      // easing: undefined,
    },
    plugins: {
      tooltip: {
        // animation: false,
        // duration: 0,

        mode: "index", // Highlight both bars on the same date
        intersect: false,
        // animation: false,
        // duration: 0,
        backgroundColor: "white", // Set the tooltip background color to white
        titleColor: "black", // Set the title text color to black for contrast
        bodyColor: "black",
        borderColor: "black",
        borderWidth: 1,
        yAlign: 'bottom', // Ensures tooltip is positioned above the bar
        caretSize: 5, // Adjust size of the tooltip caret
        caretPadding: 0,
        titleFont: {
          size: 15, // Increase title font size
        },
        bodyFont: {
          size: 14, // Increase body font size
        },
        padding: 12, // Increase padding inside the tooltip
        boxPadding: 10, // Padding around colored boxes
        boxWidth: 30, // Width of color indicator boxes
        boxHeight: 30, // Height of color indicator boxes
        callbacks: {
          label: (context) => {
            const datasetLabel = context.dataset.label;
            const value = context.raw;

            const timeString = decimalHoursToTimeString(value);

            if (datasetLabel === "Production") {
              return `Production Count: ${value}`;
            }

            return `${datasetLabel}: ${timeString}`;
          },
        },
      },
    },
  };
};

export const getData = (label = "", labels = [], data = []) => {
  return {
    labels,
    datasets: [
      {
        label,
        data,
        backgroundColor: labels.map((_date) => "#6174D7"),
        borderWidth: 1,
        maxBarThickness: 100,
      },
    ],
  };
};

export const secondsToTime = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600); // Get the hours part
  const minutes = Math.floor((totalSeconds % 3600) / 60); // Get the minutes part
  const seconds = totalSeconds % 60; // Get the seconds part

  // Pad the hours, minutes, and seconds with leading zeros if necessary
  const pad = (num) => String(num).padStart(2, "0");

  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};
