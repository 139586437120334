
import React, { useEffect, useState } from 'react';
import socketIOClient from 'socket.io-client';
import Operatorsidebar from '../Sidebar/Sidebar';
import { TODview } from '../Data';
import './TODview.css';
import Stationpoints from '../../Commonfns/Stationpoints';

const TodDataComponent = () => {
    const [todData, setTodData] = useState({});
    const [section, setSection] = useState('HV');

    // useEffect(() => {
    //     const socket = socketIOClient(process.env.REACT_APP_TODVIEW_URL);

    //     socket.on('mqtt_data', (data) => {
    //         setTodData(data);
    //     });

    //     return () => socket.disconnect();
    // }, []);


    useEffect(() => {
        // Open a WebSocket connection to the server
        const ws = new WebSocket(process.env.REACT_APP_TODVIEW_URL);
    
        
        ws.onmessage = (event) => {      
         
         const data = JSON.parse(event.data);

         setTodData(data);

        }
    
        
    
        // Clean up the WebSocket connection when the component unmounts
        return () => ws.close();
      }, []);




    const handleStationPointChange = (selectedStationPoint) => {
        setSection(selectedStationPoint);
    };

    // Function to filter TOD data based on selected section
    const filteredTodData = Object.entries(todData)
        .filter(([dataSection]) => section === '' || dataSection === section) // Show all if selectedSection is empty
        .map(([dataSection, tods]) => ({
            dataSection,
            tods: Object.entries(tods).map(([tod, data]) => ({ tod, ...data })),
        }));

    return (
        <div id='todview-total'>
            {/* <div>
                <Operatorsidebar />
            </div> */}
            <div id='todview-right'>
                {/* <div id='todview-right-1'>
                    <div id='todview-heading'>
                        {TODview.alertHeading}
                    </div>
                    <div id='todview-heading1'>
                        {TODview.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={TODview.logoSrc} alt='logo' />
                    </div>
                </div> */}

                <div id='todview-right-2'>
                    <div id='todview-right-21'>
                        <label>
                            {/* <span>Select Section:</span> */}
                            <Stationpoints onStationPointChange={handleStationPointChange} defaultStationPoint='HV' />
                        </label>
                    </div>
                    <div id='todview-right-3'>
                        <table>
                            <thead>
                                <tr>
                                    <th>TOD Timings</th>
                                    <th>Types</th>
                                    <th>Section</th>
                                    <th>KVAh</th>
                                    <th>KWh</th>
                                    <th>KVArh</th>
                                    <th>MD</th>
                                    <th>PF</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredTodData.map(({ dataSection, tods }) => (
                                    tods.map(({ tod, Type, KVAh, KWh, KVArh, MD, PF }) => (
                                        <tr key={`${dataSection}-${tod}`}>
                                            <td>{tod}</td>
                                            <td>{Type}</td>
                                            <td>{dataSection}</td>
                                            <td>{KVAh.toFixed(2)}</td>
                                            <td>{KWh.toFixed(2)}</td>
                                            <td>{KVArh.toFixed(2)}</td>
                                            <td>{MD.toFixed(2)}</td>
                                            <td>{PF.toFixed(2)}</td>
                                        </tr>
                                    ))
                                ))}
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TodDataComponent;
