import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import "./RevenueLoss.css";
import { getRevenueLossesTrends } from "../../../api/services/trends/trendsService";
import chatIcon from "../../../Images/Black.svg";
import moment from "moment/moment";
import { useForm } from "react-hook-form";
import manIcon from "../../../Images/man.svg";
import lightIcon from "../../../Images/light.svg";
import { Link } from "react-router-dom";
import { errorToast } from "../../../utils/toast";
import { useTranslation } from "react-i18next";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { DatePicker, Popover } from "antd";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const RevenueLossTrend = () => {
  const { t } = useTranslation();

  const monthInputRef = useRef(null);

  const { register, setValue, watch, getValues } = useForm({
    defaultValues: {
      month: dayjs(),
    },
  });

  const [chartData, setChartData] = useState([]);

  const { month } = watch();

  useEffect(() => {
    ChartJS.unregister(ChartDataLabels);
    getRevenueLossesTrends(month.format("YYYY-MM"))
      .then((res) => {
        setChartData(res);
      })
      .catch((error) => {
        errorToast(error.message || "failed to fetch Alert Count");
      });
  }, [month]);

  const disableFutureMonths = (current) => {
    return current && current > dayjs().endOf("month");
  };

  const onChange = (value) => {
    if (value) {
      setValue("month", value);
    }
  };

  const data = {
    labels: chartData.map((label) => label?.type),
    datasets: [
      {
        label: "Revenue Losses",
        data: chartData.map((data) => data?.details?.energyLoss),
        backgroundColor: [
          "#FF994D",
          "#4285F4",
          "#FBBC04",
          "#34A853",
          "#FF6D01",
          "#46BDC6",
          "#7BAAF7",
          "#F07B72",
          "#F07B72",
          "#FCD04F",
          "#71C287",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    animation: {
      // duration: 50,
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          padding: 33,
          textAlign: "start",
          useBorderRadius: true,
          borderRadius: 5,
        },
      },
      tooltip: {
        backgroundColor: "white", // Set the tooltip background color to white
        titleColor: "black", // Set the title text color to black for contrast
        bodyColor: "black",
        borderColor: "black",
        borderWidth: 1,
        yAlign: "bottom", // Ensures tooltip is positioned above the bar
        caretSize: 5, // Adjust size of the tooltip caret
        caretPadding: 0,

        titleFont: {
          size: 15, // Increase title font size
        },
        bodyFont: {
          size: 14, // Increase body font size
        },
        padding: 12, // Increase padding inside the tooltip
        boxPadding: 10, // Padding around colored boxes
        boxWidth: 30, // Width of color indicator boxes
        boxHeight: 30, // Height of color indicator boxes
        backgroundColor: "white", // Set the tooltip background color to white
        titleColor: "black", // Set the title text color to black for contrast
        bodyColor: "black",
        borderColor: "black",
        borderWidth: 1,
        callbacks: {
          label: (context) => {
            const value = context.raw;
            const typeObj = chartData.find(
              (data) => data.details.energyLoss == value
            );
            const energyLoss = typeObj?.details?.energyLoss;
            const revenueLoss = typeObj?.details?.revenueLoss;

            return [
              `Energy Loss:  ${energyLoss} (kWh)`,
              `Revenue Loss: ${revenueLoss} (₹)`,
            ];
          },
        },
      },
    },
  };

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          border: "1px solid #DFE6FA",
        }}
        className="filter-container"
      >
        <Box>
          <div>
            <label>{t("common.selectMonthColumn")}</label>
          </div>

          <div>
            <DatePicker
              {...register("month")}
              style={{ width: "200px" }}
              defaultValue={getValues("month")}
              size={"medium"}
              picker={"month"}
              disabledDate={disableFutureMonths} // Restricts future months
              onChange={onChange}
            />
          </div>
        </Box>
      </Paper>

      <Paper
        elevation={0}
        sx={{
          border: "1px solid #DFE6FA",
        }}
        className="chart-container"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {t("revenueLossTrendComponent.revenueLoss")}
          </Typography>

          <Popover
            placement="leftBottom"
            content={
              <Box maxWidth={600}>
                <Box className="static-content-container">
                  <Box className="imp-container">
                    <img src={chatIcon} width={"40px"} height={"40px"} />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#447F44", fontWeight: "bold" }}
                      >
                        {t("common.whyIsThisImportanQuestion")}
                      </Typography>
                      <Typography variant="body1">
                        {t(
                          "revenueLossTrendComponent.whyIsThisImportantAnswer"
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className="static-content-container">
                  <Box
                    className="imp-container"
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <img
                        style={{ alignSelf: "start" }}
                        src={manIcon}
                        width={"50px"}
                        height={"40px"}
                      />

                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="body1"
                          sx={{ color: "#447F44", fontWeight: "bold" }}
                        >
                          {t("common.WhatShouldYouDoQuestion")}
                        </Typography>
                        <Typography variant="body1">
                          {t("revenueLossTrendComponent.whatShouldYouDoAnswer")}
                        </Typography>
                      </Box>
                    </Box>

                    <Box>
                      <Link to={"/Alldash?section=MonthlyDashboard-1"}>
                        <Button
                          sx={{
                            textTransform: "none",
                            backgroundColor: "#447F44",
                            color: "white",
                            width: "150px",
                            justifySelf: "start",
                            alignSelf: "start",
                            borderRadius: "4px",
                          }}
                        >
                          {t("revenueLossTrendComponent.revenueLoss")}
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Box>

                <Box className="static-content-container">
                  <Box className="imp-container">
                    <img src={lightIcon} width={"40px"} height={"40px"} />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#447F44", fontWeight: "bold" }}
                      >
                        {t("common.howWillYouImproveQuestion")}
                      </Typography>
                      <Typography variant="body1">
                        {t("revenueLossTrendComponent.howWillYouImproveAnswer")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            }
            // title="Title"
          >
            <Box
              type="button"
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: 0.1,
              }}
              // onClick={() => setOpen()}
            >
              <HelpOutlineOutlinedIcon fontSize="medium" color="disabled" />
              <Typography
                fontSize={15}
                style={{ display: "inline", color: "gray" }}
              >
                How to use?
              </Typography>
            </Box>
          </Popover>
        </Box>

        <Pie className="alerts-chart" data={data} options={options} />

        <Typography
          sx={{ textAlign: "end" }}
          dangerouslySetInnerHTML={{
            __html: t("revenueLossTrendComponent.clickBelowLegendToToggleData"),
          }}
          variant="body1"
        />
      </Paper>
    </div>
  );
};

export default RevenueLossTrend;
