
import React, { useState , useEffect } from 'react';
import './Impedence_Test_Form.css';
import axios from 'axios';
import { Phaseimbalanceheader } from '../../../Operator/Data';
import { Link , useParams , useNavigate } from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import { getCurrentDateTime } from '../../../Diagnose/dateUtils';
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const Impedance_Test_Form = () => {
    const { token_id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        vdrop: '',
        irated: '',
        impedance: '',
        createdBy: '',
        createdAt: '',
        modifiedBy: '',
        modifiedAt: '',
        token_id:token_id,
    });

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
      // Retrieve the logged-in user from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        setLoggedInUser(user);
      }
      setUserDesignation(user.level);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = {
                ...formData,
                dateandtime: getCurrentDateTime(),
                vdrop: Number(formData.vdrop),
                irated: Number(formData.irated),
                impedance: Number(formData.impedance),
            };

            const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/impedencetestforms`, dataToSend);

            console.log('Data posted successfully:', response.data);

            alert('Impedance Test Form data added successfully');

            // Reset form fields after successful submission
            setFormData({
                name: '',
                vdrop: '',
                irated: '',
                impedance: '',
                createdBy: '',
                createdAt: '',
                modifiedBy: '',
                modifiedAt: '',
                token_id:token_id,
            });
            navigate('/Impedence_Test_Formfetch');

        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData(prevState => {
            const updatedFormData = { ...prevState, [name]: value };

            if (name === 'vdrop' || name === 'irated') {
                const vdrop = parseFloat(updatedFormData.vdrop) || 0;
                const irated = parseFloat(updatedFormData.irated) || 0;
                const impedance = irated !== 0 ? (vdrop / irated).toFixed(2) : '';

                updatedFormData.impedance = impedance;
            }

            return updatedFormData;
        });
    };

    return (
        <div id='impedance_test_form-total'>
              <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />} 
            </div>
            <div id='impedance_test_form-right'>
                <div id='impedance_test_form-right-1'>
                    <div id='impedance_test_form-heading'>
                        {Phaseimbalanceheader.alertHeading}
                    </div>
                    <div id='impedance_test_form-heading1'>
                        {Phaseimbalanceheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Phaseimbalanceheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <h3 id='impedance_test_form-h3'>{Phaseimbalanceheader.subalertHeading}</h3>
                <div id='impedance_test_form-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='impedance_test_form-divtop'>
                            <label>
                                <span>Impedance Test Monitor</span>
                            </label>
                            {/* <label>
                                <Link to='/Impedence_Test_Formfetch'><button type="button">View Data</button></Link>
                            </label> */}
                        </div>

                        <div id='impedance_test_form-row'>
                            <label>
                                <span>Name <span id='all-mandetorymark'>*</span></span>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label>
                                <span>Voltage Drop (V)</span>
                                <input
                                    type="number"
                                    name="vdrop"
                                    value={formData.vdrop}
                                    onChange={handleChange}
                              
                                />
                            </label>
                        </div>

                        <div id='impedance_test_form-row'>
                            <label>
                                <span>Rated Current (A) </span>
                                <input
                                    type="number"
                                    name="irated"
                                    value={formData.irated}
                                    onChange={handleChange}
                                 
                                />
                            </label>
                            <label>
                                <span>Impedance (Ω) <span id='all-mandetorymark'>*</span></span>
                                <input
                                    type="number"
                                    name="impedance"
                                    value={formData.impedance}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                        </div>

                        <label>
                            <button id='impedance_test_form-submit' type="submit">Submit</button>
                        </label>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Impedance_Test_Form;
