import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import './PFcorrectorsfetch.css'; 
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const PFcorrectorsfetch = ({ onFormButtonClick }) => {
    const [PFcorrectors, setPFcorrectors] = useState([]);
    const [PFcorrectorEditData, setPFcorrectorEditData] = useState(null);
    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-transformerlvNames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    const fetchPFcorrectors = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/pfcorrectors`);
            setPFcorrectors(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchPFcorrectors();
    }, []);

    const handleEditClick = (PFcorrector) => {
        setPFcorrectorEditData(PFcorrector);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setPFcorrectorEditData({ ...PFcorrectorEditData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        console.log('Updating data:', PFcorrectorEditData);
        toast.success("PF Corrector updated successfully");
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/pfcorrectors/${PFcorrectorEditData._id}`, PFcorrectorEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchPFcorrectors();
            setPFcorrectorEditData(null);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='pfcorrectorsfetch-total'>
            <div id='pfcorrectorsfetch-right'>
                <div id='pfcorrectorsfetch-right-2'>
                    <div id='pfcorrectorsfetch-right-21'>
                    <label>
                            <span> {Masterformsheadingsss.PFCorrectorHeading}</span>

                            </label>
                        <label>
                        <button onClick={onFormButtonClick}>{Masterformsheadingsss.Formheading}</button>

                        </label>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Parent Name</th>
                                <th>Meter Make</th>
                                <th>Meter SL No</th>
                                <th>Phase</th>
                                <th>Rated Power (kVA)</th>
                                <th>Operating Voltage</th>
                                <th>Auto Select</th>
                                <th>Capacitor Type</th>
                                <th>Number of Stages</th>
                                <th>Number of Cells in Each Stage</th>
                                <th>Each Cell Capacity</th>
                                <th>Present Tested Value of Each Cell</th>
                                <th>PFC Efficiency</th>
                                <th>Overcurrent Protection Available</th>
                                <th>Overvoltage Protection Available</th>
                                <th>Proposed Periodicity of Maintenance</th>
                                <th>Previous Maintenance Done Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {PFcorrectors.map((PFcorrector) => (
                                <tr key={PFcorrector._id}>
                                    <td>{PFcorrector.date}</td>
                                    <td>{PFcorrector.name}</td>
                                    <td>{PFcorrector.parentname}</td>
                                    <td>{PFcorrector.metermake}</td>
                                    <td>{PFcorrector.meterslno}</td>
                                    <td>{PFcorrector.phase}</td>
                                    <td>{PFcorrector.ratedpowerinva}</td>
                                    <td>{PFcorrector.operatingvoltage}</td>
                                    <td>{PFcorrector.autoselect}</td>
                                    <td>{PFcorrector.capacitortype}</td>
                                    <td>{PFcorrector.numberofstages}</td>
                                    <td>{PFcorrector.numberofcellsineachstage}</td>
                                    <td>{PFcorrector.eachcellcapacity}</td>
                                    <td>{PFcorrector.presenttestedvalueofeachcell}</td>
                                    <td>{PFcorrector.pfcefficiency}</td>
                                    <td>{PFcorrector.overcurrentprotectionavailable}</td>
                                    <td>{PFcorrector.overvoltageprotectionavailable}</td>
                                    <td>{PFcorrector.proposedperiodicityofmaintenance}</td>
                                    <td>{PFcorrector.previousmaintenancedonedate}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(PFcorrector)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {PFcorrectorEditData && (
                        <Popup open={true} onClose={() => setPFcorrectorEditData(null)}>
                            <div id='pfcorrectorsfetch-popup'>
                                <h3>Edit PF Corrector</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='pfcorrectorsfetch-popup1'>
                                    <label>
                                            <span>Date</span>
                                            <input type="date" name="date" value={PFcorrectorEditData.typeofpfcorrector} onChange={handleEditChange} disabled/>
                                        </label>
                                        <label>
                                            <span>Parent Name</span>
                                            <select name="parentname" value={PFcorrectorEditData.parentname} onChange={handleEditChange}>
                                                <option value="">Select Parent Name</option>
                                                {parentNames.map((name) => (
                                                    <option key={name} value={name}>{name}</option>
                                                ))}
                                            </select>
                                        </label>
                                       
                                    </div>
                                    <div id='pfcorrectorsfetch-popup1'>
                                    <label>
                                            <span>Name</span>
                                            <input type="text" name="name" value={PFcorrectorEditData.name} onChange={handleEditChange} />
                                        </label>
                                       
                                        <label>
                                            <span>Meter Make</span>
                                            <input type="text" name="metermake" value={PFcorrectorEditData.metermake} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='pfcorrectorsfetch-popup1'>
                                        <label>
                                            <span>Meter SL No</span>
                                            <input type="number" name="meterslno" value={PFcorrectorEditData.meterslno} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Phase</span>
                                            <select name="phase" value={PFcorrectorEditData.phase} onChange={handleEditChange} required>
                                                <option value=''>Select Phase</option>
                                                <option value='3-Phase'>3-Phase</option>
                                                <option value='1-Phase-Vr'>1-Phase-Vr</option>
                                                <option value='1-Phase-Vy'>1-Phase-Vy</option>
                                                <option value='1-Phase-Vb'>1-Phase-Vb</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div id='pfcorrectorsfetch-popup1'>
                                        <label>
                                            <span>Rated Power (kVA)</span>
                                            <input type="number" name="ratedpowerinva" value={PFcorrectorEditData.ratedpowerinva} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Operating Voltage</span>
                                            <input type="number" name="operatingvoltage" value={PFcorrectorEditData.operatingvoltage} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='pfcorrectorsfetch-popup1'>
                                        <label>
                                            <span>Auto Select</span>
                                            <select name="autoselect" value={PFcorrectorEditData.autoselect} onChange={handleEditChange}>
                                                <option value=''>Select Option</option>
                                                <option value='Auto'>Auto</option>
                                                <option value='Manual'>Manual</option>
                                            </select>
                                            {/* <input type="text" name="autoselect" value={PFcorrectorEditData.autoselect} onChange={handleEditChange} /> */}
                                        </label>
                                        <label>
                                            <span>Capacitor Type</span>
                                            <input type="text" name="capacitortype" value={PFcorrectorEditData.capacitortype} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='pfcorrectorsfetch-popup1'>
                                        <label>
                                            <span>Number of Stages</span>
                                            <input type="number" name="numberofstages" value={PFcorrectorEditData.numberofstages} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Number of Cells in Each Stage</span>
                                            <input type="number" name="numberofcellsineachstage" value={PFcorrectorEditData.numberofcellsineachstage} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='pfcorrectorsfetch-popup1'>
                                        <label>
                                            <span>Each Cell Capacity</span>
                                            <input type="number" name="eachcellcapacity" value={PFcorrectorEditData.eachcellcapacity} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Present Tested Value of Each Cell</span>
                                            <input type="number" name="presenttestedvalueofeachcell" value={PFcorrectorEditData.presenttestedvalueofeachcell} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='pfcorrectorsfetch-popup1'>
                                        <label>
                                            <span>PFC Efficiency</span>
                                            <input type="number" name="pfcefficiency" value={PFcorrectorEditData.pfcefficiency} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Overcurrent Protection Available</span>
                                            <select name="overcurrentprotectionavailable" value={PFcorrectorEditData.overcurrentprotectionavailable} onChange={handleEditChange}>
                                                <option value=''>Select Option</option>
                                                <option value='Y'>Yes</option>
                                                <option value='N'>No</option>
                                            </select>
                                            {/* <input type="number" name="overcurrentprotectionavailable" value={PFcorrectorEditData.overcurrentprotectionavailable} onChange={handleEditChange} /> */}
                                        </label>
                                    </div>
                                    <div id='pfcorrectorsfetch-popup1'>
                                        <label>
                                            <span>Overvoltage Protection Available</span>
                                            <select name="overvoltageprotectionavailable" value={PFcorrectorEditData.overvoltageprotectionavailable} onChange={handleEditChange} >
                                                <option value=''>Select Option</option>
                                                <option value='true'>Yes</option>
                                                <option value='false'>No</option>
                                            </select>
                                            {/* <input type="number" name="overvoltageprotectionavailable" value={PFcorrectorEditData.overvoltageprotectionavailable} onChange={handleEditChange} /> */}
                                        </label>
                                        <label>
                                            <span>Proposed Periodicity of Maintenance</span>
                                            <input  name="proposedperiodicityofmaintenance" value={PFcorrectorEditData.proposedperiodicityofmaintenance} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='pfcorrectorsfetch-popup1'>
                                        <label>
                                            <span>Previous Maintenance Done Date</span>
                                            <input type="date" name="previousmaintenancedonedate" value={PFcorrectorEditData.previousmaintenancedonedate} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    {/* <div id='pfcorrectorsfetch-divbottom'> */}
                                    <button id='pfcorrectorsfetch-popup-update' type="submit">{Masterformsheadingsss.Updateheading}</button>
                                    {/* </div> */}
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PFcorrectorsfetch;
