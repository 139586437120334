import React, { useEffect, useState, useRef } from 'react';
import './Historydata.css';
import Operatorsidebar from '../Sidebar/Sidebar';
import { alertData2 } from '../Data';
import Stationpoints from '../../Commonfns/Stationpoints';
import { FaFileCsv } from "react-icons/fa6";
import TopBar from '../../Commonfns/Topbar/Topbar';
// Helper function to get today's date in YYYY-MM-DD format
const getTodayDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const dd = String(today.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
};

const Historydata = () => {
  const [data, setData] = useState([]);
  const [section, setSection] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [page, setPage] = useState(1);
  const itemsPerPage = 50; // Number of items to display initially and on each scroll

  const tableRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let url = `${process.env.REACT_APP_API_EMS_URL}/api/historydata`;

        if (fromDate && toDate) {
          url += `?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
        }

        const response = await fetch(url);
        const result = await response.json();
        setData(result);
        setFilteredData(result);
        setDisplayedData(result.slice(0, itemsPerPage)); // Load the first batch of items
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [fromDate, toDate]);

  useEffect(() => {
    const applyFilters = () => {
      let filtered = data;

      if (fromDate && toDate) {
        const fromDateObj = new Date(fromDate);
        const toDateObj = new Date(toDate);
        fromDateObj.setHours(0, 0, 0, 0);
        toDateObj.setHours(23, 59, 59, 999);

        filtered = filtered.filter(item => {
          const year = parseInt(item.utcTimestamp.substring(0, 4));
          const month = parseInt(item.utcTimestamp.substring(4, 6)) - 1;
          const day = parseInt(item.utcTimestamp.substring(6, 8));
          const hours = parseInt(item.utcTimestamp.substring(8, 10));
          const minutes = parseInt(item.utcTimestamp.substring(10, 12));
          const seconds = parseInt(item.utcTimestamp.substring(12, 14));
          const timestamp = new Date(year, month, day, hours, minutes, seconds);
          const itemDate = new Date(timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate());

          return itemDate >= fromDateObj && itemDate <= toDateObj;
        });
      }

      if (section) {
        filtered = filtered.filter(item => item.stationPoint === section);
      }

      setFilteredData(filtered);
      setDisplayedData(filtered.slice(0, page * itemsPerPage)); // Reset displayed data based on filters
    };

    applyFilters();
  }, [data, section, fromDate, toDate, page]);

  const loadMoreItems = () => {
    const newPage = page + 1;
    const newItems = filteredData.slice(0, newPage * itemsPerPage);
    setDisplayedData(newItems);
    setPage(newPage);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        tableRef.current &&
        tableRef.current.scrollTop + tableRef.current.clientHeight >= tableRef.current.scrollHeight
      ) {
        loadMoreItems();
      }
    };

    const tableElement = tableRef.current;
    if (tableElement) {
      tableElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (tableElement) {
        tableElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [filteredData, page]);

  const handleStationPointChange = (selectedStationPoint) => {
    setSection(selectedStationPoint);
  };

  const downloadCSV = () => {
    const headers = [
      'S No', 'deviceEUi', 'division', 'stationPoint', 'Date', 'Time', 'utcTimestamp', 'Vr', 'Vy', 'Vb',
      'Vavg', 'Ir', 'Iy', 'Ib', 'Iavg', 'Imax', 'Fr', 'THDvr', 'THDvy', 'THDvb', 'THDir',
      'THDiy', 'THDib', 'Vry', 'Vyb', 'Vbr', 'Pf', 'kW', 'kVA', 'kWh', 'KVArh', 'KVAh'
    ];

    const rows = filteredData.map((item, index) => [
      index + 1, item.deviceEUi, item.division, item.stationPoint, item.Date, item.Time, item.utcTimestamp, item.Vr, item.Vy, item.Vb,
      item.Vavg, item.Ir, item.Iy, item.Ib, item.Iavg, item.Imax, item.Fr, item.THDvr, item.THDvy, item.THDvb,
      item.THDir, item.THDiy, item.THDib, item.Vry, item.Vyb, item.Vbr, item.Pf, item.kW, item.kVA, item.kWh,
      item.KVArh, item.KVAh
    ]);

    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += headers.join(',') + '\n';
    rows.forEach(row => {
      csvContent += row.join(',') + '\n';
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'historydata.csv');
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  const handleFromDateChange = (e) => {
    const selectedFromDate = e.target.value;
    setFromDate(selectedFromDate);

    if (toDate && toDate < selectedFromDate) {
      setToDate("");
    }
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  return (
    <div id='historydata-total'>
      <div>
        <Operatorsidebar />
      </div>
      <div id='historydata-right'>

        <TopBar section="HistorydataHeader" />

        <div id='historydata-right-2'>
          <div id='alert-right-21'>
            <label>
              <span>From date:</span>
              <input type='date' value={fromDate} onChange={handleFromDateChange} max={getTodayDate()} />
            </label>
            <label>
              <span>To date:</span>
              <input type='date' value={toDate} onChange={handleToDateChange} max={getTodayDate()} min={fromDate || ""}  disabled={!fromDate} />
            </label>
            <label>
              <Stationpoints onStationPointChange={handleStationPointChange} />
            </label>
            <label>
              <button onClick={downloadCSV}><FaFileCsv /></button>

            </label>
          </div>

          <div className="table-wrapper">
            <table ref={tableRef}>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Division</th>
                  <th>Section</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Vr</th>
                  <th>Vy</th>
                  <th>Vb</th>
                  <th>Vavg</th>
                  <th>Ir</th>
                  <th>Iy</th>
                  <th>Ib</th>
                  <th>Iavg</th>
                  <th>Imax</th>
                  <th>Fr</th>
                  <th>THDvr</th>
                  <th>THDvy</th>
                  <th>THDvb</th>
                  <th>THDir</th>
                  <th>THDiy</th>
                  <th>THDib</th>
                  <th>Vry</th>
                  <th>Vyb</th>
                  <th>Vbr</th>
                  <th>Pf</th>
                  <th>kW</th>
                  <th>kVA</th>
                  <th>kWh</th>
                  <th>KVArh</th>
                  <th>KVAh</th>
                </tr>
              </thead>
              <tbody>
                {displayedData.length === 0 ? (
                  <tr>
                    <td colSpan="31">No data available for selected filters.</td>
                  </tr>
                ) : (
                  displayedData.map((item, index) => (
                    <tr key={item._id}>
                      <td>{index + 1}</td>
                      <td>{item.division}</td>
                      <td>{item.stationPoint}</td>
                      <td>{item.Date}</td>
                      <td>{item.Time}</td>
                      <td>{item.Vr}</td>
                      <td>{item.Vy}</td>
                      <td>{item.Vb}</td>
                      <td>{item.Vavg}</td>
                      <td>{item.Ir}</td>
                      <td>{item.Iy}</td>
                      <td>{item.Ib}</td>
                      <td>{item.Iavg}</td>
                      <td>{item.Imax}</td>
                      <td>{item.Fr}</td>
                      <td>{item.THDvr}</td>
                      <td>{item.THDvy}</td>
                      <td>{item.THDvb}</td>
                      <td>{item.THDir}</td>
                      <td>{item.THDiy}</td>
                      <td>{item.THDib}</td>
                      <td>{item.Vry}</td>
                      <td>{item.Vyb}</td>
                      <td>{item.Vbr}</td>
                      <td>{item.Pf}</td>
                      <td>{item.kW}</td>
                      <td>{item.kVA}</td>
                      <td>{item.kWh}</td>
                      <td>{item.KVAR}</td>
                      <td>{item.KVAh}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Historydata;


// import React, { useEffect, useState, useRef } from "react";
// import "./Historydata.css";
// import Operatorsidebar from "../Sidebar/Sidebar";
// import Stationpoints from "../../Commonfns/Stationpoints";
// import { FaFileCsv } from "react-icons/fa6";
// import TopBar from "../../Commonfns/Topbar/Topbar";
// // Helper function to get today's date in YYYY-MM-DD format
// const getTodayDate = () => {
//   const today = new Date();
//   const yyyy = today.getFullYear();
//   const mm = String(today.getMonth() + 1).padStart(2, "0");
//   const dd = String(today.getDate()).padStart(2, "0");
//   return `${yyyy}-${mm}-${dd}`;
// };

// const Historydata = () => {
//   const [historydata, setHistoryData] = useState([]);
//   const [section, setSection] = useState("");
//   const [fromDate, setFromDate] = useState(getTodayDate());
//   const [toDate, setToDate] = useState(getTodayDate());
//   const [page, setPage] = useState(1);
//   const [isLoading, setIsLoading] = useState(false);
//   const itemsPerPage = 20;

//   const tableRef = useRef(null);

//   const fetchHistorydata = async () => {
//     setIsLoading(true);
//     try {
//       let query = `${process.env.REACT_APP_API_EMS_URL}/api/historydata/filter?page=${page}&limit=${itemsPerPage}&`;

//       if (fromDate) {
//         query += `fromDate=${fromDate}&`;
//       }
//       if (toDate) {
//         query += `toDate=${toDate}&`;
//       }
//       if (section) {
//         query += `stationPoint=${encodeURIComponent(section)}&`;
//       }

//       const response = await fetch(query);
//       const data = await response.json();

//       if (page === 1) {
//         setHistoryData(data);
//       } else {
//         setHistoryData((prevData) => [...prevData, ...data]); // Append new data to existing data
//       }
//     } catch (error) {
//       console.error("Error fetching alerts:", error);
//     }
//     setIsLoading(false);
//   };

//   // Reset scroll position whenever filters change
//   useEffect(() => {
//     if (tableRef.current) {
//       tableRef.current.scrollTop = 0; // Reset scroll position to top
//     }
//   }, [section, fromDate, toDate]);

//   useEffect(() => {
//     setPage(1);
//     fetchHistorydata();
//   }, [section, fromDate, toDate]);

//   // Load more items when the user scrolls to the bottom of the table
//   const loadMoreItems = () => {
//     setPage((prevPage) => prevPage + 1);
//   };

//   useEffect(() => {
//     if (page > 1) {
//       fetchHistorydata();
//     }
//   }, [page]);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (
//         tableRef.current &&
//         tableRef.current.scrollTop + tableRef.current.clientHeight >=
//           tableRef.current.scrollHeight &&
//         !isLoading
//       ) {
//         loadMoreItems();
//       }
//     };

//     const tableElement = tableRef.current;
//     if (tableElement) {
//       tableElement.addEventListener("scroll", handleScroll);
//     }

//     return () => {
//       if (tableElement) {
//         tableElement.removeEventListener("scroll", handleScroll);
//       }
//     };
//   }, [historydata, isLoading]);

//   const handleStationPointChange = (selectedStationPoint) => {
//     setSection(selectedStationPoint);
//   };

//   const downloadCSV = async () => {
//     try {
//       let query = `${process.env.REACT_APP_API_EMS_URL}/api/historydata/filter/download?`;

//       if (fromDate) {
//         query += `fromDate=${fromDate}&`;
//       }
//       if (toDate) {
//         query += `toDate=${toDate}&`;
//       }
//       if (section) {
//         query += `section=${encodeURIComponent(section)}&`;
//       }

//       const response = await fetch(query);
//       const data = await response.json();

//       // Generate CSV content from the fetched data
//       const csvContent = [
//         [
//           "S No",
//           "deviceEUi",
//           "division",
//           "stationPoint",
//           "Date",
//           "Time",
//           "utcTimestamp",
//           "Vr",
//           "Vy",
//           "Vb",
//           "Vavg",
//           "Ir",
//           "Iy",
//           "Ib",
//           "Iavg",
//           "Imax",
//           "Fr",
//           "THDvr",
//           "THDvy",
//           "THDvb",
//           "THDir",
//           "THDiy",
//           "THDib",
//           "Vry",
//           "Vyb",
//           "Vbr",
//           "Pf",
//           "kW",
//           "kVA",
//           "kWh",
//           "KVArh",
//           "KVAh",
//         ],
//         ...data.map((historydataitem, index) => [
//           index + 1,
//           historydataitem.deviceEUi,
//           historydataitem.division,
//           historydataitem.stationPoint,
//           historydataitem.Date,
//           historydataitem.Time,
//           historydataitem.utcTimestamp,
//           historydataitem.Vr,
//           historydataitem.Vy,
//           historydataitem.Vb,
//           historydataitem.Vavg,
//           historydataitem.Ir,
//           historydataitem.Iy,
//           historydataitem.Ib,
//           historydataitem.Iavg,
//           historydataitem.Imax,
//           historydataitem.Fr,
//           historydataitem.THDvr,
//           historydataitem.THDvy,
//           historydataitem.THDvb,
//           historydataitem.THDir,
//           historydataitem.THDiy,
//           historydataitem.THDib,
//           historydataitem.Vry,
//           historydataitem.Vyb,
//           historydataitem.Vbr,
//           historydataitem.Pf,
//           historydataitem.kW,
//           historydataitem.kVA,
//           historydataitem.kWh,
//           historydataitem.KVArh,
//           historydataitem.KVAh,
//         ]),
//       ]
//         .map((row) => row.join(","))
//         .join("\n");

//       // Create a Blob from the CSV content
//       const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
//       const url = URL.createObjectURL(blob);

//       // Open a new window with the same path and trigger the download
//       const newWindow = window.open(window.location.href, "_blank"); // Open a new window with the same URL
//       if (newWindow) {
//         // Create a download link in the new window and trigger the download
//         newWindow.onload = function () {
//           const link = newWindow.document.createElement("a");
//           link.setAttribute("href", url);
//           link.setAttribute("download", "Historydata.csv");
//           link.style.display = "none";
//           newWindow.document.body.appendChild(link);
//           link.click();
//           newWindow.document.body.removeChild(link); // Remove the link after downloading
//         };
//       }
//     } catch (error) {
//       console.error("Error fetching data for CSV download:", error);
//     }
//   };


//   const handleFromDateChange = (e) => {
//     const selectedFromDate = e.target.value;
//     setFromDate(selectedFromDate);

//     if (toDate && toDate < selectedFromDate) {
//       setToDate("");
//     }
//   };

//   const handleToDateChange = (e) => {
//     setToDate(e.target.value);
//   };

//   return (
//     <div id="historydata-total">
//       <div>
//         <Operatorsidebar />
//       </div>
//       <div id="historydata-right">
//         <TopBar section="HistorydataHeader" />

//         <div id="historydata-right-2">
//           <div id="alert-right-21">
//             <label>
//               <span>From date:</span>
//               <input
//                 type="date"
//                 value={fromDate}
//                 onChange={handleFromDateChange}
//                 max={getTodayDate()}
//               />
//             </label>
//             <label>
//               <span>To date:</span>
//               <input
//                 type="date"
//                 value={toDate}
//                 onChange={handleToDateChange}
//                 max={getTodayDate()}
//                 min={fromDate || ""}
//                 disabled={!fromDate}
//               />
//             </label>
//             <label>
//               <Stationpoints onStationPointChange={handleStationPointChange} />
//             </label>
//             <label>
//               <button onClick={downloadCSV}>
//                 <FaFileCsv />
//               </button>
//             </label>
//           </div>

//           <div className="table-wrapper">
//             <table ref={tableRef}>
//               <thead>
//                 <tr>
//                   <th>S. No.</th>
//                   <th>Division</th>
//                   <th>Section</th>
//                   <th>Date</th>
//                   <th>Time</th>
//                   <th>Vr</th>
//                   <th>Vy</th>
//                   <th>Vb</th>
//                   <th>Vavg</th>
//                   <th>Ir</th>
//                   <th>Iy</th>
//                   <th>Ib</th>
//                   <th>Iavg</th>
//                   <th>Imax</th>
//                   <th>Fr</th>
//                   <th>THDvr</th>
//                   <th>THDvy</th>
//                   <th>THDvb</th>
//                   <th>THDir</th>
//                   <th>THDiy</th>
//                   <th>THDib</th>
//                   <th>Vry</th>
//                   <th>Vyb</th>
//                   <th>Vbr</th>
//                   <th>Pf</th>
//                   <th>kW</th>
//                   <th>kVA</th>
//                   <th>kWh</th>
//                   <th>KVArh</th>
//                   <th>KVAh</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {historydata.length === 0 ? (
//                   <tr>
//                     <td colSpan="31">
//                       No data available for selected filters.
//                     </td>
//                   </tr>
//                 ) : (
//                   historydata.map((historydataitem, index) => (
//                     <tr key={index}>
//                       <td>{index + 1}</td>
//                       <td>{historydataitem.division}</td>
//                       <td>{historydataitem.stationPoint}</td>
//                       <td>{historydataitem.Date}</td>
//                       <td>{historydataitem.Time}</td>
//                       <td>{historydataitem.Vr}</td>
//                       <td>{historydataitem.Vy}</td>
//                       <td>{historydataitem.Vb}</td>
//                       <td>{historydataitem.Vavg}</td>
//                       <td>{historydataitem.Ir}</td>
//                       <td>{historydataitem.Iy}</td>
//                       <td>{historydataitem.Ib}</td>
//                       <td>{historydataitem.Iavg}</td>
//                       <td>{historydataitem.Imax}</td>
//                       <td>{historydataitem.Fr}</td>
//                       <td>{historydataitem.THDvr}</td>
//                       <td>{historydataitem.THDvy}</td>
//                       <td>{historydataitem.THDvb}</td>
//                       <td>{historydataitem.THDir}</td>
//                       <td>{historydataitem.THDiy}</td>
//                       <td>{historydataitem.THDib}</td>
//                       <td>{historydataitem.Vry}</td>
//                       <td>{historydataitem.Vyb}</td>
//                       <td>{historydataitem.Vbr}</td>
//                       <td>{historydataitem.Pf}</td>
//                       <td>{historydataitem.kW}</td>
//                       <td>{historydataitem.kVA}</td>
//                       <td>{historydataitem.kWh}</td>
//                       <td>{historydataitem.KVAR}</td>
//                       <td>{historydataitem.KVAh}</td>
//                     </tr>
//                   ))
//                 )}
//                  {isLoading && (
//                   <tr>
//                     <td colSpan="31" style={{ textAlign: "center" }}>
//                       Loading...
//                     </td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Historydata;
