import React, { useState , useEffect } from 'react';
import { useParams , useNavigate } from 'react-router-dom';
import './Groundingresistance.css';
import axios from 'axios';
import { IoIosAddCircle, IoIosRemoveCircle } from "react-icons/io";
import { Power_Surgesheader } from '../../../Operator/Data';
import { Link } from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import { getCurrentDateTime } from '../../../Diagnose/dateUtils';
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const Powersurge_Groundingresistance = () => {
    const { token_id } = useParams();
 const navigate = useNavigate();
    const [groundingPoints, setGroundingPoints] = useState([{ name_of_grounding_point: '', grounding_resistance: '' }]);

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
      // Retrieve the logged-in user from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        setLoggedInUser(user);
      }
      setUserDesignation(user.level);
    }, []);

    const handleAdd = () => {
        setGroundingPoints([...groundingPoints, { name_of_grounding_point: '', grounding_resistance: '' }]);
    };

    const handleChange = (index, event) => {
        const { name, value } = event.target;
        const newGroundingPoints = [...groundingPoints];
        newGroundingPoints[index][name] = value;
        setGroundingPoints(newGroundingPoints);
    };

    const handleCancel = (index) => {
        const newGroundingPoints = groundingPoints.filter((_, i) => i !== index);
        setGroundingPoints(newGroundingPoints);
    };

    const initialGroundingPointData = {
        dateandtime: getCurrentDateTime(),
        name_of_grounding_point: '',
        grounding_resistance: '',
        token_id: token_id,
        createdby: '',
        createdat: '',
        modifiedby: '',
        modifiedat: '',
        spare1: '',
        spare2: ''
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = groundingPoints.map(point => ({
                ...initialGroundingPointData,
                ...point,
                grounding_resistance: Number(point.grounding_resistance),
            }));

            const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/powersurge-groundingresistances`, dataToSend);

            console.log('Data posted successfully:', response.data);

            alert('Grounding point data added successfully');
            setGroundingPoints([{ name_of_grounding_point: '', grounding_resistance: '' }]);
            navigate('/Powersurge_Groundingresistancefetch');
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    return (
        <div id='groundingresistance-total'>
             <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />} 
            </div>
            <div id='groundingresistance-right'>
                <div id='groundingresistance-right-1'>
                    <div id='groundingresistance-heading'>
                        {Power_Surgesheader.alertHeading}
                    </div>
                    <div id='groundingresistance-heading1'>
                        {Power_Surgesheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Power_Surgesheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <h3 id='groundingresistance-h3'>{Power_Surgesheader.subalertHeading}</h3>
                <div id='groundingresistance-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='groundingresistance-divtop'>
                            <label>
                                <span>Grounding Resistance</span>
                            </label>
                            {/* <label>
                                <Link to='/Powersurge_Groundingresistancefetch'><button type="button">View Data</button></Link>
                            </label> */}
                        </div>

                        {groundingPoints.map((point, index) => (
                            <div key={index} id='groundingresistance-row'>
                                <label>
                                    <span>Name of Grounding Point <span id='all-mandetorymark'>*</span></span>
                                    <input
                                        name="name_of_grounding_point"
                                        value={point.name_of_grounding_point}
                                        onChange={(e) => handleChange(index, e)}
                                        required
                                    />
                                </label>
                                <label>
                                    <span>Grounding Resistance <span id='all-mandetorymark'>*</span></span>
                                    <input
                                        name="grounding_resistance"
                                        type="number"
                                        value={point.grounding_resistance}
                                        onChange={(e) => handleChange(index, e)}
                                        required
                                    />
                                </label>
                                {index !== 0 && (
                                    <button id='groundingresistance-cancelbtn' type="button" onClick={() => handleCancel(index)}><IoIosRemoveCircle id='reg-btn' /></button>
                                )}
                                {index === groundingPoints.length - 1 && (
                                    <button id='groundingresistance-cancelbtn' type="button" onClick={handleAdd}><IoIosAddCircle id='reg-btn' /></button>
                                )}
                            </div>
                        ))}

                        <label>
                            <button id='groundingresistance-submit' type="submit">Submit</button>
                        </label>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Powersurge_Groundingresistance;
