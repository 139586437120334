

import React from 'react';
import './MonthlyDashboard.css';
import { supervisordashbuttons } from '../../Operator/Data';

const MonthlyDashboard = ({ selectedMonth, onMonthChange }) => {
  const handleMonthChange = (event) => {
    onMonthChange(event.target.value);
  };

  const getCurrentMonth = () => {
    const date = new Date();
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
  };

  const formatMonthYear = (monthString) => {
    const date = new Date(monthString);
    const options = { year: 'numeric', month: 'long' };
    return date.toLocaleDateString('en-US', options); // e.g., "August 2024"
  };

  return (
    <div id='monthlydash-div'>
      <div id='monthlydash-div1'>
        <span id='monthlydash-bold' >{supervisordashbuttons.button3Headingfordash}</span>
        <span> &nbsp;{formatMonthYear(selectedMonth)}</span>

      </div>
      <div id='monthlydash-div2' >
        <span>Select Month:</span>
        <input
          type="month"
          id="month"
          value={selectedMonth}
          onChange={handleMonthChange}
          max={getCurrentMonth()}
        ></input>
      </div>
    </div>
  )
}

export default MonthlyDashboard;
