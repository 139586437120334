import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { getDownTimeUptimeReports } from "../../../api/services/trends/trendsService";
import { Box, Paper, Divider, Typography, Button } from "@mui/material";
import Stationpoints from "../../Commonfns/Stationpoints";
import {
  decimalHoursToTimeString,
  hoursToTimeString,
  timeStringToDecimalHours,
  whatSholdYouDo,
} from "../../../utils/trends";
import chatIcon from "../../../Images/Black.svg";
import manIcon from "../../../Images/man.svg";
import lightIcon from "../../../Images/light.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { DatePicker, Popover } from "antd";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DownTimeVsUpTime = () => {
  const { t } = useTranslation();

  const monthInputRef = useRef(null);

  const { control, register, formState, setValue, reset, watch, getValues } = useForm({
    defaultValues: {
      station_point: "HV",
      month: dayjs(),
    },
  });

  const [upTimeData, setUpTimeData] = useState([]);

  const [downTimeData, setDownTimeData] = useState([]);

  const [labels, setLabels] = useState([]);

  const { station_point, month } = watch();

  useEffect(() => {
    ChartJS.unregister(ChartDataLabels);
    getDownTimeUptimeReports(station_point, month.format('YYYY-MM')).then((data) => {
      setUpTimeData(data.uptimeData);
      setLabels(data.dateLabels);
      setDownTimeData(data.downtimeData);
    });
  }, [station_point, month]);


  const disableFutureMonths = (current) => {
    return current && current > dayjs().endOf("month");
  };

  const onChange = (value) => {
    if (value) {
      setValue("month", value);
    }
  };

  const data = {
    labels: labels.map((label) => label),
    datasets: [
      {
        label: "UpTime Hours",
        data:  upTimeData ?  upTimeData?.map((timeString) =>
          timeStringToDecimalHours(timeString)
        ) : [],
        backgroundColor: "#6174D7",
        maxBarThickness: 100,
      },
      {
        label: "DownTime Hours",
        data: downTimeData ?  downTimeData?.map((timeString) =>
          timeStringToDecimalHours(timeString)
        ): [],
        backgroundColor: "#EA4335",
        maxBarThickness: 100,
      },
    ],
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index", // Highlight both bars on the same date
      intersect: false,
    },
    hover: {
      mode: "index",
      intersect: false,
      onHover: function (event, chartElement) {
        event.native.target.style.cursor = chartElement[0]
          ? "pointer"
          : "default";
      },
    },

    animation: {
      // duration: 0,
      // easing: undefined,
    },
    plugins: {
      tooltip: {
        mode: "index", // Highlight both bars on the same date
        intersect: false,
        // animation: false,
        // duration: 0,
        backgroundColor: "white", // Set the tooltip background color to white
        titleColor: "black", // Set the title text color to black for contrast
        bodyColor: "black",
        borderColor: "black",
        borderWidth: 1,
        yAlign: 'bottom', // Ensures tooltip is positioned above the bar
        caretSize: 5, // Adjust size of the tooltip caret
        caretPadding: 0,

        titleFont: {
          size: 15, // Increase title font size
        },
        bodyFont: {
          size: 14, // Increase body font size
        },
        padding: 12, // Increase padding inside the tooltip
        boxPadding: 10, // Padding around colored boxes
        boxWidth: 30, // Width of color indicator boxes
        boxHeight: 30, // Height of color indicator boxes
        callbacks: {
          label: function (context) {
            // Get the value from the dataset

            const datasetLabel = context.dataset.label;
            const value = context.raw;
            // Convert the value to HH:MM:SS format
            const timeString = decimalHoursToTimeString(value);
            // Return the formatted time string
            return `${datasetLabel}: ${timeString}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
        beginAtZero: true,
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
      },
      y: {
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        ticks: {
          callback: (value) => hoursToTimeString(value),
          beginAtZero: true,
        },
        beginAtZero: true,
        title: {
          display: true,
          text: "Time",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
      },
    },
  };

  const handleStationPointChange = (value) => {
    setValue("station_point", value);
  };

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          border: "1px solid #DFE6FA",
        }}
        className="filter-container"
      >
        <Box>
          <div>
            <Stationpoints
              onStationPointChange={handleStationPointChange}
              defaultStationPoint="HV"
            />
          </div>
        </Box>
        <Box>
          <div>
            <label>{t("common.selectMonthColumn")}</label>
          </div>

          <div>
          <DatePicker
                  {...register("month")}
                  style={{ width: "200px" }}
                  defaultValue={getValues("month")}
                  size={"medium"}
                  picker={"month"}
                  disabledDate={disableFutureMonths} // Restricts future months
                  onChange={onChange}
                />
          </div>
        </Box>
      </Paper>
      <Paper
        elevation={0}
        sx={{
          border: "1px solid #DFE6FA",
          p: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {t("downTimeTrendsComponent.downtimeTrends")}
          </Typography>

          <Popover
            placement="leftBottom"
            content={
              <Box maxWidth={600}>
                <Box className="static-content-container">
                  <Box className="imp-container">
                    <img src={chatIcon} width={"40px"} height={"40px"} />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#447F44", fontWeight: "bold" }}
                      >
                        {t("common.whyIsThisImportanQuestion")}
                      </Typography>
                      <Typography variant="body1">
                        {t("downTimeTrendsComponent.whyIsThisImportantAnswer")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className="static-content-container">
                  <Box
                    className="imp-container"
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <img
                        style={{ alignSelf: "start" }}
                        src={manIcon}
                        width={"85px"}
                        height={"40px"}
                      />

                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="body1"
                          sx={{ color: "#447F44", fontWeight: "bold" }}
                        >
                          {t("common.WhatShouldYouDoQuestion")}
                        </Typography>
                        <Typography variant="body1">
                          {t("downTimeTrendsComponent.whatShouldYouDoAnswer")}
                        </Typography>
                      </Box>
                    </Box>

                    <Box>
                      <Link to={"/Forms?section=Breakdownlogsfetch"}>
                        <Button
                          sx={{
                            textTransform: "none",
                            backgroundColor: "#447F44",
                            color: "white",
                            width: "150px",
                            justifySelf: "end",
                            alignSelf: "end",
                            borderRadius: "4px",
                          }}
                        >
                          {t("downTimeTrendsComponent.breakdownLogs")}
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Box>

                <Box className="static-content-container">
                  <Box className="imp-container">
                    <img src={lightIcon} width={"40px"} height={"40px"} />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#447F44", fontWeight: "bold" }}
                      >
                        {t("common.howWillYouImproveQuestion")}
                      </Typography>
                      <Typography variant="body1">
                        {t("downTimeTrendsComponent.howWillYouImproveAnswer")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            }
            // title="Title"
          >
            <Box
              type="button"
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: 0.1,
              }}
              // onClick={() => setOpen()}
            >
              <HelpOutlineOutlinedIcon fontSize="medium" color="disabled" />
              <Typography
                fontSize={15}
                style={{ display: "inline", color: "gray" }}
              >
                How to use?
              </Typography>
            </Box>
          </Popover>
        </Box>

        <Bar className="alerts-chart" data={data} options={options} />
      </Paper>
    </div>
  );
};

export default DownTimeVsUpTime;
