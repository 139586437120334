
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PowerBillsfetch.css'; // Import your CSS file for styling
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { convertMMYYYYtoYYYYMM, convertYYYYMMtoMMYYYY, filterDataByMonthRange } from '../../Operator/OperatorForms/Dateformat';
import { FaFileCsv } from "react-icons/fa6";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const PowerBillsfetch = ({ onFormButtonClick }) => {
    const convertTMonthInputFormat = (dateString) => {
        const [yyyy, mm] = dateString.split('-');
        return `${mm}-${yyyy}`;
    };

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedLog, setSelectedLog] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [errors, setErrors] = useState({});
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [userDesignation, setUserDesignation] = useState('');

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
        }
        setUserDesignation(user.level);
    }, []);

    useEffect(() => {
        const fetchPowerBillsData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/power-bills`);
                const result = response.data;
                if (Array.isArray(result)) {
                    setData(result);
                    setFilteredData(result);
                } else {
                    console.error('Failed to fetch data:', result.error);
                }
            } catch (error) {
                console.error('Error fetching power bills data:', error);
            }
        };

        fetchPowerBillsData();
    }, []);

    useEffect(() => {
        filterDataByDate();
    }, [fromDate, toDate, data]);


    const filterDataByDate = () => {
        let filtered = data;
    
        if (fromDate) {
            const [fromYear, fromMonth] = fromDate.split('-');
            const fromDateFormatted = `${fromYear}-${fromMonth}`;
    
            filtered = filtered.filter(log => {
                const [logMonth, logYear] = log.month.split('-');
                const logDateFormatted = `${logYear}-${logMonth}`;
                return logDateFormatted >= fromDateFormatted;
            });
        }
    
        if (toDate) {
            const [toYear, toMonth] = toDate.split('-');
            const toDateFormatted = `${toYear}-${toMonth}`;
    
            filtered = filtered.filter(log => {
                const [logMonth, logYear] = log.month.split('-');
                const logDateFormatted = `${logYear}-${logMonth}`;
                return logDateFormatted <= toDateFormatted;
            });
        }
    
        setFilteredData(filtered);
    };
    


    const handleEditClick = (item) => {
        setSelectedLog({
            ...item,
            month: convertTMonthInputFormat(item.month), // Convert MM-YYYY to YYYY-MM for the input field
        });
        setIsPopupOpen(true);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
    
        const regexDouble = /^\d{1,15}(\.\d{0,2})?$/; // 15 digits with up to 2 decimal places
        const regexDouble8Digits = /^\d{1,8}(\.\d{0,2})?$/; // 8 digits with up to 2 decimal places
        const regexDouble2Digits = /^\d{1,2}(\.\d{0,2})?$/; // 2 digits with up to 2 decimal places
    
        let isValid = true;
    
        // Update state immediately to allow clearing the input
        setSelectedLog((prevData) => ({
            ...prevData,
            [name]: value
        }));
    
        // Skip validation for empty input
        if (value.trim() === '') return;
    
        // Perform validations based on the field name
        switch (name) {
            case 'kVAhOnFromDate':
            case 'kVAhOnToDate':
            case 'kWhOnFromDate':
            case 'kWhOnToDate':
            case 'CumulativeMaxDemand':
            case 'IncentiveTODkVAhConsumption':
            case 'PenaltyTODkVAhConsumption':
            case 'TotalBillInRupees':
                isValid = regexDouble.test(value);
                break;
    
            case 'BilledMD':
                isValid = regexDouble8Digits.test(value);
                break;
    
            case 'NumberOfResets':
                isValid = regexDouble2Digits.test(value);
                break;
    
            default:
                break;
        }
    
        if (!isValid) {
            toast.error('Please enter a valid value according to the required format.');
            return; // Stop further execution if input is invalid
        }
    
        // Perform calculations if the inputs are valid
        setSelectedLog((prevData) => {
            const updatedFormData = { ...prevData, [name]: value };
    
            // Calculate Billed_kWh_units
            if (name === 'kWhOnFromDate' || name === 'kWhOnToDate') {
                const initial = parseFloat(updatedFormData.kWhOnFromDate);
                const final = parseFloat(updatedFormData.kWhOnToDate);
    
                if (!isNaN(initial) && !isNaN(final) && initial > final) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        kWhOnFromDate: 'kWh On FromDate value cannot be greater than kWh On ToDate value.'
                    }));
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        kWhOnFromDate: ''
                    }));
                    updatedFormData.Billed_kWh_units = !isNaN(initial) && !isNaN(final) ? final - initial : '';
                }
            }
    
            // Calculate Billed_kVAh_units
            if (name === 'kVAhOnFromDate' || name === 'kVAhOnToDate') {
                const initial = parseFloat(updatedFormData.kVAhOnFromDate);
                const final = parseFloat(updatedFormData.kVAhOnToDate);
    
                if (!isNaN(initial) && !isNaN(final) && initial > final) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        kVAhOnFromDate: 'kVAh On FromDate value cannot be greater than kVAh On ToDate value.'
                    }));
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        kVAhOnFromDate: ''
                    }));
                    updatedFormData.Billed_kVAh_units = !isNaN(initial) && !isNaN(final) ? final - initial : '';
                }
            }
    
            return updatedFormData;
        });
    };
    


    const handleUpdate = async () => {

        
        const requiredFields = ['month','Billed_kWh_units','kWhOnFromDate', 'kWhOnToDate',
            'kVAhOnFromDate', 'kVAhOnToDate','Billed_kVAh_units','CumulativeMaxDemand',
            'BilledMD','TotalBillInRupees',
            'IncentiveTODkVAhConsumption','PenaltyTODkVAhConsumption',
        ];
    
        const hasEmptyFields = requiredFields.some(
            (field) => !selectedLog[field] || (typeof selectedLog[field] === 'string' && selectedLog[field].trim() === '')
        );
    
        if (hasEmptyFields) {
            toast.error('Please fill all required fields before updating.');
            return;
        }
    
        try {
            const updatedLog = {
                ...selectedLog,
                month: convertYYYYMMtoMMYYYY(selectedLog.month),
                modifiedby: loggedInUser.username,
                modifiedat: new Date().toISOString(),
            };
    
            const response = await axios.put(
                `${process.env.REACT_APP_API_EMS_URL}/api/power-bills/${selectedLog._id}`,
                updatedLog
            );
    
            if (response.data.success) {
                // setData(data.map((item) => (item._id === selectedLog._id ? selectedLog : item)));
                setData(data.map(item => (item._id === selectedLog._id ? { ...selectedLog, month: convertTMonthInputFormat(selectedLog.month) } : item)));
                setIsPopupOpen(false);
                setSelectedLog(null);
                toast.success('Power bill updated successfully');
            } else {
                console.error('Failed to update data:', response.data.error);
            }
        } catch (error) {
            console.error('Error Adding Power bill', error);
                    if (error.response && error.response.data.message === 'A log already exists for the month') {
                        toast.error('Data already exists for the Selected Month, Please select any other Month');
                    } else {
                        toast.error('Failed to add Power bill. Please try again later.');
                    }
        }
    };
    

    const downloadCSV = () => {
        const headers = [
            'Month',
            'kWh - Start Date',
            'kWh - End Date',
            'Billed kWh Units',
            'kVAh - Start Date',
            'kVAh - End Date',
            'Billed kVAh Units',
            'Cumulative Max Demand',
            'Billed MD',
            'No. of Resets',
            'TOD kVAh consumption (Penalty Hours)',
            'TOD kVAh consumption (Incentive Hours)',
            'Total Bill (In Rupees)',
            'Multiplying Factor (MF)',
            'Contracted Max Demand (CMD)'
        ];

        const rows = filteredData.map(log => [
            log.month,  // Assuming month is in MM-YYYY format as required
            log.kWhOnFromDate,
            log.kWhOnToDate,
            log.Billed_kWh_units,
            log.kVAhOnFromDate,
            log.kVAhOnToDate,
            log.Billed_kVAh_units,
            log.CumulativeMaxDemand,
            log.BilledMD,
            log.NumberOfResets,
            log.PenaltyTODkVAhConsumption,
            log.IncentiveTODkVAhConsumption,
            log.TotalBillInRupees,
            log['Multiplying_actor_(MF)'],
            log['Contracted_max_demand_(CMD)'],
        ]);

        // Create a CSV string
        const csvContent = [
            headers.join(','),
            ...rows.map(row => row.join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'Monthly_PowerBills.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleFromDateChange = (e) => {
        const selectedFromDate = e.target.value;
        setFromDate(selectedFromDate);

        if (toDate && toDate < selectedFromDate) {
            setToDate('');
        }
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const getCurrentMonth = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}`;
    };

    return (
        <div id='powerbillsfetch-total'>

            <div id='powerbillsfetch-right'>

                <div id='powerbillsfetch-right-2'>
                    <div id='powerbillsfetch-right-21'>
                        <label>
                            <span>From Month:</span>
                            <input
                                type="month"
                                value={fromDate}
                                onChange={handleFromDateChange}
                                max={getCurrentMonth()}
                            />
                        </label>
                        <label>
                            <span>To Month:</span>
                            <input
                                type="month"
                                value={toDate}
                                onChange={handleToDateChange}
                                max={getCurrentMonth()}
                                min={fromDate || ''}
                                disabled={!fromDate}
                            />
                        </label>
                        <label id='formbutton-left'>
                            <button id='formbutton-from' onClick={onFormButtonClick}>Form</button>
                        </label>
                        <label>
                            <button id='formbutton-csv' onClick={downloadCSV}><FaFileCsv id='formbutton-csvicon' /></button>
                        </label>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>Month</th>
                                <th>kWh - Start Date </th>
                                <th>kWh - End Date</th>
                                <th>Billed kWh Units</th>
                                <th>kVAh - Start Date</th>
                                <th>kVAh - End Date</th>
                                <th>Billed kVAh Units</th>
                                <th>Cumulative Max Demand</th>
                                <th>Billed MD</th>
                                <th>No. of Resets</th>
                                <th>TOD kVAh consumption (Penalty Hours) </th>
                                <th>TOD kVAh consumption (Incentive Hours)</th>
                                <th>Total Bill (In Rupees)</th>
                                <th>Multiplying Factor (MF)</th>
                                <th>Contracted Max Demand (CMD)</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.length > 0 ? (
                                filteredData.map((item) => (
                                    <tr key={item._id}>
                                        <td>{item.month}</td>
                                        <td>{item.kWhOnFromDate}</td>
                                        <td>{item.kWhOnToDate}</td>
                                        <td>{item.Billed_kWh_units}</td>
                                        <td>{item.kVAhOnFromDate}</td>
                                        <td>{item.kVAhOnToDate}</td>
                                        <td>{item.Billed_kVAh_units}</td>
                                        <td>{item.CumulativeMaxDemand}</td>
                                        <td>{item.BilledMD}</td>
                                        <td>{item.NumberOfResets}</td>
                                        <td>{item.PenaltyTODkVAhConsumption}</td>
                                        <td>{item.IncentiveTODkVAhConsumption}</td>
                                        <td>{item.TotalBillInRupees}</td>
                                        <td>{item['Multiplying_actor_(MF)']}</td>
                                        <td>{item['Contracted_max_demand_(CMD)']}</td>
                                        <td><button onClick={() => handleEditClick(item)}>Edit</button></td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="15">No data available for selected filters.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>



                    <Popup open={isPopupOpen} onClose={() => setIsPopupOpen(false)} modal>
                        <div id='powerbillsfetch-popup'>
                            <h3>Edit Monthly Power Bill Form</h3>
                            {selectedLog && (
                                <form>
                                    <div id='powerbillsfetch-popup1'>
                                        <label>
                                            <span>Month<span id='all-mandetorymark'>*</span></span>
                                            <input type='month' name='month' value={selectedLog.month} onChange={handleChange} max={getCurrentMonth()}  />
                                        </label>
                                        <label>
                                            <span>kWh - Start Date<span id='all-mandetorymark'>*</span></span>
                                            <input type='number' name='kWhOnFromDate' value={selectedLog.kWhOnFromDate} onChange={handleChange}   maxLength="18"/>
                                            {errors.kWhOnFromDate && <span style={{ color: 'red' }}>{errors.kWhOnFromDate}</span>}
                                        </label>
                                    </div>
                                    <div id='powerbillsfetch-popup1'>
                                        <label>
                                            <span>kWh - End Date<span id='all-mandetorymark'>*</span></span>
                                            <input type='number' name='kWhOnToDate' value={selectedLog.kWhOnToDate} onChange={handleChange}  maxLength="18" />
                                            {errors.kWhOnToDate && <span style={{ color: 'red' }}>{errors.kWhOnToDate}</span>}
                                        </label>
                                        <label>
                                            <span>Billed kWh Units<span id='all-mandetorymark'>*</span></span>
                                            <input name='Billed_kWh_units' value={selectedLog.Billed_kWh_units} onChange={handleChange} disabled />
                                        </label>
                                    </div>
                                    <div id='powerbillsfetch-popup1'>
                                        <label>
                                            <span>kVAh - Start Date<span id='all-mandetorymark'>*</span></span>
                                            <input type='number' name='kVAhOnFromDate' value={selectedLog.kVAhOnFromDate} onChange={handleChange}  maxLength="18" />
                                            {errors.kVAhOnFromDate && <span style={{ color: 'red' }}>{errors.kVAhOnFromDate}</span>}
                                        </label>
                                        <label>
                                            <span>kVAh - End Date<span id='all-mandetorymark'>*</span></span>
                                            <input type='number' name='kVAhOnToDate' value={selectedLog.kVAhOnToDate} onChange={handleChange}  maxLength="18" />
                                            {errors.kVAhOnToDate && <span style={{ color: 'red' }}>{errors.kVAhOnToDate}</span>}
                                        </label>
                                    </div>
                                    <div id='powerbillsfetch-popup1'>
                                        <label>
                                            <span>Billed kVAh Units<span id='all-mandetorymark'>*</span></span>
                                            <input name='Billed_kVAh_units' value={selectedLog.Billed_kVAh_units} onChange={handleChange} disabled />
                                        </label>
                                        <label>
                                            <span>Cumulative Max Demand<span id='all-mandetorymark'>*</span></span>
                                            <input type='number' name='CumulativeMaxDemand' value={selectedLog.CumulativeMaxDemand} onChange={handleChange}   maxLength="18"/>
                                        </label>
                                    </div>
                                    <div id='powerbillsfetch-popup1'>
                                        <label>
                                            <span>Billed MD<span id='all-mandetorymark'>*</span></span>
                                            <input type='number' name='BilledMD' value={selectedLog.BilledMD} onChange={handleChange}  maxLength="11"  />
                                        </label>
                                        <label>
                                            <span>No. of Resets</span>
                                            <input type='number' name='NumberOfResets' value={selectedLog.NumberOfResets} onChange={handleChange}  maxLength="5" />
                                        </label>
                                    </div>
                                    <div id='powerbillsfetch-popup1'>
                                        <label>
                                            <span>TOD kVAh consumption (Penalty Hours) <span id='all-mandetorymark'>*</span></span>
                                            <input type='number' name='PenaltyTODkVAhConsumption' value={selectedLog.PenaltyTODkVAhConsumption} onChange={handleChange}  maxLength="18" />
                                        </label>
                                        <label>
                                            <span>TOD kVAh consumption (Incentive Hours)<span id='all-mandetorymark'>*</span></span>
                                            <input type='number' name='IncentiveTODkVAhConsumption' value={selectedLog.IncentiveTODkVAhConsumption} onChange={handleChange}  maxLength="18" />
                                        </label>
                                    </div>
                                    <div id='powerbillsfetch-popup1'>
                                        <label>
                                            <span>Total Bill (In Rupees)<span id='all-mandetorymark'>*</span></span>
                                            <input type='number' name='TotalBillInRupees' value={selectedLog.TotalBillInRupees} onChange={handleChange}  maxLength="18" />
                                        </label>
                                        <label>
                                            <span>Multiplying Factor (MF)</span>
                                            <input name='Multiplying_actor_(MF)' value={selectedLog['Multiplying_actor_(MF)']} onChange={handleChange} disabled />
                                        </label>
                                    </div>
                                    <div id='powerbillsfetch-popup1'>
                                        <label>
                                            <span>Contracted Max Demand (CMD)</span>
                                            <input name='Contracted_max_demand_(CMD)' value={selectedLog['Contracted_max_demand_(CMD)']} onChange={handleChange} disabled />
                                        </label>
                                    </div>
                                    <button id='powerbillsfetch-popup-update' type="button" onClick={handleUpdate}>Update</button>
                                </form>
                            )}
                        </div>
                    </Popup>
                </div>
            </div>
        </div >
    );
};

export default PowerBillsfetch;

