import React, { useState , useEffect} from 'react';
import './Power_Quality_Form.css';
import axios from 'axios';
import { Harmonicformheader } from '../../../Operator/Data';
import { Link , useParams , useNavigate} from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import { getCurrentDateTime } from '../../../Diagnose/dateUtils';
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const Power_Quality_Form = () => {
    const { token_id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        voltage_sags: '',
        voltage_swells: '',
        transients: '',
        radio_magnetic_interference: '',
        brown_out: '',
        token_id: token_id,
        createdBy: '',
        createdAt: '',
        modifiedBy: '',
        modifiedAt: ''
    });

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
      // Retrieve the logged-in user from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        setLoggedInUser(user);
      }
      setUserDesignation(user.level);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = {
                ...formData,
                dateandtime: getCurrentDateTime(),
                voltage_sags: Number(formData.voltage_sags),
                voltage_swells: Number(formData.voltage_swells),
                transients: Number(formData.transients),
                radio_magnetic_interference: Number(formData.radio_magnetic_interference),
                brown_out: Number(formData.brown_out),
            };

            const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/powerqualityforms`, dataToSend);

            console.log('Data posted successfully:', response.data);

            alert('Power Quality Form data added successfully');

            // Reset form fields after successful submission
            setFormData({
                voltage_sags: '',
                voltage_swells: '',
                transients: '',
                radio_magnetic_interference: '',
                brown_out: '',
                token_id:token_id,
            });
            navigate('/Power_Quality_Formfetch');
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <div id='power_quality_form-total'>
           <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />} 
            </div>
            <div id='power_quality_form-right'>
                <div id='power_quality_form-right-1'>
                    <div id='power_quality_form-heading'>
                        {Harmonicformheader.alertHeading}
                    </div>
                    <div id='power_quality_form-heading1'>
                        {Harmonicformheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Harmonicformheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <h3 id='power_quality_form-h3'>{Harmonicformheader.subalertHeading}</h3>
                <div id='power_quality_form-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='power_quality_form-divtop'>
                            <label>
                                <span>Power Quality Monitor</span>
                            </label>
                            {/* <label>
                                <Link to='/Power_Quality_Formfetch'><button type="button">View Data</button></Link>
                            </label> */}
                        </div>

                        <div id='power_quality_form-row'>
                            <label>
                                <span>Voltage Sags <span id='all-mandetorymark'>*</span></span>
                                <input
                                    type="number"
                                    name="voltage_sags"
                                    value={formData.voltage_sags}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label>
                                <span>Voltage Swells <span id='all-mandetorymark'>*</span></span>
                                <input
                                    type="number"
                                    name="voltage_swells"
                                    value={formData.voltage_swells}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                        </div>

                        <div id='power_quality_form-row'>
                            <label>
                                <span>Transients <span id='all-mandetorymark'>*</span></span>
                                <input
                                    type="number"
                                    name="transients"
                                    value={formData.transients}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label>
                                <span>Radio Magnetic Interference <span id='all-mandetorymark'>*</span></span>
                                <input
                                    type="number"
                                    name="radio_magnetic_interference"
                                    value={formData.radio_magnetic_interference}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                        </div>

                        <div id='power_quality_form-row'>
                            <label>
                                <span>Brown Out <span id='all-mandetorymark'>*</span></span>
                                <input
                                    type="number"
                                    name="brown_out"
                                    value={formData.brown_out}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                        </div>
                        <label>
                            <button id='power_quality_form-submit' type="submit">Submit</button>
                        </label>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Power_Quality_Form;
