
// import React, { useEffect, useState } from 'react';
// import { FaChevronDown, FaChevronUp } from "react-icons/fa";
// import DailyConsumptionprint from '../../Operator/DailyConsumption/Dailyconsumptionprint';
// import './Dailycomsptionreport.css';
// import Stationpoints from '../../Commonfns/Stationpoints';

// const Dailycomsptionreport = () => {
//     const [dailyconsumptionData, setDailyconsumptionData] = useState([]);
//     const [selectedDate, setSelectedDate] = useState(getYesterdayDate());
//     const [showHVdata, setshowHVdata] = useState(false);
//     const [showLV2data, setShowLV2data] = useState(false);
//     const [showProductiondata, setShowProductiondata] = useState(false);
//     const [showDG1data, setShowDG1data] = useState(false);
//     const [showDG2data, setShowDG2data] = useState(false);
//     const [section, setSection] = useState('');

//     function getYesterdayDate() {
//         const today = new Date();
//         today.setDate(today.getDate() - 1); // Subtract one day
//         const year = today.getFullYear();
//         const month = String(today.getMonth() + 1).padStart(2, '0');
//         const day = String(today.getDate()).padStart(2, '0');
//         return `${year}-${month}-${day}`;
//     }

//     const fetchDailyConsumptionData = async () => {
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/dailyconsumption`);
//             const data = await response.json();
//             if (Array.isArray(data)) {
//                 setDailyconsumptionData(data);
//             } else {
//                 console.error('Daily Consumption data is not an array:', data);
//             }
//         } catch (error) {
//             console.error('Error fetching Daily Consumption data:', error);
//         }
//     };

//     useEffect(() => {
//         fetchDailyConsumptionData();
//     }, [selectedDate]); // Update data when date changes

//     const handleDateChange = (e) => {
//         setSelectedDate(e.target.value);
//     };

//     const toggleshowHVdataData = () => {
//         setshowHVdata(!showHVdata);
//     };

//     const toggleLV2Data = () => {
//         setShowLV2data(!showLV2data);
//     };
//     const toggleProductionData = () => {
//         setShowProductiondata(!showProductiondata);
//     };
//     const toggleDG1Data = () => {
//         setShowDG1data(!showDG1data);
//     };
//     const toggleDG2Data = () => {
//         setShowDG2data(!showDG2data);
//     };

//     const handleStationPointChange = (selectedStationPoint) => {
//         setSection(selectedStationPoint);
//     };

//     const filteredData = dailyconsumptionData.filter(item => {
//         return item.Date === selectedDate && (section === '' || item.Section === section || (section === 'HV' && showHVdata && item.Section !== 'HV'));
//     });

//     return (
//         <div id='dailyconsumptionreport-right-2'>
//             <div id='dailyconsumptionreport-right-21'>
//                 <label >
//                     <Stationpoints onStationPointChange={handleStationPointChange} />
//                 </label>
//                 <label>
//                     <span>Select Date:</span>
//                     <input
//                         type="date"
//                         value={selectedDate}
//                         onChange={handleDateChange}
//                     />
//                 </label>
//                 <label>
//                     <DailyConsumptionprint filteredData={filteredData} />
//                 </label>
//             </div>
//             <div id='dailyconsumption-right-22'>
//                 <div id='dailyconsumption-right-221'>
//                     <div id='dailyconsumption-right-head'>Section</div>
//                     <div id='dailyconsumption-right-head1'>Date</div>
//                     <div id='dailyconsumption-right-head2'>kWh Consumption</div>
//                 </div>
//                 {filteredData
//                     .filter(item => item.Section === 'HV')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row'>
//                             <div id='dailyconsumption-right-data1'>{item.Section} <button id='dailyconsumption-dropbtn12' onClick={toggleshowHVdataData}>{showHVdata ? <FaChevronUp /> : <FaChevronDown />}</button></div>
//                             <div id='dailyconsumption-right-data2'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data3'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//                 {showHVdata && filteredData
//                     .filter(item => item.Section === 'Induction Heater')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row1'>
//                             <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//                 {showHVdata && filteredData
//                     .filter(item => item.Section === 'Compressor (11kw)')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row1'>
//                             <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//                 {showHVdata && dailyconsumptionData
//                             .filter(item => item.Date === selectedDate && item.Section === 'Cut Bend')
//                             .map((item, index) => (
//                                 <div key={index} className='dailyconsumption-data-row1'>
//                                     <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                                     <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                                     <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                                 </div>
//                             ))}
//                         {showHVdata && dailyconsumptionData
//                             .filter(item => item.Date === selectedDate && item.Section === 'Compressor (75kw)')
//                             .map((item, index) => (
//                                 <div key={index} className='dailyconsumption-data-row1'>
//                                     <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                                     <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                                     <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                                 </div>
//                             ))}
//                         {showHVdata && dailyconsumptionData
//                             .filter(item => item.Date === selectedDate && item.Section === 'Compressor (30kw)')
//                             .map((item, index) => (
//                                 <div key={index} className='dailyconsumption-data-row1'>
//                                     <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                                     <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                                     <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                                 </div>
//                             ))}
//                         {showHVdata && dailyconsumptionData
//                             .filter(item => item.Date === selectedDate && item.Section === 'Epoxy Coating Blower')
//                             .map((item, index) => (
//                                 <div key={index} className='dailyconsumption-data-row1'>
//                                     <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                                     <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                                     <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                                 </div>
//                             ))}
//                         {showHVdata && dailyconsumptionData
//                             .filter(item => item.Date === selectedDate && item.Section === 'Epoxy Coating Booth Stabilizer')
//                             .map((item, index) => (
//                                 <div key={index} className='dailyconsumption-data-row1'>
//                                     <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                                     <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                                     <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                                 </div>
//                             ))}
//                         {showHVdata && dailyconsumptionData
//                             .filter(item => item.Date === selectedDate && item.Section === 'Shot Blasting-1')
//                             .map((item, index) => (
//                                 <div key={index} className='dailyconsumption-data-row1'>
//                                     <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                                     <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                                     <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                                 </div>
//                             ))}
//                         {showHVdata && dailyconsumptionData
//                             .filter(item => item.Date === selectedDate && item.Section === 'Shot Blasting-2')
//                             .map((item, index) => (
//                                 <div key={index} className='dailyconsumption-data-row1'>
//                                     <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                                     <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                                     <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                                 </div>
//                             ))}
//                         {showHVdata && dailyconsumptionData
//                             .filter(item => item.Date === selectedDate && item.Section === 'Pump House')
//                             .map((item, index) => (
//                                 <div key={index} className='dailyconsumption-data-row1'>
//                                     <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                                     <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                                     <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                                 </div>
//                             ))}
//                         {showHVdata && dailyconsumptionData
//                             .filter(item => item.Date === selectedDate && item.Section === 'Work Conveyor Panel')
//                             .map((item, index) => (
//                                 <div key={index} className='dailyconsumption-data-row1'>
//                                     <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                                     <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                                     <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                                 </div>
//                             ))}
//                         {showHVdata && dailyconsumptionData
//                             .filter(item => item.Date === selectedDate && item.Section === 'Lighting Panel ')
//                             .map((item, index) => (
//                                 <div key={index} className='dailyconsumption-data-row1'>
//                                     <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                                     <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                                     <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                                 </div>
//                             ))}
//                         {showHVdata && dailyconsumptionData
//                             .filter(item => item.Date === selectedDate && item.Section === 'Utility Panel')
//                             .map((item, index) => (
//                                 <div key={index} className='dailyconsumption-data-row1'>
//                                     <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                                     <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                                     <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                                 </div>
//                             ))}


//                 {filteredData
//                     .filter(item => item.Section === 'DG 62.5 KVA')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row'>
//                             <div id='dailyconsumption-right-data1'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data2'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data3'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//                 {filteredData
//                     .filter(item => item.Section === 'Solar 734.66 KVA')
//                     .map((item, index) => (
//                         <div key={index} className='dailyconsumption-data-row'>
//                             <div id='dailyconsumption-right-data1'>{item.Section}</div>
//                             <div id='dailyconsumption-right-data2'>{item.Date}</div>
//                             <div id='dailyconsumption-right-data3'>{item.KWh_Consumption}</div>
//                         </div>
//                     ))}
//             </div>
//         </div>
//     )
// }

// export default Dailycomsptionreport;




// import React, { useEffect, useState } from 'react';
// import { FaChevronDown, FaChevronUp } from "react-icons/fa";
// import DailyConsumptionprint from '../../Operator/DailyConsumption/Dailyconsumptionprint';
// import './Dailycomsptionreport.css';
// import Stationpoints from '../../Commonfns/Stationpoints';
// import axios from 'axios';
// const Dailycomsptionreport = () => {
//     const [dailyconsumptionData, setDailyconsumptionData] = useState([]);
//     const [selectedDate, setSelectedDate] = useState(getYesterdayDate());
//     const [expandedSection, setExpandedSection] = useState(''); // Store currently expanded section
//     const [section, setSection] = useState('');  // Store selected section

//     // Define parent-child relationships for sections
//     const sectionHierarchy = {
//         'HV': [
//             'Induction Heater',
//             'Compressor (11kw)',
//             'Compressor (75kw)',
//             'Compressor (30kw)',
//             'Epoxy Coating Blower',
//             'Epoxy Coating Booth Stabilizer',
//             'Shot Blasting-1',
//             'Shot Blasting-2',
//             'Pump House',
//             'Work Conveyor Panel',
//             'Lighting Panel',
//             'Utility Panel',
//         ],
//         'DG 62.5 KVA': [],
//         'Solar 734.66 KVA': [],
//     };

//     function getYesterdayDate() {
//         const today = new Date();
//         today.setDate(today.getDate() - 1); // Subtract one day
//         const year = today.getFullYear();
//         const month = String(today.getMonth() + 1).padStart(2, '0');
//         const day = String(today.getDate()).padStart(2, '0');
//         return `${year}-${month}-${day}`;
//     }

//     const fetchDailyConsumptionData = async (date, section) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/dailyconsumption-filter`, {
//                 params: { date, section } // Passing selected date and section as query parameters
//             });
//             const data = response.data;
//             if (Array.isArray(data)) {
//                 setDailyconsumptionData(data);
//             } else {
//                 console.error('Daily Consumption data is not an array:', data);
//             }
//         } catch (error) {
//             console.error('Error fetching Daily Consumption data:', error);
//         }
//     };

//     useEffect(() => {
//         fetchDailyConsumptionData(selectedDate, section);  // Fetch data with both date and section
//     }, [selectedDate, section]);  // Fetch data when date or section changes

//     const handleDateChange = (e) => {
//         setSelectedDate(e.target.value);
//     };

//     const toggleSection = (parentSection) => {
//         // If the clicked section is already expanded, collapse it. Otherwise, expand it and collapse any other expanded section.
//         setExpandedSection((prev) => prev === parentSection ? '' : parentSection);
//     };

//     const handleStationPointChange = (selectedStationPoint) => {
//         setSection(selectedStationPoint);  // Update section when selected

//         // Automatically expand the parent section if a child is selected
//         const parentSection = Object.keys(sectionHierarchy).find(parent =>
//             sectionHierarchy[parent].includes(selectedStationPoint)
//         );

//         if (parentSection) {
//             setExpandedSection(parentSection); // Automatically expand the parent section
//         }
//     };
//     return (
//         <div id='dailyconsumptionreport-right-2'>
//             <div id='dailyconsumptionreport-right-21'>
              
//                 <label>
//                     <span>Select Date:</span>
//                     <input
//                         type="date"
//                         value={selectedDate}
//                         onChange={handleDateChange}
//                         max={getYesterdayDate()}
//                     />
//                 </label>
//                 <label >
//                     <Stationpoints onStationPointChange={handleStationPointChange} />
//                 </label>
//                 <label>
//                     <DailyConsumptionprint filteredData={dailyconsumptionData} />
//                 </label>
//             </div>
//             <div id='dailyconsumption-right-22'>
//                 <div id='dailyconsumption-right-221'>
//                     <div id='dailyconsumption-right-head'>Section</div>
//                     <div id='dailyconsumption-right-head1'>Date</div>
//                     <div id='dailyconsumption-right-head2'>kWh Consumption</div>
//                 </div>
//                 {/* Loop through the parent sections */}
//                 {Object.keys(sectionHierarchy).map((parentSection) => (
//                     <div key={parentSection}>
//                         {/* Parent Section */}
//                         {dailyconsumptionData
//                             .filter(item => item.Section === parentSection)
//                             .map((item, index) => (
//                                 <div key={index} className='dailyconsumption-data-row'>
//                                     <div id='dailyconsumption-right-data1'>
//                                         {item.Section}
//                                         {/* Only show dropdown icon if there are child sections */}
//                                         {sectionHierarchy[parentSection].length > 0 && (
//                                             <button
//                                                 id='dailyconsumption-dropbtn12'
//                                                 onClick={() => toggleSection(parentSection)}
//                                             >
//                                                 {expandedSection === parentSection ? <FaChevronUp /> : <FaChevronDown />}
//                                             </button>
//                                         )}
//                                     </div>
//                                     <div id='dailyconsumption-right-data2'>{item.Date}</div>
//                                     <div id='dailyconsumption-right-data3'>{item.KWh_Consumption}</div>
//                                 </div>
//                             ))}

//                         {/* Child Sections under each Parent */}
//                         {expandedSection === parentSection && sectionHierarchy[parentSection].map((childSection) =>
//                             dailyconsumptionData
//                                 .filter(item => item.Section === childSection)
//                                 .map((item, index) => (
//                                     <div key={index} className='dailyconsumption-data-row1'>
//                                         <div id='dailyconsumption-right-data12'>{item.Section}</div>
//                                         <div id='dailyconsumption-right-data22'>{item.Date}</div>
//                                         <div id='dailyconsumption-right-data32'>{item.KWh_Consumption}</div>
//                                     </div>
//                                 ))
//                         )}
//                     </div>
//                 ))}
//             </div>
//         </div>
//     )
// }

// export default Dailycomsptionreport;







import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaChevronRight , FaChevronUp } from "react-icons/fa";
import Stationpoints from "../../Commonfns/Stationpoints"; // StationPoint dropdown
import "../../Operator/DailyConsumption/DailyConsumption.css";
import { FaFileCsv } from "react-icons/fa6";
import './Dailycomsptionreport.css';
const Dailycomsptionreport = () => {
  const [sections, setSections] = useState([]);
  const [expandedSections, setExpandedSections] = useState({});
  const [sectionFilter, setSectionFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");

  function getYesterdayDate() {
    const today = new Date();
    today.setDate(today.getDate() - 1); // Subtract one day
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    fetchData();
  }, [sectionFilter, dateFilter]);

  const fetchData = () => {
    const queryParams = new URLSearchParams();
    if (sectionFilter) queryParams.append("sectionName", sectionFilter);
    if (dateFilter) queryParams.append("date", dateFilter);

    axios
      .get(
        `${
          process.env.REACT_APP_API_EMS_URL
        }/api/sections-data?${queryParams.toString()}`
      )
      .then((response) => {
        setSections(response.data);
        if (sectionFilter) expandParents(response.data, sectionFilter); // Auto-expand parents
      })
      .catch((error) => {
        console.error("Error fetching sections data:", error);
      });
  };

  const handleStationPointChange = (selectedStationPoint) => {
    setSectionFilter(selectedStationPoint);
  };

  const handleDateChange = (event) => {
    setDateFilter(event.target.value);
  };

  const toggleSection = (sectionName) => {
    setExpandedSections((prev) => ({
      ...prev,
      [sectionName]: !prev[sectionName],
    }));
  };

  const expandParents = (allSections, childName) => {
    const findParent = (sections, child) =>
      sections.find((section) =>
        section.childs.some((c) => c.section_name === child)
      );

    let parent = findParent(allSections, childName);
    const newExpandedSections = {};

    while (parent) {
      newExpandedSections[parent.section_name] = true;
      parent = findParent(allSections, parent.section_name);
    }

    setExpandedSections((prev) => ({ ...prev, ...newExpandedSections }));
  };

  const flattenSections = (sections) => {
    const flatData = [];

    const flatten = (section, parentName = "") => {
      flatData.push({
        section_name: section.section_name,
        parent_name: parentName,
        date: section.date,
        consumption: section.value ?? "--",
      });

      section.childs.forEach((child) => flatten(child, section.section_name));
    };

    sections.forEach((section) => flatten(section));
    return flatData;
  };

  const downloadCSV = () => {
    const flatData = flattenSections(sections);

    const csvContent =
      "data:text/csv;charset=utf-8," +
      ["Section Name,Parent Name,Date,Consumption"]
        .concat(
          flatData.map(
            (row) =>
              `${row.section_name},${row.parent_name},${row.date},${row.consumption}`
          )
        )
        .join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "section_data.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
    document.body.removeChild(link);
  };

//   const renderRow = (section, level = 0) => {
//     const isExpanded = expandedSections[section.section_name];

//     return (
//       <React.Fragment key={section.section_name}>
//         <div className={`daily-consumpton-tr ${isExpanded ? "expanded" : ""}`}>
//           <div className="chevron-container">
//             {section.childs.length > 0 ? (
//               <div onClick={() => toggleSection(section.section_name)}>
//                 {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
//               </div>
//             ) : (
//               <div style={{ width: "16px" }}></div> // Empty space for alignment
//             )}
//           </div>
//           <div className="daily-consyumptoon-t1"
//           >{section.section_name}</div>
//           <div className="date-column">{section.date}</div>
//           <div className="consumption-column">{section.value ?? "--"}</div>
//         </div>
//         {isExpanded &&
//           section.childs.map((child) => renderRow(child, level + 1))}
//       </React.Fragment>
//     );
//   };

const renderRow = (section, level = 0) => {
    const isExpanded = expandedSections[section.section_name];
  
    return (
      <React.Fragment key={section.section_name}>
        <div
          className={`daily-consumpton-tr ${isExpanded ? "expanded" : ""} level-${level + 1}`}
        >
          <div className="chevron-container">
            {section.childs.length > 0 ? (
              <div onClick={() => toggleSection(section.section_name)}>
                {isExpanded ? <FaChevronUp  /> : <FaChevronRight  />}
              </div>
            ) : (
              <div style={{ width: "16px" }}></div> // Empty space for alignment
            )}
          </div>
          <div className="daily-consyumptoon-t1">{section.section_name}</div>
          <div className="date-column">{section.date}</div>
          <div className="consumption-column">{section.value ?? "--"}</div>
        </div>
        {isExpanded &&
          section.childs.map((child) => renderRow(child, level + 1))}
      </React.Fragment>
    );
  };
  

  return (
 
    <div id='dailyconsumptionreport-right-2'>
            <div id='dailyconsumptionreport-right-21'>
            <label>
              <span>Select Date:</span>
              <input
                type="date"
                onChange={handleDateChange}
                max={getYesterdayDate()}
              />
            </label>
            <label>
              <Stationpoints onStationPointChange={handleStationPointChange} />
            </label>
            <label>
              <button onClick={downloadCSV}><FaFileCsv /></button>
            </label>
          </div>
          <div id="dailyconsumption-right-22">
            <div id="dailyconsumption-right-221">
              <div className="chevron-container"></div>
              <div className="daily-consyumptoon-t1">Section Name</div>
              <div className="date-column">Date</div>
              <div className="consumption-column">kWh Consumption</div>
            </div>

            <div className="section-body">
              {sections.map((section) => renderRow(section))}
            </div>
          </div>
        </div>

  );
};

export default Dailycomsptionreport;
