import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import { Harmonicformheader } from '../../../Operator/Data'; // Adjust import as needed
import { Link } from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import './Power_Quality_Formfetch.css'; 
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const Power_Quality_Formfetch = () => {
    const [factors, setFactors] = useState([]);
    const [factorEditData, setFactorEditData] = useState(null);

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
      // Retrieve the logged-in user from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        setLoggedInUser(user);
      }
      setUserDesignation(user.level);
    }, []);

    const fetchFactors = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/powerqualityforms`); // Adjust endpoint
            setFactors(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchFactors();
    }, []);

    const handleEditClick = (factor) => {
        setFactorEditData(factor);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setFactorEditData({ ...factorEditData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/powerqualityforms/${factorEditData._id}`, factorEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchFactors();
            setFactorEditData(null);
            alert("Power Quality Form updated successfully");
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='power_quality_formfetch-total'>
           <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />} 
            </div>
            <div id='power_quality_formfetch-right'>
                <div id='power_quality_formfetch-right-1'>
                    <div id='power_quality_formfetch-heading'>
                        {Harmonicformheader.alertHeading}
                    </div>
                    <div id='power_quality_formfetch-heading1'>
                        {Harmonicformheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Harmonicformheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <div id='power_quality_formfetch-right-2'>
                    <div id='power_quality_formfetch-right-21'>
                    <label>;
                                <span style={{fontWeight:'bold'}}>Power Quality Monitor</span>
                            </label>
                        <label>
                            <Link to='/Power_Quality_Form'><button>Form</button></Link> {/* Adjust route as needed */}
                        </label>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Token ID</th>
                                <th>Date and Time</th>
                                <th>Voltage Sags</th>
                                <th>Voltage Swells</th>
                                <th>Transients</th>
                                <th>Radio Magnetic Interference</th>
                                <th>Brown Out</th>
                                {/* <th>Created By</th>
                                <th>Created At</th>
                                <th>Modified By</th>
                                <th>Modified At</th> */}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {factors.map((factor) => (
                                <tr key={factor._id}>
                                    <td>{factor.token_id}</td>
                                    <td>{factor.dateandtime}</td>
                                    <td>{factor.voltage_sags}</td>
                                    <td>{factor.voltage_swells}</td>
                                    <td>{factor.transients}</td>
                                    <td>{factor.radio_magnetic_interference}</td>
                                    <td>{factor.brown_out}</td>
                                    {/* <td>{factor.createdby}</td>
                                    <td>{factor.createdat}</td>
                                    <td>{factor.modifiedby}</td>
                                    <td>{factor.modifiedat}</td> */}
                                    <td>
                                        <button onClick={() => handleEditClick(factor)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {factorEditData && (
                        <Popup open={true} onClose={() => setFactorEditData(null)}>
                            <div id='power_quality_formfetch-popup'>
                                <h3>Edit Power Quality Form</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='power_quality_formfetch-popup1'>
                                        <label>
                                            <span>Token ID</span>
                                            <input type="text" name="token_id" value={factorEditData.token_id} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Date and Time</span>
                                            <input type="text" name="dateandtime" value={factorEditData.dateandtime} onChange={handleEditChange} disabled />
                                        </label>
                                    </div>
                                    <div id='power_quality_formfetch-popup1'>
                                        <label>
                                            <span>Voltage Sags <span id='all-mandetorymark'>*</span></span>
                                            <input type="number" name="voltage_sags" value={factorEditData.voltage_sags} onChange={handleEditChange} required/>
                                        </label>
                                        <label>
                                            <span>Voltage Swells <span id='all-mandetorymark'>*</span></span>
                                            <input type="number" name="voltage_swells" value={factorEditData.voltage_swells} onChange={handleEditChange} required/>
                                        </label>
                                    </div>
                                    <div id='power_quality_formfetch-popup1'>
                                        <label>
                                            <span>Transients <span id='all-mandetorymark'>*</span></span>
                                            <input type="number" name="transients" value={factorEditData.transients} onChange={handleEditChange} required />
                                        </label>
                                        <label>
                                            <span>Radio Magnetic Interference <span id='all-mandetorymark'>*</span></span>
                                            <input type="number" name="radio_magnetic_interference" value={factorEditData.radio_magnetic_interference} onChange={handleEditChange}  required/>
                                        </label>
                                    </div>
                                    <div id='power_quality_formfetch-popup1'>
                                        <label>
                                            <span>Brown Out <span id='all-mandetorymark'>*</span></span>
                                            <input type="number" name="brown_out" value={factorEditData.brown_out} onChange={handleEditChange} required />
                                        </label>
                                        {/* <label>
                                            <span>Created By</span>
                                            <input type="text" name="createdby" value={factorEditData.createdby} onChange={handleEditChange} disabled />
                                        </label> */}
                                    </div>
                                    {/* <div id='power_quality_formfetch-popup1'>
                                        <label>
                                            <span>Created At</span>
                                            <input type="text" name="createdat" value={factorEditData.createdat} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Modified By</span>
                                            <input type="text" name="modifiedby" value={factorEditData.modifiedby} onChange={handleEditChange} disabled />
                                        </label>
                                    </div>
                                    <div id='power_quality_formfetch-popup1'>
                                        <label>
                                            <span>Modified At</span>
                                            <input type="text" name="modifiedat" value={factorEditData.modifiedat} onChange={handleEditChange} disabled />
                                        </label>
                                    </div> */}
                                    <button id='power_quality_formfetch-popup-update' type="submit">Update</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Power_Quality_Formfetch;
