import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Todotasks = () => {
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        const fetchTODoTasks = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/assignedtasks/todo`);
                setTasks(response.data);
            } catch (error) {
                console.error('Error fetching To-do tasks', error);
            }
        };

        fetchTODoTasks();
    }, []);

    return (
        <div id="todo-total">
            <div id="todo-right">
                <div id="todo-right-1">
                    <div id="todo-heading">To do  Tasks</div>
                </div>
                <div id="todo-right-2">
                    {tasks.length === 0 ? (
                        <div>No tasks to show.</div>
                    ) : (
                        <table>
                            <thead>
                                <tr>
                                    <th>Task ID</th>
                                    <th>Token ID</th>
                                    <th>Alert Type</th>
                                    <th>Assigned To</th>
                                    <th>Created By</th>
                                    <th>Created At</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tasks.map((task) => (
                                    <tr key={task._id}>
                                        <td>{task.task_id}</td>
                                        <td>{task.token_id}</td>
                                        <td>{task.alert_heading}</td>
                                        <td>{task.assignedto}</td>
                                        <td>{task.createdby}</td>
                                        <td>{task.createdat}</td>
                                        <td>{task.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Todotasks;