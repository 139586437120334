

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import './DashCalender.css';
import { Link } from 'react-router-dom';
const DashCalendar = () => {
    const [events, setEvents] = useState([]);
    const calendarRef = useRef(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/calenderevents/hv-current-month`);
            const formattedEvents = response.data.map(event => ({
                title: `Event on ${event.date}`,
                start: event.date,
                extendedProps: {
                    Section: event.stationPoint,
                    energyIntensity: event['Energy_Intensity'] || 'N/A',
                    md: event.Maximum_Demand_kVA || 'N/A',
                    pf: event.PF || 'N/A',
                    production: event.Production || 'N/A',
                    runTime: event.Runtime || 'N/A',
                    utilization: event['Utilization_%'] || 'N/A',
                    kWh: event.KWH_consumption || 'N/A'
                }
            }));
            setEvents(formattedEvents); // Set events initially without filtering
        } catch (error) {
            console.error('Error fetching calendar events:', error);
        }
    };

    const renderEventContent = (eventInfo) => {
        const { title } = eventInfo.event;
        const { Section, energyIntensity, md, pf, production, runTime, utilization, kWh } = eventInfo.event.extendedProps;

        const renderValue = (value) => value === null ? 'N/A' : value;

        return (
            <div id='dashcalenderevents-fulcaltop'>
                <label>kWh: {renderValue(kWh)}</label>
                <span>Section: {renderValue(Section)}</span>
                <span>Energy Intensity: {renderValue(energyIntensity)}</span>
                <span>MD: {renderValue(md)}</span>
                <span>PF: {renderValue(pf)}</span>
                <span>Production: {renderValue(production)}</span>
                <span>Run Time: {renderValue(runTime)}</span>
                <span>Utilization (%): {renderValue(utilization)}</span>
            </div>
        );
    };

    return (
        <div id='dashcalenderevents-total'>
            <div id='dashcalenderevents-topdiv'>
                <label>
                   <span>Monthly Calender</span> 
                </label>
                <label>
                   <Link to='/Calenderscombine'><button>View More</button></Link> 
                </label>
            </div>
            <div id='dashcalenderevents-fulcal'>
                <FullCalendar
                    ref={calendarRef}
                    initialView="dayGridMonth"
                    headerToolbar={{
                        right: 'today',
                        left: 'title',
                    }}
                    themeSystem="Simplex"
                    plugins={[dayGridPlugin, interactionPlugin]}
                    events={events}
                    eventContent={renderEventContent}
                    dateClick={(info) => console.log(`Date clicked: ${info.dateStr}`)}
                    datesSet={(info) => console.log(`Current view dates: ${info.startStr} to ${info.endStr}`)}
                />
            </div>
        </div>
    );
};

export default DashCalendar;
