

import React, { useState, useEffect } from 'react';

const RCPEReport = () => {
  const getCurrentMonth = () => {
    const today = new Date();
    return today.toISOString().slice(0, 7); // Format: 'YYYY-MM'
  };

  const [month, setMonth] = useState(getCurrentMonth());
  const [reportData, setReportData] = useState(null); // Default to null to avoid accessing undefined data

  const fetchReportData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/reports/rcpe?month=${month}`);
      const data = await response.json();
      setReportData(data);
    } catch (error) {
      console.error('Error fetching report data:', error);
    }
  };

  useEffect(() => {
    fetchReportData();
  }, [month]);

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  if (!reportData) {
    return <div>Loading...</div>; // Show a loading message while data is being fetched
  }

  const safeJoin = (data) => (Array.isArray(data) ? data.join(', ') : 'N/A'); // Helper function to safely join arrays

  return (
    <div className="rcpe-report-container">
      <h1>RCPE Report for {month}</h1>
      
      <label>
        <span>Select Month: </span>
        <input
          type="month"
          value={month}
          onChange={handleMonthChange}
          max={getCurrentMonth()} // Prevent selecting future months
        />
      </label>

      <div className="report-section">
        <h2>Energy Intensity</h2>
        <p>Base Value: {reportData.energy_intensity?.base_value || 'N/A'}</p>
        <p>Date Labels: {safeJoin(reportData.energy_intensity?.date_labels)}</p>
        <p>Values: {safeJoin(reportData.energy_intensity?.values)}</p>
      </div>

      <div className="report-section">
        <h2>Run Hours</h2>
        <p>Date Labels: {safeJoin(reportData.run_hours?.date_labels)}</p>
        <p>Values: {safeJoin(reportData.run_hours?.values)}</p>
      </div>

      <div className="report-section">
        <h2>Consumption</h2>
        <p>Date Labels: {safeJoin(reportData.consumption?.date_labels)}</p>
        <p>Values: {safeJoin(reportData.consumption?.values)}</p>
      </div>

      <div className="report-section">
        <h2>Production</h2>
        <p>Date Labels: {safeJoin(reportData.production?.date_labels)}</p>
        <p>Values: {safeJoin(reportData.production?.values)}</p>
      </div>

      <div className="report-section">
        <h2>Production vs Run Hours</h2>
        <p>Date Labels: {safeJoin(reportData.productionvsrunhours?.date_labels)}</p>
        <p>Production Values: {safeJoin(reportData.productionvsrunhours?.prodcution_values)}</p>
        <p>Run Hours Values: {safeJoin(reportData.productionvsrunhours?.run_hours_values)}</p>
      </div>
    </div>
  );
};

export default RCPEReport;
