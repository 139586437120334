import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Import Link for routing
import './Newunfilledforms.css'
import YesterdayDataUnfilledforms from '../DashPending_Task/Unfilledforms/Yesterdayunfilledforms';
import MonthlyUnfilledforms from '../DashPending_Task/Unfilledforms/Monthlyunfilledforms';
const Newunfilledforms = ({ setUnfilledFormsCount }) => {

    const [yesterdayCount, setYesterdayCount] = useState(0);
  const [monthlyCount, setMonthlyCount] = useState(0);

  useEffect(() => {
    if (typeof setUnfilledFormsCount === 'function') {
      setUnfilledFormsCount(yesterdayCount + monthlyCount);
    } else {
      console.error("setUnfilledFormsCount is not a function");
    }
  }, [yesterdayCount, monthlyCount, setUnfilledFormsCount]);


    return (
        <div>
            <div id='newpending-right-21'>
                <div id='newpending-topone'>
                    <label>
                        <span id='newpending-alert'>Pending Tasks  ({yesterdayCount + monthlyCount}) </span>
                    </label>
                    <label>
                        <Link to='/Forms'>
                            <button id='newpending-viewmore'>View More</button>
                        </Link>
                    </label>
                </div>
                <div id='newpending-right-211'>
                <YesterdayDataUnfilledforms setYesterdayCount={setYesterdayCount} />
                <MonthlyUnfilledforms setMonthlyCount={setMonthlyCount} />
                </div>

            </div>
        </div>
    );
};

export default Newunfilledforms;
