import React, { useState , useEffect } from 'react';
import './Hotspot_Test_Form.css';
import axios from 'axios';
import { Phaseimbalanceheader } from '../../../Operator/Data';
import { Link , useParams , useNavigate} from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import { getCurrentDateTime } from '../../../Diagnose/dateUtils';
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const Hotspot_Test_Form = () => {
    const { token_id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        machines_where_hotspots_found: '',
        createdby: '',
        createdat: '',
        modifiedby: '',
        modifiedat: '',
        token_id:token_id,
    });

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
      // Retrieve the logged-in user from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        setLoggedInUser(user);
      }
      setUserDesignation(user.level);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = {
                ...formData,
                dateandtime: getCurrentDateTime(),
            };

            const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/hotspottestforms`, dataToSend);

            console.log('Data posted successfully:', response.data);

            alert('Hotspot Test Form data added successfully');

            // Reset form fields after successful submission
            setFormData({
                machines_where_hotspots_found: '',
                createdby: '',
                createdat: '',
                modifiedby: '',
                modifiedat: '',
                token_id:token_id,
            });
            navigate('/Hotspot_Test_Formfetch');

        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <div id='hotspot_test_form-total'>
             <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />} 
            </div>
            <div id='hotspot_test_form-right'>
                <div id='hotspot_test_form-right-1'>
                    <div id='hotspot_test_form-heading'>
                        {Phaseimbalanceheader.alertHeading}
                    </div>
                    <div id='hotspot_test_form-heading1'>
                        {Phaseimbalanceheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Phaseimbalanceheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <h3 id='hotspot_test_form-h3'>{Phaseimbalanceheader.subalertHeading}</h3>
                <div id='hotspot_test_form-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='hotspot_test_form-divtop'>
                            <label>
                                <span>Hotspot Test Monitor</span>
                            </label>
                            {/* <label>
                                <Link to='/Hotspot_Test_Formfetch'><button type="button">View Data</button></Link>
                            </label> */}
                        </div>

                        <div id='hotspot_test_form-row'>
                            <label>
                                <span>Machines Where Hotspots Found <span id='all-mandetorymark'>*</span></span>
                                <input
                                    type="text"
                                    name="machines_where_hotspots_found"
                                    value={formData.machines_where_hotspots_found}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                           
                        </div>
                        <label>
                            <button id='hotspot_test_form-submit' type="submit">Submit</button>
                        </label>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Hotspot_Test_Form;
