import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Userheader } from '../../Operator/Data';
import './Usercreationfetch.css'
import Superadminsidebar from '../../Superadmin/Sidebar/Sidebar';
import Adminsidebar from '../../Adminsidebar/Adminsidebar';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 
import TopBar from '../../Commonfns/Topbar/Topbar';
const Userfetch = () => {
  const [users, setUsers] = useState([]);
  const [editData, setEditData] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [loggedInUser, setLoggedInUser] = useState(null); // State to hold logged-in user details
  const [designations, setDesignations] = useState([]);
  const [userDesignation, setUserDesignation] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  useEffect(() => {
    fetchDesignations();
    fetchUsers();
    // Fetch logged-in user information from local storage or context
    const user = JSON.parse(localStorage.getItem('user'));
    setLoggedInUser(user);
    setUserDesignation(user.level);
  }, []);

  const fetchDesignations = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/designationsotherthanlevel1`);
      setDesignations(response.data);
    } catch (error) {
      console.error('Error fetching designations:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/users`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleEditClick = (user) => {
    setEditData(user);
    setValidationErrors({});
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...editData, [name]: value };
  
    // Update state with the new value
    setEditData(updatedData);
  
    // Reset the validation errors for the current field
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  
    // Perform validation for username
    if (name === 'username') {
      const usernameRegex = /^[a-z0-9]+$/; // Only lowercase letters and numbers
      if (!usernameRegex.test(value)) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          username: 'Username should only contain lowercase letters and numbers.',
        }));
      }
    }
  
    // Perform validation for password and confirm password
    if (name === 'password' || name === 'confirmpassword') {
      const password = name === 'password' ? value : updatedData.password;
      const confirmPassword = name === 'confirmpassword' ? value : updatedData.confirmpassword;
  
      // Check if password is same as username
      if (password === updatedData.username) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          password: 'Password should not be the same as Username.',
        }));
      }
  
      // Check if passwords match
      if (confirmPassword && confirmPassword !== password) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          confirmpassword: 'Passwords do not match. Please re-enter.',
        }));
      } else {
        // Clear confirm password error if they match
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          confirmpassword: '',
        }));
      }
    }
  };
  
  
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    // Check if there are any validation errors
    for (const key in validationErrors) {
      if (validationErrors[key]) {
        toast.error("Please fix all errors before submitting.");
        return;
      }
    }


    // Set modifiedby field to logged-in user's username
    const updatedUserData = {
      ...editData,
      modifiedby: loggedInUser.username // Update modifiedby field
    };

    try {
      // console.log('Submitting Edit Data:', updatedUserData);
      const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/users/${editData._id}`, updatedUserData);
      // console.log('Response:', response.data);
      fetchUsers();
      setEditData(null);
      toast.success("User updated successfully");
    } catch (error) {
      console.error('Error updating user:', error.response ? error.response.data : error.message);
      if (error.response && error.response.data.message === 'Username must be unique') {
        toast.error('Username already exists. Please choose a different username.');
      } else {
        toast.error('Failed to update user. Please try again later.');
      }
    }
  };

  const handleToggleStatus = async (id, currentStatus) => {
    try {
      const updatedUserData = {
        status: !currentStatus,
        modifiedby: loggedInUser.username // Update modifiedby field with logged-in user's username
      };

      const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/users/${id}/toggle-status`, updatedUserData);
      // console.log('Toggle status response:', response.data);

      fetchUsers(); // Assuming fetchUsers() fetches the updated list of users
    } catch (error) {
      console.error('Error toggling status:', error.response ? error.response.data : error.message);
    }
  };


  const togglePasswordVisibility = () => setShowPassword(prev => !prev);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(prev => !prev);



  return (


    <div id='userfetch-total'>
      <div>
      {/* {userDesignation === 'Level-1' ? <Superadminsidebar /> : <Adminsidebar />} */}
      <Superadminsidebar /> 
      </div>
      <div id='userfetch-right'>
      <TopBar section="AdduserHeading" />
        {/* <div id='userfetch-right-1'>
          <div id='userfetch-heading'>
            {Userheader.alertHeading}
          </div>
          <div id='userfetch-heading1'>
            {Userheader.alertActions.map((action, index) => (
              <button key={index} aria-label={action.label}>
                {action.icon}
              </button>
            ))}
            <img src={Userheader.logoSrc} alt='logo' />
          </div>
        </div> */}
        <div id='userfetch-right-2'>
          <div id='userfetch-right-21'>
            <label>
              <Link to='/Usercreation'><button>Form</button></Link>
            </label>
          </div>
          <table>
            <thead>
              <tr>
                <th>Designation</th>
                <th>Name</th>
                <th>Username</th>
                <th>Password</th>
                <th>Confirm Password</th>
                <th>Status</th>
                <th>Created At</th>
                <th>Created By</th>
                <th>Modified At</th>
                <th>Modified By</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user.designation}</td>
                  <td>{user.name}</td>
                  <td>{user.username}</td>
                  <td>{user.password}</td>
                  <td>{user.confirmpassword}</td>
                  <td>
                    <button onClick={() => handleToggleStatus(user._id, user.status)}>
                      {user.status ? 'Active' : 'Inactive'}
                    </button>
                  </td>
                  <td>{user.createdat}</td>
                  <td>{user.createdby}</td>
                  <td>{user.modifiedat}</td>
                  <td>{user.modifiedby}</td>
                  <td>
                    <button onClick={() => handleEditClick(user)}>Edit</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {editData && (
            <Popup open={true} onClose={() => setEditData(null)}>
              <div id='userfetch-popup'>
                <h3>Edit User</h3>
                <form onSubmit={handleEditSubmit}>
                  <div id='userfetch-popup1'>
                    <label>
                      <span>Designation</span>
                      <select name="designation" value={editData.designation} onChange={handleEditChange} required>
                        <option value="">Select Designation</option>
                        {designations.map((desig) => (
                          <option key={desig._id} value={desig.designation}>
                            {desig.designation}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                  <div id='userfetch-popup1'>
                  <label>
                      <span>Name</span>
                      <input id='userfetch-popup1-input1' type="text" name="name" value={editData.name} onChange={handleEditChange} required />
                    </label>
                    <label>
                      <span>Username</span>
                      <input id='userfetch-popup1-input1' type="text" name="username" value={editData.username} onChange={handleEditChange} required />
                      {validationErrors.username && <div className="userfetch-errormessage">{validationErrors.username}</div>}
                    </label>
                   
                  </div>
                  <div id='userfetch-popup1'>
                  <label>
                      <span>Password</span>

                      <div id='password-container'>
              <input
              id='userfetch-popup1-input2'
                type={showPassword ? "text" : "password"}  // Toggle input type
                value={editData.password}
                onChange={handleEditChange}
                name='password'
                required
              />
               <span onClick={togglePasswordVisibility} className='password-toggle-icon'>
               {showPassword ? <FaEyeSlash /> : <FaEye />} 
              </span>
            </div>
                      {validationErrors.password && <div className="userfetch-errormessage">{validationErrors.password}</div>}
                    </label>
                    <label>
                      <span>Confirm Password</span>
                      <div id='password-container'>
                      <input
                 id='userfetch-popup1-input2'
                 type={showConfirmPassword ? "text" : "password"}
                  name="confirmpassword"
                  value={editData.confirmpassword}
                  onChange={handleEditChange}
                  required
                />
                      <span onClick={toggleConfirmPasswordVisibility} className='password-toggle-icon'>
               {showConfirmPassword ? <FaEyeSlash /> : <FaEye />} 
              </span>
                         </div>
                      {/* <input id='userfetch-popup1-input2' type="password" name="confirmpassword" value={editData.confirmpassword} onChange={handleEditChange} required /> */}
                      {validationErrors.confirmpassword && <div className="userfetch-errormessage">{validationErrors.confirmpassword}</div>}
                    </label>
                  </div>
                  <div id='userfetch-popup1'> </div>
                  <button id='userfetch-popup-update' type="submit">Update</button>
                </form>
              </div>
            </Popup>
          )}
        </div>
      </div>
    </div>
  );
};

export default Userfetch;
