import React, { useState , useEffect } from 'react';
import './Loose_Connection_and_Corrosion_Form.css';
import axios from 'axios';
import { Phaseimbalanceheader } from '../../../Operator/Data';
import { Link , useParams , useNavigate } from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import { getCurrentDateTime } from '../../../Diagnose/dateUtils';
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const Loose_Connection_and_Corrosion_Form = () => {
    const { token_id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        section_name_location: '',
        loose_connection_or_corrosions_normal_or_severe: '',
        dateandtime: '',
        token_id:token_id,
        createdby: '',
        createdat: '',
        modifiedby: '',
        modifiedat: ''
    });
    
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
      // Retrieve the logged-in user from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        setLoggedInUser(user);
      }
      setUserDesignation(user.level);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = {
                ...formData,
                dateandtime: getCurrentDateTime(),
            };

            const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/looseconnectionandcorrosionforms`, dataToSend);

            console.log('Data posted successfully:', response.data);

            alert('Loose Connection and Corrosion Form data added successfully');

            // Reset form fields after successful submission
            setFormData({
                section_name_location: '',
                loose_connection_or_corrosions_normal_or_severe: '',
                dateandtime: '',
                token_id:token_id,
                createdby: '',
                createdat: '',
                modifiedby: '',
                modifiedat: ''
            });
            navigate('/Loose_Connection_and_Corrosion_Formfetch');

        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <div id='loose_connection_and_corrosion_form-total'>
             <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />} 
            </div>
            <div id='loose_connection_and_corrosion_form-right'>
                <div id='loose_connection_and_corrosion_form-right-1'>
                    <div id='loose_connection_and_corrosion_form-heading'>
                        {Phaseimbalanceheader.alertHeading}
                    </div>
                    <div id='loose_connection_and_corrosion_form-heading1'>
                        {Phaseimbalanceheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Phaseimbalanceheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <h3 id='loose_connection_and_corrosion_form-h3'>{Phaseimbalanceheader.subalertHeading}</h3>
                <div id='loose_connection_and_corrosion_form-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='loose_connection_and_corrosion_form-divtop'>
                            <label>
                                <span>Loose Connection and Corrosion Monitor</span>
                            </label>
                            {/* <label>
                                <Link to='/Loose_Connection_and_Corrosion_Formfetch'><button type="button">View Data</button></Link>
                            </label> */}
                        </div>

                        <div id='loose_connection_and_corrosion_form-row'>
                            <label>
                                <span>Section Name/Location <span id='all-mandetorymark'>*</span></span>
                                <input
                                    type="text"
                                    name="section_name_location"
                                    value={formData.section_name_location}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label>
                                <span>Loose connection or corrosions (Normal/severe) <span id='all-mandetorymark'>*</span></span>
                                <select
                                    name="loose_connection_or_corrosions_normal_or_severe"
                                    value={formData.loose_connection_or_corrosions_normal_or_severe}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select</option>
                                    <option value="YES">YES</option>
                                    <option value="NO">NO</option>
                                </select>
                            </label>
                        </div>

                        <label>
                            <button id='loose_connection_and_corrosion_form-submit' type="submit">Submit</button>
                        </label>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Loose_Connection_and_Corrosion_Form;
