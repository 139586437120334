import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import './LighteningArrestorsfetch.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const LightingArrestorfetch = ({ onFormButtonClick }) => {
    const [lightingArrestors, setLightingArrestors] = useState([]);
    const [lightingArrestorEditData, setLightingArrestorEditData] = useState(null);
    // const [parentNames, setParentNames] = useState([]);

    // const fetchParentNames = async () => {
    //     try {
    //         const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/names`);
    //         const fetchedParentNames = response.data.map(item => item.name);
    //         setParentNames(fetchedParentNames);
    //     } catch (error) {
    //         console.error('Error fetching parent names', error);
    //     }
    // };

    // useEffect(() => {
    //     fetchParentNames();
    // }, []);

    const fetchLightingArrestors = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/lightingarrestors`);
            setLightingArrestors(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchLightingArrestors();
    }, []);

    const handleEditClick = (lightingArrestor) => {
        setLightingArrestorEditData(lightingArrestor);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setLightingArrestorEditData({ ...lightingArrestorEditData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        console.log('Updating data:', lightingArrestorEditData);
        toast.success("Lighting Arrestor updated successfully");
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/lightingarrestors/${lightingArrestorEditData._id}`, lightingArrestorEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchLightingArrestors();
            setLightingArrestorEditData(null);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='lightingarrestorfetch-total'>
            <div id='lightingarrestorfetch-right'>
                <div id='lightingarrestorfetch-right-2'>
                    <div id='lightingarrestorfetch-right-21'>
                        <label>
                            <span> {Masterformsheadingsss.LightingArrestorHeading}</span>
                        </label>
                        <label>
                            <button onClick={onFormButtonClick}>{Masterformsheadingsss.Formheading}</button>
                        </label>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Location</th>
                                <th>Location Number</th>
                                <th>Type of Arrestor</th>
                                <th>Ground Point Number</th>
                                <th>Voltage Rating</th>
                                <th>Current Rating</th>
                                <th>Response Time</th>
                                <th>Ground Resistance</th>
                                <th>Proposed Periodicity of Maintenance</th>
                                <th>Previous Maintenance Done Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lightingArrestors.map((lightingArrestor) => (
                                <tr key={lightingArrestor._id}>
                                    <td>{lightingArrestor.date}</td>
                                    <td>{lightingArrestor.location}</td>
                                    <td>{lightingArrestor.locationnumber}</td>
                                    <td>{lightingArrestor.typeofarrestor}</td>
                                    <td>{lightingArrestor.groundpointnumber}</td>
                                    <td>{lightingArrestor.voltagerating}</td>
                                    <td>{lightingArrestor.currentrating}</td>
                                    <td>{lightingArrestor.responsetime}</td>
                                    <td>{lightingArrestor.groundresistance}</td>
                                    <td>{lightingArrestor.proposedperiodicityofmaintenance}</td>
                                    <td>{lightingArrestor.previousmaintenancedonedate}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(lightingArrestor)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {lightingArrestorEditData && (
                        <Popup open={true} onClose={() => setLightingArrestorEditData(null)}>
                            <div id='lightingarrestorfetch-popup'>
                                <h3>Edit Lighting Arrestor</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='lightingarrestorfetch-popup1'>
                                        <label>
                                            <span>Date</span>
                                            <input type="date" name="date" value={lightingArrestorEditData.date} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Location</span>
                                            <input type="text" name="location" value={lightingArrestorEditData.location} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='lightingarrestorfetch-popup1'>
                                        <label>
                                            <span>Location Number</span>
                                            <input type="text" name="locationnumber" value={lightingArrestorEditData.locationnumber} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Type of Arrestor</span>
                                            <input type="text" name="typeofarrestor" value={lightingArrestorEditData.typeofarrestor} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='lightingarrestorfetch-popup1'>
                                        <label>
                                            <span>Ground Point Number</span>
                                            <input type="number" name="groundpointnumber" value={lightingArrestorEditData.groundpointnumber} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Voltage Rating</span>
                                            <input type="number" name="voltagerating" value={lightingArrestorEditData.voltagerating} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='lightingarrestorfetch-popup1'>
                                        <label>
                                            <span>Current Rating</span>
                                            <input type="number" name="currentrating" value={lightingArrestorEditData.currentrating} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Response Time</span>
                                            <input type="number" name="responsetime" value={lightingArrestorEditData.responsetime} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='lightingarrestorfetch-popup1'>
                                        <label>
                                            <span>Ground Resistance</span>
                                            <input type="number" name="groundresistance" value={lightingArrestorEditData.groundresistance} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Proposed Periodicity of Maintenance</span>
                                            <input type="text" name="proposedperiodicityofmaintenance" value={lightingArrestorEditData.proposedperiodicityofmaintenance} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='lightingarrestorfetch-popup1'>
                                        <label>
                                            <span>Previous Maintenance Done Date</span>
                                            <input type="date" name="previousmaintenancedonedate" value={lightingArrestorEditData.previousmaintenancedonedate} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <button id='lightingarrestorfetch-popup-update' type="submit">Update</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LightingArrestorfetch;
