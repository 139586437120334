import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PFcorrectors.css'; 
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const PFcorrectors = ({ onViewData }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    const initialFormData = {
        date: getCurrentDate(),
        name: '',
        parentname: '',
        metermake: '',
        meterslno: '',
        phase: '',
        ratedpowerinva: '',
        operatingvoltage: '',
        autoselect: '',
        capacitortype: '',
        numberofstages: '',
        numberofcellsineachstage: '',
        eachcellcapacity: '',
        presenttestedvalueofeachcell: '',
        pfcefficiency: '',
        overcurrentprotectionavailable: '',
        overvoltageprotectionavailable: '',
        proposedperiodicityofmaintenance: '',
        previousmaintenancedonedate: '',
        spare1: '',
        spare2: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-transformerlvNames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    const generatePFcorrectorsID = () => {
        const randomNum = Math.floor(Math.random() * 999999) + 1;
        const paddedNum = String(randomNum).padStart(6, '0');
        return `PFCO-${paddedNum}`;
    };

    console.log(generatePFcorrectorsID());

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const pfcorrectorsid = generatePFcorrectorsID();
            const dataToSend = {
                pfcorrectorsid: pfcorrectorsid,
                ...formData,
                
                ratedpowerinva: Number(formData.ratedpowerinva),
                operatingvoltage: Number(formData.operatingvoltage),
                numberofstages: Number(formData.numberofstages),
                numberofcellsineachstage: Number(formData.numberofcellsineachstage),
                eachcellcapacity: Number(formData.eachcellcapacity),
                presenttestedvalueofeachcell: Number(formData.presenttestedvalueofeachcell),
                pfcefficiency: Number(formData.pfcefficiency),
            };

            await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/pfcorrectors`, dataToSend);

            toast.success('PF Corrector added successfully');
            setFormData(initialFormData);
            fetchParentNames();
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    return (
        <div id='pfcorrectors-total'> 
            <div id='pfcorrectors-right'> 
                <div id='pfcorrectors-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='pfcorrectors-divtop'> 
                            <label>
                            <span> {Masterformsheadingsss.PFCorrectorHeading}</span>

                            </label>
                            <label>
                            <button type="button" onClick={onViewData}>View Data</button>
                            </label>
                        </div>
                        <div id='pfcorrectors-div1'>
                            <label>
                                <span>Date</span>
                                <input type='date' name="date" value={formData.date} disabled />
                            </label>
                            <label>
                                <span>Parent Name</span>
                                <select name="parentname" value={formData.parentname} onChange={handleChange} >
                                    <option value="">Select Parent Name</option>
                                    {parentNames.map((name, index) => (
                                        <option key={index} value={name}>{name}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div id='pfcorrectors-div1'> 
                            <label>
                                <span>Name</span>
                                <input name="name" value={formData.name} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Meter Make</span>
                                <input name="metermake" value={formData.metermake} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='pfcorrectors-div1'> 
            
                            <label>
                                <span>Meter Serial No.</span>
                                <input name="meterslno" value={formData.meterslno} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Phase</span>
                                <select name="phase" value={formData.phase} onChange={handleChange} required>
                                    <option value=''>Select Phase</option>
                                    <option value='3-Phase'>3-Phase</option>
                                    <option value='1-Phase-Vr'>1-Phase-Vr</option>
                                    <option value='1-Phase-Vy'>1-Phase-Vy</option>
                                    <option value='1-Phase-Vb'>1-Phase-Vb</option>
                                </select>
                            </label>
                        </div>
                        <div id='pfcorrectors-div1'> 
                           
                            <label>
                                <span>Rated Power (in kVA)</span>
                                <input type='number' name="ratedpowerinva" value={formData.ratedpowerinva} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Operating Voltage</span>
                                <input type='number' name="operatingvoltage" value={formData.operatingvoltage} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='pfcorrectors-div1'> 
                            
                            <label>
                                <span>Auto Select</span>
                                <select name="autoselect" value={formData.autoselect} onChange={handleChange} required>
                                    <option value=''>Select Option</option>
                                    <option value='Auto'>Auto</option>
                                    <option value='Manual'>Manual</option>
                                </select>
                                
                            </label>
                            <label>
                                <span>Capacitor Type</span>
                                <input name="capacitortype" value={formData.capacitortype} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='pfcorrectors-div1'> 
                           
                            <label>
                                <span>Number of Stages</span>
                                <input type='number' name="numberofstages" value={formData.numberofstages} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Number of Cells in Each Stage</span>
                                <input type='number' name="numberofcellsineachstage" value={formData.numberofcellsineachstage} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='pfcorrectors-div1'> 
                            
                            <label>
                                <span>Each Cell Capacity</span>
                                <input type='number' name="eachcellcapacity" value={formData.eachcellcapacity} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Present Tested Value of Each Cell</span>
                                <input type='number' name="presenttestedvalueofeachcell" value={formData.presenttestedvalueofeachcell} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='pfcorrectors-div1'> 
                           
                            <label>
                                <span>PFC Efficiency</span>
                                <input type='number' name="pfcefficiency" value={formData.pfcefficiency} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Overcurrent Protection Available</span>
                                <select name="overcurrentprotectionavailable" value={formData.overcurrentprotectionavailable} onChange={handleChange} required>
                                    <option value=''>Select Option</option>
                                    <option value='Y'>Yes</option>
                                    <option value='N'>No</option>
                                </select>
                            </label>
                        </div>
                        <div id='pfcorrectors-div1'> 
                           
                            <label>
                                <span>Overvoltage Protection Available</span>
                                <select name="overvoltageprotectionavailable" value={formData.overvoltageprotectionavailable} onChange={handleChange} required>
                                    <option value=''>Select Option</option>
                                    <option value='Y'>Yes</option>
                                    <option value='N'>No</option>
                                </select>
                            </label>
                            <label>
                                <span>Proposed Periodicity of Maintenance</span>
                                <input name="proposedperiodicityofmaintenance" value={formData.proposedperiodicityofmaintenance} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='pfcorrectors-div1'>
                           
                            <label>
                                <span>Previous Maintenance Done Date</span>
                                <input type='date' name="previousmaintenancedonedate" value={formData.previousmaintenancedonedate} onChange={handleChange} required />
                            </label>
                        </div>
                        {/* <div id='pfcorrectors-div1'> 
                            <label>
                                <span>Spare 1</span>
                                <input name="spare1" value={formData.spare1} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Spare 2</span>
                                <input name="spare2" value={formData.spare2} onChange={handleChange} required />
                            </label>
                        </div> */}
                        <div id='pfcorrectors-divbottom'> 
                            <button id='pfcorrectors-save' type="submit">{Masterformsheadingsss.Submitheading}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PFcorrectors;
