
// import React, { useEffect, useState } from 'react';
// import { FaFileCsv } from "react-icons/fa6";
// import './DailyConsumption.css'
// const DailyConsumptionprint = () => {
//     const [dailyconsumptionData, setDailyconsumptionData] = useState([]);

//     const fetchDailyConsumptionData = async () => {
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/dailyconsumption`);
//             const data = await response.json();
//             if (Array.isArray(data)) {
//                 setDailyconsumptionData(data);
//             } else {
//                 console.error('Daily Consumption data is not an array:', data);
//             }
//         } catch (error) {
//             console.error('Error fetching Daily Consumption data:', error);
//         }
//     };

//     useEffect(() => {
//         fetchDailyConsumptionData();
//     }, []);

//     const convertToCSV = (data) => {
//         const csvRows = [];
//         const headers = Object.keys(data[0]);
//         csvRows.push(headers.join(','));

//         data.forEach(row => {
//             const values = headers.map(header => `"${row[header]}"`); // Enclose values in double quotes to handle commas
//             csvRows.push(values.join(','));
//         });

//         return csvRows.join('\n');
//     };

//     const downloadCSV = () => {
//         if (dailyconsumptionData.length === 0) {
//             console.error('No data available to download');
//             return;
//         }

//         const csvData = convertToCSV(dailyconsumptionData);
//         const blob = new Blob([csvData], { type: 'text/csv' });
//         const url = window.URL.createObjectURL(blob);
//         const a = document.createElement('a');
//         a.setAttribute('href', url);
//         a.setAttribute('download', 'daily_consumption.csv');
//         a.style.display = 'none'; // Hide the element
//         document.body.appendChild(a);
//         a.click();
//         document.body.removeChild(a); // Remove the element after download
//     };

//     return (
//         <div>
//             <button id='dailyconsumptiondownload-btn' onClick={downloadCSV}><FaFileCsv /></button>
//         </div>
//     );
// };

// export default DailyConsumptionprint;



import React from 'react';
import { FaFileCsv } from "react-icons/fa6";
import './DailyConsumption.css'

const DailyConsumptionprint = ({ filteredData }) => {

    const convertToCSV = (data) => {
        if (data.length === 0) return '';

        const csvRows = [];

        // Exclude the '_id' field
        const headers = Object.keys(data[0]).filter(header => header !== '_id');
        csvRows.push(headers.join(','));


        data.forEach(row => {
            const values = headers.map(header => `"${row[header]}"`); // Enclose values in double quotes to handle commas
            csvRows.push(values.join(','));
        });

        return csvRows.join('\n');
    };

    const downloadCSV = () => {
        if (filteredData.length === 0) {
            console.error('No data available to download');
            return;
        }

        const csvData = convertToCSV(filteredData);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'Consumption.csv');
        a.style.display = 'none'; // Hide the element
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a); // Remove the element after download
    };

    return (
        <div>
            <button id='dailyconsumptiondownload-btn' onClick={downloadCSV}><FaFileCsv /></button>
        </div>
    );
};

export default DailyConsumptionprint;
