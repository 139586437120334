import React, { useEffect, useState } from "react";
import { Doughnut, Bar } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  BarElement,
  Legend,
  Tooltip,
  LinearScale,
  Title,
  PointElement,
  LineElement,
  CategoryScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the plugin

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
); // Register the datalabels plugin

const EnergyIntensityBar = ({ barData = [], labels = [], baseValue = 0 }) => {
  const [isRender, setIsRender] = useState(false);

  const data = {
    labels,
    datasets: [
      {
        type: "line",
        label: "Base Line",
        data: barData.map((data) => baseValue),
        backgroundColor: "#DB4545",
        borderColor: "#DB4545",
        borderWidth: 2,
        borderDash: [5, 5],
        pointRadius: 2,
        tension: 0,
        fill: true,
        datalabels: {
          display: false,
        },
      },
      {
        data: barData,
        backgroundColor: ["#7D93DC"],
        datalabels: {
          align: "end",
          anchor: "center",
        },
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
      },
      y: {
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        beginAtZero: true,
        title: {
          display: true,
          text: "Intensity",
          font: {
            size: 15, // Font size for x-axis title
            //   weight: "bold", // Makes the x-axis title bold
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "white",
        display: function (context) {
          return context.dataset.data[context.dataIndex];
        },
        font: {
          weight: "bold",
        },
      },
    },
    elements: {
      // line: {
      //   fill: false,
      // },
      // point: {
      //   hoverRadius: 7,
      //   radius: 10,
      // },
    },
  };

  useEffect(() => {
    // Register necessary Chart.js components
    Chart.register(ChartDataLabels, Legend, Tooltip); // Register the datalabels plugin

    setIsRender(true);

    return () => {
      // Clean up any subscriptions when the component is unmounted
      Chart.unregister(ChartDataLabels);
    };
  }, []);

  return (
    <div>
      {isRender && (
        <Bar
          style={{ height: "500px", width: "100%" }}
          data={data}
          options={options}
        />
      )}
    </div>
  );
};

export default EnergyIntensityBar;
