import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import './Parentdetails.css'
import { Parentdetailsheader } from '../../Operator/Data';
import { Link } from 'react-router-dom';
import Adminsidebar from '../../Adminsidebar/Adminsidebar';
const Parentdetails = () => {
    const [parentdetails, setParentdetails] = useState([]);
    const [parentdetailseditData, setParentdetailsEditData] = useState(null);

    const fetchparentdetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/names`);
            setParentdetails(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchparentdetails();
    }, []);

    const handleEditClick = (parentdetail) => {
        setParentdetailsEditData(parentdetail);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setParentdetailsEditData({ ...parentdetailseditData, [name]: value });
    };

    //   const validateData = (data) => {
    //     // Add validation logic based on your requirements
    //     if (!data.groundingPointId || !data.location || !data.locationNumber || !data.resistance || !data.periodicityOfMaintenance || !data.previousMaintenanceDate) {
    //       return false;
    //     }
    //     return true;
    //   };

    // const handleEditSubmit = async (e) => {
    //     e.preventDefault();
    //     // if (!validateData(hvformseditData)) {
    //     //   console.error('Invalid data:', hvformseditData);
    //     //   return;
    //     // }
    //     console.log('Updating data:', parentdetailseditData);
    //     alert("Grounding Points updated sucessfully")
    //     try {
    //         const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/HVforms/${parentdetailseditData._id}`, parentdetailseditData, {
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         fetchparentdetails();
    //         setParentdetailsEditData(null);
    //     } catch (error) {
    //         console.error('Error updating data', error.response ? error.response.data : error.message);
    //     }
    // };



    const handleEditSubmit = async (e) => {
        e.preventDefault();
        console.log('Updating data:', parentdetailseditData);
        alert("Parent Details updated successfully");
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/names/${parentdetailseditData._id}`, parentdetailseditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchparentdetails();
            setParentdetailsEditData(null);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };
    

    return (


        <div id='parentdetails-total'>
            <div>
                <Adminsidebar />
            </div>
            <div id='parentdetails-right'>
                <div id='parentdetails-right-1'>
                    <div id='parentdetails-heading'>
                        {Parentdetailsheader.alertHeading}
                    </div>
                    <div id='parentdetails-heading1'>
                        {Parentdetailsheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Parentdetailsheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <div id='parentdetails-right-2' >

                    <div id='parentdetails-right-21'>
                        {/* <label>
                            <span>Filter:</span>
                            <input
                                type="text"
                            />
                        </label>
                        <label>
                            <span>Filter:</span>
                            <input
                                type="text"
                            />
                        </label> */}
                        <label>
                            {/* <Link to='/HVform'><button>Form</button></Link> */}
                        </label>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Parent Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {parentdetails.map((pd) => (
                                <tr key={pd._id}>
                                    <td>{pd.date}</td>
                                    <td>{pd.name}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(pd)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {parentdetailseditData && (
                        <Popup open={true} closeOnDocumentClick onClose={() => setParentdetailsEditData(null)}>
                            <div id='parentdetails-popup'>
                                <h3>Edit Parent Details</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='parentdetails-popup1'>
                                        <label>
                                            <span>Date</span>
                                            <input name="date" value={parentdetailseditData.date} disabled />
                                        </label>
                                        <label>
                                            <span>name</span>
                                            <input name="name" value={parentdetailseditData.name} onChange={handleEditChange} required />
                                        </label>
                                    </div>
                                    <button id='parentdetails-popup-update' type="submit">Update</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Parentdetails;
