// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#analog-totalcontent{
    margin-top: 2vh;
    table{
        background-color: white;
        width: 91.8vw;
        height: 65vh;
        text-align: center;
        overflow-y: scroll;
        overflow-x: scroll;
        display: block;
        border-collapse: collapse;
        border: 1px solid #DFE6FA;
    }
    th, td {
  
        width: calc(91.8vw / 26); 
    }
}

@media screen and (min-width: 1024px) {
    #analog-totalcontent{
        table{
            width: 92vw;
        }
    }  
}

@media screen and (min-width: 1440px) {
    #analog-totalcontent{
        table{
            width: 92.3vw;
        }
    }
   
}
@media screen and (min-width: 1920px) {
    #analog-totalcontent{
        table{
            width: 93.2vw;
        }
    }
   
}`, "",{"version":3,"sources":["webpack://./src/Components/Operator/Sectionpointview/Analog.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf;QACI,uBAAuB;QACvB,aAAa;QACb,YAAY;QACZ,kBAAkB;QAClB,kBAAkB;QAClB,kBAAkB;QAClB,cAAc;QACd,yBAAyB;QACzB,yBAAyB;IAC7B;IACA;;QAEI,wBAAwB;IAC5B;AACJ;;AAEA;IACI;QACI;YACI,WAAW;QACf;IACJ;AACJ;;AAEA;IACI;QACI;YACI,aAAa;QACjB;IACJ;;AAEJ;AACA;IACI;QACI;YACI,aAAa;QACjB;IACJ;;AAEJ","sourcesContent":["#analog-totalcontent{\n    margin-top: 2vh;\n    table{\n        background-color: white;\n        width: 91.8vw;\n        height: 65vh;\n        text-align: center;\n        overflow-y: scroll;\n        overflow-x: scroll;\n        display: block;\n        border-collapse: collapse;\n        border: 1px solid #DFE6FA;\n    }\n    th, td {\n  \n        width: calc(91.8vw / 26); \n    }\n}\n\n@media screen and (min-width: 1024px) {\n    #analog-totalcontent{\n        table{\n            width: 92vw;\n        }\n    }  \n}\n\n@media screen and (min-width: 1440px) {\n    #analog-totalcontent{\n        table{\n            width: 92.3vw;\n        }\n    }\n   \n}\n@media screen and (min-width: 1920px) {\n    #analog-totalcontent{\n        table{\n            width: 93.2vw;\n        }\n    }\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
