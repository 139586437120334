
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';
import './MaxdemsndkVA.css';

const DashMaxdemsndkVA = ({ selectedMonth }) => {
    const [maxDemandData, setMaxDemandData] = useState([]);
    const [maxValue, setMaxValue] = useState(null); // State to hold the maxValue
    const maxDemandChartRef = useRef(null);

    useEffect(() => {
        const fetchMaxValue = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/fixed-variables`);
                if (response.data && response.data && response.data.length > 0) {
                    const maxVal = parseFloat(response.data[0]["Contracted_max_demand_(CMD)"]);
                    console.log("Fetched max value:", maxVal); 
                    if (!isNaN(maxVal)) {
                        setMaxValue(maxVal);
                    } else {
                        console.log("Invalid max value fetched");
                    }
                } else {
                    console.log("Max value property not found in response");
                }
            } catch (error) {
                console.log("Error fetching the max value:", error);
            }
        };

        fetchMaxValue();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/maxdemand`);
                
                const filteredData = response.data.filter(item => {
                    const itemMonth = new Date(item.date).toISOString().slice(0, 7); 
                    return itemMonth === selectedMonth;
                });
                setMaxDemandData(filteredData);
            } catch (error) {
                console.log("Error fetching the kVA Data");
            }
        };

        fetchData();
    }, [selectedMonth]);
    

    useEffect(() => {
        if (maxDemandData.length > 0 && maxValue !== null && !isNaN(maxValue)) {
            const chartDom = maxDemandChartRef.current;
            const myChart = echarts.init(chartDom);

            const threshold90 = maxValue * 0.90;
            const threshold95 = maxValue * 0.95;

            const option = {
                title: {
                    // text: 'Maximum Demand HV (kVA)',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    }
                },
                legend: {
                    data: ['Maximum Demand HV (kVA)']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: maxDemandData.map(item => item.date),
                        axisLabel: { interval: 0, rotate: 60 }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        max: maxValue,
                        axisLabel: {
                            formatter: '{value} kVA'
                        },
                        axisPointer: {
                            snap: true
                        }
                    }
                ],
                visualMap: {
                    show: false,
                    pieces: [
                        {
                            lte: threshold90,
                            color: 'green'
                        },
                        {
                            gt: threshold90,
                            lte: threshold95,
                            color: 'orange'
                        },
                        {
                            gt: threshold95,
                            color: 'red'
                        }
                    ]
                },
                series: [
                    {
                        name: 'Maximum Demand (kVA)',
                        type: 'line',
                        stack: 'Total',
                        areaStyle: {},
                        emphasis: {
                            focus: 'series'
                        },
                        data: maxDemandData.map(item => item.Maximum_Demand_HV_kVA)
                    }
                ]
            };

            myChart.setOption(option);

            const handleResize = () => {
                myChart.resize();
            };

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
                myChart.dispose();
            };
        }
    }, [maxDemandData, maxValue]);

    return (
        <div id='dashmaxdemand-total'>
            <div id='dashmaxdemand-right-1' >
                <label>
                    <span  id='dashmaxdemand-heaading' >Maximum Demand (kVA)</span>
                </label>
                <label id='dashmaxdemand-right-headflex1'>
                    <div id='dashmaxdemand-right-headflex12'> <span> kVA :&lt; 90%<span id='btn1'></span> </span></div>
                    <div id='dashmaxdemand-right-headflex12'> <span> kVA : &gt;= 90% to 95%  <span id='btn2'></span> </span></div>
                    <div id='dashmaxdemand-right-headflex12'> <span> kVA : &gt; 95%  <span id='btn3'></span> </span></div>
                </label>
            </div>

            {maxDemandData.length === 0 ? (
                <p className="no-data" style={{ textAlign: 'center' }} >Not Available</p>
            ) : (
                <div ref={maxDemandChartRef} id='dashmaxdemand-barchart' />
            )}
        </div>
    );
};

export default DashMaxdemsndkVA;
