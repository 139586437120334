import React, { useState } from 'react';
import axios from 'axios';
import './Alertsduration.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Alertsduration = ({ onViewData }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    const generateAlertId = () => {
        return `AL-${String(Math.floor(1 + Math.random() * 999999)).padStart(6, '0')}`;
    };

    const initialFormData = {
        date: getCurrentDate(),
        alertid: generateAlertId(),
        alert_type: '',
        duration: ''
    };

    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/alertsduration`, formData);
            toast.success('Alert added successfully');
            setFormData({ ...initialFormData, alertid: generateAlertId() });
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    return (
        <div id='alertsduration-total'>
            <div id='alertsduration-right'>
                <div id='alertsduration-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='alertsduration-divtop'>
                            <label>
                                <span> {Masterformsheadingsss.AlertsdurationHeading}</span>
                            </label>
                            <label>
                                <button type="button" onClick={onViewData}>View Data</button>
                            </label>
                        </div>
                        <div id='alertsduration-div1'>
                            <label>
                                <span>Date</span>
                                <input type='date' name="date" value={formData.date} disabled />
                            </label>
                            <label>
                                <span>Alert Type</span>
                                <select name="alert_type" value={formData.alert_type} onChange={handleChange} required>
                                    <option value="">Select Alert Type</option>
                                    <option value="Voltage loss">Voltage loss</option>
                                    <option value="Abnormal PF loss">Abnormal PF loss</option>
                                    <option value="THD loss">THD loss</option>
                                    <option value="Unbalanced loads">Unbalanced loads</option>
                                    <option value="Negative PF loss">Negative PF loss</option>
                                    <option value="Idle time loss">Idle time loss</option>
                                    <option value="Transformer loss">Transformer loss</option>
                                    <option value="Utilization loss">Utilization loss</option>
                                    <option value="Currents loss">Currents loss</option>
                                    <option value="TOD loss">TOD loss</option>
                                    <option value="KVA">KVA</option>
                                </select>
                            </label>
                        </div>
                        <div id='alertsduration-div1'>
                            {/* <label>
                                <span>Alert ID</span>
                                <input name="alertid" value={formData.alertid} onChange={handleChange} required />
                            </label> */}
                            <label>
                                <span>Duration (in Mins)</span>
                                <input name="duration" value={formData.duration} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='alertsduration-divbottom'>
                            <label>
                                <button id='alertsduration-save' type="submit"> {Masterformsheadingsss.Submitheading}</button>

                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Alertsduration;
