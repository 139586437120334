

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './Breakdownlogsfetch.css';
import { FaFileCsv } from "react-icons/fa6";
import { formatDateToDDMMYYYY, formatDateTimeToDDMMYYYYHHMM, convertToInputDateTimeFormat } from '../Dateformat';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
const Breakdownlogsfetch = ({ onFormButtonClick }) => {
    const [logs, setLogs] = useState([]);
    const [filteredLogs, setFilteredLogs] = useState([]);
    const [selectedLog, setSelectedLog] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [errors, setErrors] = useState({
        starttime: '',
        endtime: ''
    });
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [userDesignation, setUserDesignation] = useState('');

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
        }
        setUserDesignation(user.level);
    }, []);

    useEffect(() => {
        const fetchLogs = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/breakdown-logs`);
                const data = response.data;
                if (Array.isArray(data)) {
                    setLogs(data);
                    setFilteredLogs(data);
                } else {
                    console.error('Failed to fetch data:', data.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchLogs();
    }, []);

    useEffect(() => {
        filterLogsByDate();
    }, [fromDate, toDate, logs]);

    const filterLogsByDate = () => {
        let filtered = logs;

        if (fromDate) {
            filtered = filtered.filter(log => new Date(log.date.split('-').reverse().join('-')) >= new Date(fromDate));
        }

        if (toDate) {
            filtered = filtered.filter(log => new Date(log.date.split('-').reverse().join('-')) <= new Date(toDate));
        }

        setFilteredLogs(filtered);
    };

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${day}-${month}-${year}`;
    };

    const calculateDuration = (starttime, endtime) => {
        const startTime = moment(starttime);
        const endTime = moment(endtime);
    
        if (endTime.isSameOrAfter(startTime)) {
            const duration = moment.duration(endTime.diff(startTime));
            const days = Math.floor(duration.asDays());
            const hours = duration.hours();
            const minutes = duration.minutes();
    
            const formattedDuration = `${days}d ${hours < 10 ? '0' + hours : hours}h ${minutes < 10 ? '0' + minutes : minutes}m`;
    
            setSelectedLog((prevState) => ({
                ...prevState,
                duration: formattedDuration,
            }));
        } else {
            // If end time is before start time, clear duration
            setSelectedLog((prevState) => ({
                ...prevState,
                duration: '',
            }));
        }
    };
    

    const handleEditClick = (log) => {
        setSelectedLog({
            ...log,
            date: log.date.split('-').reverse().join('-'), // Convert "DD-MM-YYYY" to "YYYY-MM-DD"
            starttime: convertToInputDateTimeFormat(log.starttime), // Convert to "YYYY-MM-DDTHH:MM"
            endtime: convertToInputDateTimeFormat(log.endtime) // Convert to "YYYY-MM-DDTHH:MM"
        });
        setIsPopupOpen(true);
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setSelectedLog(prevState => {
    //         const updatedFormData = {
    //             ...prevState,
    //             [name]: value
    //         };

    //         if (name === 'starttime' || name === 'endtime') {
    //             const now = new Date();
    //             const startTime = new Date(updatedFormData.starttime);
    //             const endTime = new Date(updatedFormData.endtime);

    //             if (startTime > now || endTime > now) {
    //                 setErrors(prevErrors => ({
    //                     ...prevErrors,
    //                     [name]: 'Date & Time cannot be in the future.'
    //                 }));
    //             } else {
    //                 setErrors(prevErrors => ({
    //                     ...prevErrors,
    //                     [name]: ''
    //                 }));

    //                 if (name === 'endtime' && startTime > endTime) {
    //                     setErrors(prevErrors => ({
    //                         ...prevErrors,
    //                         endtime: 'End Date & Time cannot be earlier than start date & time.'
    //                     }));
    //                 } else if (name === 'starttime' && endTime < startTime) {
    //                     setErrors(prevErrors => ({
    //                         ...prevErrors,
    //                         endtime: 'End Date & Time cannot be earlier than start date & time.'
    //                     }));
    //                 } else {
    //                     setErrors(prevErrors => ({
    //                         ...prevErrors,
    //                         endtime: ''
    //                     }));
    //                 }

    //                 calculateDuration(updatedFormData.starttime, updatedFormData.endtime);
    //             }
    //         }
    //         return updatedFormData;
    //     });
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        setSelectedLog((prevState) => {
            const updatedFormData = {
                ...prevState,
                [name]: value,
            };
    
            const startTime = moment(updatedFormData.starttime);
            const endTime = moment(updatedFormData.endtime);
            const now = moment();
            const endOfToday = moment().endOf('day');
    
            let newErrors = { ...errors };
    
            // Validation: Ensure date is not in the future
            if (name === 'starttime' || name === 'endtime') {
                if (startTime.isAfter(endOfToday) || endTime.isAfter(endOfToday)) {
                    newErrors[name] = 'Date & Time cannot be in the future.';
                } else {
                    newErrors[name] = '';
                }
    
                // Check if end time is earlier than start time
                if (name === 'endtime' || name === 'starttime') {
                    if (endTime.isBefore(startTime)) {
                        newErrors.endtime = 'End Time cannot be earlier than Start Time.';
                    } else {
                        newErrors.endtime = '';
                    }
                }
    
                // Update duration if times are valid
                if (!newErrors.starttime && !newErrors.endtime) {
                    calculateDuration(updatedFormData.starttime, updatedFormData.endtime);
                } else {
                    // If there is an error, clear the duration
                    setSelectedLog((prevState) => ({
                        ...prevState,
                        duration: '',
                    }));
                }
            }
    
            // Set errors and form data
            setErrors(newErrors);
            return updatedFormData;
        });
    };
    

    const handleUpdate = async () => {

        const requiredFields = [
            'starttime', 'endtime', 'duration', 'location', 'cause', 
            'typeofdowntime'
        ];
    
        // Validate if required fields are filled
        let hasErrors = false;
        const newErrors = { ...errors }; 
    
        requiredFields.forEach((field) => {
            if (!selectedLog[field] || selectedLog[field].trim() === '') {
                newErrors[field] = `${field} is required.`;
                hasErrors = true;
            }
        });

         // Check if there are existing errors for start and end times
    if (errors.starttime || errors.endtime) {
        hasErrors = true; // Prevent form submission if there are existing time-related errors
    }
    
        // Update the errors state if any errors exist
        setErrors(newErrors);
    
        // If there are errors, show a toast and stop submission
        if (hasErrors) {
            toast.error('Please fill all required fields before updating.');
            return;
        }

        const formattedDate = formatDateToDDMMYYYY(selectedLog.date);
        const formattedStartTime = formatDateTimeToDDMMYYYYHHMM(selectedLog.starttime);
        const formattedEndTime = formatDateTimeToDDMMYYYYHHMM(selectedLog.endtime);

        const currentDate = new Date();
        const formattedModifiedDate = formatDateTimeToDDMMYYYYHHMM(currentDate.toISOString());

        const updatedLog = {
            ...selectedLog,
            modifiedby: loggedInUser.username,
            date: formattedDate,
            starttime: formattedStartTime,
            endtime: formattedEndTime,
            modifiedat: formattedModifiedDate,
        };

        if (!errors.starttime && !errors.endtime) {
            try {
                const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/breakdown-logs/${selectedLog._id}`, updatedLog);
                const data = response.data;
                if (data.success) {
                    setLogs(logs.map(log => (log._id === selectedLog._id ? updatedLog : log)));
                    setIsPopupOpen(false);
                    setSelectedLog(null);
                    toast.success('Breakdown Log updated successfully');
                } else {
                    console.error('Failed to update data:', data.error);
                    toast.error('Failed to submit data');
                }
            } catch (error) {
                console.error('Error updating data:', error);
                toast.error('Failed to submit data');
            }
         }
         else {
            toast.error('Please fix the errors before submitting.');
        }
    };

    const downloadCSV = () => {

        const headers = ['Date', 'Downtime Type', 'Location', 'Start Time', 'End Time', 'Duration', 'Cause'];


        const rows = filteredLogs.map(log => [
            log.date,
            log.typeofdowntime,
            log.location,
            log.starttime,
            log.endtime,
            log.duration,
            log.cause,
        ]);


        const csvContent = [
            headers.join(','), // Join headers with commas
            ...rows.map(row => row.join(',')) // Join each row's data with commas
        ].join('\n'); // Combine each row with a newline character

        // Create a blob from the CSV string
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // Create a link element
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'Breakdown_Tracking.csv');
        document.body.appendChild(link); // Required for Firefox
        link.click();
        document.body.removeChild(link);
    };

    const handleFromDateChange = (e) => {
        const selectedFromDate = e.target.value;
        setFromDate(selectedFromDate);

        if (toDate && toDate < selectedFromDate) {
            setToDate('');
        }
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const getTodayDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
    };
    const getCurrentDateTime = () => {
        const today = new Date();
        return today.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:MM
    };

    const getEndOfToday = () => {
        // Get the end of today using moment
        return moment().endOf('day').format('YYYY-MM-DDTHH:mm');
    };


    return (
        <div id='breakdownfetch-total'>

            <div id='breakdownfetch-right'>

                <div id='breakdownfetch-right-2'>
                    <div id='breakdownfetch-right-21'>
                        <label>
                            <span>From Date:</span>
                            <input
                                type='date'
                                value={fromDate}
                                onChange={handleFromDateChange}
                                max={getTodayDate()}
                            />
                        </label>
                        <label>
                            <span>To Date:</span>
                            <input
                                type='date'
                                value={toDate}
                                onChange={handleToDateChange}
                                max={getTodayDate()}
                                min={fromDate || ''}
                                disabled={!fromDate}
                            />
                        </label>
                        <label id='formbutton-left'>
                            <button id='formbutton-from' onClick={onFormButtonClick}>Form</button>
                        </label>
                        <label>
                            <button id='formbutton-csv' onClick={downloadCSV}><FaFileCsv id='formbutton-csvicon' /></button>
                        </label>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Downtime Type</th>
                                <th>Location</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Duration</th>
                                <th>Cause</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredLogs.length === 0 ? (
                                <tr>
                                    <td colSpan="8">No data available for selected filters.</td>
                                </tr>
                            ) : (
                                filteredLogs.map((log, index) => (
                                    <tr key={log._id || index}>
                                        <td>{log.date}</td>
                                        <td>{log.typeofdowntime}</td>
                                        <td>{log.location}</td>
                                        <td>{log.starttime}</td>
                                        <td>{log.endtime}</td>
                                        <td>{log.duration}</td>
                                        <td>{log.cause}</td>
                                        <td><button onClick={() => handleEditClick(log)}>Edit</button></td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    <Popup open={isPopupOpen} onClose={() => setIsPopupOpen(false)} modal>
                        <div id='breakdownfetch-popup'>
                            <h3>Edit Breakdown Tracking Form</h3>
                            {selectedLog && (
                                <form>
                                    <div id='breakdownfetch-popup1'>
                                        <label>
                                            <span>Date <span id='all-mandetorymark'>*</span></span>
                                            <input type='date' name="date" value={selectedLog.date} onChange={handleChange} max={getTodayDate()} required />
                                        </label>
                                        <label>
                                            <span> Downtime Type <span id='all-mandetorymark'>*</span></span>
                                            <select name="typeofdowntime" value={selectedLog.typeofdowntime} onChange={handleChange} required >
                                                <option value="">Select</option>
                                                <option value="Planned down time">Planned down time</option>
                                                <option value="Unplanned down time">Unplanned down time</option>
                                            </select>
                                            {errors.typeofdowntime && <span span id='breakdownfetch-errormessage' style={{ color: 'red' }}>{errors.typeofdowntime}</span>}
                                        </label>

                                    </div>

                                    <div id='breakdownfetch-popup1'>
                                        <label>
                                            <span>Location  <span id='all-mandetorymark'>*</span></span>
                                            <input name="location" value={selectedLog.location} onChange={handleChange}  maxLength="50" required/>
                                            {selectedLog.location.length === 50 && <span id='breakdownfetch-errormessage' style={{ color: 'red' }}>Maximum 50 characters allowed</span>}
                                            {errors.location && <span span id='breakdownfetch-errormessage' style={{ color: 'red' }}>{errors.location}</span>}
                                        </label>
                                        <label>
                                            <span>Start Time  <span id='all-mandetorymark'>*</span></span>
                                            <input type='datetime-local' name="starttime" value={selectedLog.starttime} onChange={handleChange} max={getEndOfToday()} required/>
                                            {errors.starttime && <span id='breakdownfetch-errormessage' style={{ color: 'red' }}>{errors.starttime}</span>}
                                        </label>

                                    </div>

                                    <div id='breakdownfetch-popup1'>
                                        <label>
                                            <span> End Time <span id='all-mandetorymark'>*</span></span>
                                            <input type='datetime-local' name="endtime" value={selectedLog.endtime} onChange={handleChange} max={getEndOfToday()} required/>
                                            {errors.endtime && <span id='breakdownfetch-errormessage' style={{ color: 'red' }}>{errors.endtime}</span>}
                                        </label>
                                        <label>
                                            <span> Duration <span id='all-mandetorymark'>*</span></span>
                                            <input name="duration" value={selectedLog.duration} onChange={handleChange} disabled />
                                        </label>

                                    </div>
                                    <div id='breakdownfetch-popup1'>
                                        <label>
                                            <span>Cause  <span id='all-mandetorymark'>*</span></span>
                                            <textarea name="cause" value={selectedLog.cause} onChange={handleChange} maxLength="100" required/>
                                            {selectedLog.cause.length === 100 && <span id='breakdownfetch-errormessage' style={{ color: 'red' }}>Maximum 100 characters allowed</span>}
                                            {errors.cause && <span span id='breakdownfetch-errormessage' style={{ color: 'red' }}>{errors.cause}</span>}
                                        </label>
                                    </div>
                                    <button id='breakdownfetch-popup-update' type="button" onClick={handleUpdate}>Update</button>
                                </form>
                            )}
                        </div>
                    </Popup>
                </div>
            </div>
        </div>
    );
}

export default Breakdownlogsfetch;

