
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Breakdownlogs.css';
import { formatDateToDDMMYYYY, formatDateTimeToDDMMYYYYHHMM } from '../Dateformat';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
const Breakdownlogs = ({ onViewData }) => {

    const getEndOfToday = () => {
        // Get the end of today using moment
        return moment().endOf('day').format('YYYY-MM-DDTHH:mm');
    };
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };
    // const getEndOfToday = () => {
    //     const today = new Date();
    //     today.setHours(23, 59, 59, 999); // Set to the end of the day
    //     return today.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:MM
    // };
    // const getCurrentDateTime = () => {
    //     const now = new Date();
    //     return now.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:MM
    // };
    

    const getCurrentDateTime = () => {
        // Get the current date and time using moment
        return moment().format('YYYY-MM-DDTHH:mm');
    };

    const [formData, setFormData] = useState({
        typeofdowntime: '',
        location: '',
        starttime: getCurrentDate(),
        endtime: getCurrentDate(),
        duration: '',
        cause: '',
        createdby: '',
        date: getCurrentDate(),
    });

    const [errors, setErrors] = useState({
        starttime: '',
        endtime: ''
    });

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
            setFormData((prevData) => ({
                ...prevData,
                createdby: user.username
            }));
            setUserDesignation(user.level);
        }
    }, []);

    // const calculateDuration = (starttime, endtime) => {
    //     if (starttime && endtime) {
    //         const startTime = new Date(starttime);
    //         const endTime = new Date(endtime);

    //         let duration = endTime - startTime;

    //         const days = Math.floor(duration / (1000 * 60 * 60 * 24));
    //         const hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    //         const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));

    //         const formattedDuration = `${days}d ${hours < 10 ? '0' + hours : hours}h ${minutes < 10 ? '0' + minutes : minutes}m`;

    //         setFormData(prevState => ({
    //             ...prevState,
    //             duration: formattedDuration
    //         }));
    //     }
    // };

    const calculateDuration = (starttime, endtime) => {
        const startTime = moment(starttime);
        const endTime = moment(endtime);
    
        if (endTime.isSameOrAfter(startTime)) {
            const duration = moment.duration(endTime.diff(startTime));
            const days = Math.floor(duration.asDays());
            const hours = duration.hours();
            const minutes = duration.minutes();
    
            const formattedDuration = `${days}d ${hours < 10 ? '0' + hours : hours}h ${minutes < 10 ? '0' + minutes : minutes}m`;
    
            setFormData(prevState => ({
                ...prevState,
                duration: formattedDuration
            }));
        } else {
            // If end time is before start time, set duration to empty
            setFormData(prevState => ({
                ...prevState,
                duration: ''
            }));
        }
    };
    

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        setFormData((prevState) => {
            const updatedFormData = {
                ...prevState,
                [name]: value,
            };
    
            const now = moment();
            const startTime = moment(updatedFormData.starttime);
            const endTime = moment(updatedFormData.endtime);
            const endOfToday = moment().endOf('day');
    
            let newErrors = { ...errors };
    
            // Validation: Ensure date is not beyond today
            if (startTime.isAfter(endOfToday) || endTime.isAfter(endOfToday)) {
                newErrors[name] = 'Date & Time cannot be in the future.';
            } else {
                newErrors[name] = '';
            }
    
            // Check if end time is earlier than start time
            if (name === 'endtime' || name === 'starttime') {
                if (endTime.isBefore(startTime)) {
                    newErrors.endtime = 'End time cannot be earlier than start time.';
                } else {
                    newErrors.endtime = '';
                    calculateDuration(updatedFormData.starttime, updatedFormData.endtime);
                }
            }
    
            // Update the errors and form data
            setErrors(newErrors);
            return updatedFormData;
        });
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();

          // Check if any errors are present
    const hasErrors = Object.values(errors).some(error => error);

    if (hasErrors) {
        toast.error('Please fix the errors before submitting.');
        return; // Exit without submitting if errors exist
    }

        // Format the date and time using the formatDateToDDMMYYYY and formatDateTimeToDDMMYYYYHHMM functions
        const formattedDate = formatDateToDDMMYYYY(formData.date);
        const formattedStartTime = formatDateTimeToDDMMYYYYHHMM(formData.starttime);
        const formattedEndTime = formatDateTimeToDDMMYYYYHHMM(formData.endtime);

        // Prepare the data to be sent to the server with the formatted values
        const formattedFormData = {
            ...formData,
            date: formattedDate,
            starttime: formattedStartTime,
            endtime: formattedEndTime,
        };

        if (!errors.starttime && !errors.endtime) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/breakdown-logs`, formattedFormData);
                if (response.data.success) {
                    setFormData({
                        typeofdowntime: '',
                        location: '',
                        starttime: '',
                        endtime: '',
                        duration: '',
                        cause: '',
                        createdby: loggedInUser.username,
                        date: getCurrentDate(),
                    });
                    toast.success('Breakdown Form submitted successfully');
                } else {
                    toast.error('Failed to submit data');
                }
            } catch (error) {
                console.error('Error submitting form', error);
                toast.error('Failed to submit data');
            }
        } else {
            toast.error('Please fix the errors before submitting.');
        }
    };


    return (
        <div id='breakdown-total'>
            {/* <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Formsidebar />}
            </div> */}
            <div id='breakdown-right'>
                {/* <div id='breakdown-right-1'>
                    <div id='breakdown-heading'>
                        {Breakdownheader.alertHeading}
                    </div>
                    <div id='breakdown-heading1'>
                        {Breakdownheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Breakdownheader.logoSrc} alt='logo' />
                    </div>
                </div> */}

                <div id='breakdown-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='breakdown-divtop'>
                            {/* <label>
                                <span>Operator Name</span>
                                <span>#123456</span>
                            </label> */}
                            <label>
                                <button type="button" onClick={onViewData}>View Data</button>
                            </label>
                        </div>
                        <div id='breakdown-div1'>
                            <label>
                                <span>Date <span id='all-mandetorymark'>*</span></span>
                                <input type='date' name="date" value={formData.date} onChange={handleChange}  max={getCurrentDate()} required />
                            </label>

                            <label>
                                <span>Downtime Type <span id='all-mandetorymark'>*</span></span>
                                <select name="typeofdowntime" value={formData.typeofdowntime} onChange={handleChange} required>
                                    <option value="">Select The Down Time</option>
                                    <option value="Planned down time">Planned down time</option>
                                    <option value="Unplanned down time">Unplanned down time</option>
                                </select>
                            </label>

                        </div>

                        <div id='breakdown-div1'>
                            <label>
                                <span>Location <span id='all-mandetorymark'>*</span></span>
                                <input type='text' name="location" value={formData.location} onChange={handleChange} required  maxLength="50" />
                                {formData.location.length === 50 && <span id='breakdown-errormessage' style={{ color: 'red' }}>Maximum 50 characters allowed</span>}
                            </label>

                            <label>
                                <span>Start Time <span id='all-mandetorymark'>*</span></span>
                                <input type='datetime-local' name="starttime" value={formData.starttime} onChange={handleChange} max={getEndOfToday()} required />
                                {errors.starttime && <span id='breakdown-errormessage' style={{ color: 'red' }}>{errors.starttime}</span>}
                            </label>

                        </div>
                        <div id='breakdown-div1'>
                            <label>
                                <span>End Time <span id='all-mandetorymark'>*</span></span>
                                <input type='datetime-local' name="endtime" value={formData.endtime} onChange={handleChange} max={getEndOfToday()} required />
                                {errors.endtime && <span id='breakdown-errormessage' style={{ color: 'red' }}>{errors.endtime}</span>}
                            </label>
                            <label>
                                <span>Duration <span id='all-mandetorymark'>*</span></span>
                                <input name="duration" value={formData.duration} onChange={handleChange} disabled required />
                            </label>

                        </div>
                        <div id='breakdown-div1'>
                            <label>
                                <span>Cause<span id='all-mandetorymark'>*</span></span>
                                <textarea name="cause" value={formData.cause} onChange={handleChange} required  maxLength="100" />
                                {formData.location.length === 100 && <span id='breakdown-errormessage' style={{ color: 'red' }}>Maximum 100 characters allowed</span>}
                            </label>
                        </div>

                        <button id='breakdown-save' type="submit">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Breakdownlogs;
