
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';
import './DashRevenueloss.css';
import { useNavigate } from 'react-router-dom';

const DashboardRevenueloss = ({ selectedMonth }) => {
    const [utilizationData, setUtilizationData] = useState([]);
    const [transformerLossData, setTransformerLossData] = useState([]);
    const [highVoltageLossData, setHighVoltageLossData] = useState([]);
    const [negativePfLossData, setNegativePfLossData] = useState([]);
    const [highCurrentLossData, setHighCurrentLossData] = useState([]);
    const [idleLossData, setIdleLossData] = useState([]);
    const [todLossData, setTodLossData] = useState([]);
    const [unbalancedcurrentlossData, setUnbalancedcurrentlossData] = useState([]);
    const [abnormalpflossData, setAbnormalpflossData] = useState([]);
    const [thdLossData, setThdLossData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [
                    utilizationResponse,
                    transformerLossResponse,
                    highVoltageLossResponse,
                    negativePfLossResponse,
                    highCurrentLossResponse,
                    idleLossResponse,
                    todLossResponse,
                    unbalancedcurrentlossData,
                    abnormalpflossData,
                    thdLossResponse,
                ] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/utilizationloss`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/transformerloss`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/highvoltageloss-withmonth`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/negativepfloss-withmonth`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/highcurrentloss`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/idealloss-withmonth`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/todloss`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/unbalancedcurrentloss-withmonth`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/abnormalpfloss-withmonth`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/THDloss-withmonth`),
                ]);

                setUtilizationData(utilizationResponse.data);
                setTransformerLossData(transformerLossResponse.data);
                setHighVoltageLossData(highVoltageLossResponse.data);
                setNegativePfLossData(negativePfLossResponse.data);
                setHighCurrentLossData(highCurrentLossResponse.data);
                setIdleLossData(idleLossResponse.data);
                setTodLossData(todLossResponse.data);
                setUnbalancedcurrentlossData(unbalancedcurrentlossData.data);
                setAbnormalpflossData(abnormalpflossData.data);
                setThdLossData(thdLossResponse.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const filterByMonth = (data) => {
        return data.filter(item => item.month === selectedMonth || item.Month === selectedMonth || item.month_wise_all_section === selectedMonth);
    };

    const filteredUtilizationData = filterByMonth(utilizationData);
    const filteredTransformerLossData = filterByMonth(transformerLossData);
    const filteredHighVoltageLossData = filterByMonth(highVoltageLossData);
    const filteredNegativePfLossData = filterByMonth(negativePfLossData);
    const filteredHighCurrentLossData = filterByMonth(highCurrentLossData);
    const filteredIdleLossData = filterByMonth(idleLossData);
    const filteredTodLossData = filterByMonth(todLossData);
    const filteredUnbalancedcurrentlossData = filterByMonth(unbalancedcurrentlossData);
    const filteredAbnormalpflossData = filterByMonth(abnormalpflossData);
    const filteredThdLossData = filterByMonth(thdLossData);

    const thdEnergyLoss = filteredThdLossData.map(item => item['overall_energy_loss']);
    const abnormalpfEnergyLoss = filteredAbnormalpflossData.map(item => item['Total Energy Loss']);
    const unbalancedcurrentEnergyLoss = filteredUnbalancedcurrentlossData.map(item => item['Total Energy Loss']);
    const todEnergyLoss = filteredTodLossData.map(item => item['Total_Energy_Loss']);
    const idleEnergyLoss = filteredIdleLossData.map(item => item['total_energy_loss']);
    const highCurrentEnergyLoss = filteredHighCurrentLossData.map(item => item['Total Energy Loss']);
    const negativePfEnergyLoss = filteredNegativePfLossData.map(item => item['Total Energy Loss']);
    const highVoltageEnergyLoss = filteredHighVoltageLossData.map(item => item['Overall Energy Loss']);
    const transformerEnergyLoss = filteredTransformerLossData.map(item => item.transformer_loss);
    const utilizationEnergyLoss = filteredUtilizationData.map(item => item.kvah_consumption);

    const isDataAvailable = () => {
        return filteredUtilizationData.length > 0 ||
            filteredTransformerLossData.length > 0 ||
            filteredHighVoltageLossData.length > 0 ||
            filteredNegativePfLossData.length > 0 ||
            filteredHighCurrentLossData.length > 0 ||
            filteredIdleLossData.length > 0 ||
            filteredTodLossData.length > 0 ||
            filteredUnbalancedcurrentlossData.length > 0 ||
            filteredAbnormalpflossData.length > 0 ||
            filteredThdLossData.length > 0;
    };

    useEffect(() => {
        if (!loading && !error && isDataAvailable()) {
            const chartDom = document.getElementById('chart');
            const myChart = echarts.init(chartDom);

            const utilizationRevenueLoss = filteredUtilizationData.map(item => item.revenue_loss);
            const transformerRevenueLoss = filteredTransformerLossData.map(item => item.revenue_loss);
            const highVoltageRevenueLoss = filteredHighVoltageLossData.map(item => item['Overall Revenue Loss']);
            const negativePfRevenueLoss = filteredNegativePfLossData.map(item => item['Total Revenue Loss @ unit price']);
            const highCurrentRevenueLoss = filteredHighCurrentLossData.map(item => item['Total Revenue Loss']);
            const idleRevenueLoss = filteredIdleLossData.map(item => item['total_revenue_loss']);
            const todRevenueLoss = filteredTodLossData.map(item => item['Total_Revenue_Loss']);
            const unbalancedRevenueLoss = filteredUnbalancedcurrentlossData.map(item => item['Total Revenue Loss']);
            const abnormalpfRevenueLoss = filteredAbnormalpflossData.map(item => item['Total Revenue Loss @ unit price']);
            const thdRevenueLoss = filteredThdLossData.map(item => item['overall_revenue_loss']);

            const option = {
                title: {
                    // text: 'Revenue Loss Data'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: ['Energy Loss', 'Revenue Loss']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: [
                        'THD Loss',
                        'Abnormal PF Loss',
                        'Unbalanced Current Loss',
                        'TOD Loss',
                        'Idle Loss',
                        'High Current Loss',
                        'Negative PF Loss',
                        'High Voltage Loss',
                        'Transformer Loss',
                        'Utilization Loss'
                    ]
                },
                series: [
                    {
                        name: 'Energy Loss',
                        type: 'bar',
                        data: [
                            thdEnergyLoss.reduce((a, b) => a + b, 0),
                            abnormalpfEnergyLoss.reduce((a, b) => a + b, 0),
                            unbalancedcurrentEnergyLoss.reduce((a, b) => a + b, 0),
                            todEnergyLoss.reduce((a, b) => a + b, 0),
                            idleEnergyLoss.reduce((a, b) => a + b, 0),
                            highCurrentEnergyLoss.reduce((a, b) => a + b, 0),
                            negativePfEnergyLoss.reduce((a, b) => a + b, 0),
                            highVoltageEnergyLoss.reduce((a, b) => a + b, 0),
                            transformerEnergyLoss.reduce((a, b) => a + b, 0),
                            utilizationEnergyLoss.reduce((a, b) => a + b, 0),
                        ],
                        itemStyle: {
                            color: '#6174D7',
                        },
                    },
                    {
                        name: 'Revenue Loss',
                        type: 'bar',
                        data: [
                            thdRevenueLoss.reduce((a, b) => a + b, 0),
                            abnormalpfRevenueLoss.reduce((a, b) => a + b, 0),
                            unbalancedRevenueLoss.reduce((a, b) => a + b, 0),
                            todRevenueLoss.reduce((a, b) => a + b, 0),
                            idleRevenueLoss.reduce((a, b) => a + b, 0),
                            highCurrentRevenueLoss.reduce((a, b) => a + b, 0),
                            negativePfRevenueLoss.reduce((a, b) => a + b, 0),
                            highVoltageRevenueLoss.reduce((a, b) => a + b, 0),
                            transformerRevenueLoss.reduce((a, b) => a + b, 0),
                            utilizationRevenueLoss.reduce((a, b) => a + b, 0),
                        ],
                        itemStyle: {
                            color: '#F85A5A',
                        },
                    },
                ]
            };

            myChart.setOption(option);
        }
    }, [loading, error, selectedMonth, filteredUtilizationData, filteredTransformerLossData, filteredHighVoltageLossData, filteredNegativePfLossData, filteredHighCurrentLossData, filteredIdleLossData, filteredTodLossData, filteredUnbalancedcurrentlossData, filteredAbnormalpflossData, filteredThdLossData]);

    // Navigation Handlers
    const handleUnbalancedloaddiagnoseClick = () => {
        navigate('/Unbalancedloaddiagnose', { state: { selectedMonth } });
    };

    const handleVoltagediagnoseClick = () => {
        navigate('/Voltagediagnose', { state: { selectedMonth } });
    };

    const handlePowersurgediagnoseClick = () => {
        navigate('/Powersurgediagnose', { state: { selectedMonth } });
    };

    const handleHarmonicdistortiondiagnoseClick = () => {
        navigate('/Harmonicdistortiondiagnose', { state: { selectedMonth } });
    };

    const handleLowPFdiagnoseClick = () => {
        navigate('/LowPFdiagnose', { state: { selectedMonth } });
    };

    const handleLeadPFdiagnoseClick = () => {
        navigate('/LeadPFdiagnose', { state: { selectedMonth } });
    };

    const handleIdle_Loss_Revenue_DiagnosediagnoseClick = () => {
        navigate('/Idle_Loss_Revenue_Diagnose', { state: { selectedMonth } });
    };

    const handleTOD_loss_Revenue_DiagnosediagnoseClick = () => {
        navigate('/TOD_loss_Revenue_Diagnose', { state: { selectedMonth } });
    };

    const handleUtilization_Loss_Revenue_DiagnoseClick = () => {
        navigate('/Utilization_Loss_Revenue_Diagnose', { state: { selectedMonth } });
    };

    const handleTransformer_Loss_Revenue_DiagnoseClick = () => {
        navigate('/Transformer_Loss_Revenue_Diagnose', { state: { selectedMonth } });
    };

    const isButtonDisabled = (loss) => loss === 0;

    return (
        <div id='dashrevenueloss-total'>
            <span>Revenue Loss</span>
            {loading || error || !isDataAvailable() ? (
                <p className="no-data" style={{textAlign:'center'}}>Not Available</p>
            ) : (
                <div className="chart-container-revenueloss">
                    {/* <div className="buttons-container-revenueloss">
                        <button onClick={handleUtilization_Loss_Revenue_DiagnoseClick} className={isButtonDisabled(utilizationEnergyLoss.reduce((a, b) => a + b, 0)) ? 'button-disabled' : ''}>Suggestion</button>
                        <button onClick={handleTransformer_Loss_Revenue_DiagnoseClick} className={isButtonDisabled(transformerEnergyLoss.reduce((a, b) => a + b, 0)) ? 'button-disabled' : ''}>Suggestion</button>
                        <button onClick={handleVoltagediagnoseClick} className={isButtonDisabled(highVoltageEnergyLoss.reduce((a, b) => a + b, 0)) ? 'button-disabled' : ''}>Suggestion</button>
                        <button onClick={handleLowPFdiagnoseClick} className={isButtonDisabled(negativePfEnergyLoss.reduce((a, b) => a + b, 0)) ? 'button-disabled' : ''}>Suggestion</button>
                        <button onClick={handlePowersurgediagnoseClick} className={isButtonDisabled(highCurrentEnergyLoss.reduce((a, b) => a + b, 0)) ? 'button-disabled' : ''}>Suggestion</button>
                        <button onClick={handleIdle_Loss_Revenue_DiagnosediagnoseClick} className={isButtonDisabled(idleEnergyLoss.reduce((a, b) => a + b, 0)) ? 'button-disabled' : ''}>Suggestion</button>
                        <button onClick={handleTOD_loss_Revenue_DiagnosediagnoseClick} className={isButtonDisabled(todEnergyLoss.reduce((a, b) => a + b, 0)) ? 'button-disabled' : ''}>Suggestion</button>
                        <button onClick={handleUnbalancedloaddiagnoseClick} className={isButtonDisabled(unbalancedcurrentEnergyLoss.reduce((a, b) => a + b, 0)) ? 'button-disabled' : ''}>Suggestion</button>
                        <button onClick={handleLeadPFdiagnoseClick} className={isButtonDisabled(abnormalpfEnergyLoss.reduce((a, b) => a + b, 0)) ? 'button-disabled' : ''}>Suggestion</button>
                        <button onClick={handleHarmonicdistortiondiagnoseClick} className={isButtonDisabled(thdEnergyLoss.reduce((a, b) => a + b, 0)) ? 'button-disabled' : ''}>Suggestion</button>

                    </div> */}
                    <div id="chart" className="chart-revenueloss"></div>
                </div>
            )}
        </div>
    );
};

export default DashboardRevenueloss;


