// // ProtectedRoute.js
// import React, { useContext } from 'react';
// import { Navigate  } from 'react-router-dom';
// import { UserContext } from './UserContext';

// const ProtectedRoute = ({ children }) => {
//   const { user } = useContext(UserContext);
//   // console.log("User in ProtectedRoute: ", user);

//   if (!user) {
//     return <Navigate to="/" replace />;
//   }

//    // Render children (the routes) if user is authenticated
//    return <>{children}</>;
// };

// export default ProtectedRoute;


// ProtectedRoutes.js
import React, { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { UserContext } from './UserContext';

const ProtectedRoutes = () => {
  const { user } = useContext(UserContext);

  if (!user) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;  // Outlet will render the child routes if user is authenticated
};

export default ProtectedRoutes;
