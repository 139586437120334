import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Harmonic_filters.css'; // Rename the CSS file accordingly
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Harmonicfilter = ({ onViewData }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    const initialFormData = {
        date: getCurrentDate(),
        name: '',
        harmonicfilterid: '',
        parentname: '',
        metermake: '',
        meterslno: '',
        phase: '',
        capacityinva: '',
        harmonicfrequencyrange: '',
        attenuationperformance: '',
        ratedvoltage: '',
        ratedcurrent: '',
        connectiontype: '',
        operationfrequency: '',
        efficiency: '',
        autoselect: '',
        overcurrentprotectionavailable: '',
        overvoltageprotectionavailable: '',
        coolingmethod: '',
        proposedperiodicityofmaintenance: '',
        previousmaintenancedonedate: '',
        spare1: '',
        spare2: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-lvtransformersectionames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    const generateHarmonicfilterID = () => {
        const randomNum = Math.floor(Math.random() * 999999) + 1;
        const paddedNum = String(randomNum).padStart(6, '0');
        return `HF-${paddedNum}`;
    };

    console.log(generateHarmonicfilterID());

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const harmonicfilerid = generateHarmonicfilterID();
            const dataToSend = {
                harmonicfilerid: harmonicfilerid,
                ...formData,
                meterslno: String(formData.meterslno),
                capacityinva: Number(formData.capacityinva),
                harmonicfrequencyrange: Number(formData.harmonicfrequencyrange),
                attenuationperformance: Number(formData.attenuationperformance),
                ratedvoltage: Number(formData.ratedvoltage),
                ratedcurrent: Number(formData.ratedcurrent),
                operationfrequency: Number(formData.operationfrequency),
                efficiency: Number(formData.efficiency),
            };

            await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/harmonicfilters`, dataToSend);

            toast.success('Harmonic Filter added successfully');
            setFormData(initialFormData);
            fetchParentNames();
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    return (
        <div id='harmonicfilter-total'>
            <div id='harmonicfilter-right'>
                
                <div id='harmonicfilter-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='harmonicfilter-divtop'>
                            <label>
                            <span> {Masterformsheadingsss.HarmonicFilterHeading}</span>

                            </label>
                            <label>
                            <button type="button" onClick={onViewData}>View Data</button>

                            </label>
                        </div>
                        <div id='harmonicfilter-div1'>
                            <label>
                                <span>Date</span>
                                <input type='date' name="date" value={formData.date} disabled />
                            </label>
                            <label>
                                <span>Parent Name</span>
                                <select name="parentname" value={formData.parentname} onChange={handleChange} >
                                    <option value="">Select Parent Name</option>
                                    {parentNames.map((name, index) => (
                                        <option key={index} value={name}>{name}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div id='harmonicfilter-div1'>
                            <label>
                                <span>Name</span>
                                <input name="name" value={formData.name} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Meter Make</span>
                                <input name="metermake" value={formData.metermake} onChange={handleChange} required />
                            </label>
                        </div>

                        <div id='harmonicfilter-div1'>
                            <label>
                                <span>Meter Serial No.</span>
                                <input type='text' name="meterslno" value={formData.meterslno} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Phase</span>
                                <select name="phase" value={formData.phase} onChange={handleChange} required>
                                    <option value=''>Select Phase</option>
                                    <option value='3-Phase'>3-Phase</option>
                                    <option value='1-Phase-Vr'>1-Phase-Vr</option>
                                    <option value='1-Phase-Vy'>1-Phase-Vy</option>
                                    <option value='1-Phase-Vb'>1-Phase-Vb</option>
                                </select>
                            </label>
                        </div>
                        <div id='harmonicfilter-div1'>
                            <label>
                                <span>Capacity (in VA)</span>
                                <input type='number' name="capacityinva" value={formData.capacityinva} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Harmonic Frequency Range</span>
                                <input type='number' name="harmonicfrequencyrange" value={formData.harmonicfrequencyrange} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='harmonicfilter-div1'>
                            <label>
                                <span>Attenuation Performance</span>
                                <input type='number' name="attenuationperformance" value={formData.attenuationperformance} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Rated Voltage</span>
                                <input type='number' name="ratedvoltage" value={formData.ratedvoltage} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='harmonicfilter-div1'>
                            <label>
                                <span>Rated Current</span>
                                <input type='number' name="ratedcurrent" value={formData.ratedcurrent} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Connection Type</span>
                                <select name="connectiontype" value={formData.connectiontype} onChange={handleChange} required>
                                    <option value=''> Select Type</option>
                                    <option value='L-L'> L-L</option>
                                    <option value='L-N'> L-N</option>
                                </select>
                                {/* <input name="connectiontype" value={formData.connectiontype} onChange={handleChange} required /> */}
                            </label>
                        </div>
                        <div id='harmonicfilter-div1'>
                            <label>
                                <span>Operation Frequency</span>
                                <input type='number' name="operationfrequency" value={formData.operationfrequency} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Efficiency</span>
                                <input type='number' name="efficiency" value={formData.efficiency} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='harmonicfilter-div1'>
                            <label>
                                <span>Auto Select</span>
                                <input name="autoselect" value={formData.autoselect} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Overcurrent Protection Available</span>
                                <select name="overcurrentprotectionavailable" value={formData.overcurrentprotectionavailable} onChange={handleChange} required>
                                    <option value=''>Select Option</option>
                                    <option value='Y'>Yes</option>
                                    <option value='N'>No</option>
                                </select>
                            </label>
                        </div>
                        <div id='harmonicfilter-div1'>
                            <label>
                                <span>Overvoltage Protection Available</span>
                                <select name="overvoltageprotectionavailable" value={formData.overvoltageprotectionavailable} onChange={handleChange} required>
                                    <option value=''>Select Option</option>
                                    <option value='Y'>Yes</option>
                                    <option value='N'>No</option>
                                </select>
                            </label>
                            <label>
                                <span>Cooling Method</span>
                                <input name="coolingmethod" value={formData.coolingmethod} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='harmonicfilter-div1'>
                            <label>
                                <span>Proposed Periodicity of Maintenance</span>
                                <input name="proposedperiodicityofmaintenance" value={formData.proposedperiodicityofmaintenance} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Previous Maintenance Done Date</span>
                                <input type='date' name="previousmaintenancedonedate" value={formData.previousmaintenancedonedate} onChange={handleChange} required />
                            </label>
                        </div>
                        {/* <div id='harmonicfilter-div1'>
                            <label>
                                <span>Spare1</span>
                                <input name="spare1" value={formData.spare1} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Spare2</span>
                                <input name="spare2" value={formData.spare2} onChange={handleChange} required />
                            </label>
                        </div> */}
                        <div id='harmonicfilter-divbottom'>
                            <button id='harmonicfilter-save' type="submit">{Masterformsheadingsss.Submitheading}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Harmonicfilter;
