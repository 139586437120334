import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './Transformerformfetch.css';
import Superadminsidebar from '../../Superadmin/Sidebar/Sidebar';
import { Link } from 'react-router-dom';
import { TransformerFormHeader } from '../../Operator/Data';

const Main_TransformerFormFetch = () => {
    const [transformers, setTransformers] = useState([]);
    const [editData, setEditData] = useState(null);
    const [loggedInUser, setLoggedInUser] = useState(null);

    useEffect(() => {
        fetchTransformers();
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
        }
    }, []);

    const fetchTransformers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/maintenance-transformers`);
            setTransformers(response.data);
        } catch (error) {
            console.error('Error fetching transformers:', error);
        }
    };

    const handleEditClick = (transformer) => {
        const user = JSON.parse(localStorage.getItem('user'));
        setEditData({
            ...transformer,
            createdby: user ? user.username : ''
        });
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditData({ ...editData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const updatedTransformerData = {
            ...editData,
            modifiedby: loggedInUser ? loggedInUser.username : '',
        };

        try {
            console.log('Submitting Edit Data:', updatedTransformerData);
            const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/maintenance-transformers/${editData._id}`, updatedTransformerData);
            console.log('Response:', response.data);
            fetchTransformers();
            setEditData(null);
            alert("Transformer updated successfully");
        } catch (error) {
            console.error('Error updating transformer:', error.response ? error.response.data : error.message);
            alert('Failed to update transformer. Please try again later.');
        }
    };

    const conditionOptions = ["Please Select", "OK", "NOT OK"];

    const renderInputField = (label, name, type = "text", required = true) => (
        <label>
            <span>{label}</span>
            <input
                type={type}
                name={name}
                value={editData[name]}
                onChange={handleEditChange}
                required={required}
            />
        </label>
    );

    const renderSelectField = (label, name, options, required = true) => (
        <label>
            <span>{label}</span>
            <select
                name={name}
                value={editData[name]}
                onChange={handleEditChange}
                required={required}
            >
                {options.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                ))}
            </select>
        </label>
    );

    return (
        <div id='main-transformerfetch-total'>
            <div>
                <Superadminsidebar />
            </div>
            <div id='main-transformerfetch-right'>
                <div id='super-regfetch-right-1'>
                    <div id='super-regfetch-heading'>
                        {TransformerFormHeader.alertHeading}
                    </div>
                    <div id='super-regfetch-heading1'>
                        {TransformerFormHeader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={TransformerFormHeader.logoSrc} alt='logo' />
                    </div>
                </div>
                <div id='super-regfetch-right-2'>
                    <div id='super-regfetch-right-21'>
                        <label>
                            <Link to='/Main_transformerform'><button>Form</button></Link>
                        </label>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Date of Maintenance</th>
                                <th>Proposed Periodicity (Months)</th>
                                <th>IP Power Before</th>
                                <th>OP Power Before</th>
                                <th>IP Power After</th>
                                <th>OP Power After</th>
                                <th>Primary Voltage (kV)</th>
                                <th>Primary Voltage Lower Threshold (kV)</th>
                                <th>Primary Voltage Higher Threshold (kV)</th>
                                <th>Secondary Voltage (V)</th>
                                <th>Secondary Voltage Lower Threshold (V)</th>
                                <th>Secondary Voltage Higher Threshold (V)</th>
                                <th>Transformer Rating (kVA)</th>
                                <th>Load Current (A)</th>
                                <th>Load Current Lower Threshold (A)</th>
                                <th>Load Current Higher Threshold (A)</th>
                                <th>Impedance</th>
                                <th>Threshold Impedance</th>
                                <th>Temperature (°C)</th>
                                <th>Temperature Lower Threshold (°C)</th>
                                <th>Temperature Higher Threshold (°C)</th>
                                <th>Oil Level</th>
                                <th>Oil Level Threshold</th>
                                <th>Oil Temperature (°C)</th>
                                <th>Oil Temperature Threshold (°C)</th>
                                <th>Winding Temperature (°C)</th>
                                <th>Winding Temperature Threshold (°C)</th>
                                <th>Hysteresis Loss (kW)</th>
                                <th>Eddy Current Loss (kW)</th>
                                <th>Total Core Losses (kW)</th>
                                <th>Copper Loss at Full Load (kW)</th>
                                <th>Stray Losses (kW)</th>
                                <th>Dielectric Losses</th>
                                <th>Magnetostriction and Mechanical Losses (kW)</th>
                                <th>Insulation Resistance (MΩ)</th>
                                <th>Insulation Resistance Threshold (MΩ)</th>
                                <th>Dissolved Gas Analysis (ppm)</th>
                                <th>Dissolved Gas Analysis Threshold (ppm)</th>
                                <th>Radiator Condition Threshold</th>
                                <th>Radiator Condition</th>
                                <th>Cooling Fan Operation</th>
                                <th>Cooling Fan Operation Threshold</th>
                                <th>Pump Operation</th>
                                <th>Pump Operation Threshold</th>
                                <th>Connections</th>
                                <th>Connections Threshold</th>
                                <th>Bushing Condition</th>
                                <th>Bushing Condition Threshold</th>
                                <th>Buchholz Relay</th>
                                <th>Buchholz Relay Threshold</th>
                                <th>Pressure Relief Device</th>
                                <th>Pressure Relief Device Threshold</th>
                                <th>Temperature Gauge</th>
                                <th>Temperature Gauge Threshold</th>
                                <th>Oil Level Indicator</th>
                                <th>Oil Level Indicator Threshold</th>
                                <th>Leaks</th>
                                <th>Leaks Threshold</th>
                                <th>Noise Levels (dB)</th>
                                <th>Noise Levels Threshold (dB)</th>
                                <th>Cleanliness</th>
                                <th>Cleanliness Threshold</th>
                                <th>CT Tests</th>
                                <th>Winding Temperature</th>
                                <th>Thermal Image</th>
                                <th>Bushing Power Factoring</th>
                                <th>Transformer Power Factoring</th>
                                <th>Auxiliary Power</th>
                                <th>Voltage Ratio</th>
                                <th>Automatic Transfer Switch</th>
                                <th>Polarity</th>
                                <th>Cooling System</th>
                                <th>Transformer Turns Ratio</th>
                                <th>Bushing Potential Device</th>
                                <th>Tap Changers</th>
                                <th>Auxiliary Equipment Protection</th>
                                <th>Alarms Short Circuit Impedance</th>
                                <th>Overall Loading</th>
                                <th>Zero Sequence</th>
                                <th>Trip Checks</th>
                                <th>Winding Resistance</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transformers.map((transformer) => (
                                <tr key={transformer._id}>
                                    <td>{transformer.name}</td>
                                    <td>{transformer.date_of_maintenance}</td>
                                    <td>{transformer.proposed_periodicity_of_maintenance_months}</td>
                                    <td>{transformer.i_p_power_before}</td>
                                    <td>{transformer.o_p_power_before}</td>
                                    <td>{transformer.i_p_power_after}</td>
                                    <td>{transformer.o_p_power_after}</td>
                                    <td>{transformer.primary_voltage_kv}</td>
                                    <td>{transformer.primary_voltage_lower_threshold_kv}</td>
                                    <td>{transformer.primary_voltage_higher_threshold_kv}</td>
                                    <td>{transformer.secondary_voltage_v}</td>
                                    <td>{transformer.secondary_voltage_lower_threshold_v}</td>
                                    <td>{transformer.secondary_voltage_higher_threshold_v}</td>
                                    <td>{transformer.transformer_rating_kva}</td>
                                    <td>{transformer.load_current_a}</td>
                                    <td>{transformer.load_current_lower_threshold_a}</td>
                                    <td>{transformer.load_current_higher_threshold_a}</td>
                                    <td>{transformer.impedance}</td>
                                    <td>{transformer.threshold_impedance}</td>
                                    <td>{transformer.temperature_c}</td>
                                    <td>{transformer.temperature_lower_threshold_c}</td>
                                    <td>{transformer.temperature_higher_threshold_c}</td>
                                    <td>{transformer.oil_level}</td>
                                    <td>{transformer.oil_level_threshold}</td>
                                    <td>{transformer.oil_temperature_c}</td>
                                    <td>{transformer.oil_temperature_threshold_c}</td>
                                    <td>{transformer.winding_temperature_c}</td>
                                    <td>{transformer.winding_temperature_threshold_c}</td>
                                    <td>{transformer.hysteresis_loss_kw}</td>
                                    <td>{transformer.eddy_current_loss_kw}</td>
                                    <td>{transformer.total_core_losses_kw}</td>
                                    <td>{transformer.copper_loss_at_full_load_kw}</td>
                                    <td>{transformer.stray_losses_kw}</td>
                                    <td>{transformer.dielectric_losses}</td>
                                    <td>{transformer.magnetostriction_and_mechanical_losses_kw}</td>
                                    <td>{transformer.insulation_resistance_mohm}</td>
                                    <td>{transformer.insulation_resistance_threshold_mohm}</td>
                                    <td>{transformer.dissolved_gas_analysis_ppm}</td>
                                    <td>{transformer.dissolved_gas_analysis_threshold_ppm}</td>
                                    <td>{transformer.radiator_condition_threshold}</td>
                                    <td>{transformer.radiator_condition}</td>
                                    <td>{transformer.cooling_fan_operation}</td>
                                    <td>{transformer.cooling_fan_operation_threshold}</td>
                                    <td>{transformer.pump_operation}</td>
                                    <td>{transformer.pump_operation_threshold}</td>
                                    <td>{transformer.connections}</td>
                                    <td>{transformer.connections_threshold}</td>
                                    <td>{transformer.bushing_condition}</td>
                                    <td>{transformer.bushing_condition_threshold}</td>
                                    <td>{transformer.buchholz_relay}</td>
                                    <td>{transformer.buchholz_relay_threshold}</td>
                                    <td>{transformer.pressure_relief_device}</td>
                                    <td>{transformer.pressure_relief_device_threshold}</td>
                                    <td>{transformer.temperature_gauge}</td>
                                    <td>{transformer.temperature_gauge_threshold}</td>
                                    <td>{transformer.oil_level_indicator}</td>
                                    <td>{transformer.oil_level_indicator_threshold}</td>
                                    <td>{transformer.leaks}</td>
                                    <td>{transformer.leaks_threshold}</td>
                                    <td>{transformer.noise_levels_db}</td>
                                    <td>{transformer.noise_levels_threshold_db}</td>
                                    <td>{transformer.cleanliness}</td>
                                    <td>{transformer.cleanliness_threshold}</td>
                                    <td>{transformer.ct_tests}</td>
                                    <td>{transformer.winding_temperature}</td>
                                    <td>{transformer.thermal_image}</td>
                                    <td>{transformer.bushing_power_factoring}</td>
                                    <td>{transformer.transformer_power_factoring}</td>
                                    <td>{transformer.auxiliary_power}</td>
                                    <td>{transformer.voltage_ratio}</td>
                                    <td>{transformer.automatic_transfer_switch}</td>
                                    <td>{transformer.polarity}</td>
                                    <td>{transformer.cooling_system}</td>
                                    <td>{transformer.transformer_turns_ratio}</td>
                                    <td>{transformer.bushing_potential_device}</td>
                                    <td>{transformer.tap_changers}</td>
                                    <td>{transformer.auxiliary_equipment_protection}</td>
                                    <td>{transformer.alarms_short_circuit_impedance}</td>
                                    <td>{transformer.overall_loading}</td>
                                    <td>{transformer.zero_sequence}</td>
                                    <td>{transformer.trip_checks}</td>
                                    <td>{transformer.winding_resistance}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(transformer)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {editData && (
                        <Popup open={editData !== null} onClose={() => setEditData(null)}>
                            <div id='main-transformerfetch-popup'>
                                <h3>Edit Transformer</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Name", "name")}
                                        {renderInputField("Date of Maintenance", "date_of_maintenance", "datetime-local")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Proposed Periodicity (Months)", "proposed_periodicity_of_maintenance_months", "number")}
                                        {renderInputField("IP Power Before", "i_p_power_before", "number")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("OP Power Before", "o_p_power_before", "number")}
                                        {renderInputField("IP Power After", "i_p_power_after", "number")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("OP Power After", "o_p_power_after", "number")}
                                        {renderInputField("Primary Voltage (kV)", "primary_voltage_kv", "number")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Primary Voltage Lower Threshold (kV)", "primary_voltage_lower_threshold_kv", "number")}
                                        {renderInputField("Primary Voltage Higher Threshold (kV)", "primary_voltage_higher_threshold_kv", "number")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Secondary Voltage (V)", "secondary_voltage_v", "number")}
                                        {renderInputField("Secondary Voltage Lower Threshold (V)", "secondary_voltage_lower_threshold_v", "number")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Secondary Voltage Higher Threshold (V)", "secondary_voltage_higher_threshold_v", "number")}
                                        {renderInputField("Transformer Rating (kVA)", "transformer_rating_kva", "number")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Load Current (A)", "load_current_a", "number")}
                                        {renderInputField("Load Current Lower Threshold (A)", "load_current_lower_threshold_a", "number")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Load Current Higher Threshold (A)", "load_current_higher_threshold_a", "number")}
                                        {renderInputField("Impedance", "impedance", "number")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Threshold Impedance", "threshold_impedance", "number")}
                                        {renderInputField("Temperature (°C)", "temperature_c", "number")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Temperature Lower Threshold (°C)", "temperature_lower_threshold_c", "number")}
                                        {renderInputField("Temperature Higher Threshold (°C)", "temperature_higher_threshold_c", "number")}

                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Oil Level", "oil_level")}
                                        {renderInputField("Oil Level Threshold", "oil_level_threshold")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Oil Temperature (°C)", "oil_temperature_c", "number")}
                                        {renderInputField("Oil Temperature Threshold (°C)", "oil_temperature_threshold_c", "number")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Winding Temperature (°C)", "winding_temperature_c", "number")}
                                        {renderInputField("Winding Temperature Threshold (°C)", "winding_temperature_threshold_c", "number")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Hysteresis Loss (kW)", "hysteresis_loss_kw", "number")}
                                        {renderInputField("Eddy Current Loss (kW)", "eddy_current_loss_kw", "number")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Total Core Losses (kW)", "total_core_losses_kw", "number")}
                                        {renderInputField("Copper Loss at Full Load (kW)", "copper_loss_at_full_load_kw", "number")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Stray Losses (kW)", "stray_losses_kw", "number")}
                                        {renderInputField("Dielectric Losses", "dielectric_losses", "number")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Magnetostriction and Mechanical Losses (kW)", "magnetostriction_and_mechanical_losses_kw", "number")}
                                        {renderInputField("Insulation Resistance (MΩ)", "insulation_resistance_mohm", "number")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Insulation Resistance Threshold (MΩ)", "insulation_resistance_threshold_mohm", "number")}
                                        {renderInputField("Dissolved Gas Analysis (ppm)", "dissolved_gas_analysis_ppm")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Dissolved Gas Analysis Threshold (ppm)", "dissolved_gas_analysis_threshold_ppm")}
                                        {renderSelectField("Radiator Condition Threshold", "radiator_condition_threshold", conditionOptions)}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderSelectField("Radiator Condition", "radiator_condition", conditionOptions)}
                                        {renderSelectField("Cooling Fan Operation", "cooling_fan_operation", conditionOptions)}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderSelectField("Cooling Fan Operation Threshold", "cooling_fan_operation_threshold", conditionOptions)}
                                        {renderSelectField("Pump Operation", "pump_operation", conditionOptions)}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderSelectField("Pump Operation Threshold", "pump_operation_threshold", conditionOptions)}
                                        {renderInputField("Connections", "connections")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Connections Threshold", "connections_threshold")}
                                        {renderInputField("Bushing Condition", "bushing_condition")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Bushing Condition Threshold", "bushing_condition_threshold")}
                                        {renderInputField("Buchholz Relay", "buchholz_relay")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Buchholz Relay Threshold", "buchholz_relay_threshold")}
                                        {renderInputField("Pressure Relief Device", "pressure_relief_device")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Pressure Relief Device Threshold", "pressure_relief_device_threshold")}
                                        {renderInputField("Temperature Gauge", "temperature_gauge")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Temperature Gauge Threshold", "temperature_gauge_threshold")}
                                        {renderInputField("Oil Level Indicator", "oil_level_indicator")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Oil Level Indicator Threshold", "oil_level_indicator_threshold")}
                                        {renderInputField("Leaks", "leaks")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Leaks Threshold", "leaks_threshold")}
                                        {renderInputField("Noise Levels (dB)", "noise_levels_db", "number")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Noise Levels Threshold (dB)", "noise_levels_threshold_db", "number")}
                                        {renderInputField("Cleanliness", "cleanliness")}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Cleanliness Threshold", "cleanliness_threshold")}
                                        {renderSelectField("CT Tests", "ct_tests", conditionOptions)}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderSelectField("Winding Temperature", "winding_temperature", conditionOptions)}
                                        {renderSelectField("Thermal Image", "thermal_image", conditionOptions)}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderSelectField("Bushing Power Factoring", "bushing_power_factoring", conditionOptions)}
                                        {renderSelectField("Transformer Power Factoring", "transformer_power_factoring", conditionOptions)}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderSelectField("Auxiliary Power", "auxiliary_power", conditionOptions)}
                                        {renderSelectField("Voltage Ratio", "voltage_ratio", conditionOptions)}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderSelectField("Automatic Transfer Switch", "automatic_transfer_switch", conditionOptions)}
                                        {renderSelectField("Polarity", "polarity", conditionOptions)}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderSelectField("Cooling System", "cooling_system", conditionOptions)}
                                        {renderSelectField("Transformer Turns Ratio", "transformer_turns_ratio", conditionOptions)}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderInputField("Bushing Potential Device", "bushing_potential_device")}
                                        {renderSelectField("Tap Changers", "tap_changers", conditionOptions)}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderSelectField("Auxiliary Equipment Protection", "auxiliary_equipment_protection", conditionOptions)}
                                        {renderSelectField("Alarms Short Circuit Impedance", "alarms_short_circuit_impedance", conditionOptions)}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderSelectField("Overall Loading", "overall_loading", conditionOptions)}
                                        {renderSelectField("Zero Sequence", "zero_sequence", conditionOptions)}
                                    </div>
                                    <div id='main-transformerfetch-popup1'>
                                        {renderSelectField("Trip Checks", "trip_checks", conditionOptions)}
                                        {renderSelectField("Winding Resistance", "winding_resistance", conditionOptions)}
                                    </div>
                                    <button id='main-transformerfetch-popup-update' type="submit">Update</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Main_TransformerFormFetch;
