
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import * as echarts from 'echarts';


// const PF_MD = () => {
//   const [powerFactorData, setPowerFactorData] = useState([]);
//   const [maxDemandData, setMaxDemandData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const [powerFactorResponse, maxDemandResponse] = await Promise.all([
//           axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/powerfactor`),
//           axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/maxdemand`)
//         ]);

//         setPowerFactorData(powerFactorResponse.data);
//         setMaxDemandData(maxDemandResponse.data);
//         setLoading(false);
//       } catch (error) {
//         setError(error);
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error.message}</div>;
//   }

//   return (
//     <div>
//       <h1>Power Factor Data</h1>
//       <table>
//         <thead>
//           <tr>
//             <th>Date</th>
//             <th>PF HV</th>

//           </tr>
//         </thead>
//         <tbody>
//           {powerFactorData.map((item) => (
//             <tr key={item._id}>
//               <td>{item.PF_HV}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       <h1>Maximum Demand Data</h1>
//       <table>
//         <thead>
//           <tr>
//             <th>Date</th>
//             <th>Station Point HV</th>
//             <th>Maximum Demand HV (kVA)</th>
//           </tr>
//         </thead>
//         <tbody>
//           {maxDemandData.map((item) => (
//             <tr key={item._id}>
//               <td>{item.date}</td>
//               <td>{item.stationPoint_HV}</td>
//               <td>{item.Maximum_Demand_HV_kVA}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default PF_MD;



// import React, { useEffect, useRef, useState } from 'react';
// import axios from 'axios';
// import * as echarts from 'echarts';

// const PF_MD = () => {
//   const [powerFactorData, setPowerFactorData] = useState([]);
//   const [maxDemandData, setMaxDemandData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const chartRef = useRef(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const [powerFactorResponse, maxDemandResponse] = await Promise.all([
//           axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/powerfactor`),
//           axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/maxdemand`)
//         ]);

//         setPowerFactorData(powerFactorResponse.data);
//         setMaxDemandData(maxDemandResponse.data);
//         setLoading(false);
//       } catch (error) {
//         setError(error);
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (!loading && powerFactorData.length > 0) {
//       const chartDom = chartRef.current;
//       const myChart = echarts.init(chartDom);

//       const option = {
//         tooltip: {
//           trigger: 'axis',
//           axisPointer: {
//             type: 'shadow'
//           }
//         },
//         grid: {
//           left: '1%',
//           right: '1%',
//           bottom: '1%',
//           containLabel: true
//         },
//         xAxis: {
//           type: 'category',
//           data: powerFactorData.map(item => item.date),
//           axisTick: {
//             alignWithLabel: true
//           }
//         },
//         yAxis: {
//           type: 'value',
//           min: -1,
//           max: 1, 
//           interval: 0.2,
//           axisLabel: {
//             formatter: value => value.toFixed(1) // Format the labels to one decimal place
//           }
//         },
//         series: [
//           {
//             name: 'Power Factor HV',
//             type: 'bar',
//             barWidth: '4%',
//             data: powerFactorData.map(item => item.PF_HV),
//             itemStyle: {
//               color: '#7E95DF' // Set the bar color to red
//             }
//           }
//         ]
//       };

//       myChart.setOption(option);

//       // Resize chart on window resize
//       const resizeChart = () => {
//         myChart.resize();
//       };

//       window.addEventListener('resize', resizeChart);

//       return () => {
//         window.removeEventListener('resize', resizeChart);
//         myChart.dispose();
//       };
//     }
//   }, [loading, powerFactorData]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error.message}</div>;
//   }

//   return (
//     <div>
//       <h1>Power Factor Data</h1>
//       <table>
//         <thead>
//           <tr>
//             <th>Date</th>
//             <th>PF HV</th>
//           </tr>
//         </thead>
//         <tbody>
//           {powerFactorData.map((item) => (
//             <tr key={item._id}>
//               <td>{item.date}</td>
//               <td>{item.PF_HV}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       <h1>Maximum Demand Data</h1>
//       <table>
//         <thead>
//           <tr>
//             <th>Date</th>
//             <th>Station Point HV</th>
//             <th>Maximum Demand HV (kVA)</th>
//           </tr>
//         </thead>
//         <tbody>
//           {maxDemandData.map((item) => (
//             <tr key={item._id}>
//               <td>{item.date}</td>
//               <td>{item.stationPoint_HV}</td>
//               <td>{item.Maximum_Demand_HV_kVA}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       <div ref={chartRef} style={{ width: '100%', height: '55vh', color:'red'}} />
//     </div>
//   );
// };

// export default PF_MD;



// import React, { useEffect, useRef, useState } from 'react';
// import axios from 'axios';
// import * as echarts from 'echarts';
// import Operatorsidebar from '../Operator/Sidebar/Sidebar';
// import { pf_mdheader } from '../Operator/Data';
// import './PF&MD.css';

// const PF_MD = () => {
//   const [powerFactorData, setPowerFactorData] = useState([]);
//   const [maxDemandData, setMaxDemandData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const powerFactorChartRef = useRef(null);
//   const maxDemandChartRef = useRef(null);

//   const maxValue = 1500; // Set your maximum value here
//   const threshold90 = maxValue * 0.90;
//   const threshold95 = maxValue * 0.95;

//   // Calculate default from and to dates for last 30 days
//   const today = new Date();
//   const defaultFromDate = new Date(today);
//   defaultFromDate.setDate(today.getDate() - 30);
//   const [fromDate, setFromDate] = useState(defaultFromDate.toISOString().slice(0, 10));
//   const [toDate, setToDate] = useState(today.toISOString().slice(0, 10));

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const [powerFactorResponse, maxDemandResponse] = await Promise.all([
//           axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/powerfactor?fromDate=${fromDate}&toDate=${toDate}`),
//           axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/maxdemand?fromDate=${fromDate}&toDate=${toDate}`)
//         ]);

//         setPowerFactorData(powerFactorResponse.data);
//         setMaxDemandData(maxDemandResponse.data);
//         setLoading(false);
//       } catch (error) {
//         setError(error);
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [fromDate, toDate]);

//   useEffect(() => {
//     if (!loading && powerFactorData.length > 0) {
//       const chartDom = powerFactorChartRef.current;
//       const myChart = echarts.init(chartDom);

//       const option = {
//         title: {
//           text: 'Power Factor',
//           // subtext: 'Day-wise Data'
//         },
//         tooltip: {
//           trigger: 'axis',
//           axisPointer: {
//             type: 'shadow'
//           }
//         },
//         grid: {
//           left: '1%',
//           right: '1%',
//           bottom: '1%',
//           containLabel: true
//         },
//         xAxis: {
//           type: 'category',
//           data: powerFactorData.map(item => item.date),
//           axisTick: {
//             alignWithLabel: true
//           }
//         },
//         yAxis: {
//           type: 'value',
//           min: -1,
//           max: 1,
//           interval: 0.2,
//           axisLabel: {
//             formatter: value => value.toFixed(1) // Format the labels to one decimal place
//           }
//         },
//         series: [
//           {
//             name: 'Power Factor HV',
//             type: 'bar',
//             barWidth: '30%',
//             data: powerFactorData.map(item => item.PF_HV),
//             itemStyle: {
//               color: '#7E95DF' // Set the bar color
//             }
//           }
//         ]
//       };

//       myChart.setOption(option);

//       const resizeChart = () => {
//         myChart.resize();
//       };

//       window.addEventListener('resize', resizeChart);

//       return () => {
//         window.removeEventListener('resize', resizeChart);
//         myChart.dispose();
//       };
//     }
//   }, [loading, powerFactorData]);

//   useEffect(() => {
//     if (!loading && maxDemandData.length > 0) {
//       const chartDom = maxDemandChartRef.current;
//       const myChart = echarts.init(chartDom);

//       const option = {
//         title: {
//           text: 'Maximum Demand HV (kVA)',
//           // subtext: 'Day-wise Data'
//         },
//         tooltip: {
//           trigger: 'axis',
//           axisPointer: {
//             type: 'cross'
//           }
//         },
//         toolbox: {
//           // feature: {
//           //   saveAsImage: {}
//           // }
//         },
//         grid: {
//           left: '3%',
//           right: '4%',
//           bottom: '3%',
//           containLabel: true
//         },
//         xAxis: [
//           {
//             type: 'category',
//             boundaryGap: false,
//             data: maxDemandData.map(item => item.date)
//           }
//         ],
//         yAxis: [
//           {
//             type: 'value',
//             max: maxValue,
//             axisLabel: {
//               formatter: '{value} kVA'
//             },
//             axisPointer: {
//               snap: true
//             }
//           }
//         ],
//         visualMap: {
//           show: false,
//           pieces: [
//             {
//               lte: threshold90,
//               color: 'green'
//             },
//             {
//               gt: threshold90,
//               lte: threshold95,
//               color: 'orange'
//             },
//             {
//               gt: threshold95,
//               color: 'red'
//             }
//           ]
//         },
//         series: [
//           {
//             name: 'Maximum Demand HV (kVA)',
//             type: 'line',
//             stack: 'Total',
//             areaStyle: {},
//             emphasis: {
//               focus: 'series'
//             },
//             data: maxDemandData.map(item => item.Maximum_Demand_HV_kVA)
//           }
//         ]
//       };

//       myChart.setOption(option);

//       const handleResize = () => {
//         myChart.resize();
//       };

//       window.addEventListener('resize', handleResize);

//       return () => {
//         window.removeEventListener('resize', handleResize);
//         myChart.dispose();
//       };
//     }
//   }, [loading, maxDemandData, maxValue, threshold90, threshold95]);

//   const handleFromDateChange = (event) => {
//     setFromDate(event.target.value);
//     // Ensure toDate does not exceed 30 days from fromDate
//     const selectedFromDate = new Date(event.target.value);
//     const newToDate = new Date(selectedFromDate);
//     newToDate.setDate(selectedFromDate.getDate() + 30);
//     if (newToDate > today) {
//       setToDate(today.toISOString().slice(0, 10));
//     } else {
//       setToDate(newToDate.toISOString().slice(0, 10));
//     }
//   };

//   const handleToDateChange = (event) => {
//     setToDate(event.target.value);
//     // Ensure fromDate does not exceed 30 days from toDate
//     const selectedToDate = new Date(event.target.value);
//     const newFromDate = new Date(selectedToDate);
//     newFromDate.setDate(selectedToDate.getDate() - 30);
//     if (newFromDate < defaultFromDate) {
//       setFromDate(defaultFromDate.toISOString().slice(0, 10));
//     } else {
//       setFromDate(newFromDate.toISOString().slice(0, 10));
//     }
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error.message}</div>;
//   }

//   return (


//     <div id='pdmd-total'>
//       <div>
//         <Operatorsidebar />
//       </div>
//       <div id='pdmd-right'>
//         <div id='pdmd-right-1'>
//           <div id='pdmd-heading'>
//             {pf_mdheader.alertHeading}
//           </div>
//           <div id='pdmd-heading1'>
//             {pf_mdheader.alertActions.map((action, index) => (
//               <button key={index} aria-label={action.label}>
//                 {action.icon}
//               </button>
//             ))}
//             <img src={pf_mdheader.logoSrc} alt='logo' />
//           </div>
//         </div>

//         <div id='pdmd-right-2'>

//         <div id='pdmd-right-21'>
//         <label>
//                 <span>From date</span>
//                 <input type='date' value={fromDate} max={toDate} onChange={handleFromDateChange} disabled />
//               </label>
//               <label>
//                 <span>To date</span>
//                 <input type='date' value={toDate} min={fromDate} max={today.toISOString().slice(0, 10)} onChange={handleToDateChange} disabled />
//               </label>
//                     </div>
//           <div >
//             <div id='pfgraph'>
//               <div ref={powerFactorChartRef} id='pf-graph-div' />
//             </div>
//             <div id='maxdemandgraph'>
//               <div ref={maxDemandChartRef} id='maxdemand-graph-div'/>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>


//   );
// };

// export default PF_MD;

import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';
import Operatorsidebar from '../Operator/Sidebar/Sidebar';
import { pf_mdheader } from '../Operator/Data';
import './PF&MD.css';

const PF_MD = () => {
  const [powerFactorData, setPowerFactorData] = useState([]);
  const [maxDemandData, setMaxDemandData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const powerFactorChartRef = useRef(null);
  const maxDemandChartRef = useRef(null);

  const maxValue = 1500; // Set your maximum value here
  const threshold90 = maxValue * 0.90;
  const threshold95 = maxValue * 0.95;

  const today = new Date();
const defaultFromDate = new Date(today);
defaultFromDate.setDate(today.getDate() - 30);
const [fromDate, setFromDate] = useState(defaultFromDate.toISOString().slice(0, 10));
const [toDate, setToDate] = useState(today.toISOString().slice(0, 10));


  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching data with dates:', fromDate, toDate);
        const [powerFactorResponse, maxDemandResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/powerfactor?fromDate=${fromDate}&toDate=${toDate}`),
          axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/maxdemand?fromDate=${fromDate}&toDate=${toDate}`)
        ]);

        setPowerFactorData(powerFactorResponse.data);
        setMaxDemandData(maxDemandResponse.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [fromDate, toDate]);

  useEffect(() => {
    if (!loading && powerFactorData.length > 0) {
      const chartDom = powerFactorChartRef.current;
      const myChart = echarts.init(chartDom);

      const option = {
        title: {
          text: 'Power Factor',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['Power Factor HV']
        },
        grid: {
          left: '1%',
          right: '1%',
          bottom: '1%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: powerFactorData.map(item => item.date),
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          min: -1,
          max: 1,
          interval: 0.2,
          axisLabel: {
            formatter: value => value.toFixed(1) // Format the labels to one decimal place
          }
        },
        series: [
          {
            name: 'Power Factor HV',
            type: 'bar',
            barWidth: '30%',
            data: powerFactorData.map(item => item.PF_HV),
            itemStyle: {
              color: '#7E95DF' // Set the bar color
            }
          }
        ]
      };

      myChart.setOption(option);

      const resizeChart = () => {
        myChart.resize();
      };

      window.addEventListener('resize', resizeChart);

      return () => {
        window.removeEventListener('resize', resizeChart);
        myChart.dispose();
      };
    }
  }, [loading, powerFactorData]);

  useEffect(() => {
    if (!loading && maxDemandData.length > 0) {
      const chartDom = maxDemandChartRef.current;
      const myChart = echarts.init(chartDom);

      const option = {
        title: {
          text: 'Maximum Demand HV (kVA)',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        legend: {
          data: ['Maximum Demand HV (kVA)']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: maxDemandData.map(item => item.date)
          }
        ],
        yAxis: [
          {
            type: 'value',
            max: maxValue,
            axisLabel: {
              formatter: '{value} kVA'
            },
            axisPointer: {
              snap: true
            }
          }
        ],
        visualMap: {
          show: false,
          pieces: [
            {
              lte: threshold90,
              color: 'green'
            },
            {
              gt: threshold90,
              lte: threshold95,
              color: 'orange'
            },
            {
              gt: threshold95,
              color: 'red'
            }
          ]
        },
        series: [
          {
            name: 'Maximum Demand HV (kVA)',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: maxDemandData.map(item => item.Maximum_Demand_HV_kVA)
          }
        ]
      };

      myChart.setOption(option);

      const handleResize = () => {
        myChart.resize();
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
        myChart.dispose();
      };
    }
  }, [loading, maxDemandData, maxValue, threshold90, threshold95]);

  
  const handleFromDateChange = (event) => {
    const selectedFromDate = new Date(event.target.value);
    const newToDate = new Date(selectedFromDate);
    newToDate.setDate(selectedFromDate.getDate() + 30);
  
    // Update fromDate and toDate
    setFromDate(event.target.value);
    setToDate(newToDate.toISOString().slice(0, 10));
  };
  
  const handleToDateChange = (event) => {
    const selectedToDate = new Date(event.target.value);
    const newFromDate = new Date(selectedToDate);
    newFromDate.setDate(selectedToDate.getDate() - 30);
  
    // Update toDate and fromDate
    setToDate(event.target.value);
    setFromDate(newFromDate.toISOString().slice(0, 10));
  };
  
  
  
  



  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div id='pdmd-total'>
      <div>
        <Operatorsidebar />
      </div>
      <div id='pdmd-right'>
        <div id='pdmd-right-1'>
          <div id='pdmd-heading'>
            {pf_mdheader.alertHeading}
          </div>
          <div id='pdmd-heading1'>
            {pf_mdheader.alertActions.map((action, index) => (
              <button key={index} aria-label={action.label}>
                {action.icon}
              </button>
            ))}
            <img src={pf_mdheader.logoSrc} alt='logo' />
          </div>
        </div>

        <div id='pdmd-right-2'>
          <div id='pdmd-right-21'>
            <label>
              <span>From date</span>
              <input type='date' value={fromDate} max={toDate} onChange={handleFromDateChange}  />
            </label>
            <label>
              <span>To date</span>
              <input type='date' value={toDate} min={fromDate} max={today.toISOString().slice(0, 10)} onChange={handleToDateChange} />
            </label>
          </div>
          <div>
            <div id='pfgraph'>
              <div ref={powerFactorChartRef} id='pf-graph-div' />
            </div>
            <div id='maxdemandgraph'>
              <div ref={maxDemandChartRef} id='maxdemand-graph-div' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PF_MD;



