import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import { Voltagevoltagefluctuationsheader } from '../../../Operator/Data';
import { Link } from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import './Voltageregulatorfetch.css'
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';
const Voltageregulatorfetch = () => {
    const [voltageRegulators, setVoltageRegulators] = useState([]);
    const [voltageRegulatorEditData, setVoltageRegulatorEditData] = useState(null);

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
      // Retrieve the logged-in user from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        setLoggedInUser(user);
      }
      setUserDesignation(user.level);
    }, []);

    const fetchVoltageRegulators = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/voltage_regulators`);
            setVoltageRegulators(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchVoltageRegulators();
    }, []);

    const handleEditClick = (voltageRegulator) => {
        // setVoltageRegulatorEditData(voltageRegulator);
        setVoltageRegulatorEditData({
            ...voltageRegulator,
            modifiedby: loggedInUser ? loggedInUser.username : '',
            // modifiedat: new Date().toLocaleString(),  // Set modified at to the current date and time
        });
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setVoltageRegulatorEditData({ ...voltageRegulatorEditData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        console.log('Updating data:', voltageRegulatorEditData);
        alert("Voltage Regulator updated successfully");
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/voltage_regulators/${voltageRegulatorEditData._id}`, voltageRegulatorEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchVoltageRegulators();
            setVoltageRegulatorEditData(null);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='voltageregulatorfetch-total'>

              <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />}
            </div>
            
            <div id='voltageregulatorfetch-right'>
                <div id='voltageregulatorfetch-right-1'>
                    <div id='voltageregulatorfetch-heading'>
                        {Voltagevoltagefluctuationsheader.alertHeading}
                    </div>
                    <div id='voltageregulatorfetch-heading1'>
                        {Voltagevoltagefluctuationsheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Voltagevoltagefluctuationsheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <div id='voltageregulatorfetch-right-2'>
                    <div id='voltageregulatorfetch-right-21'>
                        <label>
                            <span style={{fontWeight:'bold'}}>Current voltage regulator</span>
                        </label>
                        {/* <label>
                        <Link to='/Voltageregulator'><button>Form</button></Link>
                    </label> */}
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Token ID</th>
                                <th>Date and Time</th>
                                <th>Voltage Regulator Name</th>
                                <th>Voltage Value</th>
                                {/* <th>Created By</th>
                                <th>Created At</th>
                                <th>Modified By</th>
                                <th>Modified At</th> */}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {voltageRegulators.map((voltageRegulator) => (
                                <tr key={voltageRegulator._id}>
                                    <td>{voltageRegulator.token_id}</td>
                                    <td>{voltageRegulator.dateandtime}</td>
                                    <td>{voltageRegulator.voltage_regulatorname}</td>
                                    <td>{voltageRegulator.voltage_value}</td>
                                    {/* <td>{voltageRegulator.createdby}</td>
                                    <td>{voltageRegulator.createdat}</td>
                                    <td>{voltageRegulator.modifiedby}</td>
                                    <td>{voltageRegulator.modifiedat}</td> */}
                                    <td>
                                        <button onClick={() => handleEditClick(voltageRegulator)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {voltageRegulatorEditData && (
                        <Popup open={true} onClose={() => setVoltageRegulatorEditData(null)}>
                            <div id='voltageregulatorfetch-popup'>
                                <h3>Edit Current Voltage Regulator</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='voltageregulatorfetch-popup1'>
                                        <label>
                                            <span>Token ID</span>
                                            <input type="text" name="token_id" value={voltageRegulatorEditData.token_id} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Date and Time</span>
                                            <input type="text" name="dateandtime" value={voltageRegulatorEditData.dateandtime} onChange={handleEditChange} disabled />
                                        </label>

                                    </div>
                                    <div id='voltageregulatorfetch-popup1'>

                                        <label>
                                            <span>Voltage Regulator Name <span id='all-mandetorymark'>*</span></span>
                                            <input type="text" name="voltage_regulatorname" value={voltageRegulatorEditData.voltage_regulatorname} onChange={handleEditChange} required />
                                        </label>
                                        <label>
                                            <span>Voltage Value <span id='all-mandetorymark'>*</span></span>
                                            <input type="number" name="voltage_value" value={voltageRegulatorEditData.voltage_value} onChange={handleEditChange} required />
                                        </label>

                                    </div>
                                    <div id='voltageregulatorfetch-popup1'>

                                        {/* <label>
                                            <span>Created By</span>
                                            <input type="text" name="createdby" value={voltageRegulatorEditData.createdby} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Created At</span>
                                            <input type="text" name="createdat" value={voltageRegulatorEditData.createdat} onChange={handleEditChange} disabled />
                                        </label> */}
                                    </div>
                                    <div id='voltageregulatorfetch-popup1'>

                                        {/* <label>
                                            <span>Modified By</span>
                                            <input type="text" name="modifiedby" value={voltageRegulatorEditData.modifiedby} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Modified At</span>
                                            <input type="text" name="modifiedat" value={voltageRegulatorEditData.modifiedat} onChange={handleEditChange} disabled />
                                        </label> */}
                                    </div>
                                    <button id='voltageregulatorfetch-popup-update' type="submit">Update</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Voltageregulatorfetch;
