import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TargetEfficiency.css'
import { useLocation } from 'react-router-dom';
import { formatDateToMMYYYY } from '../../OperatorForms/Dateformat';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const convertTMonthInputFormat = (dateString) => {
    const [mm, yyyy] = dateString.split('-');
    return `${yyyy}-${mm}`;
};
const TargetEfficiency = ({ onViewData }) => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const unfilledMonth = queryParams.get('month') || '';

    const getCurrentMonth = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month, so we add 1
        return `${year}-${month}`;
    };

    const [formData, setFormData] = useState({
        month: unfilledMonth ? convertTMonthInputFormat(unfilledMonth) : getCurrentMonth(),
        target_efficiency: '',
        createdby: '',
    });

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [errors, setErrors] = useState({});
    const [userDesignation, setUserDesignation] = useState('');

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
            setFormData((prevData) => ({
                ...prevData,
                createdby: user.username
            }));
            setUserDesignation(user.level);
        }
    }, []);


    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'target_efficiency') {
            // Validate the target_efficiency field
            let formattedValue = value;
            let errorMessage = '';
            
            if (formattedValue > 100) {
                formattedValue = 100;
                errorMessage = 'Target efficiency cannot be more than 100';
            }

            // Only allow numbers up to two decimal places
            if (/^\d+(\.\d{0,2})?$/.test(formattedValue) || formattedValue === '') {
                setFormData((prevData) => ({
                    ...prevData,
                    target_efficiency: formattedValue
                }));
            }
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if any error exists in the errors object
        const hasErrors = Object.values(errors).some(errorMessage => errorMessage !== '');

        if (hasErrors) {
            toast.error('Please fix the errors before submitting');
            return; // Stop form submission if there are any errors
        }


        const formattedMonth = formatDateToMMYYYY(formData.month);

        const formattedData = {
            ...formData,
            month: formattedMonth,
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/target-efficiency`, formattedData);
            if (response.data.success) {
                setFormData({
                    month: unfilledMonth ? convertTMonthInputFormat(unfilledMonth) : getCurrentMonth(),
                    target_efficiency: '',
                    createdby: loggedInUser.username,
                });
                toast.success(' Target Efficiency data saved successfully');
            }
        } catch (error) {
            console.error('Error Adding Power bill', error);
            if (error.response && error.response.data.message === 'A log already exists for the month') {
                toast.error('Data already exists for the Selected Month, Please select any other Month');
            } else {
                toast.error('Failed to add Target Efficiency. Please try again later.');
            }
        }
    };

    return (
        <div id='targetefficiency-total'>
            <div id='targetefficiency-right'>

                <div id='targetefficiency-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='targetefficiency-divtop'>

                            <label>
                                <button type="button" onClick={onViewData}>View Data</button>
                            </label>
                        </div>
                        <div id='targetefficiency-div1'>
                            <label>
                                <span>Month<span id='all-mandetorymark'>*</span></span>
                                <input type='month' name='month' value={formData.month} onChange={handleChange} max={getCurrentMonth()} required />
                            </label>
                            <label>
                                <span>Target Efficiency (%)<span id='all-mandetorymark'>*</span></span>
                                <input type='number' name='target_efficiency' value={formData.target_efficiency} onChange={handleChange} required
                                 step="0.01"
                                 min="0"
                                 max="100" />
                                {errors.target_efficiency && <span className="error">{errors.target_efficiency}</span>}
                            </label>
                        </div>

                        <div id='targetefficiency-div1'>
                            <label>
                                <button id='targetefficiency-save' type="submit">Submit</button>
                            </label>

                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}

export default TargetEfficiency