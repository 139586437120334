import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DashPending_Task.css'
import Unfilledforms from './Unfilledforms/Unfilledforms';
import { Link, useNavigate } from 'react-router-dom';
import DashPendingDiagnosis from './PendingDiagnosis/PendingDiagnosis';
import DashPendingActions from './DashPendingActions/DashPendingActions';
import DashPendingreviews from './DashPendingreviews/DashPendingreviews';
import DashRevenueloss_Actions from './Revenuelosspending/Revenuelosspending';
// import DashClosedTasks from './DashClosedTasks/DashClosedTasks';
const DashPending_Task = () => {
  const [activeSection, setActiveSection] = useState('Unfilledforms-1');
  const [diagnosisCount, setDiagnosisCount] = useState(0);
  const [actionsCount, setActionsCount] = useState(0);
  const [reviewsCount, setReviewsCount] = useState(0);
  const [closedCount, setClosedCount] = useState(0);
  const [unfilledFormsCount, setUnfilledFormsCount] = useState(0);
  const [revenueLossSuggestionsCount, setRevenueLossSuggestionsCount] = useState(0);
  const [username, setUsername] = useState('');
  const [designation, setDesignation] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve the user data from local storage
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setUsername(user.username);
      setDesignation(user.designation);
    }
  }, []);

  const handleLiveSummaryClick = (e) => {
    if (designation !== 'Level-4') {
      e.preventDefault();
      setShowPopup(true);
    }
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    navigate('/Pending_Tasks');
  };

  const handlePopupCancel = () => {
    setShowPopup(false);
    // Add cancel logic here (if needed)
  };

  useEffect(() => {
    const fetchDiagnosisCount = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/assignedtasks/do-it-later`);
        setDiagnosisCount(response.data.length);

        const actionsResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/assignedtasks/todo`);
        setActionsCount(actionsResponse.data.length);

        const reviewResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/assignedtasks/reassigned-todo`);
        setReviewsCount(reviewResponse.data.length);

        const closedResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/assignedtasks/closed`);
        setClosedCount(closedResponse.data.length);

        // Fetch revenue loss suggestions count
        const revenueLossResponses = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-utilizationpercantage`),
          axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-transformerloss`),
          axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-voltagediagnose`),
          axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-lowpfdiagnose`),
          axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-powersurgediagnose`),
          axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-idleloss`),
          axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-todloss`),
          axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-leadingpfdiagnose`),
          axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-unbalncedloaddiagnose`),
          axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-thddiagnose`)
        ]);

        const totalRevenueLossSuggestionsCount = revenueLossResponses.reduce((acc, response) => acc + response.data.length, 0);
        setRevenueLossSuggestionsCount(totalRevenueLossSuggestionsCount);

      } catch (error) {
        console.error('Error fetching diagnosis count:', error);
      }
    };

    fetchDiagnosisCount();
  }, []);

  const handleButtonClick = (section) => {
    setActiveSection(section);
  };

  return (
    <div id='dashpedning-total'>
      <div id='dashpedning-total-1'>
        <div  id='dashpedning-topflex'>
          <span>Pending Tasks</span>
          {/* <button>View More</button> */}
          <Link to='/Pending_Tasks' ><button>View More</button>  </Link>
        </div>
        <div id='dashpedning-tot-123'>
          <div id='dashpedning-tot-1234' >
            <div id='dashpedning-div-1' >
              <div id='dashpedning-actionhead'>Action Items</div>
            </div>
            <label id='dashpending-line'></label>
            <div id='dashpedning-div-2'>
              <div id='dashpedning-actionhead'>Tasks</div>
            </div>
          </div>
          {/* --------- */}
          <div id='dashpedning-total-2'>

            <div id='dashpedning-belowleft'>
              <button
                id='dashpedning-clickbutton'
                className={activeSection === 'Unfilledforms-1' ? 'active' : ''}
                onClick={() => handleButtonClick('Unfilledforms-1')}
              >
                Unfilled Forms ({unfilledFormsCount})
              </button>
              <button
                id='dashpedning-clickbutton1'
                className={activeSection === 'DashPendingDiagnosis-1' ? 'active' : ''}
                onClick={() => handleButtonClick('DashPendingDiagnosis-1')}
              >
                Pending Diagnosis ({diagnosisCount})
              </button>
              <button
                id='dashpedning-clickbutton2'
                className={activeSection === 'DashPendingActions-1' ? 'active' : ''}
                onClick={() => handleButtonClick('DashPendingActions-1')}
              >
                Pending Actions ({actionsCount})
              </button>
              <button
                id='dashpedning-clickbutton2'
                className={activeSection === 'DashPendingreviews-1' ? 'active' : ''}
                onClick={() => handleButtonClick('DashPendingreviews-1')}
              >
                Pending Reviews ({reviewsCount})
              </button>
              <button
                id='dashpedning-clickbutton2'
                className={activeSection === 'DashRevenueloss_Actions-1' ? 'active' : ''}
                onClick={() => handleButtonClick('DashRevenueloss_Actions-1')}
              >
                Pending Revenue Loss Suggestions ({revenueLossSuggestionsCount})
              </button>

            </div>
            <label id='dashpending-line1'></label>
            <div>
              {activeSection === 'Unfilledforms-1' && <Unfilledforms setUnfilledFormsCount={setUnfilledFormsCount} />}
              {activeSection === 'DashPendingDiagnosis-1' && <DashPendingDiagnosis />}
              {activeSection === 'DashPendingActions-1' && <DashPendingActions />}
              {activeSection === 'DashPendingreviews-1' && <DashPendingreviews />}
              {activeSection === 'DashRevenueloss_Actions-1' && <DashRevenueloss_Actions />}

            </div>
          </div>
        </div>

      </div>

    </div>
  )
}

export default DashPending_Task