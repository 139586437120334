

import React, { useState, useEffect } from 'react'
import './PendingDiagnosis.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const DashPendingDiagnosis = () => {
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);
    useEffect(() => {
        const fetchDoItLaterTasks = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/assignedtasks/do-it-later`);
                setTasks(response.data);
            } catch (error) {
                console.error('Error fetching DO IT LATER tasks:', error);
            }
        };

        fetchDoItLaterTasks();
    }, []);
    const handleDiagnoseClick = (token_id) => {
        console.log("Navigating to diagnose page for token ID:", token_id);
        navigate(`/Diagnose/${token_id}`);
    };


    return (

        <div id='pendingdiagnosisdash-total'>
            {tasks.length > 0 ? (
                tasks.map((task) => (
                    <div key={task._id} id='pendingdiagnosisdash-content'>
                        <div >
                            <strong  >Token ID:</strong>
                            <span>{task.token_id}</span>
                        </div>
                        <div >
                            <strong>Pending Diagnose:</strong>
                            <span>{task.alert_heading}</span>
                        </div>
                        <div>
                            <strong>Created By:</strong>
                            <span>{task.createdby}</span>
                        </div>
                        <div >
                            <strong>Created At:</strong>
                            <span>{task.createdat}</span>
                        </div>
                        <div>
                            <button id='pendingreviewdash-top-action' onClick={() => handleDiagnoseClick(alert.token_id)}>view more</button>
                        </div>
                    </div>
                ))
            ) : (
                <div className='no-tasks'>No Pending Diagnose available</div>
            )}
        </div>
    )
}

export default DashPendingDiagnosis