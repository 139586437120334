import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import * as echarts from "echarts";
import "./TOD_runtime.css";
import { Card, Col, Row } from "antd";
import { Box, Typography } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";

// Function to convert time string to decimal hours and round to 2 decimal points
const timeStringToDecimalHours = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  const totalHours = hours + minutes / 60 + seconds / 3600;
  return Math.round(totalHours * 100) / 100; // Round to 2 decimal points
};

const DashTOD_runtime = () => {
  const [data, setData] = useState([
    {
      date: "N/A",
      incentives: { runHours: "N/A", consumption: "N/A" },
      penalty: { runHours: "N/A", consumption: "N/A" },
      normal: { runHours: "N/A", consumption: "N/A" },
    },
  ]);

  const [cummulativeenergyintesitydata, setCummulativeenergyintesityData] =
    useState([
      {
        Cumulative_energy_intensity: "N/A",
      },
    ]);

  const consumptionChartRef = useRef(null);
  const runHoursChartRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_EMS_URL}/api/todruntime-current-month`
        );
        if (response.data.length > 0) {
          setData(response.data);
        }
      } catch (error) {
        console.error("Error fetching the data", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchcummulativeenergyintesityData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_EMS_URL}/api/cummulativeenergyintesity-currentmonth`
        );

        setCummulativeenergyintesityData(response.data);
      } catch (error) {
        console.error("Error fetching the data", error);
      }
    };

    fetchcummulativeenergyintesityData();
  }, []);

  useEffect(() => {
    if (data.length === 0) return;

    // Process consumption data
    const incentives = data[0].incentives.consumption;
    const penalty = data[0].penalty.consumption;
    const normal = data[0].normal.consumption;
    const totalConsumption =
      (parseFloat(incentives) || 0) +
      (parseFloat(penalty) || 0) +
      (parseFloat(normal) || 0);

    const consumptionData = [
      { value: incentives, name: "Incentives" },
      { value: penalty, name: "Penalty" },
      { value: normal, name: "Normal" },
    ];

    // Convert run hours to decimal hours and round to 2 decimal points
    const incentivesRunHours = timeStringToDecimalHours(
      data[0].incentives.runHours
    );
    const penaltyRunHours = timeStringToDecimalHours(data[0].penalty.runHours);
    const normalRunHours = timeStringToDecimalHours(data[0].normal.runHours);
    const totalRunHours = incentivesRunHours + penaltyRunHours + normalRunHours;

    const runHoursData = [
      { value: incentivesRunHours, name: "Incentives" },
      { value: penaltyRunHours, name: "Penalty" },
      { value: normalRunHours, name: "Normal" },
    ];

    // Render consumption chart
    if (consumptionChartRef.current) {
      const consumptionChart = echarts.init(consumptionChartRef.current);
      consumptionChart.setOption({
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            name: "Consumption",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "center",
              formatter: () => `Total\n${totalConsumption.toFixed(2)}`, // Show "Total" heading with 2 decimal points
              fontSize: 20,
              fontWeight: "bold",
              color: "#000", // Change the color if needed
            },
            emphasis: {
              label: {
                show: false, // Remove hover effect
              },
              itemStyle: {
                borderWidth: 0, // Remove border on hover
              },
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              color: (params) => {
                const colors = ["#61B061", "#E16A5F", "#b8b8e6"]; // Custom colors for each segment
                return colors[params.dataIndex];
              },
            },
            data: consumptionData,
          },
        ],
      });
    }

    // Render run hours chart
    if (runHoursChartRef.current) {
      const runHoursChart = echarts.init(runHoursChartRef.current);
      runHoursChart.setOption({
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            name: "Run Hours",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "center",
              formatter: () => `Total\n${totalRunHours.toFixed(2)} hours`, // Show "Total" heading with 2 decimal points and "hours" label
              fontSize: 20,
              fontWeight: "bold",
              color: "#000", // Change the color if needed
            },
            emphasis: {
              label: {
                show: false, // Remove hover effect
              },
              itemStyle: {
                borderWidth: 0, // Remove border on hover
              },
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              color: (params) => {
                const colors = ["#61B061", "#E16A5F", "#b8b8e6"]; // Custom colors for each segment
                return colors[params.dataIndex];
              },
            },
            data: runHoursData,
          },
        ],
      });
    }
  }, [data]);

  const chartData = {
    labels: ["Actual", "Standard"],
    datasets: [
      {
        type: "line",
        label: "Actual",
        data: [cummulativeenergyintesitydata?.target_energy_intensity, cummulativeenergyintesitydata?.target_energy_intensity],
        backgroundColor: "#DB4545",
        borderColor: "#DB4545",
        borderWidth: 2,
        borderDash: [5, 5],
        pointRadius: 0,
        tension: 0,
      },
      {
        label: "Energy Intensity",
        data: [
          cummulativeenergyintesitydata?.Cumulative_energy_intensity,
          cummulativeenergyintesitydata?.target_energy_intensity,
        ],
        backgroundColor: [
          cummulativeenergyintesitydata?.Cumulative_energy_intensity <
          cummulativeenergyintesitydata?.target_energy_intensity
            ? "#61B061"
            : "#E16A5F",
         "#b8b8e6",
        ],

        borderColor: [
          cummulativeenergyintesitydata?.Cumulative_energy_intensity <
          cummulativeenergyintesitydata?.target_energy_intensity
            ? "#61B061"
            : "#E16A5F",
        "#b8b8e6",
        ],
        borderWidth: 1,
        maxBarThickness: 80,
        datalables: {
          display: false,
        },
      },
    ],
  };

  const options = {
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        title: {
          display: false,
          text: "Date",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
      },
      y: {
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        beginAtZero: true,

        title: {
          display: false,
          text: "Consuption",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "white", // Set the tooltip background color to white
        titleColor: "black", // Set the title text color to black for contrast
        bodyColor: "black",
        borderColor: "black",
        borderWidth: 1,
        yAlign: 'bottom', // Ensures tooltip is positioned above the bar
        caretSize: 5, // Adjust size of the tooltip caret
        // caretPadding:100,

        titleFont: {
          size: 15, // Increase title font size
        },
        bodyFont: {
          size: 14, // Increase body font size
        },
        padding: 12, // Increase padding inside the tooltip
        boxPadding: 10, // Padding around colored boxes
        boxWidth: 30, // Width of color indicator boxes
        boxHeight: 30, // Height of color indicator boxes
      },
    },
  };

  return (
    <div>
      <Row gutter={[10, 10]} style={{ marginTop: "10px" }}>
        <Col md={8} sm={24} span={8}>
          <Card style={{ height: "400px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "400px !important",
              }}
            >
              <Box height={40} mb={2}>
                <Typography fontWeight="bold" fontFamily="DM Sans">
                  Cummulative Energy Intensity
                </Typography>
                <Typography fontSize="12px">
                  Energy(kWh) consumed to produce 1 metric ton
                </Typography>
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  height: 300,
                  //   bgcolor: "#000",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  mb: 5,
                }}
              >
                <Bar
                  style={{ maxHeight: "300px" }}
                  data={chartData}
                  options={options}
                />
              </Box>
            </Box>
          </Card>
        </Col>

        <Col sm={24} md={16} span={16}>
          <Card style={{ height: "400px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box width={"50%"}>
                <Typography
                  fontFamily={"DM Sans"}
                  style={{ fontWeight: "bold" }}
                >
                  TOD Consumption
                </Typography>
                <div
                  ref={consumptionChartRef}
                  style={{ height: "400px" }}
                ></div>
              </Box>

              <Box width={"50%"}>
                <Typography
                  fontFamily={"DM Sans"}
                  style={{ fontWeight: "bold" }}
                >
                  TOD Run hours
                </Typography>

                <div ref={runHoursChartRef} style={{ height: "400px" }}></div>
              </Box>
            </Box>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DashTOD_runtime;
