// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-container {
    padding: 10px;
    /* border-radius: 16px !important; */
    margin-bottom: 10px;
    display:  flex;
    align-items: center !important;
    justify-content: end !important;
    gap: 20px;
  }
  
  .chart-container {
    padding: 10px;
  }
  
  .alerts-chart {
    max-height: 500px !important;
    margin-bottom: 30px;
  }
  
  .static-content-container {
    padding: 10px;
    background-color: #daecd4 !important;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .imp-container {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100% !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Trends/TodRunHours/TodRunHours.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,oCAAoC;IACpC,mBAAmB;IACnB,cAAc;IACd,8BAA8B;IAC9B,+BAA+B;IAC/B,SAAS;EACX;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,4BAA4B;IAC5B,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,oCAAoC;IACpC,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,sBAAsB;EACxB","sourcesContent":[".filter-container {\n    padding: 10px;\n    /* border-radius: 16px !important; */\n    margin-bottom: 10px;\n    display:  flex;\n    align-items: center !important;\n    justify-content: end !important;\n    gap: 20px;\n  }\n  \n  .chart-container {\n    padding: 10px;\n  }\n  \n  .alerts-chart {\n    max-height: 500px !important;\n    margin-bottom: 30px;\n  }\n  \n  .static-content-container {\n    padding: 10px;\n    background-color: #daecd4 !important;\n    border-radius: 8px;\n    margin-bottom: 10px;\n  }\n  \n  .imp-container {\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    width: 100% !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
