import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import './UPSfetch.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const UPSfetch = ({ onFormButtonClick }) => {
    const [UPSs, setUPSs] = useState([]);
    const [UPSEditData, setUPSEditData] = useState(null);
    const [parentNames, setParentNames] = useState([]);

    // Fetch parent names from API
    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-lvtransformersectionames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    // Fetch UPS data from API
    const fetchUPSs = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/ups`);
            setUPSs(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchUPSs();
    }, []);

    // Handle click on Edit button
    const handleEditClick = (UPS) => {
        setUPSEditData(UPS);
    };

    // Handle changes in edit form inputs
    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setUPSEditData({ ...UPSEditData, [name]: value });
    };

    // Handle submission of edited UPS data
    const handleEditSubmit = async (e) => {
        e.preventDefault();
        console.log('Updating data:', UPSEditData);
        toast.success("UPS updated successfully");
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/ups/${UPSEditData._id}`, UPSEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchUPSs(); // Refresh UPS data after update
            setUPSEditData(null); // Clear edit form
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='upsfetch-total'>
            <div id='upsfetch-right'>
                <div id='upsfetch-right-2'>
                    <div id='upsfetch-right-21'>
                        <label>
                            {Masterformsheadingsss.UPSHeading}
                        </label>
                        <label>
                            <button onClick={onFormButtonClick}>{Masterformsheadingsss.Formheading}</button>
                        </label>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Parent Name</th>
                                <th>Name</th>
                                <th>Meter Make</th>
                                <th>Meter SL No</th>
                                <th>Phase</th>
                                <th>UPS Topology</th>
                                <th>Input Voltage</th>
                                <th>Output Voltage</th>
                                <th>Rated Power (kVA)</th>
                                <th>Operating Efficiency</th>
                                <th>Backup Time</th>
                                <th>Connected Load (kVA)</th>
                                <th>Battery Type</th>
                                <th>Number of Batteries</th>
                                <th>Each Battery Capacity</th>
                                <th>Battery Recharge Time</th>
                                <th>Cooling System</th>
                                <th>Proposed Periodicity of Maintenance (UPS)</th>
                                <th>Proposed Periodicity of Maintenance (Batteries)</th>
                                <th>Previous Maintenance Date (UPS)</th>
                                <th>Previous Maintenance Date (Batteries)</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {UPSs.map((UPS) => (
                                <tr key={UPS._id}>
                                    <td>{UPS.date}</td>
                                    <td>{UPS.parentname}</td>
                                    <td>{UPS.name}</td>
                                    <td>{UPS.metermake}</td>
                                    <td>{UPS.meterslno}</td>
                                    <td>{UPS.phase}</td>
                                    <td>{UPS.upstopology}</td>
                                    <td>{UPS.inputvoltage}</td>
                                    <td>{UPS.outputvoltage}</td>
                                    <td>{UPS.ratedpowerinva}</td>
                                    <td>{UPS.operatingefficiency}</td>
                                    <td>{UPS.backuptime}</td>
                                    <td>{UPS.connectedloadinva}</td>
                                    <td>{UPS.batterytype}</td>
                                    <td>{UPS.numberofbatteries}</td>
                                    <td>{UPS.eachbatterycapacity}</td>
                                    <td>{UPS.batteryrechargetime}</td>
                                    <td>{UPS.coolingsystem}</td>
                                    <td>{UPS.proposedperiodicityofmaintenanceofups}</td>
                                    <td>{UPS.proposedperiodicityofmaintenanceofbatteries}</td>
                                    <td>{UPS.previousmaintenancedonedateforups}</td>
                                    <td>{UPS.previousmaintenancedonedateofbatteries}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(UPS)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {/* Popup for editing UPS data */}
                    {UPSEditData && (
                        <Popup open={true} onClose={() => setUPSEditData(null)}>
                            <div id='upsfetch-popup'>
                                <h3>Edit UPS</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='upsfetch-popup1'>
                                        <label>
                                            <span>Date</span>
                                            <input name="date" value={UPSEditData.date} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Parent Name</span>
                                            <select name="parentname" value={UPSEditData.parentname} onChange={handleEditChange}>
                                                <option value="">Select Parent Name</option>
                                                {parentNames.map((name) => (
                                                    <option key={name} value={name}>{name}</option>
                                                ))}
                                            </select>
                                        </label>
                                    </div>
                                    <div id='upsfetch-popup1'>
                                        <label>
                                            <span>Name</span>
                                            <input type="text" name="name" value={UPSEditData.name} onChange={handleEditChange} />
                                        </label>


                                        <label>
                                            <span>Meter Make</span>
                                            <input type="text" name="metermake" value={UPSEditData.metermake} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='upsfetch-popup1'>
                                        <label>
                                            <span>Meter SL No</span>
                                            <input name="meterslno" value={UPSEditData.meterslno} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Phase</span>
                                            <select name="phase" value={UPSEditData.phase} onChange={handleEditChange} required>
                                                <option value=''>Select Phase</option>
                                                <option value='3-Phase'>3-Phase</option>
                                                <option value='1-Phase-Vr'>1-Phase-Vr</option>
                                                <option value='1-Phase-Vy'>1-Phase-Vy</option>
                                                <option value='1-Phase-Vb'>1-Phase-Vb</option>
                                            </select>
                                        </label>

                                    </div>
                                    <div id='upsfetch-popup1'>
                                        <label>
                                            <span>UPS Topology</span>
                                            <input type="text" name="upstopology" value={UPSEditData.upstopology} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Input Voltage</span>
                                            <input type="number" name="inputvoltage" value={UPSEditData.inputvoltage} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='upsfetch-popup1'>
                                        <label>
                                            <span>Output Voltage</span>
                                            <input type="number" name="outputvoltage" value={UPSEditData.outputvoltage} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Rated Power (kVA)</span>
                                            <input type="number" name="ratedpowerinva" value={UPSEditData.ratedpowerinva} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='upsfetch-popup1'>
                                        <label>
                                            <span>Operating Efficiency</span>
                                            <input type="number" name="operatingefficiency" value={UPSEditData.operatingefficiency} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Backup Time</span>
                                            <input type="text" name="backuptime" value={UPSEditData.backuptime} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='upsfetch-popup1'>
                                        <label>
                                            <span>Connected Load (kVA)</span>
                                            <input type="number" name="connectedloadinva" value={UPSEditData.connectedloadinva} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Battery Type</span>
                                            <input type="text" name="batterytype" value={UPSEditData.batterytype} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='upsfetch-popup1'>
                                        <label>
                                            <span>Number of Batteries</span>
                                            <input type="number" name="numberofbatteries" value={UPSEditData.numberofbatteries} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Each Battery Capacity</span>
                                            <input type="number" name="eachbatterycapacity" value={UPSEditData.eachbatterycapacity} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='upsfetch-popup1'>
                                        <label>
                                            <span>Battery Recharge Time</span>
                                            <input type="text" name="batteryrechargetime" value={UPSEditData.batteryrechargetime} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Cooling System</span>
                                            <input type="text" name="coolingsystem" value={UPSEditData.coolingsystem} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='upsfetch-popup1'>
                                        <label>
                                            <span>Proposed Periodicity of Maintenance (UPS)</span>
                                            <input type="text" name="proposedperiodicityofmaintenanceofups" value={UPSEditData.proposedperiodicityofmaintenanceofups} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Proposed Periodicity of Maintenance (Batteries)</span>
                                            <input type="text" name="proposedperiodicityofmaintenanceofbatteries" value={UPSEditData.proposedperiodicityofmaintenanceofbatteries} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='upsfetch-popup1'>

                                        <label>
                                            <span>Previous Maintenance Date (UPS)</span>
                                            <input type="date" name="previousmaintenancedonedateforups" value={UPSEditData.previousmaintenancedonedateforups} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Previous Maintenance Date (Batteries)</span>
                                            <input type="date" name="previousmaintenancedonedateofbatteries" value={UPSEditData.previousmaintenancedonedateofbatteries} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='upsfetch-divbottom'>
                                        <button id='upsfetch-popup-update' type="submit">{Masterformsheadingsss.Updateheading}</button>
                                    </div>
                                    {/* <div id='upsfetch-popup1'> */}
                                    {/* <button onClick={() => setUPSEditData(null)}>Cancel</button> */}
                                    {/* </div> */}
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UPSfetch;
