// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#pendingactionsdash-total{
    padding: 5px;
    overflow-y: scroll;
    height:60vh;
    width: 27.55vw;

}
#pendingactionsdash-content{
    margin-top: 5px;
    border: 1px solid #cad3eb;
    border-radius: 5px;
    padding: 7px;
    background-color: white;

    strong{
        font-size: 12px;
    }
    span{
        font-size: 12px;
    }
    
    button{
        background-color: #EFF2FC;
        border: 1px solid #C4C4C4;
        border-radius: 3px;
        padding: 3px 7px;
        cursor: pointer;
    }
}
@media screen and (min-width: 1440px) {
    #pendingactionsdash-total{
        width: 28vw;
    }
   
}
@media screen and (min-width: 1920px) {
    #pendingactionsdash-total{
        width: 28.2vw;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/SuperDashboards/Tab1/DashPending_Task/DashPendingActions/DashPendingActions.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,cAAc;;AAElB;AACA;IACI,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,uBAAuB;;IAEvB;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;;IAEA;QACI,yBAAyB;QACzB,yBAAyB;QACzB,kBAAkB;QAClB,gBAAgB;QAChB,eAAe;IACnB;AACJ;AACA;IACI;QACI,WAAW;IACf;;AAEJ;AACA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":["#pendingactionsdash-total{\n    padding: 5px;\n    overflow-y: scroll;\n    height:60vh;\n    width: 27.55vw;\n\n}\n#pendingactionsdash-content{\n    margin-top: 5px;\n    border: 1px solid #cad3eb;\n    border-radius: 5px;\n    padding: 7px;\n    background-color: white;\n\n    strong{\n        font-size: 12px;\n    }\n    span{\n        font-size: 12px;\n    }\n    \n    button{\n        background-color: #EFF2FC;\n        border: 1px solid #C4C4C4;\n        border-radius: 3px;\n        padding: 3px 7px;\n        cursor: pointer;\n    }\n}\n@media screen and (min-width: 1440px) {\n    #pendingactionsdash-total{\n        width: 28vw;\n    }\n   \n}\n@media screen and (min-width: 1920px) {\n    #pendingactionsdash-total{\n        width: 28.2vw;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
