

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Superadminsidebar from '../../Superadmin/Sidebar/Sidebar';
import './Designation.css';
import { Designationheader } from '../../Operator/Data';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopBar from '../../Commonfns/Topbar/Topbar';
const Designation = () => {
  const [formData, setFormData] = useState({
    level: '',
    designation: '',
    modifiedby: ''
  });
  const [loggedInUser, setLoggedInUser] = useState(null);
  const { designation, level, createdby } = formData;

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.username) {
      setLoggedInUser(user);
      setFormData((prevData) => ({
        ...prevData,
        createdby: user.username 
      }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const designationData = {
      ...formData,
      createdby: loggedInUser.username 
    };

    // console.log('Submitting Designation Data:', designationData);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/designations`, designationData);
      // console.log('Designation created:', response.data);
      toast.success('Designation Added Successfully');
      setFormData({
        level: '',
        designation: '',
        createdby: '',
        modifiedby: ''
      });
    } catch (error) {
      console.error('Error creating designation:', error);
      if (error.response && error.response.data.message === 'Designation already exists') {
        toast.error('Designation name already exists. Please choose a different name.');
      } else {
        toast.error('Failed to add designation. Please try again later.');
      }
    }
  };

  return (
    <div id='designation-total'>
      <div>
        <Superadminsidebar />
      </div>
      <div id='designation-right'>
      <TopBar section="DesignationHeader" />
        {/* <div id='designation-right-1'>
          <div id='designation-heading'>
            {Designationheader.alertHeading}
          </div>
          <div id='designation-heading1'>

          <div id='link-div'>
            
            {viewLink && ( 
                <button >
                    <a href={viewLink} target="_blank" rel="noopener noreferrer"> <TbBulbFilled />
                    Training</a>
                </button>
            )}

            </div>

            {Designationheader.alertActions.map((action, index) => (
              <button key={index} aria-label={action.label}>
                {action.icon}
              </button>
            ))}
            <img src={Designationheader.logoSrc} alt='logo' />
          </div>
        </div> */}
        <div id='designation-right-2'>
          <form onSubmit={handleSubmit}>
            <div id='designation-divtop'>
              <label>
                <Link to='/DesignationTable'><button type="button">View Data</button></Link>
              </label>
            </div>
            <div id='designation-div1'>
              <label>
                <span>Select level</span>
                <select
                  name="level"
                  value={level}
                  onChange={handleChange}
                  required
                >
                  <option value=''>Select Level</option>
                  {/* <option value='Level-1'>Level-1</option> */}
                  <option value='Level-2'>Level-2</option>
                  <option value='Level-3'>Level-3</option>
                  <option value='Level-4'>Level-4</option>
                </select>
              </label>
              <label>
                <span>Designation Name</span>
                <input
                  type="text"
                  name="designation"
                  value={designation}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <label id='designations-savediv' >
            <button id='designation-save' type="submit">Create</button>
            </label>
            <hr></hr>

            <div>
              <h4>Note:</h4>
              <div id='note-div'>
                <label>
                  <strong>Level-1: </strong>
                  <span>Executive Leadership (e.g., CXO, CEO, COO, CTO, CFO)</span>
                </label>
                <label>
                  <strong>Level-2: </strong>
                  <span>Administrator</span>
                </label>
                <label>
                  <strong>Level-3: </strong>
                  <span>Supervisory Roles (e.g., Plant Head, Operations Manager, Supervisor, Shift Supervisor)</span>
                </label>
                <label>
                  <strong>Level-4: </strong>
                  <span>Operational Staff (e.g., Operator, Technician, Associate, Field Staff)</span>
                </label>
                <p>Please feel free to customize the designation name as per your organization.</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Designation;


