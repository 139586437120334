import React, { useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJs, ArcElement, Legend, Title, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the plugin

ChartJs.register(ArcElement, Legend, Title, Tooltip, ChartDataLabels); // Register all necessary elements and datalabels

const DoughnutChartWithLabels = ({
  totalAlerts = 0,
  AlertsData = [],
  labels = [],
  colors = [],
}) => {
  const centerTextPlugin = {
    id: "centerText",
    beforeDraw: (chart) => {
      if (chart.config.type !== "doughnut") return;

      const { width, height, ctx } = chart;
      ctx.save();

      // Set font size based on chart height
      const fontSize = (height / 200).toFixed(2); // Adjusted scale for better visibility
      ctx.font = `${fontSize}em sans-serif`;
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#000"; // Text color

      // First text (Center Text)
      const text = "Alerts Raised";
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2; // Adjust position for the first line (slightly higher)
      ctx.fillText(text, textX, textY);

      // Second text (Total Alerts in bold and larger)
      ctx.font = `bold ${(fontSize * 1.5).toFixed(2)}em sans-serif`; // Make the second line bold and larger
      const number = `${totalAlerts}`; // Ensure it's a string for measureText
      const numberX = Math.round((width - ctx.measureText(number).width) / 2);
      const numberY = height / 1.5; // Position it slightly lower
      ctx.fillText(number, numberX, numberY);

      ctx.restore();
    },
  };

  // Register the custom plugin locally
  useEffect(() => {
    ChartJs.register(centerTextPlugin);

    // Cleanup the plugin on unmount
    return () => {
      ChartJs.unregister(centerTextPlugin);
    };
  }, []);

  const data = {
    labels,
    datasets: [
      {
        data: AlertsData,
        backgroundColor: colors,
        borderWidth: 1,
        borderColor: "#fff",
        datalabels: {
          anchor: "center",
        },
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        // borderColor: "black",
        // borderRadius: 25,
        // borderWidth: 2,
        color: "black",
        display: function (context) {
          var dataset = context.dataset;
          var value = dataset.data[context.dataIndex];

          return value;
        },
        font: {
          weight: "bold",
          size: "20px", // Adjust font size for better visibility
        },
        // padding: ,
      },
      centerText: {}, // Enable the center text plugin
    },
    cutout: "60%", // Adjust the cutout to leave space in the middle
  };

  return (
    <div style={{ height: "300px" }}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DoughnutChartWithLabels;
