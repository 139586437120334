// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#accumulator-totalcontent{
    margin-top: 2vh;
    table{
        margin-top: 2vh;
        background-color: white;
        width: 92vw;
        height: 60vh;
        text-align: center;
        overflow-y: scroll;
        display: block;
        border: 1px solid #DFE6FA;

        th, td {
            width: calc(92vw / 6); /* Adjust this to fit your column count */
        }
    }

}

@media screen and (min-width: 1024px) {
    #accumulator-totalcontent{
        table{
            width: 92vw;
        }
    }  
}

@media screen and (min-width: 1440px) {
    #accumulator-totalcontent{
        table{
            width: 92.3vw;
        }
    }
   
}
@media screen and (min-width: 1920px) {
    #accumulator-totalcontent{
        table{
            width: 93.2vw;
        }
    }
   
}`, "",{"version":3,"sources":["webpack://./src/Components/Operator/Sectionpointview/Accumulator.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf;QACI,eAAe;QACf,uBAAuB;QACvB,WAAW;QACX,YAAY;QACZ,kBAAkB;QAClB,kBAAkB;QAClB,cAAc;QACd,yBAAyB;;QAEzB;YACI,qBAAqB,EAAE,yCAAyC;QACpE;IACJ;;AAEJ;;AAEA;IACI;QACI;YACI,WAAW;QACf;IACJ;AACJ;;AAEA;IACI;QACI;YACI,aAAa;QACjB;IACJ;;AAEJ;AACA;IACI;QACI;YACI,aAAa;QACjB;IACJ;;AAEJ","sourcesContent":["#accumulator-totalcontent{\n    margin-top: 2vh;\n    table{\n        margin-top: 2vh;\n        background-color: white;\n        width: 92vw;\n        height: 60vh;\n        text-align: center;\n        overflow-y: scroll;\n        display: block;\n        border: 1px solid #DFE6FA;\n\n        th, td {\n            width: calc(92vw / 6); /* Adjust this to fit your column count */\n        }\n    }\n\n}\n\n@media screen and (min-width: 1024px) {\n    #accumulator-totalcontent{\n        table{\n            width: 92vw;\n        }\n    }  \n}\n\n@media screen and (min-width: 1440px) {\n    #accumulator-totalcontent{\n        table{\n            width: 92.3vw;\n        }\n    }\n   \n}\n@media screen and (min-width: 1920px) {\n    #accumulator-totalcontent{\n        table{\n            width: 93.2vw;\n        }\n    }\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
