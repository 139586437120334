import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Stebilizers.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Stebilizer = ({ onViewData }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    const initialFormData = {
        date: getCurrentDate(),
        name: '',
        // stebilizerid:'',
        parentname: '',
        metermake: '',
        meterslno: '',
        phase: '',
        voltageregulationaccuracy: '',
        inputvoltage: '',
        outputvoltage: '',
        ratedpowerinva: '',
        operatingefficiency: '',
        correctionspeed: '',
        automaticvoltageregulation: '',
        overvoltageprotection: '',
        undervoltageprotection: '',
        shortcircuitprotection: '',
        connectedloadinva: '',
        coolingsystem: '',
        proposedperiodicityofmaintenance: '',
        previousmaintenancedonedate: '',
        spare1: '',
        spare2: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-lvtransformersectionames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    const generateStabilizerId = () => {
        const randomNum = Math.floor(Math.random() * 999999) + 1;
        const paddedNum = String(randomNum).padStart(6, '0');
        return `SB-${paddedNum}`;
    };

    console.log(generateStabilizerId());


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const stebilizerId = generateStabilizerId();
            const dataToSend = {
                stebilizerid: stebilizerId,
                ...formData,
                inputvoltage: Number(formData.inputvoltage),
                outputvoltage: Number(formData.outputvoltage),
                ratedpowerinva: Number(formData.ratedpowerinva),
                operatingefficiency: Number(formData.operatingefficiency),
                correctionspeed: Number(formData.correctionspeed),
                connectedloadinva: Number(formData.connectedloadinva),
                undervoltageprotection: Number(formData.undervoltageprotection),
                voltageregulationaccuracy: Number(formData.voltageregulationaccuracy),
                overvoltageprotection: Number(formData.overvoltageprotection),
            };

            await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/stebilizers`, dataToSend);

            toast.success('Stebilizer added successfully');
            setFormData(initialFormData);
            fetchParentNames();
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    return (
        <div id='stebilizer-total'>

            <div id='stebilizer-right'>

                <div id='stebilizer-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='stebilizer-divtop'>
                            <label>
                                <span> {Masterformsheadingsss.StebilizerHeading}</span>
                            </label>
                            <label>
                                <button type="button" onClick={onViewData}>View Data</button>
                            </label>
                        </div>
                        <div id='stebilizer-div1'>
                            <label>
                                <span>Date</span>
                                <input name="date" value={formData.date} onChange={handleChange} disabled />
                            </label>
                            <label>
                                <span>Parent Name</span>
                                <select name="parentname" value={formData.parentname} onChange={handleChange} >
                                    <option value="">Select Parent Name</option>
                                    {parentNames.map((name, index) => (
                                        <option key={index} value={name}>{name}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div id='stebilizer-div1'>
                            <label>
                                <span>Name</span>
                                <input name="name" value={formData.name} onChange={handleChange} required />
                            </label>

                            <label>
                                <span>Meter Make</span>
                                <input name="metermake" value={formData.metermake} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='stebilizer-div1'>
                            <label>
                                <span>Meter Serial No.</span>
                                <input name="meterslno" value={formData.meterslno} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Phase</span>
                                <select name="phase" value={formData.phase} onChange={handleChange} required>
                                    <option value=''>Select Phase</option>
                                    <option value='3-Phase'>3-Phase</option>
                                    <option value='1-Phase-Vr'>1-Phase-Vr</option>
                                    <option value='1-Phase-Vy'>1-Phase-Vy</option>
                                    <option value='1-Phase-Vb'>1-Phase-Vb</option>
                                </select>
                            </label>
                        </div>
                        <div id='stebilizer-div1'>
                            <label>
                                <span>Voltage Regulation Accuracy</span>
                                <input name="voltageregulationaccuracy" value={formData.voltageregulationaccuracy} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Input Voltage</span>
                                <input type='number' name="inputvoltage" value={formData.inputvoltage} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='stebilizer-div1'>
                            <label>
                                <span>Output Voltage</span>
                                <input type='number' name="outputvoltage" value={formData.outputvoltage} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Rated Power (in VA)</span>
                                <input type='number' name="ratedpowerinva" value={formData.ratedpowerinva} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='stebilizer-div1'>
                            <label>
                                <span>Operating Efficiency</span>
                                <input type='number' name="operatingefficiency" value={formData.operatingefficiency} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Correction Speed</span>
                                <input type='number' name="correctionspeed" value={formData.correctionspeed} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='stebilizer-div1'>
                            <label>
                                <span>Automatic Voltage Regulation</span>
                                <select name="automaticvoltageregulation" value={formData.automaticvoltageregulation} onChange={handleChange} required>
                                    <option value=''>Select </option>
                                    <option value='Y'>Yes </option>
                                    <option value='N'>No </option>
                                </select>
                                {/* <input name="automaticvoltageregulation" value={formData.automaticvoltageregulation} onChange={handleChange} required /> */}
                            </label>
                            <label>
                                <span>Over Voltage Protection</span>
                                <input type='number' name="overvoltageprotection" value={formData.overvoltageprotection} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='stebilizer-div1'>
                            <label>
                                <span>Under Voltage Protection</span>
                                <input type='number' name="undervoltageprotection" value={formData.undervoltageprotection} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Short Circuit Protection</span>
                                <input name="shortcircuitprotection" value={formData.shortcircuitprotection} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='stebilizer-div1'>
                            <label>
                                <span>Connected Load (in kVA)</span>
                                <input type='number' name="connectedloadinva" value={formData.connectedloadinva} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Cooling System</span>
                                <input name="coolingsystem" value={formData.coolingsystem} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='stebilizer-div1'>
                            <label>
                                <span>Proposed Periodicity of Maintenance</span>
                                <input name="proposedperiodicityofmaintenance" value={formData.proposedperiodicityofmaintenance} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Previous Maintenance Done Date</span>
                                <input type="date" name="previousmaintenancedonedate" value={formData.previousmaintenancedonedate} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='stebilizer-divbottom'>
                            <label>
                                <button id='stebilizer-save' type="submit">{Masterformsheadingsss.Submitheading}</button>
                            </label>

                        </div>
                        {/* <div id='stebilizer-divbutton'> */}
                        {/* <button type="reset" onClick={() => setFormData(initialFormData)}>Reset</button> */}
                        {/* </div> */}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Stebilizer;
