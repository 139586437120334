
// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import * as echarts from 'echarts';
// import './Dashenergyintencity.css';
// const Dashenergyintencity = ({ selectedMonth }) => {
//     const [data, setData] = useState([]);
//     const chartRef = useRef(null);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/energyintensity/all`);
//                 const filteredData = response.data.filter(item => {
//                     // Extract the month from the date
//                     const itemMonth = new Date(item.date).toISOString().slice(0, 7); // 'YYYY-MM'
//                     return itemMonth === selectedMonth;
//                 });
//                 setData(filteredData);
//             } catch (err) {
//                 console.log("error fetching Energy Intencity Data")
//             }
//         };

//         fetchData();
//     }, [selectedMonth]);

//     useEffect(() => {
//         if (data.length > 0 && chartRef.current) {
//             renderChart(data);
//         }
//     }, [data]);

//     const renderChart = (data) => {
//         const dates = data.map(item => new Date(item.date).toLocaleDateString());
//         const energyIntensities = data.map(item => item.energy_intensity);

//         var chartDom = chartRef.current;
//         var myChart = echarts.init(chartDom);
//         var option = {
//             title: {
//                 // text: 'Energy Intensity'
//             },
//             tooltip: {
//                 trigger: 'axis',
//                 axisPointer: {
//                     type: 'shadow'
//                 }
//             },
//             legend: {
//                 data: ['Energy Intensity']
//             },
//             grid: {
//                 left: '3%',
//                 right: '4%',
//                 bottom: '3%',
//                 containLabel: true
//             },
//             xAxis: [
//                 {
//                     type: 'category',
//                     data: dates,
//                     axisTick: {
//                         alignWithLabel: true
//                     }
//                 }
//             ],
//             yAxis: [
//                 {
//                     type: 'value',
//                     // name: 'Intensity',
//                     nameLocation: 'middle',
//                     nameRotate: 90,
//                     nameGap: 21,
//                     axisLabel: {
//                         formatter: '{value}'
//                     }
//                 }
//             ],
//             series: [
//                 {
//                     name: 'Energy Intensity',
//                     type: 'bar',
//                     barWidth: '30%',
//                     data: energyIntensities
//                 },
//             ]
//         };

//         option && myChart.setOption(option);
//     };


//     return (
//         <div id='dashenergyinten-total'>
//             <span>Energy Intensity</span>
//             {data.length === 0 && <p>Not Available</p>}
//             <div ref={chartRef} id='dashenergyinten-barchart' />
//         </div>
//     );
// };

// export default Dashenergyintencity;





import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';
import './Dashenergyintencity.css';

const Dashenergyintencity = ({ selectedMonth }) => {
    const [data, setData] = useState([]);
    const chartRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/energyintensity/all`);
                const filteredData = response.data.filter(item => {
                    // Extract the month from the date
                    const itemMonth = new Date(item.date).toISOString().slice(0, 7); // 'YYYY-MM'
                    return itemMonth === selectedMonth;
                });
                setData(filteredData);
            } catch (err) {
                console.log("error fetching Energy Intensity Data");
            }
        };

        fetchData();
    }, [selectedMonth]);

    useEffect(() => {
        if (data.length > 0 && chartRef.current) {
            renderChart(data);
        }
    }, [data]);

    const renderChart = (data) => {
        const dates = data.map(item => new Date(item.date).toLocaleDateString());
        const energyIntensities = data.map(item => item.energy_intensity);

        var chartDom = chartRef.current;
        var myChart = echarts.init(chartDom);
        var option = {
            title: {
                // text: 'Energy Intensity'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                data: ['Energy Intensity']
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: dates,
                    axisTick: {
                        alignWithLabel: true
                    },
                    axisLabel: { interval: 0, rotate: 60 }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    // name: 'Intensity',
                    nameLocation: 'middle',
                    nameRotate: 90,
                    nameGap: 21,
                    axisLabel: {
                        formatter: '{value}'
                    }
                }
            ],
            series: [
                {
                    name: 'Energy Intensity',
                    type: 'bar',
                    barWidth: '30%',
                    data: energyIntensities
                },
            ]
        };

        option && myChart.setOption(option);
    };

    return (
        <div id='dashenergyinten-total'>
            <span>Energy Intensity</span>
            {data.length === 0 && <p style={{textAlign:'center'}}>Not Available</p>}
            {data.length > 0 && <div ref={chartRef} id='dashenergyinten-barchart' />}
        </div>
    );
};

export default Dashenergyintencity;
