
// export const getCurrentDateTime = () => {
//     const today = new Date();
//     const date = today.toISOString().split('T')[0];

//     let hours = today.getHours();
//     const minutes = today.getMinutes();
//     const seconds = today.getSeconds(); 

//     const ampm = hours >= 12 ? 'PM' : 'AM';
//     hours = hours % 12;
//     hours = hours ? hours : 12; // the hour '0' should be '12'
//     const strTime = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds) + ' ' + ampm;

//     return `${date} ${strTime}`;
// };




export const getCurrentDateTime = () => {
    const today = new Date();

    // Format the date to DD-MM-YYYY
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const year = today.getFullYear();
    const date = `${day}-${month}-${year}`;

    let hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds(); 

    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strTime = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds) + ' ' + ampm;

    return `${date} ${strTime}`;
};

