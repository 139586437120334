import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Superadminsidebar from '../../Superadmin/Sidebar/Sidebar';
import './Transformerform.css';
import { TransformerFormHeader } from '../../Operator/Data';

const Main_transformerform = () => {
    const getCurrentDateTime = () => {
        const now = new Date();
        return now.toISOString().slice(0, 16);
    };

    const [formData, setFormData] = useState({
        name: '',
        date_of_maintenance: getCurrentDateTime(),
        proposed_periodicity_of_maintenance_months: '',
        i_p_power_before: '',
        o_p_power_before: '',
        i_p_power_after: '',
        o_p_power_after: '',
        primary_voltage_kv: '',
        primary_voltage_lower_threshold_kv: '',
        primary_voltage_higher_threshold_kv: '',
        secondary_voltage_v: '',
        secondary_voltage_lower_threshold_v: '',
        secondary_voltage_higher_threshold_v: '',
        transformer_rating_kva: '',
        load_current_a: '',
        load_current_lower_threshold_a: '',
        load_current_higher_threshold_a: '',
        impedance: '',
        threshold_impedance: '',
        temperature_c: '',
        temperature_lower_threshold_c: '',
        temperature_higher_threshold_c: '',
        oil_level: '',
        oil_level_threshold: '',
        oil_temperature_c: '',
        oil_temperature_threshold_c: '',
        winding_temperature_c: '',
        winding_temperature_threshold_c: '',
        hysteresis_loss_kw: '',
        eddy_current_loss_kw: '',
        total_core_losses_kw: '',
        copper_loss_at_full_load_kw: '',
        stray_losses_kw: '',
        dielectric_losses: '',
        magnetostriction_and_mechanical_losses_kw: '',
        insulation_resistance_mohm: '',
        insulation_resistance_threshold_mohm: '',
        dissolved_gas_analysis_ppm: '',
        dissolved_gas_analysis_threshold_ppm: '',
        radiator_condition: '',
        radiator_condition_threshold: '',
        cooling_fan_operation: '',
        cooling_fan_operation_threshold: '',
        pump_operation: '',
        pump_operation_threshold: '',
        connections: '',
        connections_threshold: '',
        bushing_condition: '',
        bushing_condition_threshold: '',
        buchholz_relay: '',
        buchholz_relay_threshold: '',
        pressure_relief_device: '',
        pressure_relief_device_threshold: '',
        temperature_gauge: '',
        temperature_gauge_threshold: '',
        oil_level_indicator: '',
        oil_level_indicator_threshold: '',
        leaks: '',
        leaks_threshold: '',
        noise_levels_db: '',
        noise_levels_threshold_db: '',
        cleanliness: '',
        cleanliness_threshold: '',
        ct_tests: '',
        winding_temperature: '',
        thermal_image: '',
        bushing_power_factoring: '',
        transformer_power_factoring: '',
        auxiliary_power: '',
        voltage_ratio: '',
        automatic_transfer_switch: '',
        polarity: '',
        cooling_system: '',
        transformer_turns_ratio: '',
        tap_changers: '',
        auxiliary_equipment_protection: '',
        alarms_short_circuit_impedance: '',
        overall_loading: '',
        zero_sequence: '',
        trip_checks: '',
        winding_resistance: '',
        modifiedby: '',
        createdby: '',
    });
    const [loggedInUser, setLoggedInUser] = useState(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
            setFormData((prevData) => ({
                ...prevData,
                createdby: user.username
            }));
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const transformerData = {
            ...formData,
            createdby: loggedInUser.username
        };

        console.log('Submitting Transformer Data:', transformerData);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/maintenance-transformers`, transformerData);
            console.log('Transformer form created:', response.data);
            alert('Transformer Data Added Successfully');
            setFormData({
                name: '',
                date_of_maintenance: getCurrentDateTime(),
                proposed_periodicity_of_maintenance_months: '',
                i_p_power_before: '',
                o_p_power_before: '',
                i_p_power_after: '',
                o_p_power_after: '',
                primary_voltage_kv: '',
                primary_voltage_lower_threshold_kv: '',
                primary_voltage_higher_threshold_kv: '',
                secondary_voltage_v: '',
                secondary_voltage_lower_threshold_v: '',
                secondary_voltage_higher_threshold_v: '',
                transformer_rating_kva: '',
                load_current_a: '',
                load_current_lower_threshold_a: '',
                load_current_higher_threshold_a: '',
                impedance: '',
                threshold_impedance: '',
                temperature_c: '',
                temperature_lower_threshold_c: '',
                temperature_higher_threshold_c: '',
                oil_level: '',
                oil_level_threshold: '',
                oil_temperature_c: '',
                oil_temperature_threshold_c: '',
                winding_temperature_c: '',
                winding_temperature_threshold_c: '',
                hysteresis_loss_kw: '',
                eddy_current_loss_kw: '',
                total_core_losses_kw: '',
                copper_loss_at_full_load_kw: '',
                stray_losses_kw: '',
                dielectric_losses: '',
                magnetostriction_and_mechanical_losses_kw: '',
                insulation_resistance_mohm: '',
                insulation_resistance_threshold_mohm: '',
                dissolved_gas_analysis_ppm: '',
                dissolved_gas_analysis_threshold_ppm: '',
                radiator_condition: '',
                radiator_condition_threshold: '',
                cooling_fan_operation: '',
                cooling_fan_operation_threshold: '',
                pump_operation: '',
                pump_operation_threshold: '',
                connections: '',
                connections_threshold: '',
                bushing_condition: '',
                bushing_condition_threshold: '',
                buchholz_relay: '',
                buchholz_relay_threshold: '',
                pressure_relief_device: '',
                pressure_relief_device_threshold: '',
                temperature_gauge: '',
                temperature_gauge_threshold: '',
                oil_level_indicator: '',
                oil_level_indicator_threshold: '',
                leaks: '',
                leaks_threshold: '',
                noise_levels_db: '',
                noise_levels_threshold_db: '',
                cleanliness: '',
                cleanliness_threshold: '',
                ct_tests: '',
                winding_temperature: '',
                thermal_image: '',
                bushing_power_factoring: '',
                transformer_power_factoring: '',
                auxiliary_power: '',
                voltage_ratio: '',
                automatic_transfer_switch: '',
                polarity: '',
                cooling_system: '',
                transformer_turns_ratio: '',
                tap_changers: '',
                auxiliary_equipment_protection: '',
                alarms_short_circuit_impedance: '',
                overall_loading: '',
                zero_sequence: '',
                trip_checks: '',
                winding_resistance: '',
                modifiedby: '',
                createdby: '',
            });
        } catch (error) {
            console.error('Error creating transformer form:', error);
            alert('Failed to add transformer data. Please try again later.');
        }
    };

    const conditionOptions = ["Please Select", "OK", "NOT OK"];

    const renderInputField = (label, name, type = "text", required = true) => (
        <label>
            <span>{label}</span>
            <input
                type={type}
                name={name}
                value={formData[name]}
                onChange={handleChange}
                required={required}
            />
        </label>
    );

    const renderSelectField = (label, name, options, required = true) => (
        <label>
            <span>{label}</span>
            <select
                name={name}
                value={formData[name]}
                onChange={handleChange}
                required={required}
            >
                {options.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                ))}
            </select>
        </label>
    );



    const headingfileds = {

        heading1: "Operating Parameters",
        heading2: "Losses",
        heading3: "Insulation",
        heading4: "Cooling System",
        heading5: "Electrical Components",
        heading6: "Protective Devices",
        heading7: "General Inspection",
        heading8: "Testing"

    };

    return (
        <div id='main-transformerform-total'>
            <div>
                <Superadminsidebar />
            </div>
            <div id='main-transformerform-right'>
                <div id='main-transformerform-right-1'>
                    <div id='main-transformerform-heading'>
                        {TransformerFormHeader.alertHeading}
                    </div>
                    <div id='main-transformerform-heading1'>
                        {TransformerFormHeader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={TransformerFormHeader.logoSrc} alt='logo' />
                    </div>
                </div>
                <div id='main-transformerform-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='main-transformerform-divtop'>
                            <label>
                                <span>User Name :</span>
                                <span>#123456</span>
                            </label>
                            <label>
                                <Link to='/Main_TransformerFormFetch'><button type="button">View Data</button></Link>
                            </label>
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("Name", "name")}
                            {renderInputField("Date of Maintenance", "date_of_maintenance", "datetime-local")}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("Proposed Periodicity of Maintenance (Months)", "proposed_periodicity_of_maintenance_months", "number")}
                            {renderInputField("I/P Power Before (kW)", "i_p_power_before", "number")}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("O/P Power Before (kW)", "o_p_power_before", "number")}
                            {renderInputField("I/P Power After (kW)", "i_p_power_after", "number")}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("O/P Power After (kW)", "o_p_power_after", "number")}
                            {renderInputField("Primary Voltage (kV)", "primary_voltage_kv", "number")}
                        </div>

                        <p id='heading-inside' >{headingfileds.heading1}</p>

                        <div id='main-transformerform-div1'>
                            {renderInputField("Primary Voltage Lower Threshold (kV)", "primary_voltage_lower_threshold_kv", "number")}
                            {renderInputField("Primary Voltage Higher Threshold (kV)", "primary_voltage_higher_threshold_kv", "number")}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("Secondary Voltage (V)", "secondary_voltage_v", "number")}
                            {renderInputField("Secondary Voltage Lower Threshold (V)", "secondary_voltage_lower_threshold_v", "number")}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("Secondary Voltage Higher Threshold (V)", "secondary_voltage_higher_threshold_v", "number")}
                            {renderInputField("Transformer Rating (kVA)", "transformer_rating_kva", "number")}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("Load Current (A)", "load_current_a", "number")}
                            {renderInputField("Load Current Lower Threshold (A)", "load_current_lower_threshold_a", "number")}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("Load Current Higher Threshold (A)", "load_current_higher_threshold_a", "number")}
                            {renderInputField("Impedance", "impedance", "number")}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("Threshold Impedance", "threshold_impedance", "number")}
                            {renderInputField("Temperature (°C)", "temperature_c", "number")}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("Temperature Lower Threshold (°C)", "temperature_lower_threshold_c", "number")}
                            {renderInputField("Temperature Higher Threshold (°C)", "temperature_higher_threshold_c", "number")}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("Oil Level", "oil_level")}
                            {renderInputField("Oil Level Threshold", "oil_level_threshold")}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("Oil Temperature (°C)", "oil_temperature_c", "number")}
                            {renderInputField("Oil Temperature Threshold (°C)", "oil_temperature_threshold_c", "number")}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("Winding Temperature (°C)", "winding_temperature_c", "number")}
                            {renderInputField("Winding Temperature Threshold (°C)", "winding_temperature_threshold_c", "number")}
                        </div>

                        <p id='heading-inside' >{headingfileds.heading2}</p>

                        <div id='main-transformerform-div1'>
                            {renderInputField("Hysteresis Loss (kW)", "hysteresis_loss_kw", "number")}
                            {renderInputField("Eddy Current Loss (kW)", "eddy_current_loss_kw", "number")}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("Total Core Losses (kW)", "total_core_losses_kw", "number")}
                            {renderInputField("Copper Loss at Full Load (kW)", "copper_loss_at_full_load_kw", "number")}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("Stray Losses (kW)", "stray_losses_kw", "number")}
                            {renderInputField("Dielectric Losses", "dielectric_losses", "number")}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("Magnetostriction and Mechanical Losses (kW)", "magnetostriction_and_mechanical_losses_kw", "number")}
                        </div>
                        <p id='heading-inside' >{headingfileds.heading3}</p>
                        <div id='main-transformerform-div1'>
                            {renderInputField("Insulation Resistance (MΩ)", "insulation_resistance_mohm", "number")}
                            {renderInputField("Dissolved Gas Analysis Threshold (ppm)", "dissolved_gas_analysis_threshold_ppm")}

                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("Insulation Resistance Threshold (MΩ)", "insulation_resistance_threshold_mohm", "number")}
                            {renderInputField("Dissolved Gas Analysis (ppm)", "dissolved_gas_analysis_ppm")}
                        </div>




                        <p id='heading-inside' >{headingfileds.heading4}</p>


                        <div id='main-transformerform-div1'>
                            {renderInputField("Radiator Condition", "radiator_condition")}

                            {renderInputField("Radiator Condition Threshold", "radiator_condition_threshold")}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("Cooling Fan Operation", "cooling_fan_operation")}

                            {renderInputField("Cooling Fan Operation Threshold", "cooling_fan_operation_threshold")}
                        </div>

                        <div id='main-transformerform-div1'>
                            {renderInputField("Pump Operation", "pump_operation")}

                            {renderInputField("Pump Operation Threshold", "pump_operation_threshold")}
                        </div>

                        <p id='heading-inside' >{headingfileds.heading5}</p>



                        <div id='main-transformerform-div1'>
                            {renderInputField("Connections", "connections")}

                            {renderInputField("Connections Threshold", "connections_threshold")}
                        </div>

                        <div id='main-transformerform-div1'>
                            {renderInputField("Bushing Condition", "bushing_condition")}

                            {renderInputField("Bushing Condition Threshold", "bushing_condition_threshold")}
                        </div>

                        <p id='heading-inside' >{headingfileds.heading6}</p>

                        <div id='main-transformerform-div1'>
                            {renderInputField("Buchholz Relay", "buchholz_relay")}
                            {renderInputField("Buchholz Relay Threshold", "buchholz_relay_threshold")}


                        </div>


                        <div id='main-transformerform-div1'>
                            {renderInputField("Pressure Relief Device", "pressure_relief_device")}
                            {renderInputField("Pressure Relief Device Threshold", "pressure_relief_device_threshold")}
                        </div>

                        <div id='main-transformerform-div1'>
                            {renderInputField("Temperature Gauge", "temperature_gauge")}

                            {renderInputField("Temperature Gauge Threshold", "temperature_gauge_threshold")}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("Oil Level Indicator", "oil_level_indicator")}

                            {renderInputField("Oil Level Indicator Threshold", "oil_level_indicator_threshold")}
                        </div>

                        <p id='heading-inside' >{headingfileds.heading7}</p>


                        <div id='main-transformerform-div1'>
                            {renderInputField("Leaks", "leaks")}

                            {renderInputField("Leaks Threshold", "leaks_threshold")}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("Noise Levels (dB)", "noise_levels_db", "number")}

                            {renderInputField("Noise Levels Threshold (dB)", "noise_levels_threshold_db", "number")}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderInputField("Cleanliness", "cleanliness")}

                            {renderInputField("Cleanliness Threshold", "cleanliness_threshold")}
                        </div>


                        <p id='heading-inside' >{headingfileds.heading8}</p>



                       
                        <div id='main-transformerform-div1'>
                            {renderSelectField("Winding Temperature", "winding_temperature", conditionOptions)}
                            {renderSelectField("Thermal Image", "thermal_image", conditionOptions)}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderSelectField("Bushing Power Factoring", "bushing_power_factoring", conditionOptions)}
                            {renderSelectField("Transformer Power Factoring", "transformer_power_factoring", conditionOptions)}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderSelectField("Auxiliary Power", "auxiliary_power", conditionOptions)}
                            {renderSelectField("Voltage Ratio", "voltage_ratio", conditionOptions)}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderSelectField("Automatic Transfer Switch", "automatic_transfer_switch", conditionOptions)}
                            {renderSelectField("Polarity", "polarity", conditionOptions)}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderSelectField("Cooling System", "cooling_system", conditionOptions)}
                            {renderSelectField("Transformer Turns Ratio", "transformer_turns_ratio", conditionOptions)}
                        </div>

                        <div id='main-transformerform-div1'>
                            {renderSelectField("Bushing Potential Device", "bushing_potential_device", conditionOptions)}
                            {renderSelectField("Tap Changers", "tap_changers", conditionOptions)}

                        </div>
                        <div id='main-transformerform-div1'>
                            {renderSelectField("Auxiliary Equipment Protection", "auxiliary_equipment_protection", conditionOptions)}
                            {renderSelectField("Alarms Short Circuit Impedance", "alarms_short_circuit_impedance", conditionOptions)}

                        </div>
                        <div id='main-transformerform-div1'>
                            {renderSelectField("Overall Loading", "overall_loading", conditionOptions)}
                            {renderSelectField("Zero Sequence", "zero_sequence", conditionOptions)}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderSelectField("Trip Checks", "trip_checks", conditionOptions)}
                            {renderSelectField("Winding Resistance", "winding_resistance", conditionOptions)}
                        </div>
                        <div id='main-transformerform-div1'>
                            {renderSelectField("CT Tests", "ct_tests", conditionOptions)}

                        </div>

                        <div id='main-transformerform-div1'>
                            <button id='main-transformerform-save' type="submit">Submit</button>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Main_transformerform;
