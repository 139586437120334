
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';
import './DailyPF.css';
// import { Link } from 'react-router-dom';

const DailyPF = () => {
    const [pfData, setPfData] = useState([]);

    useEffect(() => {
        const fetchDailyPFData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/monthpowerfactor/thismonth`);
                const data = response.data;
                setPfData(data);

                // Initialize chart with fetched or default data
                initializeChart(data.length > 0 ? data[0] : {});
            } catch (err) {
                console.error('Error fetching daily PF data:', err);
                // Initialize chart with default values in case of error
                initializeChart({});
            }
        };

        fetchDailyPFData();
    }, []);

    // Function to initialize and update the chart
    const initializeChart = (data) => {
        const chartDom = document.getElementById('gauge-chart');
        const myChart = echarts.init(chartDom);

        const value = data.PF_HV || 0; // Use PF_HV from the data or 0 if not available

        const option = {
            tooltip: {
                formatter: (params) => {
                    const value = params.value;
                    return value === 0 ? 'N/A' : `{a} <br/>{b} : ${value}%`;
                }
            },
            series: [
                {
                    name: 'Power Factor',
                    type: 'gauge',
                    min: 0,
                    max: 1,
                    startAngle: 180,
                    endAngle: 360,
                    splitNumber: 4,
                    radius: '55%',
                    axisLine: {
                        lineStyle: {
                            width: 10,
                            color: [
                                [0.9, 'red'],
                                [0.95, 'orange'],
                                [1, 'green']
                            ],
                        },
                    },
                    pointer: {
                        width: 4,
                    },
                    detail: {
                        valueAnimation: true,
                        formatter: () => {
                            return value === 0 ? 'N/A' : `${value}`;
                        }
                    },
                    data: [
                        {
                            value: value, // Use PF_HV from the data or 0 if not available
                        }
                    ]
                }
            ]
        };

        myChart.setOption(option);

        window.addEventListener('resize', myChart.resize);

        return () => {
            window.removeEventListener('resize', myChart.resize);
        };
    };

    // Default values if no data
    const defaultData = {
        date: 'N/A',
        PF_HV: 0
    };

    // Use the first data item or default values
    const firstData = pfData.length > 0 ? pfData[0] : defaultData;

    return (
        <div id='dailypf'>
            <div id='dailypf-div1'>
                <span>Power Factor (PF)</span>
                {/* <Link to='/'> <button>View More</button></Link> */}
            </div>
            <div id="gauge-chart"></div>
            <div id='daily-pf-inside'>
                <label>
                    <span>Last Updated :</span>
                    {firstData.date || 'N/A'}
                    <span> &nbsp; (24 Hours)</span>
                </label>
            </div>
        </div>
    );
};

export default DailyPF;

