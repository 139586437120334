// import React, { useContext } from 'react';
// import { UserContext } from './UserContext';
// import { Link } from 'react-router-dom';

// const UserdetailsHeader = () => {
//   const { user, setUser } = useContext(UserContext);

//   const handleLogout = () => {
//     // Clear the user context
//     setUser(null);
//     // Clear the user data from localStorage
//     localStorage.removeItem('user');
//   };

//   return (
//     <div style={{ marginLeft: '80vw' }} className="header">
//       {user && (
//         <div style={{ color: 'red' }} className="user-details">
//           <span>{user.username}</span> | <span>{user.designation}</span> | 
//         <Link to='/'><button onClick={handleLogout} style={{ marginLeft: '10px' }}>Logout</button></Link>  
//         </div>
//       )}
//     </div>
//   );
// };

// export default UserdetailsHeader;








// import React, { useContext, useState } from 'react';
// import { UserContext } from './UserContext';
// import {useNavigate, Link } from 'react-router-dom';
// import { FaRegUserCircle } from "react-icons/fa";
// import './Userdetailshead.css'
// const UserdetailsHeader = () => {
//   const { user, setUser } = useContext(UserContext);
//   const navigate = useNavigate();
//   const [showDetails, setShowDetails] = useState(false);

//   const handleLogout = () => {
//     setUser(null);
//     localStorage.removeItem('user');
//     navigate('/');
//   };

//   const toggleDetails = () => {
//     setShowDetails(!showDetails);
//   };

//   return (
//     <div  className="header">

//       <div>
//         <label>
//           <FaRegUserCircle/>
//         </label>
//         <label>
//            <span>{user.username}</span>
//            <span>{user.designation}</span>
//         </label>
//       </div>

//       {user && (
//         <div className="user-details-container" style={{  position: 'relative' }}>
//           <FaRegUserCircle onClick={toggleDetails} style={{ cursor: 'pointer' }} size={24} />
//           {showDetails && (
//             <div className="user-details-dropdown" style={{
//               position: 'absolute',
//               top: '30px',
//               right: '0',
//               background: 'white',
//               border: '1px solid #ccc',
//               borderRadius: '5px',
//               padding: '10px',
//               zIndex: 1000
//             }}>
//               <div>{user.username}</div>
//               <div>{user.designation}</div>
//               <Link to='/'>
//                 <button onClick={handleLogout} style={{ marginTop: '10px' }}>Logout</button>
//               </Link>
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default UserdetailsHeader;


import React, { useContext, useState } from 'react';
import { UserContext } from './UserContext';
import { useNavigate, Link } from 'react-router-dom';
import { FaRegUserCircle } from "react-icons/fa";
import './Userdetailshead.css'
const UserdetailsHeader = () => {
  const { user, setUser } = useContext(UserContext);
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  const navigate = useNavigate();

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('user');
    navigate('/');
  };

  const toggleDetailsVisibility = () => {
    setIsDetailsVisible(!isDetailsVisible);
  };

  return (

    <div className="userdetails-total">
    <div className='userdetails-content'>
      <div onClick={toggleDetailsVisibility}>
        <FaRegUserCircle className='userdetails-iconn' />
      </div>
      {isDetailsVisible && (
        <div className='userdetails-div2'>
          <strong>{user.username}</strong>
          <strong>{user.designation}</strong>
        </div>
      )}
    </div>
    {isDetailsVisible && (
      <button className='userdetails-logoutbtn' onClick={handleLogout}>Logout</button>
    )}
  </div>

    // <div className="userdetails-total">
    //   <div className='userdetails-content'>
    //     <div>
    //       <FaRegUserCircle className='userdetails-iconn' />
    //     </div>
    //     <div className='userdetails-div2'>
    //       <span>{user.username}</span>
    //       <span>{user.designation}</span>
    //     </div>
    //   </div>
    //   <button className='userdetails-logoutbtn' onClick={handleLogout}>Logout</button>
    // </div>
  );
};

export default UserdetailsHeader;

