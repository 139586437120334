


// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import FullCalendar from '@fullcalendar/react';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import interactionPlugin from '@fullcalendar/interaction';
// import './Calendertab.css';

// const Calendartab = ({ selectedMonth, selectedStationPoint }) => {
//     const [events, setEvents] = useState([]);
//     const calendarRef = useRef(null);

//     useEffect(() => {
//         fetchData();
//     }, [selectedMonth, selectedStationPoint]);

//     useEffect(() => {
//         updateCalendarView();
//     }, [selectedMonth]);

//     const fetchData = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/calenderevents`);
//             const filteredData = response.data.filter(item => {
//                 // Extract the month from the date
//                 const itemMonth = new Date(item.date).toISOString().slice(0, 7); // 'YYYY-MM'
//                 return itemMonth === selectedMonth && item.stationPoint === selectedStationPoint;
//             });
//             const formattedEvents = filteredData.map(event => ({
//                 title: `Event on ${event.date}`,
//                 start: event.date,
//                 extendedProps: {
//                     Section: event.stationPoint,
//                     energyIntensity: event['Energy_Intensity'] || 'N/A',
//                     md: event.Maximum_Demand_kVA || 'N/A',
//                     pf: event.PF || 'N/A',
//                     production: event.Production || 'N/A',
//                     runTime: event.Runtime || 'N/A',
//                     utilization: event['Utilization_%'] || 'N/A',
//                     kWh: event.KWH_consumption || 'N/A'
//                 }
//             }));
//             setEvents(formattedEvents);
//         } catch (error) {
//             console.error('Error fetching calendar events:', error);
//         }
//     };

//     const updateCalendarView = () => {
//         if (calendarRef.current) {
//             const calendarApi = calendarRef.current.getApi();
//             const [year, month] = selectedMonth.split('-');
//             calendarApi.gotoDate(new Date(year, month - 1));
//         }
//     };

//     const renderEventContent = (eventInfo) => {
//         // const { title } = eventInfo.event;
//         const { Section, energyIntensity, md, pf, production, runTime, utilization, kWh } = eventInfo.event.extendedProps;

//         const renderValue = (value) => value === null ? 'N/A' : value;

//         return (
//             <div id='calendereventstab-fulcaltop'>
//                 <label>kWh: {renderValue(kWh)}</label>
//                 <span>Section: {renderValue(Section)}</span>
//                 <span>Energy Intensity: {renderValue(energyIntensity)}</span>
//                 <span>MD: {renderValue(md)}</span>
//                 <span>PF: {renderValue(pf)}</span>
//                 <span>Production: {renderValue(production)}</span>
//                 <span>Run Time: {renderValue(runTime)}</span>
//                 <span>Utilization (%): {renderValue(utilization)}</span>
//             </div>
//         );
//     };

//     return (

//         <div id='calendercombine-right-4'>
//         <p>
//         Calendar Events
//         </p>
//         <div id='calendercombine-fulcal'>
//                 <FullCalendar
//                     ref={calendarRef}
//                     initialView="dayGridMonth"
//                     headerToolbar={{
//                         right: 'today',
//                         left: 'title',
//                     }}
//                     themeSystem="Simplex"
//                     plugins={[dayGridPlugin, interactionPlugin]}
//                     events={events}
//                     eventContent={renderEventContent}
//                     dateClick={(info) => console.log(`Date clicked: ${info.dateStr}`)}
//                     datesSet={(info) => console.log(`Current view dates: ${info.startStr} to ${info.endStr}`)}
//                 />
//             </div>
//       </div>
//     );
// };

// export default Calendartab;



import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import './Calendertab.css';

const Calendartab = ({ selectedMonth, selectedStationPoint }) => {
    const [events, setEvents] = useState([]);
    const calendarRef = useRef(null);

    useEffect(() => {
        fetchData();
    }, [selectedMonth, selectedStationPoint]);

    useEffect(() => {
        updateCalendarView();
    }, [selectedMonth]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/calenderevents`);
            const filteredData = response.data.filter(item => {
                // Extract the month from the date
                const itemMonth = new Date(item.date).toISOString().slice(0, 7); // 'YYYY-MM'
                return itemMonth === selectedMonth && item.stationPoint === selectedStationPoint;
            });
            const formattedEvents = filteredData.map(event => ({
                title: `Event on ${event.date}`,
                start: event.date,
                extendedProps: {
                    Section: event.stationPoint,
                    energyIntensity: event['Energy_Intensity'] || 'N/A',
                    md: event.Maximum_Demand_kVA || 'N/A',
                    pf: event.PF || 'N/A',
                    production: event.Production || 'N/A',
                    runTime: event.Runtime || 'N/A',
                    utilization: event['Utilization_%'] || 'N/A',
                    kWh: event.KWH_consumption || 'N/A'
                }
            }));
            setEvents(formattedEvents);
        } catch (error) {
            console.error('Error fetching calendar events:', error);
        }
    };

    const updateCalendarView = () => {
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            const [year, month] = selectedMonth.split('-');
            calendarApi.gotoDate(new Date(year, month - 1));
        }
    };

    const renderEventContent = (eventInfo) => {
        // const { title } = eventInfo.event;
        const { Section, energyIntensity, md, pf, production, runTime, utilization, kWh } = eventInfo.event.extendedProps;

        const renderValue = (value) => value === null ? 'N/A' : value;

        return (
            <div id='calendereventstab-fulcaltop'>
                <label>kWh: {renderValue(kWh)}</label>
                {/* <span>Section: {renderValue(Section)}</span> */}
                <span>Energy Intensity: {renderValue(energyIntensity)}</span>
                <span>MD: {renderValue(md)}</span>
                <span>PF: {renderValue(pf)}</span>
                <span>Production: {renderValue(production)}</span>
                <span>Run Time: {renderValue(runTime)}</span>
                <span>Utilization (%): {renderValue(utilization)}</span>
            </div>
        );
    };

    return (
        <div id='calendercombine-right-4'>
            <p>Monthly Calender Overview of - {selectedStationPoint} </p>
            <div id='calendercombine-fulcal'>
                <FullCalendar
                    ref={calendarRef}
                    initialView="dayGridMonth"
                    headerToolbar={{
                        right: 'today',
                        left: 'title',
                    }}
                    themeSystem="Simplex"
                    plugins={[dayGridPlugin, interactionPlugin]}
                    events={events}
                    eventContent={renderEventContent}
                    dateClick={(info) => console.log(`Date clicked: ${info.dateStr}`)}
                    datesSet={(info) => console.log(`Current view dates: ${info.startStr} to ${info.endStr}`)}
                />
            </div>
        </div>
    );
};

export default Calendartab;
