
import axios from 'axios';

const formatDate = (date) => {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const dd = String(date.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
};

const formatMonth = (date) => {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  return `${yyyy}-${mm}`;
};

const calculateUnfilledFormsCount = async () => {
  try {
    const firstFilledResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/first-filled-date`);
    const firstDate = new Date(firstFilledResponse.data.firstFilledDate);

    const productionResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/production-entry`);
    const fetchedProductionData = productionResponse.data;

    const monitoringResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/transformer-monitoring-logs`);
    const fetchedMonitoringData = monitoringResponse.data;

    const powerBillsResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/power-bills`);
    const fetchedPowerBillsData = powerBillsResponse.data;

    const calculateMissingDates = () => {
      const missingProduction = [];
      const missingMonitoring = [];
      const startDate = new Date(firstDate);
      const endDate = new Date();

      for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
        const formattedDate = formatDate(d);
        const productionEntryExists = fetchedProductionData.some(item => item.date === formattedDate);
        const monitoringEntryExists = fetchedMonitoringData.some(item => item.date === formattedDate);

        if (!productionEntryExists) {
          missingProduction.push(formattedDate);
        }

        if (!monitoringEntryExists) {
          missingMonitoring.push(formattedDate);
        }
      }
      return { missingProduction, missingMonitoring };
    };

    const calculateMissingMonths = () => {
      const missing = [];
      const startDate = new Date(firstDate);
      const endDate = new Date();

      for (let d = new Date(startDate); d <= endDate; d.setMonth(d.getMonth() + 1)) {
        const formattedMonth = formatMonth(d);
        const entryExists = fetchedPowerBillsData.some(item => formatMonth(new Date(item.date)) === formattedMonth);

        if (!entryExists) {
          missing.push(formattedMonth);
        }
      }

      // Check if current month data exists
      const currentMonthFormatted = formatMonth(new Date());
      const currentMonthDataExists = fetchedPowerBillsData.some(item => formatMonth(new Date(item.date)) === currentMonthFormatted);

      if (!currentMonthDataExists) {
        missing.push(currentMonthFormatted);
      }

      return missing;
    };

    const { missingProduction, missingMonitoring } = calculateMissingDates();
    const missingMonthsList = calculateMissingMonths();

    return missingProduction.length + missingMonitoring.length + missingMonthsList.length;
  } catch (error) {
    console.error('Error calculating unfilled forms count:', error);
    return 0;
  }
};

export default calculateUnfilledFormsCount;
