
import { useState, React, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TopBar from '../../Commonfns/Topbar/Topbar';
import { Operatorheadingss } from '../Data';
import './Alertspage.css';
import OperatorAlertstable from '../AlertsTable/Alertstable';
import LiveAlerts from '../LiveAlerts/LiveAlerts';
import Operatorsidebar from '../Sidebar/Sidebar';
import Supervisorsidebar from '../../Supervisor/Supersidebar/Supersidebar';

const Alertspage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialSection = queryParams.get('section') || 'LiveAlerts-1';

    const [activeSection, setActiveSection] = useState(initialSection);

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    const [accessedLevel, setAccessedLevel] = useState(null);

    useEffect(() => {
        // Fetch the accessed level from localStorage
        const level = localStorage.getItem('accessedLevel');
        setAccessedLevel(level);
    }, []);
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
        }
        setUserDesignation(user.level);
    }, []);

    const handleButtonClick = (section) => {
        setActiveSection(section);
    };


    return (
        <div id='alertspage-total'>
            <div>
                {accessedLevel === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />}
            </div>
            <div id='alertspage-right'>
                <TopBar section="Alertspage" />
                <div id='alertspage-right-2'>
                    <div id='alertspage-right-21'>
                        <div id='alertspage-right-23'>

                            <button
                                className={activeSection === 'LiveAlerts-1' ? 'active' : ''}
                                onClick={() => handleButtonClick('LiveAlerts-1')}
                            >
                                Live Alerts
                            </button>
                            <button
                                className={activeSection === 'OperatorAlertstable-1' ? 'active' : ''}
                                onClick={() => handleButtonClick('OperatorAlertstable-1')}
                            >
                                Alerts History
                            </button>
                        </div>
                    </div>

                    <div id='alertspage-right-3'>
                        <div>
                            <div>
                                {activeSection === 'OperatorAlertstable-1' && <OperatorAlertstable />}
                                {activeSection === 'LiveAlerts-1' && <LiveAlerts />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Alertspage;




