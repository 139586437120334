

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';
import './Revenueloss.css'
import { useNavigate } from 'react-router-dom';
const Revenueloss = () => {
    const [utilizationData, setUtilizationData] = useState([]);
    const [transformerLossData, setTransformerLossData] = useState([]);
    const [highVoltageLossData, setHighVoltageLossData] = useState([]);
    const [negativePfLossData, setNegativePfLossData] = useState([]);
    const [highCurrentLossData, setHighCurrentLossData] = useState([]);
    const [idleLossData, setIdleLossData] = useState([]);
    const [todLossData, setTodLossData] = useState([]);
    const [unbalancedcurrentlossData, setUnbalancedcurrentlossData] = useState([]);
    const [abnormalpflossData, setAbnormalpflossData] = useState([]);
    const [thdLossData, setThdLossData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedMonth, setSelectedMonth] = useState(() => {
        const date = new Date();
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
    });

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [
                    utilizationResponse,
                    transformerLossResponse,
                    highVoltageLossResponse,
                    negativePfLossResponse,
                    highCurrentLossResponse,
                    idleLossResponse,
                    todLossResponse,
                    unbalancedcurrentlossData,
                    abnormalpflossData,
                    thdLossResponse,
                ] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/utilizationloss`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/transformerloss`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/highvoltageloss`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/negativepfloss`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/highcurrentloss`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/idealloss`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/todloss`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/abnormalpfloss`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/unbalancedcurrentloss`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/THDloss`),
                ]);

                setUtilizationData(utilizationResponse.data);
                setTransformerLossData(transformerLossResponse.data);
                setHighVoltageLossData(highVoltageLossResponse.data);
                setNegativePfLossData(negativePfLossResponse.data);
                setHighCurrentLossData(highCurrentLossResponse.data);
                setIdleLossData(idleLossResponse.data);
                setTodLossData(todLossResponse.data);
                setUnbalancedcurrentlossData(unbalancedcurrentlossData.data);
                setAbnormalpflossData(abnormalpflossData.data);
                setThdLossData(thdLossResponse.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const filterByMonth = (data) => {
        return data.filter(item => item.month === selectedMonth || item.Month === selectedMonth);
    };

    const filteredUtilizationData = filterByMonth(utilizationData);
    const filteredTransformerLossData = filterByMonth(transformerLossData);
    const filteredHighVoltageLossData = filterByMonth(highVoltageLossData);
    const filteredNegativePfLossData = filterByMonth(negativePfLossData);
    const filteredHighCurrentLossData = filterByMonth(highCurrentLossData);
    const filteredIdleLossData = filterByMonth(idleLossData);
    const filteredTodLossData = filterByMonth(todLossData);
    const filteredAbnormalpflossData = filterByMonth(abnormalpflossData);
    const filterUnbalancedcurrentlossData = filterByMonth(unbalancedcurrentlossData);
    const filteredThdLossData = filterByMonth(thdLossData);

    useEffect(() => {
        if (!loading && !error) {
            const chartDom = document.getElementById('chart');
            const myChart = echarts.init(chartDom);

            const utilizationEnergyLoss = filteredUtilizationData.map(item => item.kvah_consumption);
            const utilizationRevenueLoss = filteredUtilizationData.map(item => item.revenue_loss);

            const transformerEnergyLoss = filteredTransformerLossData.map(item => item.transformer_loss);
            const transformerRevenueLoss = filteredTransformerLossData.map(item => item.revenue_loss);

            const highVoltageEnergyLoss = filteredHighVoltageLossData.map(item => item['Overall Energy Loss']);
            const highVoltageRevenueLoss = filteredHighVoltageLossData.map(item => item['Overall Revenue Loss']);

            const negativePfEnergyLoss = filteredNegativePfLossData.map(item => item['Total Power Loss']);
            const negativePfRevenueLoss = filteredNegativePfLossData.map(item => item['Total Revenue Loss @ unit price 7.15']);

            const highCurrentEnergyLoss = filteredHighCurrentLossData.map(item => item['Total Power Loss']);
            const highCurrentRevenueLoss = filteredHighCurrentLossData.map(item => item['Total Revenue Loss']);

            const idleEnergyLoss = filteredIdleLossData.map(item => item['overall_kvah_consumption']);
            const idleRevenueLoss = filteredIdleLossData.map(item => item['revenue_loss']);

            const todEnergyLoss = filteredTodLossData.map(item => item['Total_Energy_Loss']);
            const todRevenueLoss = filteredTodLossData.map(item => item['Total_Revenue_Loss']);

            const unbalancedcurrentEnergyLoss = filterUnbalancedcurrentlossData.map(item => item['Total Power Loss']);
            const unbalancedRevenueLoss = filterUnbalancedcurrentlossData.map(item => item['Total Revenue Loss']);

            const abnormalpfEnergyLoss = filteredAbnormalpflossData.map(item => item['Total Power Loss']);
            const abnormalpfRevenueLoss = filteredAbnormalpflossData.map(item => item['Total Revenue Loss']);

            const thdEnergyLoss = filteredThdLossData.map(item => item['Total Power Loss']);
            const thdRevenueLoss = filteredThdLossData.map(item => item['Total Revenue Loss']);

            const option = {
                title: {
                    text: 'Revenue Loss Data'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: ['Energy Loss', 'Revenue Loss']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: [
                        'Utilization Loss',
                        'Transformer Loss',
                        'High Voltage Loss',
                        'Negative PF Loss',
                        'High Current Loss',
                        'Idle Loss',
                        'TOD Loss',
                        'Abnormal PF Loss',
                        'Unbalanced Current Loss',
                        'THD Loss'
                    ]
                },
                series: [
                    {
                        name: 'Energy Loss',
                        type: 'bar',
                        data: [
                            utilizationEnergyLoss.reduce((a, b) => a + b, 0),
                            transformerEnergyLoss.reduce((a, b) => a + b, 0),
                            highVoltageEnergyLoss.reduce((a, b) => a + b, 0),
                            negativePfEnergyLoss.reduce((a, b) => a + b, 0),
                            highCurrentEnergyLoss.reduce((a, b) => a + b, 0),
                            idleEnergyLoss.reduce((a, b) => a + b, 0),
                            todEnergyLoss.reduce((a, b) => a + b, 0),
                            unbalancedcurrentEnergyLoss.reduce((a, b) => a + b, 0),
                            abnormalpfEnergyLoss.reduce((a, b) => a + b, 0),
                            thdEnergyLoss.reduce((a, b) => a + b, 0),
                        ],
                        itemStyle: {
                            color: '#6174D7',
                        },
                    },
                    {
                        name: 'Revenue Loss',
                        type: 'bar',
                        data: [
                            utilizationRevenueLoss.reduce((a, b) => a + b, 0),
                            transformerRevenueLoss.reduce((a, b) => a + b, 0),
                            highVoltageRevenueLoss.reduce((a, b) => a + b, 0),
                            negativePfRevenueLoss.reduce((a, b) => a + b, 0),
                            highCurrentRevenueLoss.reduce((a, b) => a + b, 0),
                            idleRevenueLoss.reduce((a, b) => a + b, 0),
                            todRevenueLoss.reduce((a, b) => a + b, 0),
                            unbalancedRevenueLoss.reduce((a, b) => a + b, 0),
                            abnormalpfRevenueLoss.reduce((a, b) => a + b, 0),
                            thdRevenueLoss.reduce((a, b) => a + b, 0),
                        ],
                        itemStyle: {
                            color: '#EA4335',
                        },
                    }
                ]
            };

            myChart.setOption(option);
        }
    }, [
        loading,
        error,
        filteredUtilizationData,
        filteredTransformerLossData,
        filteredHighVoltageLossData,
        filteredNegativePfLossData,
        filteredHighCurrentLossData,
        filteredIdleLossData,
        filteredTodLossData,
        filterUnbalancedcurrentlossData,
        filteredAbnormalpflossData,
        filteredThdLossData
    ]);


    const handleUnbalancedloaddiagnoseClick = () => {
        navigate('/Unbalancedloaddiagnose', { state: { selectedMonth } });
    };

    const handleVoltagediagnoseClick = () => {
        // console.log('Navigating to /voltagediagnose with selectedMonth:', selectedMonth);
        navigate('/Voltagediagnose', { state: { selectedMonth } });
    };

    const handlePowersurgediagnoseClick = () => {
        navigate('/Powersurgediagnose', { state: { selectedMonth } });
    };

    const handleHarmonicdistortiondiagnoseClick = () => {
        navigate('/Harmonicdistortiondiagnose', { state: { selectedMonth } });
    };

    const handleLowPFdiagnoseClick = () => {
        navigate('/LowPFdiagnose', { state: { selectedMonth } });
    };

    const handleLeadPFdiagnoseClick = () => {
        navigate('/LeadPFdiagnose', { state: { selectedMonth } });
    };

    const handleIdle_Loss_Revenue_DiagnosediagnoseClick = () => {
        navigate('/Idle_Loss_Revenue_Diagnose', { state: { selectedMonth } });
    };

    const handleTOD_loss_Revenue_DiagnosediagnoseClick = () => {
        navigate('/TOD_loss_Revenue_Diagnose', { state: { selectedMonth } });
    };

    const handleUtilization_Loss_Revenue_DiagnoseClick = () => {
        navigate('/Utilization_Loss_Revenue_Diagnose', { state: { selectedMonth } });
    };

    const handleTransformer_Loss_Revenue_DiagnoseClick = () => {
        navigate('/Transformer_Loss_Revenue_Diagnose', { state: { selectedMonth } });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div>

            <div>
                <label htmlFor="month">Select Month: </label>
                <input
                    type="month"
                    id="month"
                    value={selectedMonth}
                    onChange={handleMonthChange}
                />
            </div>

            {/* <div id="chart" style={{ width: '100%', height: '500px' }}></div> */}

            <div className="chart-container-revenueloss">
                <div id="chart" className="chart-revenueloss"></div>
                {/* <div id="chart" className="chart"></div> */}
                <div className="buttons-container-revenueloss">
                    <button onClick={handleHarmonicdistortiondiagnoseClick}> <abbr title="Click to Open Revenue Loss Diagnose">THD Loss Diagnose</abbr></button>
                    <button onClick={handleUnbalancedloaddiagnoseClick}> <abbr title="Click to Open Unbalanced Load Diagnose">Unbalanced Load Diagnose </abbr>  </button>
                    <button onClick={handleLeadPFdiagnoseClick}>  <abbr title="Click to Open Abnormal PF Loss" >Abnormal PF Loss</abbr> </button>
                    <button onClick={handleTOD_loss_Revenue_DiagnosediagnoseClick}> <abbr title="Click to Open TOD Loss">  TOD Loss </abbr> </button>
                    <button onClick={handleIdle_Loss_Revenue_DiagnosediagnoseClick}> <abbr title="Click to Open Idle Loss" >Idle Loss</abbr> </button>
                    <button onClick={handlePowersurgediagnoseClick}> <abbr  title="Click to Open  High Current Loss"> High Current Loss </abbr></button> 
                    <button onClick={handleLowPFdiagnoseClick}>  <abbr title="Click to Open Negative PF Loss"> Negative PF Loss</abbr></button>
                    <button onClick={handleVoltagediagnoseClick}> <abbr title="Click to Open High Voltage Loss">High Voltage Loss</abbr> </button>
                    <button onClick={handleTransformer_Loss_Revenue_DiagnoseClick}> <abbr title="Click to Open Transformer Loss">Transformer Loss </abbr> </button>
                    <button onClick={handleUtilization_Loss_Revenue_DiagnoseClick}> <abbr title="Click to Open Utilization Loss"> Utilization Loss</abbr> </button>
                </div>
            </div>
        </div>
    );
};

export default Revenueloss;




















// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import * as echarts from 'echarts';

// const Revenueloss = () => {
//   const [utilizationData, setUtilizationData] = useState([]);
//   const [transformerLossData, setTransformerLossData] = useState([]);
//   const [highVoltageLossData, setHighVoltageLossData] = useState([]);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [selectedMonth, setSelectedMonth] = useState(() => {
//     const date = new Date();
//     return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const [utilizationResponse, transformerLossResponse, highVoltageLossResponse] = await Promise.all([
//           axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/utilizationloss'`),
//           axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/transformerloss`),
//           axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/highvoltageloss`),
//           axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/negativepfloss`),
//           axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/highcurrentloss`),
//           axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/idealloss`),
//           axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/todloss`),
//         ]);

//         setUtilizationData(utilizationResponse.data);
//         setTransformerLossData(transformerLossResponse.data);
//         setHighVoltageLossData(highVoltageLossResponse.data);
//       } catch (error) {
//         setError(error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const handleMonthChange = (event) => {
//     setSelectedMonth(event.target.value);
//   };

//   const filterByMonth = (data) => {
//     return data.filter(item => item.month === selectedMonth || item.Month === selectedMonth);
//   };

//   const filteredUtilizationData = filterByMonth(utilizationData);
//   const filteredTransformerLossData = filterByMonth(transformerLossData);
//   const filteredHighVoltageLossData = filterByMonth(highVoltageLossData);

//   useEffect(() => {
//     if (!loading && !error) {
//       const chartDom = document.getElementById('chart');
//       const myChart = echarts.init(chartDom);

//       const utilizationEnergyLoss = filteredUtilizationData.map(item => item.kvah_consumption);
//       const utilizationRevenueLoss = filteredUtilizationData.map(item => item.revenue_loss);

//       const transformerEnergyLoss = filteredTransformerLossData.map(item => item.transformer_loss);
//       const transformerRevenueLoss = filteredTransformerLossData.map(item => item.revenue_loss);

//       const highVoltageEnergyLoss = filteredHighVoltageLossData.map(item => item['Overall Energy Loss']);
//       const highVoltageRevenueLoss = filteredHighVoltageLossData.map(item => item['Overall Revenue Loss']);

//       const option = {
//         title: {
//           text: 'Revenue Loss Data'
//         },
//         tooltip: {
//           trigger: 'axis',
//           axisPointer: {
//             type: 'shadow'
//           }
//         },
//         legend: {
//           data: ['Energy Loss', 'Revenue Loss']
//         },
//         grid: {
//           left: '3%',
//           right: '4%',
//           bottom: '3%',
//           containLabel: true
//         },
//         xAxis: {
//           type: 'value',
//           boundaryGap: [0, 0.01]
//         },
//         yAxis: {
//           type: 'category',
//           data: ['Utilization Loss', 'Transformer Loss', 'High Voltage Loss']
//         },
//         series: [
//           {
//             name: 'Energy Loss',
//             type: 'bar',
//             data: [
//               utilizationEnergyLoss.reduce((a, b) => a + b, 0),
//               transformerEnergyLoss.reduce((a, b) => a + b, 0),
//               highVoltageEnergyLoss.reduce((a, b) => a + b, 0)
//             ]
//           },
//           {
//             name: 'Revenue Loss',
//             type: 'bar',
//             data: [
//               utilizationRevenueLoss.reduce((a, b) => a + b, 0),
//               transformerRevenueLoss.reduce((a, b) => a + b, 0),
//               highVoltageRevenueLoss.reduce((a, b) => a + b, 0)
//             ]
//           }
//         ]
//       };

//       myChart.setOption(option);
//     }
//   }, [loading, error, filteredUtilizationData, filteredTransformerLossData, filteredHighVoltageLossData]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error.message}</div>;
//   }

//   return (
//     <div>
//       <h2>Revenue Loss Data</h2>

//       <div>
//         <label htmlFor="month">Select Month: </label>
//         <input
//           type="month"
//           id="month"
//           value={selectedMonth}
//           onChange={handleMonthChange}
//         />
//       </div>

//       <div id="chart" style={{ width: '100%', height: '500px' }}></div>
//     </div>
//   );
// };

// export default Revenueloss;





// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const Revenueloss = () => {
//   const [utilizationData, setUtilizationData] = useState([]);
//   const [transformerLossData, setTransformerLossData] = useState([]);
//   const [highVoltageLossData, setHighVoltageLossData] = useState([]);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [selectedMonth, setSelectedMonth] = useState(() => {
//     const date = new Date();
//     return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const [utilizationResponse, transformerLossResponse, highVoltageLossResponse] = await Promise.all([
//           axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/utilizationloss`),
//           axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/transformerloss`),
//           axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/highvoltageloss`),
//         ]);

//         setUtilizationData(utilizationResponse.data);
//         setTransformerLossData(transformerLossResponse.data);
//         setHighVoltageLossData(highVoltageLossResponse.data);
//       } catch (error) {
//         setError(error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const handleMonthChange = (event) => {
//     setSelectedMonth(event.target.value);
//   };

//   const filterByMonth = (data) => {
//     return data.filter(item => item.month === selectedMonth || item.Month === selectedMonth);
//   };

//   const filteredUtilizationData = filterByMonth(utilizationData);
//   const filteredTransformerLossData = filterByMonth(transformerLossData);
//   const filteredHighVoltageLossData = filterByMonth(highVoltageLossData);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error.message}</div>;
//   }

//   return (
//     <div>
//       <h2>Revenue Loss Data</h2>

//       <div>
//         <label htmlFor="month">Select Month: </label>
//         <input
//           type="month"
//           id="month"
//           value={selectedMonth}
//           onChange={handleMonthChange}
//         />
//       </div>

//       <h3>Utilization Loss Data</h3>
//       {filteredUtilizationData.map(item => (
//         <div key={item._id}>
//           <p><strong>Month:</strong> {item.month}</p>
//           <p><strong>KVAH Consumption:</strong> {item.kvah_consumption}</p>
//           <p><strong>Revenue Loss:</strong> {item.revenue_loss}</p>
//         </div>
//       ))}

//       <h3>Transformer Loss Data</h3>
//       {filteredTransformerLossData.map(item => (
//         <div key={item._id}>
//           <p><strong>Month:</strong> {item.month}</p>
//           <p><strong>Transformer Loss:</strong> {item.transformer_loss}</p>
//           <p><strong>Revenue Loss:</strong> {item.revenue_loss}</p>
//         </div>
//       ))}

//       <h3>High Voltage Loss Data</h3>
//       {filteredHighVoltageLossData.map(item => (
//         <div key={item._id}>
//           <p><strong>Month:</strong> {item.Month}</p>
//           <p><strong>High Voltage Loss:</strong> {item['Overall Energy Loss']}</p>
//           <p><strong>Revenue Loss:</strong> {item['Overall Revenue Loss']}</p>
//         </div>
//       ))}
//     </div>
//   );
// }

// export default Revenueloss;





// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const Revenueloss = () => {
//   const [utilizationData, setUtilizationData] = useState([]);
//   const [transformerLossData, setTransformerLossData] = useState([]);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const [utilizationResponse, transformerLossResponse] = await Promise.all([
//           axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/utilizationloss`),
//           axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/transformerloss`),
//           axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/highvoltageloss`),
//         ]);

//         setUtilizationData(utilizationResponse.data);
//         setTransformerLossData(transformerLossResponse.data);
//       } catch (error) {
//         setError(error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error.message}</div>;
//   }

//   return (
//     <div>
//       <h2>Revenue Loss Data</h2>

//       <h3>Utilization Loss Data</h3>
//       {utilizationData.map(item => (
//         <div key={item._id}>
//           <p><strong>Month:</strong> {item.month}</p>
//           <p><strong>KVAH Consumption:</strong> {item.kvah_consumption}</p>
//           <p><strong>Revenue Loss:</strong> {item.revenue_loss}</p>
//         </div>
//       ))}

//       <h3>Transformer Loss Data</h3>
//       {transformerLossData.map(item => (
//         <div key={item._id}>
//           <p><strong>Month:</strong> {item.month}</p>
//           <p><strong>Transformer Loss:</strong> {item.transformer_loss}</p>
//           <p><strong>Revenue Loss:</strong> {item.revenue_loss}</p>
//         </div>
//       ))}
//     </div>
//   );
// }

// export default Revenueloss;