
import React, { useState , useEffect } from 'react';
import './EnvironmentalFactors.css';
import axios from 'axios';
import { Voltagevoltagefluctuationsheader } from '../../../Operator/Data';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import { getCurrentDateTime } from '../../../Diagnose/dateUtils';
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const EnvironmentalFactors = () => {
    const { token_id } = useParams();
    const navigate = useNavigate();
    const [lightingStrike, setLightingStrike] = useState('');
    const [weatherDisturbances, setWeatherDisturbances] = useState('');

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
      // Retrieve the logged-in user from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        setLoggedInUser(user);
      }
      setUserDesignation(user.level);
    }, []);

    const initialFactorData = {
        dateandtime: getCurrentDateTime(),
        lighting_strike: '',
        weather_disturbances: '',
        token_id: token_id,
        createdby: '',
        createdat: '',
        modifiedby: '',
        modifiedat: '',
        spare1: '',
        spare2: ''
    };

    

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = {
                ...initialFactorData,
                lighting_strike: lightingStrike,
                weather_disturbances: weatherDisturbances
            };

            const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/environmentalfactors`, dataToSend);

            console.log('Data posted successfully:', response.data);

            alert('Environmental Factors data added successfully');

            setLightingStrike('');
            setWeatherDisturbances('');
            navigate('/EnvironmentalFactorsfetch');
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };


    return (
        <div id='environmentalfactors-total'>
             <div>
        {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />} 
      </div>
            <div id='environmentalfactors-right'>
                <div id='environmentalfactors-right-1'>
                    <div id='environmentalfactors-heading'>
                        {Voltagevoltagefluctuationsheader.alertHeading}
                    </div>
                    <div id='environmentalfactors-heading1'>
                        {Voltagevoltagefluctuationsheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Voltagevoltagefluctuationsheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <h3 id='environmentalfactors-h3'>{Voltagevoltagefluctuationsheader.subalertHeading}</h3>
                <div id='environmentalfactors-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='environmentalfactors-divtop'>
                            <label>
                                <span>Environmental Factor</span>
                            </label>
                            {/* <label>
                                <Link to='/EnvironmentalFactorsfetch'><button type="button">View Data</button></Link>
                            </label> */}
                        </div>

                        <div id='environmentalfactors-row'>
                            <label>
                                <span>Lighting Strike <span id='all-mandetorymark'>*</span></span>
                                <select
                                    name="lighting_strike"
                                    value={lightingStrike}
                                    onChange={(e) => setLightingStrike(e.target.value)}
                                    required
                                >
                                    <option value="">Select</option>
                                    <option value="YES">YES</option>
                                    <option value="NO">NO</option>
                                </select>
                            </label>
                            <label>
                                <span>Weather Disturbances <span id='all-mandetorymark'>*</span></span>
                                <select
                                    name="weather_disturbances"
                                    value={weatherDisturbances}
                                    onChange={(e) => setWeatherDisturbances(e.target.value)}
                                    required
                                >
                                    <option value="">Select</option>
                                    <option value="YES">YES</option>
                                    <option value="NO">NO</option>
                                </select>
                            </label>
                        </div>

                        <label>
                            <button id='environmentalfactors-submit' type="submit">Submit</button>
                        </label>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EnvironmentalFactors;

