import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import './Stebilizersfetch.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Stebilizersfetch = ({ onFormButtonClick }) => {
    const [stebilizers, setStebilizers] = useState([]);
    const [stebilizerEditData, setStebilizerEditData] = useState(null);
    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-lvtransformersectionames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    const fetchStebilizers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/stebilizers`);
            setStebilizers(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchStebilizers();
    }, []);

    const handleEditClick = (stebilizer) => {
        setStebilizerEditData(stebilizer);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setStebilizerEditData({ ...stebilizerEditData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        console.log('Updating data:', stebilizerEditData);
        toast.success("Stebilizer updated successfully");
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/stebilizers/${stebilizerEditData._id}`, stebilizerEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchStebilizers();
            setStebilizerEditData(null);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='stebilizersfetch-total'>
            <div id='stebilizersfetch-right'>
                <div id='stebilizersfetch-right-2'>
                    <div id='stebilizersfetch-right-21'>
                        <label>
                            <span> {Masterformsheadingsss.StebilizerHeading}</span>
                        </label>
                        <label>
                            <button onClick={onFormButtonClick}>{Masterformsheadingsss.Formheading}</button>
                        </label>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Parent Name</th>
                                <th>Meter Make</th>
                                <th>Meter SL No</th>
                                <th>Phase</th>
                                <th>Voltage Regulation Accuracy</th>
                                <th>Input Voltage</th>
                                <th>Output Voltage</th>
                                <th>Rated Power (kVA)</th>
                                <th>Operating Efficiency</th>
                                <th>Correction Speed</th>
                                <th>Automatic Voltage Regulation</th>
                                <th>Over Voltage Protection</th>
                                <th>Under Voltage Protection</th>
                                <th>Short Circuit Protection</th>
                                <th>Connected Load (kVA)</th>
                                <th>Cooling System</th>
                                <th>Proposed Periodicity of Maintenance</th>
                                <th>Previous Maintenance Done Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stebilizers.map((stebilizer) => (
                                <tr key={stebilizer._id}>
                                    <td>{stebilizer.date}</td>
                                    <td>{stebilizer.name}</td>
                                    <td>{stebilizer.parentname}</td>
                                    <td>{stebilizer.metermake}</td>
                                    <td>{stebilizer.meterslno}</td>
                                    <td>{stebilizer.phase}</td>
                                    <td>{stebilizer.voltageregulationaccuracy}</td>
                                    <td>{stebilizer.inputvoltage}</td>
                                    <td>{stebilizer.outputvoltage}</td>
                                    <td>{stebilizer.ratedpowerinva}</td>
                                    <td>{stebilizer.operatingefficiency}</td>
                                    <td>{stebilizer.correctionspeed}</td>
                                    <td>{stebilizer.automaticvoltageregulation}</td>
                                    <td>{stebilizer.overvoltageprotection}</td>
                                    <td>{stebilizer.undervoltageprotection}</td>
                                    <td>{stebilizer.shortcircuitprotection}</td>
                                    <td>{stebilizer.connectedloadinva}</td>
                                    <td>{stebilizer.coolingsystem}</td>
                                    <td>{stebilizer.proposedperiodicityofmaintenance}</td>
                                    <td>{stebilizer.previousmaintenancedonedate}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(stebilizer)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {stebilizerEditData && (
                        <Popup open={true} onClose={() => setStebilizerEditData(null)}>
                            <div id='stebilizersfetch-popup'>
                                <h3>Edit Stebilizer</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='stebilizersfetch-popup1'>
                                        <label>
                                            <span>Date</span>
                                            <input name="date" value={stebilizerEditData.date} onChange={handleEditChange} />

                                        </label>
                                        <label>
                                            <span>Parent Name</span>
                                            <select name="parentname" value={stebilizerEditData.parentname} onChange={handleEditChange}>
                                                <option value="">Select Parent Name</option>
                                                {parentNames.map((name) => (
                                                    <option key={name} value={name}>{name}</option>
                                                ))}
                                            </select>
                                        </label>


                                    </div>
                                    <div id='stebilizersfetch-popup1'>
                                        <label>

                                            <span>Name</span>
                                            <input type="text" name="name" value={stebilizerEditData.name} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Meter Make</span>
                                            <input type="text" name="metermake" value={stebilizerEditData.metermake} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='stebilizersfetch-popup1'>
                                        <label>
                                            <span>Meter SL No</span>
                                            <input type="number" name="meterslno" value={stebilizerEditData.meterslno} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Phase</span>
                                            <select name="phase" value={stebilizerEditData.phase} onChange={handleEditChange} required>
                                                <option value=''>Select Phase</option>
                                                <option value='3-Phase'>3-Phase</option>
                                                <option value='1-Phase-Vr'>1-Phase-Vr</option>
                                                <option value='1-Phase-Vy'>1-Phase-Vy</option>
                                                <option value='1-Phase-Vb'>1-Phase-Vb</option>
                                            </select>
                                        </label>

                                    </div>
                                    <div id='stebilizersfetch-popup1'>
                                        <label>
                                            <span>Voltage Regulation Accuracy</span>
                                            <input type="number" name="voltageregulationaccuracy" value={stebilizerEditData.voltageregulationaccuracy} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Input Voltage</span>
                                            <input type="number" name="inputvoltage" value={stebilizerEditData.inputvoltage} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='stebilizersfetch-popup1'>
                                        <label>
                                            <span>Output Voltage</span>
                                            <input type="number" name="outputvoltage" value={stebilizerEditData.outputvoltage} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Rated Power (VA)</span>
                                            <input type="number" name="ratedpowerinva" value={stebilizerEditData.ratedpowerinva} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='stebilizersfetch-popup1'>
                                        <label>
                                            <span>Operating Efficiency</span>
                                            <input type="number" name="operatingefficiency" value={stebilizerEditData.operatingefficiency} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Correction Speed</span>
                                            <input type="number" name="correctionspeed" value={stebilizerEditData.correctionspeed} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='stebilizersfetch-popup1'>
                                        <label>
                                            <span>Automatic Voltage Regulation</span>
                                            <input type="text" name="automaticvoltageregulation" value={stebilizerEditData.automaticvoltageregulation} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Over Voltage Protection</span>
                                            <input type="text" name="overvoltageprotection" value={stebilizerEditData.overvoltageprotection} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='stebilizersfetch-popup1'>
                                        <label>
                                            <span>Under Voltage Protection</span>
                                            <input type="text" name="undervoltageprotection" value={stebilizerEditData.undervoltageprotection} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Short Circuit Protection</span>
                                            <input type="text" name="shortcircuitprotection" value={stebilizerEditData.shortcircuitprotection} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='stebilizersfetch-popup1'>
                                        <label>
                                            <span>Connected Load (kVA)</span>
                                            <input type="number" name="connectedloadinva" value={stebilizerEditData.connectedloadinva} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Cooling System</span>
                                            <input type="text" name="coolingsystem" value={stebilizerEditData.coolingsystem} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='stebilizersfetch-popup1'>
                                        <label>
                                            <span>Proposed Periodicity of Maintenance</span>
                                            <input type="number" name="proposedperiodicityofmaintenance" value={stebilizerEditData.proposedperiodicityofmaintenance} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Previous Maintenance Done Date</span>
                                            <input type="date" name="previousmaintenancedonedate" value={stebilizerEditData.previousmaintenancedonedate} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='stebilizersfetch-divbottom'>
                                        <label>
                                            <button id='stebilizersfetch-popup-update' type="submit">{Masterformsheadingsss.Updateheading}</button>

                                        </label>
                                    </div>

                                    {/* <button type="button" onClick={() => setStebilizerEditData(null)}>Cancel</button> */}
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Stebilizersfetch;
