import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Supervisorsidebar from '../../Supervisor/Supersidebar/Supersidebar';
import './Idle_Loss_Revenue_Diagnose.css';
import { RevenuelossHeader } from '../../Operator/Data';
import { useLocation } from 'react-router-dom';
import { MdBrightness1 } from "react-icons/md";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FaFilePdf } from "react-icons/fa6";
import TopBar from '../../Commonfns/Topbar/Topbar';
import Operatorsidebar from '../../Operator/Sidebar/Sidebar';
const Idle_Loss_Revenue_Diagnose = () => {
    const [idlerevenuelossData, setIdlerevenuelossData] = useState([]);
    const location = useLocation();
    const { selectedMonth } = location.state || { selectedMonth: null };
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [accessedLevel, setAccessedLevel] = useState(null);

    useEffect(() => {
        // Fetch the accessed level from localStorage
        const level = localStorage.getItem('accessedLevel');
        setAccessedLevel(level);
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/revenue-idleloss`);
                setIdlerevenuelossData(response.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const filterByMonth = (data) => {
        if (!selectedMonth) return data;
        return data.filter(item => item.Month === selectedMonth);
    };

    const filteredIdlerevenuelossData = filterByMonth(idlerevenuelossData);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const handleAcknowledge = async (id) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/revenue-idleloss/${id}/ack`);
            if (response.status === 200) {
                setIdlerevenuelossData(idlerevenuelossData.map(item => item._id === id ? { ...item, ack: "Acknowledged" } : item));
            }
            alert("Acknowledged Sucessfully")
        } catch (error) {
            console.error('Error acknowledging the record:', error);
        }
    };

    const handleSaveAsPDF = () => {
        const input = document.getElementById('idlerevenueloss-right'); // Updated ID to match the existing one
        html2canvas(input).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = pdfWidth;
            const imgHeight = canvas.height * pdfWidth / canvas.width;

            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            pdf.save(`idlerevenueloss-Diagnose.pdf`);
        }).catch(error => {
            console.error('Error generating PDF:', error);
        });
    };

    return (
        <div id='idlerevenueloss-total'>
            <div>
                {accessedLevel === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />}
            </div>
            <div id='idlerevenueloss-right'>
                <TopBar section="IdlerevenuelossHeading" />
                <div id='idlerevenueloss-right-2'>
                    <h3> {RevenuelossHeader.RevenuelossHeading2} </h3>
                    <div id='idlerevenueloss-right-3'>
                        {filteredIdlerevenuelossData.length > 0 ? (
                            filteredIdlerevenuelossData.map((item, index) => (
                                <div id='idlerevenueloss-right-3-1' key={index}>
                                    <div id='idlerevenueloss-cloumndiv'>
                                        <strong>Date:</strong>
                                        <span>{item.Month}</span>
                                    </div>

                                    <label >
                                        <div id='idlerevenueloss-sugessiondiv'>
                                            <strong>Suggestion :</strong>
                                        </div>
                                        <div id='idlerevenueloss-suggesiondiv'>
                                            {item.Suggestions && item.Suggestions.map((suggestion, idx) => (
                                                <label key={idx}>
                                                    <div >
                                                        <MdBrightness1 style={{ fontSize: '10px' }} />
                                                    </div>
                                                    <div id='idlerevenueloss-displaydiv'>
                                                        <span id='idlerevenueloss-sughead'> {suggestion.heading}:</span>
                                                        <span>{suggestion.suggestion}</span>
                                                    </div>

                                                </label>
                                            ))}
                                        </div>
                                    </label>

                                    <button
                                        onClick={() => handleAcknowledge(item._id)}
                                        disabled={item.ack === "Acknowledged"}
                                        className={item.ack === "Acknowledged" ? 'acknowledged' : ''}
                                    >
                                        {item.ack === "Acknowledged" ? "Acknowledged" : "Acknowledge"}
                                    </button>
                                </div>
                            ))
                        ) : (
                            <p>N/A</p>
                        )}
                    </div>
                    <div id='diagnosepage-pdfbtn'>
                        <button onClick={handleSaveAsPDF}><FaFilePdf /></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Idle_Loss_Revenue_Diagnose;
