// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#super-report-total {
    display: flex;
    background-color: #F3F6FE;
    min-height: 100vh;
    /* position: fixed; */
}

#super-report-right {
    margin-left: 4vw;
}

#super-report-right-2 {
    margin-top: 15px;
    padding: 0px 25px 25px 25px;

    #super-report-right-21 {

        #super-report-right-23 {
            display: flex;
            gap: 20px;
            padding: 10px 0px 25px 20px;
            justify-content: center;

            button {
                padding: 10px 8px;
                width: 15vw;
                border-radius: 5px;
                /* border: 1.5px solid #DFE6FA; */
                cursor: pointer;
                background-color: #D8E1FF;
                border: 1.5px solid #abb9e7;
            }

            button.active {
                background-color: #252B3F;
                color: white;
                border: 1.5px solid #DFE6FA;
                box-shadow: #DFE6FA 0px 2px 8px 0px;
            }
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Reports/Allreports/Allreports.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,2BAA2B;;IAE3B;;QAEI;YACI,aAAa;YACb,SAAS;YACT,2BAA2B;YAC3B,uBAAuB;;YAEvB;gBACI,iBAAiB;gBACjB,WAAW;gBACX,kBAAkB;gBAClB,iCAAiC;gBACjC,eAAe;gBACf,yBAAyB;gBACzB,2BAA2B;YAC/B;;YAEA;gBACI,yBAAyB;gBACzB,YAAY;gBACZ,2BAA2B;gBAC3B,mCAAmC;YACvC;QACJ;IACJ;AACJ","sourcesContent":["#super-report-total {\n    display: flex;\n    background-color: #F3F6FE;\n    min-height: 100vh;\n    /* position: fixed; */\n}\n\n#super-report-right {\n    margin-left: 4vw;\n}\n\n#super-report-right-2 {\n    margin-top: 15px;\n    padding: 0px 25px 25px 25px;\n\n    #super-report-right-21 {\n\n        #super-report-right-23 {\n            display: flex;\n            gap: 20px;\n            padding: 10px 0px 25px 20px;\n            justify-content: center;\n\n            button {\n                padding: 10px 8px;\n                width: 15vw;\n                border-radius: 5px;\n                /* border: 1.5px solid #DFE6FA; */\n                cursor: pointer;\n                background-color: #D8E1FF;\n                border: 1.5px solid #abb9e7;\n            }\n\n            button.active {\n                background-color: #252B3F;\n                color: white;\n                border: 1.5px solid #DFE6FA;\n                box-shadow: #DFE6FA 0px 2px 8px 0px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
