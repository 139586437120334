import React, { useState } from 'react';
import axios from 'axios';
import './Incomers.css'
import { Masterformsheadingsss } from '../../Operator/Data';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Incomers = ({ onViewData }) => {

    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    const initialFormData = {
        date: getCurrentDate(),
        incomersId: '',
        name: '',
        fromsubstation: '',
        voltagelevel: '',
        departmentmeterMF: '',
        ctratioofdepartmentMF: '',
        ptratioofdepartmentMF: '',
        spare1: '',
        spare2: ''
    };

    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = {
                ...formData,
                voltagelevel: Number(formData.voltagelevel),
                departmentmeterMF: Number(formData.departmentmeterMF),
                ctratioofdepartmentMF: Number(formData.ctratioofdepartmentMF),
                ptratioofdepartmentMF: Number(formData.ptratioofdepartmentMF)
            };

            await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/incomers`, dataToSend);
            toast.success('Incomers added successfully');
            setFormData(initialFormData);
        } catch (error) {
            console.error('Error submitting data', error);
            toast.error('Error adding Incomer');
        }
    };

    return (

        <div id='incomers-total'>
            <div id='incomers-right'>
                <div id='incomers-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='incomers-divtop'>
                            <label>
                                <span> {Masterformsheadingsss.incomersHeading}</span>
                                {/* <span>#123456</span> */}
                            </label>
                            <label>
                                <button type="button" onClick={onViewData}>View Data</button>
                            </label>
                        </div>
                        <div id='incomers-div1'>
                            <label>
                                <span>Date</span>
                                <input type='date' name="date" value={formData.date} disabled />
                            </label>
                            <label>
                                <span>Name</span>
                                <input name="name" value={formData.name} onChange={handleChange} required />
                            </label>

                            {/* <label>
                                <span>incomers id</span>
                                <input name="incomersId" value={formData.incomersId} onChange={handleChange} required />
                            </label> */}
                        </div>
                        <div id='incomers-div1'>
                            <label>
                                <span>From Substation</span>
                                <input name="fromsubstation" value={formData.fromsubstation} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Voltage level</span>
                                <input type='number' name="voltagelevel" value={formData.voltagelevel} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='incomers-div1'>

                            <label>
                                <span>Department meter MF</span>
                                <input type='number' name="departmentmeterMF" value={formData.departmentmeterMF} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>CT Ratio of department MF</span>
                                <input type="number" name="ctratioofdepartmentMF" value={formData.ctratioofdepartmentMF} onChange={handleChange} required />
                            </label>
                        </div>

                        <div id='incomers-div1'>

                            <label>
                                <span>PT Ratio of department MF</span>
                                <input type="number" name="ptratioofdepartmentMF" value={formData.ptratioofdepartmentMF} onChange={handleChange} required />
                            </label>
                        </div>

                        {/* <div id='incomers-div1'>
                            <label>
                                <span>Spare 1</span>
                                <input name="spare1" value={formData.spare1} onChange={handleChange} />
                            </label>

                            <label>
                                <span>Spare 2</span>
                                <input name="spare2" value={formData.spare2} onChange={handleChange} />
                            </label>
                        </div> */}

                        <button id='incomers-save' type="submit">{Masterformsheadingsss.Submitheading}</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Incomers;
