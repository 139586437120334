
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import FullCalendar from '@fullcalendar/react';
// import dayGridPlugin from '@fullcalendar/daygrid';

// const Calendar = () => {
//   const [allEvents, setAllEvents] = useState([]);
//   const [events, setEvents] = useState([]);
//   const [selectedSection, setSelectedSection] = useState('HV');

//   useEffect(() => {
//     fetchData(); // Fetch all events initially
//   }, []);

//   useEffect(() => {
//     filterEventsBySection(selectedSection); // Filter events when the selected section changes
//   }, [selectedSection, allEvents]);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/calenderevents`);
//       const formattedEvents = response.data.map(event => ({
//         title: `Event on ${event.Date}`,
//         start: event.Date,
//         extendedProps: {
//           Section: event.Section,
//           energyIntensity: event['Energy Intensity'],
//           md: event.MD,
//           pf: event.PF,
//           production: event.Production,
//           runTime: event['Run time'],
//           utilization: event['Utilization (%)'],
//           kWh: event.kWh
//         }
//       }));
//       setAllEvents(formattedEvents);
//     } catch (error) {
//       console.error('Error fetching calendar events:', error);
//     }
//   };

//   const filterEventsBySection = (section) => {
//     const filteredEvents = allEvents.filter(event => event.extendedProps.Section === section);
//     setEvents(filteredEvents);
//   };

//   const handleSectionChange = (event) => {
//     setSelectedSection(event.target.value);
//   };

//   const renderEventContent = (eventInfo) => {
//     const { title } = eventInfo.event;
//     const { Section, energyIntensity, md, pf, production, runTime, utilization, kWh } = eventInfo.event.extendedProps;

//     const renderValue = (value) => value === null ? 'null' : value;

//     return (
//       <div>
//         <b>{title}</b><br />
//         <span>Section: {renderValue(Section)}</span><br />
//         <span>Energy Intensity: {renderValue(energyIntensity)}</span><br />
//         <span>MD: {renderValue(md)}</span><br />
//         <span>PF: {renderValue(pf)}</span><br />
//         <span>Production: {renderValue(production)}</span><br />
//         <span>Run Time: {renderValue(runTime)}</span><br />
//         <span>Utilization (%): {renderValue(utilization)}</span><br />
//         <span>kWh: {renderValue(kWh)}</span><br />
//       </div>
//     );
//   };

//   return (
//     <div>
//       <label>
//         <span>Section</span>
//         <select value={selectedSection} onChange={handleSectionChange}>
//           <option value="HV">HV</option>
//           <option value="LV-1">LV-1</option>
//           <option value="Compressor-11KW">Compressor-11KW</option>
//           <option value="Compressor-75KW">Compressor-75KW</option>
//           <option value="Colony">Colony</option>
//         </select>
//       </label>
//       <FullCalendar
//         initialView="dayGridMonth"
//         headerToolbar={{
//           left: 'prev,next',
//           center: 'title',
//           right: 'dayGridMonth,timeGridWeek,timeGridDay'
//         }}
//         themeSystem="Simplex"
//         plugins={[dayGridPlugin]}
//         events={events}
//         eventContent={renderEventContent}
//       />
//     </div>
//   );
// };

// export default Calendar;



// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import FullCalendar from '@fullcalendar/react';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import interactionPlugin from '@fullcalendar/interaction';
// import Operatorsidebar from '../Operator/Sidebar/Sidebar';
// import { Calendereventsheader } from '../Operator/Data';
// import { FaFilePdf } from "react-icons/fa6";
// import { MdPrint } from "react-icons/md";
// import './Calender.css'

// const Calendar = () => {
//   const [allEvents, setAllEvents] = useState([]);
//   const [events, setEvents] = useState([]);
//   const [selectedSection, setSelectedSection] = useState('HV');
//   const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString().substring(0, 7)); // Default to current month
//   const calendarRef = useRef(null);

//   useEffect(() => {
//     fetchData();
//   }, []);

//   useEffect(() => {
//     filterEventsBySection(selectedSection);
//   }, [selectedSection, allEvents]);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/calenderevents`);
//       const formattedEvents = response.data.map(event => ({
//         title: `Event on ${event.Date}`,
//         start: event.Date,
//         extendedProps: {
//           Section: event.Section,
//           energyIntensity: event['Energy Intensity'],
//           md: event.MD,
//           pf: event.PF,
//           production: event.Production,
//           runTime: event['Run time'],
//           utilization: event['Utilization (%)'],
//           kWh: event.kWh
//         }
//       }));
//       setAllEvents(formattedEvents);
//     } catch (error) {
//       console.error('Error fetching calendar events:', error);
//     }
//   };

//   const filterEventsBySection = (section) => {
//     const filteredEvents = allEvents.filter(event => event.extendedProps.Section === section);
//     setEvents(filteredEvents);
//   };

//   const handleSectionChange = (event) => {
//     setSelectedSection(event.target.value);
//   };

//   const handleMonthChange = (event) => {
//     const selectedMonth = event.target.value;
//     setSelectedMonth(selectedMonth);
//     const calendarApi = calendarRef.current.getApi();
//     calendarApi.gotoDate(selectedMonth);
//   };

//   const renderEventContent = (eventInfo) => {
//     const { title } = eventInfo.event;
//     const { Section, energyIntensity, md, pf, production, runTime, utilization, kWh } = eventInfo.event.extendedProps;

//     const renderValue = (value) => value === null ? 'null' : value;

//     return (
//       <div id='calenderevents-fulcaltop'>
//         {/* <b>{title}</b><br /> */}
//         <label>kWh: {renderValue(kWh)}</label>
//         <span>Section: {renderValue(Section)}</span>
//         <span>Energy Intensity: {renderValue(energyIntensity)}</span>
//         <span>MD: {renderValue(md)}</span>
//         <span>PF: {renderValue(pf)}</span>
//         <span>Production: {renderValue(production)}</span>
//         <span>Run Time: {renderValue(runTime)}</span>
//         <span>Utilization (%): {renderValue(utilization)}</span>
//       </div>
//     );
//   };

//   return (

//     <div id='calenderevents-total'>
//       <div>
//         <Operatorsidebar />
//       </div>
//       <div id='calenderevents-right'>
//         <div id='calenderevents-right-1'>
//           <div id='calenderevents-heading'>
//             {Calendereventsheader.alertHeading}
//           </div>
//           <div id='calenderevents-heading1'>
//             {Calendereventsheader.alertActions.map((action, index) => (
//               <button key={index} aria-label={action.label}>
//                 {action.icon}
//               </button>
//             ))}
//             <img src={Calendereventsheader.logoSrc} alt='logo' />
//           </div>
//         </div>
//         <div id='calenderevents-right-2'>
//           <div id='calenderevents-right-21'>
//             <label>
//               <span>Select Section:</span>
//               <select value={selectedSection} onChange={handleSectionChange}>
//                 <option value=''>Select Option</option>
//                 <option>HV</option>
//                 <option>LV-1</option>
//                 <option>LV-2</option>
//                 <option>Compressor-11KW</option>
//                 <option>Compressor-75KW</option>
//                 <option>Colony</option>
//                 <option>Compressor</option>
//                 <option>APFC-1</option>
//                 <option>APFC-2</option>
//                 <option>DG Grid Incomer -1</option>
//                 <option>DG Grid Incomer -2</option>
//                 <option>DG Incomer 1</option>
//                 <option>DG Incomer 2</option>
//                 <option>DG Incomer 3</option>
//                 <option>DG Incomer 4</option>
//                 <option>DG Incomer 5</option>
//                 <option>DG Incomer 6</option>
//                 <option>Solar-1</option>
//                 <option>Solar-2</option>
//                 <option>WHR-1</option>
//                 <option>WHR-2</option>
//                 <option>ENGG store Panel</option>
//                 <option>Unit-5</option>
//                 <option>AR&D</option>
//                 <option>Production</option>
//                 <option>Lighting</option>
//                 <option>spare-1</option>
//                 <option>spare-2</option>
//               </select>
//             </label>
//             <label>
//               <span>Select Month:</span>
//               <input
//                 type="month"
//                 value={selectedMonth}
//                 onChange={handleMonthChange}
//               />
//             </label>
//             <label>
//               <button ><FaFilePdf /></button>
//             </label>
//             <label>
//               <button ><MdPrint /></button>
//             </label>
//           </div>
//           <div id='calenderevents-right-3'>
//             <p>
//               Monthly Events
//             </p>
//             <div id='calenderevents-right-32'>
//             <div id='calenderevents-right-31'>
//               <label>
//                 <span>Energy Intensity :</span>
//                 <input  disabled></input>
//               </label>
//               <label>
//                 <span>Production :</span>
//                 <input  disabled></input>
//               </label>
//             </div>
//             <div  id='calenderevents-right-31'>
//               <label>
//                 <span>Run time :</span>
//                 <input  disabled></input>
//               </label>
//               <label>
//                 <span>PF :</span>
//                 <input  disabled></input>
//               </label>
//             </div>
//             <div  id='calenderevents-right-31'>
//               <label>
//                 <span>kVAh :</span>
//                 <input  disabled></input>
//               </label>
//               <label>
//                 <span>kWh :</span>
//                 <input  disabled></input>
//               </label>
//             </div>
//             <div  id='calenderevents-right-31'>
//               <label>
//                 <span>Max Demand :</span>
//                 <input  disabled></input>
//               </label>
//               <label>
//                 <span>Utilization (%) :</span>
//                 <input  disabled></input>
//               </label>
//             </div>
//             </div>
//           </div>
//           <div id='calenderevents-right-4'>
//             <p>
//               Monthly Events
//             </p>
//             <div id='calenderevents-fulcal'>
//               <FullCalendar
//                 ref={calendarRef}
//                 initialView="dayGridMonth"
//                 headerToolbar={{
//                   right: 'today',
//                   left: 'title',
//                 }}
//                 themeSystem="Simplex"
//                 plugins={[dayGridPlugin, interactionPlugin]}
//                 events={events}
//                 eventContent={renderEventContent}
//                 dateClick={(info) => console.log(`Date clicked: ${info.dateStr}`)}
//                 datesSet={(info) => console.log(`Current view dates: ${info.startStr} to ${info.endStr}`)}
//               />
//             </div>
//           </div>
//         </div>

//       </div>
//     </div>
//   );
// };

// export default Calendar;



import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import Operatorsidebar from '../Operator/Sidebar/Sidebar';
import { Calendereventsheader } from '../Operator/Data';
import { FaFilePdf } from "react-icons/fa6";
import { MdPrint } from "react-icons/md";
import './Calender.css'

const Calendar = () => {
  const [allEvents, setAllEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedSection, setSelectedSection] = useState('HV');
  const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString().substring(0, 7)); // Default to current month
  const calendarRef = useRef(null);
  const [eventsData, setEventsData] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    filterEventsBySection(selectedSection);
  }, [selectedSection, allEvents]);

  useEffect(() => {
    filterMonthlyEvents(selectedMonth, selectedSection);
  }, [selectedMonth, eventsData]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/calenderevents`);
      const formattedEvents = response.data.map(event => ({
        title: `Event on ${event.date}`,
        start: event.date,
        extendedProps: {
          Section: event.stationPoint,
          energyIntensity: event['Energy_Intensity'] || 'N/A',
          md: event.Maximum_Demand_kVA || 'N/A',
          pf: event.PF || 'N/A',
          production: event.Production || 'N/A',
          runTime: event.Runtime || 'N/A',
          utilization: event['Utilization_%'] || 'N/A',
          kWh: event.KWH_consumption || 'N/A'
        }
      }));
      setAllEvents(formattedEvents);
    } catch (error) {
      console.error('Error fetching calendar events:', error);
    }
  };

  const filterEventsBySection = (section) => {
    const filteredEvents = allEvents.filter(event => event.extendedProps.Section === section);
    setEvents(filteredEvents);
  };

  const filterMonthlyEvents = (month, section) => {
    const filteredMonthlyEvents = eventsData.filter(event => {
      const eventDate = new Date(event.date);
      const eventMonth = eventDate.toISOString().substring(0, 7);
      return eventMonth === month && event.stationPoint === section;
    });
    setEventsData(filteredMonthlyEvents);
  };

  const handleSectionChange = (event) => {
    const section = event.target.value;
    setSelectedSection(section);
    filterEventsBySection(section);
  };

  const handleMonthChange = (event) => {
    const month = event.target.value;
    setSelectedMonth(month);
    filterMonthlyEvents(month, selectedSection);
  };

  const handlePrint = () => {
    const printContent = document.getElementById('printable-content');
    const WinPrint = window.open('', '', 'width=900,height=650');
    WinPrint.document.write(printContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
  };

  const renderEventContent = (eventInfo) => {
    const { title } = eventInfo.event;
    const { Section, energyIntensity, md, pf, production, runTime, utilization, kWh } = eventInfo.event.extendedProps;

    const renderValue = (value) => value === null ? 'N/A' : value;

    return (
      <div id='calenderevents-fulcaltop'>
        <label>kWh: {renderValue(kWh)}</label>
        <span>Section: {renderValue(Section)}</span>
        <span>Energy Intensity: {renderValue(energyIntensity)}</span>
        <span>MD: {renderValue(md)}</span>
        <span>PF: {renderValue(pf)}</span>
        <span>Production: {renderValue(production)}</span>
        <span>Run Time: {renderValue(runTime)}</span>
        <span>Utilization (%): {renderValue(utilization)}</span>
      </div>
    );
  };

  return (
    <div id='calenderevents-total'>
      <div>
        <Operatorsidebar />
      </div>
      <div id='calenderevents-right'>
        <div id='calenderevents-right-1'>
          <div id='calenderevents-heading'>
            {Calendereventsheader.alertHeading}
          </div>
          <div id='calenderevents-heading1'>
            {Calendereventsheader.alertActions.map((action, index) => (
              <button key={index} aria-label={action.label}>
                {action.icon}
              </button>
            ))}
            <img src={Calendereventsheader.logoSrc} alt='logo' />
          </div>
        </div>
        <div id='calenderevents-right-2'>
          <div id='calenderevents-right-21'>
            <label>
              <span>Select Section:</span>
              <select value={selectedSection} onChange={handleSectionChange}>
                <option value=''>Select Option</option>
                <option>HV</option>
                <option>LV-1</option>
                <option>LV-2</option>
                <option>Compressor-11KW</option>
                <option>Compressor-75KW</option>
                <option>Colony</option>
                <option>Compressor</option>
                <option>APFC-1</option>
                <option>APFC-2</option>
                <option>DG Grid Incomer -1</option>
                <option>DG Grid Incomer -2</option>
                <option>DG Incomer 1</option>
                <option>DG Incomer 2</option>
                <option>DG Incomer 3</option>
                <option>DG Incomer 4</option>
                <option>DG Incomer 5</option>
                <option>DG Incomer 6</option>
                <option>Solar-1</option>
                <option>Solar-2</option>
                <option>WHR-1</option>
                <option>WHR-2</option>
                <option>ENGG store Panel</option>
                <option>Unit-5</option>
                <option>AR&D</option>
                <option>Production</option>
                <option>Lighting</option>
                <option>spare-1</option>
                <option>spare-2</option>
              </select>
            </label>
            <label>
              <span>Select Month:</span>
              <input
                type="month"
                value={selectedMonth}
                onChange={handleMonthChange}
              />
            </label>
            <label>
              <button onClick={handlePrint}><FaFilePdf /></button>
            </label>
            <label>
              <button ><MdPrint /></button>
            </label>
          </div>
          <div id='calenderevents-right-3'>
            <p>
              Monthly Calender Events
            </p>
            <div>
              {eventsData.map((event, index) => (
                <div key={index}>
                  <div id='calenderevents-right-32'>
                    <div id='calenderevents-right-31'>
                    <label>
                        <span>Section:</span>
                        <input  />
                      </label>
                      <label>
                        <span>Energy Intensity:</span>
                        <input  />
                      </label>
                      <label>
                        <span>Production:</span>
                        <input />
                      </label>
                    </div>
                    <div id='calenderevents-right-31'>
                      <label>
                        <span>Run time:</span>
                        <input  />
                      </label>
                      <label>
                        <span>PF:</span>
                        <input />
                      </label>
                    </div>
                    <div id='calenderevents-right-31'>
                      <label>
                        <span>kVAh:</span>
                        <input  />
                      </label>
                      <label>
                        <span>kWh:</span>
                        <input />
                      </label>
                    </div>
                    <div id='calenderevents-right-31'>
                      <label>
                        <span>Max Demand:</span>
                        <input  />
                      </label>
                      <label>
                        <span>Utilization (%):</span>
                        <input  />
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div id='calenderevents-right-4'>
            <p>
              Monthly Events
            </p>
            <div id='calenderevents-fulcal'>
              <FullCalendar
                ref={calendarRef}
                initialView="dayGridMonth"
                headerToolbar={{
                  right: 'today',
                  left: 'title',
                }}
                themeSystem="Simplex"
                plugins={[dayGridPlugin, interactionPlugin]}
                events={events}
                eventContent={renderEventContent}
                dateClick={(info) => console.log(`Date clicked: ${info.dateStr}`)}
                datesSet={(info) => console.log(`Current view dates: ${info.startStr} to ${info.endStr}`)}
              />
            </div>
          </div>
        </div>
      </div>
      <div id="printable-content" style={{ display: 'none' }}>
        <h1>{Calendereventsheader.alertHeading}</h1>
        <div>
          <span>Section: {selectedSection}</span>
          <span>Month: {selectedMonth}</span>
        </div>
        <div>
          {events.map((event, index) => (
            <div key={index}>
              <h2>{event.title}</h2>
              <p>kWh: {event.extendedProps.kWh}</p>
              <p>Section: {event.extendedProps.Section}</p>
              <p>Energy Intensity: {event.extendedProps.energyIntensity}</p>
              <p>MD: {event.extendedProps.md}</p>
              <p>PF: {event.extendedProps.pf}</p>
              <p>Production: {event.extendedProps.production}</p>
              <p>Run Time: {event.extendedProps.runTime}</p>
              <p>Utilization: {event.extendedProps.utilization}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Calendar;

