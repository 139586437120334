

import React, { useState , useEffect} from 'react';
import './Harmonic_Form.css';
import axios from 'axios';
import { Harmonicformheader } from '../../../Operator/Data';
import { Link , useParams , useNavigate} from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import { getCurrentDateTime } from '../../../Diagnose/dateUtils';
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const Harmonic_Form = () => {
    const { token_id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        detuningFactor: '',
        thd: '',
        qualityFactor: ''
    });

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
      // Retrieve the logged-in user from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        setLoggedInUser(user);
      }
      setUserDesignation(user.level);
    }, []);

    const initialFactorData = {
        dateandtime: getCurrentDateTime(),
        name: '',
        detuning_factor: '',
        thd: '',
        quality_factor: '',
        token_id: token_id,
        createdby: '',
        createdat: '',
        modifiedby: '',
        modifiedat: '',
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = {
                ...initialFactorData,
                name: formData.name,
                detuning_factor: Number(formData.detuningFactor),
                thd: Number(formData.thd),
                quality_factor: Number(formData.qualityFactor),
            };

            const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/harmonicform`, dataToSend);

            console.log('Data posted successfully:', response.data);

            alert('Harmonic Form data added successfully');

            // Reset form fields after successful submission
            setFormData({
                name: '',
                detuningFactor: '',
                thd: '',
                qualityFactor: ''
            });
            navigate('/Harmonic_Formfetch');
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    return (
        <div id='harmonicform-total'>
           <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />} 
            </div>
            <div id='harmonicform-right'>
                <div id='harmonicform-right-1'>
                    <div id='harmonicform-heading'>
                        {Harmonicformheader.alertHeading}
                    </div>
                    <div id='harmonicform-heading1'>
                        {Harmonicformheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Harmonicformheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <h3 id='harmonicform-h3'>{Harmonicformheader.subalertHeading}</h3>
                <div id='harmonicform-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='harmonicform-divtop'>
                            <label>
                                <span>Harmonics - Implement  harmonic spectrum analysis</span>
                            </label>
                            {/* <label>
                                <Link to='/Harmonic_Formfetch'><button type="button">View Data</button></Link>
                            </label> */}
                        </div>

                        <div id='harmonicform-row'>
                            <label>
                                <span>Name <span id='all-mandetorymark'>*</span></span>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label>
                                <span>Detuning Factor <span id='all-mandetorymark'>*</span></span>
                                <input
                                    type="number"
                                    name="detuningFactor"
                                    value={formData.detuningFactor}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                        </div>

                        <div id='harmonicform-row'>
                            <label>
                                <span>THD <span id='all-mandetorymark'>*</span></span>
                                <input
                                    type="number"
                                    name="thd"
                                    value={formData.thd}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label>
                                <span>Quality Factor <span id='all-mandetorymark'>*</span></span>
                                <input
                                    type="number"
                                    name="qualityFactor"
                                    value={formData.qualityFactor}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                        </div>

                        <label>
                            <button id='harmonicform-submit' type="submit">Submit</button>
                        </label>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Harmonic_Form;
