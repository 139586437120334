


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const TOD_Run_Time = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [stationPoint, setStationPoint] = useState('HV');
//   const [type, setType] = useState('Incentives');
//   const [fromDate, setFromDate] = useState(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
//   const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);

//   const stationPoints = [
//     "DG Incomer -1", "DG Incomer -2", "DG Incomer -3", "Solar-1", "WHR-1", "LV-2", "ENGG store panel",
//     "Unit-5", "AR&D", "Production", "Lighting", "APFC-2", "DG Grid Incomer -2", "DG Incomer 4", "DG Incomer 5",
//     "DG Incomer 6", "Solar-2", "WHR-2", "Pump House", "HV", "LV-1", "Compressor-11 KW", "Compressor-75 KW",
//     "Colony", "Compressor", "APFC-1", "DG Grid Incomer -1", "Cut bend"
//   ];

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/todruntime-withdatefield`);
//     //   console.log('Fetched data:', response.data);
//       setData(response.data);
//       setLoading(false);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       setError('Error fetching data');
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const filterDataByDate = (data, fromDate, toDate) => {
//     const from = new Date(fromDate);
//     const to = new Date(toDate);

//     return data.filter(item => {
//       const itemDate = new Date(item.date);
//       return itemDate >= from && itemDate <= to;
//     });
//   };

//   const handleFromDateChange = (event) => {
//     const newFromDate = event.target.value;
//     setFromDate(newFromDate);

//     const maxToDate = new Date(newFromDate);
//     maxToDate.setDate(maxToDate.getDate() + 30);
//     const newMaxToDateString = maxToDate.toISOString().split('T')[0];

//     // Update toDate if it's beyond the new max date
//     if (new Date(toDate) > maxToDate) {
//       setToDate(newMaxToDateString);
//     }
//   };

//   const handleToDateChange = (event) => {
//     setToDate(event.target.value);
//   };

//   const calculateToDateMax = () => {
//     const fromDateObj = new Date(fromDate);
//     const maxToDate = new Date(fromDateObj);
//     maxToDate.setDate(fromDateObj.getDate() + 30);
//     return maxToDate.toISOString().split('T')[0];
//   };

//   const filteredData = filterDataByDate(data, fromDate, toDate).filter(item =>
//     item.stationPoints.includes(stationPoint)
//   ).map(item => ({
//     date: item.date,
//     Run_Hours: item[type]?.Run_Hours?.[stationPoint] ?? 0,
//     Consumption: item[type]?.Consumption?.[stationPoint] ?? 0
//   }));

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>{error}</div>;

//   return (
//     <div>
//       <h1>TOD_Run_Time Data</h1>

//       <div>
//         <label>
//           <span>Station Point</span>
//           <select value={stationPoint} onChange={(e) => setStationPoint(e.target.value)}>
//             {stationPoints.map(point => (
//               <option key={point} value={point}>{point}</option>
//             ))}
//           </select>
//         </label>
//         <label>
//           <span>Select Type</span>
//           <select value={type} onChange={(e) => setType(e.target.value)}>
//             <option value="Incentives">Incentives</option>
//             <option value="Normal">Normal</option>
//             <option value="Penalty">Penalty</option>
//           </select>
//         </label>
//         <label>
//           <span>From date</span>
//           <input 
//             type='date' 
//             value={fromDate} 
//             onChange={handleFromDateChange} 
//           />
//         </label>
//         <label>
//           <span>To date</span>
//           <input 
//             type='date' 
//             value={toDate} 
//             onChange={handleToDateChange} 
//             min={fromDate} 
//             max={calculateToDateMax()} 
//           />
//         </label>
//       </div>

//       {filteredData.length > 0 ? (
//         <ul>
//           {filteredData.map((item, index) => (
//             <li key={index}>
//               <div><strong>Date:</strong> {new Date(item.date).toLocaleDateString()}</div>
//               <div><strong>{type} Run Hours:</strong> {item.Run_Hours}</div>
//               <div><strong>{type} Consumption:</strong> {item.Consumption}</div>
//             </li>
//           ))}
//         </ul>
//       ) : (
//         <div>No data available for the selected filters.</div>
//       )}
//     </div>
//   );
// };

// export default TOD_Run_Time;




import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';

const stationPoints = [
  "DG Incomer -1", "DG Incomer -2", "DG Incomer -3", "Solar-1", "WHR-1", "LV-2", 
  "ENGG store panel", "Unit-5", "AR&D", "Production", "Lighting", "APFC-2", 
  "DG Grid Incomer -2", "DG Incomer 4", "DG Incomer 5", "DG Incomer 6", "Solar-2", 
  "WHR-2", "Pump House", "HV", "LV-1", "Compressor-11 KW", "Compressor-75 KW", 
  "Colony", "Compressor", "APFC-1", "DG Grid Incomer -1", "Cut bend"
];

const sortedStationPoints = stationPoints.sort();

const TOD_Run_Time = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stationPoint, setStationPoint] = useState('HV');
  const [type, setType] = useState('Incentives');
  const [fromDate, setFromDate] = useState(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
  const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);

  const chartRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/todruntime-withdatefield`);
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!chartRef.current) return;

    const chart = echarts.init(chartRef.current);

    const filteredData = data.filter(item => {
      const itemDate = new Date(item.date);
      return item.stationPoints.includes(stationPoint) &&
             itemDate >= new Date(fromDate) &&
             itemDate <= new Date(toDate);
    }).map(item => ({
      date: item.date,
      Run_Hours: item[type]?.Run_Hours?.[stationPoint] ?? "00:00:00",
      Consumption: item[type]?.Consumption?.[stationPoint] ?? 0
    }));

    const dates = filteredData.map(item => item.date);

    // Convert Run_Hours from "HH:MM:SS" format to decimal hours for plotting
    const runHoursData = filteredData.map(item => {
      const [hours, minutes, seconds] = item.Run_Hours.split(':').map(Number);
      return hours + (minutes / 60) + (seconds / 3600);
    });

    const consumptionData = filteredData.map(item => item.Consumption);

    const formatHHMMSS = (decimalHours) => {
      const totalSeconds = decimalHours * 3600;
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = Math.floor(totalSeconds % 60);
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: function (params) {
          let tooltip = params[0].name;
          params.forEach(param => {
            if (param.seriesName === 'Run Hours') {
              tooltip += `<br/>${param.marker} ${param.seriesName}: ${formatHHMMSS(param.value)}`;
            } else {
              tooltip += `<br/>${param.marker} ${param.seriesName}: ${param.value}`;
            }
          });
          return tooltip;
        }
      },
      legend: {
        data: ['Run Hours', 'Consumption']
      },
      xAxis: {
        type: 'category',
        data: dates,
        axisPointer: {
          type: 'shadow'
        },
        axisLabel: {
          rotate: 20,
          interval: 0,
        },
      },
      yAxis: [
        {
          type: 'value',
          name: 'Run Hours',
          axisLabel: {
            formatter: function (value) {
              return formatHHMMSS(value);
            }
          }
        },
        {
          type: 'value',
          name: 'Consumption',
          axisLabel: {
            show: false
          }
        }
      ],
      series: [
        {
          name: 'Run Hours',
          type: 'bar',
          data: runHoursData,
          yAxisIndex: 0,
          label: {
            formatter: function (params) {
              return formatHHMMSS(params.value);
            }
          },
          emphasis: {
            focus: 'series'
          }
        },
        {
          name: 'Consumption',
          type: 'bar',
          data: consumptionData,
          yAxisIndex: 1,
          emphasis: {
            focus: 'series'
          }
        }
      ]
    };

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [data, stationPoint, type, fromDate, toDate]);

  const handleFromDateChange = (event) => {
    const newFromDate = event.target.value;
    setFromDate(newFromDate);

    const maxToDate = new Date(newFromDate);
    maxToDate.setDate(maxToDate.getDate() + 30);
    const newMaxToDateString = maxToDate.toISOString().split('T')[0];

    // Update toDate if it's beyond the new max date
    if (new Date(toDate) > maxToDate) {
      setToDate(newMaxToDateString);
    }
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const calculateToDateMax = () => {
    const fromDateObj = new Date(fromDate);
    const maxToDate = new Date(fromDateObj);
    maxToDate.setDate(fromDateObj.getDate() + 30);
    return maxToDate.toISOString().split('T')[0];
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h1>TOD_Run_Time Data</h1>

      <div>
        <label>
          <span>Station Point</span>
          <select value={stationPoint} onChange={(e) => setStationPoint(e.target.value)}>
            {sortedStationPoints.map(point => (
              <option key={point} value={point}>{point}</option>
            ))}
          </select>
        </label>
        <label>
          <span>Select Type</span>
          <select value={type} onChange={(e) => setType(e.target.value)}>
            <option value="Incentives">Incentives</option>
            <option value="Normal">Normal</option>
            <option value="Penalty">Penalty</option>
          </select>
        </label>
        <label>
          <span>From date</span>
          <input 
            type='date' 
            value={fromDate} 
            onChange={handleFromDateChange} 
          />
        </label>
        <label>
          <span>To date</span>
          <input 
            type='date' 
            value={toDate} 
            onChange={handleToDateChange} 
            min={fromDate} 
            max={calculateToDateMax()} 
          />
        </label>
      </div>

      <div ref={chartRef} style={{ width: '100%', height: '500px' }}></div>
    </div>
  );
};

export default TOD_Run_Time;










// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const stationPointsList = [
//   "DG Incomer -1", "DG Incomer -2", "DG Incomer -3", "Solar-1", "WHR-1", "LV-2", "ENGG store panel", 
//   "Unit-5", "AR&D", "Production", "Lighting", "APFC-2", "DG Grid Incomer -2", "DG Incomer 4", 
//   "DG Incomer 5", "DG Incomer 6", "Solar-2", "WHR-2", "Pump House", "HV", "LV-1", 
//   "Compressor-11 KW", "Compressor-75 KW", "Colony", "Compressor", "APFC-1", "DG Grid Incomer -1", 
//   "Cut bend"
// ].sort();

// const TOD_Run_Time_Month = () => {
//   const [data, setData] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [selectedStation, setSelectedStation] = useState('HV');
//   const [selectedType, setSelectedType] = useState('Incentives');
//   const [fromMonth, setFromMonth] = useState('');
//   const [toMonth, setToMonth] = useState('');

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/todruntime-allmonths`);
//         setData(response.data); // Set data for all months
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching all months data:', error);
//         setError('Error fetching data');
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const formatData = (dataObject) => {
//     return Object.entries(dataObject)
//       .map(([key, value]) => `${key}: ${value}`)
//       .join(', ');
//   };

//   const filterData = () => {
//     let filteredData = {};

//     Object.keys(data).forEach((month) => {
//       if ((fromMonth && month < fromMonth) || (toMonth && month > toMonth)) {
//         return;
//       }

//       filteredData[month] = data[month].filter((item) => 
//         item.stationPoints.includes(selectedStation)
//       );
//     });

//     return filteredData;
//   };

//   const filteredData = filterData();

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>{error}</div>;

//   return (
//     <div>
//       <h1>Monthly Data</h1>
//       <div>
//         <label>
//           <span>Station points</span>
//           <select value={selectedStation} onChange={(e) => setSelectedStation(e.target.value)}>
//             {stationPointsList.map((station) => (
//               <option key={station} value={station}>{station}</option>
//             ))}
//           </select>
//         </label>

//         <label>
//           <span>Select type</span>
//           <select value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
//             <option value="Incentives">Incentives</option>
//             <option value="Normal">Normal</option>
//             <option value="Penalty">Penalty</option>
//           </select>
//         </label>

//         <label>
//           <span>From month</span>
//           <input type='month' value={fromMonth} onChange={(e) => setFromMonth(e.target.value)} />
//         </label>
//         <label>
//           <span>To month</span>
//           <input type='month' value={toMonth} onChange={(e) => setToMonth(e.target.value)} />
//         </label>
//       </div>

//       {Object.keys(filteredData).map((month) => (
//         <div key={month}>
//           <h2>{month}</h2>
//           {filteredData[month].map((item, index) => (
//             <div key={index}>
//               <div><strong>Station Points:</strong> {item.stationPoints.join(', ')}</div>
//               <p><strong>{selectedType} Run Hours:</strong> {formatData(item[selectedType].Run_Hours)}</p>
//               <p><strong>{selectedType} Consumption:</strong> {formatData(item[selectedType].Consumption)}</p>
//             </div>
//           ))}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default TOD_Run_Time_Month;



// import React, { useEffect, useState, useRef } from 'react';
// import axios from 'axios';
// import * as echarts from 'echarts';

// const stationPointsList = [
//   "HV", "DG Incomer -1", "DG Incomer -2", "DG Incomer -3", "Solar-1", "WHR-1", "LV-2", "ENGG store panel", 
//   "Unit-5", "AR&D", "Production", "Lighting", "APFC-2", "DG Grid Incomer -2", "DG Incomer 4", 
//   "DG Incomer 5", "DG Incomer 6", "Solar-2", "WHR-2", "Pump House", "LV-1", 
//   "Compressor-11 KW", "Compressor-75 KW", "Colony", "Compressor", "APFC-1", "DG Grid Incomer -1", 
//   "Cut bend"
// ].sort();

// const TOD_Run_Time_Month = () => {
//   const [data, setData] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [selectedStation, setSelectedStation] = useState('HV');
//   const [selectedType, setSelectedType] = useState('Incentives');
//   const [fromMonth, setFromMonth] = useState('');
//   const [toMonth, setToMonth] = useState('');

//   const chartRef = useRef(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/todruntime-allmonths`);
//         setData(response.data); // Set data for all months
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching all months data:', error);
//         setError('Error fetching data');
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const formatData = (dataObject) => {
//     return Object.entries(dataObject)
//       .map(([key, value]) => `${key}: ${value}`)
//       .join(', ');
//   };

//   const filterData = () => {
//     let filteredData = {};

//     Object.keys(data).forEach((month) => {
//       if ((fromMonth && month < fromMonth) || (toMonth && month > toMonth)) {
//         return;
//       }

//       const filteredItems = data[month].filter((item) => 
//         item.stationPoints.includes(selectedStation)
//       );

//       if (filteredItems.length > 0) {
//         filteredData[month] = filteredItems;
//       }
//     });

//     return filteredData;
//   };

//   const filteredData = filterData();

//   useEffect(() => {
//     if (!chartRef.current) return;

//     const chart = echarts.init(chartRef.current);

//     const runHoursData = [];
//     const consumptionData = [];
//     const months = Object.keys(filteredData);

//     months.forEach(month => {
//       filteredData[month].forEach(item => {
//         const runHours = item[selectedType].Run_Hours[selectedStation];
//         const [hours, minutes, seconds] = runHours.split(':').map(Number);
//         const decimalHours = hours + (minutes / 60) + (seconds / 3600);
//         runHoursData.push(decimalHours);
//         consumptionData.push(item[selectedType].Consumption[selectedStation]);
//       });
//     });

//     const formatHHMMSS = (decimalHours) => {
//       const totalSeconds = decimalHours * 3600;
//       const hours = Math.floor(totalSeconds / 3600);
//       const minutes = Math.floor((totalSeconds % 3600) / 60);
//       const seconds = Math.floor(totalSeconds % 60);
//       return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
//     };

//     const option = {
//       tooltip: {
//         trigger: 'axis',
//         axisPointer: {
//           type: 'shadow'
//         },
//         formatter: function (params) {
//           let tooltip = params[0].name;
//           params.forEach(param => {
//             if (param.seriesName === 'Run Hours') {
//               tooltip += `<br/>${param.marker} ${param.seriesName}: ${formatHHMMSS(param.value)}`;
//             } else {
//               tooltip += `<br/>${param.marker} ${param.seriesName}: ${param.value}`;
//             }
//           });
//           return tooltip;
//         }
//       },
//       toolbox: {
//         feature: {
//           dataView: { show: true, readOnly: false },
//           magicType: { show: true, type: ['line', 'bar'] },
//           restore: { show: true },
//           saveAsImage: { show: true }
//         }
//       },
//       legend: {
//         data: ['Run Hours', 'Consumption']
//       },
//       xAxis: [
//         {
//           type: 'category',
//           data: months,
//           axisPointer: {
//             type: 'shadow'
//           }
//         }
//       ],
//       yAxis: [
//         {
//           type: 'value',
//           name: 'Run Hours',
//           axisLabel: {
//             formatter: function (value) {
//               return formatHHMMSS(value);
//             }
//           }
//         },
//         {
//           type: 'value',
//           name: 'Consumption',
//           axisLabel: {
//             show: false // Hide the consumption axis labels
//           }
//         }
//       ],
//       series: [
//         {
//           name: 'Run Hours',
//           type: 'bar',
//           data: runHoursData,
//           yAxisIndex: 0,
//           label: {
//             // show: true,
//             // position: 'inside',
//             formatter: function (params) {
//               return formatHHMMSS(params.value);
//             }
//           },
//           emphasis: {
//             focus: 'series'
//           }
//         },
//         {
//           name: 'Consumption',
//           type: 'bar',
//           data: consumptionData,
//           yAxisIndex: 1,
//           emphasis: {
//             focus: 'series'
//           }
//         }
//       ]
//     };

//     chart.setOption(option);

//     return () => {
//       chart.dispose();
//     };
//   }, [filteredData, selectedType, selectedStation]);

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>{error}</div>;

//   return (
//     <div>
//       <h1>Monthly Data</h1>
//       <div>
//         <label>
//           <span>Station points</span>
//           <select value={selectedStation} onChange={(e) => setSelectedStation(e.target.value)}>
//             {stationPointsList.map((station) => (
//               <option key={station} value={station}>{station}</option>
//             ))}
//           </select>
//         </label>

//         <label>
//           <span>Select type</span>
//           <select value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
//             <option value="Incentives">Incentives</option>
//             <option value="Normal">Normal</option>
//             <option value="Penalty">Penalty</option>
//           </select>
//         </label>

//         <label>
//           <span>From month</span>
//           <input type='month' value={fromMonth} onChange={(e) => setFromMonth(e.target.value)} />
//         </label>
//         <label>
//           <span>To month</span>
//           <input type='month' value={toMonth} onChange={(e) => setToMonth(e.target.value)} />
//         </label>
//       </div>

//       <div ref={chartRef} style={{ width: '100%', height: '500px' }}></div>
//     </div>
//   );
// };

// export default TOD_Run_Time_Month;
