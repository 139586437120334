// import React, { useEffect, useState, useRef } from 'react';
// import axios from 'axios';
// import * as echarts from 'echarts';
// import './DashTypeofload.css';

// const DashTypeofloadConsumption = ({ selectedMonth }) => {
//   const [data, setData] = useState([]);
//   const [percentages, setPercentages] = useState([]);
//   const [loadTypes, setLoadTypes] = useState([]);
//   const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
//   const [isDataAvailable, setIsDataAvailable] = useState(true);
//   const chartRef = useRef(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/typeofloadconsumption/latestmonthlyconsumption`);
//         const filteredData = response.data.filter(item => item.Month === selectedMonth);
//         setData(filteredData);
//         setIsDataAvailable(filteredData.length > 0); // Check if data is available
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, [selectedMonth]);

//   useEffect(() => {
//     const calculatePercentages = () => {
//       if (data.length === 0) {
//         setPercentages([]);
//         setLoadTypes([]);
//         return;
//       }

//       const percentageData = {};
//       const uniqueLoadTypes = new Set();

//       data.forEach((item) => {
//         const typeOfLoad = item['TYPE OF LOAD'];
//         const consumption = parseFloat(item.Cumulative_KWh_Consumption);

//         if (!percentageData[typeOfLoad]) {
//           percentageData[typeOfLoad] = 0;
//         }

//         percentageData[typeOfLoad] += consumption;
//         uniqueLoadTypes.add(typeOfLoad);
//       });

//       const totalSum = Object.values(percentageData).reduce((a, b) => a + b, 0);

//       const result = Object.keys(percentageData).map((typeOfLoad) => {
//         const percentage = (percentageData[typeOfLoad] / totalSum) * 100;
//         return {
//           typeOfLoad,
//           percentage: percentage.toFixed(2)
//         };
//       });

//       setPercentages(result);
//       setLoadTypes(Array.from(uniqueLoadTypes));
//     };

//     calculatePercentages();
//   }, [data]);

//   useEffect(() => {
//     if (chartRef.current && isDataAvailable) {
//       const myChart = echarts.init(chartRef.current);
//       const seriesData = percentages.map(({ typeOfLoad, percentage }) => ({
//         value: percentage,
//         name: typeOfLoad
//       }));

//       const chartOption = {
//         title: {
//           text: 'Type of Load Consumption',
//           left: 'center'
//         },
//         tooltip: {
//           trigger: 'item',
//         },
//         legend: {
//           top: '5%',
//           left: 'center',
//           data: loadTypes,
//         },
//         toolbox: {
//           show: true,
//           feature: {
//             dataView: { show: true, readOnly: false },
//             magicType: { show: true, type: ['pie', 'funnel'] },
//             saveAsImage: { show: true }
//           }
//         },
//         color: [
//           '#34A853',
//           '#46BDC6',
//           '#7BAAF7',
//           '#FF6D01',
//           '#FCD04F',
//           '#F07B72',
//           '#EA4335',
//           '#CCBC81 ',
//         ],
//         series: [
//           {
//             name: 'Type of Load',
//             type: 'pie',
//             radius: '50%',
//             data: seriesData,
//             emphasis: {
//               itemStyle: {
//                 shadowBlur: 10,
//                 shadowOffsetX: 0,
//                 shadowColor: 'rgba(0, 0, 0, 0.5)'
//               }
//             },
//             label: {
//               show: true,
//               formatter: '{b} ({d}%)'
//             }
//           }
//         ]
//       };

//       myChart.setOption(chartOption);
//     }
//   }, [percentages, loadTypes, isDataAvailable]);

//   const handleMonthChange = (event) => {
//     setSelectedMonth(event.target.value);
//   };

//   function getCurrentMonth() {
//     const now = new Date();
//     const year = now.getFullYear();
//     const month = String(now.getMonth() + 1).padStart(2, '0');
//     return `${year}-${month}`;
//   }

//   return (
//     <div>
//       <div>
//         <label>
//           <span>Month</span>
//           <input
//             type='month'
//             value={selectedMonth}
//             onChange={handleMonthChange}
//           />
//         </label>
//       </div>
//       <h1>Type of Load Consumption</h1>
//       {isDataAvailable && percentages.length > 0 ? (
//         <div ref={chartRef} style={{ width: '100%', height: '400px', marginTop: '20px' }}></div>
//       ) : (
//         <div style={{ textAlign: 'center', marginTop: '20px' }}>
//           <p>No data available for the selected month</p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default DashTypeofloadConsumption;




// import React, { useEffect, useState, useRef } from 'react';
// import axios from 'axios';
// import * as echarts from 'echarts';
// import './DashTypeofload.css';

// const DashTypeofloadConsumption = ({ selectedMonth }) => {
//     const [data, setData] = useState([]);
//     const [percentages, setPercentages] = useState([]);
//     const [loadTypes, setLoadTypes] = useState([]);
//     const [isDataAvailable, setIsDataAvailable] = useState(true);
//     const chartRef = useRef(null);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/typeofloadconsumption/latestmonthlyconsumption`);
//                 const filteredData = response.data.filter(item => item.Month === selectedMonth);
//                 setData(filteredData);
//                 setIsDataAvailable(filteredData.length > 0); // Check if data is available
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         fetchData();
//     }, [selectedMonth]);

//     useEffect(() => {
//         const calculatePercentages = () => {
//             if (data.length === 0) {
//                 setPercentages([]);
//                 setLoadTypes([]);
//                 return;
//             }

//             const percentageData = {};
//             const uniqueLoadTypes = new Set();

//             data.forEach((item) => {
//                 const typeOfLoad = item['TYPE OF LOAD'];
//                 const consumption = parseFloat(item.Cumulative_KWh_Consumption);

//                 if (!percentageData[typeOfLoad]) {
//                     percentageData[typeOfLoad] = 0;
//                 }

//                 percentageData[typeOfLoad] += consumption;
//                 uniqueLoadTypes.add(typeOfLoad);
//             });

//             const totalSum = Object.values(percentageData).reduce((a, b) => a + b, 0);

//             const result = Object.keys(percentageData).map((typeOfLoad) => {
//                 const percentage = (percentageData[typeOfLoad] / totalSum) * 100;
//                 return {
//                     typeOfLoad,
//                     percentage: percentage.toFixed(2)
//                 };
//             });

//             setPercentages(result);
//             setLoadTypes(Array.from(uniqueLoadTypes));
//         };

//         calculatePercentages();
//     }, [data]);

//     useEffect(() => {
//         if (chartRef.current && isDataAvailable) {
//             const myChart = echarts.init(chartRef.current);
//             const seriesData = percentages.map(({ typeOfLoad, percentage }) => ({
//                 value: percentage,
//                 name: typeOfLoad
//             }));

//             const chartOption = {
//                 title: {
//                     // text: 'Type of Load Consumption',
//                     left: 'center'
//                 },
//                 tooltip: {
//                     trigger: 'item',
//                 },
//                 legend: {
//                     top: '5%',
//                     left: 'center',
//                     data: loadTypes,
//                 },
//                 toolbox: {
//                     show: true,
//                     feature: {
//                         // dataView: { show: true, readOnly: false },
//                         magicType: { show: true, type: ['pie', 'funnel'] },
//                         // saveAsImage: { show: true }
//                     }
//                 },
//                 color: [
//                     '#34A853',
//                     '#46BDC6',
//                     '#7BAAF7',
//                     '#FF6D01',
//                     '#FCD04F',
//                     '#F07B72',
//                     '#EA4335',
//                     '#CCBC81 ',
//                 ],
//                 series: [
//                     {
//                         name: 'Type of Load',
//                         type: 'pie',
//                         radius: '50%',
//                         data: seriesData,
//                         emphasis: {
//                             itemStyle: {
//                                 shadowBlur: 10,
//                                 shadowOffsetX: 0,
//                                 shadowColor: 'rgba(0, 0, 0, 0.5)'
//                             }
//                         },
//                         label: {
//                             show: true,
//                             formatter: '{b} ({d}%)'
//                         }
//                     }
//                 ]
//             };

//             myChart.setOption(chartOption);
//         }
//     }, [percentages, loadTypes, isDataAvailable]);

//     return (
//         <div id='dashtypeofload-total'>
//             <span>Consumption for types of load</span>
//             <div>
//                 {data.length === 0 && <p>Not Available</p>}
//                 <div ref={chartRef} id='dashtypeofload-piechart'></div>

//             </div>

//         </div>
//     );
// };

// export default DashTypeofloadConsumption;



import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';
import './DashTypeofload.css';

const DashTypeofloadConsumption = ({ selectedMonth }) => {
    const [data, setData] = useState([]);
    const [percentages, setPercentages] = useState([]);
    const [loadTypes, setLoadTypes] = useState([]);
    const [isDataAvailable, setIsDataAvailable] = useState(true);
    const chartRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/typeofloadconsumption/latestmonthlyconsumption`);
                const filteredData = response.data.filter(item => item.Month === selectedMonth);
                setData(filteredData);
                setIsDataAvailable(filteredData.length > 0); // Check if data is available
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsDataAvailable(false);
            }
        };

        fetchData();
    }, [selectedMonth]);

    useEffect(() => {
        const calculatePercentages = () => {
            if (data.length === 0) {
                setPercentages([]);
                setLoadTypes([]);
                return;
            }

            const percentageData = {};
            const uniqueLoadTypes = new Set();

            data.forEach((item) => {
                const typeOfLoad = item['TYPE OF LOAD'];
                const consumption = parseFloat(item.Cumulative_KWh_Consumption);

                if (!percentageData[typeOfLoad]) {
                    percentageData[typeOfLoad] = 0;
                }

                percentageData[typeOfLoad] += consumption;
                uniqueLoadTypes.add(typeOfLoad);
            });

            const totalSum = Object.values(percentageData).reduce((a, b) => a + b, 0);

            const result = Object.keys(percentageData).map((typeOfLoad) => {
                const percentage = (percentageData[typeOfLoad] / totalSum) * 100;
                return {
                    typeOfLoad,
                    percentage: percentage.toFixed(2)
                };
            });

            setPercentages(result);
            setLoadTypes(Array.from(uniqueLoadTypes));
        };

        calculatePercentages();
    }, [data]);

    useEffect(() => {
        if (chartRef.current && isDataAvailable) {
            const myChart = echarts.init(chartRef.current);
            const seriesData = percentages.map(({ typeOfLoad, percentage }) => ({
                value: percentage,
                name: typeOfLoad
            }));

            const chartOption = {
                title: {
                    // text: 'Type of Load Consumption',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                },
                legend: {
                    top: '5%',
                    left: 'center',
                    data: loadTypes,
                },
                toolbox: {
                    show: true,
                    feature: {
                        // dataView: { show: true, readOnly: false },
                        magicType: { show: true, type: ['pie', 'funnel'] },
                        // saveAsImage: { show: true }
                    }
                },
                color: [
                    '#34A853',
                    '#46BDC6',
                    '#7BAAF7',
                    '#FF6D01',
                    '#FCD04F',
                    '#F07B72',
                    '#EA4335',
                    '#CCBC81 ',
                ],
                series: [
                    {
                        name: 'Type of Load',
                        type: 'pie',
                        radius: '50%',
                        data: seriesData,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                        label: {
                            show: true,
                            formatter: '{b} ({d}%)'
                        }
                    }
                ]
            };

            myChart.setOption(chartOption);
        }
    }, [percentages, loadTypes, isDataAvailable]);

    return (
        <div id='dashtypeofload-total'>
            <span>Load Type Consumption Breakdown</span>
            <div>
                {!isDataAvailable && <p style={{textAlign:'center'}} >Not Available</p>}
                {isDataAvailable && <div ref={chartRef} id='dashtypeofload-piechart'></div>}
            </div>
        </div>
    );
};

export default DashTypeofloadConsumption;

