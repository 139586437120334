// import React, { useState, useEffect } from 'react'
// import axios from 'axios';

// const Stationpoints = ({ onStationPointChange }) => {

//     const [stationpointsdata, setStationpointsdata] = useState([]);
//     const [error, setError] = useState(null);
//     useEffect(() => {

//         const fetchStationpointsdata = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/allstation-points`);
//                 setStationpointsdata(response.data);
//             } catch (error) {
//                 setError(error);
//             }
//         };

//         fetchStationpointsdata();
//     }, []);

//     if (error) {
//         return <div>Error: {error.message}</div>;
//     }

//     const handleStationPointChange = (e) => {
//         onStationPointChange(e.target.value);
//     };

//     return (
//         <div>
//             <select style={{ borderRadius: '5px', border: '1.5px solid #DFE6FA', height: '4.7vh' }} id="station-points" onChange={handleStationPointChange}>
//                 {stationpointsdata.map((point, index) => (
//                     <option key={index} value={point["Station point"]}>
//                         {point["Station point"]}
//                     </option>
//                 ))}
//             </select>
//         </div>
//     )
// }

// export default Stationpoints

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import "./Stationpoints.css";
// const Stationpoints = ({
//   onStationPointChange,
//   defaultStationPoint = "",
//   showAll = false,
// }) => {
//   const [stationpointsdata, setStationpointsdata] = useState([]);
//   const [error, setError] = useState(null);
//   const [selectedStationPoint, setSelectedStationPoint] = useState(defaultStationPoint);

//   useEffect(() => {
//     if (defaultStationPoint) {
//       setSelectedStationPoint(defaultStationPoint);
//     }
//   }, [defaultStationPoint]);

//   useEffect(() => {
//     const fetchStationpointsdata = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_API_EMS_URL}/api/allstation-points`
//         );
//         setStationpointsdata(response.data);
//       } catch (error) {
//         setError(error);
//       }
//     };

//     fetchStationpointsdata();
//   }, []);

//   if (error) {
//     return <div>Error: {error.message}</div>;
//   }

//   const handleStationPointChange = (e) => {
//     setSelectedStationPoint(e.target.value);
//     onStationPointChange(e.target.value);
//   };

//   return (
//     <div className="station-select-container">
//       <label>
//         <span>Select Sections:</span>

//         <Select
//     showSearch
//     style={{
//       width: 200,
//     }}
//     placeholder="Search to Select"
//     optionFilterProp="label"
//     filterSort={(optionA, optionB) =>
//       (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
//     }
//     options={[

//     ]}
//   />
//         <select
//           style={{ height: "36px" }}
//           id="station-points"
//           value={selectedStationPoint}
//           onChange={handleStationPointChange}
//         >
//           {(showAll || !defaultStationPoint) && (
//             <option value="">Select All</option>
//           )}

//           {stationpointsdata.map((point, index) => (
//             <option key={index} value={point["Station point"]}>
//               {point["Station point"]}
//             </option>
//           ))}
//         </select>
//       </label>
//     </div>
//   );
// };

// export default Stationpoints;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select } from "antd"; // Import Ant Design Select component
import "./Stationpoints.css";

const Stationpoints = ({
  onStationPointChange,
  defaultStationPoint = "",
  showAll = false,
  isHorizontal = false,
}) => {
  const [stationpointsdata, setStationpointsdata] = useState([]);
  const [error, setError] = useState(null);
  const [selectedStationPoint, setSelectedStationPoint] =
    useState(defaultStationPoint);

  // Update selected station point if defaultStationPoint changes
  useEffect(() => {
    if (defaultStationPoint) {
      setSelectedStationPoint(defaultStationPoint);
    }
  }, [defaultStationPoint]);

  // Fetch station points data on component mount
  useEffect(() => {
    const fetchStationpointsdata = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_EMS_URL}/api/allstation-points`
        );
        setStationpointsdata(response.data);
      } catch (error) {
        setError(error);
      }
    };

    fetchStationpointsdata();
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleStationPointChange = (value) => {
    setSelectedStationPoint(value || ""); // Set to empty string if value is null
    onStationPointChange(value || "");
  };

  // Prepare options for the Ant Design Select component
  const options = stationpointsdata.map((point) => ({
    value: point["Station point"],
    label: point["Station point"],
  }));

  return (
    <div className="station-select-container">
      {isHorizontal && (
        <span style={{ marginRight: "5px" }}>Select Sections: </span>
      )}
      <label>
        {!isHorizontal && <span>Select Sections:</span>}
        <Select
          showSearch
          allowClear
          style={{
            width: 200,
            border: "none",
            boxShadow: "none",
            outline: "none",
            // height: "30px",
            zIndex: "1",
            // display: 'inline-block',
          }}
          dropdownStyle={{
            zIndex: 1050, // Ensure the dropdown appears on top
          }}
          size="medium"
          // style={{ width: 250 }}
          placeholder="Search to Select"
          optionFilterProp="label"
          value={selectedStationPoint || undefined}
          onChange={handleStationPointChange}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={[
            ...(showAll ? [{ value: "", label: "Select All" }] : []),
            ...options,
          ]}
        />
      </label>
    </div>
  );
};

export default Stationpoints;
