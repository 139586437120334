// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .score-card-description {
    font-family: DM Sans !important;
    font-weight: 400 !important;
    padding: 0 250px !important;
    margin-bottom: 20px !important;
    font-size: 0.9rem !important;
  }
  
  .energy-intensity {
    font-family: DM Sans !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 12px;
    letter-spacing: -0.05em;
    text-align: left;
    margin-bottom: 20px;
  }
  
  .energy-intensity-actual {
    font-family: DM Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 11.72px;
    text-align: left;
    margin-bottom: 30px;
  }

  
  .total-loss-value-cri {
    font-family: DM Sans !important;
    font-size: 50px !important;
    font-weight: 700 !important;
    line-height: 31.25px;
    letter-spacing: -0.05em;
    text-align: left;
    color: #000000 !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Reports/WeeklyReport/LossesWithAlerts/LossesWithAlerts.css"],"names":[],"mappings":";EACE;IACE,+BAA+B;IAC/B,2BAA2B;IAC3B,2BAA2B;IAC3B,8BAA8B;IAC9B,4BAA4B;EAC9B;;EAEA;IACE,+BAA+B;IAC/B,0BAA0B;IAC1B,2BAA2B;IAC3B,iBAAiB;IACjB,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;IAChB,mBAAmB;EACrB;;;EAGA;IACE,+BAA+B;IAC/B,0BAA0B;IAC1B,2BAA2B;IAC3B,oBAAoB;IACpB,uBAAuB;IACvB,gBAAgB;IAChB,yBAAyB;EAC3B","sourcesContent":["\n  .score-card-description {\n    font-family: DM Sans !important;\n    font-weight: 400 !important;\n    padding: 0 250px !important;\n    margin-bottom: 20px !important;\n    font-size: 0.9rem !important;\n  }\n  \n  .energy-intensity {\n    font-family: DM Sans !important;\n    font-size: 20px !important;\n    font-weight: 700 !important;\n    line-height: 12px;\n    letter-spacing: -0.05em;\n    text-align: left;\n    margin-bottom: 20px;\n  }\n  \n  .energy-intensity-actual {\n    font-family: DM Sans;\n    font-size: 15px;\n    font-weight: 400;\n    line-height: 11.72px;\n    text-align: left;\n    margin-bottom: 30px;\n  }\n\n  \n  .total-loss-value-cri {\n    font-family: DM Sans !important;\n    font-size: 50px !important;\n    font-weight: 700 !important;\n    line-height: 31.25px;\n    letter-spacing: -0.05em;\n    text-align: left;\n    color: #000000 !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
