import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

import { Bar, Doughnut, Pie } from "react-chartjs-2";
// import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the plugin
import { Flex } from "antd";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const ConsuptionChart = ({ consumptionData = [], labels = [] }) => {
  const data = {
    labels,
    datasets: [
      {
        data: consumptionData,
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        datalabels: {
          anchor: "center",
        },
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        color: "black",
        font: {
          weight: "bold",
          size: "16px",
        },
        padding: 6,
      },
    },
  };

  return <Pie  data={data} options={options} />;
};

export default ConsuptionChart;
