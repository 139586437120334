

import React, { useEffect, useState } from 'react';
import YesterdayDataUnfilledforms from './Yesterdayunfilledforms';
import MonthlyUnfilledforms from './Monthlyunfilledforms';
import './Unfilledforms.css';

const Unfilledforms = ({ setUnfilledFormsCount }) => {
  // const [unfilledCount, setUnfilledCount] = useState(0);

  // useEffect(() => {
  //   setUnfilledFormsCount(unfilledCount);
  // }, [unfilledCount, setUnfilledFormsCount]);
  const [yesterdayCount, setYesterdayCount] = useState(0);
  const [monthlyCount, setMonthlyCount] = useState(0);

  useEffect(() => {
    setUnfilledFormsCount(yesterdayCount + monthlyCount);
  }, [yesterdayCount, monthlyCount, setUnfilledFormsCount]);

  return (
    <div id='dashunfilled-total'>
      {/* <YesterdayDataUnfilledforms setUnfilledFormsCount={setUnfilledCount} />
      <MonthlyUnfilledforms setUnfilledFormsCount={setUnfilledCount} /> */}
         <YesterdayDataUnfilledforms setYesterdayCount={setYesterdayCount} />
         <MonthlyUnfilledforms setMonthlyCount={setMonthlyCount} />
    </div>
  );
};

export default Unfilledforms;
