import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import './Harmonic_filtersfetch.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Harmonicfiltersfetch = ({ onFormButtonClick }) => {
    const [harmonicFilters, setHarmonicFilters] = useState([]);
    const [harmonicFilterEditData, setHarmonicFilterEditData] = useState(null);
    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-lvtransformersectionames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    const fetchHarmonicFilters = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/harmonicfilters`); // Adjust endpoint URL
            setHarmonicFilters(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchHarmonicFilters();
    }, []);

    const handleEditClick = (harmonicFilter) => {
        setHarmonicFilterEditData(harmonicFilter);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setHarmonicFilterEditData({ ...harmonicFilterEditData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        console.log('Updating data:', harmonicFilterEditData);
        toast.success("Harmonic Filter updated successfully");
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/harmonicfilters/${harmonicFilterEditData._id}`, harmonicFilterEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchHarmonicFilters();
            setHarmonicFilterEditData(null);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='harmonicfiltersfetch-total'>
            <div id='harmonicfiltersfetch-right'>
                <div id='harmonicfiltersfetch-right-2'>
                    <div id='harmonicfiltersfetch-right-21'>
                        <label>
                            <span> {Masterformsheadingsss.HarmonicFilterHeading}</span>

                        </label>
                        <label>
                            <button onClick={onFormButtonClick}>{Masterformsheadingsss.Formheading}</button>
                        </label>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Parent Name</th>
                                <th>Name</th>
                                <th>Meter Make</th>
                                <th>Meter SL No</th>
                                <th>Phase</th>
                                <th>Capacity (kVA)</th>
                                <th>Harmonic Frequency Range</th>
                                <th>Attenuation Performance</th>
                                <th>Rated Voltage</th>
                                <th>Rated Current</th>
                                <th>Connection Type</th>
                                <th>Operation Frequency</th>
                                <th>Efficiency</th>
                                <th>Auto Select</th>
                                <th>Overcurrent Protection</th>
                                <th>Overvoltage Protection</th>
                                <th>Cooling Method</th>
                                <th>Proposed Periodicity of Maintenance</th>
                                <th>Previous Maintenance Done Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {harmonicFilters.map((harmonicFilter) => (
                                <tr key={harmonicFilter._id}>
                                    <td>{harmonicFilter.date}</td>
                                    <td>{harmonicFilter.parentname}</td>
                                    <td>{harmonicFilter.name}</td>
                                    <td>{harmonicFilter.metermake}</td>
                                    <td>{harmonicFilter.meterslno}</td>
                                    <td>{harmonicFilter.phase}</td>
                                    <td>{harmonicFilter.capacityinva}</td>
                                    <td>{harmonicFilter.harmonicfrequencyrange}</td>
                                    <td>{harmonicFilter.attenuationperformance}</td>
                                    <td>{harmonicFilter.ratedvoltage}</td>
                                    <td>{harmonicFilter.ratedcurrent}</td>
                                    <td>{harmonicFilter.connectiontype}</td>
                                    <td>{harmonicFilter.operationfrequency}</td>
                                    <td>{harmonicFilter.efficiency}</td>
                                    <td>{harmonicFilter.autoselect}</td>
                                    <td>{harmonicFilter.overcurrentprotectionavailable}</td>
                                    <td>{harmonicFilter.overvoltageprotectionavailable}</td>
                                    <td>{harmonicFilter.coolingmethod}</td>
                                    <td>{harmonicFilter.proposedperiodicityofmaintenance}</td>
                                    <td>{harmonicFilter.previousmaintenancedonedate}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(harmonicFilter)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {harmonicFilterEditData && (
                        <Popup open={true} onClose={() => setHarmonicFilterEditData(null)}>
                            <div id='harmonicfiltersfetch-popup'>
                                <h3>Edit Harmonic Filter</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='harmonicfiltersfetch-popup1'>

                                        <label>
                                            <span>Date</span>
                                            <input name="date" value={harmonicFilterEditData.date} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Parent Name</span>
                                            <select name="parentname" value={harmonicFilterEditData.parentname} onChange={handleEditChange}>
                                                <option value="">Select Parent Name</option>
                                                {parentNames.map((name) => (
                                                    <option key={name} value={name}>{name}</option>
                                                ))}
                                            </select>
                                        </label>
                                    </div>
                                    <div id='harmonicfiltersfetch-popup1'>

                                        <label>
                                            <span>Name</span>
                                            <input type="text" name="name" value={harmonicFilterEditData.name} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Meter Make</span>
                                            <input type="text" name="metermake" value={harmonicFilterEditData.metermake} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='harmonicfiltersfetch-popup1'>
                                        <label>
                                            <span>Meter SL No</span>
                                            <input type="number" name="meterslno" value={harmonicFilterEditData.meterslno} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Phase</span>
                                            <select name="phase" value={harmonicFilterEditData.phase} onChange={handleEditChange} required>
                                                <option value=''>Select Phase</option>
                                                <option value='3-Phase'>3-Phase</option>
                                                <option value='1-Phase-Vr'>1-Phase-Vr</option>
                                                <option value='1-Phase-Vy'>1-Phase-Vy</option>
                                                <option value='1-Phase-Vb'>1-Phase-Vb</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div id='harmonicfiltersfetch-popup1'>
                                        <label>
                                            <span>Capacity (kVA)</span>
                                            <input type="number" name="capacityinva" value={harmonicFilterEditData.capacityinva} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Harmonic Frequency Range</span>
                                            <input type="number" name="harmonicfrequencyrange" value={harmonicFilterEditData.harmonicfrequencyrange} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='harmonicfiltersfetch-popup1'>
                                        <label>
                                            <span>Attenuation Performance</span>
                                            <input type="number" name="attenuationperformance" value={harmonicFilterEditData.attenuationperformance} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Rated Voltage</span>
                                            <input type="number" name="ratedvoltage" value={harmonicFilterEditData.ratedvoltage} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='harmonicfiltersfetch-popup1'>
                                        <label>
                                            <span>Rated Current</span>
                                            <input type="number" name="ratedcurrent" value={harmonicFilterEditData.ratedcurrent} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Connection Type</span>
                                            <input type="text" name="connectiontype" value={harmonicFilterEditData.connectiontype} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='harmonicfiltersfetch-popup1'>
                                        <label>
                                            <span>Operation Frequency</span>
                                            <input type="number" name="operationfrequency" value={harmonicFilterEditData.operationfrequency} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Efficiency</span>
                                            <input type="number" name="efficiency" value={harmonicFilterEditData.efficiency} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='harmonicfiltersfetch-popup1'>
                                        <label>
                                            <span>Auto Select</span>
                                            <input type="text" name="autoselect" value={harmonicFilterEditData.autoselect} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Overcurrent Protection</span>
                                            <select name="overcurrentprotectionavailable" value={harmonicFilterEditData.overcurrentprotectionavailable} onChange={handleEditChange}>
                                                <option value=''>Select Option</option>
                                                <option value='Y'>Yes</option>
                                                <option value='N'>No</option>
                                            </select>
                                            {/* <input type="text" name="overcurrentprotectionavailable" value={harmonicFilterEditData.overcurrentprotectionavailable} onChange={handleEditChange} /> */}
                                        </label>
                                    </div>
                                    <div id='harmonicfiltersfetch-popup1'>
                                        <label>
                                            <span>Overvoltage Protection</span>
                                            <select name="overvoltageprotectionavailable" value={harmonicFilterEditData.overvoltageprotectionavailable} onChange={handleEditChange} >
                                                <option value=''>Select Option</option>
                                                <option value='Y'>Yes</option>
                                                <option value='N'>No</option>
                                            </select>
                                            {/* <input type="text" name="overvoltageprotectionavailable" value={harmonicFilterEditData.overvoltageprotectionavailable} onChange={handleEditChange} /> */}
                                        </label>
                                        <label>
                                            <span>Cooling Method</span>
                                            <input type="text" name="coolingmethod" value={harmonicFilterEditData.coolingmethod} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='harmonicfiltersfetch-popup1'>
                                        <label>
                                            <span>Proposed Periodicity of Maintenance</span>
                                            <input type="number" name="proposedperiodicityofmaintenance" value={harmonicFilterEditData.proposedperiodicityofmaintenance} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Previous Maintenance Done Date</span>
                                            <input type="date" name="previousmaintenancedonedate" value={harmonicFilterEditData.previousmaintenancedonedate} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='harmonicfiltersfetch-popup1'>
                                        <button id='harmonicfiltersfetch-popup-update' type="submit">{Masterformsheadingsss.Updateheading}</button>
                                    </div>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Harmonicfiltersfetch;
