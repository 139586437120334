import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UPS.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const UPS = ({ onViewData }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    const initialFormData = {
        date: getCurrentDate(),
        upsid: '',
        name: '',
        parentname: '',
        metermake: '',
        meterslno: '',
        phase: '',
        upstopology: '',
        inputvoltage: '',
        outputvoltage: '',
        ratedpowerinva: '',
        operatingefficiency: '',
        backuptime: '',
        connectedloadinva: '',
        batterytype: '',
        numberofbatteries: '',
        eachbatterycapacity: '',
        batteryrechargetime: '',
        coolingsystem: '',
        proposedperiodicityofmaintenanceofups: '',
        proposedperiodicityofmaintenanceofbatteries: '',
        previousmaintenancedonedateforups: '',
        previousmaintenancedonedateofbatteries: '',
    };

    const [formData, setFormData] = useState(initialFormData);
    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-lvtransformersectionames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = {
                ...formData,
                inputvoltage: Number(formData.inputvoltage),
                outputvoltage: Number(formData.outputvoltage),
                ratedpowerinva: Number(formData.ratedpowerinva),
                operatingefficiency: Number(formData.operatingefficiency),
                numberofbatteries: Number(formData.numberofbatteries),
                eachbatterycapacity: Number(formData.eachbatterycapacity),
                connectedloadinva: Number(formData.connectedloadinva),
            };

            await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/ups`, dataToSend);

            toast.success('UPS added successfully');
            setFormData(initialFormData);
            fetchParentNames();
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    return (
        <div id='ups-total'>
            <div id='ups-right'>
                <div id='ups-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='ups-divtop'>
                            <label>
                                <span> {Masterformsheadingsss.UPSHeading}</span>
                            </label>
                            <label>
                                <button type="button" onClick={onViewData}>View Data</button>
                            </label>
                        </div>
                        <div id='ups-div1'>
                            <label>
                                <span>Date</span>
                                <input type='date' name="date" value={formData.date} disabled />
                            </label>
                            <label>
                                <span>Parent Name</span>
                                <select name="parentname" value={formData.parentname} onChange={handleChange} >
                                    <option value="">Select Parent Name</option>
                                    {parentNames.map((name, index) => (
                                        <option key={index} value={name}>{name}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div id='ups-div1'>
                            <label>
                                <span>Name</span>
                                <input name="name" value={formData.name} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>UPS Topology</span>
                                <input name="upstopology" value={formData.upstopology} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='ups-div1'>
                            <label>
                                <span>Meter Make</span>
                                <input name="metermake" value={formData.metermake} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Meter Serial No.</span>
                                <input name="meterslno" value={formData.meterslno} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='ups-div1'>
                            <label>
                                <span>Phase</span>
                                <select name="phase" value={formData.phase} onChange={handleChange} required>
                                    <option value=''>Select Phase</option>
                                    <option value='3-Phase'>3-Phase</option>
                                    <option value='1-Phase-Vr'>1-Phase-Vr</option>
                                    <option value='1-Phase-Vy'>1-Phase-Vy</option>
                                    <option value='1-Phase-Vb'>1-Phase-Vb</option>
                                </select>
                            </label>
                            <label>
                                <span>Input Voltage</span>
                                <input type='number' name="inputvoltage" value={formData.inputvoltage} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='ups-div1'>
                            <label>
                                <span>Output Voltage</span>
                                <input type='number' name="outputvoltage" value={formData.outputvoltage} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Rated Power (in kVA)</span>
                                <input type='number' name="ratedpowerinva" value={formData.ratedpowerinva} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='ups-div1'>
                            <label>
                                <span>Operating Efficiency</span>
                                <input type='number' name="operatingefficiency" value={formData.operatingefficiency} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Backup Time</span>
                                <input name="backuptime" value={formData.backuptime} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='ups-div1'>
                            <label>
                                <span>Connected Load (in kVA)</span>
                                <input type='number' name="connectedloadinva" value={formData.connectedloadinva} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Battery Type</span>
                                <input name="batterytype" value={formData.batterytype} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='ups-div1'>
                            <label>
                                <span>Number of Batteries</span>
                                <input type='number' name="numberofbatteries" value={formData.numberofbatteries} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Each Battery Capacity</span>
                                <input type='number' name="eachbatterycapacity" value={formData.eachbatterycapacity} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='ups-div1'>
                            <label>
                                <span>Battery Recharge Time</span>
                                <input name="batteryrechargetime" value={formData.batteryrechargetime} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Cooling System</span>
                                <input name="coolingsystem" value={formData.coolingsystem} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='ups-div1'>
                            <label>
                                <span>Proposed Periodicity of Maintenance (UPS)</span>
                                <input name="proposedperiodicityofmaintenanceofups" value={formData.proposedperiodicityofmaintenanceofups} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Proposed Periodicity of Maintenance (Batteries)</span>
                                <input name="proposedperiodicityofmaintenanceofbatteries" value={formData.proposedperiodicityofmaintenanceofbatteries} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='ups-div1'>
                            <label>
                                <span>Previous Maintenance Date (UPS)</span>
                                <input type='date' name="previousmaintenancedonedateforups" value={formData.previousmaintenancedonedateforups} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Previous Maintenance Date (Batteries)</span>
                                <input type='date' name="previousmaintenancedonedateofbatteries" value={formData.previousmaintenancedonedateofbatteries} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='ups-divbottom'>
                            <label>
                                <button id='ups-save' type='submit'>{Masterformsheadingsss.Submitheading}</button>
                            </label>

                        </div>

                    </form>
                </div>
            </div>
        </div>
    );
};

export default UPS;
