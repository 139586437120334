
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import './Unfilledforms.css';

// const getYesterdayDate = () => {
//   const today = new Date();
//   const yesterday = new Date(today);
//   yesterday.setDate(today.getDate() - 1);
//   const yyyy = yesterday.getFullYear();
//   const mm = String(yesterday.getMonth() + 1).padStart(2, '0');
//   const dd = String(yesterday.getDate()).padStart(2, '0');
//   return `${yyyy}-${mm}-${dd}`;
// };

// const formatDate = (date) => {
//   const yyyy = date.getFullYear();
//   const mm = String(date.getMonth() + 1).padStart(2, '0');
//   const dd = String(date.getDate()).padStart(2, '0');
//   return `${yyyy}-${mm}-${dd}`;
// };

// const YesterdayDataUnfilledforms = ({ setYesterdayCount }) => {
//   const [productionData, setProductionData] = useState([]);
//   const [monitoringData, setMonitoringData] = useState([]);
//   const [missingProductionDates, setMissingProductionDates] = useState([]);
//   const [missingMonitoringDates, setMissingMonitoringDates] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const yesterday = getYesterdayDate();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const firstFilledResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/first-filled-date`);
//         const firstDate = firstFilledResponse.data.firstFilledDate;

//         const productionResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/production-entry`);
//         const fetchedProductionData = productionResponse.data;

//         const monitoringResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/transformer-monitoring-logs`);
//         const fetchedMonitoringData = monitoringResponse.data;

//         const calculateMissingDates = () => {
//           const missingProduction = [];
//           const missingMonitoring = [];
//           const startDate = new Date(firstDate);
//           const endDate = new Date(yesterday);

//           for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
//             const formattedDate = formatDate(d);
//             const productionEntryExists = fetchedProductionData.some(item => item.date === formattedDate);
//             const monitoringEntryExists = fetchedMonitoringData.some(item => item.date === formattedDate);

//             if (!productionEntryExists) {
//               missingProduction.push(formattedDate);
//             }

//             if (!monitoringEntryExists) {
//               missingMonitoring.push(formattedDate);
//             }
//           }
//           return { missingProduction, missingMonitoring };
//         };

//         const { missingProduction, missingMonitoring } = calculateMissingDates();
//         setMissingProductionDates(missingProduction);
//         setMissingMonitoringDates(missingMonitoring);

//         setProductionData(fetchedProductionData.filter(item => item.date === yesterday));
//         setMonitoringData(fetchedMonitoringData.filter(item => item.date === yesterday));

//         setYesterdayCount(missingProduction.length + missingMonitoring.length);

//       } catch (err) {
//         setError('Error fetching data');
//         console.error('Error fetching data:', err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [yesterday, setYesterdayCount]);

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>{error}</div>;

//   return (
//     <div>
//       {missingProductionDates.map(date => (
//         <div id='dashunfilled-div' key={date}>
//           <div>No Production entry data available on {date}</div>
//           <div><Link to='/Productionentry'><button id='dashunfilled-divbtn'>Form</button></Link></div>
//         </div>
//       ))}

//       {missingMonitoringDates.map(date => (
//         <div id='dashunfilled-div' key={date}>
//           <div>No Transformer monitoring data available on {date}</div>
//           <div><Link to='/Transformermonitoring'><button id='dashunfilled-divbtn'>Form</button></Link></div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default YesterdayDataUnfilledforms;





// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import './Unfilledforms.css';

// const getYesterdayDate = () => {
//   const today = new Date();
//   const yesterday = new Date(today);
//   yesterday.setDate(today.getDate() - 1);
//   const yyyy = yesterday.getFullYear();
//   const mm = String(yesterday.getMonth() + 1).padStart(2, '0');
//   const dd = String(yesterday.getDate()).padStart(2, '0');
//   return `${yyyy}-${mm}-${dd}`;
// };

// const formatDate = (date) => {
//   const yyyy = date.getFullYear();
//   const mm = String(date.getMonth() + 1).padStart(2, '0');
//   const dd = String(date.getDate()).padStart(2, '0');
//   return `${yyyy}-${mm}-${dd}`;
// };

// const YesterdayDataUnfilledforms = ({ setYesterdayCount }) => {
//   const [productionData, setProductionData] = useState([]);
//   const [monitoringData, setMonitoringData] = useState([]);
//   const [missingProductionDates, setMissingProductionDates] = useState([]);
//   const [missingMonitoringDates, setMissingMonitoringDates] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const yesterday = getYesterdayDate();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const firstFilledResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/first-filled-date`);
//         const { firstProductionDate, firstMonitoringDate } = firstFilledResponse.data;

//         const productionResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/production-entry`);
//         const fetchedProductionData = productionResponse.data;

//         const monitoringResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/transformer-monitoring-logs`);
//         const fetchedMonitoringData = monitoringResponse.data;

//         const calculateMissingDates = (startDate, fetchedData) => {
//           const missingDates = [];
//           // const start = new Date(startDate);
//           // const end = new Date(yesterday);

//           const start = new Date(startDate.split('-').reverse().join('-')); // Convert to Date object
//           const end = new Date(yesterday.split('-').reverse().join('-')); // Convert to Date object

//           for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
//             const formattedDate = formatDate(d);
//             const entryExists = fetchedData.some(item => item.date === formattedDate);

//             if (!entryExists) {
//               missingDates.push(formattedDate);
//             }
//           }
//           return missingDates;
//         };

//         // const missingProduction = calculateMissingDates(firstProductionDate, fetchedProductionData);
//         // const missingMonitoring = calculateMissingDates(firstMonitoringDate, fetchedMonitoringData);

//         let missingProduction = calculateMissingDates(firstProductionDate, fetchedProductionData);
//         let missingMonitoring = calculateMissingDates(firstMonitoringDate, fetchedMonitoringData);

//         // Sort missing dates in descending order
//         missingProduction = missingProduction.sort((a, b) => new Date(b) - new Date(a));
//         missingMonitoring = missingMonitoring.sort((a, b) => new Date(b) - new Date(a));

//         setMissingProductionDates(missingProduction);
//         setMissingMonitoringDates(missingMonitoring);

//         setProductionData(fetchedProductionData.filter(item => item.date === yesterday));
//         setMonitoringData(fetchedMonitoringData.filter(item => item.date === yesterday));

//         setYesterdayCount(missingProduction.length + missingMonitoring.length);

//       } catch (err) {
//         setError('Error fetching data');
//         console.error('Error fetching data:', err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [yesterday, setYesterdayCount]);

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>{error}</div>;

//   return (
//     <div>
//       {missingProductionDates.map(date => (
//         <div id='dashunfilled-div' key={date}>
//           <div>No Production entry data available on {date}</div>
//           <div>
//             <Link to={`/Forms?section=Productionentry-1&date=${date}`}>
//               <button id='dashunfilled-divbtn'>Form</button>
//             </Link>
//             {/* <Link to={`/Productionentry?date=${date}`}><button id='dashunfilled-divbtn'>Form</button></Link> */}
//           </div>
//         </div>
//       ))}

//       {missingMonitoringDates.map(date => (
//         <div id='dashunfilled-div' key={date}>
//           <div>No Transformer monitoring data available on {date}</div>
//           <div>
//           <Link to={`/Forms?section=Transformermonitoring-1&date=${date}`}>
//               <button id='dashunfilled-divbtn'>Form</button>
//             </Link>
//             {/* <Link to={`/Transformermonitoring?date=${date}`}><button id='dashunfilled-divbtn'>Form</button></Link> */}
//             </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default YesterdayDataUnfilledforms;



import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Unfilledforms.css';

const getYesterdayDate = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const dd = String(yesterday.getDate()).padStart(2, '0');
  const mm = String(yesterday.getMonth() + 1).padStart(2, '0');
  const yyyy = yesterday.getFullYear();
  return `${dd}-${mm}-${yyyy}`;
};

const formatDate = (date) => {
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const yyyy = date.getFullYear();
  return `${dd}-${mm}-${yyyy}`;
};

const YesterdayDataUnfilledforms = ({ setYesterdayCount }) => {
  const [productionData, setProductionData] = useState([]);
  const [monitoringData, setMonitoringData] = useState([]);
  const [missingProductionDates, setMissingProductionDates] = useState([]);
  const [missingMonitoringDates, setMissingMonitoringDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const yesterday = getYesterdayDate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const firstFilledResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/first-filled-date`);
        const { firstProductionDate, firstMonitoringDate } = firstFilledResponse.data;

        const productionResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/production-entry`);
        const fetchedProductionData = productionResponse.data;

        const monitoringResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/transformer-monitoring-logs`);
        const fetchedMonitoringData = monitoringResponse.data;

        const calculateMissingDates = (startDate, fetchedData) => {
          const missingDates = [];
          const start = new Date(startDate.split('-').reverse().join('-')); // Convert to Date object
          const end = new Date(yesterday.split('-').reverse().join('-')); // Convert to Date object

          for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
            const formattedDate = formatDate(d); // Format the Date object back to DD-MM-YYYY
            const entryExists = fetchedData.some(item => item.date === formattedDate);

            if (!entryExists) {
              missingDates.push(formattedDate);
            }
          }
          return missingDates;
        };

        let missingProduction = calculateMissingDates(firstProductionDate, fetchedProductionData);
        let missingMonitoring = calculateMissingDates(firstMonitoringDate, fetchedMonitoringData);

        // Sort missing dates in descending order
        missingProduction = missingProduction.sort((a, b) => new Date(b.split('-').reverse().join('-')) - new Date(a.split('-').reverse().join('-')));
        missingMonitoring = missingMonitoring.sort((a, b) => new Date(b.split('-').reverse().join('-')) - new Date(a.split('-').reverse().join('-')));

        setMissingProductionDates(missingProduction);
        setMissingMonitoringDates(missingMonitoring);

        setProductionData(fetchedProductionData.filter(item => item.date === yesterday));
        setMonitoringData(fetchedMonitoringData.filter(item => item.date === yesterday));

        setYesterdayCount(missingProduction.length + missingMonitoring.length);

      } catch (err) {
        setError('Error fetching data');
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [yesterday, setYesterdayCount]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      {missingProductionDates.map(date => (
        <div id='dashunfilled-div' key={date}>
          <div>No Production entry data available on {date}. Please fill the form</div>
          <div>
            <Link to={`/Forms?section=Productionentry-1&date=${date}`}>
              <button id='dashunfilled-divbtn'>Form</button>
            </Link>
          </div>
        </div>
      ))}

      {missingMonitoringDates.map(date => (
        <div id='dashunfilled-div' key={date}>
          <div>No Transformer monitoring data available on {date}. Please fill the form</div>
          <div>
            <Link to={`/Forms?section=Transformermonitoring-1&date=${date}`}>
              <button id='dashunfilled-divbtn'>Form</button>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default YesterdayDataUnfilledforms;

