import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import './VFDfetch.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const VFDfetch = ({ onFormButtonClick }) => {
    const [vfds, setVfds] = useState([]);
    const [vfdEditData, setVfdEditData] = useState(null);
    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-lvtransformersectionames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    const fetchVfds = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/vfds`);
            setVfds(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchVfds();
    }, []);

    const handleEditClick = (vfd) => {
        setVfdEditData(vfd);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setVfdEditData({ ...vfdEditData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        console.log('Updating data:', vfdEditData);
        toast.success("VFD updated successfully");
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/vfds/${vfdEditData._id}`, vfdEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchVfds();
            setVfdEditData(null);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='vfdfetch-total'>
            <div id='vfdfetch-right'>
                <div id='vfdfetch-right-2'>
                    <div id='vfdfetch-right-21'>
                        <label>
                            <span> {Masterformsheadingsss.VFDHeading}</span>

                        </label>
                        <label>
                            <button onClick={onFormButtonClick}>{Masterformsheadingsss.Formheading}</button>
                        </label>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Parent Name</th>
                                <th> Name</th>
                                <th>Meter Make</th>
                                <th>Meter SL No</th>
                                <th>Phase</th>
                                <th>Capacity in kW</th>
                                <th>Voltage Level</th>
                                <th>Input Voltage</th>
                                <th>Output Voltage</th>
                                <th>Output Frequency</th>
                                <th>Frequency Control Accuracy</th>
                                <th>Acceleration/Deceleration Rates</th>
                                <th>Energy Efficiency</th>
                                <th>Control Type</th>
                                <th>Overcurrent Protection</th>
                                <th>Overvoltage Protection</th>
                                <th>Regenerative Capability</th>
                                <th>Harmonic Mitigation</th>
                                <th>Compatibility</th>
                                <th>Proposed Periodicity of Maintenance</th>
                                <th>Previous Maintenance Done Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {vfds.map((vfd) => (
                                <tr key={vfd._id}>
                                    <td>{vfd.date}</td>
                                    <td>{vfd.parentname}</td>
                                    <td>{vfd.name}</td>
                                    <td>{vfd.metermake}</td>
                                    <td>{vfd.meterslno}</td>
                                    <td>{vfd.phase}</td>
                                    <td>{vfd.capacityinvw}</td>
                                    <td>{vfd.voltagelevel}</td>
                                    <td>{vfd.inputvoltage}</td>
                                    <td>{vfd.outputvoltage}</td>
                                    <td>{vfd.outputfrequency}</td>
                                    <td>{vfd.frequencycontrolaccuracy}</td>
                                    <td>{vfd.accelerationanddeaccelarationrates}</td>
                                    <td>{vfd.energyefficiency}</td>
                                    <td>{vfd.controltype}</td>
                                    <td>{vfd.overcurrentprotectionavailable}</td>
                                    <td>{vfd.overvoltageprotectionavailable}</td>
                                    <td>{vfd.regenartivecapability}</td>
                                    <td>{vfd.harmonicmigartion}</td>
                                    <td>{vfd.compatability}</td>
                                    <td>{vfd.proposedperiodicityofmaintenance}</td>
                                    <td>{vfd.previousmaintenancedonedate}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(vfd)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {vfdEditData && (
                        <Popup open={true} onClose={() => setVfdEditData(null)}>
                            <div id='vfdfetch-popup'>
                                <h3>Edit VFD</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='vfdfetch-popup1'>
                                        <label>
                                            <span>Date</span>
                                            <input type="date" name="date" value={vfdEditData.date} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Parent Name</span>
                                            <select name="parentname" value={vfdEditData.parentname} onChange={handleEditChange}>
                                                <option value="">Select Parent Name</option>
                                                {parentNames.map((name) => (
                                                    <option key={name} value={name}>{name}</option>
                                                ))}
                                            </select>
                                        </label>
                                    </div>
                                    <div id='vfdfetch-popup1'>
                                        <label>
                                            <span>Name</span>
                                            <input type="text" name="name" value={vfdEditData.name} onChange={handleEditChange} />
                                        </label>

                                        <label>
                                            <span>Meter Make</span>
                                            <input type="text" name="metermake" value={vfdEditData.metermake} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='vfdfetch-popup1'>
                                        <label>
                                            <span>Meter SL No</span>
                                            <input type="text" name="meterslno" value={vfdEditData.meterslno} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Phase</span>
                                            <select name="phase" value={vfdEditData.phase} onChange={handleEditChange} required>
                                                <option value=''>Select Phase</option>
                                                <option value='3-Phase'>3-Phase</option>
                                                <option value='1-Phase-Vr'>1-Phase-Vr</option>
                                                <option value='1-Phase-Vy'>1-Phase-Vy</option>
                                                <option value='1-Phase-Vb'>1-Phase-Vb</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div id='vfdfetch-popup1'>
                                        <label>
                                            <span>Capacity in kW</span>
                                            <input type="number" name="capacityinvw" value={vfdEditData.capacityinvw} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Voltage Level</span>
                                            <input type="number" name="voltagelevel" value={vfdEditData.voltagelevel} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='vfdfetch-popup1'>
                                        <label>
                                            <span>Input Voltage</span>
                                            <input type="number" name="inputvoltage" value={vfdEditData.inputvoltage} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Output Voltage</span>
                                            <input type="number" name="outputvoltage" value={vfdEditData.outputvoltage} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='vfdfetch-popup1'>
                                        <label>
                                            <span>Output Frequency</span>
                                            <input type="number" name="outputfrequency" value={vfdEditData.outputfrequency} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Frequency Control Accuracy</span>
                                            <input type="text" name="frequencycontrolaccuracy" value={vfdEditData.frequencycontrolaccuracy} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='vfdfetch-popup1'>
                                        <label>
                                            <span>Acceleration/Deceleration Rates</span>
                                            <input type="number" name="accelerationanddeaccelarationrates" value={vfdEditData.accelerationanddeaccelarationrates} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Energy Efficiency</span>
                                            <input type="text" name="energyefficiency" value={vfdEditData.energyefficiency} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='vfdfetch-popup1'>
                                        <label>
                                            <span>Control Type</span>
                                            <input type="text" name="controltype" value={vfdEditData.controltype} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Overcurrent Protection</span>
                                            <select name="overcurrentprotectionavailable" value={vfdEditData.overcurrentprotectionavailable} onChange={handleEditChange} required>
                                                <option value=''>Select Overcurrent Protection</option>
                                                <option value='Y'>Yes</option>
                                                <option value='N'>No</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div id='vfdfetch-popup1'>
                                        <label>
                                            <span>Overvoltage Protection</span>
                                            <select name="overvoltageprotectionavailable" value={vfdEditData.overvoltageprotectionavailable} onChange={handleEditChange} required>
                                                <option value=''>Select Overvoltage Protection</option>
                                                <option value='Y'>Yes</option>
                                                <option value='N'>No</option>
                                            </select>
                                        </label>
                                        <label>
                                            <span>Regenerative Capability</span>
                                            <select name="regenartivecapability" value={vfdEditData.regenartivecapability} onChange={handleEditChange} required>
                                                <option value=''>Select Regenerative Capability</option>
                                                <option value='Y'>Yes</option>
                                                <option value='N'>No</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div id='vfdfetch-popup1'>
                                        <label>
                                            <span>Harmonic Mitigation</span>
                                            <input name="harmonicmigartion" value={vfdEditData.harmonicmigartion} onChange={handleEditChange} required ></input>

                                        </label>
                                        <label>
                                            <span>Compatibility</span>
                                            <input type="text" name="compatability" value={vfdEditData.compatability} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='vfdfetch-popup1'>
                                        <label>
                                            <span>Proposed Periodicity of Maintenance</span>
                                            <input type="text" name="proposedperiodicityofmaintenance" value={vfdEditData.proposedperiodicityofmaintenance} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Previous Maintenance Done Date</span>
                                            <input type="date" name="previousmaintenancedonedate" value={vfdEditData.previousmaintenancedonedate} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <button id='vfdfetch-popup-update' type="submit">{Masterformsheadingsss.Updateheading}</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default VFDfetch;
