import React, { useState, useEffect } from 'react';
import axios from 'axios';
import calculateUnfilledFormsCount from './calculateUnfilledFormsCount';
const TotalTaskcounts = () => {
  const [diagnosisCount, setDiagnosisCount] = useState(0);
  const [actionsCount, setActionsCount] = useState(0);
  const [reviewsCount, setReviewsCount] = useState(0);
  const [closedCount, setClosedCount] = useState(0);
  const [unfilledFormsCount, setUnfilledFormsCount] = useState(0);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
          const diagnosisResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/assignedtasks/do-it-later`);
          setDiagnosisCount(diagnosisResponse.data.length);

          const actionsResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/assignedtasks/todo`);
          setActionsCount(actionsResponse.data.length);

          const reviewResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/assignedtasks/reassigned-todo`);
          setReviewsCount(reviewResponse.data.length);

          const closedResponse = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/assignedtasks/closed`);
          setClosedCount(closedResponse.data.length);

          const unfilledFormsCount = await calculateUnfilledFormsCount();
          setUnfilledFormsCount(unfilledFormsCount);

      } catch (error) {
          console.error('Error fetching counts:', error);
      }
  };

  fetchCounts();
}, []);

  return (
    <div>
       <span> Unfilled Forms ({unfilledFormsCount})</span>
      <span>Pending Diagnosis ({diagnosisCount})</span>
      <span>Pending Actions ({actionsCount})</span>
      <span>Pending Reviews ({reviewsCount})</span>
      <span>Closed Tasks ({closedCount})</span>

    </div>
  )
}

export default TotalTaskcounts

