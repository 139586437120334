
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Topbar.css';
import { Topbarheadings } from '../../Operator/Data';
import { TbBulbFilled } from "react-icons/tb";

const TopBar = ({ section }) => {

    const [viewLink, setViewLink] = useState('');

    // Determine the link based on access level
    useEffect(() => {
        const accessLevel = localStorage.getItem('accessedLevel');
        
        if (accessLevel === 'Level-3') {
            setViewLink('https://patternlab.portal.trainn.co/share/VVDp1nKfl5G8O2IdrnIL7g');
        } else if (accessLevel === 'Level-4') {
            setViewLink('https://patternlab.portal.trainn.co/share/OVGypoFXLaq929QZZ2599g');
        } 
        else if (accessLevel === 'Level-2') {
            setViewLink('https://patternlab.portal.trainn.co/share/1l95MhdZXRIq6v6NDnt0IA');
        } 
    }, []);

    // else {
    //     localStorage.removeItem('accessedLevel');
    //     localStorage.removeItem('user');
    //     navigate('/');
    // }

    let heading = '';
    switch (section) {
        case 'Operatordashheading':
            heading = Topbarheadings.Operatordashheading;
            break;
        case 'OperatorTaskheading':
            heading = Topbarheadings.OperatorTaskheading;
            break;
        case 'Liveplantsummaryheading':
            heading = Topbarheadings.Liveplantsummaryheading;
            break;
        case 'TODTrackingheading':
            heading = Topbarheadings.TODTrackingheading;
            break;
        case 'Alertspage':
            heading = Topbarheadings.Alertsheading;
            break;
        case 'ConsumptionHeader':
            heading = Topbarheadings.ConsumptionHeader;
            break;
        case 'SectionsHeader':
            heading = Topbarheadings.SectionsHeader;
            break;
        case 'HistorydataHeader':
            heading = Topbarheadings.HistorydataHeader;
            break;
        case 'Forms':
            heading = Topbarheadings.FormsHeader;
            break;
        case 'PendingTasksHeader':
            heading = Topbarheadings.PendingTasksHeader;
            break;

        // --------------
        case 'Supervisordashheading':
            heading = Topbarheadings.Supervisordashheading;
            break;
        case 'Criticalalertsheading':
            heading = Topbarheadings.Criticalalertsheading;
            break;
        case 'Calenderheading':
            heading = Topbarheadings.Calenderheading;
            break;
        case 'ReportsHeader':
            heading = Topbarheadings.ReportsHeader;
            break;
        case 'SupervisorTaskheading':
            heading = Topbarheadings.SupervisorTaskheading;
            break;
        case 'Generatorsheading':
            heading = Topbarheadings.Generatorsheading;
            break;
        case 'Masterformsheading':
            heading = Topbarheadings.Masterformsheading;
            break;
            case 'Trendsheading':
                heading = Topbarheadings.Trendsheading;
                break;

        // ------Revenue loss screens--------
        case 'LowpfHeading':
            heading = Topbarheadings.LowpfHeading;
            break;
        case 'LeadpfHeading':
            heading = Topbarheadings.LeadpfHeading;
            break;
        case 'HarmonicdistortionHeading':
            heading = Topbarheadings.HarmonicdistortionHeading;
            break;
        case 'PowersurgeHeading':
            heading = Topbarheadings.PowersurgeHeading;
            break;
        case 'UnbalancedloadHeading':
            heading = Topbarheadings.UnbalancedloadHeading;
            break;
        case 'VoltageHeading':
            heading = Topbarheadings.VoltageHeading;
            break;
        case 'UtilizationrevenuelossHeading':
            heading = Topbarheadings.UtilizationrevenuelossHeading;
            break;
        case 'IdlerevenuelossHeading':
            heading = Topbarheadings.IdlerevenuelossHeading;
            break;
        case 'TodrevenuelossHeading':
            heading = Topbarheadings.TodrevenuelossHeading;
            break;
        case 'TransformerrevenuelossHeading':
            heading = Topbarheadings.TransformerrevenuelossHeading;
            break;
            case 'DesignationHeader':
                heading = Topbarheadings.DesignationHeading;
                break;

                case 'AddsuperadminHeading':
                    heading = Topbarheadings.AddsuperadminHeading;
                    break;
                    case 'AdduserHeading':
                        heading = Topbarheadings.AdduserHeading;
                        break;
        // default:
        // heading = Topbarheadings.Alertsheading;
        // null;
    }

    return (
        <div id='topbar'>
            <div id='topbar-heading'>
                {heading}
            </div>
            <div id='topbar-actions'>

                <div id='link-div'>
            
                {viewLink && ( 
                    <button >
                        <a href={viewLink} target="_blank" rel="noopener noreferrer"> <TbBulbFilled />
                        Training</a>
                    </button>
                )}

                </div>
                {Topbarheadings.alertActions.map((action, index) => (
                    <button key={index} aria-label={action.label}>
                        {action.icon}
                    </button>
                ))}
                <img src={Topbarheadings.logoSrc} alt='logo' />
            </div>
        </div>
    );
};

export default TopBar;
