import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Task_Historyheadingsss } from '../Operator/Data';
import Operatorsidebar from '../Operator/Sidebar/Sidebar';
import './PendingTasks.css'
import TopBar from '../Commonfns/Topbar/Topbar';
const PendingTasks = () => {
    const [tasks, setTasks] = useState([]);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user.username);
        }
    }, []);

    useEffect(() => {
        const fetchTasks = async () => {
            if (!loggedInUser) return;

            try {
                const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/assginedtasks?assignedto=${loggedInUser}`);
                if (response.ok) {
                    const data = await response.json();
                    setTasks(data);
                } else {
                    console.error('Failed to fetch tasks');
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTasks();
    }, [loggedInUser]);

    const markAsCompleted = async (task) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/assginedtasks/${task._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...task,
                    status: 'COMPLETED',
                    modifiedby: loggedInUser,
                }),
            });

            if (response.ok) {
                const updatedTask = await response.json();
                setTasks((prevTasks) =>
                    // prevTasks.map((t) => (t._id === updatedTask._id ? updatedTask : t))
                    prevTasks.map((t) => (t._id === updatedTask._id ? { ...updatedTask, disabled: true } : t))
                );
                alert('Task Status Updated Successfully');
            } else {
                console.error('Failed to update task');
            }
        } catch (error) {
            console.error('Error updating task:', error);
        }
    };



    if (loading) {
        return <p>Loading...</p>;
    }

    const handleDiagnoseClick = (token_id) => {
        console.log("Navigating to diagnose page for token ID:", token_id);
        navigate(`/Diagnose/${token_id}`);
    };

    const getStatusClass = (status) => {
        switch (status) {
            case 'TO-DO':
                return 'status-todo';
            case 'COMPLETED':
                return 'status-completed';
            case 'CLOSED':
                return 'status-closed';
            case 'REASSIGNED':
                return 'status-reassigned';
            case 'DO IT LATER':
                return 'status-do-it-later';
            default:
                return '';
        }
    };

    return (
        <div id='pendingtasks-total'>
            <div>
                <Operatorsidebar />
            </div>
            <div id='pendingtasks-right'>
                <TopBar section="OperatorTaskheading" />
                {/* <div id='pendingtasks-right-1'>
                    <div id='pendingtaskss-heading'>
                        {Task_Historyheadingsss.TaskHeading}
                    </div>
                    <div id='pendingtaskss-heading1'>
                        {Task_Historyheadingsss.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Task_Historyheadingsss.logoSrc} alt='logo' />
                    </div>
                </div> */}

                <div id='pendingtasks-right-2'>
                    {/* <div id='pendingtasks-right-21'>
                        <label>
                            <button>123</button>
                        </label>
                        <label>
                            <button>123</button>
                        </label>
                    </div> */}

                    <div id='pendingtasks-right-3'>
                        <table>
                            <thead>
                                <tr>
                                    {/* <th>Task ID</th> */}
                                    <th>Token ID</th>
                                    <th>Assigned to user</th>
                                    {/* <th>Assigned to Userid</th> */}
                                    {/* <th>assignedtouserlevel</th> */}
                                    <th>Assigned Designation</th>
                                    {/* <th>assigneeuserlevel</th> */}
                                    <th>Assignee Designation</th>
                                    <th>Status</th>
                                    {/* <th>Created At</th> */}
                                    {/* <th>Modified At</th> */}
                                    <th>Assigned At</th>
                                    <th>Completed At</th>
                                    <th>Reassigned At</th>
                                    <th>Closed At</th>
                                    <th>View</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tasks.length > 0 ? (
                                    tasks.map((task) => (
                                        <tr key={task._id}>
                                            {/* <td>{task.task_id}</td> */}
                                            <td>{task.token_id}</td>
                                            <td>{task.assignedto}</td>
                                            {/* <td>{task.assignedtouserid}</td> */}
                                            {/* <td>{task.assignedtouserlevel}</td> */}
                                            <td>{task.assignedtodesignation}</td>
                                            {/* <td>{task.assigneeuserlevel}</td> */}
                                            <td>{task.assigneedesignation}</td>
                                            <td id='pendingtasks-status-cont' > <button className={getStatusClass(task.status)}>{task.status}</button></td>
                                            {/* <td id='pendingtasks-status-cont'>{task.status}</td> */}
                                            {/* <td>{task.createdat}</td> */}
                                            {/* <td>{task.modifiedat}</td> */}
                                            <td>{task.assinedat}</td>
                                            <td>{task.completedat}</td>
                                            <td>{task.reassignedat}</td>
                                            <td>{task.closedat}</td>
                                            <td><button id='pendingtasks-actionbuttons' onClick={() => handleDiagnoseClick(task.token_id)}>Diagnose</button></td>
                                            <td>
                                                {/* <button onClick={() => markAsCompleted(task)}>
                                        {task.status === 'TO-DO' ? 'Mark as Completed' : task.status}
                                    </button> */}
                                                <button id='pendingtasks-actionbuttons'
                                                    onClick={() => markAsCompleted(task)}
                                                    disabled={task.disabled || task.status !== 'TO-DO'}
                                                >
                                                    {task.status === 'TO-DO' ? 'Mark as Completed' : task.status}
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="16" style={{ textAlign: 'center' }}>No Pending Actions available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PendingTasks