

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaFileCsv } from "react-icons/fa6";
import './GeneratorsKPI.css';
import Stationpoints from '../Commonfns/Stationpoints';
import { GeneratorKPIHeader } from '../Operator/Data';
import Supervisorsidebar from '../Supervisor/Supersidebar/Supersidebar';
import TopBar from '../Commonfns/Topbar/Topbar';
const GeneratorsKPI = () => {
    const [generatorsKPI, setGeneratorsKPI] = useState([]);
    const [filteredKPI, setFilteredKPI] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [section, setSection] = useState('');
    useEffect(() => {
        const fetchGeneratorsData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/generatorskpi`);
                setGeneratorsKPI(response.data);
                setFilteredKPI(response.data); // Initially show all data
            } catch (error) {
                console.error('Error fetching generatorsKPI:', error);
            }
        };

        fetchGeneratorsData();
    }, []);

    useEffect(() => {
        filterData();
    }, [fromDate, toDate, section, generatorsKPI]);

    const filterData = () => {
        let filteredData = generatorsKPI;

        if (fromDate && toDate) {
            const from = new Date(fromDate);
            const to = new Date(toDate);
            filteredData = generatorsKPI.filter(item => {
                const itemDate = new Date(item.Date);
                return itemDate >= from && itemDate <= to;
            });
        }

        if (section) {
            filteredData = filteredData.filter(item => item.Section === section);
        }

        setFilteredKPI(filteredData);
    };

    const handleStationPointChange = (selectedStationPoint) => {
        setSection(selectedStationPoint);
    };

    const downloadCSV = () => {
        const headers = ["Date", "Section", "KWh Consumption", "Uptime Duration"];
        const rows = filteredKPI.map(item => [
            new Date(item.Date).toLocaleDateString(),
            item.Section,
            item.KWh_Consumption,
            item.Uptime_Duration
        ]);

        let csvContent = "data:text/csv;charset=utf-8,"
            + headers.join(",") + "\n"
            + rows.map(e => e.join(",")).join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Generators.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleFromDateChange = (e) => {
        const selectedFromDate = e.target.value;
        setFromDate(selectedFromDate);
    
        if (toDate && toDate < selectedFromDate) {
          setToDate("");
        }
      };
    
      const handleToDateChange = (e) => {
        setToDate(e.target.value);
      };
    
      const getTodayDate = () => {
        const today = new Date();
        return today.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
      };

    return (
        <div id='generatorskpi-total'>
            <div>
                <Supervisorsidebar />
            </div>
            <div id='generatorskpi-right'>
                     <TopBar section="Generatorsheading" />
                <div id='generatorskpi-right-2'> 
                <div id='generatorskpi-right-21'>
                    <label>
                        <span>From Date:</span>
                        <input type="date" value={fromDate} onChange={handleFromDateChange} max={getTodayDate()} />
                    </label>
                    <label>
                        <span>To Date:</span>
                        <input type="date" value={toDate} onChange={handleToDateChange} max={getTodayDate()}  min={fromDate || ""}  disabled={!fromDate} />
                    </label>
                    <label >
                        <Stationpoints onStationPointChange={handleStationPointChange} />
                    </label>
                    <label>
                        <button onClick={downloadCSV}><FaFileCsv /></button>

                    </label>
                </div>

                <table>
                    <thead>
                        <tr>
                            <th>S. No.</th>
                            <th>Date</th>
                            <th>Section</th>
                            <th>KWh Consumption</th>
                            <th>Uptime Duration</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredKPI.length > 0 ? (
                            filteredKPI.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{new Date(item.Date).toLocaleDateString()}</td>
                                    <td>{item.Section}</td>
                                    <td>{item.KWh_Consumption}</td>
                                    <td>{item.Uptime_Duration}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" style={{ textAlign: 'center' }}>No data available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                </div>
               

                <div id='generatorskpi-right-3'>

                </div>
            </div>


        </div>
    );
}

export default GeneratorsKPI;

