
import React, { useState } from 'react';
import './Formheadercomponent.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import Incomers from '../../MasterForms/Incomers/Incomers';
import Incomersfetch from '../../MasterForms/Incomers/Incomersfetch';
import Supervisorsidebar from '../../Supervisor/Supersidebar/Supersidebar';
import HVform from '../../MasterForms/HVform/HVform';
import HvformFetch from '../../MasterForms/HVform/HVformfetch';
import Mastertransformerform from '../../MasterForms/MasterTransformer/Mastertransformer';
import Mastertransformerfetch from '../../MasterForms/MasterTransformer/Mastertransformerfetch';
import LVform from '../../MasterForms/LVform/LVform';
import LVformFetch from '../../MasterForms/LVform/LVformfetch';
import Mastergenerator from '../../MasterForms/Generators/Generators';
import Mastergeneratorfetch from '../../MasterForms/Generators/Generstorsfetch';
import Sections from '../../MasterForms/Sections/Sections';
import Sectionsfetch from '../../MasterForms/Sections/Sectionsfetch';
import Mastermachine from '../../MasterForms/Machine/Machine';
import Mastermachinefetch from '../../MasterForms/Machine/Machinefetch';
import UPS from '../../MasterForms/UPS/UPS';
import UPSfetch from '../../MasterForms/UPS/UPSfetch';
import Stebilizer from '../../MasterForms/Stabilizers/Stebilizers';
import Stebilizersfetch from '../../MasterForms/Stabilizers/Stebilizersfetch';
import PFcorrectors from '../../MasterForms/PFcorrectors/PFcorrectors';
import PFcorrectorsfetch from '../../MasterForms/PFcorrectors/PFcorrectorsfetch';
import Harmonicfilter from '../../MasterForms/Harmonic_filters/Harmonic_filters';
import Harmonicfiltersfetch from '../../MasterForms/Harmonic_filters/Harmonic_filtersfetch';
import VFD from '../../MasterForms/VFD/VFD';
import VFDfetch from '../../MasterForms/VFD/VFDfetch';
import LightingArrestor from '../../MasterForms/LighteningArrestors/LighteningArrestors';
import LightingArrestorfetch from '../../MasterForms/LighteningArrestors/LighteningArrestorsfetch';
import Groundingpoints from '../../MasterForms/Groundingpoints/Groundingpoints';
import GroundingpointsFetch from '../../MasterForms/Groundingpoints/Groundingpointsfetch';
import Alertsduration from '../../MasterForms/Alertsduration/Alertsduration';
import Alertsdurationfetch from '../../MasterForms/Alertsduration/Alertsdurationfetch';
import Tariff from '../../MasterForms/Tariff/Tariff';
import TopBar from '../../Commonfns/Topbar/Topbar';
import Fixedvariables from '../../MasterForms/Fixedvariables/Fixedvariables';
const Formheadercomponent = () => {
    const [selectedComponent, setSelectedComponent] = useState('/Incomers');

    const options = [
        { value: '/Incomers', label: Masterformsheadingsss.incomersHeading },
        { value: '/HVform', label: Masterformsheadingsss.HVHeading },
        { value: '/Mastertransformerform', label: Masterformsheadingsss.TransformerHeading },
        { value: '/LVform', label: Masterformsheadingsss.LVHeading },
        { value: '/Mastergenerator', label: Masterformsheadingsss.GeneratorHeading },
        { value: '/Sections', label: Masterformsheadingsss.SectionsHeading },
        { value: '/Mastermachine', label: Masterformsheadingsss.MachineHeading },
        { value: '/UPS', label: Masterformsheadingsss.UPSHeading },
        { value: '/Stebilizer', label: Masterformsheadingsss.StebilizerHeading },
        { value: '/PFcorrectors', label: Masterformsheadingsss.PFCorrectorHeading },
        { value: '/Harmonicfilter', label:Masterformsheadingsss.HarmonicFilterHeading },
        { value: '/VFD', label: Masterformsheadingsss.VFDHeading},
        { value: '/LightingArrestor', label: Masterformsheadingsss.LightingArrestorHeading },
        { value: '/Groundingpoints', label: Masterformsheadingsss.GroundingpointHeading },
        { value: '/Alertsduration', label: Masterformsheadingsss.AlertsdurationHeading },
        { value: '/Tariff', label: Masterformsheadingsss.TariffHeading },
        { value: '/Fixedvariables', label: Masterformsheadingsss.FixedvariablesHeading },
        // Add more options here as needed
    ];

    const handleChange = (event) => {
        setSelectedComponent(event.target.value);
    };

    const renderComponent = () => {
        switch (selectedComponent) {
            case '/Incomers':
                return <Incomers onViewData={() => setSelectedComponent('/Incomersfetch')} />;
            case '/Incomersfetch':
                return <Incomersfetch onFormButtonClick={() => setSelectedComponent('/Incomers')} />;
            case '/HVform':
                return <HVform onViewData={() => setSelectedComponent('/HvformFetch')} />;
            case '/HvformFetch':
                return <HvformFetch onFormButtonClick={() => setSelectedComponent('/HVform')} />;
            case '/Mastertransformerform':
                return <Mastertransformerform onViewData={() => setSelectedComponent('/Mastertransformerfetch')} />;
            case '/Mastertransformerfetch':
                return <Mastertransformerfetch onFormButtonClick={() => setSelectedComponent('/Mastertransformerform')} />;
            case '/LVform':
                return <LVform onViewData={() => setSelectedComponent('/LVformFetch')} />;
            case '/LVformFetch':
                return <LVformFetch onFormButtonClick={() => setSelectedComponent('/LVform')} />;
            case '/Mastergenerator':
                return <Mastergenerator onViewData={() => setSelectedComponent('/Mastergeneratorfetch')} />;
            case '/Mastergeneratorfetch':
                return <Mastergeneratorfetch onFormButtonClick={() => setSelectedComponent('/Mastergenerator')} />;
            case '/Sections':
                return <Sections onViewData={() => setSelectedComponent('/Sectionsfetch')} />;
            case '/Sectionsfetch':
                return <Sectionsfetch onFormButtonClick={() => setSelectedComponent('/Sections')} />;
            case '/Mastermachine':
                return <Mastermachine onViewData={() => setSelectedComponent('/Mastermachinefetch')} />;
            case '/Mastermachinefetch':
                return <Mastermachinefetch onFormButtonClick={() => setSelectedComponent('/Mastermachine')} />;
            case '/UPS':
                return <UPS onViewData={() => setSelectedComponent('/UPSfetch')} />;
            case '/UPSfetch':
                return <UPSfetch onFormButtonClick={() => setSelectedComponent('/UPS')} />;
            case '/Stebilizer':
                return <Stebilizer onViewData={() => setSelectedComponent('/Stebilizersfetch')} />;
            case '/Stebilizersfetch':
                return <Stebilizersfetch onFormButtonClick={() => setSelectedComponent('/Stebilizer')} />;
            case '/PFcorrectors':
                return <PFcorrectors onViewData={() => setSelectedComponent('/PFcorrectorsfetch')} />;
            case '/PFcorrectorsfetch':
                return <PFcorrectorsfetch onFormButtonClick={() => setSelectedComponent('/PFcorrectors')} />;
            case '/Harmonicfilter':
                return <Harmonicfilter onViewData={() => setSelectedComponent('/Harmonicfiltersfetch')} />;
            case '/Harmonicfiltersfetch':
                return <Harmonicfiltersfetch onFormButtonClick={() => setSelectedComponent('/Harmonicfilter')} />;
            case '/VFD':
                return <VFD onViewData={() => setSelectedComponent('/VFDfetch')} />;
            case '/VFDfetch':
                return <VFDfetch onFormButtonClick={() => setSelectedComponent('/VFD')} />;
            case '/LightingArrestor':
                return <LightingArrestor onViewData={() => setSelectedComponent('/LightingArrestorfetch')} />;
            case '/LightingArrestorfetch':
                return <LightingArrestorfetch onFormButtonClick={() => setSelectedComponent('/LightingArrestor')} />;

            case '/Groundingpoints':
                return <Groundingpoints onViewData={() => setSelectedComponent('/GroundingpointsFetch')} />;
            case '/GroundingpointsFetch':
                return <GroundingpointsFetch onFormButtonClick={() => setSelectedComponent('/Groundingpoints')} />;

            case '/Alertsduration':
                return <Alertsduration onViewData={() => setSelectedComponent('/Alertsdurationfetch')} />;
            case '/Alertsdurationfetch':
                return <Alertsdurationfetch onFormButtonClick={() => setSelectedComponent('/Alertsduration')} />;
            case '/Tariff':
                return <Tariff />;
                case '/Fixedvariables':
                    return <Fixedvariables />;
            // Add more cases here for different components
            default:
                return null;
        }
    };

     // value={selectedComponent}
//     // <component/> retrun
    return (
        <div id='fromheader-total'>
            <Supervisorsidebar />
            <div>
                <div id='fromheader-right'>
                <TopBar section="Masterformsheading" />
                </div>
                <div id='fromheader-belowcontent'>
                    <span>Select Form :</span>
                    <select onChange={handleChange} value={selectedComponent}>
                        {/* <option value="" disabled>Select Option</option> */}
                        {options.map(option => (
                            <option
                                key={option.value}
                                value={option.value}
                            >
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div>
                {renderComponent()}
            </div>
        </div>
    );
};

export default Formheadercomponent;



