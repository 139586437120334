import React, { useEffect, useState } from 'react';
import Supervisorsidebar from '../../Supervisor/Supersidebar/Supersidebar';
import './Harmonicdistortion.css';
import { RevenuelossHeader } from '../../Operator/Data';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FaFilePdf } from "react-icons/fa6";
import TopBar from '../../Commonfns/Topbar/Topbar';
import Operatorsidebar from '../../Operator/Sidebar/Sidebar';

const Harmonicdistortiondiagnose = () => {
    const [harmonicdistortionData, setHarmonicdistortionData] = useState([]);
    const [conditionHarmonicdistortionData, setConditionHarmonicdistortionData] = useState([]);
    const location = useLocation();
    const { selectedMonth } = location.state || { selectedMonth: null };
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [accessedLevel, setAccessedLevel] = useState(null);

    useEffect(() => {
        // Fetch the accessed level from localStorage
        const level = localStorage.getItem('accessedLevel');
        setAccessedLevel(level);
    }, []);

    useEffect(() => {
        const fetchHarmonicdistortionData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/revenue-thddiagnose`);
                setHarmonicdistortionData(response.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        const fetchConditionHarmonicdistortionData = async () => { // New function to fetch condition-based data
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/unclosedTHD_Loss`);
                setConditionHarmonicdistortionData(response.data);
            } catch (error) {
                setError(error);
            }
        };

        fetchHarmonicdistortionData();
        fetchConditionHarmonicdistortionData();
    }, []);

    const filterByMonth = (data) => {
        if (!selectedMonth) return data;
        const selectedYearMonth = selectedMonth.split('-').join('-'); // Convert to YYYY-MM format
        console.log('Filtering data by month:', selectedYearMonth);
        return data.filter(item => {
            const itemMonth = item.date.slice(0, 7); // Extract YYYY-MM from date field
            return itemMonth === selectedYearMonth;
        });
    };

    const filteredHarmonicdistortionData = filterByMonth(harmonicdistortionData);
    const filteredConditionHarmonicdistortionData = filterByMonth(conditionHarmonicdistortionData);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const handleDiagnoseClick = (token_id) => {
        console.log("Navigating to diagnose page for token ID:", token_id);
        navigate(`/Diagnose/${token_id}`);
    };

    const handleAcknowledge = async (id) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/revenue-thddiagnose/${id}/ack`);
            if (response.status === 200) {
                setHarmonicdistortionData(harmonicdistortionData.map(item => item._id === id ? { ...item, ack: "Acknowledged" } : item));
            }
            alert("Acknowledged Sucessfully")
        } catch (error) {
            console.error('Error acknowledging the record:', error);
        }
    };

    const handleSaveAsPDF = () => {
        const input = document.getElementById('harmonicdistortion-right'); // Updated ID to match the existing one
        html2canvas(input).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = pdfWidth;
            const imgHeight = canvas.height * pdfWidth / canvas.width;

            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            pdf.save(`harmonicdistortionrevenueloss-Diagnose.pdf`);
        }).catch(error => {
            console.error('Error generating PDF:', error);
        });
    };

    return (
        <div id='harmonicdistortion-total'>
            <div>
                {accessedLevel === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />}
            </div>
            <div id='harmonicdistortion-right'>
                <TopBar section="HarmonicdistortionHeading" />
                <h3 id='revenueloss-topbarheading'> {RevenuelossHeader.RevenuelossHeading1} </h3>

                <div id='harmonicdistortion-right-2'>
                    {filteredConditionHarmonicdistortionData.length > 0 ? (
                        filteredConditionHarmonicdistortionData.map((item, index) => (
                            <div id='harmonicdistortion-right-21' key={index}>
                                <label>
                                    <strong>Token ID:</strong>
                                    <span>{item.token_id}</span>
                                </label>
                                <label>
                                    <strong>Date:</strong>
                                    <span>{item.date}</span>
                                </label>
                                <label>
                                    <strong>Condition:</strong>
                                    <span>{item.condition}</span>
                                </label>
                                <label>
                                    <button onClick={() => handleDiagnoseClick(item.token_id)}><abbr title={RevenuelossHeader.Revenuelossheading4}> {RevenuelossHeader.Revenuelossbuttonname}</abbr></button>
                                </label>
                            </div>
                        ))
                    ) : (
                        <p>{RevenuelossHeader.Revenuelossheading3}</p>
                    )}
                    <h3> {RevenuelossHeader.RevenuelossHeading2} </h3>
                    <div id='harmonicdistortion-right-3'>
                        {filteredHarmonicdistortionData.length > 0 ? (
                            filteredHarmonicdistortionData.map((item, index) => (
                                <div id='harmonicdistortion-right-3-1' key={index}>
                                    <label>
                                        <strong>Date:</strong>
                                        <span>{item.date}</span>
                                    </label>
                                    <label>
                                        <strong>Suggestion:</strong>
                                        <span>{item.suggestion}</span>
                                    </label>
                                    <label>
                                        <button
                                            onClick={() => handleAcknowledge(item._id)}
                                            disabled={item.ack === "Acknowledged"}
                                            className={item.ack === "Acknowledged" ? 'acknowledged' : ''}
                                        >
                                            {item.ack === "Acknowledged" ? "Acknowledged" : "Acknowledge"}
                                        </button>
                                    </label>
                                </div>
                            ))
                        ) : (
                            <p>N/A</p>
                        )}
                    </div>
                    <div id='diagnosepage-pdfbtn'>
                        <button onClick={handleSaveAsPDF}><FaFilePdf /></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Harmonicdistortiondiagnose;
