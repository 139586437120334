// import React, { useEffect, useState } from 'react';

// const Tasks = () => {
//   const [tasks, setTasks] = useState([]);
//   const [loggedInUser, setLoggedInUser] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const user = JSON.parse(localStorage.getItem('user'));
//     if (user && user.username) {
//       setLoggedInUser(user.username);
//     }
//   }, []);

//   useEffect(() => {
//     const fetchTasks = async () => {
//       if (!loggedInUser) return;

//       try {
//         const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/assginedtasks?assignedto=${loggedInUser}`);
//         if (response.ok) {
//           const data = await response.json();
//           setTasks(data);
//         } else {
//           console.error('Failed to fetch tasks');
//         }
//       } catch (error) {
//         console.error('Error fetching tasks:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchTasks();
//   }, [loggedInUser]);

//   if (loading) {
//     return <p>Loading...</p>;
//   }


//   return (
//     <div>
//       <h1>Tasks assigned to {loggedInUser}</h1>
//       {tasks.length > 0 ? (
//         <ul>
//           {tasks.map(task => (
//             <li key={task.task_id}>
//               <p><strong>Task ID:</strong> {task.task_id}</p>
//               <p><strong>Token ID:</strong> {task.token_id}</p>
//               <p><strong>Assigned To:</strong> {task.assignedto}</p>
//               <p>
//                 <strong>Status:</strong> 
//                 <button value={task.status}>
//                   {task.status === 'TO-DO' ? 'Mark as Completed' : task.status}
//                 </button>
//               </p>
//               <p><strong>Created At:</strong> {task.createdat}</p>
//               <p><strong>Modified At:</strong> {task.modifiedat}</p>
//             </li>
//           ))}
//         </ul>
//       ) : (
//         <p>No tasks assigned to {loggedInUser}</p>
//       )}
//     </div>
//   );
// };

// export default Tasks;



// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';

// const Tasks = () => {
//   const [tasks, setTasks] = useState([]);
//   const [loggedInUser, setLoggedInUser] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const user = JSON.parse(localStorage.getItem('user'));
//     if (user && user.username) {
//       setLoggedInUser(user.username);
//     }
//   }, []);

//   useEffect(() => {
//     const fetchTasks = async () => {
//       if (!loggedInUser) return;

//       try {
//         const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/assginedtasks?assignedto=${loggedInUser}`);
//         if (response.ok) {
//           const data = await response.json();
//           setTasks(data);
//         } else {
//           console.error('Failed to fetch tasks');
//         }
//       } catch (error) {
//         console.error('Error fetching tasks:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchTasks();
//   }, [loggedInUser]);


//   const markAsCompleted = async (task, action) => {
//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/assginedtasks/${task._id}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           status: action === 'close' ? 'CLOSED' : 'COMPLETED',
//           modifiedby: loggedInUser,
//         }),
//       });

//       if (response.ok) {
//         const updatedTask = await response.json();
//         setTasks((prevTasks) =>
//           prevTasks.map((t) => (t._id === updatedTask._id ? updatedTask : t))
//         );
//         alert('Task Status Updated Successfully');
//       } else {
//         console.error('Failed to update task');
//       }
//     } catch (error) {
//       console.error('Error updating task:', error);
//     }
//   };





//   if (loading) {
//     return <p>Loading...</p>;
//   }

//   return (
//     <div>
//       <Link to='/Completed'><button>Completed Tasks</button></Link>
//       <h1>Tasks assigned to {loggedInUser}</h1>
//       {tasks.length > 0 ? (
//         <table>
//           <thead>
//             <tr>
//               <th>Task ID</th>
//               <th>Token ID</th>
//               <th>Assigned To</th>
//               <th>assignedtouserid</th>
//               <th>assignedtouserlevel</th>
//               <th>assignedtodesignation</th>
//               <th>assigneeuserlevel</th>
//               <th>assigneedesignation</th>
//               <th>Status</th>
//               <th>Created At</th>
//               <th>Modified At</th>
//               <th>Assigned At</th>
//               <th>Completed At</th>
//               <th>Closed At</th>
//               <th>Reassigned At</th>
//               <th>Action</th>
//               <th>Reassign</th>
//             </tr>
//           </thead>
//           <tbody>
//             {tasks.map((task) => (
//               <tr key={task._id}>
//                 <td>{task.task_id}</td>
//                 <td>{task.token_id}</td>
//                 <td>{task.assignedto}</td>
//                 <td>{task.assignedtouserid}</td>
//                 <td>{task.assignedtouserlevel}</td>
//                 <td>{task.assignedtodesignation}</td>
//                 <td>{task.assigneeuserlevel}</td>
//                 <td>{task.assigneedesignation}</td>
//                 <td>{task.status}</td>
//                 <td>{task.createdat}</td>
//                 <td>{task.modifiedat}</td>
//                 <td>{task.assinedat}</td>
//                 <td>{task.completedat}</td>
//                 <td>{task.closedat}</td>
//                 <td>{task.reassignedat}</td>
//                 <td>
//                   {task.status === 'TO-DO' && (
//                     <button onClick={() => markAsCompleted(task, 'complete')}>
//                       Mark as Completed
//                     </button>
//                   )}
//                   {task.status === 'COMPLETED' && (
//                     <button onClick={() => markAsCompleted(task, 'close')}>
//                       Close the Task
//                     </button>
//                   )}
//                   {task.status === 'CLOSED' && (
//                     <button disabled>
//                       CLOSED
//                     </button>
//                   )}
//                 </td>
//                 <td><button>Reassign</button></td>

//               </tr>
//             ))}
//           </tbody>

//         </table>
//       ) : (
//         <p>No tasks assigned to {loggedInUser}</p>
//       )}
//     </div>
//   );
// };

// export default Tasks;





// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';

// const Tasks = () => {
//   const [tasks, setTasks] = useState([]);
//   const [loggedInUser, setLoggedInUser] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const user = JSON.parse(localStorage.getItem('user'));
//     if (user && user.username) {
//       setLoggedInUser(user.username);
//     }
//   }, []);



//   useEffect(() => {
//     const fetchTasks = async () => {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/assignedtasks`);
//         if (response.ok) {
//           const data = await response.json();
//           setTasks(data);
//         } else {
//           console.error('Failed to fetch tasks');
//         }
//       } catch (error) {
//         console.error('Error fetching tasks:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchTasks();
//   }, []);

//   const markAsCompleted = async (task, action) => {
//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/assginedtasks/${task._id}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           status: action === 'close' ? 'CLOSED' : 'COMPLETED',
//           modifiedby: loggedInUser,
//         }),
//       });

//       if (response.ok) {
//         const updatedTask = await response.json();
//         setTasks((prevTasks) =>
//           prevTasks.map((t) => (t._id === updatedTask._id ? updatedTask : t))
//         );
//         alert('Task Status Updated Successfully');
//       } else {
//         console.error('Failed to update task');
//       }
//     } catch (error) {
//       console.error('Error updating task:', error);
//     }
//   };


//   const reassignTask = async (task) => {
//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/assginedtasks/${task._id}/reassign`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           modifiedby: loggedInUser,
//         }),
//       });

//       if (response.ok) {
//         const updatedTask = await response.json();
//         setTasks((prevTasks) =>
//           prevTasks.map((t) => (t._id === updatedTask._id ? updatedTask : t))
//         );
//         alert('Task Reassigned Successfully');
//       } else {
//         console.error('Failed to reassign task');
//       }
//     } catch (error) {
//       console.error('Error reassigning task:', error);
//     }
//   };


//   if (loading) {
//     return <p>Loading...</p>;
//   }

//   return (
//     <div>
//       <Link to='/PendingTasks'><button>Opertaor Screens</button></Link>
//       <h1>Supervisor Page</h1>
//       <h1>Tasks assigned to {loggedInUser}</h1>
//       {tasks.length > 0 ? (
//         <table>
//           <thead>
//             <tr>
//               <th>Task ID</th>
//               <th>Token ID</th>
//               <th>Assigned To</th>
//               <th>assignedtouserid</th>
//               <th>assignedtouserlevel</th>
//               <th>assignedtodesignation</th>
//               <th>assigneeuserlevel</th>
//               <th>assigneedesignation</th>
//               <th>Status</th>
//               <th>Created At</th>
//               <th>Modified At</th>
//               <th>Assigned At</th>
//               <th>Completed At</th>
//               <th>Reassigned At</th>
//               <th>Closed At</th>
//               <th>Action</th>
//               <th>Reassign</th>
//             </tr>
//           </thead>
//           <tbody>
//             {tasks.map((task) => (
//               <tr key={task._id}>
//                 <td>{task.task_id}</td>
//                 <td>{task.token_id}</td>
//                 <td>{task.assignedto}</td>
//                 <td>{task.assignedtouserid}</td>
//                 <td>{task.assignedtouserlevel}</td>
//                 <td>{task.assignedtodesignation}</td>
//                 <td>{task.assigneeuserlevel}</td>
//                 <td>{task.assigneedesignation}</td>
//                 <td>{task.status}</td>
//                 <td>{task.createdat}</td>
//                 <td>{task.modifiedat}</td>
//                 <td>{task.assinedat}</td>
//                 <td>{task.completedat}</td>
//                 <td>{task.reassignedat}</td>
//                 <td>{task.closedat}</td>
//                 <td>
//                   {task.status === 'TO-DO' && (
//                     <button onClick={() => markAsCompleted(task, 'complete')}>
//                       Mark as Completed
//                     </button>
//                   )}
//                   {task.status === 'COMPLETED' && (
//                     <button onClick={() => markAsCompleted(task, 'close')}>
//                       Close the Task
//                     </button>
//                   )}
//                   {task.status === 'CLOSED' && (
//                     <button disabled>
//                       CLOSED
//                     </button>
//                   )}
//                 </td>
//                 {/* <td>
//                   {task.status !== 'CLOSED' && (
//                     <button onClick={() => reassignTask(task)}>Reassign</button>
//                   )}
//                 </td> */}
//                  <td>
//                 {task.status !== 'CLOSED' && task.status !== 'REASSIGNED' && (
//                   <button onClick={() => reassignTask(task)}>Reassign</button>
//                 )}
//                 {task.status === 'REASSIGNED' && (
//                   <button disabled>Reassigned</button>
//                 )}
//               </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       ) : (
//         <p>No tasks assigned to {loggedInUser}</p>
//       )}
//     </div>
//   );
// };

// export default Tasks;







import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Task_Historyheadingsss } from '../Operator/Data';
import './Tasks.css';
import Supervisorsidebar from '../Supervisor/Supersidebar/Supersidebar';
import TopBar from '../Commonfns/Topbar/Topbar';
const Tasks = () => {
  const [tasks, setTasks] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [isAssignEnabled, setIsAssignEnabled] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.username) {
      setLoggedInUser(user);
    }
  }, []);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/assignedtasks`);
        if (response.ok) {
          const data = await response.json();
          setTasks(data);
        } else {
          console.error('Failed to fetch tasks');
        }
      } catch (error) {
        console.error('Error fetching tasks:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/users`);
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const markAsCompleted = async (task, action) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/assginedtasks/${task._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status: action === 'close' ? 'CLOSED' : 'COMPLETED',
          modifiedby: loggedInUser.username,
        }),
      });

      if (response.ok) {
        const updatedTask = await response.json();
        setTasks((prevTasks) =>
          prevTasks.map((t) => (t._id === updatedTask._id ? updatedTask : t))
        );
        alert('Task Status Updated Successfully');
      } else {
        console.error('Failed to update task');
      }
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  const reassignTask = async (task) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/assginedtasks/${task._id}/reassign`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          modifiedby: loggedInUser.username,
        }),
      });

      if (response.ok) {
        const updatedTask = await response.json();
        setTasks((prevTasks) =>
          prevTasks.map((t) => (t._id === updatedTask._id ? updatedTask : t))
        );
        alert('Task Reassigned Successfully');
      } else {
        console.error('Failed to reassign task');
      }
    } catch (error) {
      console.error('Error reassigning task:', error);
    }
  };

  const handleUserSelect = (taskId, event) => {
    const user = event.target.value;
    setSelectedUser((prevSelectedUser) => ({
      ...prevSelectedUser,
      [taskId]: user,
    }));
    setIsAssignEnabled((prevIsAssignEnabled) => ({
      ...prevIsAssignEnabled,
      [taskId]: user !== '',
    }));
  };

  const handleAssign = async (taskId) => {
    const user = users.find(user => user.username === selectedUser[taskId]);

    if (user && loggedInUser) {
      const assignedTaskDetails = {
        assignedto: user.username,
        assignedtouserid: user.user_id,
        assignedtouserlevel: user.level,
        assignedtodesignation: user.designation,
        modifiedby: loggedInUser.username,
        assigneeuserlevel: loggedInUser.level,
        assigneedesignation: loggedInUser.designation,
      };

      try {
        const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/assignedtasks/${taskId}/do-it-later`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(assignedTaskDetails),
        });

        if (response.ok) {
          const updatedTask = await response.json();
          setTasks((prevTasks) =>
            prevTasks.map((t) => (t._id === updatedTask._id ? updatedTask : t))
          );
          alert('Task Assigned Successfully');
        } else {
          console.error('Failed to assign task');
        }
      } catch (error) {
        console.error('Error assigning task:', error);
      }

      setSelectedUser((prevSelectedUser) => ({
        ...prevSelectedUser,
        [taskId]: '',
      }));
      setIsAssignEnabled((prevIsAssignEnabled) => ({
        ...prevIsAssignEnabled,
        [taskId]: false,
      }));
    } else {
      console.error("User or logged-in user information is missing.");
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  const handleDiagnoseClick = (token_id) => {
    console.log("Navigating to diagnose page for token ID:", token_id);
    navigate(`/Diagnose/${token_id}`);
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'TO-DO':
        return 'status-todo';
      case 'COMPLETED':
        return 'status-completed';
      case 'CLOSED':
        return 'status-closed';
      case 'REASSIGNED':
        return 'status-reassigned';
      case 'DO IT LATER':
        return 'status-do-it-later';
      default:
        return '';
    }
  };

  return (
    <div id='supertasks-total' >
      <div>
        <Supervisorsidebar />
      </div>
      <div id='supertasks-right'>
             <TopBar section="SupervisorTaskheading" />
        <div id='supertasks-right-2'>
          {/* <div id='supertasks-right-21'>
            <label>
              <button>123</button>
            </label>
            <label>
              <button>123</button>
            </label>
          </div> */}

          <div id='supertasks-right-3'>
            {/* <h1>Tasks assigned to {loggedInUser?.username}</h1> */}
            {tasks.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    {/* <th>Task ID</th> */}
                    <th>Token ID</th>
                    <th>Assigned to User</th>
                    {/* <th>Assigned to Userid</th> */}
                    {/* <th>assignedtouserlevel</th> */}
                    <th>Assigned Designation</th>
                    {/* <th>assigneeuserlevel</th> */}
                    <th>Assignee Designation</th>
                    <th>Status</th>
                    {/* <th>Created At</th> */}
                    {/* <th>Modified At</th> */}
                    <th>Assigned At</th>
                    <th>Completed At</th>
                    <th>Reassigned At</th>
                    <th>Closed At</th>
                    <th>View</th>
                    <th>Action</th>
                    <th>Reassign</th>
                  </tr>
                </thead>
                <tbody>
                  {tasks.map((task) => (
                    <tr key={task._id}>
                      {/* <td>{task.task_id}</td> */}
                      <td>{task.token_id}</td>
                      <td>{task.assignedto}</td>
                      {/* <td>{task.assignedtouserid}</td> */}
                      {/* <td>{task.assignedtouserlevel}</td> */}
                      <td>{task.assignedtodesignation}</td>
                      {/* <td>{task.assigneeuserlevel}</td> */}
                      <td>{task.assigneedesignation}</td>
                      <td id='supertasks-status-cont' > <button className={getStatusClass(task.status)}>{task.status}</button></td>
                      {/* <td>{task.createdat}</td> */}
                      {/* <td>{task.modifiedat}</td> */}
                      <td>{task.assinedat}</td>
                      <td>{task.completedat}</td>
                      <td>{task.reassignedat}</td>
                      <td>{task.closedat}</td>
                      <td><button id='supertasks-actionbuttons' onClick={() => handleDiagnoseClick(task.token_id)}>Diagnose</button></td>
                      <td>
                        {task.status === 'TO-DO' && (
                          <button id='supertasks-actionbuttons' onClick={() => markAsCompleted(task, 'complete')}>
                            Mark as Completed
                          </button>
                        )}
                        {task.status === 'COMPLETED' && (
                          <button id='supertasks-actionbuttons' onClick={() => markAsCompleted(task, 'close')}>
                            Close the Task
                          </button>
                        )}
                        {task.status === 'CLOSED' && (
                          <button id='supertasks-actionbuttons' disabled>
                            CLOSED
                          </button>
                        )}
                      </td>
                      <td>
                        {task.status === 'DO IT LATER' ? (
                          < div style={{ display: 'flex', marginLeft: '1vw' }}>
                            <select
                              value={selectedUser[task._id] || ''}
                              onChange={(e) => handleUserSelect(task._id, e)}
                            >
                              <option value="">Select the User</option>
                              {users.map(user => (
                                <option key={user._id} value={user.username}>
                                  {user.name}
                                </option>
                              ))}
                            </select>
                            <button
                              id='supertasks-actionbuttons'
                              onClick={() => handleAssign(task._id)}
                              disabled={!isAssignEnabled[task._id]}
                            >
                              Assign
                            </button>
                          </div>
                        ) : task.status !== 'CLOSED' && task.status !== 'REASSIGNED' ? (
                          <button id='supertasks-actionbuttons' onClick={() => reassignTask(task)}>Reassign</button>
                        ) : (
                          <button id='supertasks-actionbuttons' disabled>
                            Reassigned
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No tasks assigned to {loggedInUser?.username}</p>
            )}
          </div>

        </div>

      </div>


    </div>
  );
};

export default Tasks;
