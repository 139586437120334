// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const Energyintensity = () => {
//   const [data, setData] = useState([]);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/energyintensity`);
//         setData(response.data);
//       } catch (err) {
//         setError('Error fetching data');
//         console.error(err);
//       }
//     };

//     fetchData();
//   }, []);

//   if (error) {
//     return <div>{error}</div>;
//   }

//   if (data.length === 0) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       <h1>Energy Intensity Data for the Last 7 Days</h1>
//       {data.map(item => (
//         <div key={item._id}>
//           <p>Date: {new Date(item.date).toLocaleDateString()}</p>
//           <p>Energy Intensity: {item.energy_intensity}</p>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Energyintensity;




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import * as echarts from 'echarts';

// const Energyintensity = () => {
//   const [data, setData] = useState([]);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/energyintensity`);
//         setData(response.data);
//         renderChart(response.data);
//       } catch (err) {
//         setError('Error fetching data');
//         console.error(err);
//       }
//     };

//     fetchData();
//   }, []);

//   const renderChart = (data) => {
//     const dates = data.map(item => new Date(item.date).toLocaleDateString());
//     const energyIntensities = data.map(item => item.energy_intensity);

//     var chartDom = document.getElementById('main');
//     var myChart = echarts.init(chartDom);
//     var option;

//     option = {
//       tooltip: {
//         trigger: 'axis',
//         axisPointer: {
//           type: 'shadow'
//         }
//       },
//       grid: {
//         left: '3%',
//         right: '4%',
//         bottom: '3%',
//         containLabel: true
//       },
//       xAxis: [
//         {
//           type: 'category',
//           data: dates,
//           axisTick: {
//             alignWithLabel: true
//           }
//         }
//       ],
//       yAxis: [
//         {
//           type: 'value'
//         }
//       ],
//       series: [
//         {
//           name: 'Energy Intensity',
//           type: 'bar',
//           barWidth: '60%',
//           data: energyIntensities
//         }
//       ]
//     };

//     option && myChart.setOption(option);
//   };

//   if (error) {
//     return <div>{error}</div>;
//   }

//   if (data.length === 0) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       <h1>Energy Intensity Data for the Last 7 Days</h1>
//       <div id="main" style={{ width: '100%', height: '400px' }}></div>
//     </div>
//   );
// };

// export default Energyintensity;




import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';

const Energyintensity = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const chartRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/energyintensity`);
        console.log('API response:', response.data);
        setData(response.data);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Error fetching data');
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0 && chartRef.current) {
      renderChart(data);
    }
  }, [data]);

  const renderChart = (data) => {
    const dates = data.map(item => new Date(item.date).toLocaleDateString());
    const energyIntensities = data.map(item => item.energy_intensity);

    var chartDom = chartRef.current;
    var myChart = echarts.init(chartDom);
    var option = {
        title: {
            text: 'Energy Intensity'
          },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: dates,
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Intensity',
          nameLocation: 'middle',
          nameRotate: 90,
          nameGap: 21,
          axisLabel: {
            formatter: '{value}'
          }
        }
      ],
      series: [
        {
          name: 'Energy Intensity',
          type: 'bar',
          barWidth: '10%',
          data: energyIntensities
        },
      ]
    };

    option && myChart.setOption(option);
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (data.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div id="main" ref={chartRef} style={{ width: '40%', height: '400px' }}></div>
    </div>
  );
};

export default Energyintensity;
