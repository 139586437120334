
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Stationpoints from '../../../Commonfns/Stationpoints';
import './Stationpointsalerts.css';
import { FaFileCsv } from "react-icons/fa6";

const Stationpointsalerts = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [section, setSection] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/all/alerts_reports`);
                setData(response.data);
                setFilteredData(response.data);
            } catch (error) {
                console.error('Error fetching the data', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        filterData();
    }, [fromDate, toDate, section]);

    const handleStationPointChange = (selectedStationPoint) => {
        setSection(selectedStationPoint);
    };

    const filterData = () => {
        let filtered = data;

        if (fromDate) {
            filtered = filtered.filter(item => new Date(item.date) >= new Date(fromDate));
        }
        if (toDate) {
            filtered = filtered.filter(item => new Date(item.date) <= new Date(toDate));
        }
        if (section) {
            filtered = filtered.filter(item => Object.keys(item.station_alert_counts).includes(section));
        }

        setFilteredData(filtered);
    };

    const downloadCSV = () => {
        const csvRows = [];
        const headers = [
            "Date",
            "Section Point",
            "Vr",
            "Vy",
            "Vb",
            "Ir",
            "Iy",
            "Ib",
            "PF",
            "KVA",
            "Switch",
            "AC Input",
            "Communication"
        ];
        csvRows.push(headers.join(','));

        filteredData.forEach(item => {
            Object.keys(item.station_alert_counts).forEach(station => {
                if (!section || section === station) {
                    const row = [
                        item.date,
                        station,
                        item.station_alert_counts[station].Vr,
                        item.station_alert_counts[station].Vy,
                        item.station_alert_counts[station].Vb,
                        item.station_alert_counts[station].Ir,
                        item.station_alert_counts[station].Iy,
                        item.station_alert_counts[station].Ib,
                        item.station_alert_counts[station].PF,
                        item.station_alert_counts[station].KVA,
                        item.station_alert_counts[station].Switch,
                        item.station_alert_counts[station]['AC Input'],
                        item.station_alert_counts[station].Communication
                    ];
                    csvRows.push(row.join(','));
                }
            });
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'Alerts-Report-data.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const handleFromDateChange = (e) => {
        const selectedFromDate = e.target.value;
        setFromDate(selectedFromDate);
    
        if (toDate && toDate < selectedFromDate) {
          setToDate("");
        }
      };
    
      const handleToDateChange = (e) => {
        setToDate(e.target.value);
      };
    
      const getTodayDate = () => {
        const today = new Date();
        return today.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
      };

    return (
        <div id='staionpointsalerts-total'>

            <div id='staionpointsalerts-right-21'>
                <label>
                    <span>From Date: </span>
                    <input type='date' value={fromDate} onChange={handleFromDateChange} max={getTodayDate()} />
                </label>
                <label>
                    <span>To Date: </span>
                    <input type='date' value={toDate} onChange={handleToDateChange} max={getTodayDate()}  min={fromDate || ""}  disabled={!fromDate} />
                </label>
                <label >
                    <Stationpoints onStationPointChange={handleStationPointChange} />
                </label>
                <label>
                    <button onClick={downloadCSV}><FaFileCsv /></button>
                </label>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Section</th>
                        <th>Vr</th>
                        <th>Vy</th>
                        <th>Vb</th>
                        <th>Ir</th>
                        <th>Iy</th>
                        <th>Ib</th>
                        <th>PF</th>
                        <th>KVA</th>
                        <th>Switch</th>
                        <th>AC Input</th>
                        <th>Communication</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((item) => (
                        Object.keys(item.station_alert_counts).map((station) => (
                            (!section || section === station) && (
                                <tr key={`${item.date}-${station}`}>
                                    <td>{item.date}</td>
                                    <td>{station}</td>
                                    <td>{item.station_alert_counts[station].Vr}</td>
                                    <td>{item.station_alert_counts[station].Vy}</td>
                                    <td>{item.station_alert_counts[station].Vb}</td>
                                    <td>{item.station_alert_counts[station].Ir}</td>
                                    <td>{item.station_alert_counts[station].Iy}</td>
                                    <td>{item.station_alert_counts[station].Ib}</td>
                                    <td>{item.station_alert_counts[station].PF}</td>
                                    <td>{item.station_alert_counts[station].KVA}</td>
                                    <td>{item.station_alert_counts[station].Switch}</td>
                                    <td>{item.station_alert_counts[station]['AC Input']}</td>
                                    <td>{item.station_alert_counts[station].Communication}</td>
                                </tr>
                            )
                        ))
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Stationpointsalerts;


