import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import { Voltagevoltagefluctuationsheader } from '../../../Operator/Data';
import { Link } from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import './Groundingresistancefetch.css';
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const Powersurge_Groundingresistancefetch = () => {
    const [groundingResistances, setGroundingResistances] = useState([]);
    const [groundingResistanceEditData, setGroundingResistanceEditData] = useState(null);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
      // Retrieve the logged-in user from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        setLoggedInUser(user);
      }
      setUserDesignation(user.level);
    }, []);

    const fetchGroundingResistances = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/powersurge-groundingresistances`);
            setGroundingResistances(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchGroundingResistances();
    }, []);

    const handleEditClick = (groundingResistance) => {
        setGroundingResistanceEditData(groundingResistance);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setGroundingResistanceEditData({ ...groundingResistanceEditData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        console.log('Updating data:', groundingResistanceEditData);
        alert("Grounding Resistance updated successfully");
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/powersurge-groundingresistances/${groundingResistanceEditData._id}`, groundingResistanceEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchGroundingResistances();
            setGroundingResistanceEditData(null);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='groundingresistancefetch-total'>
            <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />}
            </div>
            <div id='groundingresistancefetch-right'>
                <div id='groundingresistancefetch-right-1'>
                    <div id='groundingresistancefetch-heading'>
                        {Voltagevoltagefluctuationsheader.alertHeading}
                    </div>
                    <div id='groundingresistancefetch-heading1'>
                        {Voltagevoltagefluctuationsheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Voltagevoltagefluctuationsheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <div id='groundingresistancefetch-right-2'>
                    <div id='groundingresistancefetch-right-21'>

                    <label>
                            <span style={{fontWeight:'bold'}}>Grounding Resistance</span>
                        </label>
                        {/* <label>
                            <Link to='/Powersurge_Groundingresistance'><button>Form</button></Link>
                        </label> */}
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Token ID</th>
                                <th>Date and Time</th>
                                <th>Name of Grounding Point</th>
                                <th>Grounding Resistance</th>
                                {/* <th>Created By</th>
                                <th>Created At</th>
                                <th>Modified By</th>
                                <th>Modified At</th> */}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groundingResistances.map((groundingResistance) => (
                                <tr key={groundingResistance._id}>
                                    <td>{groundingResistance.token_id}</td>
                                    <td>{groundingResistance.dateandtime}</td>
                                    <td>{groundingResistance.name_of_grounding_point}</td>
                                    <td>{groundingResistance.grounding_resistance}</td>
                                    {/* <td>{groundingResistance.createdby}</td>
                                    <td>{groundingResistance.createdat}</td>
                                    <td>{groundingResistance.modifiedby}</td>
                                    <td>{groundingResistance.modifiedat}</td> */}
                                    <td>
                                        <button onClick={() => handleEditClick(groundingResistance)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {groundingResistanceEditData && (
                        <Popup open={true} onClose={() => setGroundingResistanceEditData(null)}>
                            <div id='groundingresistancefetch-popup'>
                                <h3>Edit Grounding Resistance</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='groundingresistancefetch-popup1'>
                                        <label>
                                            <span>Token ID</span>
                                            <input type="text" name="token_id" value={groundingResistanceEditData.token_id} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Date and Time</span>
                                            <input type="text" name="dateandtime" value={groundingResistanceEditData.dateandtime} onChange={handleEditChange} disabled />
                                        </label>
                                    </div>
                                    <div id='groundingresistancefetch-popup1'>
                                        <label>
                                            <span>Name of Grounding Point <span id='all-mandetorymark'>*</span></span>
                                            <input type="text" name="name_of_grounding_point" value={groundingResistanceEditData.name_of_grounding_point} onChange={handleEditChange} required />
                                        </label>
                                        <label>
                                            <span>Grounding Resistance <span id='all-mandetorymark'>*</span></span>
                                            <input type="number" name="grounding_resistance" value={groundingResistanceEditData.grounding_resistance} onChange={handleEditChange} required />
                                        </label>
                                    </div>
                                    {/* <div id='groundingresistancefetch-popup1'>
                                        <label>
                                            <span>Created By</span>
                                            <input type="text" name="createdby" value={groundingResistanceEditData.createdby} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Created At</span>
                                            <input type="text" name="createdat" value={groundingResistanceEditData.createdat} onChange={handleEditChange} disabled />
                                        </label>
                                    </div>
                                    <div id='groundingresistancefetch-popup1'>
                                        <label>
                                            <span>Modified By</span>
                                            <input type="text" name="modifiedby" value={groundingResistanceEditData.modifiedby} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Modified At</span>
                                            <input type="text" name="modifiedat" value={groundingResistanceEditData.modifiedat} onChange={handleEditChange} disabled />
                                        </label>
                                    </div> */}
                                    <button id='groundingresistancefetch-popup-update' type="submit">Update</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Powersurge_Groundingresistancefetch;
