


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Revenueloss_Actions.css';
import {  useNavigate } from 'react-router-dom';

const Revenueloss_Actions = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responses = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-utilizationpercantage`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-transformerloss`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-voltagediagnose`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-lowpfdiagnose`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-powersurgediagnose`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-idleloss`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-todloss`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-leadingpfdiagnose`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-unbalncedloaddiagnose`),
                    axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/acknowledged-revenue-thddiagnose`),
                ]);

                const allData = responses.flatMap(response => response.data);
                // console.log('Fetched data:', allData);
                setData(allData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleLocate = (item) => {
        // console.log('Navigating with item:', item);

        let route = '';
        switch (item.alert_heading) {
            case 'Utilization Loss':
                route = '/Utilization_Loss_Revenue_Diagnose';
                break;
            case 'Transformer Loss':
                route = '/Transformer_Loss_Revenue_Diagnose';
                break;
            case 'Voltage Diagnoses':
                route = '/Voltagediagnose';
                break;
            case 'Low PF':
                route = '/LowPFdiagnose';
                break;
            case 'Power Surges':
                route = '/Powersurgediagnose';
                break;
            case 'Idle Loss':
                route = '/Idle_Loss_Revenue_Diagnose';
                break;
            case 'TOD Loss':
                route = '/TOD_loss_Revenue_Diagnose';
                break;
            case 'Unbalanced Loads':
                route = '/Unbalancedloaddiagnose';
                break;
            case 'Harmonics Distortion':
                route = '/Harmonicdistortiondiagnose';
                break;
            case 'Leading PF':
                route = '/LeadPFdiagnose';
                break;
                
            default:
                console.warn('Unknown alert heading:', item.alert_heading);
        }

        navigate(route, { state: { selectedMonth: item.Month } });
    };

    return (
        <div id='revenuelossaction-total'>
            <table>
                <thead>
                    <tr>
                        <th>S. No.</th>
                        <th>Month</th>
                        <th>Type of Revenue Loss</th>
                        <th>Locate</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 ? (
                        data.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.Month}</td>
                                <td>{item.alert_heading}</td>
                                <td>
                                    <button onClick={() => handleLocate(item)}>Open</button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4">Not available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Revenueloss_Actions;


