
import React, { useEffect, useState } from 'react';
import Operatorsidebar from '../Sidebar/Sidebar';
import { TODhistory } from '../Data';
import './SavedTOD.css';
import { FaFileCsv } from "react-icons/fa6";
import Stationpoints from '../../Commonfns/Stationpoints';

const TODviewcomponent = () => {
  const [todviewData, setTODviewData] = useState([]);
  const [section, setSection] = useState('');
  const [selectedTOD, setSelectedTOD] = useState('');
  const [fromDate, setFromDate] = useState(new Date().toISOString().split('T')[0]);
  const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
  const [filteredData, setFilteredData] = useState([]);

  const fetchTODviewData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/todviewdata`);
      const data = await response.json();
      if (Array.isArray(data)) {
        setTODviewData(data);
        setFilteredData(data);
      } else {
        console.error('TOD view data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching TOD view data:', error);
    }
  };

  useEffect(() => {
    fetchTODviewData();
  }, []);

  const handleStationPointChange = (selectedStationPoint) => {
    setSection(selectedStationPoint);
  };


  const handleTODChange = (e) => {
    setSelectedTOD(e.target.value);
  };

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
  };

  const handleFromDateChange = (e) => {
    const selectedFromDate = e.target.value;
    setFromDate(selectedFromDate);

    if (toDate && toDate < selectedFromDate) {
      setToDate("");
    }
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  useEffect(() => {
    const filtered = todviewData.filter((item) => {
      const itemDate = item.timestamp.split('T')[0];
      const matchesSection = section === '' || item.section === section;
      const matchesTOD = selectedTOD === '' || item.tod_key === selectedTOD;
      const matchesDate = (fromDate === '' || itemDate >= fromDate) && (toDate === '' || itemDate <= toDate);

      return matchesSection && matchesTOD && matchesDate;
    });

    setFilteredData(filtered);
  }, [section, selectedTOD, fromDate, toDate, todviewData]);

  const downloadCSV = () => {
    const csvRows = [];
    const headers = ['S No', 'Time', 'TOD Timings', 'Type', 'Section', 'KWh', 'KVAh', 'KVARh', 'MD', 'Avg PF'];
    csvRows.push(headers.join(','));

    filteredData.forEach((item, index) => {
      const row = [
        index + 1,
        item.timestamp,
        item.tod_key,
        item.Type,
        item.section,
        item.KWh,
        item.KVAh,
        item.KVArh,
        item.MD,
        item.PF
      ];
      csvRows.push(row.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'TOD-History.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div id='todhistory-total'>
      {/* <div>
        <Operatorsidebar />
      </div> */}
      <div id='todhistory-right'>
        {/* <div id='todhistory-right-1'>
          <div id='todhistory-heading'>
            {TODhistory.alertHeading}
          </div>
          <div id='todhistory-heading1'>
            {TODhistory.alertActions.map((action, index) => (
              <button key={index} aria-label={action.label}>
                {action.icon}
              </button>
            ))}
            <img src={TODhistory.logoSrc} alt='logo' />
          </div>
        </div> */}

        <div id='todhistory-right-2'>
          <div id='todhistory-right-21'>
            <label>
              <span>From Date:</span>
              <input type='date' value={fromDate} onChange={handleFromDateChange} max={getTodayDate()} ></input>
            </label>
            <label>
              <span>To Date:</span>
              <input type='date' value={toDate} onChange={handleToDateChange}  max={getTodayDate()} min={fromDate || ""}  disabled={!fromDate}></input>
            </label>
            <label>
              <span>Select TOD:</span>
              <select value={selectedTOD} onChange={handleTODChange}>
                <option value=''>Select All</option>
                <option>TOD1 (00:00:00 - 06:00:00)</option>
                <option>TOD2 (06:00:00 - 10:00:00)</option>
                <option>TOD3 (10:00:00 - 18:00:00)</option>
                <option>TOD4 (18:00:00 - 22:00:00)</option>
                <option>TOD5 (22:00:00 - 24:00:00)</option>
              </select>
            </label>
            <label>
              <Stationpoints onStationPointChange={handleStationPointChange} />
            </label>

            <label>
              <button onClick={downloadCSV}><FaFileCsv /></button>
            </label>
          </div>
          <div id='todhistory-right-3'>
            <table>
              <thead>
                <tr>
                  <th>S No</th>
                  <th>Time</th>
                  <th>TOD Timings</th>
                  <th>Type</th>
                  <th>Section</th>
                  <th>KWh</th>
                  <th>KVAh</th>
                  <th>KVARh</th>
                  <th>MD</th>
                  <th>Avg PF</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr key={item._id}>
                    <td>{index + 1}</td>
                    <td>{item.timestamp}</td>
                    <td>{item.tod_key}</td>
                    <td>{item.Type}</td>
                    <td>{item.section}</td>
                    <td>{item.KWh}</td>
                    <td>{item.KVAh}</td>
                    <td>{item.KVArh}</td>
                    <td>{item.MD}</td>
                    <td>{item.PF}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TODviewcomponent;
