import React, { useState , useEffect } from 'react';
import './Capacitance_Manual_Form.css';
import axios from 'axios';
import { IoIosAddCircle } from "react-icons/io";
import { IoIosRemoveCircle } from "react-icons/io";
import { LowPFheader } from '../../../Operator/Data';
import { Link , useParams , useNavigate } from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import { getCurrentDateTime } from '../../../Diagnose/dateUtils';
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const Capacitance_Manual_Form = () => {
    const { token_id } = useParams();
    const navigate = useNavigate();
    const [capacitances, setCapacitances] = useState([{
        name: '',
        capacitor_bank_rating: '',
        threshold_capacitance: '',
        measured_capacitance: '',
        token_id: token_id,
        dateandtime: getCurrentDateTime(),
        createdby: '',
        createdat: '',
        modifiedby: '',
        modifiedat: ''
    }]);

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
      // Retrieve the logged-in user from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        setLoggedInUser(user);
      }
      setUserDesignation(user.level);
    }, []);

    const handleAdd = () => {
        setCapacitances([...capacitances, {
            name: '',
            capacitor_bank_rating: '',
            threshold_capacitance: '',
            measured_capacitance: '',
            token_id: token_id,
            dateandtime: getCurrentDateTime(),
            createdby: '',
            createdat: '',
            modifiedby: '',
            modifiedat: ''
        }]);
    };

    const handleChange = (index, event) => {
        const { name, value } = event.target;
        const newCapacitances = [...capacitances];
        newCapacitances[index][name] = value;
        setCapacitances(newCapacitances);
    };

    const handleCancel = (index) => {
        const newCapacitances = capacitances.filter((_, i) => i !== index);
        setCapacitances(newCapacitances);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = capacitances.map(capacitance => ({
                ...capacitance,
                capacitor_bank_rating: Number(capacitance.capacitor_bank_rating),
                threshold_capacitance: Number(capacitance.threshold_capacitance),
                measured_capacitance: Number(capacitance.measured_capacitance)
            }));

            const response = await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/capacitance`, dataToSend);

            console.log('Data posted successfully:', response.data);

            alert('Capacitance data added successfully');
            setCapacitances([{
                name: '',
                capacitor_bank_rating: '',
                threshold_capacitance: '',
                measured_capacitance: '',
                token_id: token_id,
                dateandtime: getCurrentDateTime(),
                createdby: '',
                createdat: '',
                modifiedby: '',
                modifiedat: ''
            }]);
            navigate('/Capacitance_Manual_Formfetch');

        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    return (
        <div id='capacitance_manual_form-total'>
              <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />} 
            </div>
            <div id='capacitance_manual_form-right'>
                <div id='capacitance_manual_form-right-1'>
                    <div id='capacitance_manual_form-heading'>
                        {LowPFheader.alertHeading}
                    </div>
                    <div id='capacitance_manual_form-heading1'>
                        {LowPFheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={LowPFheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <h3 id='capacitance_manual_form-h3'>{LowPFheader.subalertHeading}</h3>
                <div id='capacitance_manual_form-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='capacitance_manual_form-divtop'>
                            <label>
                                <span>Current Capacitance</span>
                            </label>
                            {/* <label>
                                <Link to='/Capacitance_Manual_Formfetch'><button type="button">View Data</button></Link>
                            </label> */}
                        </div>

                        {capacitances.map((capacitance, index) => (
                            <div key={index} id='capacitance_manual_form-row'>
                                <label>
                                    <span>Name <span id='all-mandetorymark'>*</span></span>
                                    <input
                                        name="name"
                                        value={capacitance.name}
                                        onChange={(e) => handleChange(index, e)}
                                        required
                                    />
                                </label>
                                <label>
                                    <span>Capacitor Bank Rating <span id='all-mandetorymark'>*</span></span>
                                    <input
                                        name="capacitor_bank_rating"
                                        type="number"
                                        value={capacitance.capacitor_bank_rating}
                                        onChange={(e) => handleChange(index, e)}
                                        required
                                    />
                                </label>
                                <label>
                                    <span>Threshold Capacitance <span id='all-mandetorymark'>*</span></span>
                                    <input
                                        name="threshold_capacitance"
                                        type="number"
                                        value={capacitance.threshold_capacitance}
                                        onChange={(e) => handleChange(index, e)}
                                        required
                                    />
                                </label>
                                <label>
                                    <span>Measured Capacitance <span id='all-mandetorymark'>*</span></span>
                                    <input
                                        name="measured_capacitance"
                                        type="number"
                                        value={capacitance.measured_capacitance}
                                        onChange={(e) => handleChange(index, e)}
                                        required
                                    />
                                </label>
                                {index !== 0 && (
                                    <button id='capacitance_manual_form-cancelbtn' type="button" onClick={() => handleCancel(index)}><IoIosRemoveCircle id='reg-btn' /></button>
                                )}
                                {index === capacitances.length - 1 && (
                                    <button id='capacitance_manual_form-cancelbtn' type="button" onClick={handleAdd}><IoIosAddCircle id='reg-btn' /></button>
                                )}
                            </div>
                        ))}

                       
                            <button id='capacitance_manual_form-submit' type="submit">Submit</button>
                        
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Capacitance_Manual_Form;
