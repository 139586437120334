
// import React from 'react';
// import Stationpoints from '../../Commonfns/Stationpoints';

// const Calendercombinecontainer = ({ selectedMonth, onMonthChange, onStationPointChange, defaultStationPoint }) => {
//     const handleMonthChange = (event) => {
//         onMonthChange(event.target.value);
//     };

//     const getCurrentMonth = () => {
//         const date = new Date();
//         return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
//     };

//     const handleStationPointChange = (selectedStationPoint) => {
//         onStationPointChange(selectedStationPoint);
//     };

//     return (
//         <div id='combinecal-div'>
//             <label style={{display:'flex', alignItems:'center'}}>
//                 <span>Select Section:</span>
//                 <Stationpoints onStationPointChange={handleStationPointChange} defaultStationPoint={defaultStationPoint} />
//             </label>
//             <label>
//                 <span>Select Month :</span>
//                 <input
//                     type="month"
//                     id="month"
//                     value={selectedMonth}
//                     onChange={handleMonthChange}
//                     max={getCurrentMonth()}
//                 ></input>
//             </label>
//         </div>
//     );
// };

// export default Calendercombinecontainer;



import React, { useState } from 'react';
import Stationpoints from '../../Commonfns/Stationpoints';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FaFilePdf } from "react-icons/fa6";
const Calendercombinecontainer = ({ selectedMonth, onMonthChange, onStationPointChange, defaultStationPoint }) => {
    const handleMonthChange = (event) => {
        onMonthChange(event.target.value);
    };

    const getCurrentMonth = () => {
        const date = new Date();
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
    };

    const handleStationPointChange = (selectedStationPoint) => {
        onStationPointChange(selectedStationPoint);
    };

    const [loading, setLoading] = useState(false);

    const downloadPDF = () => {
        setLoading(true);
        // alert('Please wait, PDF is being generated...');
        const input = document.getElementById('calendercombine-totalright');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save('Calender_Events.pdf');
                setLoading(false);
                // alert('PDF has been downloaded successfully!');
            })
            .catch((error) => {
                console.error('Error generating PDF:', error);
                alert('There was an error generating the PDF.');
            });
    };

    return (
        <div id='combinecal-div'>
            <label>
                <Stationpoints onStationPointChange={handleStationPointChange} defaultStationPoint={defaultStationPoint} />
            </label>
            <label>
                <span>Select Month :</span>
                <input
                    type="month"
                    id="month"
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    max={getCurrentMonth()}
                ></input>
            </label>
            <label>
                <button onClick={downloadPDF}><FaFilePdf /></button>
                {loading && <div className="combilecal-loading">Please wait, PDF is downloading...</div>}
            </label>

        </div>
    );
};

export default Calendercombinecontainer;

