
import { useState, React, useEffect } from 'react';
import { Pendingtsaksheader } from '../../Operator/Data';
import './Pending_Tasks.css';
import Unfilled_forms from './Unfilled_forms/Unfilled_forms';
// import Pending_maintenance_form from './Pending_maintenance_form/Pending_maintenance_form';
import Pending_Diagnosis from './Pending_Diagnosis/Pending_Diagnosis';
import Pending_Actions from './Pending_Actions/Pending_Actions';
import Pending_Results from './Pending_Results/Pending_Results';
// import ClosedTasks from './ClosedTasks/ClosedTasks';
import Supervisorsidebar from '../../Supervisor/Supersidebar/Supersidebar';
import Revenueloss_Actions from './Revenueloss_Actions/Revenueloss_Actions';
import Operatorsidebar from '../../Operator/Sidebar/Sidebar';
import TopBar from '../../Commonfns/Topbar/Topbar';
const Pending_Tasks = () => {
    const [activeSection, setActiveSection] = useState('Unfilled_forms-1');
    const [accessedLevel, setAccessedLevel] = useState(null);

    useEffect(() => {
        // Fetch the accessed level from localStorage
        const level = localStorage.getItem('accessedLevel');
        setAccessedLevel(level);
    }, []);

    const handleButtonClick = (section) => {
        setActiveSection(section);
    };

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.username) {
            setLoggedInUser(user);
        }
        setUserDesignation(user.level);
    }, []);

    return (
        <div id='pendingtasks123-total'>
            <div>
                {accessedLevel  === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />}
            </div>
            <div id='pendingtasks123-right'>

                <TopBar section="PendingTasksHeader" />

                <div id='pendingtasks123-right-2'>
                    <div id='pendingtasks123-right-21'>
                        <div id='pendingtasks123-right-23'>
                            <button
                                className={activeSection === 'Unfilled_forms-1' ? 'active' : ''}
                                onClick={() => handleButtonClick('Unfilled_forms-1')}
                            >
                                Unfilled Forms
                            </button>
                            {/* <button
                                className={activeSection === 'Pending_maintenance_form-1' ? 'active' : ''}
                                onClick={() => handleButtonClick('Pending_maintenance_form-1')}
                            >
                                Pending Maintenance
                            </button> */}
                            <button
                                className={activeSection === 'Pending_Diagnosis-1' ? 'active' : ''}
                                onClick={() => handleButtonClick('Pending_Diagnosis-1')}
                            >
                                Pending Diagnosis
                            </button>
                            <button
                                className={activeSection === 'Pending_Actions-1' ? 'active' : ''}
                                onClick={() => handleButtonClick('Pending_Actions-1')}
                            >
                                Pending Actions
                            </button>
                            <button
                                className={activeSection === 'Pending_Results-1' ? 'active' : ''}
                                onClick={() => handleButtonClick('Pending_Results-1')}
                            >
                                Pending Reviews
                            </button>

                            {/* <button
                                className={activeSection === 'ClosedTasks-1' ? 'active' : ''}
                                onClick={() => handleButtonClick('ClosedTasks-1')}
                            >
                                Closed Tasks
                            </button> */}

                            <button
                                className={activeSection === 'Revenueloss_Actions-1' ? 'active' : ''}
                                onClick={() => handleButtonClick('Revenueloss_Actions-1')}
                            >
                                Pending Revenue loss Suggestions
                            </button>
                            {/* <button id='pendingtasks123-right-23-btn'  onClick={handleRefreshClick} >Refresh</button> */}
                        </div>
                    </div>

                    <div id='pendingtasks123-right-3'>
                        <div>
                            <div>
                                {activeSection === 'Unfilled_forms-1' && <Unfilled_forms />}
                                {/* {activeSection === 'Pending_maintenance_form-1' && <Pending_maintenance_form />} */}
                                {activeSection === 'Pending_Diagnosis-1' && <Pending_Diagnosis />}
                                {activeSection === 'Pending_Actions-1' && <Pending_Actions />}
                                {activeSection === 'Pending_Results-1' && <Pending_Results />}
                                {/* {activeSection === 'ClosedTasks-1' && <ClosedTasks />} */}
                                {activeSection === 'Revenueloss_Actions-1' && <Revenueloss_Actions />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pending_Tasks;
