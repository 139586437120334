import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import './Mastertransformerfetch.css'
import { Masterformsheadingsss } from '../../Operator/Data';
const Mastertransformerfetch = ({ onFormButtonClick }) => {
    const [masterTransformers, setMasterTransformers] = useState([]);
    const [masterTransformerEditData, setMasterTransformerEditData] = useState(null);
    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-hvincomerNames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    const fetchMasterTransformers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/mastertransformers`);
            setMasterTransformers(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchMasterTransformers();
    }, []);

    const handleEditClick = (masterTransformer) => {
        setMasterTransformerEditData(masterTransformer);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setMasterTransformerEditData({ ...masterTransformerEditData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        console.log('Updating data:', masterTransformerEditData);
        alert("Master Transformer updated successfully");
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/mastertransformers/${masterTransformerEditData._id}`, masterTransformerEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchMasterTransformers();
            setMasterTransformerEditData(null);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='mastertransformerfetch-total'>
            
            <div id='mastertransformerfetch-right'>
                <div id='mastertransformerfetch-right-2'>
                    <div id='mastertransformerfetch-right-21'>
                    <label>
                                {Masterformsheadingsss.TransformerHeading}
                            </label>
                        <label>
                            <button onClick={onFormButtonClick}>Form</button>
                        </label>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Parent Name</th>
                                <th>Transformer Name</th>
                                <th>Phase</th>
                                <th>Primary Voltage</th>
                                <th>Secondary Voltage</th>
                                <th>KVA Rating</th>
                                <th>Full Load Current</th>
                                <th>Impedence</th>
                                <th>Idle Load Current</th>
                                <th>Vector Type</th>
                                <th>Coolant Type</th>
                                <th>Make</th>
                                <th>SL No</th>
                                <th>IEC Standard</th>
                                <th>ISO Standard</th>
                                <th>Dimensions</th>
                                <th>Weight</th>
                                <th>Proposed Periodicity of Maintenance</th>
                                <th>Previous Maintenance Done Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {masterTransformers.map((masterTransformer) => (
                                <tr key={masterTransformer._id}>
                                    <td>{masterTransformer.date}</td>
                                    <td>{masterTransformer.name}</td>
                                    <td>{masterTransformer.parentname}</td>
                                    <td>{masterTransformer.transformername}</td>
                                    <td>{masterTransformer.phase}</td>
                                    <td>{masterTransformer.primaryvoltage}</td>
                                    <td>{masterTransformer.secondaryvoltage}</td>
                                    <td>{masterTransformer.kvarating}</td>
                                    <td>{masterTransformer.fullloadcurrent}</td>
                                    <td>{masterTransformer.impedence}</td>
                                    <td>{masterTransformer.idleloadcurrent}</td>
                                    <td>{masterTransformer.vectortype}</td>
                                    <td>{masterTransformer.coolanttype}</td>
                                    <td>{masterTransformer.make}</td>
                                    <td>{masterTransformer.slno}</td>
                                    <td>{masterTransformer.iecstandard}</td>
                                    <td>{masterTransformer.isostandard}</td>
                                    <td>{masterTransformer.dimentions}</td>
                                    <td>{masterTransformer.weight}</td>
                                    <td>{masterTransformer.proposedperiodicityofmaintenance}</td>
                                    <td>{masterTransformer.previousmaintenancedonedate}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(masterTransformer)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {masterTransformerEditData && (
                        <Popup open={true} onClose={() => setMasterTransformerEditData(null)}>
                            <div id='mastertransformerfetch-popup'>
                                <h3>Edit Transformer </h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='mastertransformerfetch-popup1'>
                                        <label>
                                            <span>Date</span>
                                            <input type="date" name="date" value={masterTransformerEditData.date} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span> Parent Name</span>
                                            <select name="parentname" value={masterTransformerEditData.parentname} onChange={handleEditChange}>
                                                <option value="">Select Parent Name</option>
                                                {parentNames.map((name) => (
                                                    <option key={name} value={name}>{name}</option>
                                                ))}
                                            </select>
                                        </label>
                                    </div>
                                    <div id='mastertransformerfetch-popup1'>
                                        <label>
                                            <span>Name</span>
                                            <input type="text" name="name" value={masterTransformerEditData.name} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Transformer Name</span>
                                            <input type="text" name="transformername" value={masterTransformerEditData.transformername} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='mastertransformerfetch-popup1'>

                                        <label>
                                            <span>Phase</span>
                                            <select name="phase" value={masterTransformerEditData.phase} onChange={handleEditChange} required>
                                                <option value=''>Select Phase</option>
                                                <option value='3-Phase'>3-Phase</option>
                                                <option value='1-Phase-Vr'>1-Phase-Vr</option>
                                                <option value='1-Phase-Vy'>1-Phase-Vy</option>
                                                <option value='1-Phase-Vb'>1-Phase-Vb</option>
                                            </select>
                                        </label>
                                        <label>
                                            <span>Primary Voltage</span>
                                            <input type="number" name="primaryvoltage" value={masterTransformerEditData.primaryvoltage} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='mastertransformerfetch-popup1'>

                                        <label>
                                            <span> Secondary Voltage</span>
                                            <input type="number" name="secondaryvoltage" value={masterTransformerEditData.secondaryvoltage} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span> KVA Rating</span>
                                            <input type="number" name="kvarating" value={masterTransformerEditData.kvarating} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='mastertransformerfetch-popup1'>

                                        <label>
                                            <span>Full Load Current</span>
                                            <input type="number" name="fullloadcurrent" value={masterTransformerEditData.fullloadcurrent} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span> Full Load Current</span>
                                            <input type="number" name="fullloadcurrent" value={masterTransformerEditData.fullloadcurrent} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='mastertransformerfetch-popup1'>

                                        <label>
                                            <span> Impedence</span>
                                            <input type="number" name="impedence" value={masterTransformerEditData.impedence} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span> Vector Type</span>
                                            <input type="text" name="vectortype" value={masterTransformerEditData.vectortype} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='mastertransformerfetch-popup1'>

                                        <label>
                                            <span> Coolant Type</span>
                                            <input type="text" name="coolanttype" value={masterTransformerEditData.coolanttype} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span> SL No</span>
                                            <input type="number" name="slno" value={masterTransformerEditData.slno} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='mastertransformerfetch-popup1'>

                                        <label>
                                            <span> IEC Standard</span>
                                            <input type="text" name="iecstandard" value={masterTransformerEditData.iecstandard} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span> ISO Standard</span>
                                            <input type="text" name="isostandard" value={masterTransformerEditData.isostandard} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='mastertransformerfetch-popup1'>


                                        <label>
                                            <span>Dimensions</span>
                                            <input type="number" name="dimentions" value={masterTransformerEditData.dimentions} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>  Weight</span>
                                            <input type="number" name="weight" value={masterTransformerEditData.weight} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='mastertransformerfetch-popup1'>


                                        <label>
                                            <span>Proposed Periodicity of Maintenance</span>
                                            <input type="text" name="proposedperiodicityofmaintenance" value={masterTransformerEditData.proposedperiodicityofmaintenance} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Previous Maintenance Done Date</span>
                                            <input type="date" name="previousmaintenancedonedate" value={masterTransformerEditData.previousmaintenancedonedate} onChange={handleEditChange} />
                                        </label>
                                    </div>


                                    <button id='mastertransformerfetch-popup-update' type="submit">Update</button>

                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Mastertransformerfetch;
