import React, { useEffect, useState } from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Higherlevelheader } from "../../Operator/Data";
import Superadminsidebar from "../../Superadmin/Sidebar/Sidebar";
import { Link } from "react-router-dom";
import "./SuperadminRegistrationfetch.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import TopBar from "../../Commonfns/Topbar/Topbar";
const SuperadminTable = () => {
  const [superadmins, setSuperadmins] = useState([]);
  const [editData, setEditData] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  useEffect(() => {
    fetchSuperadmins();

    const user = JSON.parse(localStorage.getItem("user"));
    setLoggedInUser(user);
  }, []);

  const fetchSuperadmins = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_EMS_URL}/api/superadmins`
      );
      setSuperadmins(response.data);
    } catch (error) {
      console.error("Error fetching superadmins:", error);
    }
  };

  const handleEditClick = (superadmin) => {
    setEditData(superadmin);
    setValidationErrors({});
  };

  // const handleEditChange = (e) => {
  //   const { name, value } = e.target;

  //   let updatedData = { ...editData };

  //   if (name === "username") {
  //     const usernameRegex = /^[a-z0-9]+$/; // Only lowercase letters and numbers
  //     if (!usernameRegex.test(value)) {
  //       validationErrors.username =
  //         "Username should only contain lowercase letters and numbers.";
  //     } else {
  //       delete validationErrors.username;
  //     }
  //   }

  //   // Validation for password and confirm password
  //   if (name === "password" || name === "confirmpassword") {
  //     if (name === "password" && value === editData.username) {
  //       setValidationErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [name]: "Password should not be the same as Username.",
  //       }));
  //     } else if (name === "confirmpassword" && value !== editData.password) {
  //       setValidationErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [name]: "Passwords do not match. Please re-enter.",
  //       }));
  //     } else {
  //       setValidationErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [name]: "",
  //       }));
  //     }
  //   }

  //   updatedData[name] = value;
  //   setEditData(updatedData);
  // };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...editData, [name]: value };
  
    // Update state with the new value
    setEditData(updatedData);
  
    // Reset the validation errors for the current field
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  
    // Perform validation for username
    if (name === 'username') {
      const usernameRegex = /^[a-z0-9]+$/; // Only lowercase letters and numbers
      if (!usernameRegex.test(value)) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          username: 'Username should only contain lowercase letters and numbers.',
        }));
      }
    }
  
    // Perform validation for password and confirm password
    if (name === 'password' || name === 'confirmpassword') {
      const password = name === 'password' ? value : updatedData.password;
      const confirmPassword = name === 'confirmpassword' ? value : updatedData.confirmpassword;
  
      // Check if password is same as username
      if (password === updatedData.username) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          password: 'Password should not be the same as Username.',
        }));
      }
  
      // Check if passwords match
      if (confirmPassword && confirmPassword !== password) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          confirmpassword: 'Passwords do not match. Please re-enter.',
        }));
      } else {
        // Clear confirm password error if they match
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          confirmpassword: '',
        }));
      }
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    // Check if there are any validation errors
    for (const key in validationErrors) {
      if (validationErrors[key]) {
        toast.error("Please fix all errors before submitting.");
        return;
      }
    }

    const updatedSuperadminData = {
      ...editData,
      modifiedby: loggedInUser.username, // Update modifiedby field with logged-in user's username
    };

    try {
      // console.log('Submitting Edit Data:', updatedSuperadminData);
      const response = await axios.put(
        `${process.env.REACT_APP_API_EMS_URL}/api/superadmins/${editData._id}`,
        updatedSuperadminData
      );
      // console.log('Response:', response.data);
      fetchSuperadmins();
      setEditData(null);
      toast.success("Superadmin updated successfully");
    } catch (error) {
      console.error(
        "Error updating superadmin:",
        error.response ? error.response.data : error.message
      );
      if (
        error.response &&
        error.response.data.message === "Superadmin username must be unique"
      ) {
        toast.error(
          "Username already exists. Please choose a different username."
        );
      } else {
        toast.error("Failed to update superadmin. Please try again later.");
      }
    }
  };

  const handleToggleStatus = async (id, currentStatus) => {
    console.log(
      `Toggling status for ID: ${id}, Current Status: ${currentStatus}`
    );
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_EMS_URL}/api/superadmins/${id}/toggle-status`,
        {
          status: !currentStatus,
          modifiedby: loggedInUser.username, // Update modifiedby field with logged-in user's username
        }
      );
      console.log(`Toggle status response: `, response.data);
      fetchSuperadmins();
    } catch (error) {
      console.error(
        "Error toggling status:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword((prev) => !prev);

  return (
    <div id="super-regfetch-total">
      <div>
        <Superadminsidebar />
      </div>
      <div id="super-regfetch-right">
      <TopBar section="AddsuperadminHeading" />
        {/* <div id="super-regfetch-right-1">
          <div id="super-regfetch-heading">
            {Higherlevelheader.alertHeading}
          </div>
          <div id="super-regfetch-heading1">
            {Higherlevelheader.alertActions.map((action, index) => (
              <button key={index} aria-label={action.label}>
                {action.icon}
              </button>
            ))}
            <img src={Higherlevelheader.logoSrc} alt="logo" />
          </div>
        </div> */}
        <div id="super-regfetch-right-2">
          <div id="super-regfetch-right-21">
            <label>
              <Link to="/SuperadminRegistration">
                <button>Form</button>
              </Link>
            </label>
          </div>
          <table>
            <thead>
              <tr>
                <th>Superadmin ID</th>
                <th>Designation</th>
                <th>Name</th>
                <th>Username</th>
                <th>Password</th>
                <th>Confirm Password</th>
                <th>Status</th>
                <th>Created By</th>
                <th>Created At</th>
                <th>Modified By</th>
                <th>Modified At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {superadmins.map((superadmin) => (
                <tr key={superadmin._id}>
                  <td>{superadmin.superadmin_id}</td>
                  <td>{superadmin.designation}</td>
                  <td>{superadmin.name}</td>
                  <td>{superadmin.username}</td>
                  <td>{superadmin.password}</td>
                  <td>{superadmin.confirmpassword}</td>
                  <td>
                    <button
                      onClick={() =>
                        handleToggleStatus(superadmin._id, superadmin.status)
                      }
                    >
                      {superadmin.status ? "Active" : "Inactive"}
                    </button>
                  </td>
                  <td>{superadmin.createdby}</td>
                  <td>{superadmin.createdat}</td>
                  <td>{superadmin.modifiedby}</td>
                  <td>{superadmin.modifiedat}</td>
                  <td>
                    <button onClick={() => handleEditClick(superadmin)}>
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {editData && (
            <Popup open={true} onClose={() => setEditData(null)}>
              <div id="super-regfetch-popup">
                <h3>Edit Superadmin</h3>
                <form onSubmit={handleEditSubmit}>
                  <div id="super-regfetch-popup1">
                    <label>
                      <span>Name</span>
                      <input
                        type="text"
                        name="name"
                        value={editData.name}
                        onChange={handleEditChange}
                        required
                      />
                    </label>
                    <label>
                      <span>Username</span>
                      <input
                        type="text"
                        name="username"
                        value={editData.username}
                        onChange={handleEditChange}
                        required
                      />
                      {validationErrors.username && (
                        <div className="super-regfetch-errormessage">{validationErrors.username}</div>
                      )}
                    </label>
                  </div>
                  <div id="super-regfetch-popup1">
                    <label>
                      <span>Password</span>
                      <div id="password-container">
                        <input
                          id="password-container-input"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={editData.password}
                          onChange={handleEditChange}
                          required
                        />
                        <span
                          onClick={togglePasswordVisibility}
                          className="password-toggle-icon"
                        >
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </div>

                      {validationErrors.password && (
                        <div className="super-regfetch-errormessage">{validationErrors.password}</div>
                      )}
                    </label>
                    <label>
                      <span>Confirm Password</span>
                      <div id="password-container">
                        <input
                          id="password-container-input"
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirmpassword"
                          value={editData.confirmpassword}
                          onChange={handleEditChange}
                          required
                        />
                        <span
                          onClick={toggleConfirmPasswordVisibility}
                          className="password-toggle-icon"
                        >
                          {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </div>
                      {validationErrors.confirmpassword && (
                        <div className="super-regfetch-errormessage">
                          {validationErrors.confirmpassword}
                        </div>
                      )}
                    </label>
                  </div>

                  {/* <div id="super-regfetch-popup1"></div>
                  <div id="super-regfetch-popup1"> </div> */}

                  <button id="super-regfetch-popup-update" type="submit">
                    Update
                  </button>
                </form>
              </div>
            </Popup>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuperadminTable;
