
import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaChevronRight , FaChevronUp } from "react-icons/fa";
import Stationpoints from "../../Commonfns/Stationpoints"; // StationPoint dropdown
import "./DailyConsumption.css";
import Operatorsidebar from "../Sidebar/Sidebar";
import TopBar from "../../Commonfns/Topbar/Topbar";
import { FaFileCsv } from "react-icons/fa6";
const DailyConsumption = () => {
  const [sections, setSections] = useState([]);
  const [expandedSections, setExpandedSections] = useState({});
  const [sectionFilter, setSectionFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");

  function getYesterdayDate() {
    const today = new Date();
    today.setDate(today.getDate() - 1); // Subtract one day
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    fetchData();
  }, [sectionFilter, dateFilter]);

  const fetchData = () => {
    const queryParams = new URLSearchParams();
    if (sectionFilter) queryParams.append("sectionName", sectionFilter);
    if (dateFilter) queryParams.append("date", dateFilter);

    axios
      .get(
        `${
          process.env.REACT_APP_API_EMS_URL
        }/api/sections-data?${queryParams.toString()}`
      )
      .then((response) => {
        setSections(response.data);
        if (sectionFilter) expandParents(response.data, sectionFilter); // Auto-expand parents
      })
      .catch((error) => {
        console.error("Error fetching sections data:", error);
      });
  };

  const handleStationPointChange = (selectedStationPoint) => {
    setSectionFilter(selectedStationPoint);
  };

  const handleDateChange = (event) => {
    setDateFilter(event.target.value);
  };

  const toggleSection = (sectionName) => {
    setExpandedSections((prev) => ({
      ...prev,
      [sectionName]: !prev[sectionName],
    }));
  };

  const expandParents = (allSections, childName) => {
    const findParent = (sections, child) =>
      sections.find((section) =>
        section.childs.some((c) => c.section_name === child)
      );

    let parent = findParent(allSections, childName);
    const newExpandedSections = {};

    while (parent) {
      newExpandedSections[parent.section_name] = true;
      parent = findParent(allSections, parent.section_name);
    }

    setExpandedSections((prev) => ({ ...prev, ...newExpandedSections }));
  };

  const flattenSections = (sections) => {
    const flatData = [];

    const flatten = (section, parentName = "") => {
      flatData.push({
        section_name: section.section_name,
        parent_name: parentName,
        date: section.date,
        consumption: section.value ?? "--",
      });

      section.childs.forEach((child) => flatten(child, section.section_name));
    };

    sections.forEach((section) => flatten(section));
    return flatData;
  };

  const downloadCSV = () => {
    const flatData = flattenSections(sections);

    const csvContent =
      "data:text/csv;charset=utf-8," +
      ["Section Name,Parent Name,Date,Consumption"]
        .concat(
          flatData.map(
            (row) =>
              `${row.section_name},${row.parent_name},${row.date},${row.consumption}`
          )
        )
        .join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "section_data.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
    document.body.removeChild(link);
  };

//   const renderRow = (section, level = 0) => {
//     const isExpanded = expandedSections[section.section_name];

//     return (
//       <React.Fragment key={section.section_name}>
//         <div className={`daily-consumpton-tr ${isExpanded ? "expanded" : ""}`}>
//           <div className="chevron-container">
//             {section.childs.length > 0 ? (
//               <div onClick={() => toggleSection(section.section_name)}>
//                 {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
//               </div>
//             ) : (
//               <div style={{ width: "16px" }}></div> // Empty space for alignment
//             )}
//           </div>
//           <div className="daily-consyumptoon-t1"
//           >{section.section_name}</div>
//           <div className="date-column">{section.date}</div>
//           <div className="consumption-column">{section.value ?? "--"}</div>
//         </div>
//         {isExpanded &&
//           section.childs.map((child) => renderRow(child, level + 1))}
//       </React.Fragment>
//     );
//   };

const renderRow = (section, level = 0) => {
    const isExpanded = expandedSections[section.section_name];
  
    return (
      <React.Fragment key={section.section_name}>
        <div
          className={`daily-consumpton-tr ${isExpanded ? "expanded" : ""} level-${level + 1}`}
        >
          <div className="chevron-container">
            {section.childs.length > 0 ? (
              <div onClick={() => toggleSection(section.section_name)}>
                {isExpanded ? <FaChevronUp  /> : <FaChevronRight  />}
              </div>
            ) : (
              <div style={{ width: "16px" }}></div> // Empty space for alignment
            )}
          </div>
          <div className="daily-consyumptoon-t1">{section.section_name}</div>
          <div className="date-column">{section.date}</div>
          <div className="consumption-column">{section.value ?? "--"}</div>
        </div>
        {isExpanded &&
          section.childs.map((child) => renderRow(child, level + 1))}
      </React.Fragment>
    );
  };
  

  return (
    <div id="dailyconsumption-total">
      <div>
        <Operatorsidebar />
      </div>
      <div id="dailyconsumption-right">
        <TopBar section="ConsumptionHeader" />
        <div id="dailyconsumption-right-2">
          <div id="dailyconsumption-right-21">
            <label>
              <span>Select Date:</span>
              <input
                type="date"
                onChange={handleDateChange}
                max={getYesterdayDate()}
              />
            </label>
            <label>
              <Stationpoints onStationPointChange={handleStationPointChange} />
            </label>
            <label>
              <button onClick={downloadCSV}><FaFileCsv /></button>
            </label>
          </div>
          <div id="dailyconsumption-right-22">
            <div id="dailyconsumption-right-221">
              <div className="chevron-container"></div>
              <div className="daily-consyumptoon-t1">Section Name</div>
              <div className="date-column">Date</div>
              <div className="consumption-column">kWh Consumption</div>
            </div>

            <div className="section-body">
              {sections.map((section) => renderRow(section))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyConsumption;
