
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import logo from '../../../Images/logo1.svg';
// import './Loginscreens.css';
// import { Link, useNavigate } from 'react-router-dom';

// const Loginscreens = () => {
//     const [designations, setDesignations] = useState({
//         level2: null,
//         level3: null,
//         level4: null
//     });
//     const [userLevel, setUserLevel] = useState(null);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const fetchDesignations = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/designations`);
//                 const designationsMap = response.data.reduce((acc, designation) => {
//                     if (designation.level === 'Level-2') acc.level2 = designation;
//                     if (designation.level === 'Level-3') acc.level3 = designation;
//                     if (designation.level === 'Level-4') acc.level4 = designation;
//                     return acc;
//                 }, {});

//                 setDesignations(designationsMap);
//             } catch (error) {
//                 console.error('Error fetching designations:', error);
//             }
//         };

//         const user = JSON.parse(localStorage.getItem('user'));
//         if (user && user.level) {
//             setUserLevel(user.level);
//         }

//         fetchDesignations();
//     }, []);

//     const handleLogout = () => {
//         localStorage.removeItem('user');
//         localStorage.removeItem('accessedLevel');
//         navigate('/');
//     };

//     const handleLevelAccess = (level) => {
//         localStorage.setItem('accessedLevel', level);
//     };

//     return (
//         <div id='loginscreen-totalcontainer'>
//             <div id='loginscreen-total'>
//                 <div className="loginscreenimage">
//                     <img src={logo} alt="" />
//                 </div>
//             </div>
//             <div>
//                 {userLevel ? (
//                     <div id='loginscreen-desigcard'>
//                         {/* {(userLevel === 'Level-1' || userLevel === 'Level-2') && designations.level2 && designations.level2.status && (
//                             <div id='loginscreen-desiglongcard'>
//                                 <label>{designations.level2 ? designations.level2.designation : 'Level-2'}</label>
//                                 <Link id='loginscreen-link' to='/Designation'
//                                   onClick={() => handleLevelAccess('Level-2')}>Access</Link>
//                             </div>
//                         )} */}

// {(userLevel === 'Level-1' || userLevel === 'Level-2') && (
//                             <div id='loginscreen-desiglongcard'>
//                                 <label>
//                                     {designations.level2 && designations.level2.status
//                                         ? designations.level2.designation
//                                         : 'Super Admin'}
//                                 </label>
//                                 {designations.level2 && designations.level2.status ? (
//                                     <Link id='loginscreen-link' to='/Designation' onClick={() => handleLevelAccess('Level-2')}>
//                                         Access
//                                     </Link>
//                                 ) : (
//                                     <Link id='loginscreen-link' to='/Designation' onClick={() => handleLevelAccess('Level-2')}>
//                                         Access
//                                     </Link>
//                                 )}
//                             </div>
//                         )}

//                         {(userLevel === 'Level-1' || userLevel === 'Level-2' || userLevel === 'Level-3') && (
//                             <div id='loginscreen-desigcardflex'>
//                                  {designations.level3 && designations.level3.status && ( 
//                                 <div id='loginscreen-desigcard1'>
//                                     <label>{designations.level3 ? designations.level3.designation : 'Level-3'}</label>
//                                     <Link id='loginscreen-link' to='/Alldash'
//                                       onClick={() => handleLevelAccess('Level-3')}>Access</Link>
//                                 </div>
//                                 )}
//                                  {designations.level4 && designations.level4.status && ( 
//                                 <div id='loginscreen-desigcard1'>
//                                     <label>{designations.level4 ? designations.level4.designation : 'Level-4'}</label>
//                                     <Link id='loginscreen-link' to='/Operator-Dash'
//                                       onClick={() => handleLevelAccess('Level-4')}>Access</Link>
//                                 </div>
//                                 )}
//                             </div>
//                         )}
//                     </div>
//                 ) : (
//                     <div id='loginscreen-loginprompt'>
//                         <center>Please login</center>
//                     </div>
//                 )}
//             </div>
//             {userLevel && <center id='loginscreen-exittext' onClick={handleLogout}>Exit</center>}
//         </div>
//     );
// };

// export default Loginscreens;


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logo from '../../../Images/logo1.svg';
import './Loginscreens.css';
import { Link, useNavigate } from 'react-router-dom';

const Loginscreens = () => {
    const [designations, setDesignations] = useState({
        level2: [],
        level3: [],
        level4: []
    });
    const [selectedDesignation, setSelectedDesignation] = useState({
        level2: null,
        level3: null,
        level4: null
    });
    const [userLevel, setUserLevel] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDesignations = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/designations`);
                const designationsMap = response.data.reduce((acc, designation) => {
                    if (designation.status) { // Only add active designations
                        if (!acc[designation.level]) acc[designation.level] = [];
                        acc[designation.level].push(designation);
                    }
                    return acc;
                }, {});

                setDesignations({
                    level2: designationsMap['Level-2'] || [],
                    level3: designationsMap['Level-3'] || [],
                    level4: designationsMap['Level-4'] || []
                });
            } catch (error) {
                console.error('Error fetching designations:', error);
            }
        };

        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.level) {
            setUserLevel(user.level);
        }

        fetchDesignations();
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('accessedLevel');
        navigate('/');
    };

    const handleLevelAccess = (level) => {
        localStorage.setItem('accessedLevel', level);
    };

    const handleDesignationSelect = (level, designation) => {
        setSelectedDesignation(prev => ({ ...prev, [level]: designation }));
    };

    const isAccessButtonDisabled = (level) => {
        return designations[level].length > 1 && !selectedDesignation[level];
    };

    return (
        <div id='loginscreen-totalcontainer'>
            <div id='loginscreen-total'>
                <div className="loginscreenimage">
                    <img src={logo} alt="" />
                </div>
            </div>
            <div>
                {userLevel ? (
                    <div id='loginscreen-desigcard'>
                        {(userLevel === 'Level-1' || userLevel === 'Level-2') && (
                            <div id='loginscreen-desiglongcard'>
                                {designations.level2.length > 1 ? (
                                    <select
                                        onChange={(e) => handleDesignationSelect('level2', e.target.value)}
                                        defaultValue=""
                                    >
                                        <option value="" disabled>Select Designation</option>
                                        {designations.level2.map(desig => (
                                            <option key={desig._id} value={desig.designation}>
                                                {desig.designation}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <label>
                                        {designations.level2[0]?.designation || 'Super Admin'}
                                    </label>
                                )}
                                <Link
                                    id='loginscreen-link'
                                    to='/Designation'
                                    onClick={() => handleLevelAccess('Level-2')}
                                    className={isAccessButtonDisabled('level2') ? 'disabled-link' : ''}
                                >
                                    Access
                                </Link>
                            </div>
                        )}

                        {(userLevel === 'Level-1' || userLevel === 'Level-2' || userLevel === 'Level-3') && (
                            <div id='loginscreen-desigcardflex'>
                                {designations.level3.length > 0 && (
                                    <div id='loginscreen-desigcard1'>
                                        {designations.level3.length > 1 ? (
                                            <select
                                                onChange={(e) => handleDesignationSelect('level3', e.target.value)}
                                                defaultValue=""
                                            >
                                                <option value="" disabled>Select Designation</option>
                                                {designations.level3.map(desig => (
                                                    <option key={desig._id} value={desig.designation}>
                                                        {desig.designation}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            <label>{designations.level3[0]?.designation}</label>
                                        )}
                                        <Link
                                            id='loginscreen-link'
                                            to='/Alldash'
                                            onClick={() => handleLevelAccess('Level-3')}
                                            className={isAccessButtonDisabled('level3') ? 'disabled-link' : ''}
                                        >
                                            Access
                                        </Link>
                                    </div>
                                )}
                                {designations.level4.length > 0 && (
                                    <div id='loginscreen-desigcard1'>
                                        {designations.level4.length > 1 ? (
                                            <select
                                                onChange={(e) => handleDesignationSelect('level4', e.target.value)}
                                                defaultValue=""
                                            >
                                                <option value="" disabled>Select Designation</option>
                                                {designations.level4.map(desig => (
                                                    <option key={desig._id} value={desig.designation}>
                                                        {desig.designation}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            <label>{designations.level4[0]?.designation}</label>
                                        )}
                                        <Link
                                            id='loginscreen-link'
                                            to='/Operator-Dash'
                                            onClick={() => handleLevelAccess('Level-4')}
                                            className={isAccessButtonDisabled('level4') ? 'disabled-link' : ''}
                                        >
                                            Access
                                        </Link>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    <div id='loginscreen-loginprompt'>
                        <center>Please login</center>
                    </div>
                )}
            </div>
            {userLevel && <center id='loginscreen-exittext' onClick={handleLogout}>Exit</center>}
        </div>
    );
};

export default Loginscreens;
