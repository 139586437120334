
// import React, { useEffect, useState, useRef } from "react";
// import "./Alertstable.css";
// import { FaFileCsv } from "react-icons/fa6";
// import Stationpoints from "../../Commonfns/Stationpoints";
// import { useInfiniteQuery } from "@tanstack/react-query";

// const OperatorAlertstable = () => {
//   const [section, setSection] = useState("");
//   const [fromDateTime, setFromDateTime] = useState("");
//   const [toDateTime, setToDateTime] = useState("");
//   const [hasMore, setHasMore] = useState(true);
//   const itemsPerPage = 20;

//   const tableRef = useRef(null);

//   const fetchAlerts = async ({ pageParam = 1 }) => {
//     let query = `${process.env.REACT_APP_API_EMS_URL}/api/Alertshistoydata/filter?page=${pageParam}&limit=${itemsPerPage}&`;

//     if (fromDateTime) {
//       query += `fromDate=${fromDateTime}&`;
//     }
//     if (toDateTime) {
//       query += `toDate=${toDateTime}&`;
//     }
//     if (section) {
//       query += `stationPoint=${encodeURIComponent(section)}&`;
//     }

//     const response = await fetch(query);
//     if (!response.ok) {
//       throw new Error("Failed to fetch data");
//     }
//     const result = await response.json();

//     // Set hasMore based on the API response
//     setHasMore(result.hasMore); // Ensure this corresponds with your API response structure
//     return result;
//   };

//   const {
//     data,
//     fetchNextPage,
//     hasNextPage,
//     isFetchingNextPage,
//     isLoading,
//     refetch,
//     isError,
//     error,
//   } = useInfiniteQuery({
//     queryKey: ["operator-alerts", section, fromDateTime, toDateTime],
//     queryFn: fetchAlerts,
//     getNextPageParam: (lastPage, pages) => {
//       if (!lastPage.data || lastPage.data.length === 0) {
//         return undefined; // No more pages if the last response has no data
//       }
//       return pages.length + 1; // Increment page number for next request
//     },
//   });

//   // Reset scroll position whenever filters change
//   useEffect(() => {
//     if (tableRef.current) {
//       tableRef.current.scrollTop = 0; // Reset scroll position to top
//     }
//     refetch(); // Refetch data when filters change
//   }, [section, fromDateTime, toDateTime, refetch]);

//   // Load more items when the user scrolls to the bottom of the table
//   const loadMoreItems = () => {
//     if (hasNextPage) {
//       fetchNextPage();
//     }
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       if (
//         tableRef.current &&
//         tableRef.current.scrollTop + tableRef.current.clientHeight >=
//           tableRef.current.scrollHeight
//       ) {
//         loadMoreItems();
//       }
//     };

//     const tableElement = tableRef.current;
//     if (tableElement) {
//       tableElement.addEventListener("scroll", handleScroll);
//     }

//     return () => {
//       if (tableElement) {
//         tableElement.removeEventListener("scroll", handleScroll);
//       }
//     };
//   }, [hasNextPage, fetchNextPage]);

//   // Handle the station point change
//   const handleStationPointChange = (selectedStationPoint) => {
//     setSection(selectedStationPoint);
//   };

//   const downloadCSV = async () => {
//     // Implement the CSV download logic here
//     try {
//       let query = `${process.env.REACT_APP_API_EMS_URL}/api/Alertshistoydata/export?`;
//       if (fromDateTime) query += `fromDate=${fromDateTime}&`;
//       if (toDateTime) query += `toDate=${toDateTime}&`;
//       if (section) query += `stationPoint=${encodeURIComponent(section)}&`;

//       const response = await fetch(query);
//       const blob = await response.blob();
//       const url = window.URL.createObjectURL(blob);
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = "alerts_data.csv";
//       document.body.appendChild(a);
//       a.click();
//       a.remove();
//       window.URL.revokeObjectURL(url);
//     } catch (error) {
//       console.error("Failed to download CSV:", error);
//     }
//   };

//   const renderTableBody = () => {
//     if (isLoading) {
//       return (
//         <tr>
//           <td colSpan="7">Loading...</td>
//         </tr>
//       );
//     }

//     if (isError) {
//       return (
//         <tr>
//           <td colSpan="7">Error: {error.message}</td>
//         </tr>
//       );
//     }

//     const alerts = data?.pages.flatMap((page) => page.data) || [];

//     if (alerts.length === 0) {
//       return (
//         <tr>
//           <td colSpan="7">No data found</td>
//         </tr>
//       );
//     }

//     return alerts.map((alert, index) => {
//       if (!alert || !alert.stationPoint) {
//         // Skip if alert is null or missing expected properties
//         return null;
//       }

//       return (
//         <tr key={alert._id || index}>
//           <td>{index + 1}</td>
//           <td>{alert.stationPoint}</td>
//           <td>{alert.parameterName}</td>
//           <td>{alert.eventDescription}</td>
//           <td>{alert.eventType}</td>
//           <td>{alert.eventValue}</td>
//           <td>{alert.dateTime}</td>
//         </tr>
//       );
//     });
//   };

//   useEffect(() => {
//     console.log(data); // Check the fetched data structure
//   }, [data]);

//   return (
//     <div id="opalert-total">
//       <div id="opalert-right">
//         <div id="alert-right-2">
//           <div id="alert-right-21">
//             <label>
//               <span>From Date:</span>
//               <input
//                 type="date"
//                 value={fromDateTime}
//                 onChange={(e) => setFromDateTime(e.target.value)}
//                 max={new Date().toISOString().split("T")[0]}
//               />
//             </label>
//             <label>
//               <span>To Date:</span>
//               <input
//                 type="date"
//                 value={toDateTime}
//                 onChange={(e) => setToDateTime(e.target.value)}
//                 max={new Date().toISOString().split("T")[0]}
//                 min={fromDateTime || ""}
//                 disabled={!fromDateTime}
//               />
//             </label>
//             <label>
//               <Stationpoints onStationPointChange={handleStationPointChange} />
//             </label>
//             <label>
//               <button onClick={downloadCSV}>
//                 <FaFileCsv />
//               </button>
//             </label>
//           </div>

//           <div id="alert-right-3">
//             <table ref={tableRef}>
//               <thead>
//                 <tr>
//                   <th>S. No.</th>
//                   <th>Section</th>
//                   <th>Parameter</th>
//                   <th>Description</th>
//                   <th>Type</th>
//                   <th>Value</th>
//                   <th>Date & Time</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {renderTableBody()}
//                 {isFetchingNextPage && (
//                   <tr>
//                     <td colSpan="7" style={{ textAlign: "center" }}>
//                       Loading more...
//                     </td>
//                   </tr>
//                 )}
//                {!hasMore && data?.pages.length > 0 && (
//   <tr>
//     <td colSpan="7" style={{ textAlign: "center" }}>
//       No More Data Available
//     </td>
//   </tr>
// )}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default OperatorAlertstable;



import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";
import Stationpoints from "../../Commonfns/Stationpoints";
import { FaFileCsv } from "react-icons/fa6";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import "./Alertstable.css";

const itemsPerPage = 20;

const fetchAlerts = async ({ pageParam = 1, queryKey }) => {
  const [_, section, fromDateTime, toDateTime] = queryKey;
  let query = `${process.env.REACT_APP_API_EMS_URL}/api/Alertshistoydata/filter?page=${pageParam}&limit=${itemsPerPage}`;

  if (fromDateTime) query += `&fromDate=${fromDateTime}`;
  if (toDateTime) query += `&toDate=${toDateTime}`;
  if (section) query += `&stationPoint=${encodeURIComponent(section)}`;

  const response = await fetch(query);
  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }
  return response.json();
};

const OperatorAlertstable = () => {
  const [section, setSection] = useState("");
  const [fromDateTime, setFromDateTime] = useState("");
  const [toDateTime, setToDateTime] = useState("");

  const { ref, inView } = useInView({ threshold: 0 });

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isError,
    error,
  } = useInfiniteQuery({
    queryKey: ["operator-alerts", section, fromDateTime, toDateTime],
    queryFn: fetchAlerts,
    getNextPageParam: (lastPage, pages) =>
      lastPage.data.length > 0 ? pages.length + 1 : undefined,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  const handleStationPointChange = (selectedStationPoint) => {
    setSection(selectedStationPoint);
  };

  const downloadCSV = async () => {
    try {
      let query = `${process.env.REACT_APP_API_EMS_URL}/api/Alertshistoydata/export?`;

      if (fromDateTime) query += `fromDate=${fromDateTime}&`;
      if (toDateTime) query += `toDate=${toDateTime}&`;
      if (section) query += `stationPoint=${encodeURIComponent(section)}&`;

      const response = await fetch(query);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "alerts_data.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download CSV:", error);
    }
  };

  const renderTableBody = () => {
    if (isError) {
      return (
        <tr>
          <td colSpan="7">Error: {error.message}</td>
        </tr>
      );
    }

    const alerts = data?.pages.flatMap((page) => page.data) || [];

    if (alerts.length === 0) {
      return (
        <tr>
          <td colSpan="7">No data found</td>
        </tr>
      );
    }

    return alerts.map((alert, index) => (
      <tr key={alert._id || index}>
        <td>{index + 1}</td>
        <td>{alert.stationPoint}</td>
        <td>{alert.parameterName}</td>
        <td>{alert.eventDescription}</td>
        <td>{alert.eventType}</td>
        <td>{alert.eventValue}</td>
        <td>{moment(alert.dateTime).format("YYYY-MM-DD HH:mm:ss")}</td>
      </tr>
    ));
  };

  return (
    <div id="opalert-total">
      <div id="opalert-right">
        <div id="alert-right-2">
          <div id="alert-right-21">
            <label>
              <span>From Date:</span>
              <input
                type="date"
                value={fromDateTime}
                onChange={(e) => setFromDateTime(e.target.value)}
                max={new Date().toISOString().split("T")[0]}
              />
            </label>
            <label>
              <span>To Date:</span>
              <input
                type="date"
                value={toDateTime}
                onChange={(e) => setToDateTime(e.target.value)}
                max={new Date().toISOString().split("T")[0]}
                min={fromDateTime || ""}
                disabled={!fromDateTime}
              />
            </label>
            <Stationpoints onStationPointChange={handleStationPointChange} />
            <button onClick={downloadCSV}>
              <FaFileCsv />
            </button>
          </div>

          <div id="alert-right-3">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Section</th>
                  <th>Parameter</th>
                  <th>Description</th>
                  <th>Type</th>
                  <th>Value</th>
                  <th>Date & Time</th>
                </tr>
              </thead>
              <tbody>
                {renderTableBody()}
                <tr ref={ref}>
  <td colSpan="7" style={{ textAlign: "center" }}>
    {isFetchingNextPage ? <CircularProgress /> : "No More Data Available"}
  </td>
</tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperatorAlertstable;
