import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import { Link } from 'react-router-dom';
import { Phaseimbalanceheader } from '../../../Operator/Data';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import './Loose_Connection_and_Corrosion_Formfetch.css';
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const Loose_Connection_and_Corrosion_Formfetch = () => {
    const [forms, setForms] = useState([]);
    const [formEditData, setFormEditData] = useState(null);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
      // Retrieve the logged-in user from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        setLoggedInUser(user);
      }
      setUserDesignation(user.level);
    }, []);

    const fetchForms = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/looseconnectionandcorrosionforms`);
            setForms(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchForms();
    }, []);

    const handleEditClick = (form) => {
        setFormEditData(form);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setFormEditData({ ...formEditData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/looseconnectionandcorrosionforms/${formEditData._id}`, formEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchForms();
            setFormEditData(null);
            alert("Form updated successfully");
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='loose_connection_and_corrosion_formfetch-total'>
            <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />} 
            </div>
            <div id='loose_connection_and_corrosion_formfetch-right'>
                {/* Additional UI elements based on your specific design */}
                <div id='loose_connection_and_corrosion_formfetch-right-1'>
                    <div id='loose_connection_and_corrosion_formfetch-heading'>
                        {Phaseimbalanceheader.alertHeading}
                    </div>
                    <div id='loose_connection_and_corrosion_formfetch-heading1'>
                        {Phaseimbalanceheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Phaseimbalanceheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <div id='loose_connection_and_corrosion_formfetch-right-2'>
                    <div id='loose_connection_and_corrosion_formfetch-right-21'>
                    <label>
                                <span style={{fontWeight:'bold'}} >Loose Connection and Corrosion Monitor</span>
                            </label>
                        {/* <label>
                            <Link to='/Loose_Connection_and_Corrosion_Form'><button>Form</button></Link> 
                        </label> */}
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>Date and Time</th>
                                <th>Tocken ID</th>
                                <th>Section Name / Location</th>
                                <th>Loose connection or corrosions (Normal/severe)</th>
                                {/* <th>Created By</th>
                                <th>Created At</th>
                                <th>Modified By</th>
                                <th>Modified At</th> */}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {forms.map((form) => (
                                <tr key={form._id}>
                                    <td>{form.dateandtime}</td>
                                    <td>{form.token_id}</td>
                                    <td>{form.section_name_location}</td>
                                    <td>{form.loose_connection_or_corrosions_normal_or_severe}</td>
                                    {/* <td>{form.createdby}</td>
                                    <td>{form.createdat}</td>
                                    <td>{form.modifiedby}</td>
                                    <td>{form.modifiedat}</td> */}
                                    <td>
                                        <button onClick={() => handleEditClick(form)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {formEditData && (
                        <Popup open={true} onClose={() => setFormEditData(null)}>
                            <div id='loose_connection_and_corrosion_formfetch-popup'>
                                <h3>Edit Loose Connection and Corrosion Form</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='loose_connection_and_corrosion_formfetch-popup1'>
                                        <label>
                                            <span>Token ID</span>
                                            <input type="text" name="token_id" value={formEditData.token_id} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Date and Time</span>
                                            <input type="text" name="dateandtime" value={formEditData.dateandtime} onChange={handleEditChange} disabled />
                                        </label>

                                    </div>
                                    <div id='loose_connection_and_corrosion_formfetch-popup1'>
                                        <label>
                                            <span>Section Name / Location <span id='all-mandetorymark'>*</span></span>
                                            <input type="text" name="section_name_location" value={formEditData.section_name_location} onChange={handleEditChange} required />
                                        </label>
                                        <label>
                                            <span>Loose connection or corrosions (Normal/severe)<span id='all-mandetorymark'>*</span></span>
                                            <select name="loose_connection_or_corrosions_normal_or_severe" value={formEditData.loose_connection_or_corrosions_normal_or_severe} onChange={handleEditChange} required >
                                                <option value="YES">YES</option>
                                                <option value="NO">NO</option>
                                            </select>
                                        </label>
                                    </div>
                                    {/* <div id='loose_connection_and_corrosion_formfetch-popup1'>

                                        <label>
                                            <span>Created By</span>
                                            <input type="text" name="createdby" value={formEditData.createdby} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Created At</span>
                                            <input type="text" name="createdat" value={formEditData.createdat} onChange={handleEditChange} disabled />
                                        </label>

                                    </div>
                                    <div id='loose_connection_and_corrosion_formfetch-popup1'>

                                        <label>
                                            <span>Modified By</span>
                                            <input type="text" name="modifiedby" value={formEditData.modifiedby} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Modified At</span>
                                            <input type="text" name="modifiedat" value={formEditData.modifiedat} onChange={handleEditChange} disabled />
                                        </label>
                                    </div> */}
                                    <button id='loose_connection_and_corrosion_formfetch-popup-update' type="submit">Update</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Loose_Connection_and_Corrosion_Formfetch;
