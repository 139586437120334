import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import './Groundingpointsfetch.css'
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const GroundingpointsFetch = ({ onFormButtonClick }) => {
    const [groundingpoints, setGroundingpoints] = useState([]);
    const [groundingpointseditData, setGroundingpointsEditData] = useState(null);

    const fetchGroundingpoints = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/groundingpoints`);
            setGroundingpoints(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchGroundingpoints();
    }, []);

    const handleEditClick = (groundingpoint) => {
        setGroundingpointsEditData(groundingpoint);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setGroundingpointsEditData({ ...groundingpointseditData, [name]: value });
    };

    //   const validateData = (data) => {
    //     // Add validation logic based on your requirements
    //     if (!data.groundingPointId || !data.location || !data.locationNumber || !data.resistance || !data.periodicityOfMaintenance || !data.previousMaintenanceDate) {
    //       return false;
    //     }
    //     return true;
    //   };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        // if (!validateData(groundingpointseditData)) {
        //   console.error('Invalid data:', groundingpointseditData);
        //   return;
        // }
        console.log('Updating data:', groundingpointseditData);
        toast.success("Grounding Points updated sucessfully")
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/groundingpoints/${groundingpointseditData._id}`, groundingpointseditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchGroundingpoints();
            setGroundingpointsEditData(null);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (


        <div id='groundingpointsfetch-total'>

            <div id='groundingpointsfetch-right'>

                <div id='groundingpointsfetch-right-2' >

                    <div id='groundingpointsfetch-right-21'>
                        <label>
                            <span> {Masterformsheadingsss.GroundingpointHeading}</span>
                        </label>
                        <label>
                            <button onClick={onFormButtonClick}>{Masterformsheadingsss.Formheading}</button>
                        </label>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Location</th>
                                <th>Location Number</th>
                                <th>Resistance</th>
                                <th>Periodicity of Maintenance</th>
                                <th>Previous Maintenance Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groundingpoints.map((gp) => (
                                <tr key={gp._id}>
                                    <td>{gp.date}</td>
                                    <td>{gp.location}</td>
                                    <td>{gp.locationNumber}</td>
                                    <td>{gp.resistance}</td>
                                    <td>{gp.periodicityOfMaintenance}</td>
                                    <td>{gp.previousMaintenanceDate}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(gp)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {groundingpointseditData && (
                        <Popup open={true} closeOnDocumentClick onClose={() => setGroundingpointsEditData(null)}>
                            <div id='groundingpointsfetch-popup'>
                                <h3>Edit Grounding Point</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='groundingpointsfetch-popup1'>
                                        <label>
                                            <span>Date</span>
                                            <input name="date" value={groundingpointseditData.date} disabled />
                                        </label>
                                        <label>
                                            <span>Location</span>
                                            <input name="location" value={groundingpointseditData.location} onChange={handleEditChange} required />
                                        </label>
                                    </div>

                                    <div id='groundingpointsfetch-popup1'>
                                        <label>
                                            <span>Location number</span>
                                            <input name="locationNumber" value={groundingpointseditData.locationNumber} onChange={handleEditChange} required />
                                        </label>
                                        <label>
                                            <span>Resistance</span>
                                            <input type='number' name="resistance" value={groundingpointseditData.resistance} onChange={handleEditChange} required />
                                        </label>
                                    </div>

                                    <div id='groundingpointsfetch-popup1'>
                                        <label>
                                            <span>Proposed periodicity of maintenance</span>
                                            <input name="periodicityOfMaintenance" value={groundingpointseditData.periodicityOfMaintenance} onChange={handleEditChange} required />
                                        </label>
                                        <label>
                                            <span>Previous maintenance done date</span>
                                            <input type="date" name="previousMaintenanceDate" value={groundingpointseditData.previousMaintenanceDate} onChange={handleEditChange} required />
                                        </label>
                                    </div>
                                    <button id='groundingpointsfetch-popup-update' type="submit">{Masterformsheadingsss.Updateheading}</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default GroundingpointsFetch;
