// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const EnergyConsumption = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/energyconsumptiondepartment`);
//         setData(response.data);
//         setLoading(false);
//       } catch (error) {
//         setError(error);
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error.message}</div>;
//   }

//   return (
//     <div>
//       <h1>Energy Consumption Department Data</h1>
//       <table>
//         <thead>
//           <tr>
//             <th>Date</th>
//             <th>Station Point</th>
//             <th>Bill With Charges</th>
//             <th>Bill Without Charges</th>
//             <th>Fixed Charges</th>
//           </tr>
//         </thead>
//         <tbody>
//           {data.map((item) => (
//             <tr key={item._id}>
//               <td>{item.date}</td>
//               <td>{item.stationPoint}</td>
//               <td>{item.Bill_with_charges}</td>
//               <td>{item.Bill_without_charges}</td>
//               <td>{item.Fixed_charges}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default EnergyConsumption;


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const EnergyConsumption = () => {
//   const [deptData, setDeptData] = useState([]);
//   const [softData, setSoftData] = useState([]);
//   const [loadingDept, setLoadingDept] = useState(true);
//   const [loadingSoft, setLoadingSoft] = useState(true);
//   const [errorDept, setErrorDept] = useState(null);
//   const [errorSoft, setErrorSoft] = useState(null);

//   useEffect(() => {
//     const fetchDeptData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/energyconsumptiondepartment`);
//         setDeptData(response.data);
//         setLoadingDept(false);
//       } catch (error) {
//         setErrorDept(error);
//         setLoadingDept(false);
//       }
//     };

//     const fetchSoftData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/energyconsumptionsoftware`);
//         setSoftData(response.data);
//         setLoadingSoft(false);
//       } catch (error) {
//         setErrorSoft(error);
//         setLoadingSoft(false);
//       }
//     };

//     fetchDeptData();
//     fetchSoftData();
//   }, []);

//   if (loadingDept || loadingSoft) {
//     return <div>Loading...</div>;
//   }

//   if (errorDept || errorSoft) {
//     return <div>Error: {errorDept?.message || errorSoft?.message}</div>;
//   }

//   return (
//     <div>
//       <h1>Energy Consumption Department Data</h1>
//       <table>
//         <thead>
//           <tr>
//             <th>Station Point</th>
//             <th>Bill With Charges</th>
//             <th>Bill Without Charges</th>
//             <th>Fixed Charges</th>
//           </tr>
//         </thead>
//         <tbody>
//           {deptData.map((item) => (
//             <tr key={item._id}>
//               <td>{item.date}</td>
//               <td>{item.Bill_with_charges}</td>
//               <td>{item.Bill_without_charges}</td>
//               <td>{item.Fixed_charges}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       <h1>Energy Consumption Software Data</h1>
//       <table>
//         <thead>
//           <tr>
//             <th>Date</th>
//             <th>Bill With Charges</th>
//             <th>Bill Without Charges</th>
//             <th>Fixed Charges</th>
//           </tr>
//         </thead>
//         <tbody>
//           {softData.map((item) => (
//             <tr key={item._id}>
//               <td>{item.date}</td>
//               <td>{item.Bill_with_charges}</td>
//               <td>{item.Bill_without_charges}</td>
//               <td>{item.Fixed_charges}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default EnergyConsumption;


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';

const EnergyConsumption = () => {
  const [deptData, setDeptData] = useState([]);
  const [softData, setSoftData] = useState([]);
  const [loadingDept, setLoadingDept] = useState(true);
  const [loadingSoft, setLoadingSoft] = useState(true);
  const [errorDept, setErrorDept] = useState(null);
  const [errorSoft, setErrorSoft] = useState(null);

  useEffect(() => {
    const fetchDeptData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/energyconsumptiondepartment`);
        setDeptData(response.data);
        setLoadingDept(false);
      } catch (error) {
        setErrorDept(error);
        setLoadingDept(false);
      }
    };

    const fetchSoftData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/energyconsumptionsoftware`);
        setSoftData(response.data);
        setLoadingSoft(false);
      } catch (error) {
        setErrorSoft(error);
        setLoadingSoft(false);
      }
    };

    fetchDeptData();
    fetchSoftData();
  }, []);

  useEffect(() => {
    if (!loadingDept && !loadingSoft) {
      // Combine data and prepare chart
      const chartDom = document.getElementById('main');
      const myChart = echarts.init(chartDom);

      const dates = deptData.map(item => item.date);
      const deptBillsWithCharges = deptData.map(item => item.Bill_with_charges);
      const deptBillsWithoutCharges = deptData.map(item => item.Bill_without_charges);
      const deptFixedCharges = deptData.map(item => item.Fixed_charges);

      const softBillsWithCharges = softData.map(item => item.Bill_with_charges);
      const softBillsWithoutCharges = softData.map(item => item.Bill_without_charges);
      const softFixedCharges = softData.map(item => item.Fixed_charges);

      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: (params) => {
            const deptIndex = deptData.findIndex(item => item.date === params[0].axisValue);
            const softIndex = softData.findIndex(item => item.date === params[0].axisValue);
            const deptBillWithCharges = deptIndex !== -1 ? deptData[deptIndex].Bill_with_charges : 0;
            const softBillWithCharges = softIndex !== -1 ? softData[softIndex].Bill_with_charges : 0;
            return `
              <strong>Department Bill With Charges: ${deptBillWithCharges}</strong><br/>
              ${params[0].marker} Department Bill Without Charges: ${deptBillsWithoutCharges[deptIndex]}<br/>
              ${params[1].marker} Department Fixed Charges: ${deptFixedCharges[deptIndex]}<br/>
              <strong>Software Bill With Charges: ${softBillWithCharges}</strong><br/>
              ${params[2].marker} Software Bill Without Charges: ${softBillsWithoutCharges[softIndex]}<br/>
              ${params[3].marker} Software Fixed Charges: ${softFixedCharges[softIndex]}
            `;
          }
        },
        legend: {
          data: ['Department Bill Without Charges', 'Department Fixed Charges', 'Software Bill Without Charges', 'Software Fixed Charges']
        },
        xAxis: {
          type: 'category',
          data: dates
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Department Bill Without Charges',
            type: 'bar',
            stack: 'Department',
            data: deptBillsWithoutCharges,
            itemStyle: {
              color: '#61a0a8'  // Custom color for Department Bill Without Charges
            },
            barWidth: 30,  // Adjust bar width here
            label: {
              show: true,
              position: 'inside',
              formatter: '{c}'
            }
          },
          {
            name: 'Department Fixed Charges',
            type: 'bar',
            stack: 'Department',
            data: deptFixedCharges,
            itemStyle: {
              color: '#d48265'  
            },
            barWidth: 30,
            label: {
              show: true,
              position: 'inside',
              formatter: '{c}'
            }
          },
          {
            name: 'Software Bill Without Charges',
            type: 'bar',
            stack: 'Software',
            data: softBillsWithoutCharges,
            itemStyle: {
              color: '#91c7ae'  
            },
            barWidth: 30,
            label: {
              show: true,
              position: 'inside',
              formatter: '{c}'
            }
          },
          {
            name: 'Software Fixed Charges',
            type: 'bar',
            stack: 'Software',
            data: softFixedCharges,
            itemStyle: {
              color: '#f39c12'  
            },
            barWidth: 30,
            label: {
              show: true,
              position: 'inside',
              formatter: '{c}'
            }
          }
        ]
      };

      myChart.setOption(option);
    }
  }, [loadingDept, loadingSoft, deptData, softData]);

  if (loadingDept || loadingSoft) {
    return <div>Loading...</div>;
  }

  if (errorDept || errorSoft) {
    return <div>Error: {errorDept?.message || errorSoft?.message}</div>;
  }

  return (
    <div>
      <div id="main" style={{ width: '100%', height: '500px' }}></div>
    </div>
  );
};

export default EnergyConsumption;
