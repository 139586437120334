import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import './LVformfetch.css'
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const LVformFetch = ({ onFormButtonClick }) => {
    const [lvforms, setLvforms] = useState([]);
    const [lvformseditData, setLvformsEditData] = useState(null);

    const [parentNames, setParentNames] = useState([]);


    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-transformerNames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching incomer names', error);
        }
    };

    // Fetch incomer names on component mount
    useEffect(() => {
        fetchParentNames();
    }, []);

    const fetchlvforms = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/lvforms`);
            setLvforms(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchlvforms();
    }, []);

    const handleEditClick = (hvform) => {
        setLvformsEditData(hvform);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setLvformsEditData({ ...lvformseditData, [name]: value });
    };

    //   const validateData = (data) => {
    //     // Add validation logic based on your requirements
    //     if (!data.groundingPointId || !data.location || !data.locationNumber || !data.resistance || !data.periodicityOfMaintenance || !data.previousMaintenanceDate) {
    //       return false;
    //     }
    //     return true;
    //   };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        console.log('Updating data:', lvformseditData);
        toast.success("Grounding Points updated sucessfully")
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/LVforms/${lvformseditData._id}`, lvformseditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchlvforms();
            setLvformsEditData(null);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (


        <div id='lvformsfetch-total'>
            <div id='lvformsfetch-right'>
                <div id='lvformsfetch-right-2' >
                    <div id='lvformsfetch-right-21'>
                        <label>
                            {Masterformsheadingsss.LVHeading}
                        </label>
                        <label>
                            <button onClick={onFormButtonClick}>{Masterformsheadingsss.Formheading}</button>
                        </label>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Parent Name</th>
                                <th>Name</th>
                                <th>Meter Make</th>
                                <th>Meter SL.no</th>
                                <th>Phase</th>
                                <th>Connected load in VA</th>
                                <th>Threshold load</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lvforms.map((hv) => (
                                <tr key={hv._id}>
                                    <td>{hv.date}</td>
                                    <td>{hv.parentname}</td>
                                    <td>{hv.name}</td>
                                    <td>{hv.metermake}</td>
                                    <td>{hv.meterslno}</td>
                                    <td>{hv.phase}</td>
                                    <td>{hv.connectionloadinva}</td>
                                    <td>{hv.thresholdload}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(hv)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {lvformseditData && (
                        <Popup open={true} closeOnDocumentClick onClose={() => setLvformsEditData(null)}>
                            <div id='lvformsfetch-popup'>
                                <h3>Edit LV form</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='lvformsfetch-popup1'>
                                        <label>
                                            <span>Date</span>
                                            <input name="date" value={lvformseditData.date} disabled />
                                        </label>
                                        <label>
                                            <span>Parent Name</span>
                                            <select name="parentname" value={lvformseditData.parentname} onChange={handleEditChange} >
                                                <option value="">Select Parent Name</option>
                                                {parentNames.map((name, index) => (
                                                    <option key={index} value={name}>{name}</option>
                                                ))}
                                            </select>
                                        </label>
                                    </div>

                                    <div id='lvformsfetch-popup1'>
                                        <label>
                                            <span>name</span>
                                            <input name="name" value={lvformseditData.name} onChange={handleEditChange} required />
                                        </label>

                                        <label>
                                            <span>metermake</span>
                                            <input name="metermake" value={lvformseditData.metermake} onChange={handleEditChange} required />
                                        </label>
                                    </div>

                                    <div id='lvformsfetch-popup1'>
                                        <label>
                                            <span>meterslno</span>
                                            <input type="meterslno" name="meterslno" value={lvformseditData.meterslno} onChange={handleEditChange} required />
                                        </label>
                                        <label>
                                            <span>Phase</span>
                                            <select name="phase" value={lvformseditData.phase} onChange={handleEditChange} required>
                                                <option value=''>Select Phase</option>
                                                <option value='3-Phase'>3-Phase</option>
                                                <option value='1-Phase-Vr'>1-Phase-Vr</option>
                                                <option value='1-Phase-Vy'>1-Phase-Vy</option>
                                                <option value='1-Phase-Vb'>1-Phase-Vb</option>
                                            </select>
                                        </label>

                                    </div>
                                    <div id='lvformsfetch-popup1'>
                                        <label>
                                            <span>thresholdload</span>
                                            <input type='number' name="thresholdload" value={lvformseditData.thresholdload} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>connectionloadinva</span>
                                            <input type='number' name="connectionloadinva" value={lvformseditData.connectionloadinva} onChange={handleEditChange} required />
                                        </label>
                                    </div>
                                    <button id='lvformsfetch-popup-update' type="submit">{Masterformsheadingsss.Updateheading}</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LVformFetch;
