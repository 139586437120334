
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Supervisorsidebar from '../../Supervisor/Supersidebar/Supersidebar';
import './Voltagediagnose.css';
import { RevenuelossHeader } from '../../Operator/Data';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FaFilePdf } from "react-icons/fa6";
import TopBar from '../../Commonfns/Topbar/Topbar';
import Operatorsidebar from '../../Operator/Sidebar/Sidebar';

const Voltagediagnose = () => {
  const location = useLocation();
  const { selectedMonth } = location.state || { selectedMonth: null };
  const [voltageData, setVoltageData] = useState([]);
  const [conditionVoltageData, setConditionVoltageData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [accessedLevel, setAccessedLevel] = useState(null);

  useEffect(() => {
    // Fetch the accessed level from localStorage
    const level = localStorage.getItem('accessedLevel');
    setAccessedLevel(level);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/revenue-voltagediagnose`);
        setVoltageData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchConditionVoltageData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/unclosedvoltage`);
        setConditionVoltageData(response.data);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
    fetchConditionVoltageData();
  }, []);

  const filterByMonth = (data) => {
    if (!selectedMonth) return data;
    const selectedYearMonth = selectedMonth.split('-').join('-');
    console.log('Filtering data by month:', selectedYearMonth);
    return data.filter(item => {
      const itemMonth = item.date.slice(0, 7);
      return itemMonth === selectedYearMonth;
    });
  };

  const filteredVoltageData = filterByMonth(voltageData);
  const filteredConditionVoltageData = filterByMonth(conditionVoltageData);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleDiagnoseClick = (token_id) => {
    console.log("Navigating to diagnose page for token ID:", token_id);
    navigate(`/Diagnose/${token_id}`);
  };

  const handleAcknowledge = async (id) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/revenue-voltagediagnose/${id}/ack`);
      if (response.status === 200) {
        setVoltageData(voltageData.map(item => item._id === id ? { ...item, ack: "Acknowledged" } : item));
      }
      alert("Acknowledged Sucessfully")
    } catch (error) {
      console.error('Error acknowledging the record:', error);
    }
  };

  const handleSaveAsPDF = () => {
    const input = document.getElementById('voltagediagnose-right');
    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pdfWidth;
      const imgHeight = canvas.height * pdfWidth / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save(`voltagerevenueloss-Diagnose.pdf`);
    }).catch(error => {
      console.error('Error generating PDF:', error);
    });
  };

  return (

    <div id='voltagediagnose-total'>
      <div>
        {accessedLevel === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />}
      </div>
      <div id='voltagediagnose-right'>
        <TopBar section="PowersurgeHeading" />
        <h3 id='revenueloss-topbarheading'> {RevenuelossHeader.RevenuelossHeading1} </h3>
        <div id='voltagediagnose-right-2'>
          {filteredConditionVoltageData.length > 0 ? (
            filteredConditionVoltageData.map((item, index) => (
              <div id='voltagediagnose-right-21' key={index}>
                <label>
                  <strong>Token ID:</strong>
                  <span>{item.token_id}</span>
                </label>
                <label>
                  <strong>Date:</strong>
                  <span>{item.date}</span>
                </label>
                <label>
                  <strong>Condition:</strong>
                  <span>{item.condition}</span>
                </label>
                <label>
                  <button onClick={() => handleDiagnoseClick(item.token_id)}><abbr title={RevenuelossHeader.Revenuelossheading4}> {RevenuelossHeader.Revenuelossbuttonname}</abbr></button>
                </label>
              </div>
            ))
          ) : (
            <p>{RevenuelossHeader.Revenuelossheading3}</p>
          )}

          <h3> {RevenuelossHeader.RevenuelossHeading2} </h3>
          <div id='voltagediagnose-right-3'>
            {filteredVoltageData.length > 0 ? (
              filteredVoltageData.map((item, index) => (
                <div id='voltagediagnose-right-3-1' key={index}>
                  <label>
                    <strong>Date:</strong>
                    <span>{item.date}</span>
                  </label>
                  <label>
                    <strong>Suggestion:</strong>
                    <span>{item.suggestion}</span>
                  </label>
                  <label>
                    <button
                      onClick={() => handleAcknowledge(item._id)}
                      disabled={item.ack === "Acknowledged"}
                      className={item.ack === "Acknowledged" ? 'acknowledged' : ''}
                    >
                      {item.ack === "Acknowledged" ? "Acknowledged" : "Acknowledge"}
                    </button>
                    {/* <button onClick={() => handleAcknowledge(item._id)} disabled={item.ack === "Acknowledged"}>{item.ack}</button> */}
                  </label>
                </div>
              ))
            ) : (
              <p>N/A</p>
            )}
          </div>
          <div id='diagnosepage-pdfbtn'>
            <button onClick={handleSaveAsPDF}><FaFilePdf /></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Voltagediagnose;




