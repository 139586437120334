import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LighteningArrestors.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LightingArrestor = ({ onViewData }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    const initialFormData = {
        date: getCurrentDate(),
        lightingarresterid: '',
        location: '',
        locationnumber: '',
        typeofarrestor: '',
        groundpointnumber: '',
        voltagerating: '',
        currentrating: '',
        responsetime: '',
        groundresistance: '',
        proposedperiodicityofmaintenance: '',
        previousmaintenancedonedate: '',
        spare1: '',
        spare2: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    // const [parentNames, setParentNames] = useState([]);

    // const fetchParentNames = async () => {
    //     try {
    //         const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/names`);
    //         const fetchedParentNames = response.data.map(item => item.name);
    //         setParentNames(fetchedParentNames);
    //     } catch (error) {
    //         console.error('Error fetching parent names', error);
    //     }
    // };

    // useEffect(() => {
    //     fetchParentNames();
    // }, []);

    const generatelightingarrestorID = () => {
        const randomNum = Math.floor(Math.random() * 999999) + 1;
        const paddedNum = String(randomNum).padStart(6, '0');
        return `LA-${paddedNum}`;
    };

    console.log(generatelightingarrestorID());

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const lightingarrestorsid = generatelightingarrestorID();
            const dataToSend = {
                lightingarresterid: lightingarrestorsid,
                ...formData,

                groundpointnumber: Number(formData.groundpointnumber),
                voltagerating: Number(formData.voltagerating),
                currentrating: Number(formData.currentrating),
                responsetime: Number(formData.responsetime),
                groundresistance: Number(formData.groundresistance),
            };

            await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/lightingarrestors`, dataToSend);

            toast.success('Lighting Arrestor added successfully');
            setFormData(initialFormData);
            // fetchParentNames();
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    return (
        <div id='lightingarrester-total'>
            <div id='lightingarrester-right'>
                <div id='lightingarrester-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='lightingarrester-divtop'>
                            <label>
                                <span> {Masterformsheadingsss.LightingArrestorHeading}</span>
                            </label>
                            <label>
                                <button type="button" onClick={onViewData}>View Data</button>
                            </label>
                        </div>
                        <div id='lightingarrester-div1'>
                            <label>
                                <span>Date</span>
                                <input type='date' name="date" value={formData.date} disabled />
                            </label>
                            <label>
                                <span>Location</span>
                                <input name="location" value={formData.location} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='lightingarrester-div1'>

                            <label>
                                <span>Location Number</span>
                                <input name="locationnumber" value={formData.locationnumber} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Type of Arrestor</span>
                                <select name="typeofarrestor" value={formData.typeofarrestor} onChange={handleChange} required>
                                    <option value=''>Select Type of Arrestor</option>
                                    <option value='ROD'>ROD</option>
                                    <option value='Cable'>Cable</option>
                                    <option value='Station Class'>Station Class</option>
                                </select>
                                {/* <input name="typeofarrestor" value={formData.typeofarrestor} onChange={handleChange} required /> */}
                            </label>
                        </div>
                        <div id='lightingarrester-div1'>

                            <label>
                                <span>Ground Point Number</span>
                                <input type='number' name="groundpointnumber" value={formData.groundpointnumber} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Voltage Rating</span>
                                <input type='number' name="voltagerating" value={formData.voltagerating} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='lightingarrester-div1'>

                            <label>
                                <span>Current Rating</span>
                                <input type='number' name="currentrating" value={formData.currentrating} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Response Time</span>
                                <input type='number' name="responsetime" value={formData.responsetime} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='lightingarrester-div1'>

                            <label>
                                <span>Ground Resistance</span>
                                <input type='number' name="groundresistance" value={formData.groundresistance} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Proposed Periodicity of Maintenance</span>
                                <input name="proposedperiodicityofmaintenance" value={formData.proposedperiodicityofmaintenance} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='lightingarrester-div1'>

                            <label>
                                <span>Previous Maintenance Done Date</span>
                                <input type='date' name="previousmaintenancedonedate" value={formData.previousmaintenancedonedate} onChange={handleChange} required />
                            </label>
                            {/* <label>
                                <span>Spare 1</span>
                                <input name="spare1" value={formData.spare1} onChange={handleChange} />
                            </label> */}
                        </div>
                        <div id='lightingarrester-divbottom'>

                            <label>
                                <button id='lightingarrester-save' type="submit">{Masterformsheadingsss.Submitheading}</button>

                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default LightingArrestor;
