import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import './Generatorsfetch.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Mastergeneratorfetch = ({ onFormButtonClick }) => {
    const [masterGenerators, setMasterGenerators] = useState([]);
    const [masterGeneratorEditData, setMasterGeneratorEditData] = useState(null);
    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-transformerlvNames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    const fetchMasterGenerators = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/mastergenerators`);
            setMasterGenerators(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchMasterGenerators();
    }, []);

    const handleEditClick = (masterGenerator) => {
        setMasterGeneratorEditData(masterGenerator);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setMasterGeneratorEditData({ ...masterGeneratorEditData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        console.log('Updating data:', masterGeneratorEditData);
        toast.success("Master Generator updated successfully");
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/mastergenerators/${masterGeneratorEditData._id}`, masterGeneratorEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchMasterGenerators();
            setMasterGeneratorEditData(null);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='mastergeneratorfetch-total'>
            <div id='mastergeneratorfetch-right'>

                <div id='mastergeneratorfetch-right-2'>
                    <div id='mastergeneratorfetch-right-21'>
                        <label>
                            <span> {Masterformsheadingsss.GeneratorHeading}</span>

                        </label>
                        <label>
                            <button onClick={onFormButtonClick}>{Masterformsheadingsss.Formheading}</button>
                        </label>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Parent Name</th>
                                <th>Name</th>
                                <th>Type of Generator</th>
                                <th>Meter Make</th>
                                <th>Meter SL No</th>
                                <th>Phase</th>
                                <th>Generator Power (kVA)</th>
                                <th>Voltage Level</th>
                                <th>Frequency</th>
                                <th>Engine Power</th>
                                <th>Cooling System</th>
                                <th>Fuel Consumption per Hour</th>
                                <th>Fuel Consumption per kWh</th>
                                <th>Start Type</th>
                                <th>Proposed Periodicity of Maintenance</th>
                                <th>Previous Maintenance Done Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {masterGenerators.map((masterGenerator) => (
                                <tr key={masterGenerator._id}>
                                    <td>{masterGenerator.date}</td>
                                    <td>{masterGenerator.parentname}</td>
                                    <td>{masterGenerator.name}</td>
                                    <td>{masterGenerator.typeofgenerator}</td>
                                    <td>{masterGenerator.metermake}</td>
                                    <td>{masterGenerator.meterslno}</td>
                                    <td>{masterGenerator.phase}</td>
                                    <td>{masterGenerator.generatorpowerinva}</td>
                                    <td>{masterGenerator.voltagelevel}</td>
                                    <td>{masterGenerator.frequency}</td>
                                    <td>{masterGenerator.enginepower}</td>
                                    <td>{masterGenerator.coolingsystem}</td>
                                    <td>{masterGenerator.fuelconsumptionperhour}</td>
                                    <td>{masterGenerator.fuelcosumptionper1kwh}</td>
                                    <td>{masterGenerator.starttype}</td>
                                    <td>{masterGenerator.proposedperiodicityofmaintenance}</td>
                                    <td>{masterGenerator.previousmaintenancedonedate}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(masterGenerator)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {masterGeneratorEditData && (
                        <Popup open={true} onClose={() => setMasterGeneratorEditData(null)}>
                            <div id='mastergeneratorfetch-popup'>
                                <h3>Edit Generator</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='mastergeneratorfetch-popup1'>
                                        <label>
                                            <span>Date</span>
                                            <input name="date" value={masterGeneratorEditData.date} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Parent Name</span>
                                            <select name="parentname" value={masterGeneratorEditData.parentname} onChange={handleEditChange}>
                                                <option value="">Select Parent Name</option>
                                                {parentNames.map((name) => (
                                                    <option key={name} value={name}>{name}</option>
                                                ))}
                                            </select>
                                        </label>


                                    </div>
                                    <div id='mastergeneratorfetch-popup1'>
                                        <label>
                                            <span>Name</span>
                                            <input type="text" name="name" value={masterGeneratorEditData.name} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Type of Generator</span>
                                            <select name="typeofgenerator" value={masterGeneratorEditData.typeofgenerator} onChange={handleEditChange}>
                                                <option value=''>Select type of generator</option>
                                                <option value='Solar'>Solar</option>
                                                <option value='Diesel Generator (DG)'>Diesel Generator (DG) </option>
                                                <option value='Waste Heat Recovery (WHR)'>Waste Heat Recovery (WHR)</option>
                                                <option value='Wind'>Wind</option>
                                                <option value='Hydro'>Hydro</option>
                                                <option value='Bio energy'>Bio energy</option>
                                            </select>
                                            {/* <input type="text" name="typeofgenerator" value={masterGeneratorEditData.typeofgenerator} onChange={handleEditChange} /> */}
                                        </label>
                                    </div>
                                    <div id='mastergeneratorfetch-popup1'>
                                        <label>
                                            <span>Meter Make</span>
                                            <input type="text" name="metermake" value={masterGeneratorEditData.metermake} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Meter SL No</span>
                                            <input type="number" name="meterslno" value={masterGeneratorEditData.meterslno} onChange={handleEditChange} />
                                        </label>


                                    </div>
                                    <div id='mastergeneratorfetch-popup1'>
                                        <label>
                                            <span>Phase</span>
                                            <select name="phase" value={masterGeneratorEditData.phase} onChange={handleEditChange} required>
                                                <option value=''>Select Phase</option>
                                                <option value='3-Phase'>3-Phase</option>
                                                <option value='1-Phase-Vr'>1-Phase-Vr</option>
                                                <option value='1-Phase-Vy'>1-Phase-Vy</option>
                                                <option value='1-Phase-Vb'>1-Phase-Vb</option>
                                            </select>
                                        </label>
                                        <label>
                                            <span>Generator Power (kVA)</span>
                                            <input type="number" name="generatorpowerinva" value={masterGeneratorEditData.generatorpowerinva} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='mastergeneratorfetch-popup1'>
                                        <label>
                                            <span>Voltage Level</span>
                                            <input type="number" name="voltagelevel" value={masterGeneratorEditData.voltagelevel} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Frequency</span>
                                            <input type="number" name="frequency" value={masterGeneratorEditData.frequency} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='mastergeneratorfetch-popup1'>
                                        <label>
                                            <span>Engine Power</span>
                                            <input type="number" name="enginepower" value={masterGeneratorEditData.enginepower} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Cooling System</span>
                                            <input type="text" name="coolingsystem" value={masterGeneratorEditData.coolingsystem} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='mastergeneratorfetch-popup1'>
                                        <label>
                                            <span>Fuel Consumption per Hour</span>
                                            <input type="number" name="fuelconsumptionperhour" value={masterGeneratorEditData.fuelconsumptionperhour} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Fuel Consumption per kWh</span>
                                            <input type="number" name="fuelconsumptionper1kwh" value={masterGeneratorEditData.fuelconsumptionper1kwh} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='mastergeneratorfetch-popup1'>
                                        <label>
                                            <span>Start Type</span>
                                            <input type="text" name="starttype" value={masterGeneratorEditData.starttype} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Proposed Periodicity of Maintenance</span>
                                            <input type="text" name="proposedperiodicityofmaintenance" value={masterGeneratorEditData.proposedperiodicityofmaintenance} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='mastergeneratorfetch-popup1'>
                                        <label>
                                            <span>Previous Maintenance Done Date</span>
                                            <input type="date" name="previousmaintenancedonedate" value={masterGeneratorEditData.previousmaintenancedonedate} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <button id='mastergeneratorfetch-popup-update' type="submit">{Masterformsheadingsss.Updateheading}</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Mastergeneratorfetch;
