import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import './Sectionsfetch.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Sectionsfetch = ({ onFormButtonClick }) => {
    const [sections, setSections] = useState([]);
    const [editData, setEditData] = useState(null);

    // Fetch sections data
    const fetchSections = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/sections`);
            setSections(response.data);
        } catch (error) {
            console.error('Error fetching sections data', error);
        }
    };

    useEffect(() => {
        fetchSections();
    }, []);

    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-lvtransformersectionames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    // Handle edit click
    const handleEditClick = (section) => {
        setEditData(section);
    };

    // Handle form input change
    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditData({ ...editData, [name]: value });
    };

    // Handle form submit
    const handleEditSubmit = async (e) => {
        e.preventDefault();
        console.log('Updating data:', editData);
        toast.success("Sections updated sucessfully")
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/sections/${editData._id}`, editData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchSections();
            setEditData(null);
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='sectionsfetch-total'>

            <div id='sectionsfetch-right'>
                <div id='sectionsfetch-right-2'>
                    <div id='sectionsfetch-right-21'>
                        <label>
                            {Masterformsheadingsss.SectionsHeading}
                        </label>
                        <label>
                            <button onClick={onFormButtonClick}> {Masterformsheadingsss.Formheading}</button>
                        </label>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th> Parent Name</th>
                                <th> Name</th>
                                <th>Meter Make</th>
                                <th>Meter SL No</th>
                                <th>Phase</th>
                                <th>Load Rating</th>
                                <th>Type of Load</th>
                                <th>Total AMPS</th>
                                <th>Proposed Periodicity of Maintenance</th>
                                <th>Previous Maintenance Done Date</th>
                                <th>MAC</th>
                                <th>Sid</th>
                                <th>Division</th>
                                <th>Station Point</th>
                                <th>Key</th>
                                <th>Low Voltage</th>
                                <th>High Voltage</th>
                                <th>Low Frequency</th>
                                <th>High Frequency</th>
                                <th>High Warning Current</th>
                                <th>High Alert Current</th>
                                <th>High Alert PF</th>
                                <th>Low Alert PF</th>
                                <th>Full Load Current</th>
                                <th>Idle Amps</th>
                                <th>Distribution Point</th>
                                <th>Source</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sections.map((section) => (
                                <tr key={section._id}>
                                    <td>{section.date}</td>
                                    <td>{section.SELECT}</td>
                                    <td>{section.NAME}</td>
                                    <td>{section["METER MAKE"]}</td>
                                    <td>{section["METER SL_NO"]}</td>
                                    <td>{section.PHASE}</td>
                                    <td>{section["Load rating"]}</td>
                                    <td>{section["TYPE OF LOAD"]}</td>
                                    <td>{section["Total AMPS"]}</td>
                                    <td>{section["PROPOSED PERIODICITY OF MAINTENANCE"]}</td>
                                    <td>{section["PREVIOUS MAINTENANCE DONE DATE"]}</td>
                                    <td>{section.MAC}</td>
                                    <td>{section.Sid}</td>
                                    <td>{section.Division}</td>
                                    <td>{section["Station point"]}</td>
                                    <td>{section.Key}</td>
                                    <td>{section["Low voltage"]}</td>
                                    <td>{section["High voltage"]}</td>
                                    <td>{section["Low frequency"]}</td>
                                    <td>{section["High frequency"]}</td>
                                    <td>{section["High warning current"]}</td>
                                    <td>{section["High alert current"]}</td>
                                    <td>{section["High alert PF"]}</td>
                                    <td>{section["Low alert PF"]}</td>
                                    <td>{section["Full load current"]}</td>
                                    <td>{section["Idle Amps"]}</td>
                                    <td>{section.distributionpoint}</td>
                                    <td>{section.source}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(section)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {editData && (
                        <Popup open={true} onClose={() => setEditData(null)}>
                            <div id='sectionsfetch-popup'>
                                <h3>Edit Section</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='sectionsfetch-popup1'>
                                        <label>
                                            <span>Date</span>
                                            <input type="text" name="date" value={editData.date} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Parent Name</span>
                                            <select name="SELECT" value={editData.SELECT} onChange={handleEditChange} >
                                                <option value="">Select Parent Name</option>
                                                {parentNames.map((name, index) => (
                                                    <option key={index} value={name}>{name}</option>
                                                ))}
                                            </select>
                                        </label>
                                    </div>
                                    <div id='sectionsfetch-popup1'>
                                        <label>
                                            <span>Name</span>
                                            <input type="text" name="NAME" value={editData.NAME} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Meter Make</span>
                                            <input type="text" name="METER MAKE" value={editData["METER MAKE"]} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='sectionsfetch-popup1'>
                                        <label>
                                            <span>Meter SL No</span>
                                            <input type="text" name="METER SL_NO" value={editData["METER SL_NO"]} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Phase</span>
                                            <select name="PHASE" value={editData.PHASE} onChange={handleEditChange} required>
                                                <option value=''>Select Phase</option>
                                                <option value='3-Phase'>3 Phase</option>
                                                <option value='1-Phase-Vr'>1 Phase-Vr</option>
                                                <option value='1-Phase-Vy'>1 Phase-Vy</option>
                                                <option value='1-Phase-Vb'>1 Phase-Vb</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div id='sectionsfetch-popup1'>
                                        <label>
                                            <span>Load Rating</span>
                                            <input type="number" name="Load rating" value={editData["Load rating"]} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Type of Load</span>
                                            <input type="text" name="TYPE OF LOAD" value={editData["TYPE OF LOAD"]} onChange={handleEditChange} />
                                        </label>
                                    </div>

                                    <div id='sectionsfetch-popup1'>
                                        <label>
                                            <span>Total AMPS</span>
                                            <input type="number" name="Total AMPS" value={editData["Total AMPS"]} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Proposed Periodicity of Maintenance</span>
                                            <input type="text" name="PROPOSED PERIODICITY OF MAINTENANCE" value={editData["PROPOSED PERIODICITY OF MAINTENANCE"]} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='sectionsfetch-popup1'>
                                        <label>
                                            <span>Previous Maintenance Done Date</span>
                                            <input type="text" name="PREVIOUS MAINTENANCE DONE DATE" value={editData["PREVIOUS MAINTENANCE DONE DATE"]} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>MAC</span>
                                            <input type="text" name="MAC" value={editData.MAC} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='sectionsfetch-popup1'>
                                        <label>
                                            <span>Sid</span>
                                            <input type="number" name="Sid" value={editData.Sid} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Division</span>
                                            <input type="text" name="Division" value={editData.Division} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='sectionsfetch-popup1'>
                                        <label>
                                            <span>Station Point</span>
                                            <input type="text" name="Station point" value={editData["Station point"]} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Key</span>
                                            <input type="text" name="Key" value={editData.Key} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='sectionsfetch-popup1'>
                                        <label>
                                            <span>Low Voltage</span>
                                            <input type="number" name="Low voltage" value={editData["Low voltage"]} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>High Voltage</span>
                                            <input type="number" name="High voltage" value={editData["High voltage"]} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='sectionsfetch-popup1'>
                                        <label>
                                            <span>Low Frequency</span>
                                            <input type="number" name="Low frequency" value={editData["Low frequency"]} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>High Frequency</span>
                                            <input type="number" name="High frequency" value={editData["High frequency"]} onChange={handleEditChange} />
                                        </label>

                                    </div>
                                    <div id='sectionsfetch-popup1'>
                                        <label>
                                            <span>High Warning Current</span>
                                            <input type="number" name="High warning current" value={editData["High warning current"]} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>High Alert Current</span>
                                            <input type="number" name="High alert current" value={editData["High alert current"]} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='sectionsfetch-popup1'>
                                        <label>
                                            <span>High Alert PF</span>
                                            <input type="number" name="High alert PF" value={editData["High alert PF"]} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Low Alert PF</span>
                                            <input type="number" name="Low alert PF" value={editData["Low alert PF"]} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='sectionsfetch-popup1'>
                                        <label>
                                            <span>Full Load Current</span>
                                            <input type="number" name="Full load current" value={editData["Full load current"]} onChange={handleEditChange} />
                                        </label>
                                        <label>
                                            <span>Idle Amps</span>
                                            <input type="number" name="Idle Amps" value={editData["Idle Amps"]} onChange={handleEditChange} />
                                        </label>
                                    </div>
                                    <div id='sectionsfetch-popup1'>
                                        <label>
                                            <span>Distribution Point</span>
                                            <select name="distributionpoint" value={editData.distributionpoint} onChange={handleEditChange} required>
                                                <option value=''>Select Value</option>
                                                <option value='Feeding Point'>Feeding Point</option>
                                                <option value='Loading Point'>Loading Point</option>
                                            </select>
                                        </label>
                                        <label>
                                            <span>Source</span>
                                            <select name="source" value={editData.source} onChange={handleEditChange} required>
                                            <option value=''>Select Value</option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <button id='sectionsfetch-popup-update' type="submit"> {Masterformsheadingsss.Updateheading}</button>
                                    </div>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Sectionsfetch;

