import React from 'react'
import './DailyDashboard.css'
import DailyPF from './DailyPF/DailyPF'
import DailyUtilization_Percenatage from './Utilization/Utilization'
import DailyTODconsumption from './TODview/TODview'
import Maxdemand from './Maxdemand/Maxdemand'
const DailyDashboard = () => {
    return (
        <div id='dailydash-total'>
            <div id='dailydash-1'>
                <div>
                    <DailyPF />
                </div>
              
                <div>
                    <DailyUtilization_Percenatage />

                </div>
                <div>
                    <Maxdemand />
                </div>
            </div>

            <div id='dailydash-2'>
                <div>
                </div>
                <div>
                    <DailyTODconsumption />
                </div>
            </div>
        </div>
    )
}

export default DailyDashboard