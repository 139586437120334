import React from 'react'
import Operatorsidebar from '../Sidebar/Sidebar';
import { StatusSummaryView } from '../Data';
import './Statussummaryview.css'
import { FaFileCsv } from "react-icons/fa6";
const Statussummaryview = () => {
  return (
    <div id='summary-total'>
      <div>
      <Operatorsidebar />
      </div>
      <div id='summary-right'>
        <div id='summary-right-1'>
          <div id='summary-heading'>
            {StatusSummaryView.alertHeading}
          </div>
          <div id='summary-heading1'>
            {StatusSummaryView.alertActions.map((action, index) => (
              <button key={index} aria-label={action.label}>
                {action.icon}
              </button>
            ))}
            <img src={StatusSummaryView.logoSrc} alt='logo' />
          </div>
        </div>
        <div id='summary-right-2'>
          <div id='summary-right-21'>
              <label>
                <span>Select Section :</span>
                <input></input>
              </label>
              <button><FaFileCsv/></button>
          </div>
          <table>
            <thead>
              <tr>
                <th>Section</th>
                <th>Parameter Name</th>
                <th>Event Description</th>
                <th>Event Type</th>
                <th>Event Value</th>
                <th>Date & Time</th>
              </tr>
            </thead>
            <tbody>
           <tr>
            <td>    </td>
           </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Statussummaryview