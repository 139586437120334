// import React, { useEffect, useState } from 'react'
// import axios from 'axios';

// const Type_of_load_Consumption = () => {
//     const [data, setData] = useState([]);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/typeofloadconsumption`);
//                 setData(response.data);
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         fetchData();
//     }, []);
//     return (
//         <div>
//             <table>
//                 <thead>
//                     <th>Date</th>
//                     <th>TYPE OF LOAD</th>
//                     <th>Total_KWh_Consumption</th>
//                 </thead>
//                 <tbody>
//                     {data.map((item, index) => (
//                         <tr key={index}>
//                             <td>{item.Date}</td>
//                             <td>{item['TYPE OF LOAD']}</td>
//                             <td> {item.Total_KWh_Consumption}</td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         </div>
//     )
// }

// export default Type_of_load_Consumption





// import React, { useEffect, useState, useRef } from 'react';
// import axios from 'axios';
// import * as echarts from 'echarts';

// const Type_of_load_Consumption = () => {
//   const [data, setData] = useState([]);
//   const [percentages, setPercentages] = useState([]);
//   const [selectedDate, setSelectedDate] = useState(getYesterdayDate());
//   const chartRef = useRef(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/typeofloadconsumption`);
//         const filteredData = response.data.filter(item => item.Date.startsWith(selectedDate));
//         setData(filteredData);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, [selectedDate]);

//   useEffect(() => {
//     const calculatePercentages = () => {
//       if (data.length === 0) {
//         setPercentages([]); 
//         return;
//       }

//       const percentageData = {};

//       data.forEach((item) => {
//         const date = item.Date;

//         if (!percentageData[date]) {
//           percentageData[date] = {
//             date,
//             loads: {},
//             sum: 0
//           };
//         }

//         const typeOfLoad = item['TYPE OF LOAD'];
//         const consumption = parseFloat(item.Total_KWh_Consumption);

//         if (!percentageData[date].loads[typeOfLoad]) {
//           percentageData[date].loads[typeOfLoad] = 0;
//         }

//         percentageData[date].loads[typeOfLoad] += consumption;
//         percentageData[date].sum += consumption;
//       });

//       const result = Object.values(percentageData).map((entry) => {
//         const percentages = {};
//         Object.keys(entry.loads).forEach((typeOfLoad) => {
//           const percentage = (entry.loads[typeOfLoad] / entry.sum) * 100;
//           percentages[typeOfLoad] = percentage.toFixed(2); 
//         });

//         return {
//           date: entry.date,
//           percentages
//         };
//       });

//       setPercentages(result);
//     };

//     calculatePercentages();
//   }, [data]);

//   useEffect(() => {
//     if (chartRef.current) {
//       const myChart = echarts.init(chartRef.current);
//       const chartData = percentages.length > 0 ? percentages[0].percentages : {};

//       const chartOption = {
//         title: {
//           text: 'Type of Load Consumption',
//           left: 'center'
//         },
//         tooltip: {
//           trigger: 'item',
//         },
//         legend: {
//             top: '5%',
//             left: 'center',
//         },
//         toolbox: {
//           show: true,
//           feature: {
//             dataView: { show: true, readOnly: false },
//             magicType: { show: true, type: ['pie', 'funnel'] },
//             saveAsImage: { show: true }
//           }
//         },
//         color: [
//           '#34A853', 
//           '#46BDC6', 
//           '#7BAAF7', 
//           '#FF6D01', 
//           '#FCD04F', 
//           '#F07B72', 
//           '#EA4335'  
//         ],
//         series: [
//           {
//             name: 'Type of Load',
//             type: 'pie',
//             radius: '50%',
//             data: [
//               { value: chartData.Capacitive  || 0, name: 'Capacitive' },
//               { value: chartData.Inductive || 0, name: 'Inductive' },
//               { value: chartData.Compressor || 0, name: 'Compressor' },
//               { value: chartData.Source || 0, name: 'Source' },
//               { value: chartData.Generating || 0, name: 'Generating' },
//               { value: chartData.Lighting || 0, name: 'Lighting' },
//               { value: chartData.Others || 0, name: 'Others' }
//             ],
//             emphasis: {
//               itemStyle: {
//                 shadowBlur: 10,
//                 shadowOffsetX: 0,
//                 shadowColor: 'rgba(0, 0, 0, 0.5)'
//               }
//             },
//             label: {
//               show: true,
//               formatter: '{b} ({d}%)' 
//             }
//           }
//         ]
//       };

//       myChart.setOption(chartOption);
//     }
//   }, [percentages]);

//   const handleDateChange = (event) => {
//     setSelectedDate(event.target.value);
//   };

//   function getYesterdayDate() {
//     const yesterday = new Date();
//     yesterday.setDate(yesterday.getDate() - 1);
//     return yesterday.toISOString().split('T')[0];
//   }

//   return (
//     <div>
//       <div>
//         <label>
//           <span>Date</span>
//           <input 
//             type='date' 
//             value={selectedDate} 
//             onChange={handleDateChange} 
//           />
//         </label>
//       </div>
//       <table>
//         <thead>
//           <tr>
//             <th>Date</th>
//             <th>Capacitive</th>
//             <th>Inductive</th>
//             <th>Compressor</th>
//             <th>Source</th>
//             <th>Generating</th>
//             <th>Lighting</th>
//             <th>Others</th>
//           </tr>
//         </thead>
//         <tbody>
//           {percentages.length > 0 ? (
//             percentages.map((item, index) => (
//               <tr key={index}>
//                 <td>{item.date}</td>
//                 <td>{item.percentages.Capacitive || '0.00'}%</td>
//                 <td>{item.percentages.Inductive || '0.00'}%</td>
//                 <td>{item.percentages.Compressor || '0.00'}%</td>
//                 <td>{item.percentages.Source || '0.00'}%</td>
//                 <td>{item.percentages.Generating || '0.00'}%</td>
//                 <td>{item.percentages.Lighting || '0.00'}%</td>
//                 <td>{item.percentages.Others || '0.00'}%</td>
//               </tr>
//             ))
//           ) : (
//             <tr>
//               <td colSpan="8" style={{ textAlign: 'center' }}>No data available</td>
//             </tr>
//           )}
//         </tbody>
//       </table>
//       <div ref={chartRef} style={{ width: '100%', height: '400px', marginTop: '20px' }}></div>
//     </div>
//   );
// };

// export default Type_of_load_Consumption;




// import React, { useEffect, useState, useRef } from 'react';
// import axios from 'axios';
// import * as echarts from 'echarts';

// const Type_of_load_Consumption = () => {
//   const [data, setData] = useState([]);
//   const [percentages, setPercentages] = useState([]);
//   const [loadTypes, setLoadTypes] = useState([]);
//   const [selectedDate, setSelectedDate] = useState(getYesterdayDate());
//   const chartRef = useRef(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/typeofloadconsumption`);
//         const filteredData = response.data.filter(item => item.Date.startsWith(selectedDate));
//         setData(filteredData);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, [selectedDate]);

//   useEffect(() => {
//     const calculatePercentages = () => {
//       if (data.length === 0) {
//         setPercentages([]);
//         setLoadTypes([]);
//         return;
//       }

//       const percentageData = {};
//       const uniqueLoadTypes = new Set();

//       data.forEach((item) => {
//         const date = item.Date;
//         const typeOfLoad = item['TYPE OF LOAD'];
//         const consumption = parseFloat(item.Total_KWh_Consumption);

//         if (!percentageData[date]) {
//           percentageData[date] = {
//             date,
//             loads: {},
//             sum: 0
//           };
//         }

//         if (!percentageData[date].loads[typeOfLoad]) {
//           percentageData[date].loads[typeOfLoad] = 0;
//         }

//         percentageData[date].loads[typeOfLoad] += consumption;
//         percentageData[date].sum += consumption;
//         uniqueLoadTypes.add(typeOfLoad);
//       });

//       const result = Object.values(percentageData).map((entry) => {
//         const percentages = {};
//         Object.keys(entry.loads).forEach((typeOfLoad) => {
//           const percentage = (entry.loads[typeOfLoad] / entry.sum) * 100;
//           percentages[typeOfLoad] = percentage.toFixed(2);
//         });

//         return {
//           date: entry.date,
//           percentages
//         };
//       });

//       setPercentages(result);
//       setLoadTypes(Array.from(uniqueLoadTypes));
//     };

//     calculatePercentages();
//   }, [data]);

//   useEffect(() => {
//     if (chartRef.current) {
//       const myChart = echarts.init(chartRef.current);
//       const chartData = percentages.length > 0 ? percentages[0].percentages : {};

//       const seriesData = loadTypes.map(type => ({
//         value: chartData[type] || 0,
//         name: type
//       }));

//       const chartOption = {
//         title: {
//           text: 'Type of Load Consumption',
//           left: 'center'
//         },
//         tooltip: {
//           trigger: 'item',
//         },
//         legend: {
//           top: '5%',
//           left: 'center',
//           data: loadTypes,
//         },
//         toolbox: {
//           show: true,
//           feature: {
//             dataView: { show: true, readOnly: false },
//             magicType: { show: true, type: ['pie', 'funnel'] },
//             saveAsImage: { show: true }
//           }
//         },
//         color: [
//           '#34A853', 
//           '#46BDC6', 
//           '#7BAAF7', 
//           '#FF6D01', 
//           '#FCD04F', 
//           '#F07B72', 
//           '#EA4335'  
//         ],
//         series: [
//           {
//             name: 'Type of Load',
//             type: 'pie',
//             radius: '50%',
//             data: seriesData,
//             emphasis: {
//               itemStyle: {
//                 shadowBlur: 10,
//                 shadowOffsetX: 0,
//                 shadowColor: 'rgba(0, 0, 0, 0.5)'
//               }
//             },
//             label: {
//               show: true,
//               formatter: '{b} ({d}%)'
//             }
//           }
//         ]
//       };

//       myChart.setOption(chartOption);
//     }
//   }, [percentages, loadTypes]);

//   const handleDateChange = (event) => {
//     setSelectedDate(event.target.value);
//   };

//   function getYesterdayDate() {
//     const yesterday = new Date();
//     yesterday.setDate(yesterday.getDate() - 1);
//     return yesterday.toISOString().split('T')[0];
//   }

//   return (
//     <div>
//       <div>
//         <label>
//           <span>Date</span>
//           <input 
//             type='date' 
//             value={selectedDate} 
//             onChange={handleDateChange} 
//           />
//         </label>
//       </div>

//       <div ref={chartRef} style={{ width: '100%', height: '400px', marginTop: '20px' }}></div>
//     </div>
//   );
// };

// export default Type_of_load_Consumption;



import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';

const Type_of_load_Consumption = () => {
  const [data, setData] = useState([]);
  const [percentages, setPercentages] = useState([]);
  const [loadTypes, setLoadTypes] = useState([]);
  const [selectedDate, setSelectedDate] = useState(getYesterdayDate());
  const [isDataAvailable, setIsDataAvailable] = useState(true);
  const chartRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/typeofloadconsumption`);
        const filteredData = response.data.filter(item => item.Date.startsWith(selectedDate));
        setData(filteredData);
        setIsDataAvailable(filteredData.length > 0); // Check if data is available
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedDate]);

  useEffect(() => {
    const calculatePercentages = () => {
      if (data.length === 0) {
        setPercentages([]);
        setLoadTypes([]);
        return;
      }

      const percentageData = {};
      const uniqueLoadTypes = new Set();

      data.forEach((item) => {
        const date = item.Date;
        const typeOfLoad = item['TYPE OF LOAD'];
        const consumption = parseFloat(item.Total_KWh_Consumption);

        if (!percentageData[date]) {
          percentageData[date] = {
            date,
            loads: {},
            sum: 0
          };
        }

        if (!percentageData[date].loads[typeOfLoad]) {
          percentageData[date].loads[typeOfLoad] = 0;
        }

        percentageData[date].loads[typeOfLoad] += consumption;
        percentageData[date].sum += consumption;
        uniqueLoadTypes.add(typeOfLoad);
      });

      const result = Object.values(percentageData).map((entry) => {
        const percentages = {};
        Object.keys(entry.loads).forEach((typeOfLoad) => {
          const percentage = (entry.loads[typeOfLoad] / entry.sum) * 100;
          percentages[typeOfLoad] = percentage.toFixed(2);
        });

        return {
          date: entry.date,
          percentages
        };
      });

      setPercentages(result);
      setLoadTypes(Array.from(uniqueLoadTypes));
    };

    calculatePercentages();
  }, [data]);

  useEffect(() => {
    if (chartRef.current && isDataAvailable) {
      const myChart = echarts.init(chartRef.current);
      const chartData = percentages.length > 0 ? percentages[0].percentages : {};

      const seriesData = loadTypes.map(type => ({
        value: chartData[type] || 0,
        name: type
      }));

      const chartOption = {
        title: {
          // text: 'Type of Load Consumption',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          top: '5%',
          left: 'center',
          data: loadTypes,
        },
        toolbox: {
          show: true,
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['pie', 'funnel'] },
            saveAsImage: { show: true }
          }
        },
        color: [
          '#34A853',
          '#46BDC6',
          '#7BAAF7',
          '#FF6D01',
          '#FCD04F',
          '#F07B72',
          '#EA4335',
          '#CCBC81 ',
        ],
        series: [
          {
            name: 'Type of Load',
            type: 'pie',
            radius: '50%',
            data: seriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              formatter: '{b} ({d}%)'
            }
          }
        ]
      };

      myChart.setOption(chartOption);
    }
  }, [percentages, loadTypes, isDataAvailable]);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  function getYesterdayDate() {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.toISOString().split('T')[0];
  }

  return (
    <div>
      <div>
        <label>
          <span>Date</span>
          <input
            type='date'
            value={selectedDate}
            onChange={handleDateChange}
          />
        </label>
      </div>
       <h1>Type of Load Consumption</h1>
      {isDataAvailable && percentages.length > 0 ? (
        <div ref={chartRef} style={{ width: '100%', height: '400px', marginTop: '20px' }}></div>
      ) : (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <p>Please select date</p>
        </div>
      )}
    </div>
  );
};

export default Type_of_load_Consumption;
