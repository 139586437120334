


import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import './Homealerts.css';
import { Link } from 'react-router-dom';

const MAX_ALERTS = 100;
const LOCAL_ALERTS_KEY = 'alerts';

const Homealerts = () => {
    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        // Open a WebSocket connection to the server
        const ws = new WebSocket(process.env.REACT_APP_LIVEALERTS_URL);
    
        ws.onmessage = (event) => {
          const data = JSON.parse(event.data);
    
          console.log(data);
          
    
          const alertKey = `${data.stationPoint}-${data.parameterName}-${data.eventDescription}`;
    
          setAlerts((prevAlerts) => {
            const existingAlertIndex = prevAlerts.findIndex(
              (alert) => alert.key === alertKey
            );
    
            if (existingAlertIndex !== -1) {
              // Update the existing alert
              const updatedAlerts = [...prevAlerts];
              updatedAlerts[existingAlertIndex] = {
                ...updatedAlerts[existingAlertIndex],
                eventValue: data.eventValue,
                dateTime: data.dateTime,
                start_time: data.start_time,
              };
              localStorage.setItem("alerts", JSON.stringify(updatedAlerts));
              return updatedAlerts;
            } else {
              // Add a new alert
              const newAlerts = [{ ...data, key: alertKey }, ...prevAlerts].slice(
                0,
                MAX_ALERTS
              );
              localStorage.setItem("alerts", JSON.stringify(newAlerts));
              return newAlerts;
            }
          });
        };
    
        // Clean up the WebSocket connection when the component unmounts
        return () => {
          ws.close();
          setAlerts([])
        }
      }, []);
    return (
        <div id='opehomealerts-total'>
            <div id='opehomealerts-right'>
                <div id='opehomealerts-right-2'>
                    <div id='opehomealerts-right-21'>
                        <div id='opehomealerts-topflex'>
                            <span id='opehomealerts-alert'>Alerts</span>
                             <Link to='/Alertspage?section=OperatorAlertstable-1'>
                                    <button id='opehomealerts-viewmore'>View More</button>
                                </Link>
                        </div>

                        <div id='opehomealerts-right-211'>
                            {alerts.map((alert) => (
                                <div key={alert.key} className="opehomealerts-item">
                                    <div id="opehomealerts-details">
                                        <div id='opehomealerts-div'>
                                            <label>
                                                <strong>Section:</strong>
                                                <span>{alert.stationPoint}</span>
                                            </label>
                                            <label>
                                                <strong>Parameter Name:</strong>
                                                <span>{alert.parameterName}</span>
                                            </label>
                                        </div>
                                        <div id='opehomealerts-div'>
                                            <label>
                                                <strong>Event Description:</strong>
                                                <span>{alert.eventDescription}</span>
                                            </label>
                                            <label>
                                                <strong>Event Type:</strong>
                                                <span>{alert.eventType}</span>
                                            </label>
                                        </div>
                                        <div id='opehomealerts-div'>
                                            <label>
                                                <strong>Event Value:</strong>
                                                <span>{alert.eventValue}</span>
                                            </label>
                                            <label>
                                                <strong>Start Time:</strong>
                                                <span>{alert.start_time}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Homealerts;

