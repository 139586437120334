import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './VFD.css';
import { Masterformsheadingsss } from '../../Operator/Data';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const VFD = ({ onViewData }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    const initialFormData = {
        date: getCurrentDate(),
        name: '',
        vfdid: '',
        parentname: '',
        metermake: '',
        meterslno: '',
        phase: '',
        capacityinvw: '',
        voltagelevel: '',
        inputvoltage: '',
        outputvoltage: '',
        outputfrequency: '',
        frequencycontrolaccuracy: '',
        accelerationanddeaccelarationrates: '',
        energyefficiency: '',
        controltype: '',
        overcurrentprotectionavailable: '',
        overvoltageprotectionavailable: '',
        regenartivecapability: '',
        harmonicmigartion: '',
        compatability: '',
        proposedperiodicityofmaintenance: '',
        previousmaintenancedonedate: '',
        spare1: '',
        spare2: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [parentNames, setParentNames] = useState([]);

    const fetchParentNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_EMS_URL}/api/combined-lvtransformersectionames`);
            const data = await response.json();
            setParentNames(data);
        } catch (error) {
            console.error('Error fetching parent names', error);
        }
    };

    useEffect(() => {
        fetchParentNames();
    }, []);

    const generateVFDID = () => {
        const randomNum = Math.floor(Math.random() * 999999) + 1;
        const paddedNum = String(randomNum).padStart(6, '0');
        return `VFD-${paddedNum}`;
    };

    console.log(generateVFDID());

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const vfdsid = generateVFDID();
            const dataToSend = {
                vfdid: vfdsid,
                ...formData,
                capacityinvw: Number(formData.capacityinvw),
                voltagelevel: Number(formData.voltagelevel),
                inputvoltage: Number(formData.inputvoltage),
                outputvoltage: Number(formData.outputvoltage),
                outputfrequency: Number(formData.outputfrequency),
                frequencycontrolaccuracy: Number(formData.frequencycontrolaccuracy),
                accelerationanddeaccelarationrates: Number(formData.accelerationanddeaccelarationrates),
                energyefficiency: Number(formData.energyefficiency),
                harmonicmigartion: Number(formData.harmonicmigartion),
            };

            await axios.post(`${process.env.REACT_APP_API_EMS_URL}/api/vfds`, dataToSend);

            toast.success('VFD added successfully');
            setFormData(initialFormData);
            fetchParentNames();
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    return (
        <div id='vfd-total'>
            
            <div id='vfd-right'>
               
                <div id='vfd-right-2'>
                    <form onSubmit={handleSubmit}>
                        <div id='vfd-divtop'>
                            <label>
                            <span> {Masterformsheadingsss.VFDHeading}</span>

                            </label>
                            <label>
                            <button type="button" onClick={onViewData}>View Data</button>
                            </label>
                        </div>
                        <div id='vfd-div1'>
                            <label>
                                <span>Date</span>
                                <input type='date' name="date" value={formData.date} disabled />
                            </label>
                            <label>
                                <span>Parent Name</span>
                                <select name="parentname" value={formData.parentname} onChange={handleChange} >
                                    <option value="">Select Parent Name</option>
                                    {parentNames.map((name, index) => (
                                        <option key={index} value={name}>{name}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div id='vfd-div1'>
                            <label>
                                <span>Name</span>
                                <input name="name" value={formData.name} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Meter Make</span>
                                <input name="metermake" value={formData.metermake} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='vfd-div1'>

                            <label>
                                <span>Meter Serial No.</span>
                                <input type='text' name="meterslno" value={formData.meterslno} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Phase</span>
                                <select name="phase" value={formData.phase} onChange={handleChange} required>
                                    <option value=''>Select Phase</option>
                                    <option value='3-Phase'>3-Phase</option>
                                    <option value='1-Phase-Vr'>1-Phase-Vr</option>
                                    <option value='1-Phase-Vy'>1-Phase-Vy</option>
                                    <option value='1-Phase-Vb'>1-Phase-Vb</option>
                                </select>
                            </label>
                        </div>
                        <div id='vfd-div1'>

                            <label>
                                <span>Capacity (in kW)</span>
                                <input type='number' name="capacityinvw" value={formData.capacityinvw} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Voltage Level</span>
                                <input type='number' name="voltagelevel" value={formData.voltagelevel} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='vfd-div1'>

                            <label>
                                <span>Input Voltage</span>
                                <input type='number' name="inputvoltage" value={formData.inputvoltage} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Output Voltage</span>
                                <input type='number' name="outputvoltage" value={formData.outputvoltage} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='vfd-div1'>

                            <label>
                                <span>Output Frequency</span>
                                <input type='number' name="outputfrequency" value={formData.outputfrequency} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Frequency Control Accuracy</span>
                                <input type='number' name="frequencycontrolaccuracy" value={formData.frequencycontrolaccuracy} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='vfd-div1'>

                            <label>
                                <span>Acceleration and Deacceleration Rates</span>
                                <input type='number' name="accelerationanddeaccelarationrates" value={formData.accelerationanddeaccelarationrates} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Energy Efficiency</span>
                                <input type='number' name="energyefficiency" value={formData.energyefficiency} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='vfd-div1'>

                            <label>
                                <span>Control Type</span>
                                <input name="controltype" value={formData.controltype} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Overcurrent Protection Available</span>
                                <select name="overcurrentprotectionavailable" value={formData.overcurrentprotectionavailable} onChange={handleChange} required>
                                    <option value=''>Select Option</option>
                                    <option value='Y'>Yes</option>
                                    <option value='N'>No</option>
                                </select>
                            </label>
                        </div>
                        <div id='vfd-div1'>

                            <label>
                                <span>Overvoltage Protection Available</span>
                                <select name="overvoltageprotectionavailable" value={formData.overvoltageprotectionavailable} onChange={handleChange} required>
                                    <option value=''>Select Option</option>
                                    <option value='Y'>Yes</option>
                                    <option value='N'>No</option>
                                </select>
                            </label>
                            <label>
                                <span>Regenerative Capability</span>
                                <select name="regenartivecapability" value={formData.regenartivecapability} onChange={handleChange} required>
                                    <option value=''>Select Regenerative Capability</option>
                                    <option value='Y'>Yes</option>
                                    <option value='N'>No</option>
                                </select>

                            </label>
                        </div>
                        <div id='vfd-div1'>

                            <label>
                                <span>Harmonic Migration</span>
                                <input type='number' name="harmonicmigartion" value={formData.harmonicmigartion} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Compatibility</span>
                                <input name="compatability" value={formData.compatability} onChange={handleChange} required />
                            </label>
                        </div>
                        <div id='vfd-div1'>

                            <label>
                                <span>Proposed Periodicity of Maintenance</span>
                                <input name="proposedperiodicityofmaintenance" value={formData.proposedperiodicityofmaintenance} onChange={handleChange} required />
                            </label>
                            <label>
                                <span>Previous Maintenance Done Date</span>
                                <input type='date' name="previousmaintenancedonedate" value={formData.previousmaintenancedonedate} onChange={handleChange} required />
                            </label>
                        </div>
                        {/* <div id='vfd-div1'>
                           
                            <label>
                                <span>Spare 1</span>
                                <input name="spare1" value={formData.spare1} onChange={handleChange} />
                            </label>
                            <label>
                                <span>Spare 2</span>
                                <input name="spare2" value={formData.spare2} onChange={handleChange} />
                            </label>
                        </div> */}
                        <div id='vfd-divbottom'>
                            <button id='vfd-save' type='submit'>{Masterformsheadingsss.Submitheading}</button>
                            {/* <button type='reset'>Cancel</button> */}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default VFD;
