import { Box, Divider, Paper, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import chatIcon from "../../../Images/Black.svg";
import moment from "moment/moment";
import lightIcon from "../../../Images/light.svg";
import { useForm } from "react-hook-form";
import manIcon from "../../../Images/man.svg";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { getDistributionLosses } from "../../../api/services/trends/trendsService";
import { DatePicker, Popover } from "antd";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

const DistributionLosses = () => {
  const { t } = useTranslation();

  const { register, watch, setValue, getValues } = useForm({
    defaultValues: {
      year: dayjs(),
    },
  });

  const { year } = watch();


  const { data } = useQuery({
    queryKey: ["distributionLosses", year],
    queryFn: () => getDistributionLosses(year.format("YYYY")),
    retry: 0,
  });

  const chartData = {
    labels: data?.month_labels,
    datasets: [
      {
        type: "line",
        label: "Base Line",
        data: data?.month_labels?.map(() => data?.base_value),
        backgroundColor: "#DB4545",
        borderColor: "#DB4545",
        borderWidth: 2,
        borderDash: [5, 5],
        pointRadius: 0,
        tension: 0,
      },
      {
        label: "Distribution Loss",
        data: data?.values,
        backgroundColor: data?.month_labels?.map((_date) => "#6174D7"),
        borderWidth: 1,
        maxBarThickness: 100,
      },
    ],
  };

  const disableFutureMonths = (current) => {
    return current && current > dayjs().endOf("year");
  };

  const onChange = (value) => {
    if (value) {
      setValue("year", value);
    }
  };

  const options = {
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        title: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false, // Hides grid lines on the x-axis
        },
        beginAtZero: true,
        title: {
          display: true,
          text: "Loss Percentage",
          font: {
            size: 15, // Font size for x-axis title
            weight: "bold", // Makes the x-axis title bold
          },
        },
      },
    },
    animation: {
      // duration: 50,
      // easing: undefined,
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false, // Hide tooltip when hovering over multiple bars

        backgroundColor: "white", // Set the tooltip background color to white
        titleColor: "black", // Set the title text color to black for contrast
        bodyColor: "black",
        borderColor: "black",
        borderWidth: 1,
        yAlign: "bottom", // Ensures tooltip is positioned above the bar
        caretSize: 5, // Adjust size of the tooltip caret
        caretPadding: 0,

        titleFont: {
          size: 15, // Increase title font size
        },
        bodyFont: {
          size: 14, // Increase body font size
        },
        padding: 12, // Increase padding inside the tooltip
        boxPadding: 10, // Padding around colored boxes
        boxWidth: 30, // Width of color indicator boxes
        boxHeight: 30, // Height of color indicator boxes
      },
    },
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          border: "1px solid #DFE6FA",
          display: "flex",
          justifyContent: "end",
          p: 1,
          mb: 1,
        }}
        // className="rcpe-filter-container"
      >
        <Box>
          <div>
            <label>{"Select Year"}</label>
          </div>

          <div>
            <DatePicker
              {...register("year")}
              style={{ width: "200px" }}
              defaultValue={getValues("year")}
              size={"medium"}
              picker={"year"}
              disabledDate={disableFutureMonths} // Restricts future months
              onChange={onChange}
            />
          </div>
        </Box>
      </Paper>

      <Paper
        elevation={0}
        sx={{
          border: "1px solid #DFE6FA",
        }}
        className="chart-container"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {t("distributionLosses.heading")}
          </Typography>
          <Popover
            placement="leftBottom"
            content={
              <Box maxWidth={600}>
                <Box className="static-content-container">
                  <Box className="imp-container">
                    <img src={chatIcon} width={"40px"} height={"40px"} />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#447F44", fontWeight: "bold" }}
                      >
                        {t("common.whyIsThisImportanQuestion")}
                      </Typography>
                      <Typography variant="body1">
                        {t("distributionLosses.whyIsThisImportantAnswer")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className="static-content-container">
                  <Box
                    className="imp-container"
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <img
                        style={{ alignSelf: "start" }}
                        src={manIcon}
                        width={"80px"}
                        height={"40px"}
                      />

                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="body1"
                          sx={{ color: "#447F44", fontWeight: "bold" }}
                        >
                          {t("common.WhatShouldYouDoQuestion")}
                        </Typography>
                        <Typography variant="body1">
                          {t("distributionLosses.whatShouldYouDoAnswer")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box className="static-content-container">
                  <Box className="imp-container">
                    <img src={lightIcon} width={"40px"} height={"40px"} />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#447F44", fontWeight: "bold" }}
                      >
                        {t("common.howWillYouImproveQuestion")}
                      </Typography>
                      <Typography variant="body1">
                        {t("distributionLosses.howWillYouImproveAnswer")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            }
            // title="Title"
          >
            <Box
              type="button"
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: 0.1,
              }}
              // onClick={() => setOpen()}
            >
              <HelpOutlineOutlinedIcon fontSize="medium" color="disabled" />
              <Typography
                fontSize={15}
                style={{ display: "inline", color: "gray" }}
              >
                How to use?
              </Typography>
            </Box>
          </Popover>
        </Box>
        <Bar className="alerts-chart" data={chartData} options={options} />
        <div style={{ textAlign: "center" }}>{`year -- ${year}`}</div>
      </Paper>
    </>
  );
};

export default DistributionLosses;
