import React from 'react'
import DashEnergyConsumption from './Energycousumption/Energycousumption'

const Tab3 = () => {
  return (
    <div>
        <DashEnergyConsumption/>
    </div>
  )
}

export default Tab3