import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import { Voltagevoltagefluctuationsheader } from '../../../Operator/Data';
import { Link } from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import './Harmonic_Formfetch.css';
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const Harmonic_Formfetch = () => {
    const [harmonicform, setHarmonicform] = useState([]);
    const [harmonicformEditData, setHarmonicformEditData] = useState(null);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
      // Retrieve the logged-in user from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        setLoggedInUser(user);
      }
      setUserDesignation(user.level);
    }, []);

    const fetchHarmonicform = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/harmonicform`);
            setHarmonicform(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchHarmonicform();
    }, []);

    const handleEditClick = (form) => {
        setHarmonicformEditData(form);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setHarmonicformEditData({ ...harmonicformEditData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/harmonicform/${harmonicformEditData._id}`, harmonicformEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchHarmonicform();
            setHarmonicformEditData(null);
            alert("Harmonic Form updated successfully");
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='harmonic_formfetch-total'>
            <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />} 
            </div>
            <div id='harmonic_formfetch-right'>
                <div id='harmonic_formfetch-right-1'>
                    <div id='harmonic_formfetch-heading'>
                        {Voltagevoltagefluctuationsheader.alertHeading}
                    </div>
                    <div id='harmonic_formfetch-heading1'>
                        {Voltagevoltagefluctuationsheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Voltagevoltagefluctuationsheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <div id='harmonic_formfetch-right-2'>
                    <div id='harmonic_formfetch-right-21'>
                    <label>
                                <span style={{fontWeight:'bold'}} >Harmonics - Implement  harmonic spectrum analysis</span>
                            </label>
                        {/* <label>
                            <Link to='/Harmonic_Form'><button>Form</button></Link>
                        </label> */}
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Tocken ID</th>
                                <th>Date and Time</th>
                                <th>Name</th>
                                <th>Detuning Factor</th>
                                <th>THD</th>
                                <th>Quality Factor</th>
                                {/* <th>Created By</th>
                                <th>Created At</th>
                                <th>Modified By</th>
                                <th>Modified At</th> */}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {harmonicform.map((form) => (
                                <tr key={form._id}>
                                    <td>{form.token_id}</td>
                                    <td>{form.dateandtime}</td>
                                    <td>{form.name}</td>
                                    <td>{form.detuning_factor}</td>
                                    <td>{form.thd}</td>
                                    <td>{form.quality_factor}</td>
                                    {/* <td>{form.createdby}</td>
                                    <td>{form.createdat}</td>
                                    <td>{form.modifiedby}</td>
                                    <td>{form.modifiedat}</td> */}
                                    <td>
                                        <button onClick={() => handleEditClick(form)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {harmonicformEditData && (
                        <Popup open={true} onClose={() => setHarmonicformEditData(null)}>
                            <div id='harmonic_formfetch-popup'>
                                <h3>Edit Harmonic Form</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='harmonic_formfetch-popup1'>
                                        <label>
                                            <span>Tocken ID <span id='all-mandetorymark'>*</span></span>
                                            <input name="token_id" value={harmonicformEditData.token_id} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Date and Time <span id='all-mandetorymark'>*</span></span>
                                            <input name="dateandtime" value={harmonicformEditData.dateandtime} onChange={handleEditChange} disabled/>
                                        </label>
                                    </div>
                                    <div id='harmonic_formfetch-popup1'>
                                        <label>
                                            <span>Name <span id='all-mandetorymark'>*</span></span>
                                            <input type="text" name="name" value={harmonicformEditData.name} onChange={handleEditChange} required/>
                                        </label>
                                        <label>
                                            <span>Detuning Factor <span id='all-mandetorymark'>*</span></span>
                                            <input type="number" name="detuning_factor" value={harmonicformEditData.detuning_factor} onChange={handleEditChange} required/>
                                        </label>
                                    </div>
                                    <div id='harmonic_formfetch-popup1'>
                                        <label>
                                            <span>THD <span id='all-mandetorymark'>*</span></span>
                                            <input type="number" name="thd" value={harmonicformEditData.thd} onChange={handleEditChange} required />
                                        </label>
                                        <label>
                                            <span>Quality Factor <span id='all-mandetorymark'>*</span></span>
                                            <input type="number" name="quality_factor" value={harmonicformEditData.quality_factor} onChange={handleEditChange}  required/>
                                        </label>
                                    </div>
                                    {/* <div id='harmonic_formfetch-popup1'>
                                        <label>
                                            <span>Created By</span>
                                            <input type="text" name="createdby" value={harmonicformEditData.createdby} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Created At</span>
                                            <input type="text" name="createdat" value={harmonicformEditData.createdat} onChange={handleEditChange} disabled />
                                        </label>
                                    </div>
                                    <div id='harmonic_formfetch-popup1'>
                                        <label>
                                            <span>Modified By</span>
                                            <input type="text" name="modifiedby" value={harmonicformEditData.modifiedby} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Modified At</span>
                                            <input type="text" name="modifiedat" value={harmonicformEditData.modifiedat} onChange={handleEditChange} disabled />
                                        </label>
                                    </div> */}
                                    <button id='harmonic_formfetch-popup-update' type="submit">Update</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Harmonic_Formfetch;
