
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Stationpoints from '../../Commonfns/Stationpoints';
import { FaFileCsv } from "react-icons/fa6";
import './ReportDowntime.css';
const ReportDowntime_Uptime = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [section, setSection] = useState('');

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayStr = yesterday.toISOString().split('T')[0]; 

    const [fromDate, setFromDate] = useState(yesterdayStr);
    const [toDate, setToDate] = useState(yesterdayStr);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/all/downtime_Report`);
                setData(response.data);
                // Initially filter the data to show only yesterday's data
                setFilteredData(response.data.filter(item => {
                    const itemDate = new Date(item.date).toISOString().split('T')[0];
                    return itemDate === yesterdayStr;
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [yesterdayStr]);

    useEffect(() => {
        filterData();
    }, [fromDate, toDate, section]);

    const handleStationPointChange = (selectedStationPoint) => {
        setSection(selectedStationPoint);
    };

    const filterData = () => {
        let filtered = data;

        if (fromDate) {
            filtered = filtered.filter(item => new Date(item.date) >= new Date(fromDate));
        }
        if (toDate) {
            filtered = filtered.filter(item => new Date(item.date) <= new Date(toDate));
        }
        if (section) {
            filtered = filtered.filter(item => Object.keys(item.Downtime_Durations).includes(section));
        }

        setFilteredData(filtered);
    };

    const downloadCSV = () => {
        const csvRows = [];
        const headers = [
            "Date",
            "Station Point",
            "Downtime Duration",
            "Uptime Duration"
        ];
        csvRows.push(headers.join(','));

        filteredData.forEach(item => {
            item.stationPoints.forEach(point => {
                if (!section || section === point) {
                    const row = [
                        item.date,
                        point,
                        item.Downtime_Durations[point],
                        item.Uptime_Durations[point]
                    ];
                    csvRows.push(row.join(','));
                }
            });
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'Downtime_Uptime_Report.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const handleFromDateChange = (e) => {
        const selectedFromDate = e.target.value;
        setFromDate(selectedFromDate);
    
        if (toDate && toDate < selectedFromDate) {
          setToDate("");
        }
      };
    
      const handleToDateChange = (e) => {
        setToDate(e.target.value);
      };
    
      const getTodayDate = () => {
        const today = new Date();
        return today.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
      };

    return (


        <div id='reportdowntime-total'>

            <div id='reportdowntime-right-21'>
                <label>
                    <span>From Date: </span>
                    <input type='date' value={fromDate} onChange={handleFromDateChange} max={getTodayDate()} />
                </label>
                <label>
                    <span>To Date: </span>
                    <input type='date' value={toDate} onChange={handleToDateChange} max={getTodayDate()}   min={fromDate || ""}  disabled={!fromDate} />
                </label>
                <label>
                    <Stationpoints onStationPointChange={handleStationPointChange} />
                </label>
                <label>
                    <button onClick={downloadCSV}><FaFileCsv /></button>
                </label>
            </div>


            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Section</th>
                        <th>Downtime Duration</th>
                        <th>Uptime Duration</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((report) => (
                        report.stationPoints.map((point) => (
                            (!section || section === point) && (
                                <tr key={`${report.date}-${point}`}>
                                    <td>{new Date(report.date).toLocaleDateString()}</td>
                                    <td>{point}</td>
                                    <td>{report.Downtime_Durations[point]}</td>
                                    <td>{report.Uptime_Durations[point]}</td>
                                </tr>
                            )
                        ))
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ReportDowntime_Uptime;
