import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './REportsRCPE.css';
import { FaFileCsv } from "react-icons/fa6";
const ReportRCPE_Report = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/rcpe-reports`);
                setData(response.data);
                setFilteredData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        filterData();
    }, [fromDate, toDate]);

    const filterData = () => {
        let filtered = data;

        if (fromDate) {
            filtered = filtered.filter(item => new Date(item.date) >= new Date(fromDate));
        }
        if (toDate) {
            filtered = filtered.filter(item => new Date(item.date) <= new Date(toDate));
        }

        setFilteredData(filtered);
    };

    const downloadCSV = () => {
        const csvRows = [];
        const headers = [
            "Date",
            "KVAh_consumption",
            "Production_Count",
            "Energy_Intensity",
            "Uptime"
        ];
        csvRows.push(headers.join(','));

        filteredData.forEach(item => {
            const row = [
                item.date,
                item.KVAh_consumption || 0,
                item.Production_Count || 0,
                item.Energy_Intensity || 0,
                item.Uptime || 0
            ];
            csvRows.push(row.join(','));
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'RCPE_Report.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const handleFromDateChange = (e) => {
        const selectedFromDate = e.target.value;
        setFromDate(selectedFromDate);
    
        if (toDate && toDate < selectedFromDate) {
          setToDate("");
        }
      };
    
      const handleToDateChange = (e) => {
        setToDate(e.target.value);
      };
    
      const getTodayDate = () => {
        const today = new Date();
        return today.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
      };

    return (
        <div id='reportrcpe-total'>
            <div id='reportrcpe-right-21'>
                <label>
                    <span>From Date:</span>
                    <input type='date' value={fromDate} onChange={handleFromDateChange} max={getTodayDate()} />
                </label>
                <label>
                    <span>To Date:</span>
                    <input type='date' value={toDate} onChange={handleToDateChange} max={getTodayDate()}  min={fromDate || ""}  disabled={!fromDate} />
                </label>
                <label>
                    <button onClick={downloadCSV}><FaFileCsv /></button>
                </label>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Run Time</th>
                        <th>Consumption</th>
                        <th>Production</th>
                        <th>Energy Intensity</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.date}</td> 
                            <td>{item.Uptime || 0}</td>
                            <td>{item.KVAh_consumption || 0}</td>
                            <td>{item.Production_Count || 0}</td>
                            <td>{item.Energy_Intensity || 0}</td>   
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ReportRCPE_Report;
