

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import * as echarts from 'echarts';
// import './Energycousumption.css';

// const DashEnergyConsumption = () => {
//     const [deptData, setDeptData] = useState([]);
//     const [softData, setSoftData] = useState([]);
//     const [loadingDept, setLoadingDept] = useState(true);
//     const [loadingSoft, setLoadingSoft] = useState(true);

//     useEffect(() => {
//         const fetchDeptData = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/energyconsumptiondepartment`);
//                 setDeptData(response.data);
//                 setLoadingDept(false);
//             } catch (error) {
//                 console.log("error fetching the Department Data ");
//             }
//         };

//         const fetchSoftData = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/energyconsumptionsoftware`);
//                 setSoftData(response.data);
//                 setLoadingSoft(false);
//             } catch (error) {
//                 console.log("error fetching the Software Data ");
//             }
//         };

//         fetchDeptData();
//         fetchSoftData();
//     }, []);

//     useEffect(() => {
//         if (!loadingDept && !loadingSoft) {
//             // Combine data and prepare chart
//             const chartDom = document.getElementById('main');
//             const myChart = echarts.init(chartDom);

//             // Function to format date to MM-YY
//             const formatDate = (date) => {
//                 const [year, month] = date.split('-');
//                 return `${month}-${year}`;
//             };

//             const dates = deptData.map(item => formatDate(item.date));
//             const deptBillsWithCharges = deptData.map(item => item.Bill_with_charges);
//             const deptBillsWithoutCharges = deptData.map(item => item.Bill_without_charges);
//             const deptFixedCharges = deptData.map(item => item.Fixed_charges);

//             const softBillsWithCharges = softData.map(item => item.Bill_with_charges);
//             const softBillsWithoutCharges = softData.map(item => item.Bill_without_charges);
//             const softFixedCharges = softData.map(item => item.Fixed_charges);

//             const option = {
//                 tooltip: {
//                     trigger: 'axis',
//                     axisPointer: {
//                         type: 'shadow'
//                     },
//                     formatter: (params) => {
//                         const deptIndex = deptData.findIndex(item => formatDate(item.date) === params[0].axisValue);
//                         const softIndex = softData.findIndex(item => formatDate(item.date) === params[0].axisValue);
//                         const deptBillWithCharges = deptIndex !== -1 ? deptData[deptIndex].Bill_with_charges : 0;
//                         const softBillWithCharges = softIndex !== -1 ? softData[softIndex].Bill_with_charges : 0;
//                         return `
//                             <strong>Department Generated Total Bill: ${deptBillWithCharges}</strong><br/>
//                             ${params[0].marker} Department Generated Bill Without Fixed Charge: ${deptBillsWithoutCharges[deptIndex]}<br/>
//                             ${params[1].marker} Department Generated Fixed Charge: ${deptFixedCharges[deptIndex]}<br/>
//                             <strong>Software Generated Total Bill: ${softBillWithCharges}</strong><br/>
//                             ${params[2].marker} Software Generated Bill Without Fixed Charge: ${softBillsWithoutCharges[softIndex]}<br/>
//                             ${params[3].marker} Software Generated Fixed Charge: ${softFixedCharges[softIndex]}
//                         `;
//                     }
//                 },
//                 legend: {
//                     data: ['Department generated - without fixed charges', 'Department generated - fixed charges', 'Software generated - without fixed charges', 'Software generated - fixed charges']
//                 },
//                 xAxis: {
//                     type: 'category',
//                     data: dates,
//                     axisLabel: {
//                         formatter: function (value) {
//                             // Value is already formatted as MM-YY
//                             return value;
//                         }
//                     }
//                 },
//                 yAxis: {
//                     type: 'value'
//                 },
//                 series: [
//                     {
//                         name: 'Department generated - without fixed charges',
//                         type: 'bar',
//                         stack: 'Department',
//                         data: deptBillsWithoutCharges,
//                         itemStyle: {
//                             color: '#987070'  // Custom color for Department Bill Without Charges
//                         },
//                         barWidth: 30,
//                         // label: {
//                         //     show: true,
//                         //     position: 'inside',
//                         //     textStyle: {
//                         //         color: '#ffffff' // Set label color here
//                         //     },
//                         //     formatter: '{c}'
//                         // }
//                     },
//                     {
//                         name: 'Department generated - fixed charges',
//                         type: 'bar',
//                         stack: 'Department',
//                         data: deptFixedCharges,
//                         itemStyle: {
//                             color: '#C39898'
//                         },
//                         barWidth: 30,
//                         // label: {
//                         //     show: true,
//                         //     position: 'inside',
//                         //     textStyle: {
//                         //         color: '#ffffff' // Set label color here
//                         //     },
//                         //     formatter: '{c}'
//                         // }
//                     },
//                     {
//                         name: 'Software generated - without fixed charges',
//                         type: 'bar',
//                         stack: 'Software',
//                         data: softBillsWithoutCharges,
//                         itemStyle: {
//                             color: '#5F6F65'
//                         },
//                         barWidth: 30,
//                         // label: {
//                         //     show: true,
//                         //     position: 'inside',
//                         //     textStyle: {
//                         //         color: '#ffffff' // Set label color here
//                         //     },
//                         //     formatter: '{c}'
//                         // }
//                     },
//                     {
//                         name: 'Software generated - fixed charges',
//                         type: 'bar',
//                         stack: 'Software',
//                         data: softFixedCharges,
//                         itemStyle: {
//                             color: '#9CA986'
//                         },
//                         barWidth: 30,
//                         // label: {
//                         //     show: true,
//                         //     position: 'inside',
//                         //     textStyle: {
//                         //         color: '#ffffff' // Set label color here
//                         //     },
//                         //     formatter: '{c}'
//                         // }
//                     }
//                 ]
//             };

//             myChart.setOption(option);
//         }
//     }, [deptData, softData]);

//     return (
//         <div id='dashenergy-total'>
//             <span>Energy consumption & billing</span>
//             <div id="main" className='dashenergy-chart'></div>
//         </div>
//     );
// };

// export default DashEnergyConsumption;


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as echarts from 'echarts';
import './Energycousumption.css';

const DashEnergyConsumption = () => {
    const [deptData, setDeptData] = useState([]);
    const [softData, setSoftData] = useState([]);
    const [loadingDept, setLoadingDept] = useState(true);
    const [loadingSoft, setLoadingSoft] = useState(true);

    useEffect(() => {
        const fetchDeptData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/energyconsumptiondepartment`);
                setDeptData(response.data);
                setLoadingDept(false);
            } catch (error) {
                console.log("error fetching the Department Data ");
            }
        };

        const fetchSoftData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/energyconsumptionsoftware`);
                setSoftData(response.data);
                setLoadingSoft(false);
            } catch (error) {
                console.log("error fetching the Patternlab Data ");
            }
        };

        fetchDeptData();
        fetchSoftData();
    }, []);

    useEffect(() => {
        if (!loadingDept && !loadingSoft) {
            // Combine data and prepare chart
            const chartDom = document.getElementById('main');
            const myChart = echarts.init(chartDom);

            // Get the months directly from the data
            const months = deptData.map(item => item.Month);
            const deptBillsWithCharges = deptData.map(item => item.Bill_with_charges);
            const deptBillsWithoutCharges = deptData.map(item => item.Bill_without_charges);
            const deptFixedCharges = deptData.map(item => item.Fixed_charges);

            const softBillsWithCharges = softData.map(item => item.Bill_with_charges);
            const softBillsWithoutCharges = softData.map(item => item.Bill_without_charges);
            const softFixedCharges = softData.map(item => item.Fixed_charges);

            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    // formatter: (params) => {
                    //     const deptIndex = deptData.findIndex(item => item.Month === params[0].axisValue);
                    //     const softIndex = softData.findIndex(item => item.Month === params[0].axisValue);
                    //     const deptBillWithCharges = deptIndex !== -1 ? deptData[deptIndex].Bill_with_charges : 0;
                    //     const softBillWithCharges = softIndex !== -1 ? softData[softIndex].Bill_with_charges : 0;
                    //     return `
                    //         <strong>Department Generated Total Bill: ${deptBillWithCharges}</strong><br/>
                    //         ${params[0].marker} Department Generated Bill Without Fixed Charge: ${deptBillsWithoutCharges[deptIndex]}<br/>
                    //         ${params[1].marker} Department Generated Fixed Charge: ${deptFixedCharges[deptIndex]}<br/>
                    //         <strong>Software Generated Total Bill: ${softBillWithCharges}</strong><br/>
                    //         ${params[2].marker} Software Generated Bill Without Fixed Charge: ${softBillsWithoutCharges[softIndex]}<br/>
                    //         ${params[3].marker} Software Generated Fixed Charge: ${softFixedCharges[softIndex]}
                    //     `;
                    // }
                    formatter: (params) => {
                        const deptIndex = deptData.findIndex(item => item.Month === params[0]?.axisValue);
                        const softIndex = softData.findIndex(item => item.Month === params[0]?.axisValue);
                        const deptBillWithCharges = deptIndex !== -1 ? deptData[deptIndex].Bill_with_charges : 0;
                        const softBillWithCharges = softIndex !== -1 ? softData[softIndex].Bill_with_charges : 0;
                    
                        return `
                            <strong>Department Generated Total Bill: ${deptBillWithCharges}</strong><br/>
                            ${params[0].marker} Department Generated Bill Without Fixed Charge: ${deptBillsWithoutCharges[deptIndex]}<br/>
                            ${params[1].marker} Department Generated Fixed Charge: ${deptFixedCharges[deptIndex]}<br/>
                            <strong>Patternlab Generated Total Bill: ${softBillWithCharges}</strong><br/>
                            ${params[2].marker} Patternlab Generated Bill Without Fixed Charge: ${softBillsWithoutCharges[softIndex]}<br/>
                            ${params[3].marker} Patternlab Generated Fixed Charge: ${softFixedCharges[softIndex]}
                        `;
                    }
                    
                },
                legend: {
                    data: ['Department generated - without fixed charges', 'Department generated - fixed charges', 'Patternlab generated - without fixed charges', 'Patternlab generated - fixed charges']
                },
                xAxis: {
                    type: 'category',
                    data: months,
                    axisLabel: {
                        formatter: function (value) {
                            return value; // Directly use the month value from the data
                        }
                    }
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: 'Department generated - without fixed charges',
                        type: 'bar',
                        stack: 'Department',
                        data: deptBillsWithoutCharges,
                        itemStyle: {
                            color: '#987070'  // Custom color for Department Bill Without Charges
                        },
                        barWidth: 30,
                    },
                    {
                        name: 'Department generated - fixed charges',
                        type: 'bar',
                        stack: 'Department',
                        data: deptFixedCharges,
                        itemStyle: {
                            color: '#C39898'
                        },
                        barWidth: 30,
                    },
                    {
                        name: 'Patternlab generated - without fixed charges',
                        type: 'bar',
                        stack: 'Patternlab',
                        data: softBillsWithoutCharges,
                        itemStyle: {
                            color: '#5F6F65'
                        },
                        barWidth: 30,
                    },
                    {
                        name: 'Patternlab generated - fixed charges',
                        type: 'bar',
                        stack: 'Patternlab',
                        data: softFixedCharges,
                        itemStyle: {
                            color: '#9CA986'
                        },
                        barWidth: 30,
                    }
                ]
            };

            myChart.setOption(option);
        }
    }, [deptData, softData]);

    return (
        <div id='dashenergy-total'>
            <span>Energy consumption & billing</span>
            <div id="main" className='dashenergy-chart'></div>
        </div>
    );
};

export default DashEnergyConsumption;

