import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import { Operatorheadingss, Phaseimbalanceheader } from '../../../Operator/Data';
import { Link } from 'react-router-dom';
import Supervisorsidebar from '../../../Supervisor/Supersidebar/Supersidebar';
import './Oil_Winding_Temperature_Formfetch.css';
import Operatorsidebar from '../../../Operator/Sidebar/Sidebar';

const Oil_Winding_Temperature_Formfetch = () => {
    const [oilWindingTemperatureForm, setOilWindingTemperatureForm] = useState([]);
    const [oilWindingTemperatureFormEditData, setOilWindingTemperatureFormEditData] = useState(null);

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userDesignation, setUserDesignation] = useState('');
    useEffect(() => {
      // Retrieve the logged-in user from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.username) {
        setLoggedInUser(user);
      }
      setUserDesignation(user.level);
    }, []);

    const fetchOilWindingTemperatureForm = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_EMS_URL}/api/oilwindingtemperatureforms`);
            setOilWindingTemperatureForm(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchOilWindingTemperatureForm();
    }, []);

    const handleEditClick = (form) => {
        setOilWindingTemperatureFormEditData(form);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setOilWindingTemperatureFormEditData({ ...oilWindingTemperatureFormEditData, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`${process.env.REACT_APP_API_EMS_URL}/api/oilwindingtemperatureforms/${oilWindingTemperatureFormEditData._id}`, oilWindingTemperatureFormEditData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            fetchOilWindingTemperatureForm();
            setOilWindingTemperatureFormEditData(null);
            alert("Oil Winding Temperature Form updated successfully");
        } catch (error) {
            console.error('Error updating data', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div id='oil_winding_temperature_formfetch-total'>
            <div>
                {userDesignation === 'Level-4' ? <Operatorsidebar /> : <Supervisorsidebar />}  
            </div>
            <div id='oil_winding_temperature_formfetch-right'>
                <div id='oil_winding_temperature_formfetch-right-1'>
                    <div id='oil_winding_temperature_formfetch-heading'>
                        {Phaseimbalanceheader.alertHeading}
                    </div>
                    <div id='oil_winding_temperature_formfetch-heading1'>
                        {Phaseimbalanceheader.alertActions.map((action, index) => (
                            <button key={index} aria-label={action.label}>
                                {action.icon}
                            </button>
                        ))}
                        <img src={Phaseimbalanceheader.logoSrc} alt='logo' />
                    </div>
                </div>
                <div id='oil_winding_temperature_formfetch-right-2'>
                    <div id='oil_winding_temperature_formfetch-right-21'>
                    <label>
                                <span style={{fontWeight:'bold'}}>Oil and Winding Temperature Monitor</span>
                            </label>
                        {/* <label>
                            <Link to='/Oil_Winding_Temperature_Form'><button>Form</button></Link>
                        </label> */}
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Token ID</th>
                                <th>Date and Time</th>
                                <th>Name</th>
                                <th>Oil Temperature (°C)</th>
                                <th>Winding Temperature (°C)</th>
                                {/* <th>Created By</th>
                                <th>Created At</th>
                                <th>Modified By</th>
                                <th>Modified At</th> */}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {oilWindingTemperatureForm.map((form) => (
                                <tr key={form._id}>
                                    <td>{form.token_id}</td>
                                    <td>{form.dateandtime}</td>
                                    <td>{form.name}</td>
                                    <td>{form.oil_temperature}</td>
                                    <td>{form.winding_temperature_c}</td>
                                    {/* <td>{form.createdby}</td>
                                    <td>{form.createdat}</td>
                                    <td>{form.modifiedby}</td>
                                    <td>{form.modifiedat}</td> */}
                                    <td>
                                        <button onClick={() => handleEditClick(form)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {oilWindingTemperatureFormEditData && (
                        <Popup open={true} onClose={() => setOilWindingTemperatureFormEditData(null)}>
                            <div id='oil_winding_temperature_formfetch-popup'>
                                <h3>Edit Oil Winding Temperature Form</h3>
                                <form onSubmit={handleEditSubmit}>
                                    <div id='oil_winding_temperature_formfetch-popup1'>
                                        <label>
                                            <span>Token ID</span>
                                            <input name="token_id" value={oilWindingTemperatureFormEditData.token_id} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Date and Time</span>
                                            <input name="dateandtime" value={oilWindingTemperatureFormEditData.dateandtime} onChange={handleEditChange} disabled />
                                        </label>
                                    </div>
                                    <div id='oil_winding_temperature_formfetch-popup1'>
                                        <label>
                                            <span>Name <span id='all-mandetorymark'>*</span></span>
                                            <input type="text" name="name" value={oilWindingTemperatureFormEditData.name} onChange={handleEditChange} required />
                                        </label>
                                        <label>
                                            <span>Oil Temperature (°C)<span id='all-mandetorymark'>*</span></span>
                                            <input type="number" name="oil_temperature" value={oilWindingTemperatureFormEditData.oil_temperature} onChange={handleEditChange} required />
                                        </label>
                                    </div>
                                    <div id='oil_winding_temperature_formfetch-popup1'>
                                        <label>
                                            <span>Winding Temperature (°C) <span id='all-mandetorymark'>*</span></span>
                                            <input type="number" name="winding_temperature_c" value={oilWindingTemperatureFormEditData.winding_temperature_c} onChange={handleEditChange} required/>
                                        </label>
                                        {/* <label>
                                            <span>Created By</span>
                                            <input type="text" name="createdby" value={oilWindingTemperatureFormEditData.createdby} onChange={handleEditChange} disabled />
                                        </label> */}
                                    </div>
                                    {/* <div id='oil_winding_temperature_formfetch-popup1'>
                                        
                                        <label>
                                            <span>Created At</span>
                                            <input type="text" name="createdat" value={oilWindingTemperatureFormEditData.createdat} onChange={handleEditChange} disabled />
                                        </label>
                                        <label>
                                            <span>Modified By</span>
                                            <input type="text" name="modifiedby" value={oilWindingTemperatureFormEditData.modifiedby} onChange={handleEditChange} disabled />
                                        </label>
                                    </div>
                                    <div id='oil_winding_temperature_formfetch-popup1'>
                                       
                                        <label>
                                            <span>Modified At</span>
                                            <input type="text" name="modifiedat" value={oilWindingTemperatureFormEditData.modifiedat} onChange={handleEditChange} disabled />
                                        </label>
                                    </div> */}
                                    <button id='oil_winding_temperature_formfetch-popup-update' type="submit">Update</button>
                                </form>
                            </div>
                        </Popup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Oil_Winding_Temperature_Formfetch;
